import {
  Entity,
  Column,
  OneToOne,
  PrimaryGeneratedColumn,
  JoinColumn,
  Index,
  ManyToOne,
  DeleteDateColumn,
  CreateDateColumn
} from 'typeorm';
import { Tenant } from './tenant';
import { ApiKey } from './api-key';
import { RolePermissionGroup } from './role-permission-group';

export enum TENANT_USER_TYPE {
  USER = 'USER',
  ADMIN_API_KEY = 'ADMIN_API_KEY'
}

export enum TENANT_USER_STATUS {
  INVITED = 'INVITED',
  EXPIRED = 'EXPIRED',
  ACTIVE = 'ACTIVE',
  SUSPENDED = 'SUSPENDED',
  WAITING = 'WAITING'
}

export enum TENANT_USER_ROLE {
  ADMIN = 'Admin',
  PUBLISHER = 'Publisher',
  CREATOR = 'Creator',
  READ_ONLY = 'Read Only'
}

/**
 * Class services as a RDS copy of Cognito users and ADMIN_API api keys to be used for createdBy columns.
 */
@Entity('tenant_user')
@Index(['username', 'deletedAt'])
@Index(['apiKey', 'deletedAt'])
@Index(['tenant', 'deletedAt'])
export class TenantUser {
  @PrimaryGeneratedColumn('uuid')
  id: string;

  @Column({ type: 'varchar', nullable: true, unique: true })
  @Index()
  username: string;

  @Column({ nullable: true })
  apiKeyId: string;
  @OneToOne((_) => ApiKey, (apiKey) => apiKey.user, { nullable: true })
  @JoinColumn()
  apiKey: ApiKey;

  @Column({
    type: 'varchar',
    nullable: true,
    unique: true,
    transformer: {
      to: (value: string) => value?.toLowerCase?.() || value,
      from: (value: string) => value
    }
  })
  email: string;

  @Column({ type: 'varchar', nullable: true })
  name: string;

  @Column({ type: 'enum', enum: TENANT_USER_TYPE })
  type: TENANT_USER_TYPE;

  @Column({ type: 'enum', enum: TENANT_USER_STATUS, default: TENANT_USER_STATUS.INVITED })
  status: TENANT_USER_STATUS;

  @Column({ name: 'invite_scheduled_at', type: 'timestamptz', nullable: true })
  inviteScheduledAt: Date;

  // deprecated - will temporarily keep value to allow for rollback if required
  @Column({ type: 'boolean', name: 'is_admin', default: false })
  isAdmin: boolean;

  @Column()
  roleId: string;
  @ManyToOne((_) => RolePermissionGroup, (rolePermissionGroup) => rolePermissionGroup.user, { nullable: true })
  @JoinColumn()
  role: RolePermissionGroup;

  @Column({ nullable: true })
  parentTenantId: string;
  @ManyToOne((_) => Tenant, { onDelete: 'SET NULL' })
  @JoinColumn()
  parentTenant: Tenant;

  // for associating session API KEYs with their owner on the Super Tenant
  @Column({ nullable: true })
  parentTenantUserId?: string;
  @ManyToOne((_) => TenantUser, { onDelete: 'SET NULL' })
  @JoinColumn()
  parentTenantUser?: TenantUser;

  @Column()
  tenantId: string;
  @ManyToOne((_) => Tenant, { onDelete: 'CASCADE' })
  @JoinColumn()
  tenant: Tenant;

  @CreateDateColumn({ name: 'created_at', type: 'timestamptz' })
  @Index()
  createdAt: Date;

  @Column({ nullable: true })
  createdById: string;
  @ManyToOne((_) => TenantUser, { onDelete: 'SET NULL' })
  @JoinColumn()
  @Index()
  createdBy: TenantUser;

  @DeleteDateColumn({ name: 'deleted_at' })
  deletedAt: Date;

  @Column({ name: 'redacted_at', type: 'timestamptz', nullable: true })
  redactedAt: Date;

  @Column({ name: 'last_login', type: 'timestamptz', nullable: true })
  lastLogin: Date;
}
