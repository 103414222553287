import React from 'react';
import { RuleId, rules as rulesData } from '@pushologies/segments-service/data/rules';
import { useStoreActions } from '~store/hooks';
import { Rule } from '~store/create-segment';
import { RuleIcon } from './rule/icon';
import PlusIcon from '~images/plus.svg';
import { RuleListSection, RuleDiv } from './styles';

interface Props {
  rules: Rule[];
}

export const RuleList: React.FC<Props> = (props) => {
  const { addRules } = useStoreActions((state) => state.createSegment);
  const handleSelectRule = (ruleId: RuleId) => () => {
    addRules([{ ruleId, operator: null, value: null }]);
  };

  const getRuleCount = (id: string) => {
    return props.rules.filter((obj) => obj.segmentRule.ruleId === id).length;
  };

  return (
    <RuleListSection data-tour="ruleList">
      {Object.values(rulesData)
        .filter(({ disabled }) => !disabled)
        .map((rule) => (
          <RuleDiv data-testid={`listedRule${rule.id}`} onClick={handleSelectRule(rule.id)} key={rule.id}>
            <RuleIcon id={rule.id} />
            <h3>{rule.name}</h3>
            <div className="add-tag">
              <PlusIcon />
            </div>
            {getRuleCount(rule.id) > 0 && <span>{getRuleCount(rule.id)}</span>}
          </RuleDiv>
        ))}
    </RuleListSection>
  );
};
