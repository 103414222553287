import React from 'react';
import ReactPaginate from 'react-paginate';
import { PaginationSection } from './styles';

export type PageChangeHandlerArgs = { selected: number };

export interface ViewerPaginationProps {
  limit: number;
  totalItems: number;
  offset: number;
  testId?: string;
  onPageChange(offset: number): void;
}

/** Pagination component used by ListViewer and TilesViewer components */
export const ViewerPagination: React.FC<ViewerPaginationProps> = ({
  limit,
  totalItems,
  onPageChange,
  offset,
  testId
}) => {
  const handlePageChange = ({ selected }: PageChangeHandlerArgs) => {
    const newOffset = selected * limit;
    onPageChange(newOffset);
  };

  return (
    <PaginationSection data-testid={testId}>
      <ReactPaginate
        pageCount={Math.ceil(totalItems / limit)}
        marginPagesDisplayed={1}
        pageRangeDisplayed={2}
        breakLabel="..."
        nextLabel=">"
        previousLabel="<"
        onPageChange={handlePageChange}
        forcePage={offset / limit}
      />
    </PaginationSection>
  );
};
