import { Operator, OperatorValue as Op } from '../types';

export const operators: Record<Op, Operator> = {
  [Op.greaterThan]: {
    name: 'greater than',
    value: Op.greaterThan
  },
  [Op.lessThan]: {
    name: 'less than',
    value: Op.lessThan
  },
  [Op.greaterThanOrEqual]: {
    name: 'greater than or equal',
    value: Op.greaterThanOrEqual
  },
  [Op.lessThanOrEqual]: {
    name: 'less than or equal',
    value: Op.lessThanOrEqual
  },
  [Op.is]: {
    name: 'is',
    value: Op.is
  },
  [Op.isNot]: {
    name: 'is not',
    value: Op.isNot
  },
  [Op.in]: {
    name: 'in',
    value: Op.in
  },
  [Op.notIn]: {
    name: 'not in',
    value: Op.notIn
  },
  [Op.between]: {
    name: 'between',
    value: Op.between
  },
  [Op.before]: {
    name: 'before',
    value: Op.before
  },
  [Op.after]: {
    name: 'after',
    value: Op.after
  },
  [Op.inside]: {
    name: 'inside',
    value: Op.inside
  },
  [Op.outside]: {
    name: 'outside',
    value: Op.outside
  }
};
