import React, { useEffect, useMemo, useState } from 'react';
import { Button, Col } from 'react-bootstrap';
import { TextField } from '~components/inputs';
import { DateRangePicker } from '~components/inputs/date-time-picker';
import { Select, SelectOption } from '~components/select';
import MagGlassIcon from '~images/mag_glass.svg';
import { useStoreState } from '~store/hooks';
import { StyledFiltersRow, TitleCol, SelectsCol } from './styles';

export interface Filters {
  offset: number;
  title: string;
  createdById: SelectOption[];
  pushed: SelectOption<boolean>[];
  createdBetween: [Date, Date];
}

const pushedSelect: SelectOption[] = [
  {
    name: 'Pushed',
    value: true
  },
  {
    name: 'Not Pushed',
    value: false
  }
];

export const filtersToApi = (filters: Partial<Filters>) => ({
  ...(!!filters.offset && { offset: filters.offset }),
  ...(filters.title?.length && { title: filters.title }),
  ...(filters.createdById?.length && { createdById: filters.createdById[0].value }),
  ...(filters.pushed?.length && { pushed: filters.pushed[0].value }),
  ...(filters.createdBetween?.length && {
    createdAfter: filters.createdBetween[0],
    createdBefore: filters.createdBetween[1]
  })
});

interface Props extends Filters {
  onFilterChange(filters: Partial<Filters>): void;
  onClear(): void;
}

export const FiltersRow: React.FC<Props> = (props) => {
  const [localTitle, setLocalTitle] = useState('');
  const [{ users }, { isSuperTenant }] = useStoreState((state) => [state.users, state.childTenants]);

  useEffect(() => {
    // this allows title to be "clear"ed
    setLocalTitle(props.title);
  }, [props]);

  const userSelectOptions: SelectOption[] = useMemo(
    () =>
      Object.values(users).map((user) => ({
        name: user.name,
        value: user.id
      })),
    [users]
  );

  const handleTitleFilterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setLocalTitle(event.target.value);
  };

  const handleUserFilterChange = (option: SelectOption[]) => {
    props.onFilterChange({ createdById: option });
  };

  const handlePushedFilterChange = (option: SelectOption[]) => {
    props.onFilterChange({ pushed: option });
  };

  const handleDateFiterChange = (dates: [Date, Date]) => {
    props.onFilterChange({ createdBetween: dates });
  };

  const handleSearchSubmit = () => {
    props.onFilterChange({ title: localTitle });
  };

  return (
    <StyledFiltersRow>
      <SelectsCol md={6}>
        {!isSuperTenant && (
          <Select
            id="createdByIdSelect"
            testId="createdByIdSelect"
            value={props.createdById}
            options={userSelectOptions || []}
            placeholder="Filter User"
            inValid={false}
            onChange={handleUserFilterChange}
          />
        )}
        <Select
          id="pushedSelect"
          testId="pushedSelect"
          value={props.pushed}
          options={pushedSelect}
          placeholder="Filter Pushed"
          inValid={false}
          onChange={handlePushedFilterChange}
        />
        <DateRangePicker value={props.createdBetween} placeholder="Created Between" onChange={handleDateFiterChange} />
      </SelectsCol>
      <TitleCol md={5}>
        <Button onClick={props.onClear} data-testid="clearButton">
          Clear
        </Button>
        <TextField
          name="titleFilter"
          placeholder="Search by title/subTitle"
          value={localTitle}
          onChange={handleTitleFilterChange}
          onKeyUp={(event: React.KeyboardEvent<HTMLInputElement>) => {
            if (event.key === 'Enter') {
              handleSearchSubmit();
            }
          }}
          icon={<MagGlassIcon />}
        />
      </TitleCol>
      <Col md={1}>
        <Button size="sm" variant="primary" onClick={handleSearchSubmit} data-testid="searchButton">
          Search
        </Button>
      </Col>
    </StyledFiltersRow>
  );
};
