import {
  Column,
  Entity,
  PrimaryGeneratedColumn,
  CreateDateColumn,
  JoinColumn,
  ManyToOne,
  Index,
  Unique
} from 'typeorm';
import { Tenant } from './tenant';

@Entity('tag')
@Index(['name', 'tenant'])
@Unique('tag_unique_name_tenantId', ['name', 'tenant'])
export class Tag {
  @PrimaryGeneratedColumn('uuid')
  id: string;

  @Column({ type: 'varchar' })
  @Index()
  name: string;

  @Column()
  tenantId: string;
  @ManyToOne((_) => Tenant, { onDelete: 'CASCADE' })
  @JoinColumn()
  @Index()
  tenant: Tenant;

  @CreateDateColumn({ name: 'created_at', type: 'timestamptz' })
  @Index()
  createdAt: Date;
}
