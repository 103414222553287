import React from 'react';
import { Col } from 'react-bootstrap';
import { useTheme } from 'styled-components';
import { useStoreState } from '~store/hooks';
import { ApiNotification } from '~api/notifications/types';
import { dateTimeFormat, numberCommaFormat } from '~helpers/formats';
import { AndroidIconStyled, AppleIconStyled } from '~components/styled/icons';
import { getBarChartData } from './data';
import { NotificationTotalStyledRow, BarChartRow, StyledBarChart } from './styles';

interface Props {
  notification: ApiNotification;
  segmentSize: number;
}

export const TopRow: React.FC<Props> = ({ notification, segmentSize }) => {
  const theme = useTheme();
  const { notificationResults } = useStoreState((state) => state.notificationResult);
  const { barChartData, androidResults, iosResults, totalResults } = getBarChartData(
    notificationResults,
    segmentSize,
    theme,
    notification
  );

  return (
    <NotificationTotalStyledRow>
      <Col md="auto">
        <div className="notification-total" data-testid="notificationTotals">
          <h3>{dateTimeFormat(notification?.pushedAt || notification?.schedule?.pushAt, 'LL')}</h3>
          <p>Total Number Of Recipients</p>
          <div className="android">
            <AndroidIconStyled />
            <p>{numberCommaFormat(androidResults?.messageCount)}</p>
          </div>
          <div className="apple">
            <AppleIconStyled />
            <p>{numberCommaFormat(iosResults?.messageCount)}</p>
          </div>
          <p className="total">Total</p>
          <h2>{numberCommaFormat(totalResults)}</h2>
        </div>
      </Col>
      <Col>
        <BarChartRow $chartNum={barChartData.length}>
          {barChartData.map((data) => (
            <StyledBarChart key={data?.label} {...data} testId={data.testId} />
          ))}
        </BarChartRow>
      </Col>
    </NotificationTotalStyledRow>
  );
};
