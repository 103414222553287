import React, { useCallback } from 'react';
import { Col, Spinner } from 'react-bootstrap';
import { useStoreActions, useStoreState } from '~store/hooks';
import AndroidIcon from '~images/android.svg';
import AppleIcon from '~images/apple.svg';
import { CredentialWrapperDiv } from './styles';

export const ViewCol: React.FC = () => {
  const actions = useStoreActions(({ credentials }) => credentials);
  const { loading, firebase, apn } = useStoreState(({ credentials }) => credentials);
  const fetchCredentials = useCallback(async () => await actions.fetchCredentials(), []);

  React.useEffect(() => {
    fetchCredentials();
  }, []);

  return (
    <Col md="auto">
      <h1>Native App Platforms</h1>
      <CredentialWrapperDiv>
        <AppleIcon />
        <div className="creds-info">
          {loading && <Spinner animation="border" size="sm" />}
          {apn && (
            <>
              <p>
                <span>Bundle Id:</span> {apn.bundleId}
              </p>
              <p>
                <span>Team Id:</span> {apn.teamId}
              </p>
            </>
          )}
          {!loading && !apn && <span>No APN data uploaded</span>}
        </div>
      </CredentialWrapperDiv>
      <CredentialWrapperDiv>
        <AndroidIcon />
        <div className="creds-info">
          {loading && <Spinner animation="border" size="sm" />}
          {firebase && (
            <>
              <p>
                <span>Firebase email:</span> {firebase.email}
              </p>
              <p>
                <span>Firebase Project Id:</span> {firebase.projectId}
              </p>
            </>
          )}
          {!loading && !firebase && <span>No Firebase data uploaded</span>}
        </div>
      </CredentialWrapperDiv>
    </Col>
  );
};
