import React from 'react';
import { Table, Spinner } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { StyledContainer } from '~components/styled/container';
import { TableSection, ActionsTd } from '~components/styled/table-section';
import { Pagination } from '~components/pagination';
import { SidePanel } from '~components/side-panel';
import { PromptButton } from '~components/prompt-button';
import { useStoreState, useStoreActions } from '~store/hooks';
import { ApiSegment } from '~api/segments/types';
import { LIMIT } from '~store/segment';
import EditIcon from '~images/edit.svg';
import EyeIcon from '~images/eye.svg';
import CloneIcon from '~images/clone.svg';
import DeleteIcon from '~images/delete.svg';
import { SegmentDetails } from './details';
import { DeleteSpan, StyledRow } from './styles';
import { Loader } from '~components/loader';
import { RoleGuard } from '../../../role-guard';
import { generateRandomStrUID } from '~helpers/files';

interface ActionsColumnProps {
  handleEyeIconClick(event: React.MouseEvent): void;
  handleEditClick(event: React.MouseEvent): void;
  handleCloneClick(event: React.MouseEvent): void;
  handleDeleteClick(): void;
  isDeleting: boolean;
  segment: ApiSegment;
}

const ActionsColumn: React.FC<ActionsColumnProps> = ({
  handleEyeIconClick,
  handleEditClick,
  handleCloneClick,
  handleDeleteClick,
  isDeleting,
  segment
}) => {
  return (
    <ActionsTd>
      <span className="eye" onClick={handleEyeIconClick} data-testid="eyeIcon">
        <EyeIcon />
      </span>
      <RoleGuard requiredRole="Creator">
        <span onClick={handleEditClick} data-testid="editButton">
          <EditIcon />
        </span>
      </RoleGuard>
      <RoleGuard requiredRole="Admin">
        <span onClick={handleCloneClick} data-testid="cloneButton">
          <CloneIcon />
        </span>
      </RoleGuard>
      <RoleGuard requiredRole="Admin">
        <DeleteSpan isDeleting={isDeleting} data-testid="deleteButton">
          <PromptButton
            buttonType="icon"
            buttonIcon={<DeleteIcon />}
            onConfirm={handleDeleteClick}
            confirmButtonText="delete"
            promptTitle="Confirm Delete"
          >
            Are you sure you would like to delete segment "{segment.name}"?
          </PromptButton>
          {isDeleting && <Spinner animation="border" size="sm" />}
        </DeleteSpan>
      </RoleGuard>
    </ActionsTd>
  );
};

export const ListSegments: React.FC = () => {
  const history = useHistory();
  const [loading, setLoading] = React.useState(true);
  const [segments, setSegments] = React.useState<ApiSegment[]>([]);
  const [activeSegment, setActiveSegment] = React.useState<ApiSegment>();
  const [totalItems, setTotalItems] = React.useState(10);
  const [offset, setOffset] = React.useState(0);
  const { visibility } = useStoreState(({ sideNav }) => sideNav);
  const { fetchSegments, deleteSegment } = useStoreActions((state) => state.segments);
  const [isDeleting, setIsDeleting] = React.useState(false);
  const { setModel } = useStoreActions(({ createSegment }) => createSegment);

  const handlePageChange = ({ selected }: { selected: number }) => {
    setLoading(true);
    setOffset(selected * LIMIT);
  };

  const handleSeeSegmentDetails = (segment: ApiSegment) => (event: React.MouseEvent) => {
    event.stopPropagation();
    setActiveSegment(segment);
  };

  const handleCloneClick = (segment: ApiSegment) => () => {
    const rulesArray: any = [];
    segment.rules.map((rule) => rulesArray.push({ id: generateRandomStrUID(10), segmentRule: rule }));
    setModel({
      name: segment?.name || '',
      description: segment?.description || '',
      rules: rulesArray || [],
      dynamism: segment?.dynamism || null
    });
    history.push('segments/create');
  };

  const handleEditClick = (segment: ApiSegment) => () => {
    history.push(`segments/create/${segment.id}`);
  };

  const handleDeleteClick = (segment: ApiSegment) => () => {
    setIsDeleting(true);
    deleteSegment({
      id: segment.id,
      onSuccess() {
        const index = segments.findIndex(({ id }) => id === segment.id);
        setSegments([...segments.slice(0, index), ...segments.slice(index + 1)]);
        setIsDeleting(false);
      },
      onError() {
        setIsDeleting(false);
      }
    });
  };

  React.useEffect(() => {
    fetchSegments({
      limit: LIMIT,
      offset,
      onSuccess(newSegments, itemsCount) {
        setTotalItems(itemsCount);
        setSegments(newSegments);
        setLoading(false);
      }
    });
  }, [offset]);

  return (
    <StyledContainer $sideNavVisible={visibility === 'show'}>
      <StyledRow>
        <TableSection>
          <Loader loading={loading} backdrop />
          <Table responsive>
            <thead>
              <tr>
                <th>Name</th>
                <th>Description</th>
                <th style={{ width: '7rem' }}>Dynamism</th>
                <th style={{ width: '8rem' }}>Actions</th>
              </tr>
            </thead>
            <tbody>
              {segments.map((segment) => (
                <tr key={segment.id} data-testid="segmentListRow">
                  <td>{segment.name}</td>
                  <td>{segment.description}</td>
                  <td className="dynamism">{segment.dynamism}</td>
                  <ActionsColumn
                    handleEyeIconClick={handleSeeSegmentDetails(segment)}
                    handleEditClick={handleEditClick(segment)}
                    handleCloneClick={handleCloneClick(segment)}
                    handleDeleteClick={handleDeleteClick(segment)}
                    isDeleting={isDeleting}
                    segment={segment}
                  />
                </tr>
              ))}
            </tbody>
          </Table>
          <Pagination
            pageCount={Math.ceil(totalItems / LIMIT)}
            marginPagesDisplayed={5}
            pageRangeDisplayed={4}
            forcePage={offset / LIMIT}
            breakLabel="..."
            onPageChange={handlePageChange}
          />
        </TableSection>
        <SidePanel
          show={!!activeSegment}
          title={activeSegment?.name}
          onClose={() => setActiveSegment(null)}
          testId="segmentSidePanel"
          closeOnBackdropClick
        >
          <SegmentDetails segment={activeSegment} />
        </SidePanel>
      </StyledRow>
    </StyledContainer>
  );
};
