import styled from 'styled-components';
import { StyledContainer as Container } from '~components/styled/container';

export const StyledContainer = styled(Container)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 50vh;
`;
