import styled from 'styled-components';
import LoadingDots from '~images/loading-dots.svg';
import { blink } from '~styles/mixins';

export const Dots = styled(LoadingDots)`
  g {
    circle {
      &:nth-child(1) {
        animation: ${blink} 1s infinite;
      }
      &:nth-child(2) {
        animation: ${blink} 1s infinite;
        animation-delay: 0.2s;
      }
      &:nth-child(3) {
        animation: ${blink} 1s infinite;
        animation-delay: 0.4s;
      }
    }
  }
`;
