import React, { useRef } from 'react';
import {
  VictoryAxis,
  VictoryChart,
  VictoryLine,
  VictoryScatter,
  VictoryThemeDefinition,
  VictoryTooltip
} from 'victory';
import { DefaultTheme, useTheme } from 'styled-components';
import { ChartSection } from './styles';

type Coordinates = { x: number; y: number; customLabel: string };
export interface ChartMetrics {
  activeUsers: Coordinates[];
  optedInUsers: Coordinates[];
  totalUsers: Coordinates[];
  xAxisLabels: string[];
}

export const activeUsersColor = '#0EA6C5';
export const optedInColor = '#D33429';
export const totalColor = '#3A0267';
const chartTheme = (theme: DefaultTheme): VictoryThemeDefinition => ({
  axis: {
    style: {
      axis: {
        fill: 'transparent',
        stroke: theme.palette.lightGrey
      },
      axisLabel: {
        stroke: 'transparent'
      },
      grid: {
        stroke: theme.palette.lightGrey
      },
      ticks: {
        display: 'none'
      },
      tickLabels: {
        fill: theme.palette.navGreyColor
      }
    }
  }
});

interface Props {
  metrics: ChartMetrics;
}

export const UserChart: React.FC<Props> = ({ metrics }) => {
  const theme = useTheme();
  const sectionRef = useRef<HTMLElement>();

  return (
    <ChartSection ref={sectionRef} data-testid="userVictoryChart">
      <VictoryChart
        theme={chartTheme(theme)}
        width={sectionRef?.current?.clientWidth}
        height={metrics.activeUsers.length ? sectionRef?.current?.clientHeight - 35 : 0}
        domainPadding={0}
        padding={{ top: 0, bottom: 5, right: 10, left: 50 }}
        style={{ parent: { zIndex: 100 } }}
      >
        {/* Dummy axis to create top/right border */}
        <VictoryAxis orientation="top" tickFormat={() => ''} style={{ grid: { display: 'none' } }} />
        <VictoryAxis
          orientation="right"
          tickFormat={() => ''}
          scale="log"
          style={{
            grid: {
              stroke: 'none'
            }
          }}
        />

        {/* bottom/left axis */}
        <VictoryAxis
          tickValues={metrics.activeUsers.map(({ x }) => x)}
          tickFormat={metrics.xAxisLabels}
          style={{
            ticks: {
              size: 20,
              stroke: 'white',
              strokeWidth: 1,
              display: 'inherit'
            } as any
          }}
        />
        <VictoryAxis
          tickCount={7}
          dependentAxis
          style={{
            tickLabels: { padding: 5 }
          }}
        />

        {/* Active Users */}
        <VictoryScatter
          size={2.5}
          style={{
            data: {
              fill: activeUsersColor
            }
          }}
          data={metrics.activeUsers}
          labels={({ datum }: any) => datum.customLabel}
          labelComponent={<VictoryTooltip />}
          animate={true}
        />
        <VictoryLine
          style={{
            data: {
              stroke: activeUsersColor,
              strokeWidth: 2
            }
          }}
          data={metrics.activeUsers}
          animate={true}
        />

        {/* Opted in subscribers */}
        <VictoryScatter
          size={4}
          style={{
            data: {
              fill: optedInColor
            }
          }}
          data={metrics.optedInUsers}
          labels={({ datum }: any) => datum.customLabel}
          labelComponent={<VictoryTooltip />}
          animate={true}
        />
        <VictoryLine
          style={{
            data: {
              stroke: optedInColor,
              strokeWidth: 2
            }
          }}
          data={metrics.optedInUsers}
          animate={true}
        />

        {/* Total subscribers */}
        <VictoryScatter
          size={4}
          style={{
            data: {
              fill: totalColor
            }
          }}
          data={metrics.totalUsers}
          labels={({ datum }: any) => datum.customLabel}
          labelComponent={<VictoryTooltip />}
          animate={true}
        />
        <VictoryLine
          style={{
            data: {
              stroke: totalColor,
              strokeWidth: 2
            }
          }}
          data={metrics.totalUsers}
          animate={true}
        />
      </VictoryChart>
    </ChartSection>
  );
};
