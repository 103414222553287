import React from 'react';
import { AutoSuggestChipField } from '~components/inputs/chip-field/autosuggest';
import { useStoreActions } from '~store/hooks';
import { Rule } from '~store/create-segment/types';
import { Suggestion } from '~components/inputs/autosuggest-field';
import { isInvalidValue } from './validation';

interface Props {
  rule: Rule<string[]>;
  onChange(tags: string[]): void;
}

export const TagsChipfield: React.FC<Props> = (props) => {
  const [suggestions, setSuggestions] = React.useState<Suggestion<string>[]>([]);
  const [loading, setLoading] = React.useState(false);
  const { fetchTags } = useStoreActions(({ tags }) => tags);
  const chips: Suggestion<string>[] = props?.rule?.segmentRule?.value
    ? props.rule.segmentRule.value.map((value) => ({ label: value, value }))
    : [];

  const handleChange = (tags: Suggestion<string>[]) => {
    props.onChange(tags.map((tag) => tag.value));
  };

  const handleInputChange = (name: string) => {
    setLoading(true);
    fetchTags({
      name,
      onSuccess(tags) {
        setSuggestions(tags.map((tag) => ({ label: tag.name, value: tag.name })));
        setLoading(false);
      }
    });
  };

  return (
    <AutoSuggestChipField<string>
      chips={chips}
      onChange={handleChange}
      onInputChange={handleInputChange}
      placeholder="Search tags"
      invalid={isInvalidValue(props.rule.segmentRule)}
      suggestions={suggestions}
      loading={loading}
      testId="tagsChipField"
    />
  );
};
