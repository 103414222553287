import React from 'react';
import { NumberTimeFieldSection, InputP } from './styles';

type TimeMetric = 'seconds' | 'minutes';

interface Props {
  /** Time in milliseconds */
  value: number;
  onChange(milliSeconds: number): void;
  label?: string;
  testId?: string;
}

const millisecondsToMetric = (milliseconds: number = 0) => {
  const minutes = Math.floor(milliseconds / 60000);
  const seconds = (milliseconds % 60000) / 1000;

  return { minutes, seconds };
};

/**
 * Input component for displaying time in "00:00" (minutes:seconds) format.
 * Implementation (auto growing input solution with span) inspired by https://css-tricks.com/auto-growing-inputs-textareas
 */
export const NumberTimeField: React.FC<Props> = ({ onChange, value = 0, label, testId }) => {
  const calculatedMetric = millisecondsToMetric(value);
  const minutesRef = React.useRef<HTMLSpanElement>();
  const secondsRef = React.useRef<HTMLSpanElement>();
  const [minutes, setMinutes] = React.useState<number>(calculatedMetric.minutes);
  const [seconds, setSeconds] = React.useState<number>(calculatedMetric.seconds);
  const flooredSec = Math.floor(seconds);
  const flooredMins = Math.floor(minutes);

  // tslint:disable-next-line: cyclomatic-complexity
  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    const validateKey = /[0-9]|Backspace|^Arrow/.exec(event.key);

    if (!validateKey) {
      event.stopPropagation();
      event.preventDefault();
      return;
    }

    if (event.key === 'ArrowLeft' || event.key === 'ArrowDown') {
      // decrement when left/down arrows clicked
      let newValue;
      if (event.currentTarget.name === 'minutes') {
        newValue = minutes - 1;
        if (newValue < 0) newValue = 0;
        setMinutes(newValue);
        onChange((newValue || 0) * 60000 + seconds * 1000); // convert to milliseconds
      } else {
        newValue = seconds - 1;
        if (newValue < 0) newValue = 0;
        setSeconds(newValue);
        onChange(minutes * 60000 + newValue * 1000);
      }
    } else if (event.key === 'ArrowRight' || event.key === 'ArrowUp') {
      // increment when right/up arrows clicked
      let newValue;
      if (event.currentTarget.name === 'minutes') {
        newValue = minutes + 1;
        if (newValue > 59) return;
        setMinutes(newValue);
        onChange((newValue || 0) * 60000 + seconds * 1000);
      } else {
        newValue = seconds + 1;
        if (newValue > 59) return;
        setSeconds(newValue);
        onChange(minutes * 60000 + newValue * 1000);
      }
    }
  };

  const handleOnChange = (metric: TimeMetric) => (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = event.target.value ? Number(event.target.value) : 0;

    if (inputValue < 0 || inputValue > 59) return;

    if (metric === 'minutes') {
      setMinutes(inputValue);
      onChange((inputValue || 0) * 60000 + seconds * 1000); // convert to milliseconds
    } else {
      setSeconds(inputValue);
      onChange(minutes * 60000 + inputValue * 1000);
    }
  };

  const handleOnArrowClick = (incrementor: number) => () => {
    const newValue = value + incrementor;
    onChange(newValue < 0 ? 0 : newValue);
  };

  React.useEffect(() => {
    const metrics = millisecondsToMetric(value);
    setMinutes(metrics.minutes);
    setSeconds(metrics.seconds);
  }, [value]);

  React.useEffect(() => {
    minutesRef.current.innerText = flooredMins < 10 ? `0${flooredMins}` : flooredMins.toString();
  }, [minutes]);

  React.useEffect(() => {
    secondsRef.current.innerText = flooredSec < 10 ? `0${flooredSec}` : flooredSec.toString();
  }, [seconds]);

  return (
    <NumberTimeFieldSection data-testid={testId}>
      {label && <label>{label}</label>}
      <InputP>
        <span className="wrapper">
          <span ref={minutesRef} className="width-machine" aria-hidden="true" />
          <input
            type="text"
            name="minutes"
            value={flooredMins < 10 ? `0${flooredMins}` : flooredMins}
            onChange={handleOnChange('minutes')}
            onKeyDown={handleKeyPress}
          />
        </span>
      </InputP>
      <span className="separator">:</span>
      <InputP>
        <span className="wrapper">
          <span ref={secondsRef} className="width-machine" aria-hidden="true" />
          <input
            type="text"
            name="seconds"
            value={flooredSec < 10 ? `0${flooredSec}` : flooredSec}
            onChange={handleOnChange('seconds')}
            onKeyDown={handleKeyPress}
          />
        </span>
      </InputP>
      <span className="back" onClick={handleOnArrowClick(-1000)} />
      <span className="forward" onClick={handleOnArrowClick(1000)} />
    </NumberTimeFieldSection>
  );
};
