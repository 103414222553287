import React, { KeyboardEvent } from 'react';
import { Button, Col } from 'react-bootstrap';
import { Switch, useRouteMatch } from 'react-router-dom';
import { useHistory } from 'react-router';
import { throttle } from 'throttle-debounce';
import { PrivateRoute } from '~routes/private-route';
import MagnifiyGlassIcon from '~images/mag_glass.svg';
import { AutosuggestField, Suggestion } from '~components/inputs/autosuggest-field';
import { ApiSubscriber } from '~api/subscribers/types';
import { StyledContainer } from '~components/styled/container';
import { useStoreActions, useStoreState } from '~store/hooks';
import { SubscriberDetail } from './subscriber-detail';
import { SubscriberExplorerList } from './subscriber-list';
import { subscriberExplorerPageLinks } from './links';
import { InputRow, WrapperDiv, StyledSelect } from './styles';
import { SelectOption } from '~components/select';

const searchTypeOptions: SelectOption[] = [
  { name: 'Subscriber ID', value: 'subscriberId' },
  { name: 'Device ID', value: 'deviceId' },
  { name: 'Customer ID', value: 'customerId' }
];

export const UsersExplorer: React.FC = () => {
  const { path } = useRouteMatch();
  const history = useHistory();
  const { setSideNavModel } = useStoreActions(({ sideNav }) => sideNav);
  const { fetchSubscribers } = useStoreActions((state) => state.subscribers);
  const { visibility } = useStoreState(({ sideNav }) => sideNav);
  const [suggestions, setSuggestions] = React.useState<Suggestion<ApiSubscriber>[]>([]);
  const [searchType, setSearchType] = React.useState<SelectOption>(searchTypeOptions[0]);
  const [searchText, setSearchText] = React.useState('');

  const handleSuggestSelect = ({ id }: ApiSubscriber) => {
    setSuggestions([]);
    history.push(`/subscriber-explorer/${id}`);
  };

  const handleSearchTypeSelect = (newSearchType: SelectOption[]) => {
    setSuggestions([]);
    setSearchType(newSearchType[0]);
    handleSearchSubmit(newSearchType[0]);
  };

  const handleSearchSubmit = (currentSearchType = searchType) => {
    throttle(250, () => {
      fetchSubscribers({
        [currentSearchType.value]: searchText,
        onSuccess(items) {
          setSuggestions(
            items.map((item) => ({
              label: [item.id, item.customerId, item.deviceId].filter(Boolean).join(' | '),
              value: item
            }))
          );
        }
      });
    })();
  };

  React.useEffect(() => {
    setSideNavModel({
      topBarLeftTitle: 'Subscriber Explorer',
      activeChild: 'subscriberExplorer',
      links: subscriberExplorerPageLinks
    });
  }, []);

  return (
    <Switch>
      <StyledContainer $sideNavVisible={visibility === 'show'}>
        <WrapperDiv>
          <InputRow>
            <Col md={6} className="iconCol"></Col>
            <Col md={5} className="inputCol">
              <StyledSelect
                id="searchType"
                value={[
                  searchTypeOptions.find((eachOption) => eachOption.value === searchType.value) || searchTypeOptions[0]
                ]}
                options={searchTypeOptions}
                onChange={handleSearchTypeSelect}
              />
              <AutosuggestField
                name="userSearch"
                placeholder={`Search by ${searchType.name}...`}
                onChange={(value: string) => {
                  setSearchText(value);
                }}
                icon={<MagnifiyGlassIcon />}
                suggestions={suggestions}
                onSuggestionSelect={handleSuggestSelect}
                onKeyUp={(event: KeyboardEvent<HTMLInputElement>) => {
                  if (event.key === 'Enter') {
                    handleSearchSubmit();
                  }
                }}
              />
            </Col>
            <Col md={1}>
              <Button size="sm" variant="primary" onClick={() => handleSearchSubmit()} data-testid="searchButton">
                Search
              </Button>
            </Col>
          </InputRow>

          <PrivateRoute exact path={path}>
            <SubscriberExplorerList />
          </PrivateRoute>

          <PrivateRoute exact path={`${path}/:id`}>
            <SubscriberDetail />
          </PrivateRoute>
        </WrapperDiv>
      </StyledContainer>
    </Switch>
  );
};
