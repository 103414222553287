import React, { useEffect, useState } from 'react';
import { Button, Spinner } from 'react-bootstrap';
import { SectionHeaderDiv, StyledContainer, StyledTextField, TenantGroupDiv, TenantListSection } from './styles';
import MagGlassIcon from '~images/mag_glass.svg';
import { useStoreState } from '~store/hooks';
import { SubTenantCard } from '~components/sub-tenant-card';
import { MEASUREMENT_INTERVAL, MEASUREMENT_TYPE } from '@pushologies/database-service/db/entities/measurement';
import { Measurements } from '~api/measurements';
import dayjs from '~helpers/dayjs';
import { calcTopMetrics } from '~pages/dashboard/user-metrics/helpers';
import { useStoreActions } from '~store/hooks';
import { Tenant } from '@pushologies/database-service/db/entities';
import { SubTenantModal } from '~components/sub-tenant-modal';

export const MultiLanding: React.FC = () => {
  const { childTenants, sessionApiKey } = useStoreState((state) => state.childTenants);
  const { resetSuperTenantState, fetchSessionApiKey } = useStoreActions((state) => state.childTenants);
  const tenantsArr = React.useMemo(() => Object.values(childTenants), [childTenants]);
  const { fetchMeasurements } = useStoreActions((state) => state.measurements);
  const [name, setName] = React.useState('');
  const [loading, setLoading] = React.useState(true);
  const [showModal, setShowModal] = React.useState(false);
  const [activeTenant, setActiveTenant] = React.useState(null);
  const [tenants, setTenants] = useState(tenantsArr);
  const [measurementsArr, setMeasurementsArr] = React.useState<Record<string, any>>({});
  const [openRate, setOpenRate] = React.useState<Record<string, any>>({});
  const [sevenDaysOpenRate, setSevenDaysOpenRate] = React.useState<Record<string, any>>({});

  const startChildTenantSession = async (tenantId: string) => {
    // ensure if our user logs into another account that super tenant state is reset
    resetSuperTenantState();
    await fetchSessionApiKey({ tenantId, regenerate: true });
    // we need to delay our session start to allow a successful state sync
    setTimeout(() => (window.location.href = '/'), 2000);
  };

  const filterTenants = (filter: string) => {
    const newTenantsArr = tenantsArr.filter((tenant) => tenant.name.toLowerCase().includes(filter.toLowerCase()));
    filter === '' ? setTenants(tenantsArr) : setTenants(newTenantsArr);
  };

  const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value);
    filterTenants(event.target.value);
  };

  const fetchDailyMeasurements = () => {
    const startAt = dayjs();
    const endAt = dayjs().subtract(27, 'hours');

    fetchMeasurements({
      to: startAt.toDate(),
      from: endAt.toDate(),
      types: [
        MEASUREMENT_TYPE.TOTAL_SUBSCRIBERS,
        MEASUREMENT_TYPE.ACTIVE_OPTED_IN_SUBSCRIBERS,
        MEASUREMENT_TYPE.TOTAL_OPTED_IN_SUBSCRIBERS
      ],
      intervals: [MEASUREMENT_INTERVAL.HOURLY],
      tenantIds: tenants.map((tenant) => tenant.id),
      onSuccess(measurements: Measurements[]) {
        const mArr: Record<string, any> = {};
        measurements.map((res) => {
          const getDayTotalSubscribers = () => {
            return (
              calcTopMetrics(MEASUREMENT_INTERVAL.HOURLY, res.measurements).activeSubscribers -
              res.measurements.slice(-1)[0]?.value
            );
          };
          mArr[res.id] = {
            ...calcTopMetrics(MEASUREMENT_INTERVAL.HOURLY, res.measurements),
            totalDaySubscribers: getDayTotalSubscribers()
          };
        });
        setMeasurementsArr(mArr);
        setLoading(false);
      }
    });
  };

  const fetchOpenRates = () => {
    const now = dayjs();
    setLoading(true);

    fetchMeasurements({
      to: now.toDate(),
      from: dayjs().subtract(5, 'years').toDate(),
      intervals: [MEASUREMENT_INTERVAL.MONTHLY],
      tenantIds: tenants.map((tenant) => tenant.id),
      types: [
        MEASUREMENT_TYPE.NOTIFICATIONS_SENT_TOTAL,
        MEASUREMENT_TYPE.NOTIFICATIONS_OPENED_UNIQUE,
        MEASUREMENT_TYPE.NOTIFICATIONS_LINK_OPENED_UNIQUE
      ],
      onSuccess(measurements: Measurements[]) {
        const measurementArr: Record<string, any> = {};

        measurements.map((res) => {
          const totalMessages = res.measurements
            .filter(
              ({ type, interval }) =>
                type === MEASUREMENT_TYPE.NOTIFICATIONS_SENT_TOTAL && interval === MEASUREMENT_INTERVAL.MONTHLY
            )
            .reduce((prev, curr) => prev + curr.value, 0);

          const opened = res.measurements
            .filter(
              ({ type, interval }) =>
                type === MEASUREMENT_TYPE.NOTIFICATIONS_OPENED_UNIQUE && interval === MEASUREMENT_INTERVAL.MONTHLY
            )
            .reduce((prev, curr) => prev + curr.value, 0);

          measurementArr[res.id] = {
            opened: Math.floor((opened / totalMessages) * 100)
          };
        });
        setOpenRate(measurementArr);
        setLoading(false);
      }
    });

    fetchMeasurements({
      to: now.toDate(),
      from: dayjs().subtract(8, 'days').toDate(),
      intervals: [MEASUREMENT_INTERVAL.DAILY],
      tenantIds: tenants.map((tenant) => tenant.id),
      types: [
        MEASUREMENT_TYPE.NOTIFICATIONS_SENT_TOTAL,
        MEASUREMENT_TYPE.NOTIFICATIONS_OPENED_UNIQUE,
        MEASUREMENT_TYPE.NOTIFICATIONS_LINK_OPENED_UNIQUE
      ],
      onSuccess(measurements: Measurements[]) {
        const measurementArr: Record<string, any> = {};

        measurements.map((res) => {
          const totalMessagesSevenDays = res.measurements
            .filter(
              ({ type, interval }) =>
                type === MEASUREMENT_TYPE.NOTIFICATIONS_SENT_TOTAL && interval === MEASUREMENT_INTERVAL.DAILY
            )
            .reduce((prev, curr) => prev + curr.value, 0);

          const openedSevenDays = res.measurements
            .filter(
              ({ type, interval }) =>
                type === MEASUREMENT_TYPE.NOTIFICATIONS_OPENED_UNIQUE && interval === MEASUREMENT_INTERVAL.DAILY
            )
            .reduce((prev, curr) => prev + curr.value, 0);

          measurementArr[res.id] = {
            openedSevenDays: Math.floor((openedSevenDays / totalMessagesSevenDays) * 100)
          };
        });
        setSevenDaysOpenRate(measurementArr);
        setLoading(false);
      }
    });
  };

  const handleModalChange = (tenant?: Tenant) => {
    if (!showModal && tenant) {
      setShowModal(true);
      setActiveTenant(tenant);
    } else setShowModal(false);
  };

  useEffect(() => {
    if (!tenants?.length) return;
    fetchDailyMeasurements();
    fetchOpenRates();
  }, [tenants]);

  useEffect(() => {
    setTenants(tenantsArr);
  }, [tenantsArr]);

  if (sessionApiKey) {
    return (
      <StyledContainer>
        <section className="dash-top-session">
          <h3>Signing you in...</h3>
        </section>
      </StyledContainer>
    );
  }

  return (
    <StyledContainer>
      <section className="dash-top">
        <h3>Easily manage subtenants and take quick actions across multiple tenants.</h3>
        <Button variant="light" size="lg" onClick={() => (window.location.href = 'notifications/create')}>
          Push Notification
        </Button>
      </section>
      <TenantListSection>
        <SectionHeaderDiv data-testid="notificationStatsHeader">
          <StyledTextField
            name="tenantsSearch"
            placeholder="Search tenants"
            value={name}
            onChange={handleNameChange}
            icon={<MagGlassIcon />}
          />

          {/* <StyledSelect id="" label="Sort" onChange={() => {}} options={[]} value={[]} inValid={false} /> */}
        </SectionHeaderDiv>
        <SubTenantModal
          show={showModal}
          testId="childTenantModal"
          tenant={activeTenant}
          measurements={measurementsArr}
          openRate={openRate}
          sevenDaysOpenRate={sevenDaysOpenRate}
          closeModal={handleModalChange}
        />
        <TenantGroupDiv>
          {tenants.length ? (
            tenants.map((tenant, id) => {
              return loading ? (
                <Spinner animation="grow" key={id} />
              ) : (
                <SubTenantCard
                  testId="childTenantCard"
                  tenant={tenant}
                  measurements={measurementsArr[tenant.id]}
                  key={tenant.id + id + Math.random()}
                  onPrimaryButtonClick={() => startChildTenantSession(tenant.id)}
                  onSecondaryButtonClick={() => handleModalChange(tenant)}
                />
              );
            })
          ) : (
            <p>You do not have any subtenant with that name</p>
          )}
        </TenantGroupDiv>
      </TenantListSection>
    </StyledContainer>
  );
};
