import React, { useMemo } from 'react';
import { useStoreState } from '~store/hooks';
import { Button } from 'react-bootstrap';
import { TextField } from '~components/inputs';
import { DateRangePicker } from '~components/inputs/date-time-picker';
import { Select, SelectOption } from '~components/select';
import MagGlassIcon from '~images/mag_glass.svg';
import { StyledFiltersRow, TitleCol, SelectsCol } from './styles';

export interface Filters {
  offset: number;
  question: string;
  createdById: SelectOption[];
  createdBetween: [Date, Date];
}

export const filtersToApi = (filters: Partial<Filters>) => ({
  ...(!!filters.offset && { offset: filters.offset }),
  ...(filters.question?.length && { question: filters.question }),
  ...(filters.createdById?.length && { createdById: filters.createdById[0].value }),
  ...(filters.createdBetween?.length && {
    createdAfter: filters.createdBetween[0],
    createdBefore: filters.createdBetween[1]
  })
});

interface Props extends Filters {
  onFilterChange(filters: Partial<Filters>): void;
  onClear(): void;
}

export const FiltersRow: React.FC<Props> = (props) => {
  const { users } = useStoreState((state) => state.users);
  const userSelectOptions: SelectOption[] = useMemo(
    () =>
      Object.values(users).map((user) => ({
        name: user.name,
        value: user.id
      })),
    [users]
  );

  const handleQuestionFilterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const question = event.target.value;
    props.onFilterChange({ question });
  };

  const handleUserFilterChange = (option: SelectOption[]) => {
    props.onFilterChange({ createdById: option });
  };

  const handleDateFiterChange = (dates: [Date, Date]) => {
    props.onFilterChange({ createdBetween: dates });
  };

  return (
    <StyledFiltersRow>
      <SelectsCol md={6}>
        <Select
          id="createdByIdSelect"
          testId="createdByIdSelect"
          value={props.createdById}
          options={userSelectOptions || []}
          placeholder="Filter User"
          inValid={false}
          onChange={handleUserFilterChange}
        />
        <DateRangePicker value={props.createdBetween} placeholder="Created Between" onChange={handleDateFiterChange} />
      </SelectsCol>
      <TitleCol md={6}>
        <Button onClick={props.onClear} data-testid="clearButton">
          Clear
        </Button>
        <TextField
          name="questionFilter"
          placeholder="Search by poll text"
          value={props.question}
          onChange={handleQuestionFilterChange}
          icon={<MagGlassIcon />}
        />
      </TitleCol>
    </StyledFiltersRow>
  );
};
