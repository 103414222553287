import React, { useMemo } from 'react';
import { useStoreState } from '~store/hooks';
import { ButtonOptions } from '~store/create-notification/types';
import { useVideoBuilderContext } from '~contexts/video-builder';
import {
  ButtonsControllerDiv,
  ButtonSliderDiv,
  ButtonReactSlider,
  ButtonSliderTrack,
  ButtonSliderThumb
} from './styles';

interface Props {
  videoDuration: number;
}

export const ButtonsController: React.FC<Props> = ({ videoDuration }) => {
  const { updateButtonDurations, activeButtonId } = useVideoBuilderContext();
  const { notification } = useStoreState((state) => state.createNotification);
  const buttons = useMemo(() => Object.values(notification.buttons), [notification.buttons]);

  const renderThumb = (props: any, state: { index: number; value: number | readonly number[] }) => (
    <ButtonSliderThumb {...props} index={state.index} />
  );

  const renderButtonSlider = (button: ButtonOptions) => {
    const handleButtonSliderChange = (values: number[]) => {
      updateButtonDurations(button.id, values);
    };
    const renderTrack = (props: any, state: { index: number; value: number }) => (
      <ButtonSliderTrack {...props} color={button.controlsColor} index={state.index} />
    );

    return (
      <ButtonSliderDiv key={button.id} isActive={activeButtonId === button.id}>
        <ButtonReactSlider
          value={[button.options.buttonAppear, button.options.buttonDisappear]}
          max={videoDuration}
          renderThumb={renderThumb}
          renderTrack={renderTrack}
          onChange={handleButtonSliderChange}
        />
      </ButtonSliderDiv>
    );
  };

  return <ButtonsControllerDiv>{buttons.map((btn) => renderButtonSlider(btn))}</ButtonsControllerDiv>;
};
