import styled from 'styled-components';
import { Row, Col } from 'react-bootstrap';
import { InputSection } from '~components/inputs/styles';
import { StyledDropdown } from '~components/select/styles';
import { TableSection } from '~components/styled/table-section';

const topPadding = '1.5rem';
const topPaddings = `calc(${topPadding} * 3)`;
const inputSectionHeight = '1.75rem';

export const StyledRow = styled(Row)`
  padding: ${topPadding} 0;

  tr:hover {
    background-color: ${({ theme }) => theme.palette.lighterGrey};
    cursor: pointer;
  }

  .selected-poll {
    border: 2px solid ${({ theme }) => theme.palette.primaryMustard} !important;
  }

  ${TableSection} {
    .table-responsive {
      max-height: ${(props) => `calc(
        100vh - 5rem - (
          ${props.theme.metrics.headerHeight} +
          ${props.theme.metrics.headerOverlayHeight} +
          ${topPaddings} +
          ${inputSectionHeight}
        )
      )`};
    }
  }
`;

export const StyledFiltersRow = styled(Row)`
  justify-content: space-between;
  padding: ${topPadding} 0 0 0;
`;

export const TitleCol = styled(Col)`
  display: flex;
  justify-content: space-between;
  padding-right: 0;

  button {
    height: ${inputSectionHeight};
    margin-right: 1rem;
    padding: 0 2.5rem;
    background: ${({ theme }) => theme.palette.lighterGrey} !important;

    &:hover {
      background: ${({ theme }) => theme.palette.textGrey} !important;
    }
  }

  ${InputSection} {
    flex-grow: 2;
    height: ${inputSectionHeight};
    min-height: unset;
  }
`;

export const SelectsCol = styled(Col)`
  display: flex;
  justify-content: space-between;
  padding: 0;
  height: ${inputSectionHeight};

  ${StyledDropdown} {
    margin-right: 1rem;

    button.dropdown-toggle {
      font-family: EavesRegular;
      font-size: 0.9rem;
      letter-spacing: 0;
    }
  }
`;

interface DeleteSpanProps {
  isDeleting: boolean;
}

export const DeleteSpan = styled.span<DeleteSpanProps>`
  svg {
    opacity: ${({ isDeleting }) => (isDeleting ? 0 : 1)};
  }

  .spinner-border {
    position: absolute;
    top: 0.15rem;
    left: 0;
    margin: 0;
    color: ${({ theme }) => theme.palette.lighterGrey};
  }
`;
