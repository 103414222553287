import { lighten, darken } from 'polished';
import { createGlobalStyle } from 'styled-components';

export const GlobalStyles = createGlobalStyle`
  body {
    margin: 0;
    padding: 0;
    background: ${({ theme }) => theme.palette.bodyBgColor};
    font-size: ${({ theme }) => theme.metrics.fontSize};
    font-family: EavesRegular;
    letter-spacing: 0.06rem;

    textarea:focus,
    textarea.form-control:focus,
    input.form-control:focus,
    input[type=text]:focus,
    input[type=password]:focus,
    input[type=email]:focus,
    input[type=number]:focus,
    input[type=file]:focus,
    [type=text].form-control:focus,
    [type=password].form-control:focus,
    [type=email].form-control:focus,
    [type=tel].form-control:focus,
    [contenteditable].form-control:focus,
    button:focus {
      box-shadow: inset 0 0 0 #ddd;
      outline: none;
    }

    button {
      background: ${({ theme }) => theme.palette.primaryPlum};
      font-weight: bold;
      text-transform: uppercase;
      box-shadow: none !important;
    }

    #page-container, #page-container-full-width {
      width: 100%;
      margin: 0;
      max-width: none;
      padding-right: 0;
      padding-top: ${({ theme: { metrics } }) => `calc(${metrics.headerOverlayHeight} + ${metrics.headerHeight})`};

      .row {
        margin-right: auto;
        margin-left: auto;
      }
    }

    #page-container-full-width {
      padding-left: 0;
    }

    a {
      &:hover {
        color: ${({ theme }) => theme.palette.primaryMustard};
        text-decoration: none;
      }
      &.disabled {
        cursor: none;
        pointer-events: none;
        color: ${({ theme }) => theme.palette.lightGrey} !important;
      }
    }

    .btn:disabled {
      &:hover {
        background-color: ${({ theme }) => lighten(0.25, theme.palette.primaryPlum)} !important;
      }
    }

    .btn-primary:disabled {
      background: ${({ theme }) => lighten(0.25, theme.palette.primaryPlum)} !important;

      &:hover {
        background: ${({ theme }) => lighten(0.75, theme.palette.primaryPlum)};
      }
    }

    .btn.btn-primary {
      display: flex;
      align-items: center;
      justify-content: center;
      border: none;
      background: ${({ theme }) => theme.palette.primaryPlum};
      transition: background .1s linear;

      &:hover {
        background: ${({ theme }) => darken(0.05, theme.palette.primaryPlum)};
      }
    }

    .form-group {
      margin-bottom: 0.5rem;

      .form-control {
        border-radius: 0;
        border-color: ${({ theme }) => theme.palette.darkGrey};

        &:focus {
          border-color: ${({ theme }) => theme.palette.primaryMustard};
        }
      }
    }

    /* ---START--- React toastify styling ---START--- */
    .Toastify__toast-container {
      right: 0;
      bottom: 1rem;
      padding: 0;
      text-transform: uppercase;
      color: ${({ theme }) => theme.palette.darkGrey};

      .Toastify__toast {
        font-family: EavesRegular;
        font-size: 1rem;
        min-height: unset;
        .Toastify__toast-body {
          margin: 0;
          display: flex;
          align-items: center;
          justify-content: flex-start;
        }
      }
      .Toastify__toast--success {
        background: ${({ theme }) => theme.palette.successSecondary};
        color: #6c757d;
        border-left: .3rem ${({ theme }) => theme.palette.successPrimary} solid;
      }
      .Toastify__toast--info {
        background: ${({ theme }) => theme.palette.infoSecondary};
        color: #6c757d;
        border-left: .3rem ${({ theme }) => theme.palette.infoPrimary} solid;
      }
      .Toastify__toast--error {
        background: ${({ theme }) => theme.palette.errorSecondary};
        color: #6c757d;
        border-left: .3rem ${({ theme }) => theme.palette.errorPrimary} solid;
      }
    }
    /* ---END--- React toastify styling ---END--- */

    /* ---START--- Google Maps autosuggest dropdown container ---START--- */
    .pac-container {
      z-index: 1100;

      .pac-item {
        span {
          color: ${({ theme }) => theme.palette.primaryPlum};
        }
      }
    }
    /* ---END--- Google Maps autosuggest dropdown container ---END--- */
  }
`;
