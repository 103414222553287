/** https://stackoverflow.com/a/4238971/2676078 */
export function placeCaretAtEnd(element: HTMLElement) {
  element.focus();
  if (typeof window.getSelection !== 'undefined' && typeof document.createRange !== 'undefined') {
    const range = document.createRange();
    range.selectNodeContents(element);
    range.collapse(false);
    const selection = window.getSelection();
    selection.removeAllRanges();
    selection.addRange(range);
  } else if (typeof (document.body as any).createTextRange !== 'undefined') {
    const textRange = (document.body as any).createTextRange();
    textRange.moveToElementText(element);
    textRange.collapse(false);
    textRange.select();
  }
}
