import styled from 'styled-components';
import { Row } from 'react-bootstrap';

export const StyledRow = styled(Row)`
  max-width: 70%;
  margin-top: 2rem;

  .nav {
    .nav-item {
      position: relative;
      flex-grow: 2;
      text-align: center;

      &:first-child {
        border-right: 1px solid ${({ theme }) => theme.palette.lighterGrey};
      }

      a {
        text-transform: uppercase;
        color: ${({ theme }) => theme.palette.textLightGrey};

        &:hover {
          color: ${({ theme }) => theme.palette.primaryMustard};
        }

        &::after {
          content: '';
          position: absolute;
          bottom: 0;
          left: 0;
          width: 100%;
          height: 1px;
          background: ${({ theme }) => theme.palette.lighterGrey};
        }

        &.active {
          color: ${({ theme }) => theme.palette.primaryPlum};

          &::after {
            height: 4px;
            background: ${({ theme }) => theme.palette.primaryPlum};
          }
        }
      }
    }

    button {
      position: relative;
      margin-left: 1.5rem;
      font-size: 1.1rem;
      padding: 0 2rem;

      &:disabled {
        color: transparent;
      }

      div {
        position: absolute;
        left: calc(50% - 0.5rem);
        top: calc(50% - 0.5rem);
        color: white !important;
      }
    }
  }

  .tab-pane {
    text-align: center;

    .spinner-border {
      position: relative;
      margin-bottom: 2rem;
      color: ${({ theme }) => theme.palette.darkGrey};
    }
  }
`;

export const StyledSection = styled.section`
  section {
    height: 2.5rem;
    margin-top: 0.5rem;
  }
`;
