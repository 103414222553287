import React, { useCallback } from 'react';
import { Form, Button, Col, Spinner } from 'react-bootstrap';
import { StyledForm, StyledFormHeader } from '~components/styled/form';
import { useStoreActions, useStoreState } from '~store/hooks';
import { formInputsToNameValueObject } from '~helpers/forms';
import AndroidIcon from '~images/android.svg';
import AppleIcon from '~images/apple.svg';
import { CredentialWrapperDiv } from './styles';
import { FileFormGroup } from './file-control';

export const UploadCol: React.FC = () => {
  const appleFormRef = React.useRef<HTMLFormElement>();
  const androidFormRef = React.useRef<HTMLFormElement>();
  const { uploadCredentials } = useStoreActions(({ credentials }) => credentials);
  const { loading } = useStoreState(({ credentials }) => credentials);
  const [isAppleValid, setIsAppleValid] = React.useState(false);
  const [isAndroidValid, setIsAndroidValid] = React.useState(false);

  const validateAppleForm = useCallback(() => {
    const { apnAuthKey, apnBundleId, apnTeamId } = formInputsToNameValueObject(appleFormRef.current, [
      'apnAuthKey',
      'apnBundleId',
      'apnTeamId'
    ]);
    const valid = !!apnAuthKey.length && !!apnBundleId.length && !!apnTeamId.length;
    setIsAppleValid(valid);
  }, []);

  const validateAndroidForm = useCallback(() => {
    const { firebase } = formInputsToNameValueObject(androidFormRef.current, ['firebase']);
    const valid = !!firebase.length;
    setIsAndroidValid(valid);
  }, []);

  const onAppleSubmit = useCallback(async (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    const { apnAuthKey, apnBundleId, apnTeamId, apnKeyId } = formInputsToNameValueObject(appleFormRef.current, [
      'apnAuthKey',
      'apnBundleId',
      'apnTeamId',
      'apnKeyId'
    ]);
    const onSuccess = () => {
      appleFormRef.current.reset();
      setIsAppleValid(false);
    };
    await uploadCredentials({ apnAuthKey: apnAuthKey[0], apnBundleId, apnTeamId, apnKeyId, onSuccess });
  }, []);

  const onAndroidSubmit = useCallback(async (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    const { firebase } = formInputsToNameValueObject(androidFormRef.current, ['firebase']);
    const onSuccess = () => {
      androidFormRef.current.reset();
      setIsAndroidValid(false);
    };
    await uploadCredentials({ firebase: firebase[0], onSuccess });
  }, []);

  return (
    <Col md="auto">
      <h1>Upload New Credentials</h1>
      <CredentialWrapperDiv>
        <AppleIcon />
        <StyledForm ref={appleFormRef}>
          <StyledFormHeader>
            Apple IOS configuration
            <Button variant="primary" disabled={!isAppleValid || loading} onClick={onAppleSubmit}>
              {loading ? <Spinner animation="border" size="sm" /> : 'save'}
            </Button>
          </StyledFormHeader>
          <FileFormGroup name="apnAuthKey" label="authorization key" onChange={validateAppleForm} />
          <Form.Group controlId="apnBundleId">
            <Form.Label>bundle id</Form.Label>
            <Form.Control type="text" name="p12-file" onChange={validateAppleForm} />
          </Form.Group>
          <Form.Group controlId="apnTeamId">
            <Form.Label>team id</Form.Label>
            <Form.Control type="text" name="apnTeamId" onChange={validateAppleForm} />
          </Form.Group>
          <Form.Group controlId="apnKeyId">
            <Form.Label>key id</Form.Label>
            <Form.Label className="secondary">
              If the original APNS authkey p8 file is not provided with original name (in format{' '}
              <code>{`AuthKey_<APN Key Id>.p8`}</code>), then you will need to explicitly provide the apn key id in this
              field
            </Form.Label>
            <Form.Control type="text" name="apnKeyId" onChange={validateAppleForm} />
          </Form.Group>
        </StyledForm>
      </CredentialWrapperDiv>
      <CredentialWrapperDiv>
        <AndroidIcon />
        <StyledForm ref={androidFormRef}>
          <StyledFormHeader>
            Google android configuration
            <Button variant="primary" disabled={!isAndroidValid || loading} onClick={onAndroidSubmit}>
              {loading ? <Spinner animation="border" size="sm" /> : 'save'}
            </Button>
          </StyledFormHeader>
          <FileFormGroup name="firebase" label="service token" onChange={validateAndroidForm} />
        </StyledForm>
      </CredentialWrapperDiv>
    </Col>
  );
};
