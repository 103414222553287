import { Column, Entity, PrimaryGeneratedColumn, ManyToOne, JoinColumn, OneToMany } from 'typeorm';
import { Tenant } from './tenant';
import { LocaleTextTranslation } from './locale-text-translation';

@Entity('locale_text')
export class LocaleText {
  @PrimaryGeneratedColumn('uuid')
  id: string;

  @Column()
  name: string;

  @OneToMany((_) => LocaleTextTranslation, (translation) => translation.parent, {
    cascade: ['insert', 'update'],
    eager: true,
    onDelete: 'CASCADE'
  })
  translations: LocaleTextTranslation[];

  @Column()
  tenantId: string;
  @ManyToOne((_) => Tenant, (tenant) => tenant.id, { onDelete: 'CASCADE' })
  @JoinColumn()
  tenant: Tenant;
}
