import styled, { css } from 'styled-components';
import { Dropdown } from 'react-bootstrap';

interface StyledDropdown {
  $inValid: boolean;
  $hasValue: boolean;
  $clearable: boolean;
}

export const StyledDropdown = styled(Dropdown)<StyledDropdown>`
  display: flex;
  align-items: center;
  width: 100%;
  border: 1px solid ${({ theme, $inValid }) => ($inValid ? 'red !important' : theme.palette.lightGrey)};
  box-sizing: border-box;

  button {
    display: flex;
    align-items: center;
    width: 100%;
    background: white;
    text-align: left;
    outline: none !important;
    height: 100%;
    white-space: nowrap;
    border: none;
    border-radius: 0 !important;
    overflow: hidden;

    &.btn {
      color: ${({ theme, $hasValue }) => ($hasValue ? theme.palette.primaryPlum : theme.palette.lightGrey)};

      span {
        color: ${({ theme, $hasValue }) => ($hasValue ? theme.palette.primaryPlum : theme.palette.lightGrey)};
        width: calc(100% - 0.75rem);

        .label {
          color: ${({ theme }) => theme.palette.lightGrey};
        }
      }

      &:after {
        position: absolute;
        right: ${({ $clearable }) => ($clearable ? 3.25 : 0.5)}rem;
        top: 50%;
        transform: translateY(-50%);
        border-top: 0.6rem solid ${({ theme }) => theme.palette.primaryMustard};
        border-right: 0.35rem solid transparent;
        border-left: 0.35rem solid transparent;
      }

      &:hover {
        background: white;
        border-color: ${({ theme }) => theme.palette.lightGrey};
        color: ${({ theme, $hasValue }) => ($hasValue ? theme.palette.primaryPlum : theme.palette.darkGrey)};
      }
    }

    &:disabled {
      background: white;
      border: 1px solid ${({ theme }) => theme.palette.lightGrey} !important;

      &.btn {
        &:after {
          border-top-color: ${({ theme }) => theme.palette.lightGrey};
        }
      }

      &:hover {
        background: white !important;
      }
    }

    span {
      color: ${({ theme }) => theme.palette.textLightGrey};
      padding-right: 0.2rem;
    }
  }

  &.show {
    .btn {
      border: 1px solid ${({ theme }) => theme.palette.primaryMustard};
      color: ${({ theme, $hasValue }) => ($hasValue ? theme.palette.primaryPlum : theme.palette.lightGrey)};
      background: white;

      &:hover {
        border-color: ${({ theme }) => theme.palette.primaryMustard};
        color: ${({ theme, $hasValue }) => ($hasValue ? theme.palette.primaryPlum : theme.palette.darkGrey)};
      }
    }
  }
`;

export const ClearableSpan = styled.span<{ disabled: boolean }>`
  background: ${({ theme }) => theme.palette.lightGrey};
  width: 3.5rem;
  margin: 0 0.2rem;
  cursor: pointer;
  color: white;
  line-height: 0.95rem;
  text-transform: uppercase;
  font-size: 0.65rem;
  text-align: center;
  border-radius: 0.1rem;
  transition: background 0.15s linear;

  &:hover {
    background: ${({ theme }) => theme.palette.platinum};
  }

  ${({ disabled }) =>
    disabled &&
    css`
      background: ${({ theme }) => theme.palette.lighterGrey} !important;
      cursor: default !important;
      pointer-events: none;
    `}
`;

export const StyledDropdownMenu = styled(Dropdown.Menu)`
  border: 1px solid ${({ theme }) => theme.palette.primaryMustard};
  border-radius: 0;
  padding-bottom: 0;
  margin: 0.2rem 0;
  box-shadow: 10px 10px 15px -7px rgba(0, 0, 0, 0.2);
  min-width: 100%;
  max-height: 50vh;
  overflow-y: scroll;

  span.bottom-caret-placeholder {
    position: relative;
    display: block;
    height: 1rem;
  }
`;

interface DropdownItemProps {
  $selected?: boolean;
}

export const DropdownItem = styled(Dropdown.Item)<DropdownItemProps>`
  position: relative;
  padding: 0.5rem 1.25rem 0.5rem 0.5rem;
  color: ${({ theme, $selected }) => ($selected ? theme.palette.primaryMustard : theme.palette.lightGrey)};
  font-size: 0.9rem;
  border: none;
  white-space: nowrap;
  overflow-x: hidden;

  &:after {
    content: '';
    position: absolute;
    width: 0.75rem;
    height: 0.75rem;
    border-radius: 0.375rem;
    right: 0.25rem;
    top: 50%;
    transform: translateY(-50%);
    background: ${({ theme, $selected }) => ($selected ? theme.palette.primaryMustard : theme.palette.lightGrey)};
  }

  &:hover {
    color: ${({ theme }) => theme.palette.primaryMustard};
    cursor: pointer;

    &:after {
      background: ${({ theme }) => theme.palette.primaryMustard};
    }
  }
`;
