import {
  Entity,
  PrimaryGeneratedColumn,
  Column,
  CreateDateColumn,
  ManyToOne,
  OneToMany,
  JoinColumn,
  Index,
  Unique
} from 'typeorm';
import {
  CONTENT_ITEM_SOURCE_TYPE,
  CONTENT_ITEM_MEDIA_TYPE,
  CONTENT_ITEM_PRESENTATION_TYPE
} from '@pushologies/common/constants/content-item';
import { Tenant } from './tenant';
import { Content } from './content';
import { TenantUser } from './tenant-user';

@Entity('content_item')
@Index(['id', 'tenant'])
@Unique('content_item_unique_name_tenant', ['name', 'tenant'])
@Index(['tenant', 'presentation'])
@Index(['id', 'tenant', 'presentation'])
@Index(['name', 'tenant', 'sourceType', 's3Uploaded'])
@Index(['name', 'tenant', 'sourceType', 's3Uploaded', 's3File'])
@Index(['id', 'tenant', 'sourceType', 'externalSourceUrl'])
@Index(['sourceType', 's3Uploaded', 'createdAt'])
@Index(['tenant', 's3File'])
export class ContentItem {
  @PrimaryGeneratedColumn('uuid')
  id: string;

  @Column({ type: 'varchar' })
  name: string;

  @Column({ name: 'source_type', type: 'enum', enum: CONTENT_ITEM_SOURCE_TYPE })
  sourceType: CONTENT_ITEM_SOURCE_TYPE;

  @Column({ name: 'media_type', type: 'enum', enum: CONTENT_ITEM_MEDIA_TYPE })
  mediaType: CONTENT_ITEM_MEDIA_TYPE;

  @Column({ name: 's3_file', type: 'text', nullable: true })
  s3File: string;

  @Column({ name: 's3_uploaded', type: 'boolean', default: false })
  s3Uploaded: boolean;

  @Column({ name: 'external_source_url', type: 'text', nullable: true })
  externalSourceUrl: string;

  @Column({ type: 'text', nullable: true })
  metadata: string;

  @Column({ type: 'integer', nullable: true })
  size: number;

  @Column({ type: 'varchar', name: 'mime_type', nullable: true })
  mimeType: string;

  @Column({ type: 'integer', default: -1 })
  ttl: number;

  @OneToMany((_) => Content, (content) => content.contentItem)
  contents: Content[];

  @Column()
  tenantId: string;
  @ManyToOne((_) => Tenant, (tenant) => tenant.id, { onDelete: 'CASCADE' })
  @JoinColumn()
  @Index()
  tenant: Tenant;

  @Column({ nullable: true })
  createdById: string;
  @ManyToOne((_) => TenantUser, { onDelete: 'SET NULL' })
  @JoinColumn()
  @Index()
  createdBy: TenantUser;

  @CreateDateColumn({ name: 'created_at', type: 'timestamptz' })
  @Index()
  createdAt: Date;

  downloadUrl: string;

  @Column({
    name: 'presentation',
    type: 'simple-array',
    default: [CONTENT_ITEM_PRESENTATION_TYPE.DEFAULT]
  })
  presentation: CONTENT_ITEM_PRESENTATION_TYPE[];

  @Column({ name: 'has_thumbnail', type: 'boolean', default: false })
  hasThumbnail: boolean;

  @Column({ type: 'bytea', select: false, nullable: true })
  thumbnail: Buffer;
  thumbnailUrl: string;

  @Column({ name: 'video_duration', type: 'float', nullable: true })
  videoDuration: number;

  @Column({ type: 'int', nullable: true })
  width?: number;

  @Column({ type: 'int', nullable: true })
  height?: number;
}
