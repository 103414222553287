import React from 'react';
import ISO6391 from 'iso-639-1';
import { throttle } from 'throttle-debounce';
import { Rule } from '~store/create-segment/types';
import { AutosuggestField, Suggestion } from '~components/inputs/autosuggest-field';
import MagnifiyGlassIcon from '~images/mag_glass.svg';

interface Props {
  rule: Rule;
  invalid: boolean;
  onChange(value: string): void;
}

export const DeviceLanguageSelect: React.FC<Props> = ({ rule, invalid, onChange }) => {
  const [suggestions, setSuggestions] = React.useState<Suggestion<string>[]>([]);

  const handleSuggestSelect = (text: string) => {
    setSuggestions([]);
    onChange(text);
  };

  const handleOnChange = (text: string) => {
    if (text.length < 2) {
      setSuggestions([]);
      return;
    }

    const options = ISO6391.getLanguages(ISO6391.getAllCodes()).filter((item) =>
      item.name.toLowerCase().match(text.toLowerCase())
    );
    throttle(250, () => {
      setSuggestions(
        options.map(({ name, code }) => ({
          label: name,
          value: code
        }))
      );
    })();
  };

  return (
    <AutosuggestField
      name={`${rule.segmentRule.ruleId}Select`}
      placeholder="search by language"
      onChange={handleOnChange}
      icon={<MagnifiyGlassIcon />}
      suggestions={suggestions}
      onSuggestionSelect={handleSuggestSelect}
      invalid={invalid}
      selectedSuggestionNamePath="default"
      stringValue={rule.segmentRule.value}
    />
  );
};
