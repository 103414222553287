import { SideNavLinks } from '~store/sidenav/types';

export const segmentsPageLinks: SideNavLinks[] = [
  {
    name: 'View All',
    path: '/segments'
  },
  {
    name: 'Create Segment',
    path: '/segments/create'
  }
];
