import dayjs from 'dayjs';
import localizedPlugin from 'dayjs/plugin/localizedFormat';
import advancedFormatPlugin from 'dayjs/plugin/advancedFormat';
import utcPlugin from 'dayjs/plugin/utc';

dayjs.extend(utcPlugin);
dayjs.extend(localizedPlugin);
dayjs.extend(advancedFormatPlugin);

export default (date?: dayjs.ConfigType) => dayjs(date).utc(true);
