import React, { MutableRefObject } from 'react';

/**
 * Hook to run a callback when user clicks away from a certain element (given via a mutable
 * ref object as first argument)
 */
export function useClickAway(ref: MutableRefObject<any>, callback: (e: Event) => void) {
  const handleClickAwayEvent = (e: Event) => {
    if (ref.current && !ref.current.contains(e.target)) {
      callback(e);
    }
  };

  React.useEffect(() => {
    document.addEventListener('click', handleClickAwayEvent);
    return () => document.removeEventListener('click', handleClickAwayEvent);
  }, [callback]);
}
