import React from 'react';
import { useHistory } from 'react-router';
import { Table } from 'react-bootstrap';
import { StyledContainer } from '~components/styled/container';
import dayjs from '~helpers/dayjs';
import { useStoreActions, useStoreState } from '~store/hooks';
import { TableSection } from '~components/styled/table-section';
import { Pagination } from '~components/pagination';
import { Select, SelectOption } from '~components/select';
import { LIMIT } from '~store/notifications';
import { ApiNotification } from '~api/notifications/types';
import { Loader } from '~components/loader';
import { notificationsPageLinks } from '../links';
import { TenantSelectDiv, StyledRow } from './styles';
import { NotificationRow } from './row';
import { FiltersRow, Filters, filtersToApi } from './filters';
import { throttle } from 'throttle-debounce';
import { NOTIFICATION_TYPE } from '@pushologies/database-service/db/entities/notification';
import { isNotificationsPage } from '../helpers';
import { useRouteMatch } from 'react-router-dom';

const SUPER_TENANT_SELECTION = 'pushologies:selectedChildTenant';

interface Props {
  type?: NOTIFICATION_TYPE;
}

export const ListNotifications: React.FC<Props> = ({ type }) => {
  const { visibility } = useStoreState(({ sideNav }) => sideNav);
  const history = useHistory();
  const [loading, setLoading] = React.useState(true);
  const { childTenants, isSuperTenant } = useStoreState((state) => state.childTenants);
  const [selectedTenant, setSelectedTenant] = React.useState<SelectOption[]>([]);
  const [notifications, setNotifications] = React.useState<ApiNotification[]>([]);
  const { setSideNavModel } = useStoreActions((state) => state.sideNav);
  const { fetchNotifications } = useStoreActions((state) => state.notifications);
  const { users } = useStoreState((state) => state.users);
  const [totalItems, setTotalItems] = React.useState(10);
  const [offset, setOffset] = React.useState(0);
  const [title, setTitle] = React.useState('');
  const [createdById, setCreatedById] = React.useState<SelectOption[]>([]);
  const [pushed, setPushed] = React.useState<SelectOption<boolean>[]>([]);
  const [createdBetween, setCreatedBetween] = React.useState<[Date, Date]>([] as any);
  const router = useRouteMatch();
  const isOnNotificationPage = isNotificationsPage(router);
  // Declaring the variable and assigning the required objects within the array as an filtering option.
  const types = isOnNotificationPage
    ? Object.values(NOTIFICATION_TYPE).filter((eachType) => ![NOTIFICATION_TYPE.INAPP].includes(eachType))
    : [NOTIFICATION_TYPE.INAPP];

  const handleFilterChange = (filters: Partial<Filters>) => {
    setLoading(true);

    filters.hasOwnProperty('createdById') && setCreatedById(filters.createdById);
    filters.hasOwnProperty('pushed') && setPushed(filters.pushed);
    filters.hasOwnProperty('createdBetween') && setCreatedBetween(filters.createdBetween);
    const titleChanged = filters.hasOwnProperty('title');
    titleChanged && setTitle(filters.title);
    // if filters above have been changed reset offset to 0 otherwise to changed offset value
    filters.hasOwnProperty('offset') ? setOffset(filters.offset) : setOffset(0);

    throttle(
      titleChanged ? 250 : 0,
      fetchNotifications({
        limit: LIMIT,
        types,
        includeCreatedByData: true,
        ...(selectedTenant[0] && { tenantId: selectedTenant[0].value }),
        ...filtersToApi({ offset, title, createdBetween, createdById, pushed, ...filters }),
        onSuccess(newNotifications, newTotalItems) {
          setNotifications(newNotifications);
          setTotalItems(newTotalItems);
          setLoading(false);
        }
      })
    );
  };

  const handlePageChange = ({ selected }: { selected: number }) => {
    const newOffset = selected * LIMIT;
    handleFilterChange({ offset: newOffset });
  };

  const deleteNotification = (notificationId: string) => () => {
    const index = notifications.findIndex(({ id }) => id === notificationId);
    setNotifications([...notifications.slice(0, index), ...notifications.slice(index + 1)]);
  };

  const handleClear = () => {
    setLoading(true);
    setOffset(0);
    setCreatedById([]);
    setPushed([]);
    setCreatedBetween([] as any);
    setTitle('');
    history.push({ search: null });

    fetchNotifications({
      limit: LIMIT,
      types,
      includeCreatedByData: true,
      ...(selectedTenant[0] && { tenantId: selectedTenant[0].value }),
      onSuccess(newNotifications, newTotalItems) {
        setNotifications(newNotifications);
        setTotalItems(newTotalItems);
        setLoading(false);
      }
    });
  };

  const queryParamsToFilters = () => {
    const filters: Partial<Filters> = {};

    const params = new URLSearchParams(history.location.search) as any;
    for (const [key, value] of params.entries()) {
      switch (key) {
        case 'title':
          filters.title = value;
          setTitle(filters.title);
          break;
        case 'pushed':
          const bool = value === 'true' ? true : false;
          filters.pushed = [{ name: 'Pushed', value: bool }];
          setPushed(filters.pushed);
          break;
        case 'createdById':
          filters.createdById = [{ name: users[value].name, value: users[value].id }];
          setCreatedById(filters.createdById);
          break;
        case 'createdBetween':
          const [from, to] = value.split(',');
          filters.createdBetween = [new Date(from), new Date(to)];
          setCreatedBetween(filters.createdBetween);
          break;
        case 'offset':
          filters.offset = value;
          setOffset(filters.offset);
          break;
        default:
          break;
      }
    }

    return filters;
  };

  React.useLayoutEffect(() => {
    const params: string[] = [];

    createdById.length && params.push(`createdById=${createdById[0].value}`);
    pushed.length && params.push(`pushed=${pushed[0].value}`);
    createdBetween.length &&
      params.push(`createdBetween=${createdBetween[0].toISOString()},${createdBetween[1].toISOString()}`);
    title.length && params.push(`title=${title}`);
    !!offset && params.push(`offset=${offset}`);

    if (params.length) history.push({ search: `?${params.join('&')}` });
    else if (history.action !== 'POP') history.push({ search: null });
  }, [offset, title, createdBetween, createdById, pushed]);

  React.useEffect(() => {
    setSideNavModel({
      topBarLeftTitle: type && type === NOTIFICATION_TYPE.INAPP ? 'InApp Notifications' : 'Notifications',
      activeChild: 'listNotifications',
      links: notificationsPageLinks
    });
    fetchNotifications({
      limit: LIMIT,
      types,
      includeCreatedByData: true,
      ...(selectedTenant[0] && { tenantId: selectedTenant[0].value }),
      ...filtersToApi(queryParamsToFilters()),
      onSuccess(newNotifications, newTotalItems) {
        setNotifications(newNotifications);
        setTotalItems(newTotalItems);
        setLoading(false);
      }
    });
  }, [type]);

  const tenantOptions: SelectOption[] = React.useMemo(
    () =>
      Object.values(childTenants || {}).map(({ id, name }) => ({
        name,
        value: id
      })),
    [childTenants]
  );

  const handleTenantOnChange = (selected: SelectOption[]) => {
    if (selected[0]) {
      localStorage.setItem(SUPER_TENANT_SELECTION, selected[0].value);
    }
    setSelectedTenant(selected);
  };

  React.useEffect(() => {
    // set initial value
    const savedTenantSelection = localStorage.getItem(SUPER_TENANT_SELECTION);

    !selectedTenant.length &&
      tenantOptions.length &&
      setSelectedTenant([
        savedTenantSelection
          ? tenantOptions.find((eachOption) => eachOption.value === savedTenantSelection)
          : tenantOptions[0]
      ]);
  }, [tenantOptions]);

  React.useEffect(() => {
    if (!isSuperTenant || !selectedTenant[0]) return;

    fetchNotifications({
      limit: LIMIT,
      types,
      includeCreatedByData: true,
      ...(selectedTenant[0] && { tenantId: selectedTenant[0].value }),
      ...filtersToApi(queryParamsToFilters()),
      onSuccess(newNotifications, newTotalItems) {
        setNotifications(newNotifications);
        setTotalItems(newTotalItems);
        setLoading(false);
      }
    });
  }, [isSuperTenant, selectedTenant]);

  return (
    <StyledContainer $sideNavVisible={visibility === 'show'}>
      {isSuperTenant && (
        <TenantSelectDiv>
          <h4>Select Tenant to view corresponding notifications</h4>
          <Select
            id="tenantNotificationSelect"
            testId="tenantNotificationSelect"
            label="Tenant"
            value={selectedTenant}
            options={tenantOptions}
            onChange={handleTenantOnChange}
            drop="down"
            inValid={false}
          />
        </TenantSelectDiv>
      )}

      <FiltersRow
        title={title}
        pushed={pushed}
        createdBetween={createdBetween}
        createdById={createdById}
        offset={offset}
        onFilterChange={handleFilterChange}
        onClear={handleClear}
      />
      <StyledRow>
        <TableSection>
          <Loader loading={loading} backdrop />
          <Table responsive>
            <thead>
              <tr>
                <th style={{ width: '5%' }}>Type</th>
                <th>Title</th>
                <th>SubTitle</th>
                <th style={{ width: '15%' }}>Pushed At</th>
                <th style={{ width: '15%' }}>Created At</th>
                <th style={{ width: '10%' }}>Created By</th>
                <th style={{ width: '10%' }}>Actions</th>
              </tr>
            </thead>
            <tbody>
              {notifications
                .sort((a, b) => dayjs(b.createdAt).diff(a.createdAt))
                .map((notification) => (
                  <NotificationRow
                    key={notification.id}
                    notification={notification}
                    tenantId={isSuperTenant ? selectedTenant[0].value : null}
                    onDeleteSuccess={deleteNotification(notification.id)}
                  />
                ))}
            </tbody>
          </Table>
          <Pagination
            pageCount={Math.ceil(totalItems / LIMIT)}
            marginPagesDisplayed={5}
            pageRangeDisplayed={4}
            forcePage={offset / LIMIT}
            breakLabel="..."
            onPageChange={handlePageChange}
          />
        </TableSection>
      </StyledRow>
    </StyledContainer>
  );
};
