import React, { useMemo } from 'react';
import { OperatorValue as Op } from '@pushologies/segments-service/types';
import { Rule } from '~store/create-segment/types';
import { DateTimePicker, DateTimeRangePicker } from '~components/inputs/date-time-picker';
import { NumberField } from '~components/inputs/number-field';
import { ChipField } from '~components/inputs/chip-field';
import { Location } from '~components/inputs/location';
import { useStoreActions } from '~store/hooks';
import { isInvalidValue } from './validation';
import { DeviceSelect } from './device-select';
import { DeviceLanguageSelect } from './device-language-select';
import { CountryCodeChipfield } from './country-code-chip-field';
import { LocationPermissionSelect } from './location-permission-select';
import { NotificationPermissionSelect } from './notification-permission-select';
import { NotificationStatusSelect } from './notification-status-select';
import { TagsChipfield } from './tags-chip-field';
import { LocationRuleInput } from './location';

interface Props {
  rule: Rule;
}

export const ValueInput: React.FC<Props> = ({ rule }) => {
  const { setRuleValue } = useStoreActions(({ createSegment }) => createSegment);

  const handleNumberOnChange = (value: number) => {
    setRuleValue({
      id: rule.id,
      value
    });
  };

  const handleStringArrayOnChange = (value: string[]) => {
    setRuleValue({
      id: rule.id,
      value
    });
  };

  const handleBooleanOnChange = (value: boolean) => {
    setRuleValue({
      id: rule.id,
      value
    });
  };

  const handleNotificationStatusOnChange = (value: object) => {
    setRuleValue({
      id: rule.id,
      value
    });
  };

  const handleDatePickerChange = (date: Date) => {
    setRuleValue({
      id: rule.id,
      value: { date }
    });
  };

  const handleDateRangePickerChange = ([from, to]: [Date, Date]) => {
    setRuleValue({
      id: rule.id,
      value: { from, to }
    });
  };

  const handleDeviceLanguageOnChange = (value: string) => {
    setRuleValue({
      id: rule.id,
      value
    });
  };

  const handleDeviceLocationOnChange = (value: Location) => {
    setRuleValue({
      id: rule.id,
      value
    });
  };

  // tslint:disable-next-line: cyclomatic-complexity
  return useMemo(() => {
    switch (rule.segmentRule.ruleId) {
      case 'duration':
        return (
          <NumberField
            value={rule.segmentRule.value}
            onChange={handleNumberOnChange}
            min={0}
            placeholder="seconds"
            invalid={isInvalidValue(rule.segmentRule)}
          />
        );
      case 'device':
        return (
          <DeviceSelect rule={rule} onChange={handleStringArrayOnChange} invalid={isInvalidValue(rule.segmentRule)} />
        );
      case 'deviceLanguage':
        return (
          <DeviceLanguageSelect
            rule={rule}
            onChange={handleDeviceLanguageOnChange}
            invalid={isInvalidValue(rule.segmentRule)}
          />
        );
      case 'firstSession':
      case 'lastSession':
        return rule.segmentRule.operator === Op.between ? (
          <DateTimeRangePicker
            value={[rule.segmentRule.value?.from, rule.segmentRule.value?.to]}
            onChange={handleDateRangePickerChange}
            invalid={isInvalidValue(rule.segmentRule)}
          />
        ) : (
          <DateTimePicker
            value={rule.segmentRule.value?.date}
            onChange={handleDatePickerChange}
            invalid={isInvalidValue(rule.segmentRule)}
          />
        );
      case 'appVersion':
        return (
          <ChipField
            chips={rule.segmentRule.value || []}
            onChange={handleStringArrayOnChange}
            placeholder="Press enter to add version tag"
            invalid={isInvalidValue(rule.segmentRule)}
            testId="appVersionChipInput"
          />
        );
      case 'subscriberIds':
        return (
          <ChipField
            chips={rule.segmentRule.value || []}
            onChange={handleStringArrayOnChange}
            placeholder="Type id and press enter"
            invalid={isInvalidValue(rule.segmentRule)}
            testId="subscriberIdsChipInput"
          />
        );
      case 'sessionCounts':
        return (
          <NumberField
            value={rule.segmentRule.value}
            onChange={handleNumberOnChange}
            min={0}
            placeholder="seconds"
            invalid={isInvalidValue(rule.segmentRule)}
          />
        );
      case 'deviceIds':
        return (
          <ChipField
            chips={rule.segmentRule.value || []}
            onChange={handleStringArrayOnChange}
            placeholder="Type id and press enter"
            invalid={isInvalidValue(rule.segmentRule)}
            testId="deviceIdsChipInput"
          />
        );
      case 'customerIds':
        return (
          <ChipField
            chips={rule.segmentRule.value || []}
            onChange={handleStringArrayOnChange}
            placeholder="Type id and press enter"
            invalid={isInvalidValue(rule.segmentRule)}
            testId="customerIdsChipInput"
          />
        );
      case 'tags':
        return <TagsChipfield rule={rule} onChange={handleStringArrayOnChange} />;

      case 'location':
        return (
          <LocationRuleInput
            rule={rule}
            onChange={handleDeviceLocationOnChange}
            invalid={isInvalidValue(rule.segmentRule)}
          />
        );
      case 'locationPermission':
        return (
          <LocationPermissionSelect
            rule={rule}
            onChange={handleBooleanOnChange}
            invalid={isInvalidValue(rule.segmentRule)}
          />
        );
      case 'countryCode':
        return (
          <CountryCodeChipfield
            rule={rule}
            onChange={handleStringArrayOnChange}
            invalid={isInvalidValue(rule.segmentRule)}
          />
        );
      case 'notificationPermission':
        return (
          <NotificationPermissionSelect
            rule={rule}
            onChange={handleBooleanOnChange}
            invalid={isInvalidValue(rule.segmentRule)}
          />
        );
      case 'notificationPushStatus':
        return (
          <NotificationStatusSelect
            rule={rule}
            onChange={handleNotificationStatusOnChange}
            invalid={isInvalidValue(rule.segmentRule)}
          />
        );
      default:
        return <></>;
    }
  }, [rule.segmentRule]);
};
