import { Action, Thunk, Computed } from 'easy-peasy';
import { SEGMENT_DYNAMISM } from '@pushologies/database-service/db/entities/segment';
import { SegmentRule, OperatorValue } from '@pushologies/segments-service/types';
import { ApiSegment } from '~api/segments/types';
import { Injections, StoreModel } from '..';

export interface Rule<T = any> {
  id: string;
  segmentRule: SegmentRule<T>;
}

interface SetOperator extends Pick<Rule, 'id'> {
  operator: OperatorValue;
}

interface SetValue {
  id: string;
  value: any;
}

interface NewSegment {
  id?: string;
  name: string;
  description?: string;
  rules: Rule[];
  dynamism: SEGMENT_DYNAMISM;
}

export enum IdKeys {
  SUBSCRIBER = 'subscriberIds',
  DEVICE = 'deviceIds',
  CUSTOMER = 'customerIds'
}

export type ImportedIds = Partial<Record<IdKeys, string[]>>;

export interface CreateSegmentModel extends NewSegment {
  createSegmentLoading: boolean;
  invalidRules: string[];
  setModel: Action<
    CreateSegmentModel,
    Partial<Pick<CreateSegmentModel, 'dynamism' | 'name' | 'description' | 'createSegmentLoading' | 'id' | 'rules'>>
  >;
  resetModel: Action<CreateSegmentModel>;
  addRules: Action<CreateSegmentModel, SegmentRule[]>;
  deleteRule: Action<CreateSegmentModel, string>;
  setRuleOperator: Action<CreateSegmentModel, SetOperator>;
  setRuleValue: Action<CreateSegmentModel, SetValue>;
  addInvalidRule: Action<CreateSegmentModel, string>;
  removeInvalidRule: Action<CreateSegmentModel, string>;
  isInvalid: Computed<CreateSegmentModel, boolean>;
  hasInvalidRules: Computed<CreateSegmentModel, boolean>;
  saveSegment: Thunk<CreateSegmentModel, void | { onSuccess(segment: ApiSegment): void }, Injections, StoreModel>;
  persistSegment: Thunk<CreateSegmentModel, { id: string; onComplete?: () => void }, Injections, StoreModel>;
}
