import {
  PersonalisationConfigItemType,
  PersonalisationConfigItem
} from '@pushologies/database-service/db/entities/notification';
import { decode } from 'html-entities';
import { PersonalisationValueItem, PersonalisationItem } from './types';
import { ApiPersonalisationVariable } from '~api/personalisation-variables';
import { buildPersonalisationVariableMap } from '~helpers/personalisation';

export const stringToPersonalisationValue = (text: string, personalisationConfigItems: PersonalisationConfigItem[]) => {
  let activeIndex = 0;
  const str = text ?? '';
  const value: PersonalisationValueItem[] = [];
  const personalisationConfig = buildPersonalisationVariableMap(personalisationConfigItems);

  for (const match of str.matchAll(/\{\{(.*?)\}\}/g)) {
    match.index !== 0 && value.push(match.input.substring(activeIndex, match.index));
    value.push({
      id: match[1],
      config: personalisationConfig[match[1]]
    });
    activeIndex = match.input.indexOf('}}', match.index) + 2;
  }

  // add remaining text to value array
  activeIndex !== str.length && value.push(str.substring(activeIndex));

  // if personalisation array is last element add empty string afterwards to allow edits after it
  if (typeof value[value.length - 1] !== 'string') {
    value.push('');
  }

  return value;
};

export const formatLineBreak = (text: string) => {
  return text.replace(/<br>|<div><br><\/div>|<div>/g, ' ').replace(/<\/div>/g, '');
};

export const valueArrayToString = (value: PersonalisationValueItem[]): string => {
  const newText = value.reduce((str, curr) => {
    if (isStringValue(curr)) {
      str += formatLineBreak(decode(curr));
    } else {
      str += `{{${curr.id}}}`;
    }
    return str;
  }, '') as string;

  return newText;
};

/**
 * Construct options which will appear in dropdown if personalisation-value is not complete
 * From a personalisation value text (e.g. "subscriber.countryCode") get the last input string and
 * filter results by that string
 */
export const getPersonalisationDropdownOptions = (
  search: string,
  personalisationVariables: ApiPersonalisationVariable[]
) => {
  return personalisationVariables
    .filter(({ name }) => !!name.toLowerCase().includes(search.toLowerCase()))
    .map((value) => ({ key: value.name, value }));
};

export const isStringValue = (value: PersonalisationValueItem): value is string => typeof value === 'string';
export const isPersonalisationItemValue = (value: PersonalisationValueItem): value is PersonalisationItem =>
  !!value && typeof value === 'object' && value?.hasOwnProperty('id') && value?.hasOwnProperty('config');
export const isPersonalisationConfigComplete = (config: PersonalisationConfigItemType) =>
  !!config?.name && !!config?.defaultValue;
