import styled from 'styled-components';
import { Nav } from 'react-bootstrap';
import ArrowLeftIcon from '~images/arrow_left.svg';
import { darken } from 'polished';

interface StyledSideNavProps {
  $expanded: boolean;
}

export const StyledSideNav = styled(Nav)<StyledSideNavProps>`
  width: ${({ theme }) => theme.metrics.sideNavWidth};
  position: fixed;
  height: 100vh;
  display: flex;
  text-transform: uppercase;
  color: ${(props) => props.theme.palette.darkGrey};
  background: ${(props) => props.theme.palette.bodyBgColor};
  box-shadow: -10px 0px 40px 0px rgba(0, 0, 0, 0.35);
  padding-top: 16rem;
  z-index: 100;
  transform: ${(props) => (props.$expanded ? `translateX(0px)` : `translateX(-15vw)`)};
  transition: 300ms ease-in-out !important;

  h1 {
    font-size: 1.25rem;
    font-family: EavesBold;
  }
`;

export const SideNavHidden = styled.div`
  display: flex;
`;

export const SideNavToggle = styled.div`
  width: 60px;
  background-color: red;
  height: 10vh;
  display: flex;
`;

export const StyledTopBar = styled.section`
  position: fixed;
  display: flex;
  justify-content: space-between;
  top: ${({ theme }) => theme.metrics.headerHeight};
  width: 100vw;
  height: ${({ theme }) => theme.metrics.headerOverlayHeight};
  background: ${({ theme }) => theme.palette.primaryPlum};
  opacity: 0.8;
  z-index: 250;
  padding: 0 1.5rem;
`;

export const TitleDiv = styled.div`
  height: ${({ theme }) => theme.metrics.headerOverlayHeight};
  position: relative;
  z-index: 200;
  display: flex;
  align-items: center;

  p {
    color: white;
    text-transform: uppercase;
    margin: 0;
  }
  &.warning p {
    color: ${({ theme }) => theme.palette.primaryMustard};
  }

  h2 {
    color: white;
    text-transform: uppercase;
    margin: 0;
    font-family: EavesBold;
    font-size: 1.75rem;
  }
`;

export const ChildrenDiv = styled.div`
  position: relative;
  display: block;
  padding-left: 1.5rem;
  padding-right: 1rem;
  overflow-wrap: anywhere;
`;

export const LinksDiv = styled.div`
  position: absolute;
  bottom: 1rem;
  padding: 0 1rem;
  width: 100%;
  text-align: center;

  a {
    display: block;
    margin: 0.5rem 0;
    background: ${({ theme }) => theme.palette.darkGrey};
    color: ${({ theme }) => theme.palette.textGrey};
    text-transform: uppercase;
    font-size: 1rem;
    border-right-width: 7px;
    border-right: solid;
    border-right-color: ${({ theme }) => theme.palette.textGrey};
    border-radius: 1.25rem;

    &.active {
      border-right-color: ${({ theme }) => theme.palette.primaryPlum};
      color: ${({ theme }) => theme.palette.primaryPlum} !important;
    }
    &:hover {
      color: ${({ theme }) => theme.palette.textGrey};
      border-right-color: ${({ theme }) => theme.palette.primaryPlum};
    }
  }
`;

interface StyledArrowIconProps {
  $flip: boolean;
}

export const StyledArrowIcon = styled(ArrowLeftIcon)<StyledArrowIconProps>`
  position: absolute;
  width: 1.25rem;
  height: 1.25rem;
  right: 5px;
  top: 105px;
  transform: rotate(${({ $flip }) => ($flip ? `180deg` : 0)});
  cursor: pointer;

  path {
    fill: ${({ theme }) => theme.palette.primaryMustard};
  }

  &:hover {
    path {
      fill: ${({ theme }) => darken(0.2, theme.palette.primaryMustard)};
    }
  }
`;
