import styled from 'styled-components';

export const SideNavWrapperDiv = styled.div`
  a {
    color: ${({ theme }) => theme.palette.primaryMustard};
  }
`;

export const SideNavTitle = styled.p`
  font-family: EavesBold;
  font-size: 2rem;
  margin: 2.9rem 0 2rem;
`;
