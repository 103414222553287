import React from 'react';
import { Redirect, Switch, useRouteMatch } from 'react-router-dom';
import { PrivateRoute } from 'src/routes/private-route';
import { CreateSegment } from './create';
import { useStoreActions, useStoreState } from '~store/hooks';
import { segmentsPageLinks as links } from './links';
import { ListSegments } from './list';
import { userHasRequiredRole } from '../../role-guard';

export const Segments: React.FC = () => {
  const { path } = useRouteMatch();
  const { setSideNavModel } = useStoreActions(({ sideNav }) => sideNav);
  const tenant = useStoreState((state) => state.tenant.tenant);

  React.useEffect(() => {
    setSideNavModel({ topBarLeftTitle: 'segments', activeChild: 'segments', links });
  }, []);

  // we only want to render the segments /create route if the user is a creator
  // there is a problem where the user will start with no role, causing us to hit the /:id route
  // we should not try and render routes until we have fetched the role
  const userRole = tenant?.userProfile?.role?.name;
  if (!userRole) return <></>;
  const isCreator = userHasRequiredRole(userRole, 'Creator');

  return (
    <Switch>
      <PrivateRoute exact path={`${path}`}>
        <ListSegments />
      </PrivateRoute>

      {isCreator && (
        <PrivateRoute exact path={`${path}/create`}>
          <CreateSegment />
        </PrivateRoute>
      )}

      {isCreator && (
        <PrivateRoute exact path={`${path}/create/:id`}>
          <CreateSegment />
        </PrivateRoute>
      )}
      <Redirect to="/404" />
    </Switch>
  );
};
