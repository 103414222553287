import styled from 'styled-components';

export const InfoDiv = styled.div`
  padding: 0.5rem 2rem;
  background-color: rgba(249, 175, 5, 0.6);
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-align: center;

  h4 {
    font-size: 1rem;
    color: #713600;
    margin: 0;
  }
`;
