import {
  PersonalisationConfigItem,
  PersonalisationConfigType
} from '@pushologies/database-service/db/entities/notification';

export const buildPersonalisationVariableMap = (
  personalisationConfigItems: PersonalisationConfigItem[]
): PersonalisationConfigType => {
  if (!personalisationConfigItems || !Array.isArray(personalisationConfigItems)) {
    return {};
  }

  return personalisationConfigItems.reduce((acc, { uuid, variable }) => ({ ...acc, [uuid]: variable }), {});
};
