import React, { useState, useEffect, ChangeEvent, useMemo } from 'react';
import { Button } from '~components/button';
import { ColorPickerField, NumberField, rgbColorToHexString, TextField } from '~components/inputs';
import { useStoreState, useStoreActions } from '~store/hooks';
import {
  PollNav,
  PollFormWrapper,
  PollFormInputs,
  PollFormRow,
  PollAnswerRow,
  PollFormActions,
  PollFormContainer
} from './styles';
import { DateTimePicker } from '~components/inputs/date-time-picker';
import { POLL_UPSERT_SUCCESS_MESSAGES } from '~store/polls';
import { Row, Col, Nav, Tab } from 'react-bootstrap';
import { PollPreview } from './PollPreview';
import { StyledSelect } from '../../video-builder/video-info/styles';
import { SelectOption } from '~components/select';
import { Fonts } from '@pushologies/database-service/db/entities/content';
import { TextCustomisation } from '@pushologies/database-service/db/entities/poll';
import { RGBColor } from 'react-color';
import { PollImageUpload } from './PollImageUpload';
import { ApiContentItem } from '~api/content-items/types';

export const fontOptions: SelectOption<string>[] = Object.values(Fonts).map((font) => ({
  name: font,
  value: font
}));

enum CUSTOMISATION_TYPE {
  QUESTION = 'questionTextCustomisation',
  CHOICES = 'choicesTextCustomisation'
}

export interface PollBasicDto {
  id?: string;
  question: string;
  answers: { text: string; tagName?: string }[];
  validFrom?: Date;
  validUntil?: Date;
  marginTop?: number;
  backgroundImageContentItemId?: string;
  headerImageContentItemId?: string;
  backgroundColor?: string;
  questionTextCustomisation?: TextCustomisation;
  choicesTextCustomisation?: TextCustomisation;
  voteSuccessMessage?: string;
}

interface Props {
  pollToEdit?: PollBasicDto;
  onPollSaved: () => void;
}

const DEFAULT_POLL_VALUES: PollBasicDto = {
  question: 'Question?',
  answers: [{ text: 'Answer 1' }, { text: 'Answer 2' }]
};

enum POLL_TABS {
  ANSWERS = 'answers',
  TAGS = 'tags',
  OPTIONS = 'options'
}

// tslint:disable-next-line:cyclomatic-complexity
export const CreateEditPoll: React.FC<Props> = ({ onPollSaved, pollToEdit = {} }) => {
  const [poll, setPoll] = useState<PollBasicDto>(Object.assign({}, DEFAULT_POLL_VALUES, pollToEdit));
  const [headerImage, setHeaderImage] = useState<ApiContentItem | undefined>();
  const [backgroundImage, setBackgroundImage] = useState<ApiContentItem | undefined>();
  const [tabKey, setTabKey] = useState<POLL_TABS>(POLL_TABS.ANSWERS);
  const { upsertPoll } = useStoreActions((state) => state.polls);
  const { loading } = useStoreState((state) => state.polls);
  const { fetchItem } = useStoreActions((state) => state.contentItems);

  const formIncomplete =
    !poll.question?.trim().length ||
    poll.answers?.some((pollAnswer) => pollAnswer.text?.trim().length === 0) ||
    poll.answers.length < 2 ||
    poll.answers.length > 10;

  // if our poll has header/background images, we'll need to fecth them
  useEffect(() => {
    poll.backgroundImageContentItemId &&
      fetchItem({
        id: poll.backgroundImageContentItemId,
        url: true,
        onSuccess(contentItem) {
          setBackgroundImage(contentItem);
        }
      });
    poll.headerImageContentItemId &&
      fetchItem({
        id: poll.headerImageContentItemId,
        url: true,
        onSuccess(contentItem) {
          setHeaderImage(contentItem);
        }
      });
  }, []);

  const handleTabChange = (newTab: POLL_TABS) => setTabKey(newTab);

  const updateTextField = (name: keyof PollBasicDto) => (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setPoll((state) => ({ ...state, [name]: value }));
  };

  const updateAnswer = (index: number) => (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setPoll((state) => {
      const newAnswers = [...state.answers];
      newAnswers[index] = { text: value };
      return { ...state, answers: newAnswers };
    });
  };

  const updateAnswerTag = (index: number) => (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setPoll((state) => {
      const newAnswers = [...state.answers];
      newAnswers[index].tagName = value;
      return { ...state, answers: newAnswers };
    });
  };

  const updateDate = (name: 'validFrom' | 'validUntil') => (value: Date) => {
    setPoll((state) => ({ ...state, [name]: value.toISOString() }));
  };

  const handleAddAnswer = () => {
    setPoll((state) => ({ ...state, answers: [...state.answers, { text: '' }] }));
  };

  const handleDeleteAnswer = (answerIndex: number) => {
    setPoll((state) => ({
      ...state,
      answers: [...state.answers.slice(0, answerIndex), ...state.answers.slice(answerIndex + 1)]
    }));
  };

  const handleSubmit = () => {
    upsertPoll({
      poll,
      successMessage: poll.id ? POLL_UPSERT_SUCCESS_MESSAGES.UPDATE : POLL_UPSERT_SUCCESS_MESSAGES.CREATE,
      onSuccess() {
        onPollSaved();
      }
    });
  };

  const handleVoteSuccessMessageChange = (event: ChangeEvent<HTMLInputElement>) =>
    setPoll({
      ...poll,
      voteSuccessMessage: event.target.value
    });
  const handleTopMarginChange = (marginTop: number) =>
    setPoll({
      ...poll,
      marginTop
    });

  const handleFontChange = (type: CUSTOMISATION_TYPE) => (font: SelectOption<Fonts>[]) =>
    setPoll({
      ...poll,
      [type]: {
        ...poll[type],
        font: font[0].value
      }
    });
  const handleFontSizeChange = (type: CUSTOMISATION_TYPE) => (fontSize: number) =>
    setPoll({
      ...poll,
      [type]: {
        ...poll[type],
        fontSize
      }
    });
  const handlePollBackgroundColorChange = (color: RGBColor) =>
    setPoll({
      ...poll,
      backgroundColor: rgbColorToHexString(color)
    });
  const handleBackgroundColorChange = (type: CUSTOMISATION_TYPE) => (color: RGBColor) =>
    setPoll({
      ...poll,
      [type]: {
        ...poll[type],
        backgroundColor: rgbColorToHexString(color)
      }
    });
  const handleTextColorChange = (type: CUSTOMISATION_TYPE) => (color: RGBColor) =>
    setPoll({
      ...poll,
      [type]: {
        ...poll[type],
        color: rgbColorToHexString(color)
      }
    });
  const handleSelectedBackgroundColorChange = (type: CUSTOMISATION_TYPE) => (color: RGBColor) =>
    setPoll({
      ...poll,
      [type]: {
        ...poll[type],
        selectedBackgroundColor: rgbColorToHexString(color)
      }
    });
  const handleSelectedTextColorChange = (type: CUSTOMISATION_TYPE) => (color: RGBColor) =>
    setPoll({
      ...poll,
      [type]: {
        ...poll[type],
        selectedColor: rgbColorToHexString(color)
      }
    });
  const handleBackgroundImageSelect = (type: 'header' | 'background') => (contentItem: ApiContentItem) => {
    type === 'header' ? setHeaderImage(contentItem) : setBackgroundImage(contentItem);
    setPoll({ ...poll, [`${type}ImageContentItemId`]: contentItem.id });
  };

  const tagsStatus = useMemo(
    () => `${poll.answers.filter((t) => !!t.tagName).length} / ${poll.answers.length}`,
    [poll]
  );

  return (
    <PollFormWrapper>
      <PollFormContainer>
        <Row>
          <Col md="6">
            <PollFormInputs>
              <PollFormRow data-testid={'pollCreateQuestion'}>
                <TextField label="poll" name="question" value={poll.question} onChange={updateTextField('question')} />
              </PollFormRow>

              <PollFormRow>
                <Tab.Container
                  defaultActiveKey={POLL_TABS.ANSWERS}
                  activeKey={tabKey}
                  onSelect={handleTabChange}
                  data-testId={'pollTabs'}
                >
                  <PollNav>
                    <Nav className="flex-row">
                      <Nav.Item>
                        <Nav.Link eventKey={POLL_TABS.ANSWERS}>Answers</Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey={POLL_TABS.TAGS}>Tags ({tagsStatus})</Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey={POLL_TABS.OPTIONS}>Options</Nav.Link>
                      </Nav.Item>
                    </Nav>
                  </PollNav>
                  <Tab.Content>
                    <Tab.Pane eventKey={POLL_TABS.ANSWERS}>
                      <PollFormRow>
                        <p>Provide up to 10 answers to your poll question.</p>
                        <PollFormRow>
                          {poll.answers.map((answer, i) => (
                            <PollAnswerRow key={i} data-testid={`pollCreateAnswer${i}`}>
                              <TextField label="answer" name="answer" value={answer.text} onChange={updateAnswer(i)} />

                              <Button testId={`pollCreateAnswerRemove${i}`} onClick={() => handleDeleteAnswer(i)}>
                                X
                              </Button>
                            </PollAnswerRow>
                          ))}
                        </PollFormRow>
                      </PollFormRow>
                      <PollFormRow>
                        <Button
                          testId={`pollCreateAnswerAdd`}
                          onClick={handleAddAnswer}
                          disabled={poll.answers.length === 10}
                        >
                          {poll.answers.length === 10 ? `you've reached the 10 answer limit` : 'add answer'}
                        </Button>
                      </PollFormRow>
                    </Tab.Pane>
                    <Tab.Pane eventKey={POLL_TABS.TAGS}>
                      <PollFormRow>
                        <p>Choose which "Tag" a subscriber should get when they answer your poll (Optional).</p>
                        {poll.answers.map((answer, i) => (
                          <PollFormRow key={i} data-testid={`pollTagAnswer${i}`}>
                            <p>
                              Answer {i + 1}: {answer.text}
                            </p>
                            <TextField label="tag" name="tag" value={answer.tagName} onChange={updateAnswerTag(i)} />
                          </PollFormRow>
                        ))}
                      </PollFormRow>
                    </Tab.Pane>
                    <Tab.Pane eventKey={POLL_TABS.OPTIONS}>
                      <PollFormRow>
                        <p>Style your question:</p>
                        <PollFormRow>
                          <StyledSelect
                            id="pollQuestionFontSelect"
                            label="Font:"
                            options={fontOptions}
                            value={
                              poll?.questionTextCustomisation?.font
                                ? fontOptions.filter(({ value }) => value === poll.questionTextCustomisation.font)
                                : [fontOptions[0]]
                            }
                            onChange={handleFontChange(CUSTOMISATION_TYPE.QUESTION)}
                          />
                          <NumberField
                            testId="pollQuestionFontSize"
                            label="Font Size:"
                            value={poll?.questionTextCustomisation?.fontSize || 16}
                            onChange={handleFontSizeChange(CUSTOMISATION_TYPE.QUESTION)}
                          />
                          <ColorPickerField
                            testId="pollQuestionBackgroundColorPicker"
                            label="Background Color:"
                            value={poll?.questionTextCustomisation?.backgroundColor || '#ffffff'}
                            onChange={handleBackgroundColorChange(CUSTOMISATION_TYPE.QUESTION)}
                          />
                          <ColorPickerField
                            testId="pollQuestionTextColorPicker"
                            label="Text Color:"
                            value={poll?.questionTextCustomisation?.color || '#000000'}
                            onChange={handleTextColorChange(CUSTOMISATION_TYPE.QUESTION)}
                          />
                        </PollFormRow>

                        <p>Style your answers:</p>
                        <PollFormRow>
                          <StyledSelect
                            id="pollAnswerFontSelect"
                            label="Font:"
                            options={fontOptions}
                            value={
                              poll?.choicesTextCustomisation?.font
                                ? fontOptions.filter(({ value }) => value === poll.choicesTextCustomisation.font)
                                : [fontOptions[0]]
                            }
                            onChange={handleFontChange(CUSTOMISATION_TYPE.CHOICES)}
                          />
                          <NumberField
                            testId="pollAnswerFontSize"
                            label="Font Size:"
                            value={poll?.choicesTextCustomisation?.fontSize || 16}
                            onChange={handleFontSizeChange(CUSTOMISATION_TYPE.CHOICES)}
                          />
                          <ColorPickerField
                            testId="pollAnswerBackgroundColorPicker"
                            label="Background Color:"
                            value={poll?.choicesTextCustomisation?.backgroundColor || '#ffffff'}
                            onChange={handleBackgroundColorChange(CUSTOMISATION_TYPE.CHOICES)}
                          />
                          <ColorPickerField
                            testId="pollAnswerTextColorPicker"
                            label="Text Color:"
                            value={poll?.choicesTextCustomisation?.color || '#000000'}
                            onChange={handleTextColorChange(CUSTOMISATION_TYPE.CHOICES)}
                          />
                          <br />
                          <ColorPickerField
                            testId="pollAnswerSelectedBackgroundColorPicker"
                            label="Selected Background Color:"
                            value={poll?.choicesTextCustomisation?.selectedBackgroundColor || '#f9af04'}
                            onChange={handleSelectedBackgroundColorChange(CUSTOMISATION_TYPE.CHOICES)}
                          />
                          <ColorPickerField
                            testId="pollAnswerSelectedTextColorPicker"
                            label="Selected Text Color:"
                            value={poll?.choicesTextCustomisation?.selectedColor || '#000000'}
                            onChange={handleSelectedTextColorChange(CUSTOMISATION_TYPE.CHOICES)}
                          />
                        </PollFormRow>

                        <p>Enter a short message to display after an answer is chosen (Optional)</p>
                        <PollFormRow>
                          <TextField
                            placeholder="Thanks for your vote!"
                            name={'voteSuccessMessage'}
                            value={poll.voteSuccessMessage}
                            onChange={handleVoteSuccessMessageChange}
                          />
                        </PollFormRow>

                        <p>Set a date range to limit the availability of your poll (Optional):</p>
                        <PollFormRow>
                          <DateTimePicker
                            testId={`pollCreateValidFromDate`}
                            label={'Valid From'}
                            value={poll.validFrom}
                            onChange={updateDate('validFrom')}
                          />
                          <DateTimePicker
                            testId={`pollCreateValidUntilDate`}
                            label={'Valid Until'}
                            value={poll.validUntil}
                            onChange={updateDate('validUntil')}
                          />
                        </PollFormRow>

                        <p>Adjust the spacing around your header and poll output (Optional)</p>
                        <PollFormRow>
                          <NumberField
                            testId="pollTopMargin"
                            label="Spacing:"
                            min={0}
                            max={500}
                            value={poll?.marginTop || 0}
                            onChange={handleTopMarginChange}
                          />
                        </PollFormRow>
                      </PollFormRow>
                    </Tab.Pane>
                  </Tab.Content>
                </Tab.Container>
              </PollFormRow>
            </PollFormInputs>
          </Col>
          <Col md="6">
            <p>Poll Preview:</p>
            <div>
              <ColorPickerField
                testId="pollBackgroundColorPicker"
                label="Background Color:"
                value={poll?.backgroundColor || '#a71580'}
                onChange={handlePollBackgroundColorChange}
              />
            </div>
            <PollPreview poll={poll} headerImage={headerImage} backgroundImage={backgroundImage} />

            <PollImageUpload
              selectedId={headerImage?.id}
              testId="pollHeaderImageField"
              title="Poll Header"
              label="Header Image:"
              uploadButtonText="Create New Header Image"
              fileUploadLabel="Drag image file here"
              onChange={handleBackgroundImageSelect('header')}
            />

            <PollImageUpload
              selectedId={backgroundImage?.id}
              testId="pollBackgroundImageField"
              title="Poll Background"
              label="Background Image:"
              uploadButtonText="Create New Background Image"
              fileUploadLabel="Drag image file here"
              onChange={handleBackgroundImageSelect('background')}
            />
          </Col>
        </Row>
      </PollFormContainer>
      <PollFormActions>
        <Button testId={'pollCreateSubmit'} onClick={handleSubmit} disabled={formIncomplete} loading={loading}>
          save
        </Button>
      </PollFormActions>
    </PollFormWrapper>
  );
};
