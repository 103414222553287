import styled from 'styled-components';
import { Row } from 'react-bootstrap';
import { TableSection } from '~components/styled/table-section';
import { CopyDiv } from '~components/copy-widget/styles';

const topPadding = '1.5rem';
const topPaddings = `calc(${topPadding} * 3)`;
const inputSectionHeight = '1.75rem';

export const StyledRow = styled(Row)`
  padding: ${topPadding} 4rem;

  ${TableSection} {
    .table-responsive {
      max-height: ${(props) => `calc(
        100vh - 2.75rem - (
          ${props.theme.metrics.headerHeight} +
          ${props.theme.metrics.headerOverlayHeight} +
          ${topPaddings} +
          ${inputSectionHeight}
        )
      )`};
    }
  }
`;

export const StyledTr = styled.tr`
  ${CopyDiv} {
    justify-content: center;
  }
`;
