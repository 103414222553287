import React, { ReactNode, useMemo } from 'react';
import { Table } from 'react-bootstrap';
import classnames from 'classnames';
import { Loader } from '~components/loader';
import { ViewerPagination } from '~components/file-viewers/pagination';
import { ViewerHeader } from '../header';
import { FileViewerWrapperDiv, StyledTr, TableSection } from './styles';
import { Props, DisplayKey, ListFile } from './types';
import { extractKeyData } from './helpers';

export * from './types';

/** Renders a list of items in a customisable table  */
export const ListViewer: React.FC<Props> = (props) => {
  const handleSelect = (file: ListFile) => () => {
    const idx = props.selectedFiles.findIndex((selectedFile) => file.id === selectedFile?.id);
    let newSelected;

    if (idx >= 0) {
      newSelected = [...props.selectedFiles.slice(0, idx), ...props.selectedFiles.slice(idx + 1)];
    } else {
      newSelected = props.multiSelect ? props.selectedFiles.concat([file]) : [file];
    }

    props.onSelect(newSelected);
  };

  const formatDisplayKey = (file: ListFile, keyObj: DisplayKey) => {
    const key = extractKeyData(keyObj);
    let value = file;
    key.split('.').forEach((k) => (value = value[k]));

    if (props.displayKeyFormat[key]) return props.displayKeyFormat[key](value);
    else return value;
  };

  const renderTableRow = (rows: ReactNode[], file: ListFile) => {
    const isSelected = !!props.selectedFiles.find((selectedFile) => selectedFile?.id === file.id);

    rows.push(<tr key={`${file.id}-spacer`} className="spacer" />);
    rows.push(
      <StyledTr
        key={file.id}
        id={file.id}
        className={classnames({ isSelected })}
        onClick={handleSelect(file)}
        data-testid={`${props.testId}Row`}
      >
        {props.displayKeys.map((displayKey) => {
          const key = extractKeyData(displayKey);

          return (
            <td key={`${key}${file.id}`} width={props.columnWidths[key]}>
              {formatDisplayKey(file, key)}
            </td>
          );
        })}
      </StyledTr>
    );

    return rows;
  };

  return useMemo(
    () => (
      <FileViewerWrapperDiv data-testid={props.testId} className={props.className}>
        {(props.header || props.filterValue) && (
          <ViewerHeader
            title={props.header}
            onTextChange={props.onFilterValueChange}
            filterValue={props.filterValue}
            filterPlaceholder={props.filterPlaceholder}
          />
        )}
        <TableSection $hasHeader={!!props.header} hideTableHeader={props.hideTableHeader}>
          <Loader loading={props.loading} testId={`${props.testId}Loader`} backdrop />
          <Table>
            {!props.hideTableHeader && (
              <thead>
                <tr>
                  {props.displayKeys.map((displayKey) => {
                    const key = extractKeyData(displayKey, 'columnName');
                    return (
                      <th key={key}>
                        <div>{key}</div>
                      </th>
                    );
                  })}
                </tr>
              </thead>
            )}
            <tbody>{props.files.reduce(renderTableRow, [])}</tbody>
          </Table>
        </TableSection>
        {props.pagination && <ViewerPagination {...props.pagination} />}
      </FileViewerWrapperDiv>
    ),
    [
      props.files,
      props.loading,
      props.header,
      props.displayKeys,
      props.multiSelect,
      props.displayKeyFormat,
      props.selectedFiles,
      props.loading
    ]
  );
};

ListViewer.defaultProps = {
  displayKeyFormat: {},
  selectedFiles: [],
  columnWidths: {}
};
