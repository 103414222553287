import React, { useMemo } from 'react';
import { NOTIFICATION_TYPE } from '@pushologies/database-service/db/entities/notification';
import { useStoreState } from '~store/hooks';
import { Select } from '~components/select';
import { PreviewDevice, DeviceOption, deviceOptions } from '~components/preview-device';
import { VideoBuilder } from '../video-builder';
import { CarouselBuilder } from '../carousel-builder';
import { PollBuilder } from '../poll-builder';
import { NotificationPreviewColumnContentDiv, StyledColumnDiv, StyledButton, StyledCol } from './styles';
import { INAPP_TYPE } from '@pushologies/common/constants/in-app';
import { constructThumbnailUrl } from '~api/tools/helpers';

export const MiddleColumn: React.FC = () => {
  const [activeModal, setActiveModal] = React.useState<NOTIFICATION_TYPE>(null);
  const [selectedDevice, setSelectedDevice] = React.useState(deviceOptions[0]);

  const { notification } = useStoreState((state) => state.createNotification);
  const { items } = useStoreState((state) => state.contentItems);
  const notStandard = ![NOTIFICATION_TYPE.STANDARD, NOTIFICATION_TYPE.INAPP].includes(notification.type);
  const inAppBuilderRequired = [INAPP_TYPE.CAROUSEL, INAPP_TYPE.VIDEO].includes(notification?.inApp?.type);
  const isInAppVideo = notification?.inApp?.type === INAPP_TYPE.VIDEO;

  const bannerImageThumbnail =
    items[notification?.bannerContent?.contentItem?.id ?? notification?.richImageContent?.contentItem?.id]?.downloadUrl;
  const inAppVideoThumbnail = isInAppVideo
    ? constructThumbnailUrl(items[notification.videoContent?.contentItem?.id])
    : undefined;
  const bannerUrl = bannerImageThumbnail || inAppVideoThumbnail;

  const handlePreviewDeviceChange = (selected: DeviceOption[]) => {
    setSelectedDevice(selected[0]);
  };

  const handleActiveModalChange = () => {
    if (!activeModal) setActiveModal(notification.type);
    else setActiveModal(null);
  };

  return useMemo(
    () => (
      <StyledCol md="4">
        <StyledColumnDiv>
          <NotificationPreviewColumnContentDiv>
            {(notStandard || inAppBuilderRequired) && (
              <StyledButton onClick={handleActiveModalChange} testId="builderButton" tourId="builderButton">
                {!inAppBuilderRequired ? `${notification.type} Builder` : `${notification?.inApp?.type} Builder`}
              </StyledButton>
            )}
            <VideoBuilder
              show={
                activeModal === NOTIFICATION_TYPE.VIDEO ||
                (activeModal === NOTIFICATION_TYPE.INAPP && notification?.inApp?.type === INAPP_TYPE.VIDEO)
              }
              closeModal={handleActiveModalChange}
            />
            <CarouselBuilder
              show={
                activeModal === NOTIFICATION_TYPE.CAROUSEL ||
                (activeModal === NOTIFICATION_TYPE.INAPP && notification?.inApp?.type === INAPP_TYPE.CAROUSEL)
              }
              closeModal={handleActiveModalChange}
            />
            <PollBuilder show={activeModal === NOTIFICATION_TYPE.POLL} closeModal={handleActiveModalChange} />
            <PreviewDevice
              notificationType={notification.type}
              title={notification.title}
              message={notification.message}
              subTitle={notification.subTitle}
              selectedDevice={selectedDevice}
              bannerUrl={bannerUrl}
              carouselContent={notification.carouselContent || []}
              inApp={notification.inApp}
              presentation={notification.bannerContent?.presentation || notification.richImageContent?.presentation}
            />
          </NotificationPreviewColumnContentDiv>
          <Select
            id="devicePreviewSelect"
            value={[selectedDevice]}
            options={deviceOptions}
            onChange={handlePreviewDeviceChange}
            label="Preview Device:"
          />
        </StyledColumnDiv>
      </StyledCol>
    ),
    [notification, selectedDevice, activeModal, bannerUrl]
  );
};
