import dayjs from '~helpers/dayjs';

export const dateTimeFormat = (date: Date | string | number, format?: string) =>
  dayjs(date).format(format || 'DD/MM/YYYY HH:mm');

export const numberCommaFormat = (value: number) =>
  typeof value === 'number' ? new Intl.NumberFormat().format(value) : 0;

// stolen from https://stackoverflow.com/a/6312999/2676078
// until this is approved https://github.com/tc39/proposal-intl-duration-format
export const millisecondsToTime = (milliseconds: number) => {
  let secs = milliseconds / 1000;
  const addZero = (num: number) => (num < 10 ? `0${num}` : num);
  secs = Math.round(secs);
  const hours = Math.floor(secs / 3600);

  const divisorForMinutes = secs % 3600;
  const minutes = Math.floor(divisorForMinutes / 60);

  const divisorForSeconds = divisorForMinutes % 60;
  const seconds = Math.ceil(divisorForSeconds);

  return {
    text: `${addZero(hours)}:${addZero(minutes)}:${addZero(seconds)}`,
    hours,
    minutes,
    seconds
  };
};

export const trimText = (text: string, maxLength: number) => {
  if (!text) return '';

  return text.length > maxLength ? `${text.substr(0, maxLength)}...` : text;
};

export const generateTourSelector = (tourId: string) => `[data-tour="${tourId}"]`;

export const extractInitials = (fullname: string): string => {
  const initials = fullname
    .replace(/[^a-zA-Z- ]/g, '')
    .match(/\b\w/g)
    ?.join('')
    .toUpperCase();
  return initials?.substring(0, 2) || '';
};
