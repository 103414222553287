import React from 'react';
import { fabric } from 'fabric';
import { useTheme } from 'styled-components';
import randomcolor from 'randomcolor';
import { Fonts } from '@pushologies/database-service/db/entities/content';
import { useStoreActions, useStoreState } from '~store/hooks';
import { constructThumbnailUrl } from '~api/tools/helpers';
import { generateRandomStrUID } from '~helpers/files';
import { useStateRef } from '~hooks/use-state-ref';
import { renderSelectionContext, buttonToPosition, getButtonDimensions, persistStoreToCanvas } from './helpers';
import { VideoBuilderContext } from './types';
import { ButtonOptions } from '~store/create-notification';

const INITIAL_BTN_TEXT = 'edit me';
export const CANVAS_ID = 'videoBuilderCanvas';
export const VIDEO_ID = 'videoBuilderVideo';
const VideoBuilderCanvasContext = React.createContext<VideoBuilderContext>(null);

export const useVideoBuilderContext = () => {
  const context = React.useContext(VideoBuilderCanvasContext);

  if (!context) {
    throw new Error('Cannot use "useFabricCanvasContext" outside a FabricCanvasProvider');
  }

  return context;
};

/**
 * Video Builder Context Provider. Provides access to fabric.js canvas and also helper methods
 * to create video buttons
 */
export const VideoBuilderProvider: React.FC = ({ children }) => {
  const videoRef = React.useRef<HTMLVideoElement>();
  const scalingRef = React.useRef(1);
  const eventListenerCallbacksRef = React.useRef<Record<string, (() => void)[]>>({});
  const [canvas, setCanvas, canvasRef] = useStateRef<fabric.Canvas>(null);
  const [activeButtonId, setActiveButtonId] = React.useState<string>(null);
  const [initialising, setInitialising] = React.useState(false);
  const { notification } = useStoreState((state) => state.createNotification);
  const { setButton, deleteButton } = useStoreActions((state) => state.createNotification);
  const theme = useTheme();

  /** Updates a buttons easy peasy store position values */
  const updateStorePositioning = (button: fabric.Object) => {
    setButton({
      id: button.data.id,
      ...getButtonDimensions(button),
      options: {
        position: buttonToPosition(button)
      }
    });
  };

  /** Helper to call renderSelectionContext() */
  const updateSelectionContext = (cb?: (obj: fabric.Object) => void) => {
    renderSelectionContext(canvasRef.current, videoRef.current, scalingRef.current, notification.personalisation, cb);
  };

  const value: VideoBuilderContext = {
    canvas,
    activeButtonId,
    videoRef,
    initialising,
    setInitialising,

    addEventListenerCallback(event, cb) {
      if (!eventListenerCallbacksRef.current[event]) {
        eventListenerCallbacksRef.current[event] = [];
      }

      eventListenerCallbacksRef.current[event].push(cb);
    },

    async createVideoCanvas(options) {
      if (canvas) canvas.dispose(); // cleanup old canvas

      const newCanvas = new fabric.Canvas(CANVAS_ID, {
        ...options,
        containerClass: CANVAS_ID,
        width: videoRef.current.videoWidth,
        height: videoRef.current.videoHeight,
        selection: false,
        backgroundImage: new fabric.Image(videoRef.current, {
          left: 0,
          top: 0,
          width: videoRef.current.videoWidth,
          height: videoRef.current.videoHeight,
          originX: 'left',
          originY: 'top',
          objectCaching: false,
          hasControls: false,
          selectable: false,
          evented: false,
          lockMovementX: true,
          lockMovementY: true,
          lockScalingX: true,
          lockScalingY: true
        })
      });

      setCanvas(newCanvas);
    },

    addButton() {
      const id = generateRandomStrUID(10);
      const color = randomcolor({ luminosity: 'random', hue: 'random' });
      const textColor = theme.palette.primaryMustard;
      const backgroundColor = theme.palette.primaryPlum;
      const displayName = `Button ${Object.keys(notification?.buttons).length + 1}`;
      const currentTimeInMilliSeconds = videoRef.current.currentTime * 1000;
      const buttonAppear = 0;
      const buttonDisappear = Math.max(10, Math.floor(videoRef.current.duration * 0.1)) * 1000;
      const fontSize = 41;
      const font = Fonts.Arial;
      const text = INITIAL_BTN_TEXT;
      const textAlignmentVertical = 'center';
      const textAlignmentHorizontal = 'center';
      const textWrapping = false;
      const linkHandling = 'defaultBrowser';

      const rectangle = new fabric.Rect({
        data: {
          color,
          id,
          displayName,
          text,
          textColor,
          font,
          fontSize,
          buttonAppear,
          buttonDisappear,
          textAlignmentVertical,
          textAlignmentHorizontal,
          textWrapping,
          linkHandling
        },
        originX: 'left',
        originY: 'top',
        width: 300,
        height: 80,
        fill: backgroundColor,
        strokeWidth: 3,
        lockRotation: true,
        cornerSize: Math.max(scalingRef.current * 20, 20),
        cornerColor: color,
        transparentCorners: false,
        visible: currentTimeInMilliSeconds <= buttonDisappear,
        ...canvas.getCenter()
      });
      rectangle.setControlVisible('mtr', false); // disable rotation controls

      canvas.add(rectangle);
      setActiveButtonId(id);
      canvas.setActiveObject(rectangle);

      setButton({
        id,
        displayName,
        controlsColor: color,
        ...getButtonDimensions(rectangle),
        options: {
          text,
          textColor,
          fontSize,
          font,
          linkHandling,
          backgroundColor,
          position: buttonToPosition(rectangle),
          buttonAppear,
          buttonDisappear,
          textAlignmentVertical,
          textAlignmentHorizontal,
          textWrapping,
          // TEMP FIX: fix for when undefined url is passed as value to PersonalisationTextField
          // and when handleUrlChange() is called is doesn't habve up to date activeButton object
          url: ' '
        }
      });

      return id;
    },

    cloneButton(button: ButtonOptions) {
      const { width, height, options } = button;
      const {
        buttonImageUrl,
        contentItemId,
        textColor,
        backgroundColor,
        buttonAppear,
        buttonDisappear,
        fontSize,
        font,
        text,
        textAlignmentHorizontal,
        textAlignmentVertical,
        textWrapping,
        url,
        position = canvas.getCenter(),
        linkHandling,
        tagName
      } = options;

      const id = generateRandomStrUID(10);
      const displayName = `Button ${Object.keys(notification?.buttons).length + 1}`;
      const color = randomcolor({ luminosity: 'random', hue: 'random' });
      const currentTimeInMilliSeconds = videoRef.current.currentTime * 1000;

      // are we cloning an image?
      if (buttonImageUrl) {
        fabric.Image.fromURL(buttonImageUrl, function (image) {
          image.left = position.left;
          image.top = position.top;
          image.data = {
            color,
            id,
            displayName,
            text,
            textColor,
            font,
            fontSize,
            buttonAppear,
            buttonDisappear,
            linkHandling,
            tagName
          };
          image.objectCaching = true;
          image.statefullCache = true;
          image.lockRotation = true;
          image.strokeWidth = 3;
          image.stroke = color;
          image.cornerSize = Math.max(scalingRef.current * 20, 20);
          image.cornerColor = color;
          image.transparentCorners = false;
          image.visible = currentTimeInMilliSeconds >= buttonAppear && currentTimeInMilliSeconds <= buttonDisappear;
          image.setControlVisible('mtr', false); // disable rotation controls

          canvas.add(image);
          setActiveButtonId(id);
          canvas.setActiveObject(image);

          setButton({
            id,
            ...getButtonDimensions(image),
            options: {
              buttonImageUrl,
              text: undefined,
              backgroundColor: undefined,
              textColor: undefined,
              position: buttonToPosition(image),
              contentItemId,
              linkHandling,
              tagName
            }
          });
        });
      }

      // cloning standard button
      const rectangle = new fabric.Rect({
        data: {
          color,
          id,
          displayName,
          text,
          textColor,
          font,
          fontSize,
          textAlignmentHorizontal,
          textAlignmentVertical,
          textWrapping,
          buttonAppear,
          buttonDisappear,
          linkHandling,
          tagName
        },
        originX: 'left',
        originY: 'top',
        width,
        height,
        fill: backgroundColor,
        strokeWidth: 3,
        lockRotation: true,
        cornerSize: Math.max(scalingRef.current * 20, 20),
        cornerColor: color,
        transparentCorners: false,
        visible: currentTimeInMilliSeconds <= buttonDisappear,
        ...position
      });
      rectangle.setControlVisible('mtr', false); // disable rotation controls

      canvas.add(rectangle);
      setActiveButtonId(id);
      canvas.setActiveObject(rectangle);

      setButton({
        id,
        displayName,
        controlsColor: color,
        ...getButtonDimensions(rectangle),
        options: {
          text,
          textColor,
          fontSize,
          font,
          textAlignmentHorizontal,
          textAlignmentVertical,
          textWrapping,
          backgroundColor,
          position: buttonToPosition(rectangle),
          buttonAppear,
          buttonDisappear,
          url,
          linkHandling,
          tagName
        }
      });

      return id;
    },

    updateButtonImage(id, contentItem) {
      const oldButton = canvas.getActiveObject();
      const currentTimeInMilliSeconds = videoRef.current.currentTime * 1000;
      const url = contentItem?.downloadUrl || constructThumbnailUrl(contentItem);

      // remove old non image button - important to do before adding new image to resolve text bug
      canvas.remove(oldButton);

      setTimeout(() => {
        fabric.Image.fromURL(url, function (image) {
          image.left = oldButton.left;
          image.top = oldButton.top;
          image.data = { ...oldButton.data, text: null };
          image.objectCaching = true;
          image.statefullCache = true;
          image.lockRotation = true;
          image.strokeWidth = 3;
          image.stroke = oldButton.stroke;
          image.cornerSize = Math.max(scalingRef.current * 20, 20);
          image.cornerColor = oldButton.data.color;
          image.transparentCorners = false;
          image.visible =
            currentTimeInMilliSeconds >= oldButton.data.buttonAppear &&
            currentTimeInMilliSeconds <= oldButton.data.buttonDisappear;
          image.setControlVisible('mtr', false); // disable rotation controls

          canvas.add(image);
          setActiveButtonId(id);
          canvas.setActiveObject(image);

          setButton({
            id,
            ...getButtonDimensions(image),
            options: {
              buttonImageUrl: url,
              text: undefined,
              backgroundColor: undefined,
              textColor: undefined,
              position: buttonToPosition(image),
              contentItemId: contentItem.id
            }
          });
        });
      }, 50);
    },

    clearButtonImage(button: ButtonOptions) {
      const oldButton = canvas.getActiveObject();
      const { url, font, fontSize, buttonAppear, buttonDisappear } = button.options;

      const color = randomcolor({ luminosity: 'random', hue: 'random' });
      const textColor = theme.palette.primaryMustard;
      const backgroundColor = theme.palette.primaryPlum;
      const currentTimeInMilliSeconds = videoRef.current.currentTime * 1000;
      const text = INITIAL_BTN_TEXT;

      canvas.remove(oldButton);
      setTimeout(() => {
        const rectangle = new fabric.Rect({
          data: {
            ...oldButton.data,
            color,
            id: button.id,
            text,
            textColor,
            font,
            fontSize,
            buttonAppear,
            buttonDisappear
          },
          originX: 'left',
          originY: 'top',
          width: 300,
          height: 80,
          fill: backgroundColor,
          strokeWidth: 3,
          lockRotation: true,
          cornerSize: Math.max(scalingRef.current * 20, 20),
          cornerColor: color,
          transparentCorners: false,
          visible: currentTimeInMilliSeconds <= buttonDisappear,
          top: oldButton.top,
          left: oldButton.left
        });
        rectangle.setControlVisible('mtr', false); // disable rotation controls

        canvas.add(rectangle);
        setActiveButtonId(button.id);
        canvas.setActiveObject(rectangle);

        setButton({
          id: button.id,
          controlsColor: color,
          ...getButtonDimensions(rectangle),
          options: {
            buttonImageUrl: undefined,
            contentItemId: undefined,
            text,
            textColor,
            fontSize,
            font,
            linkHandling: 'defaultBrowser',
            backgroundColor,
            position: buttonToPosition(rectangle),
            buttonAppear,
            buttonDisappear,
            url
          }
        });
      }, 50);

      return button.id;
    },

    getActiveButton() {
      return canvas ? canvas.getActiveObject() : null;
    },

    deleteButton(id) {
      canvas.forEachObject((button) => {
        if (button.data.id === id) {
          canvas.remove(button);
          deleteButton({ id });
          updateSelectionContext();
          return;
        }
      });
    },

    setActiveButton(id) {
      canvas.forEachObject((button) => {
        if (button.data.id === id) {
          canvas.setActiveObject(button);
          setActiveButtonId(id);
          return;
        }
      });
    },

    discardActiveButton() {
      canvas.discardActiveObject();
    },

    updateButtonDuration(id, milliseconds, key) {
      setButton({ id, options: { [key]: milliseconds } });
      updateSelectionContext((button) => {
        if (button.data.id === id) {
          button.set('data', { ...button.data, [key]: milliseconds });
          canvas.setActiveObject(button);
          return;
        }
      });
    },

    updateButtonDurations(id, [buttonAppear, buttonDisappear]) {
      setButton({ id, options: { buttonAppear, buttonDisappear } });
      updateSelectionContext((button) => {
        if (button.data.id === id) {
          button.set('data', { ...button.data, buttonAppear, buttonDisappear });
          canvas.setActiveObject(button);
          return;
        }
      });
    },

    updateButtonUrl(id, url) {
      setButton({ id, options: { url } });
    },

    updateButtonDisplayName(id, displayName) {
      const button = canvas.getActiveObject();
      button.set('data', { ...button.data, displayName });
      updateSelectionContext();
      setButton({ id, displayName });
    },

    updateButtonText(id, text) {
      const button = canvas.getActiveObject();
      button.set('data', { ...button.data, text });
      updateSelectionContext();

      setButton({
        id,
        options: { text }
      });
    },

    updateButtonTextColor(id, textColor) {
      const button = canvas.getActiveObject();
      button.set('data', { ...button.data, textColor });
      updateSelectionContext();

      setButton({ id, options: { textColor } });
    },

    updateButtonFontSize(id, fontSize) {
      const button = canvas.getActiveObject();
      button.set('data', { ...button.data, fontSize });
      updateSelectionContext();

      setButton({
        id,
        options: { fontSize }
      });
    },

    updateButtonTextAlignment(id, textAlignmentVertical, textAlignmentHorizontal) {
      const button = canvas.getActiveObject();
      button.set('data', { ...button.data, textAlignmentVertical, textAlignmentHorizontal });
      updateSelectionContext();

      setButton({
        id,
        options: { textAlignmentVertical, textAlignmentHorizontal }
      });
    },

    updateButtonTextWrapping(id, textWrapping) {
      const button = canvas.getActiveObject();
      button.set('data', { ...button.data, textWrapping });
      updateSelectionContext();

      setButton({
        id,
        options: { textWrapping }
      });
    },

    updateButtonFont(id, font) {
      const button = canvas.getActiveObject();
      button.set('data', { ...button.data, font });
      updateSelectionContext();

      setButton({ id, options: { font } });
    },

    updateButtonBackgroundColor(id, backgroundColor) {
      canvas.getActiveObject().set('fill', backgroundColor);
      canvas.requestRenderAll();
      setButton({ id, options: { backgroundColor } });
    },

    updateButtonBorderColor(id, borderColor) {
      const button = canvas.getActiveObject();
      button.set('stroke', borderColor);
      canvas.requestRenderAll();
      setButton({ id, options: { borderColor } });
    },

    updateButtonPosition(id, { x, y }) {
      const button = canvas.getActiveObject();
      typeof x !== 'undefined' && button.set('left', x || 0);
      typeof y !== 'undefined' && button.set('top', y || 0);
      button.setCoords();
      canvas.requestRenderAll();
      updateSelectionContext();

      setButton({
        id,
        ...getButtonDimensions(button),
        options: {
          position: buttonToPosition(button)
        }
      });
    },

    clearCanvas() {
      if (canvas) {
        canvas.dispose();
        canvas.backgroundImage = null;
        setCanvas(null);
      }
    },

    setCanvasScaling(scale: number) {
      scalingRef.current = scale;
      if (canvas)
        canvas.forEachObject((button) => {
          button.set('cornerSize', Math.max(scale * 20, 20));
        });
    }
  };

  React.useEffect(() => {
    setInitialising(false);

    if (!canvas) return;

    // event listeners to draw guidelines and button text in selection context
    canvas.on('selection:created', function (event) {
      setActiveButtonId(event.selected[0].data.id);
    });
    canvas.on('selection:updated', function (event) {
      setActiveButtonId(event.selected[0].data.id);
    });
    canvas.on('selection:cleared', function () {
      setActiveButtonId(null);
    });
    canvas.on('object:moving', function () {
      updateSelectionContext();
    });
    canvas.on('object:scaling', function () {
      updateSelectionContext();
    });
    canvas.on('object:modified', function (event) {
      updateStorePositioning(event.target);
    });

    // restore objects if they exist in easy peasy store
    if (Object.keys(notification?.buttons).length) {
      persistStoreToCanvas(canvas, notification?.buttons, videoRef.current);
      setActiveButtonId(null);
      updateSelectionContext();
    }

    videoRef.current.ontimeupdate = function () {
      eventListenerCallbacksRef.current.ontimeupdate.forEach((cb) => cb());
      updateSelectionContext();
    };

    // refresh canvas whenever video frame updates
    fabric.util.requestAnimFrame(function render() {
      if (
        !videoRef?.current?.paused &&
        !videoRef?.current?.ended &&
        !videoRef?.current?.seeking &&
        !!canvas?.backgroundImage
      ) {
        canvas.requestRenderAll();
      }
      fabric.util.requestAnimFrame(render);
    });
  }, [canvas]);

  React.useEffect(() => {
    if (canvas) updateSelectionContext();
  }, [activeButtonId]);

  React.useEffect(() => {
    return () => {
      if (canvas) canvas.dispose();
    };
  }, []);

  return <VideoBuilderCanvasContext.Provider value={value}>{children}</VideoBuilderCanvasContext.Provider>;
};
