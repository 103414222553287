import React, { useCallback } from 'react';
import { Form } from 'react-bootstrap';

interface Props {
  name: string;
  label: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

export const FileFormGroup: React.FC<Props> = ({ name, label, onChange }) => {
  const [inputText, setInputText] = React.useState('');
  const ref = React.useRef<HTMLInputElement>();

  if (ref?.current?.form) {
    ref.current.form.onreset = () => setInputText('');
  }

  const onFileChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setInputText(e.target?.files[0]?.name || '');
    onChange(e);
  }, []);

  return (
    <Form.Group controlId="firebase">
      <Form.Label>{label}</Form.Label>
      <Form.File custom>
        <Form.File.Input name={name} onChange={onFileChange} ref={ref} />
        <Form.File.Label data-browse="choose file">{inputText}</Form.File.Label>
      </Form.File>
    </Form.Group>
  );
};
