import styled, { css } from 'styled-components';
import ReactSlider from 'react-slider';
import { HideScrollbars } from '~styles/mixins';

export const VideoTimelineDiv = styled.div`
  position: relative;
  display: block;
  width: 100%;
  margin-bottom: 1rem;
  z-index: 100;
`;

interface PlayControlsSectionProps {
  disabled: boolean;
}

export const PlayControlsSection = styled.section<PlayControlsSectionProps>`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  top: -3.5rem;

  span {
    width: 2rem;
    height: 2rem;
    margin: 0 0.5rem;
    cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};

    &:hover {
      svg {
        path {
          fill: ${({ theme, disabled }) => (disabled ? theme.palette.primaryMustard : 'white')};
        }
      }
    }

    &:nth-child(1),
    &:nth-child(2),
    &:nth-child(3) {
      transform: rotate(180deg);
    }
  }
`;

interface SliderThumbDivProps {
  currentTimeString: string;
}

export const SliderThumbDiv = styled.div<SliderThumbDivProps>`
  bottom: 0;
  height: 5rem;
  width: 2px;
  background: ${({ theme }) => theme.palette.textGrey};
  z-index: 200;

  &:after {
    content: '';
    position: absolute;
    width: 1rem;
    height: 1rem;
    left: -0.45rem;
    border-radius: 50%;
    background: ${({ theme }) => theme.palette.primaryMustard};
  }

  &:before {
    content: '${({ currentTimeString }) => currentTimeString}';
    position: absolute;
    display: none;
    top: -1.75rem;
    transform: translateX(-50%);
    font-family: EavesRegular;
    color: ${({ theme }) => theme.palette.primaryMustard};
    background: ${({ theme }) => theme.palette.primaryPlum};
    padding: 0 0.3rem;
  }
`;

export const SliderControlsSection = styled.section`
  position: relative;
  width: 100%;

  &:hover {
    ${SliderThumbDiv} {
      &:before {
        display: block;
      }
    }
  }
`;

export const StyledReactSlider = styled(ReactSlider)`
  width: 100%;
  height: 0.55rem;
  background: ${({ theme }) => theme.palette.primaryPlum};
`;

export const TimecodeSection = styled.section`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  span {
    font-family: EavesRegular;
    font-size: 1rem;
    color: ${({ theme }) => theme.palette.textGrey};
  }
`;

export const ButtonsControllerDiv = styled.div`
  position: relative;
  display: block;
  width: 100%;
  height: 2.5rem;
  margin: 0 0 0.5rem 0;
  border: 1px solid ${({ theme }) => theme.palette.textGrey};
  overflow-y: scroll;
  overflow-x: hidden;
  z-index: 100;

  ${HideScrollbars}
`;

interface ButtonSliderDivProps {
  isActive: boolean;
}

export const ButtonSliderDiv = styled.div<ButtonSliderDivProps>`
  position: relative;
  display: block;
  width: 100%;
  height: 0.5rem;
  border-bottom: 1px solid ${({ theme }) => theme.palette.lighterGrey};
  box-sizing: content-box;
  opacity: ${({ isActive }) => (isActive ? 1 : 0.5)};

  &:hover {
    opacity: 1;
  }
`;

export const ButtonReactSlider = styled(ReactSlider)`
  width: 100%;
  height: 0.55rem;
  background: transparent;
`;

interface ButtonSliderTrackProps {
  color: string;
  index: number;
}

export const ButtonSliderTrack = styled.div<ButtonSliderTrackProps>`
  top: 0;
  bottom: 0;
  background: ${({ color, index }) => (index === 1 ? color : 'transparent')};
`;

interface VideoSliderTrackProps {
  index: number;
}

export const VideoSliderTrack = styled.div<VideoSliderTrackProps>`
  top: 0;
  bottom: 0;
  background: ${({ theme, index }) => (index === 0 ? theme.palette.primaryMustard : theme.palette.primaryPlum)};
`;

interface ButtonSliderThumbProps {
  index: number;
}

export const ButtonSliderThumb = styled.div<ButtonSliderThumbProps>`
  cursor: pointer;
  border-top: 0.35rem solid transparent;
  border-bottom: 0.35rem solid transparent;

  ${({ index, theme }) =>
    index === 0
      ? css`
          transform: translate(-115%, -10%);
          border-right: 0.6rem solid ${theme.palette.primaryMustard};
        `
      : css`
          transform: translate(125%, -10%);
          border-left: 0.6rem solid ${theme.palette.primaryMustard};
        `}
`;
