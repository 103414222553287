import { SelectQueryBuilder } from 'typeorm';
import { Subscriber } from '@pushologies/database-service/db/entities/subscriber';
import { OperatorValue as Op, SegmentRule } from '../types';
import { Rule } from '.';

export class CustomerIdsRule extends Rule {
  constructor(private rule: SegmentRule<string[]>) {
    super();
  }

  updateQuery(query: SelectQueryBuilder<Subscriber>) {
    if (!this.rule.value?.length) return;

    const [sql, params] = this.getQueryString();
    query.andWhere(sql, params);
  }

  private getQueryString(): [string, object] {
    const customerIdsParam = this.createParameterHash();
    switch (this.rule.operator) {
      case Op.in:
        return [`subscriber.customer_id IN (:...${customerIdsParam})`, { [customerIdsParam]: this.rule.value }];
      case Op.notIn:
        return [`subscriber.customer_id NOT IN (:...${customerIdsParam})`, { [customerIdsParam]: this.rule.value }];
      default:
        throw new Error(`operator "${this.rule.operator}" not supported`);
    }
  }
}
