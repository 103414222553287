import styled, { keyframes, css } from 'styled-components';
import { StyledButton } from '~components/button/styles';
import { StyledDropdown } from '~components/select/styles';

const onDragTransition = (primaryColor: string, secondaryColor: string) => keyframes`
  0% {
    border-color: ${primaryColor};
    fill: ${primaryColor};
  }
  50% {
    border-color: ${secondaryColor};
    fill: ${secondaryColor};
  }
  100% {
    border-color: ${primaryColor};
    fill: ${primaryColor};
  }
`;

const fadeColorTransition = (primaryColor: string, secondaryColor: string) => keyframes`
  0% {
    color: ${primaryColor};
  }
  50% {
    color: ${secondaryColor};
  }
  100% {
    color: ${primaryColor};
  }
`;

interface DropzoneSectionProps {
  $isDragActive: boolean;
  $isUploading: boolean;
  $loading: boolean;
  disabled: boolean;
}

export const DropzoneSection = styled.section<DropzoneSectionProps>`
  position: relative;
  display: flex;
  flex-direction: column;
  border: 2px dashed ${({ theme }) => theme.palette.textLightGrey};
  transition: border-color 0.2s linear;
  min-height: 3rem;

  &:hover,
  &:focus {
    border: 2px dashed ${({ theme }) => theme.palette.primaryPlum};
    outline: none !important;

    svg {
      path {
        fill: ${({ theme }) => theme.palette.primaryPlum};
      }
    }
  }

  ${({
    $isDragActive,
    theme: {
      palette: { textLightGrey, primaryPlum }
    }
  }) =>
    $isDragActive &&
    css`
      animation: ${() => onDragTransition(textLightGrey, primaryPlum)} 0.75s infinite;
      svg {
        path {
          animation: ${() => onDragTransition(textLightGrey, primaryPlum)} 0.75s infinite;
        }
      }
    `}

  ${({ disabled, $loading }) =>
    (disabled || $loading) &&
    css`
      border-color: ${({ theme }) => theme.palette.textLightGrey} !important;
      svg {
        path {
          fill: ${({ theme }) => theme.palette.textLightGrey} !important;
        }
      }
    `}

  svg {
    position: absolute;
    height: 1.2rem;
    top: 0.5rem;
    right: 0.5rem;

    path {
      transition: fill 0.2s linear;
    }
  }

  & > span {
    text-align: center;
    line-height: 1rem;
    color: ${({ theme }) => theme.palette.textLightGrey};
    transition: opacity 0.5s linear;

    &.label {
      text-transform: uppercase;
      margin-top: 0.5rem;
      font-family: EavesBold;
      opacity: ${({ $isUploading }) => ($isUploading ? 0 : 1)};
    }

    &.content {
      position: absolute;
      width: 100%;
      top: 0.5rem;
      left: 50%;
      transform: translateX(-50%);
      opacity: ${({ $isUploading }) => ($isUploading ? 1 : 0)};

      ${({
        $isUploading,
        theme: {
          palette: { textLightGrey, primaryPlum }
        }
      }) =>
        $isUploading &&
        css`
          animation: ${() => fadeColorTransition(textLightGrey, primaryPlum)} 1s infinite;
        `}
    }
  }

  button {
    font-size: 1.25rem;
    margin: 0.5rem 1rem 0.5rem;
    height: auto;
    width: auto;
  }
`;

export const ModalSection = styled.section`
  width: 50vw;
  padding: 0 3rem 1rem;

  p {
    margin-bottom: 0.5rem;
    text-align: center;
    color: ${({ theme }) => theme.palette.platinum};

    span {
      font-size: 0.75rem;
      font-style: italic;
    }
  }

  span.separator {
    position: relative;
    display: block;
    margin: 2rem 0;
    width: 100%;
    border-top: 1px ${({ theme }) => theme.palette.platinum} dashed;

    &:before {
      content: 'OR';
      background: white;
      position: absolute;
      font-size: 1.25rem;
      color: ${({ theme }) => theme.palette.platinum};
      left: calc(50% - 1.5rem);
      top: -0.9rem;
      width: 3rem;
      text-align: center;
    }
  }
`;

export const ExternalAssetDiv = styled.div`
  section {
    margin-bottom: 0.5rem;
    border-color: ${({ theme }) => theme.palette.lightGrey};
  }

  section.bottomInputs {
    display: flex;
    justify-content: space-between;
    margin-bottom: 0;

    & > section {
      &:nth-child(2) {
        margin-left: 0.5rem;
        margin-right: 0.5rem;
      }
    }
  }

  ${StyledButton} {
    position: relative;
    margin-left: 100%;
    transform: translateX(-100%);
  }

  ${StyledDropdown} {
    margin-bottom: 0.5rem;
    border-color: ${({ theme }) => theme.palette.lightGrey};

    .btn:disabled:hover {
      background-color: transparent !important;
    }
  }
`;
