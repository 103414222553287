import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { useFlags } from 'launchdarkly-react-client-sdk';
import Login from '~pages/login';
import { ForgotPassword } from '~pages/forgot-password';
import { Notifications } from '~pages/notifications';
import Register from '~pages/register';
import { Settings } from '~pages/settings';
import { Segments } from '~pages/segments';
import Dashboard from '~pages/dashboard';
import { useStoreActions, useStoreState } from '~store/hooks';
import { UsersExplorer } from '~pages/subscriber-explorer';
import { MediaGallery } from '~pages/media';
import ErrorPage from '~pages/ErrorPage';
import { PrivateRoute } from './private-route';
import Maintenance from '~pages/maintenance/maintenance';
import { NOTIFICATION_CHANNEL } from '~pages/notifications/helpers';
import { NOTIFICATION_TYPE } from '@pushologies/database-service/db/entities/notification';
import { RoleGuard, userHasRequiredRole } from '../role-guard';

export const Routes = () => {
  const [{ checkAuth }, { fetchUsers }, { fetchChildTenants, refreshChildTenantSession }] = useStoreActions((state) => [
    state.user,
    state.users,
    state.childTenants
  ]);
  const [{ isAuthenticated }, tenant, childTenants] = useStoreState((state) => [
    state.user,
    state.tenant.tenant,
    state.childTenants
  ]);
  const { maintenanceMode } = useFlags();

  const isAdmin = userHasRequiredRole(tenant?.userProfile?.role?.name, 'Admin');

  React.useEffect(() => {
    checkAuth();
  }, []);

  React.useEffect(() => {
    if (isAuthenticated) {
      fetchUsers({
        limit: 100
      });
      isAdmin && fetchChildTenants();
    }
  }, [isAuthenticated, isAdmin]);

  // if we are a parent viewing a child tenant, we'll need to extend our session every 10 minutes
  React.useEffect(() => {
    if (!childTenants?.sessionApiKey) return;

    const interval = setInterval(refreshChildTenantSession, 1000 * 60 * 10);

    return () => clearInterval(interval);
  }, [childTenants.sessionApiKey, refreshChildTenantSession]);

  return (
    <Switch>
      {maintenanceMode && maintenanceMode.maintenance && (
        <Route path="*">
          <Maintenance />
        </Route>
      )}
      <Route path="/login">
        <Login />
      </Route>
      <Route path="/forgot-password">
        <ForgotPassword />
      </Route>
      <Route path="/register">
        <Register />
      </Route>
      <PrivateRoute exact path="/">
        <Dashboard />
      </PrivateRoute>
      <PrivateRoute path={`/${NOTIFICATION_CHANNEL.Notification}`}>
        <Notifications />
      </PrivateRoute>
      <PrivateRoute path={`/${NOTIFICATION_CHANNEL.InApp}`}>
        <Notifications type={NOTIFICATION_TYPE.INAPP} />
      </PrivateRoute>
      <PrivateRoute path="/subscriber-explorer">
        <UsersExplorer />
      </PrivateRoute>
      <PrivateRoute path="/media">
        <MediaGallery />
      </PrivateRoute>
      {isAdmin && (
        <PrivateRoute path="/settings">
          <Settings />
        </PrivateRoute>
      )}
      <PrivateRoute path="/segments">
        <Segments />
      </PrivateRoute>
      {isAuthenticated ? (
        <RoleGuard requiredRole={'Read Only'}>
          <Route path={'*'} component={ErrorPage} />
        </RoleGuard>
      ) : (
        <Route path={'*'} component={ErrorPage} />
      )}
    </Switch>
  );
};
