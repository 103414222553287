import { action, State, thunk } from 'easy-peasy';
import { arrayMove } from '@dnd-kit/sortable';
import deepmerge from 'deepmerge';
import randomcolor from 'randomcolor';
import { NOTIFICATION_TYPE } from '@pushologies/database-service/db/entities/notification';
import { CONTENT_ITEM_PRESENTATION_TYPE } from '@pushologies/common/constants/content-item';
import { Fonts } from '@pushologies/database-service/db/entities/content';
import { handleUnAuthenticated } from '~store/helpers';
import { CarouselContent } from '~store/create-notification/types';
import { generateRandomStrUID } from '~helpers/files';
import { processStateNotificationForApi } from './helpers';
import { ButtonOptions, CreateNotificationModel } from './types';
import { INAPP_TYPE } from '@pushologies/common/constants/in-app';

export * from './types';

const initialState: State<CreateNotificationModel> = {
  status: 'idle',
  error: null,
  videoDuration: 0,
  notification: {
    type: NOTIFICATION_TYPE.STANDARD,
    buttons: {},
    videoContent: {
      options: {
        orientation: 'portrait',
        zoomMode: 'zoom',
        useEmbeddedPlayer: true,
        allowPreview: true,
        fullScreen: true
      }
    },
    carouselContent: [],
    personalisation: []
  }
};
// tslint:disable:cyclomatic-complexity
export const createNotificationModel: CreateNotificationModel = {
  ...initialState,

  setModel: action((state, payload) => {
    Object.assign(state, payload);
  }),

  resetModel: action((state, notification = {}) => {
    Object.assign(state, deepmerge(initialState, { notification }));
  }),

  setPersonalisation: action((state, payload) => {
    state.notification.personalisation = [
      ...state.notification.personalisation.filter(({ uuid }) => uuid !== payload.uuid),
      payload
    ];
  }),

  deletePersonalisationItems: action((state, ids) => {
    state.notification.personalisation = [
      ...state.notification.personalisation.filter(({ uuid }) => !ids.includes(uuid))
    ];
  }),

  restoreNotificationCopy: action((state, payload) => {
    state.notification = payload;
  }),

  setNotification: action((state, payload) => {
    Object.assign(state.notification, payload);
  }),

  setNotificationFromApi: action((state, { notification, clone }) => {
    let videoContent;
    let buttonOptions: Record<string, ButtonOptions> = {};
    let scheduling;
    let geofence;
    let stagger;
    const carouselContent: CarouselContent[] = [];

    if (notification.type === NOTIFICATION_TYPE.VIDEO || notification.inApp?.type === INAPP_TYPE.VIDEO) {
      const {
        id: videoContentId,
        contentItem,
        ...content
      } = notification.contents.find(({ contentItem: { mediaType } }) => mediaType === 'VIDEO');

      const {
        options: { buttons, ...rest }
      } = content;
      buttonOptions = buttons.reduce((prev, curr, i) => {
        const id = generateRandomStrUID(10);
        const buttonAppear = curr?.buttonAppear ?? 0;
        const buttonDisappear =
          curr?.buttonDisappear ?? (contentItem.videoDuration ? contentItem.videoDuration * 1000 : Infinity);
        prev[id] = {
          id,
          displayName: curr?.displayName || `Button ${i + 1}`,
          controlsColor: randomcolor({ luminosity: 'random', hue: 'random' }),
          // if button width/height doesn't exist just set defualts
          width: curr?.position ? curr.position.right - curr.position.left : 200,
          height: curr?.position ? curr.position.bottom - curr.position.top : 150,
          options: {
            // if button timestamps dont exist just set defaults
            buttonAppear,
            buttonDisappear,
            // if button position doesn't exist just set defualts
            position: curr?.position ?? {
              left: contentItem.width / 2,
              top: contentItem.height / 2,
              bottom: null,
              right: null
            },
            // if button font size doesn't exist just set defualt
            fontSize: curr?.fontSize ? Number(curr.fontSize) : 50,
            font: curr.font ?? Fonts.Arial,
            backgroundColor: curr.backgroundColor,
            borderColor: curr.borderColor,
            contentItemId: curr.contentItemId,
            fullScreen: curr.fullScreen,
            linkHandling: curr.linkHandling,
            text: curr.text,
            textColor: curr.textColor,
            url: curr.url,
            textAlignmentVertical: curr.textAlignmentVertical || 'center',
            textAlignmentHorizontal: curr.textAlignmentHorizontal || 'center',
            textWrapping: curr.textWrapping || false,
            buttonImageUrl: curr?.buttonImageUrl,
            tagName: curr?.tagName
          }
        };
        return prev;
      }, buttonOptions);

      videoContent = {
        ...content,
        contentItem: { id: contentItem.id },
        ...(!clone && { id: videoContentId }),
        options: {
          ...state.notification?.videoContent?.options,
          ...rest
        }
      };
    }

    if (notification.type === NOTIFICATION_TYPE.CAROUSEL || notification.inApp?.type === INAPP_TYPE.CAROUSEL) {
      notification.contents
        .filter(({ presentation }) => presentation === CONTENT_ITEM_PRESENTATION_TYPE.CAROUSEL)
        .sort(({ sortOrder: a }, { sortOrder: b }) => a - b)
        .forEach((item) => {
          carouselContent.push({
            id: item.id,
            contentItem: { id: item.contentItem.id },
            url: item.options.buttons[0].url
          });
        });
    }

    const bannerContent = notification.contents.find(
      ({ contentItem }) =>
        contentItem.mediaType === 'IMAGE' && contentItem.presentation.includes(CONTENT_ITEM_PRESENTATION_TYPE.BANNER)
    );

    const richImageContent = notification.contents.find(
      ({ contentItem }) =>
        contentItem.mediaType === 'IMAGE' &&
        contentItem.presentation.includes(CONTENT_ITEM_PRESENTATION_TYPE.RICH_IMAGE)
    );

    if (notification.schedule) {
      scheduling = {
        mode: notification.schedule.mode,
        pushAt: notification.schedule.pushAt
      };
    }

    if (notification.stagger) {
      stagger = notification.stagger;
    }

    if (notification.geofence) {
      geofence = {
        startDate: notification.geofence.startDate,
        endDate: notification.geofence.endDate,
        trigger: notification.geofence.trigger,
        ...(notification.geofence.vertices
          ? { vertices: notification.geofence.vertices }
          : {
              longitude: notification.geofence.location.longitude,
              latitude: notification.geofence.location.latitude,
              radius: notification.geofence.radius
            })
      };
    }

    if (notification.type === NOTIFICATION_TYPE.INAPP && notification.inApp?.type === INAPP_TYPE.IMAGE) {
      delete notification.inApp?.closeLabel;
    }

    Object.assign(state.notification, {
      ...(!clone && { id: notification.id }),
      type: notification.type,
      title: notification.title,
      subTitle: notification.subTitle,
      message: notification?.message,
      ...(notification.targetUrl && { targetUrl: notification.targetUrl }),
      ...(notification.metadata && { metadata: notification.metadata }),
      ...(notification.clientMetadata && { clientMetadata: notification.clientMetadata }),
      personalisation: notification?.personalisation ?? [],
      segment: { id: notification?.segment?.id },
      ...(bannerContent && {
        bannerContent: {
          ...state.notification?.bannerContent,
          ...bannerContent,
          ...(!clone && { id: bannerContent.id })
        }
      }),
      ...(richImageContent && {
        richImageContent: {
          ...state.notification?.richImageContent,
          ...richImageContent,
          ...(!clone && { id: richImageContent.id })
        }
      }),
      stagger,
      videoContent,
      buttons: buttonOptions || {},
      scheduling,
      geofence,
      inApp: notification.inApp,
      pollId: notification.pollId,
      carouselContent
    });
  }),

  setButton: action((state, payload) => {
    const { id, ...rest } = payload;

    state.notification.buttons[id] = Object.assign(
      {},
      deepmerge(state.notification.buttons[id], { id, ...rest } as any)
    );
  }),

  deleteButton: action((state, { id }) => {
    delete state.notification.buttons[id];
  }),

  setScheduling: action((state, payload) => {
    if (!payload) {
      state.notification.scheduling = undefined;
    } else {
      state.notification.scheduling = Object.assign({}, state.notification.scheduling, payload);
    }
  }),

  setGeofencing: action((state, payload) => {
    if (!payload) {
      state.notification.geofence = undefined;
    } else {
      state.notification.geofence = Object.assign({}, state.notification.geofence, payload);
      state.notification.stagger = undefined;
    }
  }),

  setStaggering: action((state, payload) => {
    if (!payload) {
      state.notification.stagger = undefined;
    } else {
      state.notification.stagger = Object.assign({}, state.notification.stagger, payload);
      state.notification.geofence = undefined;
    }
  }),

  setInApp: action((state, payload) => {
    if (!payload) {
      state.notification.inApp = undefined;
    } else {
      state.notification.inApp = Object.assign({}, state.notification.inApp, payload);
    }
  }),

  setVideoContent: action((state, payload) => {
    state.notification.videoContent = {
      ...state.notification?.videoContent,
      ...payload,
      options: {
        ...state.notification?.videoContent?.options,
        ...payload?.options
      }
    };
  }),

  setContent: action((state, { type, content }) => {
    if (!content) return;

    const key = type === CONTENT_ITEM_PRESENTATION_TYPE.RICH_IMAGE ? 'richImageContent' : 'bannerContent';
    state.notification[key] = {
      ...state.notification?.[key],
      ...content
    };
  }),

  deleteContent: action((state) => {
    delete state.notification.bannerContent;
    delete state.notification.richImageContent;
  }),

  setCarouselButtons: action((state, payload) => {
    // handle de-selection
    if (!payload.length) {
      // we must remove "options" entirely unless it's being used elsewhere
      if (Object.keys(state.notification.options).length === 1) {
        delete state.notification.options;
        return;
      }
      delete state.notification.options.carousel;
      return;
    }

    state.notification.options = Object.assign({}, state.notification.options, {
      carousel: {
        navButtons: {
          previous: {
            contentItemId: payload[0] || ''
          },
          next: {
            contentItemId: payload.length === 2 ? payload[1] : ''
          }
        }
      }
    });
  }),

  addCarouselItem: action((state, { contentItemId }) => {
    state.notification.carouselContent.push({
      contentItem: { id: contentItemId }
    });
  }),

  updateCarouselItem: action((state, { currentIndex, url, newIndex }) => {
    if (typeof url === 'string') {
      const item = state.notification.carouselContent[currentIndex];
      state.notification.carouselContent = [
        ...state.notification.carouselContent.slice(0, currentIndex),
        { ...item, url },
        ...state.notification.carouselContent.slice(currentIndex + 1)
      ];
    }

    if (typeof newIndex === 'number') {
      state.notification.carouselContent = arrayMove(state.notification.carouselContent, currentIndex, newIndex);
    }
  }),

  deleteCarouselItem: action((state, { index }) => {
    state.notification.carouselContent = [
      ...state.notification.carouselContent.slice(0, index),
      ...state.notification.carouselContent.slice(index + 1)
    ];
  }),

  saveNotification: thunk(async (actions, payload, { injections, getStoreActions, getState }) => {
    actions.setModel({ status: 'saving' });

    try {
      const { notification } = await injections.notificationsApi.save(processStateNotificationForApi(getState()));

      actions.setModel({
        status: 'idle',
        error: null
      });
      actions.setNotification({
        id: notification.id,
        segment: {
          id: notification?.segment?.id
        },
        campaign: {
          id: notification?.campaign?.id
        }
      });

      const bannerContent = notification.contents.find(
        ({ contentItem: { mediaType, presentation } }) =>
          mediaType === 'IMAGE' && presentation.includes(CONTENT_ITEM_PRESENTATION_TYPE.BANNER)
      );
      const richImageContent = notification.contents.find(
        ({ contentItem: { mediaType, presentation } }) =>
          mediaType === 'IMAGE' && presentation.includes(CONTENT_ITEM_PRESENTATION_TYPE.RICH_IMAGE)
      );

      bannerContent &&
        actions.setContent({
          type: CONTENT_ITEM_PRESENTATION_TYPE.BANNER,
          content: bannerContent
        });
      richImageContent &&
        actions.setContent({
          type: CONTENT_ITEM_PRESENTATION_TYPE.RICH_IMAGE,
          content: richImageContent
        });
      actions.setVideoContent(notification.contents.find(({ contentItem: { mediaType } }) => mediaType === 'VIDEO'));

      if (payload && payload.onSuccess) payload.onSuccess(notification);

      getStoreActions().notificationWidget.addNotification({
        type: 'success',
        message: 'Successfully saved Notification'
      });
    } catch (error) {
      await handleUnAuthenticated(error, getStoreActions());

      getStoreActions().notificationWidget.addNotification({
        type: 'error',
        message: error.message
      });

      actions.setModel({ status: 'idle', error: error.message });
      if (payload && payload.onError) payload.onError(error.message);
    }
  }),

  pushNotification: thunk(async (actions, payload, { injections, getStoreActions, getState }) => {
    actions.setModel({ status: 'pushing' });

    try {
      const { tenantSegmentMap } = payload || {};
      const processedNotification = processStateNotificationForApi(getState());

      const { notification } = await (tenantSegmentMap
        ? injections.notificationsApi.pushByProxy(processedNotification, tenantSegmentMap)
        : injections.notificationsApi.push(processedNotification));

      actions.setModel({ ...initialState });
      getStoreActions().notificationWidget.addNotification({
        type: 'success',
        message: 'Successfully pushed notification'
      });

      if (payload && payload.onSuccess) payload.onSuccess(notification.id);
    } catch (error) {
      await handleUnAuthenticated(error, getStoreActions());

      getStoreActions().notificationWidget.addNotification({
        type: 'error',
        message: error.message
      });
      actions.setModel({ status: 'idle', error: error.message });

      if (payload && payload.onError) payload.onError(error.message);
    }
  }),

  persistNotification: thunk(async (actions, { notificationId, clone, onComplete }, { getStoreActions }) => {
    getStoreActions().notifications.fetchNotification({
      id: notificationId,
      getDownloadUrl: true,
      onSuccess(notification) {
        actions.setNotificationFromApi({ notification, clone });
        onComplete && onComplete();
      }
    });
  })
};
