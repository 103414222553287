import React from 'react';
import ISO from 'iso-3166-1';
import { Suggestion } from '~components/inputs/autosuggest-field';
import { AutoSuggestChipField } from '~components/inputs/chip-field/autosuggest';
import { Rule } from '~store/create-segment/types';

interface Props {
  rule: Rule<string[]>;
  invalid: boolean;
  onChange(selected: string[]): void;
}

const getLabel = (value: string) => {
  return ISO.all().find((item) => item.alpha2 === value).country;
};

export const CountryCodeChipfield: React.FC<Props> = ({ rule, invalid, onChange }) => {
  const [suggestions, setSuggestions] = React.useState<Suggestion<string>[]>([]);
  const [loading, setLoading] = React.useState(false);
  const chips: Suggestion<string>[] = rule?.segmentRule?.value
    ? rule?.segmentRule?.value.map((value) => ({
        label: getLabel(value),
        value
      }))
    : [];

  const handleChange = (codes: Suggestion<string>[]) => {
    onChange(codes.map((code) => code.value));
  };

  const handleInputChange = (text: string) => {
    setLoading(true);

    const options = ISO.all().filter((item) => item.country.toLowerCase().match(text.toLowerCase()));

    setSuggestions(
      options.map(({ country, alpha2 }) => ({
        label: country,
        value: alpha2
      }))
    );
    setLoading(false);
  };

  return (
    <AutoSuggestChipField<string>
      chips={chips}
      onChange={handleChange}
      onInputChange={handleInputChange}
      placeholder="Search country codes"
      invalid={invalid}
      suggestions={suggestions}
      loading={loading}
      testId="countryCodeChipField"
    />
  );
};
