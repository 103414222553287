import { AxiosInstance } from 'axios';
import {
  Measurement as MeasurementEntity,
  MEASUREMENT_TYPE,
  MEASUREMENT_INTERVAL
} from '@pushologies/database-service/db/entities/measurement';
import { instance } from './tools/axios-instance';
import { refreshAuthToken } from './tools/auth.decorater';
import { FetchParams as BaseFetchParams } from './tools/types';

export interface FetchParams extends BaseFetchParams {
  types?: MEASUREMENT_TYPE[];
  intervals?: MEASUREMENT_INTERVAL[];
  from?: Date;
  to?: Date;
}

export interface Measurement extends Omit<MeasurementEntity, 'tenant' | 'startsAt'> {
  startsAt: string;
}

export interface Measurements {
  id?: string;
  measurements: Measurement[];
  totalItems: number;
}

export class MeasurementApiClass {
  constructor(private axios: AxiosInstance) {}

  @refreshAuthToken()
  async fetchMeasurements(params: FetchParams): Promise<{
    measurements: Measurement[];
    totalItems: number;
  }> {
    const response = await this.axios.get('/measurements', { params });
    return response.data.response;
  }

  @refreshAuthToken()
  async fetchMeasurementsByProxy(
    params: FetchParams = {},
    tenantIds: string[]
  ): Promise<{
    measurements: Measurements[];
  }> {
    const {
      data: {
        response: { responses: responseBody }
      }
    } = await this.axios.post('/proxy-call', {
      endpoint: '/v1/measurements',
      method: 'GET',
      requests: tenantIds.map((id) => {
        return { tenantId: id, queryStringParameters: params };
      })
    });

    const measurementsArr = responseBody.map((res: any) => {
      return { id: res.tenantId, ...res.responseBody.response };
    });

    return { measurements: measurementsArr };
  }
}
export const measurementsApi = new MeasurementApiClass(instance);
