import React, { useCallback } from 'react';
import { Select, SelectOption } from '~components/select';
import { Rule } from '~store/create-segment/types';

interface Props {
  rule: Rule;
  invalid: boolean;
  onChange(selected: string[]): void;
}

const options: SelectOption[] = [
  {
    name: 'iOS',
    value: 'IOS'
  },
  {
    name: 'Android',
    value: 'ANDROID'
  }
];

export const DeviceSelect: React.FC<Props> = ({ rule, invalid, onChange }) => {
  const value = options.filter((option) => rule.segmentRule?.value?.includes(option.value));

  const handleOnChange = useCallback((selected: SelectOption[]) => {
    onChange(selected.map((item) => item.value));
  }, []);

  return (
    <Select
      id={rule.segmentRule.ruleId}
      value={value}
      options={options}
      onChange={handleOnChange}
      multi
      drop="down"
      placeholder="Select devices"
      inValid={invalid}
    />
  );
};
