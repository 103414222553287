import styled from 'styled-components';
import { TilesViewer } from '~components/file-viewers/tiles';
import { InputDiv } from '~components/file-viewers/styles';
import { HideScrollbars } from '~styles/mixins';
import { TilesViewerDiv } from '~components/file-viewers/tiles/styles';

export const StyledTilesViewer = styled(TilesViewer)`
  height: 100%;

  section.viewer {
    border-color: ${({ theme }) => theme.palette.platinum};
  }

  header {
    color: ${({ theme }) => theme.palette.textGrey};
    background: ${({ theme }) => theme.palette.lightGrey};
    border-bottom: 1px solid ${({ theme }) => theme.palette.platinum};
    height: 2rem;

    ${InputDiv} {
      width: 40%;
    }
  }
`;

export const ImageArticle = styled.article`
  position: relative;
  display: inline-block;
  background: ${({ theme }) => theme.palette.darkenedWhite};
  border: 1px solid ${({ theme }) => theme.palette.platinum};
  padding: 0.5rem;
  margin: 0.5rem;
`;

export const TopDiv = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 2rem;

  p {
    display: inline-block;
    width: calc(100% - 1.5rem);
    overflow: scroll;
    white-space: nowrap;
    color: ${({ theme }) => theme.palette.platinum};
    font-size: 0.9rem;
    margin: 0;

    ${HideScrollbars}
  }

  section {
    position: relative;
    display: inline-block;

    span {
      display: inline-block;
      width: 1rem;
      cursor: pointer;

      svg {
        width: 100%;

        path {
          fill: ${({ theme }) => theme.palette.platinum};
        }
      }

      &:hover {
        svg path {
          fill: ${({ theme }) => theme.palette.primaryPlum};
        }
      }
    }
  }
`;

interface ImgDivProps {
  url: string;
}

export const ImgDiv = styled.div<ImgDivProps>`
  position: relative;
  display: block;
  width: 100%;
  height: 7rem;
  background: url(${({ url }) => url});
  background-size: cover;
  background-position: center;
`;

export const ImageListDiv = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: calc(100% - 3rem);

  button {
    margin-bottom: 0.5rem;
  }

  ${TilesViewerDiv} {
    height: calc(100% - 2rem);
  }
`;
