import React, { forwardRef } from 'react';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { useStoreState, useStoreActions } from '~store/hooks';
import { PersonalisationTextField } from '~components/inputs';
import { CarouselContent } from '~store/create-notification/types';
import DeleteIcon from '~images/delete.svg';
import SwapIcon from '~images/swap.svg';
import PlusIcon from '~images/plus.svg';
import { CarouselItemArticle, TopHeader, ImageDiv } from './styles';
import { SyntheticListenerMap } from '@dnd-kit/core/dist/hooks/utilities';

interface CarouselItemProps extends DraggableCarouselItemProps {
  style?: React.CSSProperties;
  draggableProps?: {
    listeners: SyntheticListenerMap;
    attributes: object;
  };
}

export const CarouselItem = forwardRef<HTMLElement, CarouselItemProps>(
  ({ index, item, style, draggableProps }, ref) => {
    const { deleteCarouselItem, updateCarouselItem } = useStoreActions((state) => state.createNotification);
    const { fetchItem } = useStoreActions((state) => state.contentItems);
    const { items } = useStoreState((state) => state.contentItems);
    const contentItem = items[item?.contentItem?.id];

    const handleDelete = () => {
      deleteCarouselItem({ index });
    };

    const handleUrlChange = (url: string) => {
      updateCarouselItem({ currentIndex: index, url });
    };

    React.useEffect(() => {
      if (item) fetchItem({ id: item?.contentItem?.id, url: true });
    }, [item?.contentItem?.id]);

    return (
      <CarouselItemArticle ref={ref} style={style} hasItem={!!item} data-testid="carousalItem">
        <TopHeader>
          <h2>Slide {index + 1}</h2>
          <span role="button" onClick={handleDelete}>
            <DeleteIcon />
          </span>
          <span
            {...draggableProps?.attributes}
            {...draggableProps?.listeners}
            className="handle"
            aria-roledescription="Delete carousel item"
          >
            <SwapIcon />
          </span>
        </TopHeader>
        <ImageDiv url={contentItem?.downloadUrl}>
          <p>{contentItem?.name}</p>
          <section>
            Add (
            <span>
              <PlusIcon />
            </span>
            ) images from left
          </section>
        </ImageDiv>
        <PersonalisationTextField
          id={item?.contentItem?.id ?? 'carouselItemUrl'}
          value={item?.url}
          label={!!item ? 'Url:' : null}
          onChange={handleUrlChange}
          disabled={!item}
        />
      </CarouselItemArticle>
    );
  }
);

interface DraggableCarouselItemProps {
  index: number;
  item?: CarouselContent;
}

export const DraggableCarouselItem: React.FC<DraggableCarouselItemProps> = ({ index, item }) => {
  const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({
    id: item.contentItem.id
  });
  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    opacity: isDragging ? 0 : 1
  };

  return (
    <CarouselItem ref={setNodeRef} style={style} draggableProps={{ listeners, attributes }} index={index} item={item} />
  );
};
