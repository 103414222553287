import React, { MouseEvent } from 'react';
import { dateTimeFormat } from '~helpers/formats';
import { ApiPollWithResults } from '~api/polling/types';
import { ActionsTd } from '~components/styled/table-section';
import EyeIcon from '~images/eye.svg';
import EditIcon from '~images/edit.svg';
import DeleteIcon from '~images/delete.svg';
import CloneIcon from '~images/clone.svg';
import { DeleteSpan } from './styles';
import { PromptButton } from '~components/prompt-button';
import { useStoreActions } from '~store/hooks';
import { Spinner } from 'react-bootstrap';
import { POLL_UPSERT_SUCCESS_MESSAGES } from '~store/polls';

interface Props {
  poll: ApiPollWithResults;
  onSelectPoll: (poll: ApiPollWithResults) => void;
  onEditPoll: (poll: ApiPollWithResults) => void;
  onClonePoll: () => void;
  onDeletePoll: (pollId: string) => void;
  selected: boolean;
}

export const PollRow: React.FC<Props> = ({ poll, onSelectPoll, onEditPoll, onDeletePoll, onClonePoll, selected }) => {
  const [isDeleting, setIsDeleting] = React.useState(false);
  const { upsertPoll, deletePoll } = useStoreActions((state) => state.polls);

  const handlePollEdit = (event: MouseEvent<HTMLSpanElement>) => {
    event.stopPropagation();
    onEditPoll(poll);
  };
  const handlePollClone = (event: MouseEvent<HTMLSpanElement>) => {
    event.stopPropagation();
    upsertPoll({
      poll: {
        ...poll,
        id: undefined,
        question: poll.question.translations[0].value,
        answers: poll.pollChoices.map(({ text, tagName }) => ({ text: text.translations[0].value, tagName })),
        voteSuccessMessage: poll.voteSuccessMessage?.translations[0].value
      },
      successMessage: POLL_UPSERT_SUCCESS_MESSAGES.CLONE,
      onSuccess() {
        onClonePoll();
        setIsDeleting(false);
      },
      onError() {
        setIsDeleting(false);
      }
    });
  };
  const handlePollDelete = () => {
    setIsDeleting(true);
    deletePoll({
      id: poll.id,
      onSuccess() {
        onDeletePoll(poll.id);
        setIsDeleting(false);
      },
      onError() {
        setIsDeleting(false);
      }
    });
  };

  return (
    <tr data-testid="pollRow" onClick={() => onSelectPoll(poll)} className={selected ? 'selected-poll' : ''}>
      <td>{poll.question.translations.find((label) => label.locale === 'en-GB').value}</td>
      <td>
        <ul>
          {poll.pollChoices.map((choice: ApiPollWithResults['pollChoices'][0]) => (
            <li key={choice.id}>
              {
                choice.text.translations.find((label: { locale: string; value: string }) => label.locale === 'en-GB')
                  .value
              }
            </li>
          ))}
        </ul>
      </td>
      <td>{poll.totalVoteCount}</td>
      <td>{dateTimeFormat(poll.createdAt)}</td>
      <ActionsTd>
        <span className="eye" data-testid="pollViewButton">
          <a
            href={`${process.env.WEB_APP_UI_URL}/poll/${poll.id}`}
            target={'_blank'}
            // viewing a poll shouldn't set is as the "selected" poll
            onClick={(e) => e.stopPropagation()}
          >
            <EyeIcon />
          </a>
        </span>

        <span onClick={handlePollEdit} data-testid="pollEditButton">
          <EditIcon />
        </span>
        <span onClick={handlePollClone} data-testid="pollCloneButton">
          <CloneIcon />
        </span>
        <DeleteSpan isDeleting={isDeleting} data-testid="pollDeleteButton">
          <PromptButton
            buttonType="icon"
            buttonIcon={<DeleteIcon />}
            onConfirm={handlePollDelete}
            confirmButtonText="delete"
            promptTitle="Confirm Delete"
          >
            Are you sure you would like to delete poll "{poll.question.translations[0].value}"?
          </PromptButton>
          {isDeleting && <Spinner animation="border" size="sm" />}
        </DeleteSpan>
      </ActionsTd>
    </tr>
  );
};
