import { SideNavLinks } from '~store/sidenav/types';

export const notificationsPageLinks: SideNavLinks[] = [
  {
    name: 'View All Notifications',
    path: '/notifications'
  },
  {
    name: 'Create Notification',
    path: '/notifications/create'
  }
];
