import styled, { css } from 'styled-components';

const placeholderStyles = css`
  font-family: EavesRegular;
  font-size: 0.9rem;
  letter-spacing: 0;
  color: ${({ theme }) => theme.palette.lightGrey};
  text-transform: uppercase;
`;

interface InputSectionProps {
  invalid?: boolean;
  $disabled?: boolean;
}

export const InputSection = styled.section<InputSectionProps>`
  position: relative;
  border: 1px solid
    ${({ theme, invalid, $disabled }) => {
      if ($disabled) return theme.palette.lightGrey;
      if (invalid) return 'red !important';
      return theme.palette.platinum;
    }};
  border-radius: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 0.5rem;
  background: white;
  height: 100%;
  min-height: 1.9rem;

  label {
    font-family: EavesRegular;
    font-size: 0.9rem;
    letter-spacing: 0;
    color: ${({ theme, $disabled }) => ($disabled ? theme.palette.lightGrey : theme.palette.platinum)};
    text-transform: uppercase;
    margin: 0 0.5rem 0 0;
    line-height: 0.9rem;
    white-space: nowrap;

    span {
      color: ${({ theme }) => theme.palette.darkGrey};
      font-size: 0.85rem;
    }
  }

  input,
  textarea {
    border: none;
    width: 100%;
    color: ${({ theme, $disabled }) => ($disabled ? theme.palette.lightGrey : theme.palette.primaryPlum)};
    font-size: 1.1rem;
    font-family: EavesRegular;

    ::-webkit-input-placeholder {
      /* Edge */
      ${placeholderStyles}
    }
    :-ms-input-placeholder {
      /* Internet Explorer 10-11 */
      ${placeholderStyles}
    }
    ::placeholder {
      ${placeholderStyles}
    }
  }

  input {
    height: 100%;
  }

  ${({ $disabled }) =>
    $disabled &&
    css`
      cursor: no-drop;
      pointer-events: none !important;
    `}
`;
