import { RGBColor } from 'react-color';
import { hexToRgba, rgbaToHex } from 'hex-and-rgba';

export const rgbColorToRgbaString = ({ r, g, b, a }: RGBColor) => `rgba(${r}, ${g}, ${b}, ${a})`;
export const rgbColorToHexString = ({ r, g, b, a }: RGBColor) => rgbaToHex(r, g, b, a);

/**
 * Converts a hex/rgb/rgba string to the RGBColor format needed by ColorPickerField.
 * If color is undefined returns white opaque value
 * @param color hex/rgb/rgba string
 */
export const colorStringToRgbaColor = (color: string): RGBColor => {
  if (!color) return { r: 255, g: 255, b: 255, a: 0 };

  if (color.startsWith('#')) {
    const [r, g, b, a] = hexToRgba(color);
    return { r, g, b, a };
  }

  if (color.startsWith('rgb')) {
    const [r, g, b, a] = color.substring(color.indexOf('(') + 1, color.length - 1).split(',');
    return { r: Number(r), g: Number(g), b: Number(b), a: Number(a || '1') };
  }

  throw new Error('"color" must be of type hex or rgb/rgba');
};
