import styled, { css } from 'styled-components';
import EyeIcon from '~images/eye.svg';
import AndroidIcon from '~images/android.svg';
import AppleIcon from '~images/apple.svg';

export const StyledEye = styled(EyeIcon)`
  width: 1.5rem;
  height: 1.5rem;
  cursor: pointer;
  path {
    fill: ${({ theme }) => theme.palette.primaryMustard};
  }

  &:hover {
    filter: invert(0.5) sepia(1) hue-rotate(600deg) saturate(20) brightness(0.9);
  }

  ${({ active }) =>
    active &&
    css`
      filter: invert(0.5) sepia(1) hue-rotate(600deg) saturate(20) brightness(1) !important;
    `}
`;

export const AndroidIconStyled = styled(AndroidIcon).attrs({
  className: 'androidIcon'
})`
  transition: fill 0.2s linear;
  width: 2.45rem;
  margin-right: 0.75rem;

  path {
    fill: ${({ theme }) => theme.palette.android};
  }
`;

export const AppleIconStyled = styled(AppleIcon).attrs({
  className: 'appleIcon'
})`
  transition: fill 0.2s linear;
  width: 2.45rem;
  margin-right: 0.75rem;

  path {
    fill: ${({ theme }) => theme.palette.apple};
  }
`;
