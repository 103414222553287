import { thunk, action } from 'easy-peasy';
import { MEASUREMENT_INTERVAL } from '@pushologies/database-service/db/entities/measurement';
import { handleUnAuthenticated } from '~store/helpers';
import { MeasurementsModel } from './types';

export * from './types';

export const measurementsModel: MeasurementsModel = {
  error: null,
  activeInterval: MEASUREMENT_INTERVAL.DAILY,

  setActiveInterval: action((state, interval) => {
    state.activeInterval = interval;
  }),

  fetchMeasurements: thunk(
    async (_, { onSuccess, onError, tenantIds, ...payload }, { injections, getStoreActions }) => {
      try {
        // split calls to separate interval calls to not hit response payload size limit
        const promises = payload.intervals.map((interval) =>
          tenantIds
            ? injections.measurementsApi.fetchMeasurementsByProxy(
                {
                  ...payload,
                  intervals: [interval]
                },
                tenantIds
              )
            : injections.measurementsApi.fetchMeasurements({
                ...payload,
                intervals: [interval]
              })
        );
        const responses = await Promise.all(promises);
        const measurements = responses.reduce((prev, curr) => {
          return prev.concat(curr.measurements);
        }, []);

        if (onSuccess) onSuccess(measurements);
      } catch (error) {
        await handleUnAuthenticated(error, getStoreActions());

        getStoreActions().notificationWidget.addNotification({
          type: 'error',
          message: error.message
        });

        if (onError) onError(error.message);
      }
    }
  )
};
