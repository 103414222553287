import styled from 'styled-components';

export const OverlayDiv = styled.div`
  border: 1px solid ${({ theme }) => theme.palette.primaryMustard};
  border-radius: 0;
  padding-bottom: 0;
  margin: 0.2rem 0 0 0.5rem;
  box-shadow: 10px 10px 15px -7px rgba(0, 0, 0, 0.2);
  max-width: 40rem;
  max-height: 50vh;
  overflow-y: scroll;
  z-index: 1000;
  background: white;
`;
