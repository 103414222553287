import { SelectQueryBuilder } from 'typeorm';
import { Subscriber } from '@pushologies/database-service/db/entities/subscriber';
import { OperatorValue as Op, SegmentRule } from '../types';
import { Rule } from '.';

export class LocationPermissionRule extends Rule {
  constructor(private rule: SegmentRule<boolean>) {
    super();
  }

  updateQuery(query: SelectQueryBuilder<Subscriber>) {
    const [sql, params] = this.getQueryString();
    query.andWhere(sql, params);
  }

  private getQueryString(): [string, object] {
    const permissionLocationParam = this.createParameterHash();
    if (this.rule.operator === Op.is) {
      return [
        `subscriber.permissions_location = :${permissionLocationParam}`,
        { [permissionLocationParam]: this.rule.value }
      ];
    }

    throw new Error(`operator "${this.rule.operator}" not supported`);
  }
}
