import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { Form, Container, Row, Col, Spinner } from 'react-bootstrap';
import { StyledFormHeader, StyledFormButton } from '~components/styled/form';
import { useStoreActions, useStoreState } from '~store/hooks';
import Logo from '~images/logo-strapline.svg';
import { LogoWrapperDiv, StyledForm, ErrorMessageP, StyledRow } from './styles';
import { formInputsToNameValueObject } from '~helpers/forms';

export const PasswordVerify: React.FC = () => {
  const history = useHistory();
  const { error, loading } = useStoreState(({ user }) => user);
  const [{ setUserModel, forgotPassword }, { setSideNavModel }] = useStoreActions(({ user, sideNav }) => [
    user,
    sideNav
  ]);
  const formRef = React.useRef();

  const onSubmit = useCallback(async (e: React.MouseEvent | React.KeyboardEvent) => {
    e.preventDefault();
    e.stopPropagation();

    const { email } = formInputsToNameValueObject(formRef.current, ['email']);
    forgotPassword({
      email,
      onSuccess() {
        history.push('/forgot-password/code', { email });
      }
    });
  }, []);

  React.useEffect(() => {
    setSideNavModel({ topBarLeftTitle: 'Forgot Password', activeChild: 'forgotPassword' });
    return () => setUserModel({ loading: false, error: null });
  }, []);

  return (
    <Container id="page-container">
      <StyledRow className="justify-content-md-center">
        <Col md="auto">
          <LogoWrapperDiv>
            <Logo />
          </LogoWrapperDiv>
          <StyledForm ref={formRef}>
            <StyledFormHeader data-testid="forgotHeader">reset password</StyledFormHeader>
            <Form.Group controlId="email" data-testid="emailFormGroup">
              <Form.Label>Email</Form.Label>
              <Form.Control type="email" name="email" />
            </Form.Group>
            <Form.Group>
              <StyledFormButton variant="primary" onClick={onSubmit} disabled={loading} data-testid="submitButton">
                {loading ? <Spinner animation="border" size="sm" /> : 'Send Verification Code'}
              </StyledFormButton>
            </Form.Group>
            <Row>{error && <ErrorMessageP data-testid="errorMessage">{error}</ErrorMessageP>}</Row>
          </StyledForm>
        </Col>
      </StyledRow>
    </Container>
  );
};
