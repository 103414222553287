import React from 'react';
import { PersonalisationConfigItemType } from '@pushologies/database-service/db/entities/notification';
import { PersonalisationItem } from './types';
import { useStoreActions, useStoreState } from '~store/hooks';
import { getPersonalisationDropdownOptions } from './helpers';
import { placeCaretAtEnd } from '~helpers/dom';
import { PersonalisationValueSpan, StyledButton, StyledPopover } from './styles';
import { Overlay, Popover } from 'react-bootstrap';
import { ApiPersonalisationVariable } from '~api/personalisation-variables';

interface Props {
  id: string;
  value: PersonalisationItem;
  showPersonalisationModal(id: string): void;
  onUpdatePersonalisation(id: string, config: PersonalisationConfigItemType): void;
}

export const PersonalisationValue: React.FC<Props> = (props) => {
  const ref = React.useRef<HTMLSpanElement>();
  const [search, setSearch] = React.useState('');
  const [activeBtnIndex, setActiveBtnIndex] = React.useState(0);
  const { personalisationVariables } = useStoreState((state) => state.personalisationVariables);
  const { fetchPersonalisationSchema } = useStoreActions((state) => state.personalisationVariables);
  const isComplete = !!props.value?.config?.name;
  const options = getPersonalisationDropdownOptions(search, personalisationVariables);

  const handleOnClick: React.MouseEventHandler = (event) => {
    if (!isComplete) {
      event.stopPropagation();
      event.preventDefault();
      return;
    }

    props.showPersonalisationModal(props.value.id);
  };

  const handleKeyUp: React.KeyboardEventHandler = (event) => {
    setSearch((event.target as HTMLSpanElement).textContent);

    if (!event.key.startsWith('Arrow')) setActiveBtnIndex(0);
  };

  const handleKeyDown: React.KeyboardEventHandler = (event) => {
    switch (event.key) {
      case 'Tab':
      case 'ArrowLeft':
      case 'ArrowRight': // disable
        event.preventDefault();
        event.stopPropagation();
        break;
      case 'ArrowDown':
        setActiveBtnIndex((activeBtnIndex + 1) % options.length);
        event.preventDefault();
        event.stopPropagation();
        break;
      case 'ArrowUp':
        setActiveBtnIndex((activeBtnIndex === 0 ? options.length : activeBtnIndex) - 1);
        event.preventDefault();
        event.stopPropagation();
        break;
      case 'Enter':
        const option = options[activeBtnIndex];
        handleUpdatePersonalisation(option.value)();
        event.preventDefault();
        event.stopPropagation();
        break;
      default:
        // only allow letters, numbers, underscores and dashes
        if (!/^[A-Za-z0-9_-]*$/.exec(event.key)) {
          event.preventDefault();
          event.stopPropagation();
        }
        break;
    }
  };

  const handleUpdatePersonalisation = (config: ApiPersonalisationVariable) => () => {
    props.onUpdatePersonalisation(props.value.id, {
      ...props.value.config,
      ...config
    });
    setSearch('');
  };

  React.useEffect(() => {
    if (!isComplete) {
      ref.current.focus();
      ref.current.textContent = ref.current.innerText;
      if (!!props.value.config?.name) placeCaretAtEnd(ref.current);
    }
  }, [isComplete, props.value.config]);

  React.useEffect(() => {
    fetchPersonalisationSchema();
  }, []);

  return (
    <>
      <PersonalisationValueSpan
        id={props.id}
        onClick={handleOnClick}
        onKeyUp={handleKeyUp}
        onKeyDown={handleKeyDown}
        ref={ref}
        contentEditable={!isComplete}
        suppressContentEditableWarning={true}
        role={isComplete ? undefined : 'textbox'}
        $editable={!isComplete}
      >
        {props.value?.config?.name}
      </PersonalisationValueSpan>
      <Overlay show={!props?.value?.config?.name} target={ref.current} placement="bottom-end">
        <StyledPopover>
          <Popover.Content>
            {options.map((option, i) => (
              <StyledButton
                key={option.key}
                $active={i === activeBtnIndex}
                onMouseDown={handleUpdatePersonalisation(option.value)}
                data-testid={`${props.id.split('_')[0]}PersonalisationOption`}
              >
                {option?.value.name}
              </StyledButton>
            ))}
          </Popover.Content>
        </StyledPopover>
      </Overlay>
    </>
  );
};
