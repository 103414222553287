import React from 'react';
import { Tabs, Tab } from 'react-bootstrap';
import { EventKey } from 'react-bootstrap/esm/types';
import { TabsSection } from './styles';

export { Tab };

interface Props {
  onSelect?(text: string): void;
  activeKey?: EventKey;
}

export const TabsViewer: React.FC<Props> = ({ children, onSelect, activeKey }) => {
  return (
    <TabsSection>
      <Tabs activeKey={activeKey} onSelect={onSelect}>
        {children}
      </Tabs>
    </TabsSection>
  );
};
