import React, { KeyboardEvent } from 'react';
import MagnifyGlassIcon from '~images/mag_glass.svg';
import { StyledHeader, InputDiv } from './styles';
import { Button } from 'react-bootstrap';

export interface ViewerHeaderProps {
  title?: React.ReactNode;
  filterPlaceholder?: string;
  filterValue?: string;
  testId?: string;
  onTextChange?(text: string): void;
  onSearchSubmit?(): void;
}

/** Header component used by ListViewer and TilesViewer components */
export const ViewerHeader: React.FC<ViewerHeaderProps> = ({
  title,
  onTextChange,
  onSearchSubmit,
  filterPlaceholder,
  filterValue,
  testId
}) => {
  const valueExists = typeof filterValue === 'string';
  let justifyContent = 'center';
  if (title && valueExists) justifyContent = 'space-between';
  else if (valueExists) justifyContent = 'flex-end';

  const handleFilterChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onTextChange(e.target.value);
  };

  return (
    <StyledHeader style={{ justifyContent }} data-testid={testId}>
      {title}
      {valueExists && (
        <>
          <InputDiv>
            <input
              type="text"
              value={filterValue}
              onChange={handleFilterChange}
              onKeyUp={(event: KeyboardEvent<HTMLInputElement>) => {
                if (event.key === 'Enter') {
                  onSearchSubmit?.();
                }
              }}
              placeholder={filterPlaceholder}
            />
            <MagnifyGlassIcon />
          </InputDiv>
          {onSearchSubmit && (
            <Button size="sm" variant="secondary" onClick={onSearchSubmit} data-testid="searchButton">
              Search
            </Button>
          )}
        </>
      )}
    </StyledHeader>
  );
};
