import React from 'react';
import Dropzone from 'react-dropzone';
import { OverlayTrigger } from 'react-bootstrap';
import { Rule, IdKeys } from '~store/create-segment/types';
import { StyledTooltip } from '~components/styled/tooltip';
import { useStoreActions } from '~store/hooks';
import DeleteIcon from '~images/delete.svg';
import UploadIcon from '~images/upload.svg';
import { ActionsSection } from './styles';

interface Props {
  rule: Rule;
}

export const RuleActions: React.FC<Props> = ({ rule }) => {
  const { deleteRule, setRuleValue } = useStoreActions(({ createSegment }) => createSegment);
  const rulesToShowUploadIcon: string[] = Object.values(IdKeys);
  const { ruleId } = rule.segmentRule;

  const handleDeleteRule = () => deleteRule(rule.id);

  const handleFileUpload = async (files: File[]) => {
    const text = await files[0].text();
    setRuleValue({
      id: rule.id,
      // to preserve backwards compatability: condense to one-line, then split up our ids
      value: text
        .trim()
        .replace(new RegExp('\r\n', 'g'), ',')
        .replace(new RegExp('\n', 'g'), ',')
        .replace(new RegExp('"', 'g'), '')
        .split(',')
        .filter((eachValue) => !!eachValue.trim())
    });
  };

  return (
    <ActionsSection>
      {rulesToShowUploadIcon.includes(ruleId) && (
        <Dropzone onDrop={handleFileUpload} accept="text/*" multiple={false}>
          {({ getInputProps, getRootProps }) => (
            <OverlayTrigger
              placement="top"
              overlay={<StyledTooltip>Import a csv file of {ruleId.substring(0, ruleId.length - 3)} ids</StyledTooltip>}
            >
              <span {...getRootProps()}>
                <UploadIcon />
                <input {...getInputProps()} data-testid="uploadCsvInput" />
              </span>
            </OverlayTrigger>
          )}
        </Dropzone>
      )}
      <DeleteIcon onClick={handleDeleteRule} data-testid="ruleDelete" />
    </ActionsSection>
  );
};
