import React, { useMemo } from 'react';
import { useStoreState, useStoreActions } from '~store/hooks';
import { StyledCol, SaveButton, PushButton } from './styles';
import { useHistory, useRouteMatch } from 'react-router-dom';

import { RoleGuard } from 'src/role-guard';
import { INAPP_TRIGGERS, isInAppPage } from '~pages/notifications/helpers';
import { NOTIFICATION_TYPE } from '@pushologies/database-service/db/entities/notification';
import { INAPP_TYPE } from '@pushologies/common/constants/in-app';

interface ControlProps {
  tenantSegmentMap?: Record<string, string>;
}

export const ControlColumn: React.FC<ControlProps> = ({ tenantSegmentMap }) => {
  const router = useRouteMatch();
  const history = useHistory();
  const { isSuperTenant } = useStoreState((state) => state.childTenants);
  const { notification, status } = useStoreState((state) => state.createNotification);
  const { pushNotification, saveNotification } = useStoreActions((state) => state.createNotification);
  const schedulingEnabled = !!notification?.scheduling;

  const isNotInAppPage = !isInAppPage(router);

  const handleCreateNotification = () => {
    pushNotification({
      onSuccess(id) {
        if (isSuperTenant) return history.push('/');
        history.push(`/${isNotInAppPage ? 'notifications' : 'in-app'}/${id}`);
      },
      ...(isSuperTenant && { tenantSegmentMap })
    });
  };

  // tslint:disable:cyclomatic-complexity
  const inAppTypeValid = (commonValidityChecks: boolean, type: INAPP_TYPE) => {
    if (type === INAPP_TYPE.IMAGE) {
      return (
        commonValidityChecks &&
        !!notification?.inApp?.imageOptions?.alignment &&
        !!notification?.bannerContent?.contentItem?.id
      );
    }

    if (type === INAPP_TYPE.VIDEO) {
      return (
        commonValidityChecks &&
        !!notification?.inApp?.videoOptions?.alignment &&
        !!notification?.videoContent?.contentItem?.id
      );
    }

    if (notification?.inApp?.type === INAPP_TYPE.CAROUSEL) {
      return (
        commonValidityChecks &&
        !!notification?.carouselContent?.length &&
        notification?.carouselContent.filter(
          (eachCarouselItem) => !!eachCarouselItem.contentItem && !!eachCarouselItem.url
        ).length === notification?.carouselContent?.length
      );
    }

    const ctaIsValid =
      !!notification?.inApp?.ctaLabel || !!notification?.inApp?.targetUrl
        ? !!notification?.inApp?.ctaLabel && !!notification?.inApp?.targetUrl
        : true;

    // we'll validate as type STANDARD by default
    return commonValidityChecks && notification.message && ctaIsValid;
  };

  const pushNotificationValid = useMemo(() => {
    if (notification.type === NOTIFICATION_TYPE.INAPP) return true;

    const commonValidityChecks = !!notification.title && !!notification.message && !!notification.segment?.id;

    if (notification.type === NOTIFICATION_TYPE.VIDEO) {
      return commonValidityChecks && !!notification?.videoContent?.contentItem?.id;
    }

    if (notification.type === NOTIFICATION_TYPE.CAROUSEL) {
      return (
        commonValidityChecks &&
        !!notification?.carouselContent?.length &&
        notification?.carouselContent.filter(
          (eachCarouselItem) => !!eachCarouselItem.contentItem && !!eachCarouselItem.url
        ).length === notification?.carouselContent?.length
      );
    }

    return commonValidityChecks;
  }, [notification]);

  const inAppValid = useMemo(() => {
    if (notification.type !== NOTIFICATION_TYPE.INAPP) return true;

    const commonValidityChecks =
      !!notification.title &&
      !!notification.segment?.id &&
      !!notification?.inApp?.trigger &&
      (notification?.inApp?.trigger === INAPP_TRIGGERS.APP_OPEN || !!notification?.inApp?.customEvent);

    return inAppTypeValid(commonValidityChecks, notification?.inApp?.type);
  }, [notification]);

  const pollValid = useMemo(() => {
    return notification.type !== NOTIFICATION_TYPE.POLL || !!notification.pollId;
  }, [notification]);

  const superTenantPushValid = useMemo(() => {
    if (!isSuperTenant) return true;
    return !!Object.keys(tenantSegmentMap).length;
  }, [tenantSegmentMap, isSuperTenant]);

  return useMemo(
    () => (
      <StyledCol>
        {!isSuperTenant && (
          <RoleGuard requiredRole="Creator">
            <SaveButton
              id="saveButton"
              loading={status === 'saving'}
              onClick={saveNotification}
              disabled={status === 'pushing'}
            >
              Save
            </SaveButton>
          </RoleGuard>
        )}

        <RoleGuard requiredRole="Publisher">
          <PushButton
            id="pushButton"
            onClick={handleCreateNotification}
            loading={status === 'pushing'}
            disabled={
              status === 'saving' ||
              (schedulingEnabled && !notification.scheduling.pushAt) ||
              !pushNotificationValid ||
              !pollValid ||
              !superTenantPushValid ||
              !inAppValid
            }
          >
            Push
          </PushButton>
        </RoleGuard>
      </StyledCol>
    ),
    [notification, status, pollValid, inAppValid, superTenantPushValid]
  );
};
