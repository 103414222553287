import styled from 'styled-components';
import { Form, Button } from 'react-bootstrap';

export const StyledForm = styled(Form)`
  position: relative;
  display: inline-block;
  width: 100%;
  color: ${({ theme }) => theme.palette.textColor};
  background: white;
  box-shadow: 0 0 40px -10px rgba(0, 0, 0, 0.35);
  margin-bottom: 2rem;
  padding-bottom: 1rem;

  label {
    display: inline-block;

    &.secondary {
      font-family: EavesRegular;
      font-size: 0.7rem;
      line-height: 0.7rem;
    }
  }

  .form-group {
    margin: 0.5rem 2rem;

    .form-label {
      font-family: EavesBold;
      text-transform: uppercase;
      margin: 0;
      color: ${({ theme }) => theme.palette.textGrey};
    }
  }

  .form-control {
    border-radius: 0;
  }

  .form-check {
    display: inline-block;
    margin-left: 0.5rem;
  }

  .forgot-password {
    display: block;
    margin-top: 1rem;
    text-align: center;
    font-size: 1.2rem;
  }

  .custom-file {
    input:hover,
    label:hover {
      cursor: pointer;
    }

    .custom-file-label {
      border-radius: 0;

      &::after {
        height: calc(100% - 0.6rem);
        width: 35%;
        top: 0.3rem;
        right: 0.3rem;
        border-radius: 0;
        background: ${({ theme }) => theme.palette.primaryMustard};
        display: flex;
        justify-content: center;
        align-items: center;
        text-transform: uppercase;
        color: white;
      }
    }
  }

  .register-submit {
    margin-top: 1.5rem;
  }
`;

export const StyledFormHeader = styled.header`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${({ theme }) => theme.palette.darkGrey};
  height: 2.5rem;
  width: 100%;
  text-transform: uppercase;
  color: white;
  margin-bottom: 1rem;
  font-weight: bold;
  font-size: 1.2rem;
  text-align: left;

  button {
    position: absolute;
    top: calc(calc(100% - 2rem) / 2);
    right: 0.5rem;
    height: 2rem;
    width: 5rem;
    font-size: 1rem;
    margin: 0;
  }
`;

export const StyledFormButton = styled(Button)`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 2.75rem;
  width: 100%;
  padding: 0;
  font-size: 1.9rem;
  border: 0;
  border-radius: 0;

  &:disabled {
    background: #007bff7a !important;
  }

  .spinner-border {
    font-size: 1rem !important;
  }
`;
