import React from 'react';
import { StyledContainer } from '~components/styled/container';
import { MaintenanceContainerDiv, MaintenanceMessage } from './styles';
import { useFlags } from 'launchdarkly-react-client-sdk';

const Maintenance: React.FC = () => {
  const { maintenanceMode } = useFlags();

  return (
    <StyledContainer $sideNavVisible={false}>
      <MaintenanceContainerDiv>
        <MaintenanceMessage>
          Under Maintenance Until: {maintenanceMode.eta} <br />{' '}
          {maintenanceMode.additionalMessage && <p>{maintenanceMode.additionalMessage}</p>}
        </MaintenanceMessage>
      </MaintenanceContainerDiv>
    </StyledContainer>
  );
};

export default Maintenance;
