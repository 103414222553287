import React, { useMemo } from 'react';
import { OverlayTrigger } from 'react-bootstrap';
import { v4 as uuid } from 'uuid';
import Cookies from 'js-cookie';
import { decode } from 'html-entities';
import { PersonalisationConfigItemType } from '@pushologies/database-service/db/entities/notification';
import { TENANT_LICENSES } from '@pushologies/common/constants/tenant-licenses';
import { StyledTooltip } from '~components/styled/tooltip';
import { Tour } from '~components/tour';
import CaretIcon from '~images/cursor_caret.svg';
import BrushIcon from '~images/clear_brush.svg';
import { useStoreActions, useStoreState } from '~store/hooks';
import { useStateRef } from '~hooks/use-state-ref';
import { StringValue } from './string-value';
import { PersonalisationValue } from './personalisation-value';
import { PersonalisationModal } from './modal';
import { Props, PersonalisationValueItem, CaretLocation } from './types';
import {
  stringToPersonalisationValue,
  valueArrayToString,
  formatLineBreak,
  isPersonalisationItemValue,
  isStringValue
} from './helpers';
import { personalisationSteps, PERSONALISATION_COOKIE } from './tour';
import { StyledInputSection, CaretIconDiv, StyledSpan } from './styles';
import { buildPersonalisationVariableMap } from '~helpers/personalisation';

export const PersonalisationTextField: React.FC<Props> = (props) => {
  const [value, setValue, valueRef] = useStateRef<PersonalisationValueItem[]>(['']);
  const [showTour, setShowTour] = React.useState(false);
  const [activePersonalisationId, setActivePersonalisationId] = React.useState<string>();
  const caretPosition = React.useRef({ valueIndex: 0, anchorIndex: 0 });
  const [caretLocation, setCaretLocation] = React.useState<CaretLocation>({ left: 0, top: 0, display: 'none' });
  const { deletePersonalisationItems, setPersonalisation } = useStoreActions((state) => state.createNotification);
  const { tenant } = useStoreState((state) => state.tenant);
  const { notification } = useStoreState((state) => state.createNotification);
  const personalisationVariableMap = useMemo(
    () => buildPersonalisationVariableMap(notification.personalisation),
    [notification.personalisation]
  );
  const licensed = tenant?.licenses && tenant.licenses.includes(TENANT_LICENSES.PERSONALISED_NOTIFICATIONS);

  const updateStringValueAtIndex = (index: number, text: string, key?: string) => {
    let sliceTo = index;
    const previousValue = valueRef.current[index - 1];

    // if backspace pressed and cursor is at start of span string value remove previous value if it is a personalisation object
    if (
      key === 'Backspace' &&
      valueRef.current.length > 1 &&
      caretPosition.current.anchorIndex === 0 &&
      isPersonalisationItemValue(previousValue)
    ) {
      sliceTo = index - 1;
      deletePersonalisationItems([previousValue.id]);
      hideCaretImg();
    }

    let newValue = valueRef.current.slice(0, sliceTo);
    if (isStringValue(newValue[sliceTo - 1])) {
      newValue[sliceTo - 1] += text;
    } else newValue.push(text);
    newValue = newValue.concat(valueRef.current.slice(index + 1));

    const lastValue = newValue[newValue.length - 1];
    const lastTwoChars = text.substring(text.length - 2);

    if (licensed && lastTwoChars === '{{' && isStringValue(lastValue)) {
      newValue[newValue.length - 1] = lastValue.replace('{{', '');
      const emptyConfig: PersonalisationValueItem = {
        id: uuid(),
        config: {
          name: null,
          defaultValue: null
        }
      };
      setPersonalisation({ uuid: emptyConfig.id, variable: emptyConfig.config });
      newValue.push(emptyConfig);
      newValue.push('');
    }

    if (!newValue.length) newValue.push('');

    setValue(newValue);
    props.onChange(valueArrayToString(newValue));
  };

  const calculateNewCaretLocation = () => {
    const selection = window.getSelection();

    if (selection.rangeCount === 0) return; // do nothing if no cursor set
    const elementId = selection.anchorNode.parentElement.id;
    caretPosition.current = {
      anchorIndex: selection.anchorOffset,
      valueIndex: Number(elementId.substring(props.id.length + 1)) // extract valueIndex from element id which is in form "`<props.id>`_`<index>`"
    };

    const range = selection.getRangeAt(0).cloneRange();
    range.collapse(true);
    const boundingClientRect = (range.startContainer as any).getBoundingClientRect
      ? (range.startContainer as HTMLSpanElement).getBoundingClientRect()
      : null;
    const rect = range.getClientRects()[0] ?? boundingClientRect;

    if (rect) setCaretLocation({ left: rect.left, top: rect.top, display: 'block' });
  };

  const hideCaretImg = () => {
    setCaretLocation({ ...caretLocation, display: 'none' });
  };

  const handleShowModal = (show: boolean) => () => {
    show ? setActivePersonalisationId('') : setActivePersonalisationId(null);
  };

  const handleNewActivePersonalisationId = (personalisationId?: string) => {
    setActivePersonalisationId(personalisationId);
  };

  const handleInsertPersonalisation = (id: string, config: PersonalisationConfigItemType) => {
    const { valueIndex, anchorIndex } = caretPosition.current;
    const activeValue = formatLineBreak(decode(value[valueIndex] as string));
    const beforeText = activeValue.substring(0, anchorIndex);
    const afterText = activeValue.substring(anchorIndex);
    setPersonalisation({ uuid: id, variable: config });

    let newValue = value.slice(0, valueIndex);

    !!beforeText.length && newValue.push(beforeText);
    newValue.push({ id, config });
    if (!!afterText.length) {
      newValue.push(afterText);
    } else {
      newValue.push('');
    }
    if (valueIndex === value.length - 1 && anchorIndex === (value[valueIndex] as string).length) {
      newValue.push('');
    } else {
      newValue = newValue.concat(value.slice(valueIndex + 1));
    }

    setValue(newValue);
    props.onChange(valueArrayToString(newValue));
    setActivePersonalisationId(null);
  };

  const handleUpdatePersonalisation = (id: string, config: PersonalisationConfigItemType) => {
    const valueIndex = value.findIndex((item) => isPersonalisationItemValue(item) && item.id === id);
    const newValue = [...value.slice(0, valueIndex), { id, config }, ...value.slice(valueIndex + 1)];
    setValue(newValue);
    setPersonalisation({ uuid: id, variable: config });
    setActivePersonalisationId(null);
  };

  const handleClearField = () => {
    const personalisationIds = value.filter(isPersonalisationItemValue).map(({ id }) => id);
    deletePersonalisationItems(personalisationIds);
    setValue(['']);
    setActivePersonalisationId(null);
    setCaretLocation({ left: 0, top: 0, display: 'none' });
    caretPosition.current = { valueIndex: 0, anchorIndex: 0 };
    props.onChange('');
  };

  const startTour = () => {
    if (!Cookies.get(PERSONALISATION_COOKIE)) setShowTour(true);
  };

  React.useEffect(() => {
    if (!licensed) return setValue([props.value]);

    setValue(stringToPersonalisationValue(props.value, notification.personalisation));
  }, [props.value, notification.personalisation, licensed]);

  return React.useMemo(
    () => (
      <>
        <StyledInputSection
          className={props.className}
          invalid={props.invalid}
          data-tour={props.id}
          data-testid={props.id}
          $disabled={props.disabled}
          $type={props.as}
          $rows={props.rows}
        >
          {props.label && (
            <label htmlFor={props.id}>
              {props.label} <span>{props.subLabel}</span>
            </label>
          )}
          {licensed && (
            <>
              <CaretIconDiv
                $location={caretLocation}
                role="button"
                onMouseDown={handleShowModal(true)}
                data-testid="personalisationCaretIcon"
              >
                <CaretIcon />
              </CaretIconDiv>
              <PersonalisationModal
                show={typeof activePersonalisationId === 'string'}
                personalisationId={activePersonalisationId}
                personalisation={personalisationVariableMap[activePersonalisationId]}
                onHide={handleShowModal(false)}
                onInsertPersonalisation={handleInsertPersonalisation}
                onUpdatePersonalisation={handleUpdatePersonalisation}
              />

              {showTour && <Tour id={PERSONALISATION_COOKIE} steps={personalisationSteps(props.id)} />}
            </>
          )}
          <p id={props.id} onBlur={hideCaretImg} onFocus={startTour}>
            {value.map((item, index) => {
              const valueId = `${props.id}_${index}`;
              return isPersonalisationItemValue(item) ? (
                <PersonalisationValue
                  value={item}
                  id={valueId}
                  key={`${item?.id}${item?.config?.name}`}
                  showPersonalisationModal={handleNewActivePersonalisationId}
                  onUpdatePersonalisation={handleUpdatePersonalisation}
                />
              ) : (
                <StringValue
                  value={item}
                  handleOnSelect={calculateNewCaretLocation}
                  id={valueId}
                  key={valueId}
                  updateStringValueAtIndex={updateStringValueAtIndex}
                  fillEntireParentElement={value.length === 1 || value.length === index + 1}
                  disabled={props.disabled}
                />
              );
            })}
          </p>
          <StyledSpan role="button" onClick={handleClearField} data-testid="clearButton">
            <OverlayTrigger placement="top" overlay={<StyledTooltip>Clear input</StyledTooltip>}>
              <BrushIcon data-tour={`${props.id}Delete`} />
            </OverlayTrigger>
          </StyledSpan>
        </StyledInputSection>
      </>
    ),
    [
      value,
      activePersonalisationId,
      showTour,
      licensed,
      caretLocation,
      notification.personalisation,
      props.id,
      props.value,
      props.disabled,
      props.invalid,
      props.rows
    ]
  );
};

PersonalisationTextField.defaultProps = {
  value: ''
};
