import React, { useCallback } from 'react';
import { useHistory } from 'react-router';
import { Container, Table } from 'react-bootstrap';
import dayjs from '~helpers/dayjs';
import { useStoreActions } from '~store/hooks';
import { TableSection } from '~components/styled/table-section';
import { Pagination } from '~components/pagination';
import { LIMIT } from '~store/notifications';
import { Loader } from '~components/loader';
import { StyledRow } from './styles';
import { PollRow } from './row';
import { FiltersRow, Filters, filtersToApi } from './filters';
import { debounce } from 'throttle-debounce';
import { ApiPollWithResults } from '~api/polling/types';
import { SelectOption } from '~components/select';

interface Props {
  selectedPollId?: string;
  onSelectPoll: (poll: ApiPollWithResults) => void;
  onEditPoll: (poll: ApiPollWithResults) => void;
}

export const ListPolls: React.FC<Props> = ({ selectedPollId, onSelectPoll, onEditPoll }) => {
  const history = useHistory();
  const [loading, setLoading] = React.useState(true);
  const [polls, setPolls] = React.useState<ApiPollWithResults[]>([]);
  const { fetchPolls } = useStoreActions((state) => state.polls);
  const [totalItems, setTotalItems] = React.useState(10);
  const [offset, setOffset] = React.useState(0);
  const [question, setQuestion] = React.useState('');
  const [createdById, setCreatedById] = React.useState<SelectOption[]>([]);
  const [createdBetween, setCreatedBetween] = React.useState<[Date, Date]>([] as any);

  const handleFilterChange = useCallback((filters: Partial<Filters>) => {
    setLoading(true);

    filters.hasOwnProperty('createdById') && setCreatedById(filters.createdById);
    filters.hasOwnProperty('createdBetween') && setCreatedBetween(filters.createdBetween);
    const questionChanged = filters.hasOwnProperty('question');
    questionChanged && setQuestion(filters.question);
    // if filters above have been changed reset offset to 0 otherwise to changed offset value
    filters.hasOwnProperty('offset') ? setOffset(filters.offset) : setOffset(0);

    debounce(500, true, () => {
      fetchPolls({
        limit: LIMIT,
        ...filtersToApi({ offset, question, createdById, createdBetween, ...filters }),
        onSuccess(newPolls, newTotalItems) {
          setPolls(newPolls);
          setTotalItems(newTotalItems);
          setLoading(false);
        }
      });
    })();
  }, []);

  const handlePageChange = ({ selected }: { selected: number }) => {
    const newOffset = selected * LIMIT;
    handleFilterChange({ offset: newOffset });
  };

  const handleClear = () => {
    setLoading(true);
    setOffset(0);
    setCreatedById([]);
    setCreatedBetween([] as any);
    setQuestion('');
    history.push({ search: null });

    fetchPolls({
      limit: LIMIT,
      onSuccess(newPolls, newTotalItems) {
        setPolls(newPolls);
        setTotalItems(newTotalItems);
        setLoading(false);
      }
    });
  };

  const onClonePoll = () => {
    setLoading(true);
    fetchPolls({
      limit: LIMIT,
      onSuccess(newPolls, newTotalItems) {
        setPolls(newPolls);
        setTotalItems(newTotalItems);
        setLoading(false);
      }
    });
  };

  const onDeletePoll = (pollId: string) => {
    const index = polls.findIndex(({ id }) => id === pollId);
    setPolls([...polls.slice(0, index), ...polls.slice(index + 1)]);
  };

  React.useEffect(() => {
    fetchPolls({
      limit: LIMIT,
      onSuccess(newPolls, newTotalItems) {
        setPolls(newPolls);
        setTotalItems(newTotalItems);
        setLoading(false);
      }
    });
  }, []);

  return (
    <Container>
      <FiltersRow
        question={question}
        createdById={createdById}
        createdBetween={createdBetween}
        offset={offset}
        onFilterChange={handleFilterChange}
        onClear={handleClear}
      />
      <StyledRow>
        <TableSection>
          <Loader loading={loading} backdrop />
          <Table responsive>
            <thead>
              <tr>
                <th>Poll</th>
                <th>Answers</th>
                <th>Total Votes</th>
                <th style={{ width: '15%' }}>Created At</th>
                <th style={{ width: '15%' }}>Actions</th>
              </tr>
            </thead>
            <tbody>
              {polls
                .sort((a, b) => dayjs(b.createdAt).diff(a.createdAt))
                .map((poll) => (
                  <PollRow
                    key={poll.id}
                    poll={poll}
                    onSelectPoll={onSelectPoll}
                    onEditPoll={onEditPoll}
                    onClonePoll={onClonePoll}
                    onDeletePoll={onDeletePoll}
                    selected={poll.id === selectedPollId}
                  />
                ))}
            </tbody>
          </Table>
          <Pagination
            pageCount={Math.ceil(totalItems / LIMIT)}
            marginPagesDisplayed={5}
            pageRangeDisplayed={4}
            forcePage={offset / LIMIT}
            breakLabel="..."
            onPageChange={handlePageChange}
          />
        </TableSection>
      </StyledRow>
    </Container>
  );
};
