import React from 'react';
import { ReactourStep } from 'reactour';
import { generateTourSelector } from '~helpers/formats';
import { TourDiv } from '~components/tour/styles';

export const POLL_TOUR_COOKIE = 'POLL_TOUR_COMPLETE';

export const pollSteps: ReactourStep[] = [
  {
    selector: generateTourSelector('pollBuilderCreate'),
    content: () => (
      <TourDiv>
        Here you can create new polls to include in your notifications.
        <br />
        <br />
        If you already have a poll, you can select it from the list below.
        <br />
        <br />
        Simply press "save" to update your notification.
      </TourDiv>
    )
  }
];
