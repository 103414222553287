import {
  Column,
  Entity,
  PrimaryGeneratedColumn,
  CreateDateColumn,
  JoinColumn,
  ManyToOne,
  Index,
  OneToMany,
  JoinTable,
  OneToOne
} from 'typeorm';
import { Tenant } from '@pushologies/database-service/db/entities/tenant';
import { LocaleText } from './locale-text';
import { PollChoice } from './poll-choice';
import { TenantUser } from './tenant-user';
import { Fonts } from '@pushologies/database-service/db/entities/content';
import { ContentItem } from '@pushologies/database-service/db/entities/content-item';

export interface TextCustomisation {
  font: Fonts;
  fontSize: number;
  backgroundColor: string;
  color: string;
  selectedBackgroundColor?: string;
  selectedColor?: string;
}

@Entity('poll')
@Index(['tenantId', 'createdAt'])
export class Poll {
  @PrimaryGeneratedColumn('uuid')
  id: string;

  @Index()
  @Column('varchar', { name: 'secondary_id', nullable: true })
  secondaryId: string;

  @OneToOne(() => LocaleText, { cascade: ['insert', 'update'], eager: true })
  @JoinColumn()
  question: LocaleText;

  @JoinTable()
  @OneToMany((_) => PollChoice, (choice) => choice.poll, {
    cascade: ['insert', 'update'],
    onDelete: 'CASCADE'
  })
  pollChoices: PollChoice[];

  @Column({ nullable: true })
  createdById: string;
  @ManyToOne((_) => TenantUser, { onDelete: 'SET NULL' })
  @JoinColumn()
  createdBy: TenantUser;

  @CreateDateColumn({ name: 'created_at', type: 'timestamptz' })
  createdAt: Date;

  @Column()
  tenantId: string;
  @ManyToOne((_) => Tenant, (tenant) => tenant.id, { onDelete: 'CASCADE' })
  @JoinColumn()
  tenant: Tenant;

  @Column({ name: 'valid_from', type: 'timestamptz', nullable: true })
  validFrom?: Date;

  @Column({ name: 'valid_until', type: 'timestamptz', nullable: true })
  validUntil?: Date;

  @Column({ nullable: true })
  backgroundImageContentItemId?: string;
  @ManyToOne((_) => ContentItem, (contentItem) => contentItem.id, { onDelete: 'CASCADE' })
  @JoinColumn()
  backgroundImageContentItem: ContentItem;

  @Column({ nullable: true })
  headerImageContentItemId?: string;
  @ManyToOne((_) => ContentItem, (contentItem) => contentItem.id, { onDelete: 'CASCADE' })
  @JoinColumn()
  headerImageContentItem: ContentItem;

  @Column({ nullable: true })
  backgroundColor?: string;

  @Column({ nullable: true, type: 'json' })
  questionTextCustomisation?: TextCustomisation;

  @Column({ nullable: true, type: 'json' })
  choicesTextCustomisation?: TextCustomisation;

  @Column({ nullable: true, type: 'integer' })
  marginTop?: number;

  @OneToOne(() => LocaleText, { cascade: ['insert', 'update'], eager: true })
  @JoinColumn()
  voteSuccessMessage?: LocaleText;
}
