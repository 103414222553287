import { RuleId } from './data/rules';
import { SUBSCRIBER_NOTIFICATION_EVENT_STATUS } from '@pushologies/common/constants/subscriber';

export enum OperatorValue {
  greaterThan = 'greaterThan',
  lessThan = 'lessThan',
  greaterThanOrEqual = 'greaterThanOrEqual',
  lessThanOrEqual = 'lessThanOrEqual',
  is = 'is',
  isNot = 'isNot',
  in = 'in',
  notIn = 'notIn',
  between = 'between',
  before = 'before',
  after = 'after',
  inside = 'inside',
  outside = 'outside'
}

export interface Operator {
  name: string;
  value: OperatorValue;
}

export interface SegmentRule<V = any> {
  ruleId: RuleId;
  operator: OperatorValue;
  value: V;
}

export interface CreateSubscriberListOptions {
  bucketSize?: number;
  batchSize?: number;
}

export interface DatesRuleValue {
  from?: string;
  to?: string;
  date?: string;
}

interface GeoCoordinates {
  latitude: number;
  longitude: number;
}

export interface LocationRuleValue extends Partial<GeoCoordinates> {
  radius?: number;
  vertices?: GeoCoordinates[];
}

export interface NotificationStatusRuleValue {
  notificationId: string;
  status: SUBSCRIBER_NOTIFICATION_EVENT_STATUS[];
}
