import React from 'react';
import { Toast } from 'react-bootstrap';
import { useStoreState, useStoreActions } from '~store/hooks';
import { Notification } from '~store/notification-widget/types';
import { useClickAway } from '~hooks/click-away';
import { StyledCardWrapper, StyledCard } from './styles';

export const NotificationWidget: React.FC = () => {
  const cardRef = React.useRef<HTMLDivElement>();
  const { showWidget, notifications } = useStoreState((state) => state.notificationWidget);
  const { removeNotification, setShowWidget } = useStoreActions(({ notificationWidget }) => notificationWidget);

  useClickAway(cardRef, () => setShowWidget(false));

  const removeNotificationByIndex = (index: number) => () => removeNotification(index);

  const notificationToasts = notifications.map((notification: Notification, index) => (
    <Toast key={notification.timestamp + index} animation onClose={removeNotificationByIndex(index)}>
      <div className={notification.type}>
        <Toast.Header>
          <strong>{notification.message}</strong>
        </Toast.Header>
        <Toast.Body title={notification.message}>
          <i>{notification.timestamp}</i>
        </Toast.Body>
      </div>
    </Toast>
  ));

  return (
    <StyledCardWrapper $active={showWidget}>
      <StyledCard ref={cardRef}>
        <h2>notifications</h2>
        <section>{notificationToasts}</section>
      </StyledCard>
    </StyledCardWrapper>
  );
};
