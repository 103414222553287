import React from 'react';
import { ApiContentItem } from '~api/content-items/types';
import PlusIcon from '~images/plus.svg';
import { constructThumbnailUrl } from '~api/tools/helpers';
import { useStoreActions } from '~store/hooks';
import { ImageArticle, TopDiv, ImgDiv } from './styles';

interface Props {
  file: ApiContentItem;
}

export const Image: React.FC<Props> = ({ file }) => {
  const { addCarouselItem } = useStoreActions((state) => state.createNotification);

  const handleImageClick = () => {
    addCarouselItem({ contentItemId: file.id });
  };

  return (
    <ImageArticle>
      <TopDiv>
        <p>{file.name}</p>
        <section>
          <span tabIndex={0} role="button" aria-label="Add image to carousel items" onClick={handleImageClick}>
            <PlusIcon />
          </span>
        </section>
      </TopDiv>
      <ImgDiv url={constructThumbnailUrl(file)} />
    </ImageArticle>
  );
};
