import { Column, Entity, Index, ManyToOne, PrimaryGeneratedColumn } from 'typeorm';
import { Tenant } from './tenant';
import { Notification } from './notification';

export enum PUSH_SCHEDULE_MODE {
  NORMAL = 'NORMAL',
  TIMEZONE = 'TIMEZONE'
}

@Entity('scheduled_push')
@Index(['pushAt', 'processed'])
export class ScheduledPush {
  @PrimaryGeneratedColumn('increment')
  id: number;

  @Column()
  notificationId: string;
  @ManyToOne((_) => Notification, (notification) => notification.id, { onDelete: 'CASCADE' })
  @Index()
  notification: Notification;

  @Column()
  tenantId: string;
  @ManyToOne((_) => Tenant, (tenant) => tenant.id, { onDelete: 'CASCADE' })
  @Index()
  tenant: Tenant;

  @Column({ name: 'schedule_mode', type: 'enum', enum: PUSH_SCHEDULE_MODE })
  scheduleMode: PUSH_SCHEDULE_MODE;

  @Column({ type: 'varchar', nullable: true })
  timezone: string;

  @Column({ name: 'push_at', type: 'timestamp' })
  pushAt: Date;

  @Column({ type: 'boolean', default: false })
  processed: boolean;
}
