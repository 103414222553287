import React, { memo } from 'react';
import { ToggleButton } from '~components/toggle-button';
import { StyledInputSection } from './styles';

interface Props {
  id: string;
  value: boolean;
  disabled?: boolean;
  label?: string;
  onChange(checked: boolean): void;
  className?: string;
}

const ToggleFieldComponent: React.FC<Props> = ({ id, value, label, onChange, className, disabled }) => (
  <StyledInputSection data-testid={id} className={className} $disabled={disabled}>
    {label && <label htmlFor={id}>{label}</label>}
    <input id={id} type="hidden" value={String(value)} disabled={disabled} />
    <ToggleButton value={value} onChange={onChange} disabled={disabled} />
  </StyledInputSection>
);

export const ToggleField = memo(ToggleFieldComponent);
