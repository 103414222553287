import { css, keyframes } from 'styled-components';

export const breakpoints = {
  xs: '(max-width: 576px)',
  sm: '(max-width: 768px)',
  md: '(max-width: 992px)',
  lg: '(max-width: 1200px)',
  xl: '(min-width: 1200px)'
};

export const BottomCaretMixin = css`
  &:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 1rem;
    background: ${({ theme }) => theme.palette.primaryMustard};
  }

  &:before {
    content: '';
    position: absolute;
    z-index: 10;
    left: 50%;
    bottom: 0.25rem;
    transform: translateX(-50%);
    border-bottom: 0.6rem solid ${({ theme }) => theme.palette.primaryPlum};
    border-right: 0.35rem solid transparent;
    border-left: 0.35rem solid transparent;
  }
`;

export const HideScrollbars = css`
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */

  &::-webkit-scrollbar {
    display: none; /* Chrome, Safari and Opera */
  }
`;

export const EnlargedScrollbar = css`
  &::-webkit-scrollbar {
    width: 1rem;
    max-width: 70px;
  }

  &::-webkit-scrollbar-track {
    box-shadow: none;
    border-radius: 0;
    border-left: none;
    transition: all 0.2s linear;
    border-left: 1px solid ${({ theme }) => theme.palette.lighterGrey};
  }

  &::-webkit-scrollbar-thumb {
    background: transparent;
    border-radius: 0;
    transition: all 0.2s linear;
    background: ${({ theme }) => theme.palette.lightGrey};
  }
`;

export const blink = keyframes`
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.2;
  }
  100% {
    opacity: 1;
  }
`;
