import React from 'react';
import { ApiSubscriberEvent, ApiSubscriberNotificationEvent, EventGroup } from '~api/subscribers/types';
import dayjs from '~helpers/dayjs';
import { ApiTags } from '~api/tags/types';
import { PromptButton } from '~components/prompt-button';
import DeleteIcon from '~images/delete.svg';
import { EventDiv } from './styles';

interface EventHistoryRowProps {
  event: EventGroup;
  index: number;
}

export const EventHistoryRow: React.FC<EventHistoryRowProps> = ({ event, index }) => {
  const [expand, setExpand] = React.useState(false);

  return (
    <EventDiv $highlight={index % 2 === 0} $expand={expand} key={index}>
      <i role="button" onClick={() => setExpand(!expand)} />
      {event.type === 'singleEvent' ? (
        <>
          <p>
            <span>
              {event?.content[0].eventType === 'geofence'
                ? `${event?.content[0].eventType}_${(event.content[0] as ApiSubscriberEvent).subType}`
                : event.content[0].eventType}
            </span>
            <span>{dayjs(event?.content[0].createdAt).format('HH:mm:ss - DD/MM/YY')}</span>
          </p>
          <pre>{JSON.stringify(event?.content[0].eventData, undefined, 2)}</pre>
        </>
      ) : (
        <>
          <p>
            <a href={`/notifications/${event.content[0].notificationId}`}> Notification </a>
          </p>
          {event.content.map((ev: ApiSubscriberNotificationEvent) => (
            <pre>
              <span>
                <code>{ev.status}</code>
                <code>{dayjs(ev.createdAt).format('HH:mm:ss - DD/MM/YY')}</code>
              </span>
            </pre>
          ))}
        </>
      )}
    </EventDiv>
  );
};

export const OptionsRow: React.FC<{ option: ApiTags; handleDeleteTagDelete(): void }> = ({
  option,
  handleDeleteTagDelete
}) => {
  return (
    <EventDiv $highlight={true} $expand={false}>
      <p>
        <span>{option?.name}</span>
        <PromptButton
          buttonType="icon"
          buttonIcon={<DeleteIcon />}
          onConfirm={handleDeleteTagDelete}
          confirmButtonText="delete"
          promptTitle="Confirm Delete"
        >
          Are you sure you would like to delete subscriber tag "{option.name}"?
        </PromptButton>
      </p>
    </EventDiv>
  );
};
