import styled from 'styled-components';
import { SECTION_HEIGHT } from '../styles';

export const MetricsChartsSection = styled.section`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: calc(${SECTION_HEIGHT} - 3rem);
  padding: 0;
  margin-top: 1rem;
`;

export const MetricsWrapperDiv = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  height: calc(calc(100% / 3) - 0.5rem);
  box-shadow: 0.2rem 0.2rem 1.5rem -0.75rem rgba(0, 0, 0, 0.5);
  padding: 0.5rem 1rem;
  letter-spacing: 0.03rem;

  &:first-child {
    height: calc(calc(100% / 3) - 1.5rem);
  }
`;

interface MetricsHeaderDiv {
  $headingColor: string;
}

export const MetricsHeaderDiv = styled.div<MetricsHeaderDiv>`
  position: relative;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  min-width: 20%;
  text-transform: uppercase;
  font-family: EavesRegular;

  h6 {
    color: ${({ theme }) => theme.palette.platinum};
    font-size: 0.9rem;
    margin-bottom: 0.2rem;
  }

  p.title {
    color: ${({ $headingColor: $color }) => $color};
    font-family: EavesBold;
    display: block;
    margin: 0;
  }

  section {
    position: relative;
    display: flex;
    color: ${({ $headingColor: $color }) => $color};
    font-family: EavesBold;
    flex-flow: wrap;

    p {
      margin: 0;
      display: flex;

      &.number {
        margin-left: 0.5rem;
        font-size: 3.5rem;
        line-height: 3rem;
      }
      &.metric {
        align-items: flex-end;
      }
    }
  }
`;

export const ChartsWrapperDiv = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 0 1rem;
`;

export const ChartWrapperDiv = styled.div`
  position: relative;
  display: inline-block;
  overflow: hidden;

  figure {
    position: relative;
    margin: -0.25rem 0 0 0;

    figcaption {
      font-family: EavesBold;
      color: ${({ theme }) => theme.palette.red};
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translateX(-50%) translateY(-50%);
    }

    svg {
      width: 5rem !important;

      g {
        path {
          &:first-child {
            fill: url(#VictoryPieGradient) !important;
          }
          &:nth-child(2) {
            fill: transparent !important;
          }
        }

        text {
          display: none;
        }
      }
    }
  }

  p {
    font-family: EavesRegular;
    color: ${({ theme }) => theme.palette.platinum};
    margin: -0.75rem 0 0 0;
    text-align: center;
    text-transform: uppercase;
    font-size: 0.9rem;
  }
`;
