import { AxiosInstance } from 'axios';
import { TenantCredentials } from '@pushologies/database-service/db/entities/tenant-credentials';
import { instance } from './tools/axios-instance';
import { refreshAuthToken } from './tools/auth.decorater';

export type Credentials = Omit<TenantCredentials, 'tenant'>;

export interface UploadCredentialsParams {
  firebase: File;
  apnAuthKey: File;
  apnTeamId: string;
  apnBundleId: string;
  apnKeyId?: string;
}

export class CredentialsApiClass {
  constructor(private axios: AxiosInstance) {}

  @refreshAuthToken()
  async fetchCredentials(): Promise<{ credentials: Credentials }> {
    const response = await this.axios.get('/credentials');
    return response.data.response;
  }

  @refreshAuthToken()
  async uploadCredentials(params: Partial<UploadCredentialsParams>): Promise<{ credentials: Credentials }> {
    const data = new FormData();
    if (params.firebase) {
      data.append('firebase', params.firebase);
    }
    if (params.apnAuthKey) {
      data.append('apnAuthKey', params.apnAuthKey);
      data.append('apnBundleId', params.apnBundleId);
      data.append('apnTeamId', params.apnTeamId);
      params.apnKeyId && data.append('apnKeyId', params.apnKeyId);
    }
    const response = await this.axios.post('/credentials', data, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
    return response.data.response;
  }
}

export const credentialsApi = new CredentialsApiClass(instance);
