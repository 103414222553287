import { SelectQueryBuilder } from 'typeorm';
import { Subscriber } from '@pushologies/database-service/db/entities/subscriber';
import { OperatorValue as Op, SegmentRule, LocationRuleValue } from '../types';
import { Rule } from '.';

export class LocationRule extends Rule {
  constructor(private rule: SegmentRule<LocationRuleValue>) {
    super();
  }

  updateQuery(query: SelectQueryBuilder<Subscriber>) {
    const [sql, params] = this.getQueryString();
    query.andWhere(sql, params);
  }

  private getQueryString(): [string, object] {
    const { latitude, longitude, radius } = this.rule.value;
    const latitudeParam = this.createParameterHash();
    const longitudeParam = this.createParameterHash();
    const radiusParam = this.createParameterHash();

    // make first point also last point to close polygon, then convert to LINESTRING format
    const polygon =
      this.rule.value.hasOwnProperty('vertices') &&
      [...this.rule.value.vertices, this.rule.value.vertices[0]]
        .map((coords) => `${coords.longitude} ${coords.latitude}`)
        .join(', ');

    switch (this.rule.operator) {
      case Op.inside:
        if (polygon) {
          return [`ST_DWithin(subscriber.location, ST_MakePolygon(ST_GeomFromText('LINESTRING(${polygon})')), 0)`, {}];
        } else {
          return [
            `ST_DWithin(subscriber.location, ST_MakePoint(:${longitudeParam},:${latitudeParam}), :${radiusParam})`,
            { [latitudeParam]: latitude, [longitudeParam]: longitude, [radiusParam]: radius }
          ];
        }
      case Op.outside:
        if (polygon) {
          return [
            `NOT ST_DWithin(subscriber.location, ST_MakePolygon(ST_GeomFromText('LINESTRING(${polygon})')), 0)`,
            {}
          ];
        } else {
          return [
            `NOT ST_DWithin(subscriber.location, ST_MakePoint(:${longitudeParam},:${latitudeParam}), :${radiusParam})`,
            { [latitudeParam]: latitude, [longitudeParam]: longitude, [radiusParam]: radius }
          ];
        }
      default:
        throw new Error(`operator "${this.rule.operator}" not supported`);
    }
  }
}
