import React from 'react';
import { Container, Col, Tab, Nav, Spinner } from 'react-bootstrap';
import { API_KEY_TYPES } from '@pushologies/database-service/db/entities/api-key';
import { ApiKey } from '~components/api-key';
import { PromptButton } from '~components/prompt-button';
import { useStoreState, useStoreActions } from '~store/hooks';
import { settingsPageLinks } from '../links';
import { StyledRow, StyledSection } from './styles';
import { TextField } from '~components/inputs';
import { Select, SelectOption } from '~components/select';

const apiKeyTypeOptions: SelectOption[] = [
  {
    name: 'SDK',
    value: API_KEY_TYPES.SDK
  },
  {
    name: 'ADMIN',
    value: API_KEY_TYPES.ADMIN_API
  }
];

export const ApiKeys: React.FC = () => {
  const [apiKeyType, setApiKeyType] = React.useState<SelectOption>(apiKeyTypeOptions[0]);
  const [description, setDescription] = React.useState('');
  const { keys, newKeySecret, loading } = useStoreState(({ apiKeys }) => apiKeys);
  const [{ fetchApiKeys, createApiKeys, setApiKeyModel }, { setSideNavModel }] = useStoreActions(
    ({ apiKeys, sideNav }) => [apiKeys, sideNav]
  );
  const { user } = useStoreState((state) => state.user);
  const [tabKey, setTabKey] = React.useState<API_KEY_TYPES>(API_KEY_TYPES.SDK);

  const fetchKeys = () => {
    if (!keys.length) fetchApiKeys();
    return setApiKeyModel({ newKeySecret: {} });
  };

  const handleTabChange = (tab: API_KEY_TYPES) => {
    setTabKey(tab);
    setApiKeyType(apiKeyTypeOptions.find(({ value }) => value === tab));
  };

  const handleKeyTypeChange = ([option]: SelectOption[]) => {
    setApiKeyType(option);
  };

  const handleDescriptionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDescription(event.target.value);
  };

  const handleCreateKey = () => {
    createApiKeys({
      type: apiKeyType.value,
      description,
      onSuccess() {
        setTabKey(apiKeyType.value);
      }
    });
  };

  React.useEffect(() => {
    setSideNavModel({ topBarLeftTitle: 'settings', activeChild: 'apiKeys', links: settingsPageLinks });
    fetchKeys();
  }, []);

  return (
    <Container id="page-container">
      <Tab.Container defaultActiveKey={API_KEY_TYPES.SDK} activeKey={tabKey} onSelect={handleTabChange}>
        <StyledRow className="justify-content-md-center">
          <Col sm>
            <Nav className="flex-row">
              <Nav.Item>
                <Nav.Link eventKey={API_KEY_TYPES.SDK}>SDK</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey={API_KEY_TYPES.ADMIN_API}>Admin</Nav.Link>
              </Nav.Item>
              <PromptButton
                buttonType="button"
                onConfirm={handleCreateKey}
                buttonText="Create Api Key"
                confirmButtonText="create"
                loading={loading}
                promptTitle="Create a new api key"
                disableConfirm={!description.length}
                onClose={() => setDescription('')}
              >
                <StyledSection>
                  <Select
                    id="ApiKeyTypeSelect"
                    value={[apiKeyType]}
                    options={apiKeyTypeOptions}
                    onChange={handleKeyTypeChange}
                    placeholder="Select Api Key Type"
                  />
                  <TextField
                    name="apiKeyDescription"
                    label="Name"
                    value={description}
                    onChange={handleDescriptionChange}
                    invalid={!description.length}
                  />
                </StyledSection>
              </PromptButton>
            </Nav>
          </Col>
        </StyledRow>
        <StyledRow className="justify-content-md-center">
          <Col sm="auto">
            <Tab.Content>
              <Tab.Pane eventKey={API_KEY_TYPES.SDK}>
                {loading && <Spinner animation="border" />}
                {keys
                  .filter((key) => key.type === API_KEY_TYPES.SDK)
                  .map((apiKey) => (
                    <ApiKey key={apiKey.id} tenantId={user.tenantId} apiKey={apiKey} secret={newKeySecret[apiKey.id]} />
                  ))}
              </Tab.Pane>
              <Tab.Pane eventKey={API_KEY_TYPES.ADMIN_API}>
                {loading && <Spinner animation="border" />}
                {keys
                  .filter((key) => key.type === API_KEY_TYPES.ADMIN_API)
                  .map((apiKey) => (
                    <ApiKey key={apiKey.id} apiKey={apiKey} secret={newKeySecret[apiKey.id]} />
                  ))}
              </Tab.Pane>
            </Tab.Content>
          </Col>
        </StyledRow>
      </Tab.Container>
    </Container>
  );
};
