import {
  Entity,
  Column,
  PrimaryGeneratedColumn,
  JoinColumn,
  ManyToOne,
  DeleteDateColumn,
  CreateDateColumn,
  OneToMany
} from 'typeorm';
import { USER_PERMISSIONS } from '@pushologies/common/constants/user-permissions';
import { Tenant } from './tenant';
import { TenantUser } from './tenant-user';

@Entity('role_permission_group')
export class RolePermissionGroup {
  @PrimaryGeneratedColumn('uuid')
  id: string;

  @Column({ type: 'varchar', nullable: false })
  name: string;

  @Column({ type: 'simple-array', nullable: true })
  permissions: USER_PERMISSIONS[];

  @Column()
  tenantId: string;
  @ManyToOne((_) => Tenant, { onDelete: 'CASCADE' })
  @JoinColumn()
  tenant: Tenant;

  @OneToMany((_) => TenantUser, (tenantUser) => tenantUser.role)
  user: TenantUser;

  @CreateDateColumn({ name: 'created_at', type: 'timestamptz' })
  createdAt: Date;

  @Column({ nullable: true })
  createdById: string;
  @ManyToOne((_) => TenantUser, { onDelete: 'SET NULL' })
  @JoinColumn()
  createdBy: TenantUser;

  @DeleteDateColumn({ name: 'deleted_at' })
  deletedAt: Date;
}
