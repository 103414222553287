import { AxiosInstance } from 'axios';
import { SegmentRule } from '@pushologies/segments-service/types';
import { instance } from '../tools/axios-instance';
import { refreshAuthToken } from '../tools/auth.decorater';
import {
  ApiSegment,
  CreateSegmentParams,
  UpdateSegmentParams,
  FetchSegmentsParams,
  AddSubscribersToSegmentParams
} from './types';

export class SegmentsApiClass {
  constructor(private axios: AxiosInstance) {}

  @refreshAuthToken()
  async fetch(args: FetchSegmentsParams = {}): Promise<{ segments: ApiSegment[]; totalItems: number }> {
    if (args && !args?.name?.length) delete args.name;
    const { name, ...params } = args;

    const response = await this.axios.get(`/segments${name ? `?name=${encodeURIComponent(name)}` : ''}`, { params });
    return response.data.response;
  }

  @refreshAuthToken()
  async fetchByProxy(
    params: FetchSegmentsParams = {},
    tenantId: string
  ): Promise<{ segments: ApiSegment[]; totalItems: number }> {
    if (params && !params?.name?.length) delete params.name;
    const { name, ...queryStringParameters } = params;

    const {
      data: {
        response: {
          responses: [{ responseBody }]
        }
      }
    } = await this.axios.post('/proxy-call', {
      endpoint: `/v1/segments${name ? `?name=${encodeURIComponent(name)}` : ''}`,
      method: 'GET',
      requests: [
        {
          tenantId,
          queryStringParameters
        }
      ]
    });

    return responseBody.response;
  }

  @refreshAuthToken()
  async fetchSegment(id: string): Promise<ApiSegment> {
    const response = await this.axios.get('/segment', { params: { id } });
    return response.data.response;
  }

  @refreshAuthToken()
  async fetchSegmentByProxy(id: string, tenantId?: string): Promise<ApiSegment> {
    const {
      data: {
        response: {
          responses: [{ responseBody }]
        }
      }
    } = await this.axios.post('/proxy-call', {
      endpoint: '/v1/segment',
      method: 'GET',
      requests: [
        {
          tenantId,
          queryStringParameters: { id }
        }
      ]
    });

    return responseBody.response;
  }

  @refreshAuthToken()
  async fetchCount(
    params: { rules: SegmentRule[]; excludeUnsubscribers?: boolean } | { segmentBucketId: string }
  ): Promise<{ count: number }> {
    const response = await this.axios.post('/segment/preview', params);
    return response.data.response;
  }

  @refreshAuthToken()
  async create(params: CreateSegmentParams): Promise<{ segment: ApiSegment }> {
    const response = await this.axios.post('/segment', params);
    return response.data.response;
  }

  @refreshAuthToken()
  async update(params: UpdateSegmentParams): Promise<{ segment: ApiSegment }> {
    const response = await this.axios.put('/segment', params);
    return { segment: response.data.response };
  }

  @refreshAuthToken()
  async delete(id: string): Promise<{ success: boolean }> {
    const response = await this.axios.delete('/segment', { params: { id } });
    return response.data.response;
  }

  @refreshAuthToken()
  async addSubscribersToSegment({
    segmentId,
    segmentBucketId,
    key,
    ids
  }: AddSubscribersToSegmentParams): Promise<{ success: boolean; addedSubscriberCount: number }> {
    const response = await this.axios.post('/segment/subscribers', { segmentId, segmentBucketId, [key]: ids });
    return response.data.response;
  }
}

export const segmentsApi = new SegmentsApiClass(instance);
