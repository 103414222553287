import React from 'react';
import { DateTimePicker } from '../date-time-picker';
import { LocationInputSection } from './styles';
import { MapsPromptButton } from './maps-prompt-button';
import { Props, Location, Vertice } from './types';
import { CircleInputs, PolygonInputs } from './inputs';

export * from './types';

export const LocationField: React.FC<Props> = (props) => {
  const handleOnChange = (key: keyof Location) => (value: number | Vertice[]) => {
    props.onChange({ [key]: value });
  };

  const handleDateChange = (key: keyof Location) => (date: Date) => {
    props.onChange({ [key]: date });
  };

  const handleMapsPromptConfirm = (location: Location) => {
    props.onChange({ ...location });
  };

  return (
    <LocationInputSection
      invalid={props.invalid}
      className={props.className}
      data-testid={props.testId}
      $disabled={props.disabled}
    >
      <div className="inputRow">
        {props.vertices ? (
          <PolygonInputs
            vertices={props.vertices}
            handleInputChange={handleOnChange}
            testId={props.testId}
            disabled={props.disabled}
          />
        ) : (
          <CircleInputs
            latitude={props.latitude}
            longitude={props.longitude}
            radius={props.radius}
            handleInputChange={handleOnChange}
            testId={props.testId}
            disabled={props.disabled}
          />
        )}
        <MapsPromptButton
          location={{
            longitude: props.longitude,
            latitude: props.latitude,
            radius: props.radius,
            startDate: props.startDate,
            vertices: props.vertices,
            endDate: props.endDate
          }}
          onConfirm={handleMapsPromptConfirm}
          disabled={props.disabled}
          displayDateFields={props.displayDateFields}
          allowedDrawingModes={props.allowedDrawingModes}
        />
      </div>
      {props.displayDateFields && (
        <div className="inputRow dates">
          <DateTimePicker
            value={props.startDate}
            onChange={handleDateChange('startDate')}
            label="From"
            testId={`${props.testId}DatePicker`}
            disabled={props.disabled}
          />
          <DateTimePicker
            value={props.endDate}
            onChange={handleDateChange('endDate')}
            label="To"
            testId={`${props.testId}DatePicker`}
            disabled={props.disabled}
          />
        </div>
      )}
    </LocationInputSection>
  );
};
