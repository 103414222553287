import {
  Column,
  CreateDateColumn,
  Entity,
  Index,
  JoinColumn,
  JoinTable,
  ManyToMany,
  ManyToOne,
  OneToMany,
  PrimaryGeneratedColumn,
  UpdateDateColumn
} from 'typeorm';
import { Tag } from './tag';
import { Tenant } from './tenant';
import { SubscriberNotificationEvent } from './subscriber-event/notification';
import { lonLatPointToPoint, pointToLonLat } from '../../helpers/point-location-transformer';
import { CONNECTION_TYPE } from '@pushologies/common/constants/subscriber';

export enum SUBSCRIBER_DEVICE_TYPES {
  IOS = 'IOS',
  ANDROID = 'ANDROID'
}

export enum LOCATION_ACCURACY {
  EXACT = 'EXACT',
  IP_ADDRESS_BASED = 'IP_ADDRESS_BASED',
  NONE = 'NONE'
}

export enum INVALID_STATES {
  DEVICE = 'invalid_device',
  CREDENTIALS = 'invalid_credentials'
}

@Entity('subscriber')
@Index(['tenant', 'id'])
@Index(['deviceId', 'tenant'])
export class Subscriber {
  @PrimaryGeneratedColumn('uuid')
  id: string;

  @Column({ name: 'device_type', type: 'enum', enum: SUBSCRIBER_DEVICE_TYPES })
  @Index()
  deviceType: SUBSCRIBER_DEVICE_TYPES;

  @Column({ name: 'device_id', type: 'text', nullable: true, unique: true })
  @Index()
  deviceId: string;

  @Column({ name: 'fcm_token', type: 'text', nullable: true, unique: true })
  @Index()
  fcmToken: string;

  @Column({ name: 'pushkit_id', type: 'text', nullable: true, unique: true })
  @Index()
  pushKitId: string;

  @Column()
  tenantId: string;
  @ManyToOne((_) => Tenant, (tenant) => tenant.subscribers, { onDelete: 'CASCADE' })
  @JoinColumn()
  @Index()
  tenant: Tenant;

  @Column({ type: 'varchar', name: 'customer_id', length: 100, nullable: true })
  @Index()
  customerId: string;

  @Column({ type: 'timestamptz', name: 'last_seen', default: () => 'CURRENT_TIMESTAMP' })
  @Index()
  lastSeen: Date;

  /** Duration in milliseconds */
  @Column({ name: 'total_session_duration', type: 'float', default: 0 })
  @Index()
  totalSessionDuration: number;

  /** Duration in milliseconds */
  @Column({ name: 'average_session_duration', type: 'float', default: 0 })
  @Index()
  averageSessionDuration: number;

  @Column({ name: 'session_count', type: 'int', default: 0 })
  @Index()
  sessionCount: number;

  @Column({ name: 'app_version', nullable: true })
  @Index()
  appVersion: string;

  @Column({ name: 'sdk_version', nullable: true })
  @Index()
  sdkVersion: string;

  @Column({ name: 'connection_type', type: 'enum', enum: CONNECTION_TYPE, nullable: true })
  @Index()
  connectionType: CONNECTION_TYPE;

  @Column({ name: 'device_language', nullable: true })
  @Index()
  deviceLanguage: string;

  @Column({ name: 'keyboard_language', nullable: true })
  @Index()
  keyboardLanguage: string;

  @Column({ name: 'country_code', nullable: true })
  @Index()
  countryCode: string;

  @Column({ name: 'city_name', nullable: true })
  @Index()
  cityName: string;

  @Column({ name: 'ip_address', type: 'inet', nullable: true })
  @Index()
  ipAddress: string;

  @Column({
    type: 'geography',
    nullable: true,
    transformer: {
      from: (value) => pointToLonLat(value),
      to: (value) => lonLatPointToPoint(value)
    }
  })
  @Index()
  location: {
    longitude: number;
    latitude: number;
  };

  @Column({ name: 'timezone', nullable: true })
  @Index()
  timezone: string;

  @Column({ name: 'location_accuracy', type: 'enum', enum: LOCATION_ACCURACY, default: LOCATION_ACCURACY.NONE })
  @Index()
  locationAccuracy: LOCATION_ACCURACY;

  @Column({ name: 'permissions_location', type: 'boolean', nullable: true })
  @Index()
  permissionsLocation: boolean;

  @Column({ name: 'permissions_notification', type: 'boolean', nullable: true })
  @Index()
  permissionsNotification: boolean;

  @Column({ name: 'device_screen_x', type: 'smallint', nullable: true })
  deviceScreenX: number;

  @Column({ name: 'device_screen_y', type: 'smallint', nullable: true })
  deviceScreenY: number;

  @Column({ name: 'device_version', nullable: true })
  @Index()
  deviceVersion: string;

  @Column({ name: 'device_model_identifier', nullable: true })
  @Index()
  deviceModelIdentifier: string;

  @Column({ name: 'device_os', nullable: true })
  @Index()
  deviceOS: string;

  @Column({ nullable: true, type: 'varchar', length: 50 })
  @Index()
  invalid: INVALID_STATES;

  @Column({ nullable: true, type: 'varchar', length: 32 })
  checksum: string;

  @ManyToMany((_) => Tag, { cascade: true })
  @JoinTable({ name: 'subscriber_tags' })
  tags: Tag[];

  @OneToMany(
    (_) => SubscriberNotificationEvent,
    (subscriberNotificationEvent) => subscriberNotificationEvent.subscriber
  )
  subscriberNotificationEvents: SubscriberNotificationEvent[];

  @CreateDateColumn({ name: 'created_at', type: 'timestamptz' })
  createdAt: Date;

  @UpdateDateColumn({ name: 'updated_at', type: 'timestamptz' })
  updatedAt: Date;
}
