import styled from 'styled-components';
import { Button, ButtonProps } from 'react-bootstrap';

interface StyledButtonProps extends ButtonProps {
  $loading: boolean;
  $loadingPercentage: number;
}

export const StyledButton = styled(Button)<StyledButtonProps>`
  position: relative;
  border-radius: 2rem;
  padding: 0.375rem 1.5rem;

  div.spinner-border {
    position: absolute;
    top: calc(50% - 0.5rem);
    left: calc(50% - 0.5rem);
    z-index: 10;
  }

  span {
    opacity: ${({ $loading }) => ($loading ? 0 : 1)};
    z-index: 10;
  }

  &::after {
    content: '';
    background: ${({ theme }) => theme.palette.primaryPlum};
    position: absolute;
    top: 0;
    left: 0;
    transition: width 0.2s linear;
    width: ${({ $loadingPercentage }) => $loadingPercentage || '0'}%;
    height: 100%;
    border-radius: 2rem;
  }
`;
