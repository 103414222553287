import sizeof from 'object-sizeof';
import { SegmentRule } from '@pushologies/segments-service/types';
import { IdKeys } from '~store/create-segment/types';
import { Rule } from './types';

export function getRuleInvalidId(rule: Rule, type: 'value' | 'operator') {
  return `${rule.id}-${type}`;
}

export type IdsPayload = Partial<{
  key: IdKeys;
  ids: string[];
}>;

/**
 * Constructs segment subscriber endpoint body payloads which adher to a certain byte constraint
 */
export function constructSegmentSubscribersPayloads(rules: SegmentRule<string[]>[], byteThreshold: number) {
  const payloads: IdsPayload[] = [];
  let payload: IdsPayload = { ids: [] };

  rules.forEach((rule) => {
    payload.key = rule.ruleId as IdKeys;

    while (true) {
      payload.ids = payload.ids.concat(rule.value.splice(0, 50));
      if (sizeof(payload) >= byteThreshold) {
        payloads.push({ ...payload });
        payload.ids = [];
      }
      if (!rule.value.length) {
        if (payload.ids.length) payloads.push({ ...payload });
        payload = { ids: [] };
        break;
      }
    }
  });

  return payloads;
}
