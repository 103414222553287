import React, { useRef, useMemo } from 'react';
import { throttle } from 'throttle-debounce';
import { CONTENT_ITEM_MEDIA_TYPE, CONTENT_ITEM_PRESENTATION_TYPE } from '@pushologies/common/constants/content-item';
import { FileUpload, FileUploadRef } from '~components/file-upload';
import { useStoreState, useStoreActions } from '~store/hooks';
import { useFileViewerOptions } from '~components/file-viewers/hooks';
import { ApiContentItem } from '~api/content-items/types';
import { TileFile, TilesViewer } from '~components/file-viewers/tiles';
import { constructThumbnailUrl } from '~api/tools/helpers';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { ColumnDiv, CarouselBannerDiv } from '../styles';
import { BannerImagesColumnContentDiv, BannerPreviewFigure, BannerUploadColumnContentDiv } from './styles';

const ITEM_LIMIT = 40;

interface Props {
  type?: string;
}

export const CarouselButtonArea: React.FC<Props> = (props) => {
  const [bannerItems, setBannerItems] = React.useState<ApiContentItem[]>([]);
  const fileUploadRef = useRef<FileUploadRef>();
  const { notification } = useStoreState((state) => state.createNotification);
  const { setContent, setCarouselButtons } = useStoreActions((state) => state.createNotification);
  const { status, items } = useStoreState((state) => state.contentItems);
  const { fetchItems, uploadItems, fetchItem } = useStoreActions((state) => state.contentItems);
  const { options, setOptions } = useFileViewerOptions();
  const { hideCarouselButtons } = useFlags();

  const presentationType = CONTENT_ITEM_PRESENTATION_TYPE.CAROUSEL_BUTTON;

  const [carouselPreviousButtonUrl, carouselNextButtonUrl] = useMemo(() => {
    const { previous, next } = notification?.options?.carousel?.navButtons || {};
    const previousItem = items[previous?.contentItemId];
    const nextItem = items[next?.contentItemId];

    return [constructThumbnailUrl(previousItem), constructThumbnailUrl(nextItem)];
  }, [items, notification]);

  const bannerTileFiles: TileFile[] = useMemo(
    () =>
      bannerItems.map((item) => ({
        id: item.id,
        name: item.name,
        thumbnailUrl: constructThumbnailUrl(item),
        size: item.size
      })),
    [bannerItems]
  );

  const handleBannerFileSelect = (fileIds: string[]) => {
    setCarouselButtons(fileIds);
  };

  const handleBannerUpload = (files: File[]) => {
    fileUploadRef.current.setLoading(true);

    uploadItems({
      files,
      mediaType: CONTENT_ITEM_MEDIA_TYPE.IMAGE,
      presentation: [presentationType],
      onProgress: fileUploadRef.current.setLoadingPercentage,
      onSuccess([item]) {
        setContent({
          type: CONTENT_ITEM_PRESENTATION_TYPE.BANNER,
          content: { contentItem: { id: item.id } }
        });
        fileUploadRef.current.reset();
        setBannerItems([item, ...bannerItems]);
      },
      onError() {
        fileUploadRef.current.setLoading(false);
      }
    });
  };

  const handleBannerFilterChange = (text: string) => {
    setOptions({ text });
    throttle(250, () => {
      fetchItems({
        limit: ITEM_LIMIT,
        offset: options.offset,
        name: text,
        mediaType: CONTENT_ITEM_MEDIA_TYPE.IMAGE,
        presentation: [presentationType],
        onSuccess(newItems, totalItems) {
          setOptions({ totalItems });
          setBannerItems(newItems);
        }
      });
    })();
  };

  const handlePageChange = (offset: number) => {
    fetchItems({
      limit: ITEM_LIMIT,
      offset,
      name: options.text,
      mediaType: CONTENT_ITEM_MEDIA_TYPE.IMAGE,
      presentation: [presentationType],
      onSuccess(newItems, totalItems) {
        setOptions({ totalItems, offset });
        setBannerItems(newItems);
      }
    });
  };

  const handleExternalContentAdded = () => {
    fetchItems({
      limit: ITEM_LIMIT,
      mediaType: CONTENT_ITEM_MEDIA_TYPE.IMAGE,
      onSuccess(updatedItems, totalItems) {
        setOptions({ totalItems });
        setBannerItems(updatedItems);
      }
    });
  };

  React.useEffect(() => {
    fetchItems({
      limit: ITEM_LIMIT,
      offset: 0,
      mediaType: CONTENT_ITEM_MEDIA_TYPE.IMAGE,
      presentation: [presentationType],
      onSuccess(newItems, totalItems) {
        setOptions({ totalItems });
        setBannerItems(newItems);
      }
    });
  }, [props.type]);

  React.useEffect(() => {
    if (notification?.bannerContent?.contentItem?.id) {
      fetchItem({ id: notification.bannerContent.contentItem.id, url: true });
    }
  }, [notification?.bannerContent?.contentItem?.id]);

  return (
    <>
      {!hideCarouselButtons ? (
        <CarouselBannerDiv>
          <ColumnDiv>
            <BannerUploadColumnContentDiv>
              <BannerPreviewFigure label="L" isCarousel $bannerUrl={carouselPreviousButtonUrl} />
              <BannerPreviewFigure label="R" isCarousel $bannerUrl={carouselNextButtonUrl} />

              <FileUpload
                ref={fileUploadRef}
                dropzoneOptions={{
                  onDrop: handleBannerUpload,
                  accept: ['image/*'],
                  multiple: false
                }}
                buttonText={'Create Nav Button'}
                presetExternalFields={{
                  mediaType: CONTENT_ITEM_MEDIA_TYPE.IMAGE,
                  presentation: [presentationType]
                }}
                disabledExternalFields={['mediaType', 'presentation']}
                onExternalContentAdded={handleExternalContentAdded}
              />
            </BannerUploadColumnContentDiv>
          </ColumnDiv>
          <ColumnDiv>
            <BannerImagesColumnContentDiv>
              <TilesViewer
                testId="bannerImagesTileViewer"
                files={bannerTileFiles}
                loading={status === 'fetching'}
                title={'Carousel Buttons'}
                onSelect={handleBannerFileSelect}
                // selectedFileIds={[
                //   notification?.options?.carousel?.navButtons?.previous?.contentItemId,
                //   notification?.options?.carousel?.navButtons?.next?.contentItemId
                // ]}
                pagination={{
                  limit: ITEM_LIMIT,
                  offset: options.offset,
                  totalItems: options.totalItems,
                  onPageChange: handlePageChange
                }}
                filterValue={options.text}
                onFilterValueChange={handleBannerFilterChange}
                filterPlaceholder="Filter by Name"
                multiSelect={true}
                multiSelectLimit={2}
              />
            </BannerImagesColumnContentDiv>
          </ColumnDiv>
        </CarouselBannerDiv>
      ) : (
        ''
      )}
    </>
  );
};
