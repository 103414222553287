import React, { useRef, useMemo } from 'react';
import { throttle } from 'throttle-debounce';
import { CONTENT_ITEM_MEDIA_TYPE, CONTENT_ITEM_PRESENTATION_TYPE } from '@pushologies/common/constants/content-item';
import { FileUpload, FileUploadRef } from '~components/file-upload';
import { ColumnDiv } from '../styles';
import { BannerImagesColumnContentDiv, BannerPreviewFigure, BannerUploadColumnContentDiv } from './styles';
import { useStoreState, useStoreActions } from '~store/hooks';
import { useFileViewerOptions } from '~components/file-viewers/hooks';
import { ApiContentItem } from '~api/content-items/types';
import { TileFile, TilesViewer } from '~components/file-viewers/tiles';
import { constructThumbnailUrl } from '~api/tools/helpers';

const ITEM_LIMIT = 40;

interface Props {
  type?: string;
}

export const BannerArea: React.FC<Props> = (props) => {
  const [bannerItems, setBannerItems] = React.useState<ApiContentItem[]>([]);
  const fileUploadRef = useRef<FileUploadRef>();
  const { notification } = useStoreState((state) => state.createNotification);
  const { setContent } = useStoreActions((state) => state.createNotification);
  const { status, items } = useStoreState((state) => state.contentItems);
  const { fetchItems, uploadItems, fetchItem } = useStoreActions((state) => state.contentItems);
  const bannerUrl =
    items[notification?.bannerContent?.contentItem?.id ?? notification?.richImageContent?.contentItem?.id]?.downloadUrl;
  const { options, setOptions } = useFileViewerOptions();

  const presentationType =
    props.type === 'richImageTab' ? CONTENT_ITEM_PRESENTATION_TYPE.RICH_IMAGE : CONTENT_ITEM_PRESENTATION_TYPE.BANNER;

  const bannerTileFiles: TileFile[] = useMemo(
    () =>
      bannerItems.map((item) => ({
        id: item.id,
        name: item.name,
        thumbnailUrl: constructThumbnailUrl(item),
        size: item.size
      })),
    [bannerItems]
  );

  const handleFileSelect = (fileIds: string[]) => {
    const id = fileIds[0];

    setContent({
      type: presentationType,
      content: { contentItem: { id }, presentation: presentationType }
    });
  };

  const handleBannerUpload = (files: File[]) => {
    fileUploadRef.current.setLoading(true);

    uploadItems({
      files,
      mediaType: CONTENT_ITEM_MEDIA_TYPE.IMAGE,
      presentation: [presentationType],
      onProgress: fileUploadRef.current.setLoadingPercentage,
      onSuccess([item]) {
        setContent({
          type: presentationType,
          content: { contentItem: { id: item.id }, presentation: presentationType }
        });
        fileUploadRef.current.reset();
        setBannerItems([item, ...bannerItems]);
      },
      onError() {
        fileUploadRef.current.setLoading(false);
      }
    });
  };

  const handleBannerFilterChange = (text: string) => {
    setOptions({ text });
    throttle(250, () => {
      fetchItems({
        limit: ITEM_LIMIT,
        offset: options.offset,
        name: text,
        mediaType: CONTENT_ITEM_MEDIA_TYPE.IMAGE,
        presentation: [presentationType],
        onSuccess(newItems, totalItems) {
          setOptions({ totalItems });
          setBannerItems(newItems);
        }
      });
    })();
  };

  const handlePageChange = (offset: number) => {
    fetchItems({
      limit: ITEM_LIMIT,
      offset,
      name: options.text,
      mediaType: CONTENT_ITEM_MEDIA_TYPE.IMAGE,
      presentation: [presentationType],
      onSuccess(newItems, totalItems) {
        setOptions({ totalItems, offset });
        setBannerItems(newItems);
      }
    });
  };

  React.useEffect(() => {
    fetchItems({
      limit: ITEM_LIMIT,
      offset: 0,
      mediaType: CONTENT_ITEM_MEDIA_TYPE.IMAGE,
      presentation: [presentationType],
      onSuccess(newItems, totalItems) {
        setOptions({ totalItems });
        setBannerItems(newItems);
      }
    });
  }, [props.type]);

  React.useEffect(() => {
    if (notification?.bannerContent?.contentItem?.id) {
      fetchItem({ id: notification.bannerContent.contentItem.id, url: true });
    }
    if (notification?.richImageContent?.contentItem?.id) {
      fetchItem({ id: notification.richImageContent.contentItem.id, url: true });
    }
  }, [notification?.bannerContent?.contentItem?.id, notification?.richImageContent?.contentItem?.id]);

  const handleExternalContentAdded = () => {
    fetchItems({
      limit: ITEM_LIMIT,
      presentation: [presentationType],
      onSuccess(updateItems, totalItems) {
        setOptions({ totalItems });
        setBannerItems(updateItems);
      }
    });
  };

  return (
    <>
      <ColumnDiv>
        <BannerUploadColumnContentDiv>
          <BannerPreviewFigure $bannerUrl={bannerUrl} isRichImage={props.type === 'richImageTab'} />
          <FileUpload
            ref={fileUploadRef}
            dropzoneOptions={{
              onDrop: handleBannerUpload,
              accept: ['image/*'],
              multiple: false
            }}
            buttonText={props.type === 'richImageTab' ? 'Create New Rich Image' : 'Create New Banner Image'}
            presetExternalFields={{
              mediaType: CONTENT_ITEM_MEDIA_TYPE.IMAGE,
              presentation: [presentationType]
            }}
            disabledExternalFields={['mediaType', 'presentation']}
            onExternalContentAdded={handleExternalContentAdded}
          />
        </BannerUploadColumnContentDiv>
      </ColumnDiv>
      <ColumnDiv>
        <BannerImagesColumnContentDiv>
          <TilesViewer
            testId={props.type === 'richImageTab' ? 'richImageTileViewer' : 'bannerImagesTileViewer'}
            files={bannerTileFiles}
            loading={status === 'fetching'}
            title={props.type === 'richImageTab' ? 'Rich Images' : 'Banner Images (android only)'}
            onSelect={handleFileSelect}
            selectedFileIds={[
              notification?.bannerContent?.contentItem?.id ?? notification?.richImageContent?.contentItem?.id
            ]}
            pagination={{
              limit: ITEM_LIMIT,
              offset: options.offset,
              totalItems: options.totalItems,
              onPageChange: handlePageChange
            }}
            filterValue={options.text}
            onFilterValueChange={handleBannerFilterChange}
            filterPlaceholder="Filter by Name"
          />
        </BannerImagesColumnContentDiv>
      </ColumnDiv>
    </>
  );
};
