import {
  Entity,
  PrimaryGeneratedColumn,
  Column,
  OneToMany,
  ManyToOne,
  CreateDateColumn,
  JoinColumn,
  DeleteDateColumn,
  Index,
  OneToOne
} from 'typeorm';
import dayjs from '@pushologies/common/helpers/dayjs';
import { PersonalisationModifierKeys } from '@pushologies/common/helpers/string-modifiers';
import { Content } from './content';
import { Campaign } from './campaign';
import { Segment } from './segment';
import { Tenant } from './tenant';
import { NotificationResults } from './notification-results';
import { TenantUser } from './tenant-user';
import { ScheduledPush, PUSH_SCHEDULE_MODE } from './scheduled-push';
import { SegmentBucket } from './segment-bucket';
import { Geofence } from './geofence';
import { Poll } from './poll';
import { InApp } from '@pushologies/common/constants/in-app';

export interface PersonalisationConfigItemType {
  name: string;
  defaultValue: any;
  description?: string;
  modifiers?: PersonalisationModifierKeys[];
}

export interface PersonalisationConfigType {
  [key: string]: PersonalisationConfigItemType;
}

export interface PersonalisationConfigItem {
  uuid: string;
  variable: PersonalisationConfigItemType;
}

export interface TargetUrlData {
  url: string;
  buttonId: string;
}

export type PushScheduleConfig =
  | { mode: PUSH_SCHEDULE_MODE.NORMAL; pushAt: Date; localTime: never }
  | { mode: PUSH_SCHEDULE_MODE.TIMEZONE; pushAt: Date; localTime: { hour: number; minute: number } };

export enum NOTIFICATION_TYPE {
  VIDEO = 'VIDEO',
  DOCUMENT = 'DOCUMENT',
  STANDARD = 'STANDARD',
  CAROUSEL = 'CAROUSEL',
  INAPP = 'INAPP',
  POLL = 'POLL'
}

export interface NotificationOptions {
  carousel?: {
    navButtons: {
      previous: { contentItemId: string };
      next: { contentItemId: string };
    };
  };
}

export interface StaggerOptions {
  intervals: number;
  batchSize: number;
}

@Entity('notification')
@Index(['id', 'deletedAt'])
@Index(['id', 'tenant', 'deletedAt'])
@Index(['tenant', 'createdBy', 'deletedAt'])
@Index(['tenant', 'pushedAt', 'deletedAt'])
export class Notification {
  @PrimaryGeneratedColumn('uuid')
  id: string;

  @Index()
  @Column({ type: 'text' })
  title: string;

  @Index()
  @Column({ name: 'sub_title', type: 'text', nullable: true })
  subTitle: string;

  @Column({ type: 'text', nullable: true })
  description: string;

  @Column({ type: 'text' })
  message: string;

  @Column({ type: 'json', nullable: true })
  schedule: PushScheduleConfig;

  @OneToMany((_) => ScheduledPush, (scheduledPush) => scheduledPush.notification)
  scheduledPushList: ScheduledPush[];

  @Column({ type: 'integer', default: -1 })
  ttl: number;

  @Column({ type: 'json', nullable: true })
  options: NotificationOptions;

  @Column({ name: 'client_metadata', type: 'text', nullable: true })
  clientMetadata: string;

  @Column({ type: 'text', nullable: true })
  metadata: string;

  @Column({ name: 'target_url', type: 'text', nullable: true })
  targetUrl: string;

  @Column({ name: 'target_url_data', type: 'json', nullable: true })
  targetUrlData?: TargetUrlData;

  @Index()
  @Column({ name: 'type', type: 'enum', enum: NOTIFICATION_TYPE, nullable: true })
  type: NOTIFICATION_TYPE;

  @OneToMany((_) => Content, (content) => content.notification, { cascade: ['insert'] })
  contents: Content[];

  @Column({ name: 'personalisation', type: 'json', nullable: true })
  personalisation: PersonalisationConfigItem[];

  @Column({ name: 'in_app', type: 'json', nullable: true })
  inApp?: InApp;

  @ManyToOne((_) => Campaign, (campaign) => campaign.notifications)
  @Index()
  campaign: Campaign;

  @Column({ nullable: true })
  segmentBucketId?: string;
  @ManyToOne((_) => SegmentBucket, (bucket) => bucket.id, { nullable: true })
  segmentBucket?: SegmentBucket;

  @Column({ nullable: true })
  segmentId?: string;
  @ManyToOne((_) => Segment, (segment) => segment.id, { nullable: true })
  @Index()
  segment?: Segment;

  @Column()
  @Index()
  tenantId: string;
  @ManyToOne((_) => Tenant, (tenant) => tenant.notifications, { onDelete: 'CASCADE' })
  @Index()
  tenant: Tenant;

  @Column({ name: 'is_draft', type: 'boolean', default: true })
  isDraft: boolean;

  @Column({ name: 'cancelled_at', type: 'timestamptz', nullable: true })
  cancelledAt: Date;

  @Column({ name: 'pushed_at', type: 'timestamptz', nullable: true })
  pushedAt: Date;

  @OneToMany((_) => NotificationResults, (notificationResults) => notificationResults.notification)
  results: NotificationResults[];

  @Column({ nullable: true })
  geofenceId?: string;
  @OneToOne((_) => Geofence, { cascade: true })
  @JoinColumn()
  geofence?: Geofence;

  @Column({ type: 'simple-json', nullable: true })
  stagger?: StaggerOptions;

  @Column({ nullable: true })
  createdById: string;
  @ManyToOne((_) => TenantUser, { onDelete: 'SET NULL' })
  @JoinColumn()
  @Index()
  createdBy: TenantUser;

  @CreateDateColumn({ name: 'created_at', type: 'timestamptz' })
  @Index()
  createdAt: Date;

  @DeleteDateColumn({ name: 'deleted_at', type: 'timestamptz' })
  deletedAt: Date;

  @Column({ nullable: true })
  pollId?: string;
  @OneToOne((_) => Poll, { cascade: true })
  @JoinColumn()
  poll?: Poll;

  @Column({ name: 'is_hidden', type: 'boolean', default: false })
  isHidden: boolean;

  isPushCancelled() {
    return !this.cancelledAt || !this.pushedAt ? false : dayjs(this.cancelledAt).diff(this.pushedAt) > 0;
  }
}
