import { SelectQueryBuilder } from 'typeorm';
import { Subscriber } from '@pushologies/database-service/db/entities/subscriber';
import { OperatorValue as Op, SegmentRule } from '../types';
import { Rule } from '.';

export class DeviceLanguageRule extends Rule {
  constructor(private rule: SegmentRule<string>) {
    super();
  }

  updateQuery(query: SelectQueryBuilder<Subscriber>) {
    const [sql, params] = this.getQueryString();
    query.andWhere(sql, params);
  }

  private getQueryString(): [string, object] {
    const languageParam = this.createParameterHash();
    switch (this.rule.operator) {
      case Op.is:
        return [`subscriber.device_language = :${languageParam}`, { [languageParam]: this.rule.value }];
      case Op.isNot:
        return [`subscriber.device_language != :${languageParam}`, { [languageParam]: this.rule.value }];
      default:
        throw new Error(`operator "${this.rule.operator}" not supported`);
    }
  }
}
