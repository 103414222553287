import { NOTIFICATION_TYPE } from '@pushologies/database-service/db/entities/notification';
import { SelectOption } from '~components/select';
import { Fonts, LinkHandling } from '@pushologies/database-service/db/entities/content';
import { INAPP_TYPE } from '@pushologies/common/constants/in-app';

export const zoomModeOptions: SelectOption<string>[] = [
  {
    name: 'Fill',
    value: 'zoom'
  },
  {
    name: 'Fit',
    value: 'fit'
  }
];

export const orientationOptions: SelectOption<string>[] = [
  {
    name: 'landscape',
    value: 'landscape'
  },
  {
    name: 'portrait',
    value: 'portrait'
  }
];

export const linkHandlingOptions: SelectOption<LinkHandling>[] = [
  {
    name: 'Default',
    value: 'defaultBrowser'
  },
  {
    name: 'In-App',
    value: 'inAppBrowser'
  },
  {
    name: 'Default + Tag',
    value: 'defaultBrowserWithTag'
  },
  {
    name: 'In-App + Tag',
    value: 'inAppBrowserWithTag'
  }
];

export const fontOptions: SelectOption<string>[] = Object.values(Fonts).map((font) => ({
  name: font,
  value: font
}));

export const textAlignmentOptions: SelectOption<string>[] = [
  'Top-left',
  'Top-center',
  'Top-right',
  'Center-center',
  'Bottom-left',
  'Bottom-center',
  'Bottom-right'
].map((name) => ({
  name,
  value: name.toLowerCase()
}));
export const textWrappingOptions: SelectOption<boolean>[] = ['No-wrap', 'Wrap'].map((name) => ({
  name,
  value: name === 'Wrap'
}));

export const notificationsTypeOptions = [
  { label: 'Standard', value: NOTIFICATION_TYPE.STANDARD },
  { label: 'Video', value: NOTIFICATION_TYPE.VIDEO },
  { label: 'Carousel', value: NOTIFICATION_TYPE.CAROUSEL },
  { label: 'Poll', value: NOTIFICATION_TYPE.POLL }
];

export const inAppTypeOptions = [
  { label: 'Standard', value: INAPP_TYPE.STANDARD },
  { label: 'Image', value: INAPP_TYPE.IMAGE },
  { label: 'Video', value: INAPP_TYPE.VIDEO },
  { label: 'Carousel', value: INAPP_TYPE.CAROUSEL }
];

export interface DeviceCase {
  name: string;
  make: 'samsung' | 'apple';
  element: React.ReactNode;
  /** Width of device in pixels */
  width: number;
  /** Height of device in pixels */
  height: number;
  /** Width of device screen in pixels */
  screenWidth: number;
  /** height of device screen in pixels */
  screenHeight: number;
  /** The amount of pixels from the left of device the screen begins */
  screenOffsetX: number;
  /** The amount of pixels from the top of device the screen begins */
  screenOffsetY: number;
}

export const deviceSelectOptions: SelectOption<string>[] = [
  {
    name: 'Samsung Note',
    value: 'samsung_note_'
  },
  {
    name: 'Apple 12',
    value: 'iphone_12_'
  }
];

export const deviceCaseMetrics: Record<string, DeviceCase> = {
  samsung_note_landscape: {
    name: 'Samsung Note',
    make: 'samsung',
    element: require('../../../assets/images/samsung_note_landscape.svg').default(),
    width: 1252,
    height: 595,
    screenWidth: 1202,
    screenHeight: 567,
    screenOffsetX: 19,
    screenOffsetY: 13
  },
  samsung_note_portrait: {
    name: 'Samsung Note',
    make: 'samsung',
    element: require('../../../assets/images/samsung_note_portrait.svg').default(),
    width: 595,
    height: 1252,
    screenHeight: 1204,
    screenWidth: 567,
    screenOffsetX: 16,
    screenOffsetY: 18
  },
  iphone_12_portrait: {
    name: 'Apple 12',
    make: 'apple',
    element: require('../../../assets/images/iphone_12.svg').default(),
    width: 153,
    height: 309,
    screenHeight: 296,
    screenWidth: 138,
    screenOffsetX: 7,
    screenOffsetY: 6
  },
  iphone_12_landscape: {
    name: 'Apple 12',
    make: 'apple',
    element: require('../../../assets/images/iphone_12_landscape.svg').default(),
    width: 309,
    height: 153,
    screenHeight: 137,
    screenWidth: 297,
    screenOffsetX: 7,
    screenOffsetY: 8
  }
};
