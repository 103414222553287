import React from 'react';
import { TENANT_USER_ROLE, TENANT_USER_STATUS } from '@pushologies/database-service/db/entities/tenant-user';
import { TextField } from '~components/inputs';
import { Select, SelectOption } from '~components/select';
import MagGlassIcon from '~images/mag_glass.svg';
import { StyledFiltersRow, SearchCol, SelectsCol } from './styles';
import { Col, Button } from 'react-bootstrap';
import { UserForm } from './user-form';

export interface Filters {
  offset: number;
  search: string;
  status: SelectOption[];
  role: SelectOption[];
}

export const filtersToApi = (filters: Partial<Filters>) => ({
  ...(!!filters.offset && { offset: filters.offset }),
  ...(filters.search?.length && { search: filters.search }),
  ...(filters.status && filters.status?.[0]?.value !== 'SHOW ALL' && { status: filters.status?.[0]?.value }),
  ...(filters.role && filters.role?.[0]?.value !== 'SHOW ALL' && { role: filters.role?.[0]?.value })
});

interface Props extends Filters {
  onFilterChange(filters: Partial<Filters>): void;
  onClear(): void;
  onUpdateSuccess(): void;
}

const statusSelectOption: SelectOption[] = ['SHOW ALL', ...Object.values(TENANT_USER_STATUS)].map((item) => ({
  name: item,
  value: item
}));
const roleSelectOption: SelectOption[] = ['SHOW ALL', ...Object.values(TENANT_USER_ROLE)].map((item) => ({
  name: item,
  value: item
}));

export const FiltersRow: React.FC<Props> = (props) => {
  const handleSearchFilterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const search = event.target.value;
    props.onFilterChange({ search, offset: 0 });
  };

  const handleStatusChange = (selected: SelectOption[]) => {
    // allow for deselection
    props.onFilterChange({
      status: props.status?.[0]?.value === selected?.[0].value ? [] : selected,
      offset: 0
    });
  };
  const handleRoleChange = (selected: SelectOption[]) => {
    // allow for deselection
    props.onFilterChange({
      role: props.role?.[0]?.value === selected?.[0].value ? [] : selected,
      offset: 0
    });
  };

  const handleClear = () => {
    props.onFilterChange({ search: '', status: undefined, role: undefined, offset: 0 });
  };

  return (
    <StyledFiltersRow>
      <SelectsCol md={6}>
        <Select
          id="userStatusSelect"
          value={props.status}
          options={statusSelectOption || []}
          placeholder="Status"
          inValid={false}
          onChange={handleStatusChange}
        />
        <Select
          id="userRoleSelect"
          value={props.role}
          options={roleSelectOption || []}
          placeholder="Role"
          inValid={false}
          onChange={handleRoleChange}
        />
        <Button variant="secondary" size="lg" onClick={handleClear} data-testid="clearButton">
          Clear
        </Button>
      </SelectsCol>
      <SearchCol md={5}>
        <TextField
          name="searchFilter"
          placeholder="Search by name or e-mail address"
          value={props.search}
          onChange={handleSearchFilterChange}
          icon={<MagGlassIcon />}
        />
      </SearchCol>
      <Col md={1}>
        <UserForm formType={'create'} onClose={props.onUpdateSuccess} />
      </Col>
    </StyledFiltersRow>
  );
};
