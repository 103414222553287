import React from 'react';
import { useHistory } from 'react-router-dom';
import { Col, Table } from 'react-bootstrap';
import { SUBSCRIBER_DEVICE_TYPES } from '@pushologies/database-service/db/entities/subscriber';
import { AndroidIconStyled, AppleIconStyled, StyledEye } from '~components/styled/icons';
import { useStoreActions } from '~store/hooks';
import { TableSection } from '~components/styled/table-section';
import { Loader } from '~components/loader';
import { Pagination } from '~components/pagination';
import { CopyWidget } from '~components/copy-widget';
import { dateTimeFormat } from '~helpers/formats';
import { ListRow } from './styles';

const LIMIT = 50;

export const SubscriberExplorerList: React.FC = () => {
  const history = useHistory();
  const [subScriberList, setSubScriberList] = React.useState([]);
  const { fetchSubscribers } = useStoreActions((state) => state.subscribers);
  const [loading, setLoading] = React.useState(true);
  const [pageCount, setPageCount] = React.useState(0);
  const [offset, setOffset] = React.useState(0);

  const handlePageChange = ({ selected }: { selected: number }) => {
    setLoading(true);
    const newOffset = selected * LIMIT;
    setOffset(newOffset);
    fetchSubscribers({
      limit: LIMIT,
      offset: newOffset,
      onSuccess(subscribers, totalItems) {
        setSubScriberList(subscribers);
        setPageCount(totalItems / LIMIT);
        setLoading(false);
      }
    });
  };

  const showSubscriberDetail = (id: string) => {
    history.push(`/subscriber-explorer/${id}`);
  };

  React.useEffect(() => {
    fetchSubscribers({
      limit: LIMIT,
      onSuccess(subscribers, totalItems) {
        setSubScriberList(subscribers);
        setPageCount(totalItems / LIMIT);
        setLoading(false);
      }
    });
  }, []);

  return (
    <ListRow>
      <Col md={12}>
        <TableSection>
          <Loader loading={loading} backdrop />
          <Table responsive>
            <thead>
              <tr>
                <th>Subscriber Id</th>
                <th style={{ width: '4rem' }}>Type</th>
                <th>Device Id</th>
                <th style={{ width: '10rem' }}>Created At </th>
                <th style={{ width: '10rem' }}>Last Seen</th>
                <th style={{ width: '7rem' }}>Actions</th>
              </tr>
            </thead>
            <tbody>
              {subScriberList.map((subscriber) => {
                return (
                  <tr key={subscriber.id} data-testid="subscriberListRow">
                    <td>
                      <CopyWidget copyText={subscriber.id} copyMessage="copied subscriber id">
                        {subscriber.id}
                      </CopyWidget>
                    </td>
                    <td className="deviceType">
                      {subscriber?.deviceType === SUBSCRIBER_DEVICE_TYPES.ANDROID ? (
                        <AndroidIconStyled />
                      ) : (
                        <AppleIconStyled />
                      )}
                    </td>
                    <td>
                      <CopyWidget copyText={subscriber.deviceId} copyMessage="copied device id">
                        {subscriber.deviceId}
                      </CopyWidget>
                    </td>
                    <td>{dateTimeFormat(subscriber.createdAt)}</td>
                    <td>{dateTimeFormat(subscriber.lastSeen)}</td>

                    <td>
                      <a data-testid="displaySubscriber" onClick={() => showSubscriberDetail(subscriber.id)}>
                        <StyledEye />
                      </a>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
          <Pagination
            pageCount={pageCount}
            marginPagesDisplayed={2}
            pageRangeDisplayed={5}
            forcePage={offset / LIMIT}
            breakLabel="..."
            onPageChange={handlePageChange}
          />
        </TableSection>
      </Col>
    </ListRow>
  );
};
