import dayjs from '~helpers/dayjs';

export const isUrlValid = (url: string) => {
  if (!url) return false;

  const urlObj = new URL(url);
  const expiry = parseInt(urlObj.searchParams.get('Expires'), 0) * 1000; // unix epoch time -> js epoch time

  return dayjs() < dayjs(expiry).subtract(1.75, 'day');
};
