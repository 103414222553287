import { Fonts } from '@pushologies/database-service/db/entities/content';
import { Row } from 'react-bootstrap';
import styled from 'styled-components';

export const PollFormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  height: 94%;

  section {
    width: 100%;
  }

  #pollQuestionFontSelect,
  #pollAnswerFontSelect {
    border: 1px solid #9f9f9f;
  }
`;

export const PollFormContainer = styled.div`
  flex-grow: 1;
  overflow: hidden;
`;

export const PollFormActions = styled.div`
  flex: none;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  padding: 20px 0;
`;

export const PollFormInputs = styled.div`
  height: 100%;
  overflow: auto;
`;

export const PollFormRow = styled.div`
  margin: 20px 0;

  p {
    margin: 0;
  }
`;

export const PollAnswerRow = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 10px 0;

  section {
    flex-grow: 1;
    margin-right: 10px;
  }

  button {
    padding: 0px 10px;
    border-radius: 0;
  }
`;

export const PollPreviewWrapper = styled.div<{
  styleConfig: {
    backgroundColor?: string;
    backgroundImageUrl?: string;
  };
}>`
  position: relative;
  width: 100%;
  height: 75%;
  border: 1px solid #000;
  display: flex;
  flex-direction: column;
  padding: 20px;
  overflow: auto;

  ${({ styleConfig }) => `
    background-color: ${styleConfig.backgroundColor};
    ${
      styleConfig.backgroundImageUrl &&
      `
      background-image: url(${styleConfig.backgroundImageUrl});
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
`
    }
  `}
`;

export const PollPreviewResetButton = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;
  width: 30px;
  height: 30px;
  padding: 4px;
  border-radius: 100vh;
  background-color: #000;
  color: #fff;
  z-index: 2;
  text-align: center;
  user-select: none;
  cursor: pointer;
`;

export const PollPreviewBlock = styled.div<{
  styleConfig: {
    backgroundColor?: string;
    color?: string;
    font?: Fonts;
    fontSize?: number;
    marginTop?: number;
  };
}>`
  width: 100%;
  text-align: center;
  padding: 10px;
  margin: 10px 0;
  user-select: none;

  img {
    width: 100%;
  }

  &.poll-choice {
    cursor: pointer;
  }

  ${({ styleConfig }) => `
    background-color: ${styleConfig.backgroundColor};
    color: ${styleConfig.color};
    font-family: ${styleConfig.font};
    font-size: ${styleConfig.fontSize}px;
    margin-top: ${styleConfig.marginTop}px;
  `}
`;

export const PollVoteSuccessMessage = styled.div<{
  styleConfig: {
    backgroundColor?: string;
    color?: string;
    font?: Fonts;
    fontSize?: number;
  };
}>`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  span {
    ${({ styleConfig }) => `
      background-color: ${styleConfig.backgroundColor};
      color: ${styleConfig.color};
      font-family: ${styleConfig.font};
      font-size: ${styleConfig.fontSize}px;
      font-weight: bold;
      padding: 20px;
      border-radius: 20px;
    `}
  }
`;

export const PollHeaderImage = styled.img`
  width: auto !important;
  max-height: 20vh;
`;

export const PollNav = styled(Row)`
  display: flex;
  justify-content: space-between;

  div {
    flex: 1;
    display: flex;
    align-items: center;
    color: ${({ theme }) => theme.palette.darkGrey};

    h1 {
      font-family: EavesBold;
      font-size: 2.75rem;
      margin: 0;
      color: ${({ theme }) => theme.palette.platinum};
      text-transform: uppercase;

      .personalisation {
        font-size: 1.5rem;
      }
    }

    span.status {
      font-family: EavesBold;
      margin-left: 0.75rem;
      color: ${({ theme }) => theme.palette.primaryPlum};
      text-transform: uppercase;
    }
  }

  .nav {
    .nav-item {
      position: relative;
      flex-grow: 2;
      text-align: center;
      width: 200px;

      * {
        border-right: 1px solid ${({ theme }) => theme.palette.lighterGrey};
      }

      &:last-child {
        border-right: none;
      }

      a {
        width: 100%;
        text-transform: uppercase;
        color: ${({ theme }) => theme.palette.textLightGrey};

        &:hover {
          color: ${({ theme }) => theme.palette.primaryMustard};
        }

        &::after {
          content: '';
          position: absolute;
          bottom: 0;
          left: 0;
          width: 100%;
          height: 1px;
          background: ${({ theme }) => theme.palette.lighterGrey};
        }

        &.active {
          color: ${({ theme }) => theme.palette.primaryPlum};

          &::after {
            height: 4px;
            background: ${({ theme }) => theme.palette.primaryPlum};
          }
        }
      }
    }
  }

  .tab-pane {
    text-align: center;

    .spinner-border {
      position: relative;
      margin-bottom: 2rem;
      color: ${({ theme }) => theme.palette.darkGrey};
    }
  }
`;
