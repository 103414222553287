import styled from 'styled-components';
import { rgba } from 'polished';

export const StyledCheckbox = styled.input.attrs({ type: 'checkbox' })`
  &:not(:checked),
  &:checked {
    position: absolute;
    left: 0;
    opacity: 0.01;
  }
  &:not(:checked) + label,
  &:checked + label {
    position: relative;
    padding-left: 1.5em;
    font-family: EavesRegular;
    text-transform: uppercase;
    color: ${({ theme }) => theme.palette.textGrey};
    font-size: 1rem;
    cursor: pointer;
    margin: 0.25rem 0;
    line-height: 1.4rem;
  }

  /* Unchecked state */
  &:not(:checked) + label:before,
  &:checked + label:before {
    content: '';
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 1em;
    height: 1em;
    border: 1px solid ${({ theme }) => theme.palette.textGrey};
    background: white;
    border-radius: 0.5em;
    box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.1), 0 0 0 ${({ theme }) => rgba(theme.palette.primaryMustard, 0.1)};
  }

  /* Checked state */
  &:not(:checked) + label:after,
  &:checked + label:after {
    content: '';
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 1em;
    height: 1em;
    border-radius: 0.5em;
    border: 1px solid ${({ theme }) => theme.palette.textGrey};
    background: ${({ theme }) => theme.palette.primaryMustard};
  }
  &:not(:checked) + label:after {
    opacity: 0;
  }
  &:checked + label:after {
    opacity: 1;
  }

  /* Disabled checkbox */
  &:disabled:not(:checked) + label:before,
  &:disabled:checked + label:before {
    box-shadow: none;
    border-color: ${({ theme }) => theme.palette.lightGrey};
    background-color: ${({ theme }) => theme.palette.lighterGrey};
  }
  &:disabled:checked + label:after {
    color: ${({ theme }) => theme.palette.lightGrey};
  }
  &:disabled + label {
    color: ${({ theme }) => theme.palette.lightGrey};
    cursor: default;
  }

  /* Accessibility */
  &:checked:focus + label:before,
  &:not(:checked):focus + label:before {
    box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.1), 0 0 0 6px ${({ theme }) => rgba(theme.palette.primaryMustard, 0.1)};
  }
`;
