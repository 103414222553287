import React from 'react';
import { darken } from 'polished';
import { useTheme } from 'styled-components';
import { CONTENT_ITEM_PRESENTATION_TYPE } from '@pushologies/common/constants/content-item';
import { ContentButtonOptions } from '@pushologies/database-service/db/entities/content';
import { ApiButtonResults, ApiNotification } from '~api/notifications/types';
import { PersonalisationString } from '~components/personalisation-string';
import { AndroidIconStyled, AppleIconStyled } from '~components/styled/icons';
import { BarChart, Bar } from '~components/bar-chart';
import { millisecondsToTime } from '~helpers/formats';
import { ButtonStatsDiv, ButtonImgDiv, ButtonTextDiv } from './styles';
import { ApiContent } from '~api/content-items/types';

interface Props {
  iosStats: ApiButtonResults;
  androidStats: ApiButtonResults;
  notification: ApiNotification;
}

const ButtonImage: React.FC<{ options: ContentButtonOptions; content: ApiContent }> = ({ content, options }) => {
  let imgUrl = options?.buttonImageUrl;
  if (!imgUrl && content?.presentation === CONTENT_ITEM_PRESENTATION_TYPE.CAROUSEL) {
    imgUrl = content?.contentItem?.downloadUrl;
  }

  return imgUrl ? (
    <ButtonImgDiv $url={imgUrl} />
  ) : (
    <ButtonTextDiv
      $textColor={options?.textColor}
      $backgroundColor={options?.backgroundColor}
      $borderColor={options?.borderColor}
    >
      {options?.displayName || options?.text || `Target URL`}
    </ButtonTextDiv>
  );
};

export const ButtonStats: React.FC<Props> = ({ iosStats, androidStats, notification }) => {
  const theme = useTheme();
  const buttonId = iosStats?.buttonId ?? androidStats?.buttonId;
  const buttonUrl = iosStats?.url ?? androidStats?.url;
  let options: ContentButtonOptions;
  const buttonContent = notification?.contents?.find((content) => {
    const buttonOptions = content?.options?.buttons?.find(({ buttonId: id, url }) =>
      buttonId ? buttonId === id : url === buttonUrl
    );
    if (buttonOptions) {
      options = buttonOptions;
      return true;
    } else return false;
  });
  const totals =
    iosStats?.totalClicks + iosStats?.uniqueClicks + androidStats?.totalClicks + androidStats?.uniqueClicks || 1;
  const chartData: Bar[][] = [
    [
      {
        label: androidStats.uniqueClicks,
        subLabel: 'Unique',
        labelAlign: 'right',
        color: darken(0.2, theme.palette.android),
        percentage: (androidStats.uniqueClicks / totals) * 100
      },
      {
        label: androidStats.totalClicks,
        subLabel: 'Total',
        labelAlign: 'right',
        color: theme.palette.android,
        percentage: (androidStats.totalClicks / totals) * 100
      }
    ],
    [
      {
        label: iosStats.uniqueClicks,
        subLabel: 'Unique',
        labelAlign: 'right',
        color: darken(0.2, theme.palette.apple),
        percentage: (iosStats.uniqueClicks / totals) * 100
      },
      {
        label: iosStats.totalClicks,
        subLabel: 'Total',
        labelAlign: 'right',
        color: theme.palette.apple,
        percentage: (iosStats.totalClicks / totals) * 100
      }
    ]
  ];
  const appear = millisecondsToTime(options?.buttonAppear);
  const disappear = millisecondsToTime(options?.buttonDisappear);

  return (
    <ButtonStatsDiv>
      <ButtonImage options={options} content={buttonContent} />
      <div className="info">
        <PersonalisationString as="h5" personalisation={notification?.personalisation} text={buttonUrl} />
        {options?.buttonAppear && (
          <div>
            <p>
              Appears <span>{appear.text}</span>
            </p>
            <p>
              {!!disappear.seconds && (
                <>
                  Disappears <span>{disappear.text}</span>
                </>
              )}
            </p>
          </div>
        )}
        {options?.position && (
          <div>
            <p>
              Top <span>{options?.position.top}</span>
            </p>
            <p>
              Left <span>{options?.position.left}</span>
            </p>
            <p>
              Bottom <span>{options?.position.bottom}</span>
            </p>
            <p>
              Right <span>{options?.position.right}</span>
            </p>
          </div>
        )}
      </div>
      <div className="stats">
        <AndroidIconStyled />
        <AppleIconStyled />
        <BarChart bars={chartData} label="" orientation="vertical" />
      </div>
    </ButtonStatsDiv>
  );
};
