import {
  Entity,
  Column,
  CreateDateColumn,
  ManyToOne,
  BeforeInsert,
  PrimaryGeneratedColumn,
  JoinColumn,
  DeleteDateColumn,
  Index,
  OneToOne
} from 'typeorm';
import uuidApiKey from 'uuid-apikey';
import { Tenant } from './tenant';
import { TenantUser } from './tenant-user';

export enum API_KEY_TYPES {
  SDK = 'SDK',
  ADMIN_API = 'ADMIN_API'
}

@Entity('api_key')
@Index(['apiKey', 'type', 'deletedAt'])
@Index(['apiKey', 'type', 'deletedAt', 'id'])
@Index(['tenantId', 'deletedAt', 'id'])
@Index(['id', 'tenant'])
export class ApiKey {
  @PrimaryGeneratedColumn('uuid')
  id: string;

  @Column()
  tenantId: string;
  @ManyToOne((_) => Tenant, (tenant) => tenant.id, { onDelete: 'CASCADE' })
  @Index()
  tenant: Tenant;

  @Column({ name: 'type', type: 'enum', enum: API_KEY_TYPES })
  type: string;

  @Column({ name: 'description', type: 'varchar' })
  description: string;

  @Column({ name: 'api_key', unique: true })
  apiKey: string;

  @Column({ unique: true, select: false })
  secret: string;

  @Column({ nullable: true })
  userId: string;
  @OneToOne((_) => TenantUser, (user) => user.apiKey, { nullable: true, onDelete: 'CASCADE' })
  @JoinColumn()
  user: TenantUser;

  @Column({ nullable: true })
  createdById: string;
  @ManyToOne((_) => TenantUser, { onDelete: 'SET NULL' })
  @JoinColumn()
  @Index()
  createdBy: TenantUser;

  @CreateDateColumn({ name: 'created_at', type: 'timestamptz' })
  createdAt: Date;

  @DeleteDateColumn({ name: 'deleted_at' })
  deletedAt: Date;

  @Column({ name: 'expires_at', nullable: true, type: 'timestamptz' })
  expiresAt?: Date;

  @BeforeInsert()
  createKey() {
    if (this.apiKey) return;
    const keys = uuidApiKey.create();
    this.apiKey = keys.apiKey;
  }
}
