import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { Form, Container, Row, Col, Spinner } from 'react-bootstrap';
import { StyledFormHeader, StyledFormButton } from '~components/styled/form';
import { useStoreActions, useStoreState } from '~store/hooks';
import { formInputsToNameValueObject } from '~helpers/forms';
import Logo from '~images/logo-strapline.svg';
import { ErrorMessageP, StyledForm, LogoWrapperDiv, StyledRow } from '~pages/login/styles';

const Register: React.FC = () => {
  const history = useHistory();
  const formRef = React.useRef<HTMLFormElement>();
  const [isValid, setIsValid] = React.useState(false);
  const [{ register, setUserModel }, { setSideNavModel }] = useStoreActions(({ user, sideNav }) => [user, sideNav]);
  const { error, loading } = useStoreState(({ user }) => user);

  const onInputChange = useCallback((e: React.KeyboardEvent) => {
    const inputs = formInputsToNameValueObject(formRef.current, ['email', 'oldPassword', 'newPassword', 'confirmNew']);
    const valid =
      !!inputs.confirmNew.length && !!inputs.email.length && !!inputs.newPassword.length && !!inputs.oldPassword.length;
    setIsValid(valid);

    if (valid && !loading && e.key === 'Enter') onSubmit(e);
  }, []);

  const onSubmit = useCallback(async (e: React.MouseEvent | React.KeyboardEvent) => {
    e.preventDefault();
    e.stopPropagation();
    const { email, oldPassword, newPassword, confirmNew } = formInputsToNameValueObject(formRef.current, [
      'email',
      'oldPassword',
      'newPassword',
      'confirmNew'
    ]);

    const onSuccess = () => history.replace('/');

    await register({ email, oldPassword, newPassword, confirmNew, onSuccess });
  }, []);

  React.useEffect(() => {
    setSideNavModel({ topBarLeftTitle: 'register', activeChild: 'register' });
    return () => setUserModel({ loading: false, error: null });
  }, []);

  return (
    <Container id="page-container">
      <StyledRow className="justify-content-md-center">
        <Col md="auto">
          <LogoWrapperDiv>
            <Logo />
          </LogoWrapperDiv>
          <StyledForm ref={formRef}>
            <StyledFormHeader>new customer set up</StyledFormHeader>
            <Form.Group controlId="email">
              <Form.Label>Email</Form.Label>
              <Form.Control type="email" name="email" onKeyUp={onInputChange} />
            </Form.Group>
            <Form.Group controlId="old-password">
              <Form.Label>Temporary Password</Form.Label>
              <Form.Control type="password" name="oldPassword" onKeyUp={onInputChange} />
            </Form.Group>
            <Form.Group controlId="new-password">
              <Form.Label>New Password</Form.Label>
              <Form.Control type="password" name="newPassword" onKeyUp={onInputChange} />
            </Form.Group>
            <Form.Group controlId="confirm-new-password">
              <Form.Label>Confirm New Password</Form.Label>
              <Form.Control type="password" name="confirmNew" onKeyUp={onInputChange} />
            </Form.Group>
            <Form.Group>
              <StyledFormButton
                variant="primary"
                type="submit"
                className="register-submit"
                onClick={onSubmit}
                disabled={!isValid || loading}
              >
                {loading ? <Spinner animation="border" size="sm" /> : 'Register'}
              </StyledFormButton>
            </Form.Group>
            <Row>{error && <ErrorMessageP>{error}</ErrorMessageP>}</Row>
          </StyledForm>
        </Col>
      </StyledRow>
    </Container>
  );
};

export default Register;
