import React from 'react';
import { Spinner } from 'react-bootstrap';
import { ApiTags } from '~api/tags/types';
import { PromptButton } from '~components/prompt-button';
import { ActionsTd } from '~components/styled/table-section';
import { dateTimeFormat } from '~helpers/formats';
import { useStoreActions } from '~store/hooks';
import DeleteIcon from '~images/delete.svg';
import { PersonalisationString } from '~components/personalisation-string';
import { DeleteSpan, StyledTr } from './styles';
import { CopyWidget } from '~components/copy-widget';
import { useFlags } from 'launchdarkly-react-client-sdk';

interface Props {
  tag: ApiTags;
  onDeleteSuccess(): void;
}

const ActionsColumn: React.FC<Props> = ({ tag, onDeleteSuccess }) => {
  const { deleteTags } = useStoreActions((state) => state.tags);
  const [isDeleting, setIsDeleting] = React.useState(false);
  const { hideDeleteTagAction } = useFlags();

  const handleDeleteClick = () => {
    setIsDeleting(true);

    deleteTags({
      ids: [tag.id],
      names: [tag.name],
      onSuccess() {
        onDeleteSuccess();
        setIsDeleting(false);
      },
      onError() {
        setIsDeleting(false);
      }
    });
  };

  return (
    <ActionsTd>
      {!hideDeleteTagAction && (
        <DeleteSpan isDeleting={isDeleting} data-testid="deleteButton">
          <PromptButton
            buttonType="icon"
            buttonIcon={<DeleteIcon />}
            onConfirm={handleDeleteClick}
            confirmButtonText="delete"
            promptTitle="Confirm Delete"
          >
            Are you sure you would like to delete tag "{tag.name}"?
          </PromptButton>
          {isDeleting && <Spinner animation="border" size="sm" />}
        </DeleteSpan>
      )}
    </ActionsTd>
  );
};

export const TagRow: React.FC<Props> = ({ tag, onDeleteSuccess }) => {
  return (
    <StyledTr data-testid="tagRow">
      <td>
        <CopyWidget copyText={tag.id} copyMessage="copied tag id">
          {tag.id}
        </CopyWidget>
      </td>
      <PersonalisationString as="td" text={tag.name} personalisation={null} />
      <td>{dateTimeFormat(tag.createdAt)}</td>
      <ActionsColumn tag={tag} onDeleteSuccess={onDeleteSuccess} />
    </StyledTr>
  );
};
