import styled from 'styled-components';
import { Row } from 'react-bootstrap';
import { StyledForm as Form } from '~components/styled/form';

export const StyledRow = styled(Row)`
  margin-top: 7rem;
`;

export const LogoWrapperDiv = styled.div`
  width: 45rem;
  display: flex;
  justify-content: center;

  svg {
    width: 60%;
    margin-bottom: 3rem;
  }
`;

export const StyledForm = styled(Form)`
  width: 45rem;

  .form-group {
    p {
      margin: 0;
      font-style: italic;
    }
  }
`;

export const ErrorMessageP = styled.p`
  color: red;
  width: 100%;
  text-align: center;
  margin: 16px 0 0;
`;
