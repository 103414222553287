import styled from 'styled-components';
import { breakpoints } from '~styles/mixins';

export const SubTenantDiv = styled.div`
  border: solid 2px ${({ theme }) => theme.palette.secondaryPurple};
  border-radius: 10px;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  margin-bottom: 2rem;

  h3 {
    font-size: 2rem;
    font-weight: bold;
    font-family: EavesHeavy;
    margin: 0;
  }

  h4 {
    font-size: 1.5rem;
    margin: 0;
  }

  h5 {
    font-size: 0.9rem;
    font-family: EavesBold;
    text-transform: uppercase;
    font-weight: 800;
    margin: 0;
    opacity: 60%;
  }

  span {
    background-color: ${({ theme }) => theme.palette.secondaryPurple};
    height: 40px;
    width: 40px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: white;
    font-family: EavesBold;
  }

  .bio-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 5%;
    margin-bottom: 1rem;
    background-color: rgba(100, 100, 100, 0.1);
    padding: 0.5rem 1rem;
    border-radius: 1rem;

    @media ${breakpoints.lg} {
      padding: 0.5rem;
    }
  }

  .stat-row {
    display: flex;
    flex-direction: row;
    margin-bottom: 1rem;
    max-width: 100%;
    overflow: hidden;

    @media ${breakpoints.md} {
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }

    div {
      border-right: solid 1px rgba(100, 100, 100, 0.4);
      margin-right: 1.5rem;
      padding-right: 1.5rem;
      display: block;

      @media ${breakpoints.md} {
        border: none;
        text-align: center;
        margin: 0 auto;
        padding-bottom: 1rem;
        border-bottom: solid 1px rgba(100, 100, 100, 0.4);
      }

      &:last-child {
        border: none;
      }
    }
  }
`;

export const SubTenantActions = styled.div`
  display: flex;

  @media ${breakpoints.md} {
    flex-direction: column;
  }

  & > * {
    flex: 1;

    &:first-of-type {
      margin-right: 10px;

      @media ${breakpoints.md} {
        margin-right: 0;
        margin-bottom: 10px;
      }
    }
  }
`;
