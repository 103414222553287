import { Column, Entity, PrimaryGeneratedColumn, ManyToOne, JoinColumn } from 'typeorm';
import { LocaleText } from './locale-text';
import { Tenant } from './tenant';

@Entity('locale_text_translation')
export class LocaleTextTranslation {
  @PrimaryGeneratedColumn('uuid')
  id: string;

  @Column()
  locale: string;

  @Column()
  value: string;

  @ManyToOne((_) => LocaleText, (localeText) => localeText.translations)
  parent: LocaleText;

  @Column()
  tenantId: string;
  @ManyToOne((_) => Tenant, (tenant) => tenant.id, { onDelete: 'CASCADE' })
  @JoinColumn()
  tenant: Tenant;
}
