import React from 'react';
import { Spinner } from 'react-bootstrap';
import { LoaderDiv } from './styles';

interface Props {
  loading: boolean;
  /** Apply white opaque background to parent element */
  backdrop?: boolean;
  testId?: string;
  className?: string;
}

export const Loader: React.FC<Props> = ({ loading, testId, className, backdrop }) => {
  return loading ? (
    <LoaderDiv className={className} data-testid={testId} $backdrop={backdrop}>
      <Spinner animation="border" />
    </LoaderDiv>
  ) : null;
};
