import { action, thunk } from 'easy-peasy';
import { handleUnAuthenticated } from '~store/helpers';
import { SubscriberModel } from './types';

export * from './types';

export const subscriberModel: SubscriberModel = {
  error: null,
  totalItems: 100,
  subscribers: [],

  setModel: action((state, payload) => {
    Object.assign(state, payload);
  }),

  fetchSubscribers: thunk(async (actions, payload, { injections, getStoreActions }) => {
    const { onSuccess, onError, saveState, ...params } = payload || {};

    try {
      const { subscribers, totalItems } = await injections.subscriberApi.fetch(params);
      actions.setModel(
        Object.assign(
          {
            status: 'idle' as 'idle',
            error: null,
            totalItems
          },
          saveState ? { subscribers } : {}
        )
      );

      if (onSuccess) onSuccess(subscribers, totalItems);
    } catch (error) {
      await handleUnAuthenticated(error, getStoreActions());
      actions.setModel({ error: error.message });
      if (onError) onError(error.message);
    }
  }),

  fetchSubscriber: thunk(async (actions, payload, { injections, getStoreActions }) => {
    const { onSuccess, subscriberId } = payload;

    try {
      const { subscriber } = await injections.subscriberApi.fetchOne(subscriberId);
      onSuccess(subscriber);
    } catch (error) {
      await handleUnAuthenticated(error, getStoreActions());
      actions.setModel({ error: error.message });
    }
  }),

  fetchSubscriberEvents: thunk(async (actions, payload, { injections, getStoreActions }) => {
    const { onSuccess, onError, ...params } = payload;

    try {
      const { subscriberEvents, totalItems } = await injections.subscriberApi.fetchEvents(params);
      actions.setModel({ error: null });
      onSuccess(subscriberEvents, totalItems);
    } catch (error) {
      await handleUnAuthenticated(error, getStoreActions());
      actions.setModel({ error: error.message });

      onError && onError(error.message);
    }
  }),

  fetchSubscriberNotificationEvents: thunk(async (actions, payload, { injections, getStoreActions }) => {
    const { onSuccess, onError, ...params } = payload;

    try {
      const { notificationEvents, totalItems } = await injections.subscriberApi.fetchNotificationEvents(params);
      actions.setModel({ error: null });
      onSuccess && onSuccess(notificationEvents, totalItems);
    } catch (error) {
      await handleUnAuthenticated(error, getStoreActions());
      actions.setModel({ error: error.message });

      onError && onError(error.message);
    }
  }),

  deleteSubscriberTag: thunk(async (actions, payload, { injections, getStoreActions }) => {
    const { onSuccess, onError, subscriberId, tagId } = payload;

    try {
      await injections.subscriberApi.deleteTag({ subscriberId, tagId });
      actions.setModel({ error: null });

      onSuccess && onSuccess();
    } catch (error) {
      await handleUnAuthenticated(error, getStoreActions());
      actions.setModel({ error: error.message });

      getStoreActions().notificationWidget.addNotification({
        type: 'error',
        message: error.message
      });

      onError && onError(error.message);
    }
  })
};
