import React from 'react';
import { ReactourProps, ReactourStep } from 'reactour';
import { useTheme } from 'styled-components';
import Cookies from 'js-cookie';
import { StyledTour } from './styles';
import dayjs from '~helpers/dayjs';

interface Props extends Partial<ReactourProps> {
  id: string;
  steps: ReactourStep[];
}

export const Tour: React.FC<Props> = ({ id, onRequestClose, ...props }) => {
  const theme = useTheme();
  const [open, setOpen] = React.useState(!Cookies.get(id));
  const handleClose = (event: React.MouseEvent<HTMLDivElement>) => {
    onRequestClose && onRequestClose(event);
    setOpen(false);
  };

  if (open) Cookies.set(id, 'true', { expires: dayjs().add(1, 'year').toDate() });

  return (
    <StyledTour
      steps={props.steps}
      accentColor={theme.palette.primaryMustard}
      showNavigationNumber={false}
      isOpen={open}
      onRequestClose={handleClose}
      disableFocusLock={true}
      disableInteraction={false}
      {...props}
    />
  );
};
