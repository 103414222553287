import { Fonts } from '@pushologies/database-service/db/entities/content';
import { TextCustomisation } from '@pushologies/database-service/db/entities/poll';
import React, { FC, useState } from 'react';
import { ApiContentItem } from '~api/content-items/types';
import { PollBasicDto } from '.';
import {
  PollPreviewBlock,
  PollPreviewResetButton,
  PollPreviewWrapper,
  PollVoteSuccessMessage,
  PollHeaderImage
} from './styles';

const pollPreviewBlockStyleFactory = (config: TextCustomisation, marginTop = 0) => ({
  backgroundColor: config?.backgroundColor || '#ffffff',
  color: config?.color || '#000000',
  font: config?.font || Fonts.Arial,
  fontSize: config?.fontSize || 16,
  marginTop
});

interface Props {
  poll: PollBasicDto;
  headerImage?: ApiContentItem;
  backgroundImage?: ApiContentItem;
}

export const PollPreview: FC<Props> = ({
  poll: {
    question,
    answers,
    marginTop,
    backgroundColor,
    questionTextCustomisation,
    choicesTextCustomisation,
    voteSuccessMessage = 'Thanks for your vote!'
  },
  headerImage,
  backgroundImage
}) => {
  const [selectedAnswerIndex, setSelectedAnswerIndex] = useState<number | undefined>();
  const [showSuccessMessage, setShowSuccessMessage] = useState<boolean>();

  return (
    <PollPreviewWrapper
      data-testid={'pollPreviewWrapper'}
      styleConfig={{
        backgroundColor: backgroundColor || '#a71580',
        backgroundImageUrl: backgroundImage?.externalSourceUrl || backgroundImage?.downloadUrl
      }}
    >
      <PollPreviewResetButton
        onClick={() => {
          setSelectedAnswerIndex(undefined);
          setShowSuccessMessage(false);
        }}
      >
        X
      </PollPreviewResetButton>

      {showSuccessMessage && (
        <PollVoteSuccessMessage
          styleConfig={pollPreviewBlockStyleFactory({
            ...choicesTextCustomisation,
            backgroundColor: choicesTextCustomisation?.selectedBackgroundColor || '#f9af04',
            color: choicesTextCustomisation?.selectedColor || '#000000'
          })}
        >
          <span>{voteSuccessMessage}</span>
        </PollVoteSuccessMessage>
      )}

      {headerImage && (
        <PollPreviewBlock data-testid={'pollPreviewHeaderBlock'} styleConfig={{ marginTop }}>
          <PollHeaderImage
            data-testid={'pollPreviewHeaderImage'}
            src={headerImage?.externalSourceUrl || headerImage?.downloadUrl}
          />
        </PollPreviewBlock>
      )}

      <PollPreviewBlock
        data-testid={'pollPreviewQuestion'}
        styleConfig={pollPreviewBlockStyleFactory(questionTextCustomisation, marginTop)}
      >
        {question}
      </PollPreviewBlock>
      {answers.map((answer, i) => (
        <PollPreviewBlock
          key={i}
          data-testid={`pollPreviewAnswer${i + 1}`}
          onClick={() => {
            setSelectedAnswerIndex(i);
            setShowSuccessMessage(true);
          }}
          className={'poll-choice'}
          styleConfig={{
            ...pollPreviewBlockStyleFactory(choicesTextCustomisation),
            ...(i === selectedAnswerIndex && {
              backgroundColor: choicesTextCustomisation?.selectedBackgroundColor || '#f9af04',
              color: choicesTextCustomisation?.selectedColor || '#000000'
            })
          }}
        >
          {answer.text}
        </PollPreviewBlock>
      ))}
    </PollPreviewWrapper>
  );
};
