import {
  DeviceRule,
  DurationRule,
  FirstSessionRule,
  SessionCountsRule,
  LastSessionRule,
  DeviceLanguageRule,
  DeviceModelsRule,
  LocationRule,
  DeviceIdsRule,
  SubscriberIdsRule,
  AppVersionRule,
  TagsRule,
  CustomerIdsRule,
  LocationPermissionRule,
  NotificationPermissionRule,
  CountryCodeRule,
  NotificationPushStatusRule
} from '../rules';
import { OperatorValue as Op, Operator } from '../types';
import { operators } from './operators';

export type RuleId =
  | 'duration'
  | 'device'
  | 'firstSession'
  | 'lastSession'
  | 'sessionCounts'
  | 'deviceLanguage'
  | 'deviceModels'
  | 'location'
  | 'deviceIds'
  | 'subscriberIds'
  | 'appVersion'
  | 'tags'
  | 'customerIds'
  | 'locationPermission'
  | 'notificationPermission'
  | 'countryCode'
  | 'notificationPushStatus';

interface Rule {
  id: RuleId;
  name: string;
  description: string;
  allowedOperators: Operator[];
  disabled: boolean;
  class: any;
}

type RulesMap = Record<RuleId, Rule>;

export const rules: RulesMap = {
  duration: {
    id: 'duration',
    name: 'Duration',
    description: 'Total number of seconds the users device has had your app open',
    allowedOperators: [
      operators[Op.greaterThan],
      operators[Op.greaterThanOrEqual],
      operators[Op.lessThan],
      operators[Op.lessThanOrEqual]
    ],
    disabled: false,
    class: DurationRule
  },
  device: {
    id: 'device',
    name: 'Device',
    description: 'Users operating device',
    allowedOperators: [operators[Op.is]],
    disabled: false,
    class: DeviceRule
  },
  firstSession: {
    id: 'firstSession',
    name: 'First Session',
    description: 'First communication with users device',
    allowedOperators: [operators[Op.between], operators[Op.before], operators[Op.after]],
    disabled: false,
    class: FirstSessionRule
  },
  lastSession: {
    id: 'lastSession',
    name: 'Last Session',
    description: 'Most recent communication with users device',
    allowedOperators: [operators[Op.between], operators[Op.before], operators[Op.after]],
    disabled: false,
    class: LastSessionRule
  },
  sessionCounts: {
    id: 'sessionCounts',
    name: 'Session Counts',
    description: 'Number of times user has opened your app',
    allowedOperators: [operators[Op.greaterThanOrEqual], operators[Op.lessThanOrEqual], operators[Op.is]],
    disabled: false,
    class: SessionCountsRule
  },
  deviceLanguage: {
    id: 'deviceLanguage',
    name: 'Device Language',
    description: 'Users Device Language',
    allowedOperators: [operators[Op.is], operators[Op.isNot]],
    disabled: false,
    class: DeviceLanguageRule
  },
  deviceModels: {
    id: 'deviceModels',
    name: 'Device Models',
    description: 'Match specific users Device Models',
    allowedOperators: [operators[Op.in], operators[Op.notIn]],
    disabled: true,
    class: DeviceModelsRule
  },
  location: {
    id: 'location',
    name: 'Location',
    description: 'Most recent users device location',
    allowedOperators: [operators[Op.inside], operators[Op.outside]],
    disabled: false,
    class: LocationRule
  },
  deviceIds: {
    id: 'deviceIds',
    name: 'Device Ids',
    description: 'Match specific users Device Ids',
    allowedOperators: [operators[Op.in], operators[Op.notIn]],
    disabled: false,
    class: DeviceIdsRule
  },
  subscriberIds: {
    id: 'subscriberIds',
    name: 'Subscriber Ids',
    description: 'Match specific users Ids',
    allowedOperators: [operators[Op.in], operators[Op.notIn]],
    disabled: false,
    class: SubscriberIdsRule
  },
  appVersion: {
    id: 'appVersion',
    name: 'App Version',
    description: 'Match specific App Versions users have installed',
    allowedOperators: [operators[Op.in], operators[Op.notIn]],
    disabled: false,
    class: AppVersionRule
  },
  tags: {
    id: 'tags',
    name: 'Tags',
    description: 'Match specific Tags that users are associated with',
    allowedOperators: [operators[Op.in], operators[Op.notIn]],
    disabled: false,
    class: TagsRule
  },
  customerIds: {
    id: 'customerIds',
    name: 'Customer Identifiers',
    description: 'Match specific customer identifiers for your users',
    allowedOperators: [operators[Op.in], operators[Op.notIn]],
    disabled: false,
    class: CustomerIdsRule
  },
  locationPermission: {
    id: 'locationPermission',
    name: 'Location permissions',
    description: 'Match users that have opted in to allow precise location data to be accessed',
    allowedOperators: [operators[Op.is]],
    disabled: false,
    class: LocationPermissionRule
  },
  notificationPermission: {
    id: 'notificationPermission',
    name: 'Notification permissions',
    description: 'Match users that have opted in to allow notifications to be sent',
    allowedOperators: [operators[Op.is]],
    disabled: false,
    class: NotificationPermissionRule
  },
  countryCode: {
    id: 'countryCode',
    name: 'Country code',
    description: 'Match specific country code(s) for your users',
    allowedOperators: [operators[Op.in], operators[Op.notIn]],
    disabled: false,
    class: CountryCodeRule
  },
  notificationPushStatus: {
    id: 'notificationPushStatus',
    name: 'Notification push status',
    description: "Match specific pushed notification, and it's reached delivery status(es) for your users",
    allowedOperators: [operators[Op.in], operators[Op.notIn]],
    disabled: false,
    class: NotificationPushStatusRule
  }
};
