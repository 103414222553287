import React from 'react';
import { millisecondsToTime } from '~helpers/formats';
import { Select, SelectOption } from '~components/select';

const intervalOptions: SelectOption[] = [
  {
    name: '15s',
    value: 15
  },
  {
    name: '30s',
    value: 30
  },
  {
    name: '1m',
    value: 60
  },
  {
    name: '5m',
    value: 60 * 5
  },
  {
    name: '15m',
    value: 60 * 15
  },
  {
    name: '30m',
    value: 60 * 30
  },
  {
    name: '1h',
    value: 60 * 60
  }
];

interface Props {
  onRefresh(): void;
}

export const RefreshIntervalDropdown: React.FC<Props> = (props) => {
  const [refreshInterval, setRefreshInterval] = React.useState(30);
  const [refreshCount, setRefreshCount] = React.useState(30);
  const [intervalID, setIntervalID] = React.useState(null);
  const { text } = millisecondsToTime(refreshCount * 1000);

  const updateCount = () => {
    setRefreshCount(refreshCount - 1);
  };

  const refreshData = () => {
    props.onRefresh();
    setRefreshCount(refreshInterval);
  };

  const handleIntervalChange = (option: SelectOption[]) => {
    clearTimeout(intervalID);
    setRefreshInterval(option[0]?.value);
    setRefreshCount(option[0]?.value);
  };

  React.useEffect(() => {
    refreshCount <= 0 ? refreshData() : setIntervalID(setTimeout(updateCount, 1000));
  }, [refreshCount]);

  React.useEffect(() => {
    return function cleanup() {
      clearTimeout(intervalID);
    };
  }, []);

  return (
    <span data-testid="refreshIntervalContainer">
      Refreshing Data In:
      <b> {text} </b>
      <Select
        id="refreshInterval"
        value={intervalOptions.filter((opt) => opt.value === refreshInterval)}
        options={intervalOptions || []}
        inValid={false}
        onChange={handleIntervalChange}
        placeholder="Refresh Interval"
        label={'Refresh Interval: '}
      />
    </span>
  );
};
