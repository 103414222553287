import styled from 'styled-components';
import { InputSection } from '../styles';

interface FieldWrapperSectionProps {
  invalid?: boolean;
}

export const StyledInputSection = styled(InputSection)<FieldWrapperSectionProps>`
  position: relative;
  height: 2.4rem;

  input,
  input::-webkit-inner-spin-button,
  input::-webkit-outer-spin-button {
    -moz-appearance: textfield;
    appearance: textfield;
    -webkit-appearance: textfield;
    height: 100%;
    border-color: ${({ theme, invalid }) => (invalid ? 'red !important' : theme.palette.lightGrey)};
    border-radius: 0;
    text-transform: uppercase;
    font-family: EavesRegular;
  }

  input::placeholder,
  input::-webkit-input-placeholder {
    color: ${({ theme }) => theme.palette.lightGrey};
  }
  input:-ms-input-placeholder {
    color: ${({ theme }) => theme.palette.lightGrey};
  }

  label {
    span {
      color: ${({ theme }) => theme.palette.darkGrey};
      font-size: 0.85rem;
    }
  }

  span.up,
  span.down {
    position: absolute;
    right: 0.5rem;
    top: 50%;
    width: 0.7rem;
    height: 0.6rem;
    background: transparent;

    &.up {
      transform: translateY(-120%);
      border-bottom: 0.6rem solid
        ${({ theme, $disabled }) => ($disabled ? theme.palette.lightGrey : theme.palette.primaryMustard)};
      border-right: 0.35rem solid transparent;
      border-left: 0.35rem solid transparent;
    }

    &.down {
      transform: translateY(20%);
      border-top: 0.6rem solid
        ${({ theme, $disabled }) => ($disabled ? theme.palette.lightGrey : theme.palette.primaryMustard)};
      border-right: 0.35rem solid transparent;
      border-left: 0.35rem solid transparent;
    }

    &:hover {
      cursor: ${({ $disabled }) => ($disabled ? 'default' : 'pointer')};
    }
  }

  span.suffix {
    position: relative;
    display: none;
    right: 2rem;

    @media (min-width: 1740px) {
      display: block;
    }
  }
`;
