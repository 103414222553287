import { Subscriber as SubscriberEntity } from '@pushologies/database-service/db/entities/subscriber';
import { SubscriberEvent as SubscriberEventEntity } from '@pushologies/database-service/db/entities/subscriber-event/default';
import { SubscriberNotificationEvent as SubscriberNotificationEventEntity } from '@pushologies/database-service/db/entities/subscriber-event/notification';
import { FetchParams as BaseFetchParams } from '../tools/types';
import { ApiTags } from '../tags/types';

type NotificationEventStatuses =
  | 'downloadFailed'
  | 'downloaded'
  | 'linkOpened'
  | 'opened'
  | 'pending'
  | 'pushFailed'
  | 'received'
  | 'viewed'
  | 'messageCount';

export interface ApiSubscriber
  extends Omit<
    SubscriberEntity,
    'subscribers' | 'subscriber' | 'createdAt' | 'createdBy' | 'tenant' | 'contents' | 'tags'
  > {
  createdAt: string;
  tags: ApiTags[];
  notificationEventsSummary: Record<NotificationEventStatuses, number>;
}

export interface ApiSubscriberEvent extends Omit<SubscriberEventEntity, 'timestamp' | 'createdAt' | 'tenant'> {
  createdAt: string;
  timestamp: string;
  notificationId?: string;
}

export interface ApiSubscriberNotificationEvent
  extends Omit<SubscriberNotificationEventEntity, 'timestamp' | 'createdAt' | 'tenant' | 'notification'> {
  createdAt: string;
  timestamp: string;
  eventType?: string;
}

export interface FetchSubscribersParams extends BaseFetchParams {
  subscriberId?: string;
  deviceId?: string;
  customerId?: string;
}

export interface FetchSubscriberEventsParams extends BaseFetchParams {
  subscriberId: string;
}

export interface FetchSubscriberNotificationEventsParams extends BaseFetchParams {
  subscriberId: string;
  notificationId?: string;
}

export interface DeleteSubscriberTagParams {
  subscriberId: string;
  tagId: string;
}

export function isNotificationEvent(
  result: ApiSubscriberEvent | ApiSubscriberNotificationEvent
): result is ApiSubscriberNotificationEvent {
  return result.hasOwnProperty('status');
}

/** Must be same as Body.subscribers type in apps/admin-api/src/functions/subscribers-upload/index.ts */
export interface UploadSubscriber {
  pushKitId?: string;
  fcmToken?: string;
  lastSeen?: Date;
  createdAt?: Date;
  notificationsEnabled?: boolean;
  locationEnabled?: boolean;
}

export interface EventGroup {
  id: string;
  type: string;
  notificationId?: string;
  content?: (ApiSubscriberEvent | ApiSubscriberNotificationEvent)[];
}
