import {
  Entity,
  Column,
  PrimaryGeneratedColumn,
  CreateDateColumn,
  OneToMany,
  OneToOne,
  JoinColumn,
  DeleteDateColumn,
  Index,
  ManyToOne
} from 'typeorm';
import { TENANT_LICENSES } from '@pushologies/common/constants/tenant-licenses';
import { ApiKey } from './api-key';
import { Segment } from './segment';
import { Content } from './content';
import { Notification } from './notification';
import { Campaign } from './campaign';
import { Subscriber } from './subscriber';
import { TenantCredentials } from './tenant-credentials';
import { NotificationResults } from './notification-results';
import { PersonalisationVariable } from './personalisation-variable';

@Entity('tenant')
@Index(['id', 'deletedAt'])
export class Tenant {
  @PrimaryGeneratedColumn('uuid')
  id: string;

  @Column({ type: 'varchar', length: 100, unique: true })
  name: string;

  @Column({
    type: 'text',
    transformer: {
      to: (value: string) => value?.toLowerCase(),
      from: (value: string) => value
    }
  })
  email: string;

  @Column({ name: 'logo_url', type: 'varchar', length: 100, nullable: true })
  logoUrl: string;

  @Column({ name: 'events_expire_in', type: 'integer', default: 30 })
  // number of days subscriberEvents and subscriberNotificationEvents will be stored
  eventsExpireIn: number;

  @Column({ name: 'licenses', type: 'simple-array', nullable: true })
  licenses: TENANT_LICENSES[];

  @Column({ nullable: true })
  parentId: string;
  @ManyToOne((_) => Tenant, { onDelete: 'SET NULL' })
  @JoinColumn()
  parent: Tenant;

  @OneToOne((_) => TenantCredentials, (tenantCred) => tenantCred.tenant, { cascade: true })
  @JoinColumn()
  @Index()
  credentials: TenantCredentials;

  @OneToMany((_) => Subscriber, (subscriber) => subscriber.tenant, { cascade: true })
  subscribers: Subscriber[];

  @OneToMany((_) => ApiKey, (apiKey) => apiKey.tenant, { cascade: true, nullable: true })
  apiKeys: ApiKey[];

  @OneToMany((_) => Segment, (segment) => segment.tenant, { cascade: true })
  segments: Segment[];

  @OneToMany((_) => Content, (content) => content.tenant, { cascade: true })
  contents: Content[];

  @OneToMany((_) => Campaign, (campaign) => campaign.tenant, { cascade: true })
  campaigns: Campaign[];

  @OneToMany((_) => Notification, (notification) => notification.tenant, { cascade: true })
  notifications: Notification[];

  @OneToMany((_) => NotificationResults, (notificationResults) => notificationResults.tenant, { cascade: true })
  notificationResults: NotificationResults[];

  @OneToMany((_) => PersonalisationVariable, (personalisationVariable) => personalisationVariable.tenant, {
    cascade: true
  })
  personalisationSchema: PersonalisationVariable[];

  @CreateDateColumn({ name: 'created_at', type: 'timestamptz' })
  createdAt: Date;

  @DeleteDateColumn({ name: 'deleted_at' })
  deletedAt: Date;

  @Column({ name: 'cloudfront_distribution_id', type: 'varchar', length: 30, nullable: true })
  cloudfrontDistributionId: string;
}
