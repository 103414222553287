import React, { useMemo } from 'react';
import { throttle } from 'throttle-debounce';
import { CONTENT_ITEM_MEDIA_TYPE, CONTENT_ITEM_PRESENTATION_TYPE } from '@pushologies/common/constants/content-item';
import { useVideoBuilderContext } from '~contexts/video-builder';
import { TileFile } from '~components/file-viewers/tiles';
import { useStoreActions, useStoreState } from '~store/hooks';
import { useFileViewerOptions } from '~components/file-viewers/hooks';
import { constructThumbnailUrl } from '~api/tools/helpers';
import { ApiContentItem } from '~api/content-items/types';
import { FileUploadRef } from '~components/file-upload';
import DeleteIcon from '~images/delete.svg';
import PlusIcon from '~images/plus.svg';
import CloneIcon from '~images/clone.svg';
import { ListFile } from '~components/file-viewers/list';
import { trimText } from '~helpers/formats';
import { personalisationValueStringToPlainString } from '~components/personalisation-string';
import { ColumnDiv } from '../../styles';
import { ButtonViewerHeaderDiv, ActionIconSpan, UrlSpan, StyledFileViewer, StyledTilesViewerUpload } from './styles';

const VIDEO_LIMIT = 40;

export const AssetLists: React.FC = () => {
  const vbContext = useVideoBuilderContext();
  const fileUploadRef = React.useRef<FileUploadRef>();
  const { notification } = useStoreState((state) => state.createNotification);
  const { status } = useStoreState((state) => state.contentItems);
  const { fetchItems, fetchItem, uploadItems } = useStoreActions((state) => state.contentItems);
  const { setVideoContent } = useStoreActions((state) => state.createNotification);
  const [videoItems, setVideoItems] = React.useState<ApiContentItem[]>([]);
  const { options, setOptions } = useFileViewerOptions();
  const videoFiles: TileFile[] = useMemo(
    () =>
      videoItems.map((item) => ({
        id: item.id,
        name: item.name,
        size: item.size,
        thumbnailUrl: constructThumbnailUrl(item)
      })),
    [videoItems]
  );
  const buttons = useMemo(() => Object.values(notification.buttons), [notification.buttons]);
  const buttonDisplayKeyFormats = {
    id: (id: string) => {
      const handleDelete = (e: React.MouseEvent) => {
        e.stopPropagation();
        e.preventDefault();
        vbContext.deleteButton(id);
      };
      const handleClone = (e: React.MouseEvent) => {
        e.stopPropagation();
        e.preventDefault();
        vbContext.cloneButton(buttons.find((btn) => btn.id === id));
      };
      return (
        <>
          <ActionIconSpan onClick={handleDelete} data-testid="deleteButton" role="button">
            <DeleteIcon />
          </ActionIconSpan>
          <ActionIconSpan onClick={handleClone} data-testid="cloneButton" role="button">
            <CloneIcon />
          </ActionIconSpan>
        </>
      );
    },
    'options.url': (url: string) => (
      <UrlSpan>
        {trimText(personalisationValueStringToPlainString(url, notification.personalisation), window.innerWidth / 50)}
      </UrlSpan>
    )
  };

  const handleVideoUpload = (files: File[]) => {
    fileUploadRef.current.setLoading(true);
    uploadItems({
      files,
      mediaType: CONTENT_ITEM_MEDIA_TYPE.VIDEO,
      presentation: [CONTENT_ITEM_PRESENTATION_TYPE.DEFAULT],
      onProgress: fileUploadRef.current.setLoadingPercentage,
      onSuccess([item]) {
        vbContext.setInitialising(true);
        setVideoContent({ contentItem: { id: item.id } });
        fileUploadRef.current.reset();
        setVideoItems([item, ...videoItems]);
      },
      onError() {
        fileUploadRef.current.setLoading(false);
      }
    });
  };

  const handleVideoSelect = ([id]: string[]) => {
    vbContext.setInitialising(true);
    setVideoContent({ contentItem: { id } });

    if (!id) {
      vbContext.clearCanvas();
    }
  };

  const handleButtonSelect = ([file]: ListFile[]) => {
    if (file) {
      vbContext.setActiveButton(file.id);
    } else {
      vbContext.discardActiveButton();
    }
  };

  const handlePageChange = (offset: number) => {
    fetchItems({
      limit: VIDEO_LIMIT,
      offset,
      name: options.text,
      mediaType: CONTENT_ITEM_MEDIA_TYPE.VIDEO,
      onSuccess(items, totalItems) {
        setVideoItems(items);
        setOptions({ totalItems, offset });
      }
    });
  };

  const handleFilterValueChange = (text: string) => {
    setOptions({ text });
    throttle(250, () => {
      fetchItems({
        limit: VIDEO_LIMIT,
        offset: options.offset,
        name: text,
        mediaType: CONTENT_ITEM_MEDIA_TYPE.VIDEO,
        onSuccess(items, totalItems) {
          setVideoItems(items);
          setOptions({ totalItems });
        }
      });
    })();
  };

  const handleExternalContentAdded = () => {
    fetchItems({
      limit: VIDEO_LIMIT,
      mediaType: CONTENT_ITEM_MEDIA_TYPE.VIDEO,
      onSuccess(items, totalItems) {
        setOptions({ totalItems });
        setVideoItems(items);
      }
    });
  };

  const renderButtonViewerHeader = () => {
    return (
      <ButtonViewerHeaderDiv disabled={!vbContext.canvas}>
        Buttons
        <span onClick={vbContext.addButton} data-tour="addButton" data-testid="addButton" role="button">
          <PlusIcon />
        </span>
      </ButtonViewerHeaderDiv>
    );
  };

  React.useEffect(() => {
    if (notification.videoContent?.contentItem?.id) {
      fetchItem({ id: notification.videoContent.contentItem.id, url: true });
    }
  }, [notification.videoContent?.contentItem?.id]);

  React.useEffect(() => {
    fetchItems({
      limit: VIDEO_LIMIT,
      mediaType: CONTENT_ITEM_MEDIA_TYPE.VIDEO,
      onSuccess(items, totalItems) {
        setVideoItems(items);
        setOptions({ totalItems });
      }
    });
  }, []);

  return useMemo(
    () => (
      <>
        <ColumnDiv>
          <StyledTilesViewerUpload
            testId="videoFilesViewer"
            tourId="videoFilesViewer"
            title="Video Files"
            uploadButtonText="Create New Video Item"
            uploadPresetExternalFields={{
              mediaType: CONTENT_ITEM_MEDIA_TYPE.VIDEO,
              presentation: [CONTENT_ITEM_PRESENTATION_TYPE.DEFAULT]
            }}
            uploadDisableExternalFields={['mediaType', 'presentation']}
            fileUploadLabel="Drag Content Here"
            files={videoFiles}
            onSelect={handleVideoSelect}
            selectedFileIds={[notification.videoContent?.contentItem?.id]}
            acceptedFiles="video/*"
            onUpload={handleVideoUpload}
            ref={fileUploadRef}
            loading={status === 'fetching'}
            uploadMultiple={false}
            filterValue={options.text}
            filterPlaceholder="Filter videos by name"
            onFilterValueChange={handleFilterValueChange}
            pagination={{
              offset: options.offset,
              totalItems: options.totalItems,
              limit: VIDEO_LIMIT,
              onPageChange: handlePageChange
            }}
            onExternalContentAdded={handleExternalContentAdded}
          />
        </ColumnDiv>
        <StyledFileViewer
          testId="buttonsViewer"
          header={renderButtonViewerHeader()}
          files={buttons}
          hideTableHeader
          displayKeys={['displayName', 'options.url', 'id']}
          displayKeyFormat={buttonDisplayKeyFormats}
          onSelect={handleButtonSelect}
          selectedFiles={[notification.buttons[vbContext.activeButtonId]]}
        />
      </>
    ),
    [
      notification.videoContent?.contentItem?.id,
      notification.personalisation,
      buttons.length,
      notification?.buttons[vbContext.activeButtonId]?.options?.url,
      notification?.buttons[vbContext.activeButtonId]?.displayName,
      vbContext.activeButtonId,
      vbContext.canvas,
      videoItems,
      options
    ]
  );
};
