import { AxiosInstance } from 'axios';
import { ApiChildTenant } from './types';
import { instance } from '../tools/axios-instance';
import { refreshAuthToken } from '../tools/auth.decorater';
import { ApiKey } from '@pushologies/database-service/db/entities';

export class ChildTenantsApiClass {
  constructor(private axios: AxiosInstance) {}

  @refreshAuthToken()
  async fetchChildTenants(): Promise<{
    totalItems: number;
    childTenants: ApiChildTenant[];
  }> {
    const {
      data: {
        response: { tenants, totalItems }
      }
    } = await this.axios.get(`/tenants`);

    return {
      childTenants: tenants,
      totalItems
    };
  }

  @refreshAuthToken()
  async fetchSessionApiKey(tenantId: string, regenerate = false): Promise<ApiKey> {
    const {
      data: { response }
    } = await this.axios.post(`/tenants/session`, { tenantId, regenerate });

    return response;
  }

  @refreshAuthToken()
  async refreshChildTenantSession(tenantId: string): Promise<ApiKey> {
    const {
      data: { response }
    } = await this.axios.post(
      `/tenants/session`,
      { tenantId },
      {
        headers: {
          'x-api-key': undefined,
          'x-api-secret': undefined
        }
      }
    );

    return response;
  }
}

export const childTenantsApi = new ChildTenantsApiClass(instance);
