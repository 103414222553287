import React from 'react';
import { MEASUREMENT_TYPE, MEASUREMENT_INTERVAL } from '@pushologies/database-service/db/entities/measurement';
import { useStoreActions } from '~store/hooks';
import dayjs from '~helpers/dayjs';
import { Measurement } from '~api/measurements';
import { Loader } from '~components/loader';
import { SectionHeaderDiv } from '../styles';
import { MetricsRowDiv } from '../user-metrics/styles';
import { NotificationsStatsSection, Metric } from './styles';

interface Metrics {
  viewed: number;
  opened: number;
  linkOpened: number;
  downloaded: number;
  delivered: number;
}

export const NotificationStats: React.FC = () => {
  const [loading, setLoading] = React.useState(false);
  const { fetchMeasurements } = useStoreActions((state) => state.measurements);
  const [metrics, setMetrics] = React.useState<Metrics>({
    viewed: 0,
    opened: 0,
    linkOpened: 0,
    downloaded: 0,
    delivered: 0
  });

  React.useEffect(() => {
    const now = dayjs();
    setLoading(true);

    fetchMeasurements({
      intervals: [MEASUREMENT_INTERVAL.MONTHLY],
      types: [
        MEASUREMENT_TYPE.NOTIFICATIONS_SENT_TOTAL,
        MEASUREMENT_TYPE.NOTIFICATIONS_VIEWED_UNIQUE,
        MEASUREMENT_TYPE.NOTIFICATIONS_OPENED_UNIQUE,
        MEASUREMENT_TYPE.NOTIFICATIONS_DOWNLOADED_UNIQUE,
        MEASUREMENT_TYPE.NOTIFICATIONS_RECEIVED_UNIQUE,
        MEASUREMENT_TYPE.NOTIFICATIONS_LINK_OPENED_UNIQUE
      ],
      onSuccess(monthlyMeasurements: Measurement[]) {
        // monthly measurements generated end of month so fetch daily measurements for current month and combine
        fetchMeasurements({
          to: now.toDate(),
          from: now.startOf('month').toDate(),
          intervals: [MEASUREMENT_INTERVAL.DAILY],
          types: [
            MEASUREMENT_TYPE.NOTIFICATIONS_SENT_TOTAL,
            MEASUREMENT_TYPE.NOTIFICATIONS_VIEWED_UNIQUE,
            MEASUREMENT_TYPE.NOTIFICATIONS_OPENED_UNIQUE,
            MEASUREMENT_TYPE.NOTIFICATIONS_DOWNLOADED_UNIQUE,
            MEASUREMENT_TYPE.NOTIFICATIONS_RECEIVED_UNIQUE,
            MEASUREMENT_TYPE.NOTIFICATIONS_LINK_OPENED_UNIQUE
          ],
          onSuccess(dailyMeasurements: Measurement[]) {
            const measurements = [...monthlyMeasurements, ...dailyMeasurements];
            const totalMessages = measurements
              .filter(({ type }) => type === MEASUREMENT_TYPE.NOTIFICATIONS_SENT_TOTAL)
              .reduce((prev, curr) => prev + curr.value, 0);
            const viewed = measurements
              .filter(({ type }) => type === MEASUREMENT_TYPE.NOTIFICATIONS_VIEWED_UNIQUE)
              .reduce((prev, curr) => prev + curr.value, 0);
            const opened = measurements
              .filter(({ type }) => type === MEASUREMENT_TYPE.NOTIFICATIONS_OPENED_UNIQUE)
              .reduce((prev, curr) => prev + curr.value, 0);
            const linkOpened = measurements
              .filter(({ type }) => type === MEASUREMENT_TYPE.NOTIFICATIONS_LINK_OPENED_UNIQUE)
              .reduce((prev, curr) => prev + curr.value, 0);
            const downloaded = measurements
              .filter(({ type }) => type === MEASUREMENT_TYPE.NOTIFICATIONS_DOWNLOADED_UNIQUE)
              .reduce((prev, curr) => prev + curr.value, 0);
            const delivered = measurements
              .filter(({ type }) => type === MEASUREMENT_TYPE.NOTIFICATIONS_RECEIVED_UNIQUE)
              .reduce((prev, curr) => prev + curr.value, 0);

            setMetrics({
              viewed: Math.floor((viewed / totalMessages) * 100),
              opened: Math.floor((opened / totalMessages) * 100),
              linkOpened: Math.floor((linkOpened / totalMessages) * 100),
              downloaded: Math.floor((downloaded / totalMessages) * 100),
              delivered: Math.floor((delivered / totalMessages) * 100)
            });
            setLoading(false);
          }
        });
      }
    });
  }, []);

  return (
    <NotificationsStatsSection>
      <Loader loading={loading} testId="sectionLoader" />
      <SectionHeaderDiv data-testid="notificationStatsHeader">
        <h3>notification statistics</h3>
      </SectionHeaderDiv>
      <MetricsRowDiv>
        <Metric $percentage={metrics.viewed} data-testid="notificationStatsMetric">
          <p>{metrics.viewed || 0}%</p>
          <p>finished viewing</p>
          <span />
          <span />
        </Metric>
        <Metric $percentage={metrics.opened} data-testid="notificationStatsMetric">
          <p>{metrics.opened || 0}%</p>
          <p>opened</p>
          <span />
          <span />
        </Metric>
        <Metric $percentage={metrics.linkOpened} data-testid="notificationStatsMetric">
          <p>{metrics.linkOpened || 0}%</p>
          <p>link opened</p>
          <span />
          <span />
        </Metric>
        <Metric $percentage={metrics.downloaded} data-testid="notificationStatsMetric">
          <p>{metrics.downloaded || 0}%</p>
          <p>downloaded</p>
          <span />
          <span />
        </Metric>
        <Metric $percentage={metrics.delivered} data-testid="notificationStatsMetric">
          <p>{metrics.delivered || 0}%</p>
          <p>delivered</p>
          <span />
          <span />
        </Metric>
      </MetricsRowDiv>
    </NotificationsStatsSection>
  );
};
