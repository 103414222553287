import React, { FC } from 'react';
import { useStoreState } from '~store/hooks';

const rolePriorityMap: Record<string, number> = {
  Admin: 3,
  Publisher: 2,
  Creator: 1,
  'Read Only': 0
};

interface Props {
  requiredRole: string;
  children: any;
}

export const userHasRequiredRole = (userRole: string, requiredRole: string) => {
  return rolePriorityMap[userRole] >= rolePriorityMap[requiredRole];
};

export const RoleGuard: FC<Props> = ({ requiredRole, children }) => {
  const tenant = useStoreState((state) => state.tenant.tenant);

  // do we have fall short of the required role to view this UI element?
  if (!userHasRequiredRole(tenant?.userProfile?.role?.name, requiredRole)) return <></>;

  return children;
};
