import styled from 'styled-components';
import { Row } from 'react-bootstrap';

export const StyledRow = styled(Row)`
  margin-bottom: 2rem;

  .col-md-auto {
    width: 50vw;

    h1 {
      font-family: EavesBold;
      text-transform: uppercase;
      color: ${({ theme }) => theme.palette.darkGrey};
      font-size: 1.4rem;
      margin: 2rem 0;
    }
  }
`;

export const CredentialWrapperDiv = styled.div`
  position: relative;
  display: flex;
  align-items: flex-start;

  svg {
    margin-right: 0.75rem;
    width: 2rem;

    path {
      fill: ${({ theme }) => theme.palette.darkGrey};
    }
  }

  .creds-info {
    position: relative;
    margin-left: 1.5rem;
    margin-bottom: 3rem;

    .spinner-border {
      position: absolute;
      left: -1.5rem;
      color: ${({ theme }) => theme.palette.darkGrey};
    }

    p {
      margin: 0;
      color: ${({ theme }) => theme.palette.darkGrey};

      span {
        font-family: EavesBold;
        text-transform: uppercase;
      }
    }

    span {
      color: ${({ theme }) => theme.palette.lighterGrey};
    }
  }
`;
