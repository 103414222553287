import React, { memo } from 'react';
import { FileRejection, DropEvent } from 'react-dropzone';
import { ExternalContentItem } from '~api/content-items/types';
import { FileUpload, FileUploadRef } from '~components/file-upload';
import { ViewerHeader } from '../header';
import { Props as TilesViewerProps, TilesViewer } from '../tiles';
import { TilesViewerUploadSection, UploadWrapperDiv } from './styles';

export interface Props<T> extends TilesViewerProps<T> {
  title: string;
  onUpload: <P extends File>(acceptedFiles: P[], fileRejections: FileRejection[], event: DropEvent) => void;
  acceptedFiles: string | string[];
  uploadButtonText: string;
  uploadMultiple?: boolean;
  fileUploadLabel?: string;
  className?: string;
  uploadPresetExternalFields?: Partial<ExternalContentItem>;
  uploadDisableExternalFields?: Partial<keyof ExternalContentItem>[];
  onExternalContentAdded?: () => void;
}

const TilesViewerUploadComponent = React.forwardRef(
  <T extends { id: string }>(
    {
      title,
      onUpload,
      acceptedFiles,
      fileUploadLabel,
      className,
      testId,
      uploadMultiple,
      filterValue,
      filterPlaceholder,
      onFilterValueChange,
      tourId,
      uploadButtonText,
      uploadPresetExternalFields,
      uploadDisableExternalFields,
      onExternalContentAdded,
      ...rest
    }: React.PropsWithChildren<Props<T>>,
    fileUploadRef: React.ForwardedRef<FileUploadRef>
  ) => {
    return (
      <TilesViewerUploadSection className={className} data-testid={testId} data-tour={tourId}>
        <ViewerHeader
          title={title}
          onTextChange={onFilterValueChange}
          filterValue={filterValue}
          filterPlaceholder={filterPlaceholder}
        />
        <UploadWrapperDiv>
          <FileUpload
            buttonText={uploadButtonText}
            ref={fileUploadRef}
            label={fileUploadLabel}
            dropzoneOptions={{
              onDrop: onUpload,
              accept: acceptedFiles,
              multiple: uploadMultiple
            }}
            presetExternalFields={uploadPresetExternalFields}
            disabledExternalFields={uploadDisableExternalFields}
            onExternalContentAdded={onExternalContentAdded}
          />
        </UploadWrapperDiv>
        <TilesViewer {...rest} testId={`${testId}TilesViewer`} />
      </TilesViewerUploadSection>
    );
  }
);

/** Renders a TilesViewer along with the FileUpload component */
export const TilesViewerUpload = memo(TilesViewerUploadComponent);
