import { Container } from 'react-bootstrap';
import styled from 'styled-components';
import { TextField } from '~components/inputs/text-field';
import { Select } from '~components/select';

export const StyledContainer = styled(Container).attrs({
  id: 'page-container-full-width'
})`
  width: 100%;
  padding-top: ${({ theme: { metrics } }) => `calc(${metrics.headerHeight}) !important`};
  display: flex;
  flex-direction: column;
  align-items: center;

  .dash-top {
    background-color: ${({ theme }) => theme.palette.secondaryPurple};
    border-top: solid 4px ${({ theme }) => theme.palette.primaryPlum};
    padding: 4rem 8rem;
    width: 100%;
    flex: 1;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    h3 {
      max-width: 30rem;
      color: #fff;
    }
  }

  .dash-top-session {
    background-color: ${({ theme }) => theme.palette.secondaryPurple};
    border-top: solid 4px ${({ theme }) => theme.palette.primaryPlum};
    padding: 4rem 8rem;
    width: 100%;
    flex: 1;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    text-align: center;

    h3 {
      color: #fff;
    }
  }
`;

export const TenantListSection = styled.div`
  width: 80%;
  margin: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const SectionHeaderDiv = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 5%;
  margin: 5rem 0;
`;

export const TenantGroupDiv = styled.div`
  width: 100%;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-around;
  align-items: center;
  gap: 2%;
`;

export const StyledTextField = styled(TextField)`
  width: 40%;
  height: 3rem;
  border-radius: 5px;
`;

export const StyledSelect = styled(Select)`
  width: 20%;
  height: 2.5rem;
  border-radius: 5px;
`;

export const SessionSplashScreen = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  background-color: #fff;
  width: 100vw;
  height: 100vh;
  z-index: 999;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${({ theme }) => theme.palette.secondaryPurple};

  div {
    text-align: center;
  }
`;
