import React from 'react';
import { Tab } from '~components/file-viewers/tabs';
import { TabsViewer } from '~components/file-viewers/tabs';
import { InfoBanner } from '~components/info-banner';
import { useStoreActions } from '~store/hooks';
import { BannerArea } from './banner-area';
import { TabContentDiv } from './styles';

type ActiveTab = 'bannerTab' | 'richImageTab';
interface Props {
  activeTab: ActiveTab;
}

export const TabBannerView: React.FC<Props> = (props) => {
  const [activeTab, setActiveTab] = React.useState(props.activeTab);
  const { deleteContent } = useStoreActions((state) => state.createNotification);

  const handleSelect = (tab: ActiveTab) => {
    deleteContent();
    setActiveTab(tab);
  };

  React.useEffect(() => {
    setActiveTab(props.activeTab);
  }, [props.activeTab]);

  return (
    <TabsViewer activeKey={activeTab} onSelect={handleSelect}>
      <Tab eventKey="bannerTab" title="Banner">
        <TabContentDiv className="content-div">
          <BannerArea type={activeTab} />
        </TabContentDiv>
      </Tab>
      <Tab eventKey="richImageTab" title="Rich Image">
        <TabContentDiv className="content-div">
          <InfoBanner message="Rich Image functionality will only work on SDK versions 4.6.0 and above!" />
          <BannerArea type={activeTab} />
        </TabContentDiv>
      </Tab>
    </TabsViewer>
  );
};
