import React from 'react';
import classnames from 'classnames';
import { RuleId } from '@pushologies/segments-service/data/rules';
import DurationIcon from '~images/duration.svg';
import DeviceIcon from '~images/device.svg';
import LanguageIcon from '~images/language.svg';
import FirstSessionIcon from '~images/first_session.svg';
import LastSessionIcon from '~images/last_session.svg';
import SessionCountsIcon from '~images/session_counts.svg';
import AppVersionIcon from '~images/app_version.svg';
import DeviceIdsIcon from '~images/devices_id.svg';
import SubscriberIdsIcon from '~images/subscriber_id.svg';
import TagsIcon from '~images/tags.svg';
import LocationIcon from '~images/location.svg';
import LocationPermissionIcon from '~images/location_permission.svg';
import UserIcon from '~images/user.svg';
import CountryIcon from '~images/country.svg';
import NotificationPermissionIcon from '~images/notification_permission.svg';
import NotificationStatusIcon from '~images/info.svg';

interface Props {
  id: RuleId;
}

// tslint:disable-next-line: cyclomatic-complexity
export const RuleIcon: React.FC<Props> = ({ id }) => {
  switch (id) {
    case 'duration':
      return <DurationIcon className={classnames(id, 'rule-icon')} />;
    case 'device':
      return <DeviceIcon className={classnames(id, 'rule-icon')} />;
    case 'deviceLanguage':
      return <LanguageIcon className={classnames(id, 'rule-icon')} />;
    case 'firstSession':
      return <FirstSessionIcon className={classnames(id, 'rule-icon')} />;
    case 'lastSession':
      return <LastSessionIcon className={classnames(id, 'rule-icon')} />;
    case 'sessionCounts':
      return <SessionCountsIcon className={classnames(id, 'rule-icon')} />;
    case 'appVersion':
      return <AppVersionIcon className={classnames(id, 'rule-icon')} />;
    case 'deviceIds':
      return <DeviceIdsIcon className={classnames(id, 'rule-icon')} />;
    case 'subscriberIds':
      return <SubscriberIdsIcon className={classnames(id, 'rule-icon')} />;
    case 'tags':
      return <TagsIcon className={classnames(id, 'rule-icon')} />;
    case 'location':
      return <LocationIcon className={classnames(id, 'rule-icon')} />;
    case 'customerIds':
      return <UserIcon className={classnames(id, 'rule-icon')} />;
    case 'countryCode':
      return <CountryIcon className={classnames(id, 'rule-icon')} />;
    case 'notificationPermission':
      return <NotificationPermissionIcon className={classnames(id, 'rule-icon')} />;
    case 'locationPermission':
      return <LocationPermissionIcon className={classnames(id, 'rule-icon')} />;
    case 'notificationPushStatus':
      return <NotificationStatusIcon className={classnames(id, 'rule-icon')} />;
    default:
      return null;
  }
};
