import React, { ChangeEvent, useState } from 'react';
import { Overlay } from 'react-bootstrap';
import { ChromePicker, ColorChangeHandler, RGBColor } from 'react-color';
import { ColorPickerInputSection, ContentWrapper, PickerWrapperDiv, ColorPickerManualInput } from './styles';
import { rgbColorToRgbaString, colorStringToRgbaColor } from './helpers';
import { rgbColorToHexString } from './helpers';

export * from './helpers';

interface Props {
  testId?: string;
  label?: string;
  /** hex, rgb or rgba string */
  value: string;
  disabled?: boolean;
  onChange(colorResult: RGBColor): void;
}

export const ColorPickerField: React.FC<Props> = (props) => {
  const ref = React.useRef<HTMLElement>();
  const [show, setShow] = React.useState(false);
  const [color, setColor] = React.useState<RGBColor>(colorStringToRgbaColor(props.value));
  const [localValue, setLocalValue] = useState(props.value || 'none');
  const isOpaque = color.a === 0;

  const handleShowPicker = () => {
    setShow(!show);
  };

  const handleDirectInput = (event: ChangeEvent<HTMLInputElement>) => {
    setLocalValue(event.target.value);
    try {
      const rgbColor = colorStringToRgbaColor(event.target.value);
      setColor(rgbColor);
      props.onChange(rgbColor);
    } catch (e) {
      return;
    }
  };

  const handleOnChange: ColorChangeHandler = ({ rgb }, _) => {
    setColor(rgb);
    setLocalValue(rgbColorToHexString(rgb));
    props.onChange(rgb);
  };

  React.useEffect(() => {
    setColor(colorStringToRgbaColor(props.value));
  }, [props.value]);

  return (
    <ColorPickerInputSection
      $color={isOpaque ? null : rgbColorToRgbaString(color)}
      $disabled={props.disabled}
      data-testid={props.testId}
      ref={ref}
    >
      {props.label && <label>{props.label}</label>}
      <ContentWrapper>
        <ColorPickerManualInput value={localValue} onChange={handleDirectInput} />
        <figure onClick={handleShowPicker} />
      </ContentWrapper>
      <Overlay target={ref.current} show={show} placement="bottom" onHide={handleShowPicker} rootClose>
        {({ placement, arrowProps, show: _show, popper, ...root }) => (
          <PickerWrapperDiv {...root} data-testid={`${props.testId}Overlay`}>
            <ChromePicker color={color} onChange={handleOnChange} />
          </PickerWrapperDiv>
        )}
      </Overlay>
    </ColorPickerInputSection>
  );
};
