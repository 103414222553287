import React, { useMemo } from 'react';
import { useTheme } from 'styled-components';
import { Col, Row, OverlayTrigger } from 'react-bootstrap';
import { CONTENT_ITEM_MEDIA_TYPE, CONTENT_ITEM_PRESENTATION_TYPE } from '@pushologies/common/constants/content-item';
import { NOTIFICATION_TYPE } from '@pushologies/database-service/db/entities/notification';
import { SUBSCRIBER_DEVICE_TYPES } from '@pushologies/database-service/db/entities/subscriber';
import { ApiNotification, ApiButtonResults } from '~api/notifications/types';
import { dateTimeFormat } from '~helpers/formats';
import { PreviewDevice, DeviceOption, deviceOptions } from '~components/preview-device';
import { Select } from '~components/select';
import { TabsViewer, Tab } from '~components/file-viewers/tabs';
import { StyledTooltip } from '~components/styled/tooltip';
import { bytesToSize } from '~helpers/files';
import { CarouselContent } from '~store/create-notification/types';
import { PersonalisationString } from '~components/personalisation-string';
import { useStoreState } from '~store/hooks';
import { getNotificationTypeIcon } from '../helpers';
import { ButtonStats } from './button-stats';
import { BottomStyledRow, PreviewCol, DeviceRow, TabContentDiv } from './styles';
import { constructThumbnailUrl } from '~api/tools/helpers';
import { useFlags } from 'launchdarkly-react-client-sdk';

interface Props {
  notification: ApiNotification;
}

type StatsAggregate = Record<string, Partial<Record<SUBSCRIBER_DEVICE_TYPES, ApiButtonResults>>>;

export const BottomRow: React.FC<Props> = ({ notification }) => {
  const theme = useTheme();
  const [selectedDevice, setSelectedDevice] = React.useState(deviceOptions[0]);
  const { isSuperTenant } = useStoreState((state) => state.childTenants);
  const { buttonResults } = useStoreState((state) => state.notificationResult);
  const statAggregates =
    buttonResults?.reduce<StatsAggregate>((prev, button) => {
      const resultsKey = button.buttonId ?? button.url;

      if (!prev[resultsKey]) prev[resultsKey] = {};
      prev[resultsKey][button.deviceType] = button;
      return prev;
    }, {}) || {};
  const urls = Object.keys(statAggregates);
  const videoContent = notification?.contents?.find(
    ({ contentItem }) => contentItem.mediaType === CONTENT_ITEM_MEDIA_TYPE.VIDEO
  );
  const bannerContent = notification?.contents?.find(
    ({ contentItem }) =>
      contentItem.mediaType === CONTENT_ITEM_MEDIA_TYPE.IMAGE &&
      (contentItem.presentation.includes(CONTENT_ITEM_PRESENTATION_TYPE.BANNER) ||
        contentItem.presentation.includes(CONTENT_ITEM_PRESENTATION_TYPE.RICH_IMAGE))
  );
  const carouselContent: CarouselContent[] = notification?.contents
    ?.filter(({ presentation }) => presentation === CONTENT_ITEM_PRESENTATION_TYPE.CAROUSEL)
    ?.map(({ contentItem }) => ({ contentItem }));
  const mimeType = videoContent?.contentItem?.mimeType;
  const bannerDownloadUrl = bannerContent?.contentItem?.downloadUrl;
  const videoThumbnailDownloadUrl = constructThumbnailUrl(videoContent?.contentItem);
  const {
    hideVideoOrientationAdminUi,
    hideVideoScrubbingAdminUi,
    hideVideoAspectRatioAdminUi,
    hideVideoFullscreenAdminUi,
    hideVideoEmbeddedPlayerAdminUi,
    hideVideoPreviewAdminUi,
    hideVideoSharingAdminUi
  } = useFlags();

  const handlePreviewDeviceChange = (selected: DeviceOption[]) => {
    setSelectedDevice(selected[0]);
  };

  const notificationPushTimestamp = useMemo(
    () => notification?.pushedAt || notification?.schedule?.pushAt,
    [notification]
  );

  return (
    <BottomStyledRow>
      <Col md={12}>
        <Row className="top">
          <div className="details">
            <PersonalisationString
              as="h4"
              text={notification?.title}
              personalisation={notification?.personalisation}
              primaryColour={theme.palette.primaryPlum}
            />
            <p>{dateTimeFormat(notificationPushTimestamp, 'LL')}</p>
            <p>{dateTimeFormat(notificationPushTimestamp, 'HH:mm:ss A')}</p>
          </div>
          <div className="metadata">
            {notification?.type === NOTIFICATION_TYPE.VIDEO && (
              <>
                <h6>Format: {mimeType}</h6>
                <h6>File Size: {bytesToSize(videoContent?.contentItem?.size)}</h6>
              </>
            )}
            {notification?.type === NOTIFICATION_TYPE.CAROUSEL && (
              <>
                <h6>Number of Slides: {carouselContent.length}</h6>
              </>
            )}
            <OverlayTrigger
              placement="top"
              overlay={
                <StyledTooltip data-testid="barChartTooltip">{`${notification?.type} Notification`}</StyledTooltip>
              }
            >
              <span className="icon">{getNotificationTypeIcon(notification?.type)}</span>
            </OverlayTrigger>
          </div>
        </Row>
        <Row className="bottom">
          <Col md={9}>
            {notification?.type !== NOTIFICATION_TYPE.STANDARD && (
              <TabsViewer>
                <Tab eventKey="buttonsReport" title={`${notification?.type} Buttons Report`}>
                  <TabContentDiv>
                    {urls.map((url) => (
                      <ButtonStats
                        key={url}
                        iosStats={statAggregates[url].IOS}
                        androidStats={statAggregates[url].ANDROID}
                        notification={notification}
                      />
                    ))}
                    {!urls.length && <i>Notification does not contain buttons with a url</i>}
                  </TabContentDiv>
                </Tab>
              </TabsViewer>
            )}
          </Col>
          <PreviewCol md={3}>
            <DeviceRow>
              <PreviewDevice
                notificationType={notification?.type}
                title={notification?.title}
                message={notification?.message}
                subTitle={notification?.subTitle}
                selectedDevice={selectedDevice}
                bannerUrl={bannerDownloadUrl}
                videoThumbnailUrl={videoThumbnailDownloadUrl}
                carouselContent={carouselContent}
                inApp={notification?.inApp}
                tenantId={isSuperTenant && notification?.tenantId}
                presentation={bannerContent?.presentation}
              />
            </DeviceRow>
            <Row>
              <Select
                id="devicePreviewSelect"
                value={[selectedDevice]}
                options={deviceOptions}
                onChange={handlePreviewDeviceChange}
                label="Preview Device:"
              />
            </Row>
          </PreviewCol>
        </Row>
        {notification?.type === NOTIFICATION_TYPE.VIDEO && (
          <Row className="videoInfo">
            <Col md={12}>
              {!hideVideoOrientationAdminUi && (
                <p>
                  Orientation <span>{videoContent?.options?.orientation}</span>
                </p>
              )}
              {!hideVideoAspectRatioAdminUi && (
                <p>
                  Aspect <span>{videoContent?.options?.zoomMode}</span>
                </p>
              )}
              {!hideVideoScrubbingAdminUi && (
                <p>
                  Allow Scrubbing <span>{String(videoContent?.options?.allowScrubbing)}</span>
                </p>
              )}
              {!hideVideoFullscreenAdminUi && (
                <p>
                  Full Screen <span>{String(videoContent?.options?.fullScreen)}</span>
                </p>
              )}
              {!hideVideoPreviewAdminUi && (
                <p>
                  Allow Preview <span>{String(videoContent?.options?.allowPreview)}</span>
                </p>
              )}
              {!hideVideoSharingAdminUi && (
                <p>
                  Allow Sharing <span>{String(videoContent?.options?.allowSharing)}</span>
                </p>
              )}
              {!hideVideoEmbeddedPlayerAdminUi && (
                <p>
                  Use Embedded Player <span>{String(videoContent?.options?.useEmbeddedPlayer)}</span>
                </p>
              )}
            </Col>
          </Row>
        )}
      </Col>
    </BottomStyledRow>
  );
};
