import { Modal } from 'react-bootstrap';
import styled from 'styled-components';
import { HideScrollbars } from '~styles/mixins';

export const StyledModal = styled(Modal)`
  overflow-y: hidden !important;
  display: flex !important;

  .modal-dialog {
    border: solid 2px ${({ theme }) => theme.palette.secondaryPurple};
    background-color: #fff;
    border-radius: 10px;
    padding: 2rem;
    width: 650px;
    max-width: 34%;
    max-height: 50%;
    min-height: 650px;
    display: flex;
    align-items: center;
    justify-content: center;

    .modal-content {
      border: none;
      border-radius: 0;

      .modal-body {
        overflow-y: scroll;
        padding-right: 0;
        padding-left: 0;
        ${HideScrollbars}
      }
    }
  }

  h3 {
    font-size: 2rem;
    font-weight: bold;
    font-family: EavesHeavy;
    margin: 0;
    color: ${({ theme }) => theme.palette.secondaryPurple};
  }

  h4 {
    font-size: 1.5rem;
    margin: 0;
  }

  h5 {
    font-size: 0.9rem;
    font-family: EavesBold;
    text-transform: uppercase;
    font-weight: 800;
    margin: 0;
    opacity: 60%;
  }

  .btn {
    width: 100%;
  }

  .bio-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 1rem;
    margin-bottom: 2rem;
    border-bottom: solid 1px rgba(100, 100, 100, 0.4);

    div {
      display: flex;

      align-items: center;
      width: 100%;
      gap: 4%;

      p {
        margin: 0;

        b {
          font-family: EavesBold;
        }
      }

      &:last-child {
        justify-content: flex-end;
      }
    }

    span {
      background-color: ${({ theme }) => theme.palette.secondaryPurple};
      height: 40px;
      width: 40px;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      color: white;
      font-family: EavesBold;
    }
  }

  .stat-body {
    padding: 1rem;
    border-radius: 10px;
    margin-bottom: 2rem;
    box-shadow: 0 0 10px rgba(100, 100, 100, 0.3);
    margin: 2rem 0.5rem;
  }

  .stat-label {
    background-color: ${({ theme }) => theme.palette.primaryPlum};
    color: #fff;
    padding: 0.25rem 1rem;
    padding-right: 2rem;
    font-family: EavesBold;
    text-transform: uppercase;
    margin-bottom: 1rem;
    margin-left: -1rem;
    display: inline-block;

    h4 {
      font-size: 1rem;
    }
  }

  .date-stat {
    display: flex;
    gap: 3%;
    align-items: center;
    justify-content: center;
    margin: 1.5rem 0;

    span {
      color: ${({ theme }) => theme.palette.primaryPlum};
      font-family: EavesBold;
      font-weight: 900;
    }
  }

  .stat-row {
    display: flex;
    flex-direction: row;
    justify-content: center;

    div {
      border-right: solid 1px rgba(100, 100, 100, 0.4);
      margin-right: 1.5rem;
      padding-right: 1.5rem;

      &:last-child {
        border: none;
        margin-right: 0;
        padding-right: 0;
      }
    }
  }

  .modal-buttons {
    display: flex;
    gap: 5%;

    button:last-child {
      background-color: ${({ theme }) => theme.palette.secondaryPurple};
    }
  }
`;
