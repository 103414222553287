import React from 'react';
import { Spinner } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { NOTIFICATION_TYPE } from '@pushologies/database-service/db/entities/notification';
import { ApiNotification } from '~api/notifications/types';
import { PromptButton } from '~components/prompt-button';
import { ActionsTd } from '~components/styled/table-section';
import { dateTimeFormat } from '~helpers/formats';
import { useStoreActions, useStoreState } from '~store/hooks';
import EditIcon from '~images/edit.svg';
import CloneIcon from '~images/clone.svg';
import EyeIcon from '~images/eye.svg';
import DeleteIcon from '~images/delete.svg';
import { PersonalisationString } from '~components/personalisation-string';
import { getNotificationTypeIcon } from '../helpers';
import { CreatedByTd, PushedAtTd, DeleteSpan, TypeTd } from './styles';
import { INAPP_TYPE } from '@pushologies/common/constants/in-app';
import { RoleGuard } from '../../../role-guard';

// Different notification types have different URLs
// we need to direct the user to the correct place when they click action buttons
const notificationTypeUrlSegmentMap: Partial<Record<NOTIFICATION_TYPE, string>> = {
  [NOTIFICATION_TYPE.INAPP]: 'in-app'
};

interface Props {
  notification: ApiNotification;
  tenantId?: string;
  onDeleteSuccess(): void;
}

const ActionsColumn: React.FC<Props> = ({ notification, tenantId, onDeleteSuccess }) => {
  const history = useHistory();
  const { deleteNotification } = useStoreActions((state) => state.notifications);
  const { isSuperTenant } = useStoreState((state) => state.childTenants);
  const [isDeleting, setIsDeleting] = React.useState(false);
  const urlTypeSegment = notificationTypeUrlSegmentMap[notification.type] || 'notifications';

  const handleEditClick = () => {
    history.push(`/${urlTypeSegment}/create/${notification.id}`);
  };

  const handleCloneClick = () => {
    history.push(`/${urlTypeSegment}/create`, { notificationId: notification.id });
  };

  const handleAccessResultsClick = () => {
    isSuperTenant
      ? history.push(`/${urlTypeSegment}/${notification.id}/${tenantId}`)
      : history.push(`/${urlTypeSegment}/${notification.id}`);
  };

  const handleDeleteClick = () => {
    setIsDeleting(true);
    deleteNotification({
      id: notification.id,
      onSuccess() {
        onDeleteSuccess();
        setIsDeleting(false);
      },
      onError() {
        setIsDeleting(false);
      }
    });
  };

  return (
    <ActionsTd>
      <RoleGuard requiredRole="Creator">
        {!isSuperTenant && notification.isDraft && (
          <span onClick={handleEditClick} data-testid="editButton">
            <EditIcon />
          </span>
        )}
      </RoleGuard>

      {notification.pushedAt && (
        <span className="eye" onClick={handleAccessResultsClick} data-testid="resultsButton">
          <EyeIcon />
        </span>
      )}

      {!isSuperTenant && (
        <>
          <RoleGuard requiredRole="Creator">
            <span onClick={handleCloneClick} data-testid="cloneButton">
              <CloneIcon />
            </span>
          </RoleGuard>
          <RoleGuard requiredRole="Admin">
            <DeleteSpan isDeleting={isDeleting} data-testid="deleteButton">
              <PromptButton
                buttonType="icon"
                buttonIcon={<DeleteIcon />}
                onConfirm={handleDeleteClick}
                confirmButtonText="delete"
                promptTitle="Confirm Delete"
              >
                Are you sure you would like to delete notification "{notification.title}"?
              </PromptButton>
              {isDeleting && <Spinner animation="border" size="sm" />}
            </DeleteSpan>
          </RoleGuard>
        </>
      )}
    </ActionsTd>
  );
};

const CreatedByColumn: React.FC<Pick<Props, 'notification'>> = ({ notification }) => {
  return <CreatedByTd>{notification?.createdBy?.name}</CreatedByTd>;
};

const TypeColumn: React.FC<{ type: NOTIFICATION_TYPE | INAPP_TYPE }> = ({ type }) => {
  return <TypeTd>{getNotificationTypeIcon(type)}</TypeTd>;
};

const StatusColumn: React.FC<{ notification: ApiNotification }> = ({ notification }) => {
  let text: string;
  if (!!notification.pushedAt) text = dateTimeFormat(notification.pushedAt);
  else if (!!notification.schedule) text = `${dateTimeFormat(notification.schedule.pushAt)} (scheduled)`;

  return <PushedAtTd>{text ?? <i>draft</i>}</PushedAtTd>;
};

export const NotificationRow: React.FC<Props> = ({ notification, tenantId, onDeleteSuccess }) => {
  const computedType = notification.type === NOTIFICATION_TYPE.INAPP ? notification.inApp?.type : notification.type;

  return (
    <tr data-testid="notificationRow">
      <TypeColumn type={computedType} />
      <PersonalisationString as="td" text={notification.title} personalisation={notification.personalisation} />
      <PersonalisationString as="td" text={notification.subTitle} personalisation={notification.personalisation} />
      <StatusColumn notification={notification} />
      <td>{dateTimeFormat(notification.createdAt)}</td>
      <CreatedByColumn notification={notification} />
      <ActionsColumn
        notification={notification}
        tenantId={tenantId ? tenantId : null}
        onDeleteSuccess={onDeleteSuccess}
      />
    </tr>
  );
};
