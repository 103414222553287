import React from 'react';
import { useStoreActions, useStoreState } from '~store/hooks';
import { SegmentBuilder } from '~components/segment-builder';
import { StyledContainer } from '~components/styled/container';
import { WrapperDiv } from './styles';

export const CreateSegment: React.FC = () => {
  const { resetModel } = useStoreActions((state) => state.createSegment);
  const { visibility } = useStoreState(({ sideNav }) => sideNav);

  React.useEffect(() => resetModel, []);

  return (
    <StyledContainer $sideNavVisible={visibility === 'show'}>
      <WrapperDiv>
        <SegmentBuilder />
      </WrapperDiv>
    </StyledContainer>
  );
};
