import React from 'react';
import { match } from 'react-router-dom';
import { NOTIFICATION_TYPE } from '@pushologies/database-service/db/entities/notification';
import DocumentIcon from '~images/notification_document.svg';
import VideoIcon from '~images/notification_video.svg';
import CarouselIcon from '~images/notification_carousel.svg';
import StandardIcon from '~images/notification_standard.svg';
import PollIcon from '~images/notification_poll.svg';
import ImageIcon from '~images/notification_image.svg';
import { INAPP_TYPE } from '@pushologies/common/constants/in-app';

export const getNotificationTypeIcon = (type: NOTIFICATION_TYPE | INAPP_TYPE) => {
  return (
    <>
      {type === 'DOCUMENT' && <DocumentIcon />}
      {type === 'VIDEO' && <VideoIcon />}
      {type === 'STANDARD' && <StandardIcon />}
      {type === 'CAROUSEL' && <CarouselIcon />}
      {type === 'POLL' && <PollIcon />}
      {type === 'IMAGE' && <ImageIcon />}
    </>
  );
};

export enum NOTIFICATION_CHANNEL {
  InApp = 'in-app',
  Notification = 'notifications'
}

export enum INAPP_TRIGGERS {
  APP_OPEN = 'APP_OPEN',
  CUSTOM_TRIGGER = 'CUSTOM_TRIGGER'
}

export const isNotificationsPage = (router: match) => router.path.match(/^\/notifications/);
export const isInAppPage = (router: match) => router.path.match(/^\/in-app/);
