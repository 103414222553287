import dayjs from './dayjs';

type StringModifierFn = (value: string) => string;
type ComplexModifierFn<T1, T2> = (value: T1, modifier: T2) => any;

export interface StringModifiersType {
  capitalizeFirst: StringModifierFn;
  capitalizeAllFirst: StringModifierFn;
  uppercase: StringModifierFn;
  lowercase: StringModifierFn;
}

export interface ComplexModifiersType {
  dateFormat: ComplexModifierFn<string, string>;
}

export type PersonalisationModifierKeys = {
  type: keyof StringModifiersType | keyof ComplexModifiersType;
  config?: string;
};

export const stringModifiers: StringModifiersType = {
  capitalizeFirst: (value) => value.charAt(0).toUpperCase() + value.slice(1),
  capitalizeAllFirst: (value) => value.replace(/(^\w{1})|(\s+\w{1})/g, (letter) => letter.toUpperCase()),
  uppercase: (value) => value.toUpperCase(),
  lowercase: (value) => value.toLowerCase()
};

export const complexModifiers: ComplexModifiersType = {
  dateFormat: (value, modifier) => dayjs(value).format(modifier)
};
