export enum CONTENT_ITEM_PRESENTATION_TYPE {
  BANNER = 'BANNER',
  BUTTON = 'BUTTON',
  CAROUSEL = 'CAROUSEL',
  CAROUSEL_BUTTON = 'CAROUSEL_BUTTON',
  BACKGROUND_IMAGE = 'BACKGROUND_IMAGE',
  RICH_IMAGE = 'RICH_IMAGE',
  DEFAULT = 'DEFAULT'
}

export enum CONTENT_ITEM_MEDIA_TYPE {
  VIDEO = 'VIDEO',
  IMAGE = 'IMAGE'
}

export enum CONTENT_ITEM_SOURCE_TYPE {
  S3_OBJECT = 'S3_OBJECT',
  EXTERNAL_URL = 'EXTERNAL_URL'
}
