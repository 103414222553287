import React, { FC, useEffect, useMemo, useRef, useState } from 'react';
import { Col, Container, Modal, Nav, Row, Tab } from 'react-bootstrap';
import {
  ClientMetadataNav,
  StyledClientMetadataModal,
  StyledClientMetadataModalBody,
  StyledClientMetadataInvalidStatus,
  StyledDeleteClientMetadataButton
} from './styles';
import { Button } from '~components/button';
import { TextField } from '~components/inputs';
import Editor from '@monaco-editor/react';
import DeleteIcon from '~images/delete.svg';

enum ClientMetadataTabs {
  BASIC = 'basic',
  ADVANCED = 'advanced'
}

interface Props {
  isOpen: boolean;
  value: any;
  onChange: (newValue: any) => void;
  onClose: () => void;
}

export const ClientMetadataModal: FC<Props> = ({ isOpen, value, onChange, onClose }) => {
  const [localValue, setLocalValue] = useState<any>();
  const [basicValue, setBasicValue] = useState([]);
  const [showError, setShowError] = useState(false);
  const [tabKey, setTabKey] = useState(ClientMetadataTabs.BASIC);
  const editorRef = useRef(null);

  useEffect(() => {
    if (isOpen) {
      setLocalValue(value ? JSON.parse(value) : null);
    }
  }, [value, isOpen]);
  useEffect(() => {
    setBasicValue(Object.entries(localValue || {}));
  }, [localValue]);

  const handleEditorDidMount = (editor: any) => {
    editorRef.current = editor;
    handleTabChange(ClientMetadataTabs.BASIC);
  };
  const handleEditorChange = (updatedValue: string) => {
    try {
      setLocalValue(JSON.parse(updatedValue));
      setShowError(false);
    } catch (e) {
      setShowError(true);
    }
  };

  const isMetadataValid = useMemo(() => {
    if (showError) return false;
    if (!localValue) return true;
    try {
      const valueString = JSON.stringify(localValue);
      return valueString.length <= 300;
    } catch (e) {
      return false;
    }
  }, [localValue, showError]);

  const addMetadataProperty = () => {
    const currentValue = localValue || {};
    const newValue = { ...currentValue, [`property${localValue ? Object.keys(localValue).length + 1 : 1}`]: 'empty' };
    setLocalValue(newValue);
  };

  const handleClose = () => {
    setLocalValue(null);
    setShowError(false);
    onClose();
  };
  const handleSave = () => {
    const newValue = JSON.stringify(localValue);
    onChange(newValue === '{}' ? null : newValue);
    onClose();
  };

  const handleTabChange = (newTab: ClientMetadataTabs) => {
    try {
      if (localValue) {
        editorRef.current.getModel().setValue(JSON.stringify(localValue, null, 2));
      }
    } catch (e) {
      // do nothing with invalid data
    }
    setTabKey(newTab);
  };

  const handleBasicValueChange = (propertyIndex: number, valueIndex: number) => (event: any) => {
    const newBasicValue = [...basicValue];
    let formattedValue = event.target.value;
    if (formattedValue && !formattedValue.startsWith('0') && !isNaN(formattedValue)) {
      formattedValue = Number(formattedValue);
    } else if (['true', 'false'].includes(formattedValue)) {
      formattedValue = formattedValue === 'true';
    }
    newBasicValue[propertyIndex][valueIndex] = formattedValue;
    setBasicValue(newBasicValue);
    const newClientMetadataValue = newBasicValue.reduce(
      (acc, [eachKey, eachValue]) => ({
        ...acc,
        [eachKey]: eachValue
      }),
      {}
    );
    setLocalValue(newClientMetadataValue);
  };

  const canShowBasicEditor =
    !localValue ||
    (!Array.isArray(localValue) &&
      !Object.keys(localValue).find(
        (eachProperty) => !['string', 'number', 'boolean'].includes(typeof localValue[eachProperty])
      ));

  return (
    <StyledClientMetadataModal show={isOpen} backdrop="static" centered>
      <StyledClientMetadataModalBody>
        <Container fluid>
          <Tab.Container
            defaultActiveKey={ClientMetadataTabs.BASIC}
            activeKey={tabKey}
            onSelect={handleTabChange}
            data-testId={'clientMetadataTabs'}
          >
            <h5>Notification metadata</h5>
            <p>Any custom metadata you set will be delivered to your app along with your notification.</p>
            <ClientMetadataNav>
              <Nav className="flex-row">
                <Nav.Item>
                  <Nav.Link eventKey={ClientMetadataTabs.BASIC}>Basic</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey={ClientMetadataTabs.ADVANCED}>Advanced</Nav.Link>
                </Nav.Item>
              </Nav>
            </ClientMetadataNav>
            <Tab.Content>
              <Tab.Pane eventKey={ClientMetadataTabs.BASIC}>
                {canShowBasicEditor ? (
                  <>
                    <p>
                      Use the inputs below to add metadata properties. Text strings, numbers and true/false values are
                      supported in our "Basic" editor.
                    </p>
                    <p>
                      Use the "Advanced" tab above when you need more than a simple key/value store OR when your data
                      types matter.
                    </p>
                    {basicValue.map(([eachKey, eachValue]: any, i) => (
                      <Row key={i}>
                        <Col xs={5}>
                          <TextField
                            label={`Key${i + 1}:`}
                            name={`property${i}`}
                            value={String(eachKey)}
                            onChange={handleBasicValueChange(i, 0)}
                          />
                        </Col>
                        <Col xs={5}>
                          <TextField
                            label={`Value${i + 1}:`}
                            name={`value${i}`}
                            value={String(eachValue)}
                            onChange={handleBasicValueChange(i, 1)}
                          />
                        </Col>
                        <Col xs={2}>
                          <StyledDeleteClientMetadataButton
                            data-testId={`deleteMetadataProperty${i}`}
                            onClick={() => {
                              const newValue = { ...localValue };
                              delete newValue[eachKey];
                              setLocalValue(newValue);
                            }}
                          >
                            <DeleteIcon />
                          </StyledDeleteClientMetadataButton>
                        </Col>
                      </Row>
                    ))}
                    <Button onClick={addMetadataProperty}>Add new property</Button>
                  </>
                ) : (
                  <>
                    <p>Your metadata does not meet the requirements for "Basic" editing.</p>
                    <p>
                      Basic metadata has a flat key/value structure where both the "key" and "value" are numbers/strings
                      of text.
                    </p>
                    <p>
                      You can still edit your metadata using the "Advanced" tab above. If you'd like to clear existing
                      metadata and start again using the basic editor, you can:
                    </p>
                    <Button onClick={() => setLocalValue(null)}>Clear current metadata</Button>
                  </>
                )}
              </Tab.Pane>
              <Tab.Pane eventKey={ClientMetadataTabs.ADVANCED}>
                <p>Use the editor below to craft a custom JSON metadata value.</p>

                <Editor
                  height="45vh"
                  defaultLanguage="json"
                  onMount={handleEditorDidMount}
                  onChange={handleEditorChange}
                />
              </Tab.Pane>
            </Tab.Content>
          </Tab.Container>
        </Container>
      </StyledClientMetadataModalBody>
      <Modal.Footer>
        {!isMetadataValid && (
          <StyledClientMetadataInvalidStatus>
            Your metadata must be a valid JSON string that is less than 300 characters in length.
          </StyledClientMetadataInvalidStatus>
        )}
        <Button testId="clientMetadataCancel" onClick={handleClose}>
          Cancel
        </Button>
        <Button testId="clioentMetadataSave" onClick={handleSave} disabled={!isMetadataValid}>
          Save
        </Button>
      </Modal.Footer>
    </StyledClientMetadataModal>
  );
};
