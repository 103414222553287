import styled from 'styled-components';
import { Row, Col } from 'react-bootstrap';
import { darken } from 'polished';
import { breakpoints } from '~styles/mixins';

export const SECTION_HEIGHT = '26rem';

export const StyledRow = styled(Row)`
  padding: 3rem 2rem 2rem;
`;

export const StyledCol = styled(Col)`
  padding: 0 0.5rem;

  &:first-child {
    @media ${breakpoints.md} {
      display: inline-flex;
    }
    @media ${breakpoints.sm} {
      flex-direction: column;
    }
  }
`;

export const Section = styled.section`
  position: relative;
  display: block;
  width: 100%;
  height: ${SECTION_HEIGHT};
  box-shadow: 0.2rem 0.2rem 1.5rem -0.75rem rgba(0, 0, 0, 0.5);
  padding: 0.9rem 1rem;
`;

export const SectionHeaderDiv = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 0 0.5rem;

  h3,
  h2 {
    margin: 0;
    font-size: 1.1rem;
    letter-spacing: 0.02rem;
    color: ${({ theme }) => theme.palette.navGreyColor};
    text-transform: uppercase;
  }
  h3 {
    font-family: EavesBold;
  }
  h2 {
    font-family: EavesRegular;
  }
`;

export const IntervalButtonsDiv = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.3rem;
`;

interface IntervalButtonProps {
  $active: boolean;
}

export const IntervalButton = styled.button<IntervalButtonProps>`
  flex-grow: 1;
  margin: 0 0.25rem;
  background: ${({ theme, $active }) => ($active ? `${theme.palette.purple} !important` : theme.palette.lighterGrey)};
  color: ${({ theme, $active }) => ($active ? 'white' : theme.palette.navGreyColor)};
  border: none;
  font-size: 0.8rem;

  &:first-child {
    margin-left: 0;
  }
  &:last-child {
    margin-right: 0;
  }

  &:hover {
    background: ${({ theme }) => darken(0.025, theme.palette.lighterGrey)};
  }
`;
