import React from 'react';
import { SideNavTitle, SideNavWrapperDiv } from './styles';

export const sideNavChildren = {
  credentials() {
    return (
      <SideNavWrapperDiv>
        <SideNavTitle>Credentials</SideNavTitle>
        <h1>Configuration</h1>
        <p>
          Load your <b>certificate</b> details here
        </p>
        <p>
          Read our help documentation{' '}
          <span>
            <a href="/">here</a>
          </span>
        </p>
      </SideNavWrapperDiv>
    );
  },

  apiKeys() {
    return (
      <SideNavWrapperDiv>
        <SideNavTitle>Api Keys</SideNavTitle>
        <h1>Configuration</h1>
        <p>Create api keys for the sdk and admin panel</p>
        <p>
          Read our help documentation{' '}
          <span>
            <a href="/">here</a>
          </span>
        </p>
      </SideNavWrapperDiv>
    );
  },

  tags() {
    return (
      <SideNavWrapperDiv>
        <SideNavTitle>Tags</SideNavTitle>
        <p>View all your tags here.</p>
        <p>
          Read our help documentation{' '}
          <span>
            <a href="https://docs.pushologies.com/docs/segment-rules#tags">TAGS</a>
          </span>
        </p>
      </SideNavWrapperDiv>
    );
  },

  users() {
    return (
      <SideNavWrapperDiv>
        <SideNavTitle>Users</SideNavTitle>
        <p>View all your users here.</p>
        <p>
          Read our help documentation{' '}
          <span>
            <a href="https://docs.pushologies.com/reference/get_users">USERS</a>
          </span>
        </p>
      </SideNavWrapperDiv>
    );
  },

  forgotPassword() {
    return (
      <SideNavWrapperDiv>
        <SideNavTitle>Reset Password</SideNavTitle>
        <p>Verification code will be sent to the email you provided if it is already registered</p>
      </SideNavWrapperDiv>
    );
  },

  register() {
    return (
      <SideNavWrapperDiv>
        <SideNavTitle>Register</SideNavTitle>
        <p>Use the temporary password provided in the signup email you should have recieved from us</p>
      </SideNavWrapperDiv>
    );
  },

  segments() {
    return (
      <SideNavWrapperDiv>
        <SideNavTitle>Segments</SideNavTitle>
        <p>
          Read our help documentation{' '}
          <a href="https://docs.pushologies.com/docs/creating-segments" target="_blank">
            segments
          </a>
        </p>
      </SideNavWrapperDiv>
    );
  },

  pushNotification() {
    return (
      <SideNavWrapperDiv>
        <SideNavTitle>Create Notifications</SideNavTitle>
        <p>Create either a Standard, Video or Carousel notification.</p>
        <p>
          Read our help documentation{' '}
          <a href="https://docs.pushologies.com/docs/pushing-notifications" target="_blank">
            notifications
          </a>
        </p>
      </SideNavWrapperDiv>
    );
  },

  listNotifications() {
    return (
      <SideNavWrapperDiv>
        <SideNavTitle>Notification List</SideNavTitle>
        <p>View all your notifications from here.</p>
        <p>
          Read our help documentation{' '}
          <a href="https://docs.pushologies.com/docs/pushing-notifications" target="_blank">
            notifications
          </a>
        </p>
      </SideNavWrapperDiv>
    );
  },

  notificationResults() {
    return (
      <SideNavWrapperDiv>
        <SideNavTitle>Notification Report</SideNavTitle>
        <p>View all your notification results from here.</p>
      </SideNavWrapperDiv>
    );
  },

  listPolls() {
    return (
      <SideNavWrapperDiv>
        <SideNavTitle>Poll List</SideNavTitle>
        <p>View all your polls from here.</p>
      </SideNavWrapperDiv>
    );
  },

  subscriberExplorer() {
    return (
      <SideNavWrapperDiv>
        <SideNavTitle>Subscriber Explorer</SideNavTitle>
        <p>Search </p>
        <p>
          Read our help documentation{' '}
          <a href="https://docs.pushologies.com/docs/subscribers" target="_blank">
            subscribers
          </a>
        </p>
      </SideNavWrapperDiv>
    );
  },
  mediaLibrary() {
    return (
      <SideNavWrapperDiv>
        <SideNavTitle>Media Library</SideNavTitle>
        <p>Manage your media</p>
        <p>
          Read our help documentation <a href="https://docs.pushologies.com/docs/uploading-content">Media Management</a>
        </p>
      </SideNavWrapperDiv>
    );
  },
  geofences() {
    return (
      <SideNavWrapperDiv>
        <SideNavTitle>Geofences</SideNavTitle>
        <p>A list of all geofences ordered by most recently created</p>
      </SideNavWrapperDiv>
    );
  }
};
