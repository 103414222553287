import styled from 'styled-components';
import { rgba } from 'polished';
import { HideScrollbars } from '~styles/mixins';
import { Dots } from '~components/styled/loaders';
import CrossIcon from '~images/cross.svg';
import { InputSection } from '../styles';

export const StyledInputSection = styled(InputSection)`
  position: relative;
  min-height: 2.4rem;
  flex-direction: column;

  section {
    display: flex;
    align-items: center;
    width: 100%;

    input {
      height: unset;
    }
  }

  figure {
    position: relative;
    display: block;
    width: 100%;
    overflow-x: scroll;
    margin: 0 0 0.2rem 0;
    white-space: nowrap;
    ${HideScrollbars}

    span {
      border: 1px solid ${({ theme }) => theme.palette.primaryPlum};
      background: ${({ theme }) => theme.palette.primaryMustard};
      color: ${({ theme }) => theme.palette.primaryPlum};
      display: inline-flex;
      align-items: center;
      padding: 0 0.25rem;
      margin: 0 0.1rem;
      height: 1.3rem;
      font-size: 0.75rem;

      svg {
        fill: ${({ theme }) => theme.palette.primaryPlum};
        width: 0.7rem;
        margin-left: 0.25rem;
        cursor: pointer;

        &:hover,
        &:focus,
        &:active {
          transform: scale(1.1);
          transform-origin: center;
          fill: ${({ theme }) => theme.palette.primaryPlum};
        }
      }
    }
  }
`;

export const StyledCrossIcon = styled(CrossIcon)`
  position: absolute;
  right: 0.25rem;
  top: 0.5rem;
  fill: ${({ theme }) => theme.palette.textGrey};
  width: 0.8rem;
  cursor: pointer;

  &:hover,
  &:focus,
  &:active {
    transform: scale(1.1);
    transform-origin: center;
    fill: ${({ theme }) => theme.palette.textColor};
  }
`;

export const StyledLoadingDots = styled(Dots)`
  position: absolute;
  width: 3rem;
  top: 0.5rem;
  right: 1rem;

  g {
    fill: ${({ theme }) => rgba(theme.palette.primaryPlum, 0.5)};
  }
`;

export const StyledSpanNonCapitalised = styled.span`
  text-transform: none;
  overflow: hidden !important;
`;
