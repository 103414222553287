import styled, { css } from 'styled-components';

interface ToggleWrapperDivProps {
  active: boolean;
  $disabled?: boolean;
}

export const ToggleWrapperDiv = styled.div<ToggleWrapperDivProps>`
  position: relative;
  display: inline-block;
  border: 1px solid ${({ theme, $disabled }) => ($disabled ? theme.palette.lightGrey : theme.palette.darkGrey)};
  width: 3rem;
  height: 1.4rem;
  background: white;
  cursor: ${({ $disabled }) => ($disabled ? 'default' : 'pointer')};

  span {
    position: absolute;
    top: 0;
    left: 0;
    width: 50%;
    height: 100%;
    background: ${({ theme, $disabled }) => ($disabled ? theme.palette.lightGrey : theme.palette.darkGrey)};
    transition: background 0.1s linear 0.15s;
    transition: transform 0.25s ease-out;

    ${({ theme, active, $disabled }) =>
      active &&
      !$disabled &&
      css`
        background: ${theme.palette.primaryMustard};
        transform: translateX(100%);
      `};
  }
`;
