import { createStore } from 'easy-peasy';
import { EASY_PEASY_STORE_VERSION } from '~helpers/constants';
import { UserModel, userModel } from './user';
import { apiKeyApi, ApiKeyApiClass } from '~api/api-keys';
import { ApiKeysModel, apiKeysModel } from './api-keys';
import { SideNavModel, sideNavModel } from './sidenav';
import { notificationWidgetModel, NotificationWidgetModel } from './notification-widget';
import { segmentsApi, SegmentsApiClass } from '~api/segments';
import { segmentModel, SegmentModel } from './segment';
import { subscriberApi, SubscribersApiClass } from '~api/subscribers';
import { subscriberModel, SubscriberModel } from './subscribers';
import { createSegmentModel, CreateSegmentModel } from './create-segment';
import { credentialsApi, CredentialsApiClass } from '~api/credentials';
import { credentialsModel, CredentialsModel } from './credentials';
import { notificationsApi, NotificationsApiClass } from '~api/notifications';
import { createNotificationModel, CreateNotificationModel } from './create-notification';
import { notificationsModel, NotificationsModel } from './notifications';
import { pollsModel, PollsModel } from './polls';
import { contentItemsApi, ContentItemsApiClass } from '~api/content-items';
import { contentItemsModel, ContentItemsModel } from './content-items';
import { usersApi, UsersApiClass } from '~api/users';
import { notificationResultModel, NotificationResultModel } from './notification-results';
import { usersModel, UsersModel } from './users';
import { measurementsApi, MeasurementApiClass } from '../api/measurements';
import { measurementsModel, MeasurementsModel } from './measurements';
import { tagsApi, TagsApiClass } from '~api/tags';
import { tagsModel, TagsModel } from './tags';
import { PersonsalisationVariablesApiClass, personalisationVariablesApi } from '~api/personalisation-variables';
import { PersonalisationVariablesModel, personalisationVariablesModel } from './personalisation-variables';
import { pollingApi, PollingApiClass } from '~api/polling';
import { childTenantsApi, ChildTenantsApiClass } from '~api/child-tenants';
import { childTenantsModel, ChildTenantsModel } from './child-tenants';
import { tenantApi, TenantApiClass } from '~api/tenant';
import { TenantModel, tenantModel } from './tenant';
import { GeofencesApiClass, geofencesApi } from '~api/geofences';
import { geofencesModel, GeofencesModel } from '~store/geofences';

export interface Injections {
  apiKeyApi: ApiKeyApiClass;
  credentialsApi: CredentialsApiClass;
  segmentsApi: SegmentsApiClass;
  notificationsApi: NotificationsApiClass;
  pollingApi: PollingApiClass;
  contentItemsApi: ContentItemsApiClass;
  usersApi: UsersApiClass;
  measurementsApi: MeasurementApiClass;
  subscriberApi: SubscribersApiClass;
  tagsApi: TagsApiClass;
  childTenantsApi: ChildTenantsApiClass;
  personalisationVariablesApi: PersonsalisationVariablesApiClass;
  tenantApi: TenantApiClass;
  geofencesApi: GeofencesApiClass;
}

export interface StoreModel {
  user: UserModel;
  apiKeys: ApiKeysModel;
  sideNav: SideNavModel;
  credentials: CredentialsModel;
  notificationWidget: NotificationWidgetModel;
  segments: SegmentModel;
  createNotification: CreateNotificationModel;
  createSegment: CreateSegmentModel;
  contentItems: ContentItemsModel;
  notifications: NotificationsModel;
  polls: PollsModel;
  users: UsersModel;
  subscribers: SubscriberModel;
  notificationResult: NotificationResultModel;
  measurements: MeasurementsModel;
  tags: TagsModel;
  childTenants: ChildTenantsModel;
  personalisationVariables: PersonalisationVariablesModel;
  tenant: TenantModel;
  geofences: GeofencesModel;
}

export const storeModel: StoreModel = {
  user: userModel,
  apiKeys: apiKeysModel,
  sideNav: sideNavModel,
  credentials: credentialsModel,
  notificationWidget: notificationWidgetModel,
  segments: segmentModel,
  createNotification: createNotificationModel,
  createSegment: createSegmentModel,
  contentItems: contentItemsModel,
  notifications: notificationsModel,
  polls: pollsModel,
  users: usersModel,
  subscribers: subscriberModel,
  notificationResult: notificationResultModel,
  measurements: measurementsModel,
  tags: tagsModel,
  personalisationVariables: personalisationVariablesModel,
  childTenants: childTenantsModel,
  tenant: tenantModel,
  geofences: geofencesModel
};

export const injections = {
  apiKeyApi,
  credentialsApi,
  segmentsApi,
  notificationsApi,
  pollingApi,
  contentItemsApi,
  usersApi,
  subscriberApi,
  measurementsApi,
  tagsApi,
  personalisationVariablesApi,
  childTenantsApi,
  tenantApi,
  geofencesApi
};

export const store = createStore<StoreModel, {}, Injections>(storeModel, {
  name: 'PushPortal',
  injections,
  version: EASY_PEASY_STORE_VERSION
});
