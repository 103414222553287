import React from 'react';
import { Button } from 'react-bootstrap';
import { Link, Redirect } from 'react-router-dom';
import { useStoreState } from '~store/hooks';
import { StyledContainer } from './styles';

const ErrorPage: React.FC = () => {
  const isAuthenticated = useStoreState((state) => state.user.isAuthenticated);

  return !isAuthenticated ? (
    <Redirect to={{ pathname: '/login' }} />
  ) : (
    <StyledContainer $sideNavVisible={false}>
      <h2>Page Not Found</h2>
      <Link to="/">
        <Button variant="primary" size="lg">
          Return to Dashboard
        </Button>
      </Link>
    </StyledContainer>
  );
};

export default ErrorPage;
