import styled from 'styled-components';

export const TabsSection = styled.section`
  position: relative;
  border: 1px solid ${({ theme }) => theme.palette.textGrey};

  nav.nav {
    border-bottom: 1px solid ${({ theme }) => theme.palette.textGrey};
    background: ${({ theme }) => theme.palette.faintGrey};

    a.nav-item {
      display: flex;
      align-items: center;
      border-radius: 0;
      border: none;
      border-right: 1px solid ${({ theme }) => theme.palette.textGrey};
      border-bottom: 1px solid ${({ theme }) => theme.palette.textGrey};
      height: 1.75rem;
      padding: 0 1rem;
      background: ${({ theme }) => theme.palette.lightGrey};
      color: ${({ theme }) => theme.palette.textColor};
      text-transform: uppercase;
      font-family: EavesRegular;
      font-size: 0.9rem;
      flex-grow: 1;

      &.active {
        background: ${({ theme }) => theme.palette.primaryPlum};
        color: white;
      }
    }
  }
`;
