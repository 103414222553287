import styled from 'styled-components';
import { InputSection } from '../styles';

export const NumberTimeFieldSection = styled(InputSection)`
  position: relative;
  justify-content: unset;

  label {
    margin: 0 0.1rem 0 0;
  }

  span.separator {
    color: ${({ theme }) => theme.palette.primaryPlum};
    font-family: EavesRegular;
    font-size: 1.1rem;
    letter-spacing: 0;
  }

  span.back,
  span.forward {
    position: absolute;
    right: 0.25rem;
    top: calc(50% - 0.4rem);
    width: 0.7rem;
    height: 0.6rem;
    background: transparent;
    cursor: pointer;
  }

  span.back {
    transform: translateX(-120%);
    border-right: 0.6rem solid ${({ theme }) => theme.palette.primaryMustard};
    border-top: 0.35rem solid transparent;
    border-bottom: 0.35rem solid transparent;
  }

  span.forward {
    border-left: 0.6rem solid ${({ theme }) => theme.palette.primaryMustard};
    border-top: 0.35rem solid transparent;
    border-bottom: 0.35rem solid transparent;
  }
`;

export const InputP = styled.p`
  margin: 0;

  .wrapper {
    position: relative;

    input {
      position: absolute;
      width: 100%;
      left: 0;
      top: 0;
      border: none;
      color: ${({ theme }) => theme.palette.primaryPlum};
      font-family: EavesRegular;
      line-height: 1rem;
    }

    .width-machine {
      padding: 0 0.1rem;
      position: relative;
      color: ${({ theme }) => theme.palette.primaryPlum};
      font-family: EavesRegular;
      font-size: 1.1rem;
      line-height: 1rem;
    }
  }
`;
