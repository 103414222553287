import Auth from '@aws-amplify/auth';
import { thunk, action, computed, persist, actionOn } from 'easy-peasy';
import { instance } from '~api/tools/axios-instance';
import { store } from '..';
import { UserModel } from './types';
import { cognitoUserAttrToStore, hydrateApiAuth } from './helpers';

export * from './types';

const PASSWORD_RESET_MESSAGE =
  'Your password reset request has been processed. If the email address provided is valid, you will receive an email with instructions on how to reset your password';
const LOGIN_ERROR_MESSAGE = 'Incorrect username or password';

export const userModel: UserModel = persist(
  {
    loading: false,
    error: null,
    user: null,
    isAuthenticated: computed((state) => !!state.user),

    setUserModel: action((state, payload) => {
      Object.assign(state, payload);
    }),

    onSetUserModel: actionOn(
      (actions) => actions.setUserModel,
      (state, currentAction) => {
        if (!!state.user && currentAction.payload.user === null) {
          store.persist.clear().then(() => {
            window.sessionStorage.clear();
            window.localStorage.clear();
            window.location.reload();
          });
        }
      }
    ),

    checkAuth: thunk(async (actions) => {
      try {
        await Auth.currentSession();
        await hydrateApiAuth();
      } catch (error) {
        actions.setUserModel({ user: null });
      }
    }),

    register: thunk(async (actions, payload, { getStoreActions }) => {
      actions.setUserModel({ loading: true });
      const { email: username, oldPassword: password, newPassword, confirmNew, onSuccess } = payload;

      if (confirmNew !== newPassword) {
        const errorMessage = 'New password and confirm password do not match';
        actions.setUserModel({
          error: errorMessage,
          loading: false
        });
        getStoreActions().notificationWidget.addNotification({
          message: errorMessage,
          type: 'error'
        });
        return;
      }

      try {
        const unconfirmedUser = await Auth.signIn({ username, password });
        if (unconfirmedUser.challengeName === 'NEW_PASSWORD_REQUIRED') {
          const cognitoUser = await Auth.completeNewPassword(unconfirmedUser, newPassword, null);
          actions.setUserModel({
            user: cognitoUserAttrToStore(cognitoUser.challengeParam.userAttributes),
            loading: false,
            error: null
          });
          await hydrateApiAuth();
          onSuccess();
          getStoreActions().notificationWidget.addNotification({
            message: 'registered successfully',
            type: 'info'
          });
        }
      } catch (error) {
        actions.setUserModel({ error: error.message, loading: false });
        getStoreActions().notificationWidget.addNotification({
          message: error.message,
          type: 'error'
        });
      }
    }),

    login: thunk(async (actions, payload, { getStoreActions }) => {
      actions.setUserModel({ loading: true });
      const { email, password, onSuccess } = payload;

      // ensure our username value is lowercase - stops e-mail addresses from being case-sensitive
      const username = email.toLowerCase();

      try {
        const cognitoUser = await Auth.signIn({ username, password });
        const user = cognitoUserAttrToStore(cognitoUser.attributes);
        actions.setUserModel({
          user,
          loading: false,
          error: null
        });
        await hydrateApiAuth();
        onSuccess();
        getStoreActions().notificationWidget.addNotification({
          message: 'successfully logged in',
          type: 'info'
        });
      } catch (error) {
        actions.setUserModel({ error: LOGIN_ERROR_MESSAGE, loading: false });
        getStoreActions().notificationWidget.addNotification({
          message: LOGIN_ERROR_MESSAGE,
          type: 'error'
        });
      }
    }),

    logout: thunk(async (actions, _, { getStoreActions, getState }) => {
      try {
        const { user } = getState();
        await Auth.signOut({ global: true });
        actions.setUserModel({ user: null, loading: false, error: null });
        delete instance.defaults.headers.common.Authorization;
        getStoreActions().notificationWidget.addNotification({
          message: `successfully logged out user: ${user.name}`,
          type: 'info'
        });

        await store.persist.clear();
        window.sessionStorage.clear();
        window.localStorage.clear();
        window.location.reload();
      } catch (error) {
        actions.setUserModel({ error: error.message, loading: false });
        getStoreActions().notificationWidget.addNotification({
          message: error.message,
          type: 'error'
        });
      }
    }),

    forgotPassword: thunk(async (actions, payload, { getStoreActions }) => {
      actions.setUserModel({ loading: true });
      const { email, onSuccess, onError } = payload;

      try {
        await Auth.forgotPassword(email);
        actions.setUserModel({ loading: false, error: null });

        getStoreActions().notificationWidget.addNotification({
          message: PASSWORD_RESET_MESSAGE,
          type: 'info'
        });

        if (onSuccess) onSuccess();
      } catch (error) {
        actions.setUserModel({ error: PASSWORD_RESET_MESSAGE, loading: false });

        if (onError) onError(error);
      }
    }),

    forgotPasswordSubmit: thunk(async (actions, payload, { getStoreActions }) => {
      actions.setUserModel({ loading: true });
      const { email, code, newPassword, onSuccess, onError } = payload;

      try {
        await Auth.forgotPasswordSubmit(email, code, newPassword);
        actions.setUserModel({ loading: false, error: null });

        getStoreActions().notificationWidget.addNotification({
          message: 'password successfully changed',
          type: 'success'
        });

        if (onSuccess) onSuccess();
      } catch (error) {
        actions.setUserModel({ error: error.message, loading: false });

        if (onError) onError(error);
      }
    })
  },
  {
    allow: ['user'],
    storage: 'localStorage'
  }
);
