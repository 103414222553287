import React from 'react';
import { rules, operators, RuleId } from '@pushologies/segments-service/data';
import { SegmentRule } from '@pushologies/segments-service/types';
import { dateTimeFormat } from '~helpers/formats';
import { ApiSegment } from '~api/segments/types';
import { StyledDetailsTable } from './styles';

interface Props {
  segment: ApiSegment;
}

const dateBasedRules: RuleId[] = ['firstSession', 'lastSession'];

export const SegmentDetails: React.FC<Props> = ({ segment }) => {
  const renderRuleValue = (rule: SegmentRule) => {
    if (Array.isArray(rule.value)) return rule.value.join(', ');
    if (rule.ruleId === 'location' && !!rule.value.vertices) {
      return JSON.stringify(rule.value.vertices);
    }
    if (typeof rule.value === 'boolean') return rule.value.toString();
    if (typeof rule.value === 'object')
      return Object.keys(rule.value).map((key) => {
        const text = dateBasedRules.includes(rule.ruleId) ? dateTimeFormat(rule.value[key]) : rule.value[key];
        return (
          <p key={key}>
            <i>{key}</i>: {text}
          </p>
        );
      });
    return rule.value;
  };

  return (
    <StyledDetailsTable>
      <thead>
        <tr>
          <th>Rule</th>
          <th>Operator</th>
          <th>Value</th>
        </tr>
      </thead>
      <tbody>
        {segment?.rules?.map((rule) => (
          <tr key={rule.ruleId} data-testid="segmentSidePanelListRow">
            <td>{rules[rule.ruleId].name}</td>
            <td>{operators[rule.operator].name}</td>
            <td>
              <div>{renderRuleValue(rule)}</div>
            </td>
          </tr>
        ))}
      </tbody>
    </StyledDetailsTable>
  );
};
