import styled from 'styled-components';
import Reactour from 'reactour';
import { lighten } from 'polished';

export const StyledTour = styled(Reactour)`
  max-width: 30rem;
  padding: 1rem;
  background: white;
  border: 1px solid ${({ theme }) => theme.palette.primaryMustard};
  color: ${({ theme }) => theme.palette.navGreyColor};
  border-radius: 0;
  font-family: EavesRegular;
  text-transform: uppercase;
  box-shadow: 9px 15px 30px -20px rgb(0 0 0 / 75%);
  font-size: 0.8rem;
  line-height: 0.8rem;

  button.reactour__close {
    top: 0.1rem;
    right: 0.2rem;

    svg {
      path {
        fill: ${({ theme }) => theme.palette.primaryPlum};
      }
    }

    &:hover {
      svg path {
        fill: ${({ theme }) => lighten(0.3, theme.palette.primaryPlum)};
      }
    }
  }
`;

export const TourDiv = styled.div.attrs({
  'data-testid': 'reactTour'
})`
  b {
    font-family: EavesBold;
  }

  a {
    color: darkblue !important;
    cursor: pointer;

    &:hover {
      text-decoration: underline !important;
    }
  }

  section {
    display: block;
    width: 100%;
    padding-top: 50%; /* hack to keep rectangle aspect ratio */
    background-size: contain !important;
    background-repeat: no-repeat !important;
    background-position: center !important;
  }
`;
