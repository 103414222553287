import React from 'react';
import { TextField } from '~components/inputs';
import MagGlassIcon from '~images/mag_glass.svg';
import { StyledFiltersRow, NameCol } from './styles';

export interface Filters {
  offset: number;
  name: string;
}

export const filtersToApi = (filters: Partial<Filters>) => ({
  ...(!!filters.offset && { offset: filters.offset }),
  ...(filters.name?.length && { name: filters.name })
});

interface Props extends Filters {
  onFilterChange(filters: Partial<Filters>): void;
  onClear(): void;
}

export const FiltersRow: React.FC<Props> = (props) => {
  const handleNameFilterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const name = event.target.value;
    props.onFilterChange({ name });
  };

  return (
    <StyledFiltersRow>
      <NameCol md={6}>
        <TextField
          name="nameFilter"
          placeholder="Search by Name"
          value={props.name}
          onChange={handleNameFilterChange}
          icon={<MagGlassIcon />}
        />
      </NameCol>
    </StyledFiltersRow>
  );
};
