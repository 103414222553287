import styled from 'styled-components';
import { InputSection } from '../styles';

export const StyledInputSection = styled(InputSection)`
  .dropdown {
    width: 50%;

    button {
      height: 1.5rem;
      margin: 0.25rem 0;
      padding: 0 0.5rem;
      font-size: 0.9rem;
      font-family: EavesBold;
      border-radius: 0 !important;
    }

    .dropdown-menu {
      padding-top: 0;
    }
  }
`;
