import React, { useCallback } from 'react';
import { Select, SelectOption } from '~components/select';
import { Rule } from '~store/create-segment/types';

interface Props {
  rule: Rule;
  invalid: boolean;
  onChange(selected: boolean): void;
}

const options: SelectOption[] = [
  {
    name: 'True',
    value: true
  },
  {
    name: 'False',
    value: false
  }
];

export const LocationPermissionSelect: React.FC<Props> = ({ rule, invalid, onChange }) => {
  const [value, setValue] = React.useState<SelectOption[]>(
    rule ? options.filter((option) => rule.segmentRule?.value === option.value) : []
  );

  const handleOnChange = useCallback((selected: SelectOption[]) => {
    setValue(selected);
    onChange(selected[0].value);
  }, []);

  return (
    <Select
      id={rule.segmentRule.ruleId}
      testId="locationPermissionSelect"
      value={value}
      options={options}
      onChange={handleOnChange}
      drop="down"
      placeholder="Location Permission"
      inValid={invalid}
    />
  );
};
