import styled from 'styled-components';
import { Row, Col } from 'react-bootstrap';
import { InputSection } from '~components/inputs/styles';
import { TableSection } from '~components/styled/table-section';
import { CopyDiv } from '~components/copy-widget/styles';

const topPadding = '1.5rem';
const topPaddings = `calc(${topPadding} * 3)`;
const inputSectionHeight = '1.75rem';

export const StyledRow = styled(Row)`
  padding: ${topPadding} 4rem;

  ${TableSection} {
    .table-responsive {
      max-height: ${(props) => `calc(
        100vh - 2.75rem - (
          ${props.theme.metrics.headerHeight} +
          ${props.theme.metrics.headerOverlayHeight} +
          ${topPaddings} +
          ${inputSectionHeight}
        )
      )`};
    }
  }
`;

export const StyledTr = styled.tr`
  ${CopyDiv} {
    justify-content: center;
  }
`;

export const StyledFiltersRow = styled(Row)`
  justify-content: flex-end;
  padding: ${topPadding} 4rem 0;
`;

export const NameCol = styled(Col)`
  display: flex;
  justify-content: space-between;
  padding: 0;

  button {
    height: ${inputSectionHeight};
    margin-right: 1rem;
    padding: 0 2.5rem;
    background: ${({ theme }) => theme.palette.lighterGrey} !important;

    &:hover {
      background: ${({ theme }) => theme.palette.textGrey} !important;
    }
  }

  ${InputSection} {
    flex-grow: 2;
    height: ${inputSectionHeight};
    min-height: unset;
  }
`;

interface DeleteSpanProps {
  isDeleting: boolean;
}

export const DeleteSpan = styled.span<DeleteSpanProps>`
  svg {
    opacity: ${({ isDeleting }) => (isDeleting ? 0 : 1)};
  }

  .spinner-border {
    position: absolute;
    top: 0.15rem;
    left: 0;
    margin: 0;
    color: ${({ theme }) => theme.palette.lighterGrey};
  }
`;
