import { SelectQueryBuilder } from 'typeorm';
import { Subscriber } from '@pushologies/database-service/db/entities/subscriber';
import { NotificationStatusRuleValue, OperatorValue as Op, SegmentRule } from '../types';
import { Rule } from '.';

export class NotificationPushStatusRule extends Rule {
  constructor(private rule: SegmentRule<NotificationStatusRuleValue>) {
    super();
  }

  updateQuery(query: SelectQueryBuilder<Subscriber>) {
    if (!this.rule.value?.notificationId?.length) return;
    if (!this.rule.value?.status?.length) return;

    const [condition, params] = this.getQueryString();
    query.innerJoin('subscriber.subscriberNotificationEvents', 'sne', condition, params);
  }

  private getQueryString(): [string, object] {
    const { notificationId, status } = this.rule.value;
    const statusParam = this.createParameterHash();

    switch (this.rule.operator) {
      case Op.in:
        return [
          `sne.notificationId = :notificationId AND sne.status IN (:...${statusParam})`,
          { notificationId, [statusParam]: status }
        ];
      case Op.notIn:
        return [
          `sne.notificationId = :notificationId AND sne.status NOT IN (:...${statusParam})`,
          { notificationId, [statusParam]: status }
        ];
      default:
        throw new Error(`operator "${this.rule.operator}" not supported`);
    }
  }
}
