import styled, { css } from 'styled-components';
import { Modal, Col } from 'react-bootstrap';
import { Orientation } from '~store/create-notification';
import { HideScrollbars } from '~styles/mixins';
import TimelineGif from '~images/tours/timeline.gif';
import { VideoTimelineDiv, PlayControlsSection } from './video-timeline/styles';
import { CanvasSection, PreviewDeviceDiv } from './device-canvas/styles';
import {
  VideoInfoDiv,
  ActiveButtonSection,
  VideoOptionsSection,
  ButtonPreviewDiv,
  TwinSection
} from './video-info/styles';

export const StyledModal = styled(Modal)`
  overflow-y: hidden !important;

  .modal-dialog {
    max-width: unset;
    min-height: unset;
    width: 97vw;
    height: 95vh;

    .modal-content {
      width: 100%;
      height: 100%;
      border: none;
      border-radius: 0;

      .modal-body {
        overflow-y: scroll;
        padding-right: 0;
        padding-left: 0;
        ${HideScrollbars}

        .container-fluid {
          .row {
            margin: 0;

            div[class^='col-md'] {
              &:first-child {
                padding-left: 0;
              }
            }
          }
        }
      }
    }
  }
`;

interface RightColProps {
  $orientation: Orientation;
}

export const RightCol = styled(Col)<RightColProps>`
  padding: 0.5rem;
  border: 1px solid ${({ theme }) => theme.palette.primaryPlum};
  display: inline-block;

  ${({ $orientation }) =>
    ($orientation === 'default' || $orientation === 'portrait') &&
    css`
      display: flex;
      justify-content: space-between;

      ${CanvasSection} {
        display: inline-block !important;
        height: 87.5% !important;
        width: 55% !important;

        ${PreviewDeviceDiv} {
          padding-left: 35%;
          padding-bottom: 0;
        }
      }
      ${PreviewDeviceDiv} {
        figure {
          width: 70%;
        }
      }

      ${VideoTimelineDiv} {
        position: absolute;
        width: calc(100% - 1rem);
        bottom: 0;

        ${PlayControlsSection} {
          width: 40%;
          right: 0;
          top: -2rem;
        }
      }

      ${VideoInfoDiv} {
        display: inline-block;
        width: calc(47% - 2rem);
        height: 70%;

        ${ActiveButtonSection} {
          flex-direction: column;
          width: 100%;
          height: 80%;

          .inputFieldsWrapper {
            width: 100%;

            ${TwinSection} {
              width: 100%;
              margin-bottom: 0.4rem !important;
            }
          }
          ${ButtonPreviewDiv} {
            width: 100%;
            margin-top: 0.75rem;
            margin-left: 0;
          }
        }
        ${VideoOptionsSection} {
          width: 100%;
          height: 30%;
          margin-top: 1rem;
        }
      }
    `}
`;

export const TimelineGifSection = styled.section`
  background: url(${TimelineGif});
`;
