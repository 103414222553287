import React from 'react';
import { ReactourStep } from 'reactour';
import { generateTourSelector } from '~helpers/formats';
import { TourDiv } from '~components/tour/styles';
import { TourReloadIcon } from './styles';

let goToCopy: (step: number) => void;

export const segmentSteps: ReactourStep[] = [
  {
    selector: generateTourSelector('ruleList'),
    content: ({ goTo }) => {
      goToCopy = goTo;
      return (
        <TourDiv>
          Select a rule from this list to begin creating a segment. You can choose multiple rules to add and remove them
          by clicking on a selected rule again
        </TourDiv>
      );
    },
    action: (node) => {
      node.onmouseup = () => goToCopy(1);
    }
  },
  {
    selector: generateTourSelector('rules'),
    content: ({ goTo }) => (
      <TourDiv>
        Selected rules will appear here. Each rule will have a operator dropdown to select and a custom value input to
        update
        <br />
        <i>
          To see a rule in action make sure to have selected a rule from <a onClick={() => goTo(0)}>previous step</a>.
        </i>
      </TourDiv>
    )
  },
  {
    selector: generateTourSelector('userCount'),
    content: () => (
      <TourDiv>
        To view how many subscribers are captured with your rules press the <TourReloadIcon /> icon. This will only be
        enabled either when rules are correctly filled or you have amended them
      </TourDiv>
    )
  },
  {
    selector: generateTourSelector('segmentMetadata'),
    content: () => (
      <TourDiv>
        To complete creating a segment you must give it a <b>name</b> and <b>description</b>. The <b>Dynamism</b> option
        determines whether you want to have the bucket of subscribers created now (leave this option blank) or{' '}
        <b>only once when first</b> notification is pushed ("ONE TIME"), or finally created every time for each
        notification each time it is pushed ("ALWAYS")
      </TourDiv>
    )
  },
  {
    selector: generateTourSelector('segmentSave'),
    content: () => (
      <TourDiv>
        Once the segment form has been filled in and validates, the save button will be enabled and you're ready to
        create your segment!
      </TourDiv>
    )
  }
];
