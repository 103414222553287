import React from 'react';
import { Dayjs } from 'dayjs';
import { MEASUREMENT_TYPE, MEASUREMENT_INTERVAL } from '@pushologies/database-service/db/entities/measurement';
import { useStoreActions, useStoreState } from '~store/hooks';
import dayjs from '~helpers/dayjs';
import { numberCommaFormat } from '~helpers/formats';
import { Measurement } from '~api/measurements';
import { Loader } from '~components/loader';
import {
  HelperSection,
  HelperSpan,
  NavigationBtnSpan,
  NavigationSection,
  UserGraphSection,
  GraphIntervalButtonsDiv
} from './styles';
import { UserChart, ChartMetrics, activeUsersColor, optedInColor, totalColor } from './chart';
import { IntervalButton } from '../styles';
import { INTERVAL_TO_UNIT } from '../helpers';

interface RangeType {
  to: Dayjs;
  from: Dayjs;
}

const getCustomLabel = (date: Dayjs, interval: MEASUREMENT_INTERVAL, measure: Measurement) =>
  interval === MEASUREMENT_INTERVAL.DAILY
    ? `${date.format('D MMM')}\n${numberCommaFormat(measure?.value || 0)}`
    : `${date.format('MMMM YYYY')}\n${numberCommaFormat(measure?.value || 0)}`;

const getXAxisLabel = (date: Dayjs, interval: MEASUREMENT_INTERVAL) =>
  interval === MEASUREMENT_INTERVAL.DAILY
    ? `${date.format('ddd')} ${date.format('D/M')}`
    : `${date.format('MMMM')} '${date.format('YY')}`;

export const SubscriberGraph: React.FC = () => {
  const [loading, setLoading] = React.useState(true);
  const [nextDisabled, setNextDisabled] = React.useState(true);
  const [previousDisabled, setPreviousDisabled] = React.useState(false);
  const activeInterval = useStoreState((state) => state.measurements.activeInterval);
  const unit = INTERVAL_TO_UNIT[activeInterval][1];
  const start = dayjs().utc();
  const rangeRef = React.useRef<RangeType>({
    to: start.startOf(unit),
    from: start.subtract(8, unit).startOf(unit)
  });
  const [metrics, setMetrics] = React.useState<ChartMetrics>({
    activeUsers: [],
    optedInUsers: [],
    totalUsers: [],
    xAxisLabels: []
  });
  const { fetchMeasurements, setActiveInterval } = useStoreActions((state) => state.measurements);

  const fetchMeasurementsInRange = (currentRange: RangeType) => {
    const totalUnitsBack = currentRange.to.diff(currentRange.from, unit);
    fetchMeasurements({
      to: currentRange.to.add(1, unit).endOf(unit).toDate(),
      from: currentRange.from.subtract(1, unit).startOf(unit).toDate(),
      intervals: [activeInterval],
      types: [
        MEASUREMENT_TYPE.ACTIVE_SUBSCRIBERS,
        MEASUREMENT_TYPE.ACTIVE_OPTED_IN_SUBSCRIBERS,
        MEASUREMENT_TYPE.TOTAL_SUBSCRIBERS,
        MEASUREMENT_TYPE.AVG_SESSION_DURATION
      ],
      // tslint:disable-next-line: cyclomatic-complexity
      onSuccess(measurements: Measurement[]) {
        const xAxisLabels: string[] = [];
        const activeUsers = [];
        for (let i = totalUnitsBack; i >= 1; i--) {
          const date = currentRange.to.subtract(i, unit).startOf(unit);
          const measure = measurements.find(
            ({ type, startsAt }) => type === MEASUREMENT_TYPE.ACTIVE_SUBSCRIBERS && startsAt === date.toISOString()
          );
          activeUsers.push({
            x: totalUnitsBack - i,
            y: measure?.value || 0,
            customLabel: getCustomLabel(date, activeInterval, measure)
          });
          xAxisLabels.push(getXAxisLabel(date, activeInterval));
        }

        const optedInUsers = [];
        for (let i = totalUnitsBack; i >= 1; i--) {
          const date = currentRange.to.subtract(i, unit).startOf(unit);
          const measure = measurements.find(
            ({ type, startsAt }) =>
              type === MEASUREMENT_TYPE.ACTIVE_OPTED_IN_SUBSCRIBERS && startsAt === date.toISOString()
          );
          optedInUsers.push({
            x: totalUnitsBack - i,
            y: measure?.value || 0,
            customLabel: getCustomLabel(date, activeInterval, measure)
          });
        }

        const totalUsers = [];
        for (let i = totalUnitsBack; i >= 1; i--) {
          const date = currentRange.to.subtract(i, unit).startOf(unit);
          const measure = measurements.find(
            ({ type, startsAt }) => type === MEASUREMENT_TYPE.TOTAL_SUBSCRIBERS && startsAt === date.toISOString()
          );
          totalUsers.push({
            x: totalUnitsBack - i,
            y: measure?.value || 0,
            customLabel: getCustomLabel(date, activeInterval, measure)
          });
        }

        setMetrics({ activeUsers, optedInUsers, totalUsers, xAxisLabels });
        setLoading(false);
        setNextDisabled(currentRange.to.isAfter(dayjs().subtract(7, unit)));
        setPreviousDisabled(
          currentRange.from.isBefore(
            dayjs()
              .subtract(unit === 'day' ? 1 : 3, 'year')
              .add(unit === 'day' ? 1 : 5, 'month')
          )
        );
      }
    });
  };

  const handleIntervalChange = (interval: MEASUREMENT_INTERVAL) => () => {
    if (interval === activeInterval) return;

    setLoading(true);
    setActiveInterval(interval);
  };

  const handleRangeClick = (direction: '>' | '<') => () => {
    if (direction === '>' && nextDisabled) return;
    if (direction === '<' && previousDisabled) return;

    setLoading(true);
    const newRange: RangeType = {
      to: direction === '<' ? rangeRef.current.from : rangeRef.current.to.add(8, unit),
      from: direction === '>' ? rangeRef.current.to : rangeRef.current.from.subtract(8, unit)
    };
    rangeRef.current = newRange;
    fetchMeasurementsInRange(newRange);
  };

  React.useEffect(() => {
    fetchMeasurementsInRange({
      to: start.startOf(unit),
      from: start.subtract(8, unit).startOf(unit)
    });
  }, [activeInterval]);

  return (
    <UserGraphSection>
      <Loader loading={loading} testId="sectionLoader" />
      <GraphIntervalButtonsDiv>
        {[MEASUREMENT_INTERVAL.DAILY, MEASUREMENT_INTERVAL.MONTHLY].map((interval) => (
          <IntervalButton
            key={interval}
            onClick={handleIntervalChange(interval)}
            $active={interval === activeInterval}
            data-testid={`${interval.toLowerCase()}GraphIntervalButton`}
          >
            {interval}
          </IntervalButton>
        ))}
      </GraphIntervalButtonsDiv>
      <HelperSection data-testid="userGraphHelpSection">
        <HelperSpan $color={activeUsersColor}>active users</HelperSpan>
        <HelperSpan $color={optedInColor}>opted in subscribers</HelperSpan>
        <HelperSpan $color={totalColor}>total subscribers</HelperSpan>
      </HelperSection>
      <UserChart metrics={metrics} />
      <NavigationSection>
        <NavigationBtnSpan $disabled={previousDisabled} onClick={handleRangeClick('<')} data-testid="graphNavPrev" />
        <NavigationBtnSpan $disabled={nextDisabled} onClick={handleRangeClick('>')} data-testid="graphNavNext" />
      </NavigationSection>
    </UserGraphSection>
  );
};
