import React from 'react';
import PlusIcon from '~images/plus.svg';
import { Props as TilesViewerUploadProps } from '~components/file-viewers/tiles-upload';
import { FileUploadRef } from '~components/file-upload';
import { InputSection } from '~components/inputs/styles';
import { trimText } from '~helpers/formats';
import { useClickAway } from '~hooks/click-away';
import { ContentWrapper, AddIconSpan, StyledTilesViewerUpload, Placement } from './styles';

interface Props<T> extends Omit<TilesViewerUploadProps<T>, 'fileUploadRef'> {
  label?: string;
  value: string;
  disabled?: boolean;
  placement: Placement;
}

export const TilesViewerUploadField = React.forwardRef(
  <T extends { id: string }>(
    { label, value, disabled, placement, testId, ...rest }: React.PropsWithChildren<Props<T>>,
    ref: React.ForwardedRef<FileUploadRef>
  ) => {
    const inputSectionRef = React.useRef<HTMLElement>();
    const [show, setShow] = React.useState(false);
    const handleShowChange = () => setShow(!show);

    useClickAway(inputSectionRef, () => show && setShow(false));

    return (
      <InputSection ref={inputSectionRef} data-testid={testId}>
        {label && <label>{label}</label>}
        <ContentWrapper>
          <span className="valueSpan">{trimText(value, 30)}</span>
          <AddIconSpan id={`${testId}Open`} onClick={handleShowChange}>
            <PlusIcon />
          </AddIconSpan>
          {show && (
            <StyledTilesViewerUpload {...rest} ref={ref} $placement={placement} testId={`${testId}TilesViewerUpload`} />
          )}
        </ContentWrapper>
      </InputSection>
    );
  }
);
