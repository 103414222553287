import { AxiosInstance } from 'axios';
import { instance } from '../tools/axios-instance';
import { refreshAuthToken } from '../tools/auth.decorater';
import { ApiPersonalisationVariable, FetchPersonalisationVariableParams } from './types';

export * from './types';

export class PersonsalisationVariablesApiClass {
  constructor(private axios: AxiosInstance) {}

  @refreshAuthToken()
  async fetchSchema(params: FetchPersonalisationVariableParams): Promise<ApiPersonalisationVariable[]> {
    const response = await this.axios.get('/personalisation-schema', { params });
    return response.data.response;
  }

  @refreshAuthToken()
  async upsertVariable(personalisationVariable: ApiPersonalisationVariable): Promise<ApiPersonalisationVariable[]> {
    const response = await this.axios.post('/personalisation-schema', personalisationVariable);
    return response.data.response;
  }

  @refreshAuthToken()
  async deleteVariable(variableName: string) {
    const response = await this.axios.delete(`/personalisation-schema?name=${variableName}`);
    return response.data.response;
  }
}

export const personalisationVariablesApi = new PersonsalisationVariablesApiClass(instance);
