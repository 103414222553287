import styled from 'styled-components';
import { Button } from '~components/button';
import { ToggleField } from '~components/inputs';
import { ToggleWrapperDiv } from '~components/toggle-button/styles';
import { InputSection } from '~components/inputs/styles';
import { LocationInputSection } from '~components/inputs/location/styles';
import { FileViewerWrapperDiv, TableSection } from '~components/file-viewers/list/styles';
import BrushIcon from '~images/clear_brush.svg';
import { ColumnContentDiv } from '../styles';

export const ClearIcon = styled(BrushIcon)`
  width: 0.8rem;
  height: 1rem;

  path {
    fill: ${({ theme }) => theme.palette.primaryMustard};
  }
`;

export const SegmentsColumnContentDiv = styled(ColumnContentDiv)`
  border: 1px solid ${({ theme }) => theme.palette.platinum};

  ${FileViewerWrapperDiv} {
    border: none;
  }

  ${TableSection} {
    height: 24.5rem;
  }
`;

export const SchedulingColumnContentDiv = styled(ColumnContentDiv)`
  & > section {
    margin-bottom: 0.5rem;

    &:last-child {
      margin-bottom: 0;
    }
  }
`;

export const LabelDiv = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 0.5rem;

  span {
    color: ${({ theme }) => theme.palette.lightGrey};
    display: block !important;
    text-transform: none;
    white-space: normal;
  }

  b {
    display: block;
    text-transform: none;
    margin-top: 20px;
  }
`;

const StyledToggleField = styled(ToggleField)`
  ${ToggleWrapperDiv} {
    border: 1px solid ${({ theme, disabled }) => (disabled ? theme.palette.lightGrey : theme.palette.platinum)};
    width: 3.1rem;
    height: 1.275rem;
  }
`;

export const GeofenceSection = styled.div`
  border: 1px solid ${({ theme }) => theme.palette.lightGrey};
  margin: 0;
  margin-bottom: 0.5rem;

  ${LocationInputSection} {
    label {
      top: 0.2rem;
      left: 0.2rem;
    }
  }

  > section {
    border: none;
    margin: 0.5rem 0;
  }

  label {
    margin-right: 1.5rem;
  }

  ${StyledToggleField} {
    border: none;
  }
`;

export const StaggerSection = styled.div`
  border: 1px solid ${({ theme }) => theme.palette.lightGrey};
  margin: 0;

  > section {
    border: none;
    margin: 0.5rem 0;
  }

  #staggerInputGroup {
    width: 100%;
    & > section {
      margin-bottom: 0.5rem;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  label {
    margin-right: 1.5rem;
  }

  ${StyledToggleField} {
    border: none;
  }
`;

export const StyledInputSection = styled(InputSection)`
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;

  .react-datetime-picker {
    border: none;
  }
`;

export const InAppSection = styled(StyledInputSection)`
  flex-wrap: wrap;
  margin: 0;

  ${LocationInputSection} {
    label {
      top: 0.2rem;
      left: 0.2rem;
    }
  }

  > * {
    flex: 50%;
  }

  > section {
    border: 1px solid ${({ theme }) => theme.palette.lightGrey};
    margin: 0.5rem 0;
  }

  label {
    margin-right: 1.5rem;
  }

  select {
    border: none;
  }
`;

export const CreateButton = styled(Button)`
  width: calc(100% - 2rem);
  margin: 0.75rem auto;
  background: ${({ theme }) => theme.palette.primaryMustard} !important;
`;

export const DynamismColumnSpan = styled.span`
  width: 100%;
  text-align: center;
  text-transform: uppercase;
  font-size: 0.8rem;
`;

export { StyledToggleField };

export const StyledDisclaimer = styled.span`
  font-size: 14px;
  font-style: italic;
  padding: 5px;
`;
