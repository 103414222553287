import React from 'react';
import { CONTENT_ITEM_MEDIA_TYPE, CONTENT_ITEM_PRESENTATION_TYPE } from '@pushologies/common/constants/content-item';
import { Button } from '~components/button';
import { NumberField, TextField } from '~components/inputs';
import { Select, SelectOption } from '~components/select';
import { useStoreActions, useStoreState } from '~store/hooks';
import { ExternalContentItem } from '~api/content-items/types';
import { ExternalAssetDiv } from './styles';

interface Props {
  presetExternalFields?: Partial<ExternalContentItem>;
  disabledExternalFields?: Partial<keyof ExternalContentItem>[];
  onExternalContentAdded?: () => void;
}

const mediaTypeOptions: SelectOption<CONTENT_ITEM_MEDIA_TYPE>[] = Object.values(CONTENT_ITEM_MEDIA_TYPE).map(
  (value) => ({
    name: value,
    value
  })
);
const presentationOptions: SelectOption<CONTENT_ITEM_PRESENTATION_TYPE>[] = Object.values(
  CONTENT_ITEM_PRESENTATION_TYPE
).map((value) => ({
  name: value,
  value
}));
const DEFAULT_CONTENT_ITEM: ExternalContentItem = {
  name: null,
  size: null,
  height: null,
  width: null,
  externalSourceUrl: null,
  mediaType: null,
  presentation: null
};

export const ExternalAssetForm: React.FC<Props> = ({
  presetExternalFields,
  disabledExternalFields,
  onExternalContentAdded
}) => {
  const initialItemState = Object.assign({}, DEFAULT_CONTENT_ITEM, presetExternalFields);
  const [item, setItem] = React.useState<ExternalContentItem>(initialItemState);
  const { createExternalContentItem } = useStoreActions((state) => state.contentItems);
  const { status } = useStoreState((state) => state.contentItems);

  const formIncomplete =
    !item.name?.length ||
    !item.size ||
    !item.height ||
    !item.width ||
    !item.externalSourceUrl?.length ||
    !item.mediaType?.length ||
    !item.presentation?.length;

  const updateTextField = (name: 'name' | 'externalSourceUrl') => (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setItem((state) => ({ ...state, [name]: value }));
  };

  const updateNumberField = (name: 'size' | 'height' | 'width') => (value: number) => {
    // ensure our value is non-negative
    if (value < 0) {
      value = 0;
    }
    setItem((state) => ({ ...state, [name]: value }));
  };

  const updateSelectField = (name: 'mediaType' | 'presentation') => (options: SelectOption[]) => {
    if (name === 'mediaType') setItem((state) => ({ ...state, mediaType: options[0].value }));
    if (name === 'presentation') {
      setItem((state) => ({ ...state, presentation: options.map((option) => option.value) }));
    }
  };

  const handleSubmit = () => {
    createExternalContentItem({
      data: item,
      onSuccess() {
        setItem(initialItemState);
        onExternalContentAdded && onExternalContentAdded();
      }
    });
  };

  return (
    <ExternalAssetDiv>
      <TextField label="name" name="name" value={item.name} onChange={updateTextField('name')} />
      <TextField
        label="external url"
        name="externalSourceUrl"
        value={item.externalSourceUrl}
        onChange={updateTextField('externalSourceUrl')}
      />
      <Select
        id="mediaType"
        value={mediaTypeOptions.filter((option) => option.name === item.mediaType)}
        options={mediaTypeOptions}
        onChange={updateSelectField('mediaType')}
        placeholder="media type"
        disabled={disabledExternalFields?.includes('mediaType')}
      />
      <Select
        id="presentation"
        value={presentationOptions.filter((option) => item.presentation?.includes(option.value))}
        options={presentationOptions}
        onChange={updateSelectField('presentation')}
        placeholder="presentation"
        multi
        disabled={disabledExternalFields?.includes('presentation')}
      />
      <p>The values you enter here will impact how this content appears in your notification.</p>
      <section className="bottomInputs">
        <NumberField value={item.size} onChange={updateNumberField('size')} label="size" subLabel="(mb)" />
        <NumberField value={item.width} onChange={updateNumberField('width')} label="width" subLabel="(px)" />
        <NumberField value={item.height} onChange={updateNumberField('height')} label="height" subLabel="(px)" />
      </section>
      <Button onClick={handleSubmit} disabled={formIncomplete} loading={status === 'creatingExternal'}>
        create
      </Button>
    </ExternalAssetDiv>
  );
};
