import React, { FC, useState, useMemo } from 'react';
import dayjs from '~helpers/dayjs';
import { useStoreActions } from '~store/hooks';
import { UserFormContainer } from './styles';
import { TextField, ToggleField } from '~components/inputs';
import { DateTimePicker } from '~components/inputs/date-time-picker';
import { PromptButton } from '~components/prompt-button';
import { Select, SelectOption } from '~components/select';
import { CreateEditUserDto } from '~store/users';
import EditIcon from '~images/edit.svg';

const EMAIL_REGEX =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

interface Props {
  formType: string;
  initialData?: CreateEditUserDto;
  onClose: () => void;
}

const roleOptions: SelectOption[] = ['Admin', 'Publisher', 'Creator', 'Read Only'].map((name) => ({
  name,
  value: name
}));

export const UserForm: FC<Props> = ({ formType, initialData, onClose }) => {
  const isEditing = formType === 'edit';
  const [sendImmediately, setSendImmediately] = useState(true);
  const [userData, setUserData] = useState<CreateEditUserDto>(Object.assign({}, initialData));
  const { editUser, addUser } = useStoreActions((state) => state.users);

  const handleChange = (field: string) => (event: any) => {
    const value = event?.target?.value ?? event;
    setUserData({ ...userData, [field]: Array.isArray(value) ? value[0].value : value });
  };

  const onSubmit = async () => {
    if (formType === 'edit') {
      await editUser({ user: userData, onSuccess: onClose });
    } else {
      await addUser({ user: userData, onSuccess: onClose });
      setUserData({} as CreateEditUserDto);
    }
  };

  const toggleInviteScheduling = () => {
    setSendImmediately(!sendImmediately);
    setUserData({
      ...userData,
      inviteScheduledAt: sendImmediately ? dayjs().add(1, 'day').startOf('hour').toDate() : undefined
    });
  };

  const isValid = useMemo(() => {
    const nameIsValid = !!userData.name;
    const emailIsValid = String(userData.email).toLowerCase().match(EMAIL_REGEX);
    const roleIsValid = roleOptions.findIndex(({ value }) => value === userData.role) >= 0;
    const inviteScheduledAtIsValid = !userData.inviteScheduledAt || userData.inviteScheduledAt > new Date();

    return nameIsValid && emailIsValid && roleIsValid && inviteScheduledAtIsValid;
  }, [userData]);

  return (
    <PromptButton
      buttonType={isEditing ? 'icon' : 'button'}
      buttonText={isEditing ? undefined : 'create'}
      buttonIcon={isEditing ? <EditIcon /> : undefined}
      onConfirm={onSubmit}
      confirmButtonText={isEditing ? 'save' : 'create'}
      promptTitle={isEditing ? 'Edit User' : 'Create User'}
      disableConfirm={!isValid}
      testId={isEditing ? 'editButton' : 'createButton'}
    >
      <UserFormContainer>
        <p>
          {isEditing
            ? `Update your user's details.`
            : `Enter your user's details to provide them with access to your account.`}
        </p>
        <TextField label="E-mail address" name={'email'} value={userData.email} onChange={handleChange('email')} />
        <TextField label="Full name" name={'name'} value={userData.name} onChange={handleChange('name')} />
        <Select
          id={'asas'}
          label="Role"
          value={roleOptions.filter(({ value }) => value === userData.role)}
          options={roleOptions}
          onChange={handleChange('role')}
        />

        {!isEditing && (
          <ToggleField
            id={'sendImmediately'}
            label={'Invite immediately'}
            value={sendImmediately}
            onChange={toggleInviteScheduling}
          />
        )}

        {!sendImmediately && (
          <DateTimePicker
            testId={'user-form-schedule-invite-date-picker'}
            label={'Schedule Invitation'}
            value={userData.inviteScheduledAt}
            onChange={handleChange('inviteScheduledAt')}
          />
        )}
      </UserFormContainer>
    </PromptButton>
  );
};
