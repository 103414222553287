import React from 'react';
import { Container, Modal, Row } from 'react-bootstrap';
import { v4 as uuid } from 'uuid';
import { PersonalisationConfigItemType } from '@pushologies/database-service/db/entities/notification';
import {
  ComplexModifiersType,
  StringModifiersType,
  complexModifiers,
  stringModifiers
} from '@pushologies/common/helpers/string-modifiers';
import { useStoreActions, useStoreState } from '~store/hooks';
import { Select } from '~components/select';
import { Button } from '~components/button';
import { SelectOption } from '~components/select';
import { modifierSelectOptions } from '../data';
import { isPersonalisationConfigComplete } from '../helpers';
import {
  CanvasCol,
  StyledP,
  OptionsCol,
  ButtonsCol,
  StyledFormHeader,
  StyledAddNewButton,
  StyledPersonalisationPreview
} from './styles';
import EditIcon from '~images/edit.svg';
import DeleteIcon from '~images/delete.svg';
import { ApiPersonalisationVariable } from '~api/personalisation-variables';
import { TextField } from '~components/inputs/text-field';
import { PromptButton } from '~components/prompt-button';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { RoleGuard } from '../../../../role-guard';

interface Props {
  personalisation?: PersonalisationConfigItemType;
  personalisationId?: string;
  show: boolean;
  onHide(): void;
  onInsertPersonalisation(id: string, config: PersonalisationConfigItemType): void;
  onUpdatePersonalisation(id: string, config: PersonalisationConfigItemType): void;
}

// tslint:disable:cyclomatic-complexity
export const PersonalisationModal: React.FC<Props> = (props) => {
  const { loading, personalisationVariables } = useStoreState((state) => state.personalisationVariables);
  const { fetchPersonalisationSchema, upsertPersonalisationVariable, deletePersonalisationVariable } = useStoreActions(
    (state) => state.personalisationVariables
  );
  const [config, setConfig] = React.useState<PersonalisationConfigItemType>();
  const [selectedVariable, setSelectedVariable] = React.useState<ApiPersonalisationVariable>();
  const [createEditVariableData, setCreateEditVariableData] = React.useState<ApiPersonalisationVariable | undefined>();
  const isVariableNameInvalid =
    createEditVariableData && (!createEditVariableData?.name || !/^[A-Za-z0-9_-]*$/.test(createEditVariableData?.name));
  const isDefaultValueNotSet = createEditVariableData && !createEditVariableData?.defaultValue;
  const { hidePersonalisationDateFormat } = useFlags();

  const setConfigField = (field: keyof PersonalisationConfigItemType, value: any) => () => {
    setConfig({
      ...config,
      [field]: value
    });
  };
  const handleVariableSelect = (personalisationVariable: ApiPersonalisationVariable) => {
    if (createEditVariableData?.name) return;
    setConfig({
      name: personalisationVariable.name,
      description: personalisationVariable.description,
      defaultValue: personalisationVariable.defaultValue
    });
    setSelectedVariable(personalisationVariable);
    setCreateEditVariableData(undefined);
  };

  const handleModifierChange = (options: SelectOption<keyof StringModifiersType | keyof ComplexModifiersType>[]) => {
    setConfigField('modifiers', [{ type: options[0].value }])();
  };

  const handleModifierConfigChange = (modifierConfigStr: string) => {
    setConfig({
      ...config,
      modifiers: [
        {
          ...config.modifiers[0],
          config: modifierConfigStr
        }
      ]
    });
  };

  const handleValueChange =
    (property: keyof ApiPersonalisationVariable) => (event: React.ChangeEvent<HTMLInputElement>) => {
      setCreateEditVariableData({ ...createEditVariableData, [property]: event.target.value });
    };

  const handleSave = async () => {
    const { name, description, defaultValue } = createEditVariableData;
    await upsertPersonalisationVariable({
      personalisationVariable: {
        name,
        description,
        defaultValue
      },
      onSuccess: fetchPersonalisationSchema
    });
    setSelectedVariable(createEditVariableData);
    setConfig({ name: createEditVariableData.name, defaultValue: createEditVariableData.defaultValue });
    setCreateEditVariableData(undefined);
  };

  const handleVariableDelete = async () => {
    const { name } = selectedVariable;
    await deletePersonalisationVariable({
      variableName: name,
      onSuccess: fetchPersonalisationSchema
    });
    setSelectedVariable(undefined);
    setCreateEditVariableData(undefined);
  };

  const handleAddNewVariable = () => {
    setCreateEditVariableData({});
    setSelectedVariable({});
    setConfig({ name: '', defaultValue: null });
  };

  const handleCloseModal = () => {
    setSelectedVariable(undefined);
    setCreateEditVariableData(undefined);
    props.onHide();
  };

  const handleInsert = () => {
    props.onInsertPersonalisation(uuid(), config);
  };

  const handleUpdate = () => {
    props.onUpdatePersonalisation(props.personalisationId, config);
  };

  React.useEffect(() => {
    if (props.show) return;
    setConfig(null);
  }, [props.show]);

  React.useEffect(() => {
    if (props.personalisation) {
      setConfig(props.personalisation);
      setSelectedVariable({ ...props.personalisation });
      return;
    }
    setConfig(null);
  }, [props.personalisation]);

  React.useEffect(() => {
    fetchPersonalisationSchema();
  }, []);

  React.useEffect(() => {
    if (selectedVariable?.name) {
      setSelectedVariable(personalisationVariables.find((eachVariable) => eachVariable.name === selectedVariable.name));
    }
  }, [personalisationVariables]);

  const variablePreview = React.useMemo(() => {
    const variableToRender = selectedVariable?.defaultValue || selectedVariable?.name;
    const { type, config: modifierConfig } = config?.modifiers?.[0] || {};

    if (!variableToRender) return;

    if (type === 'dateFormat') {
      return complexModifiers.dateFormat(new Date().toISOString(), modifierConfig);
    }

    return stringModifiers[type]?.(variableToRender) || variableToRender;
  }, [selectedVariable, config]);

  const filteredModifierSelectOptions = React.useMemo<SelectOption<keyof StringModifiersType | 'dateFormat'>[]>(() => {
    let modifiers = modifierSelectOptions || [];

    if (hidePersonalisationDateFormat) {
      modifiers = modifiers.filter((eachModifier) => eachModifier.value !== 'dateFormat');
    }

    return modifiers;
  }, [hidePersonalisationDateFormat]);

  return (
    <Modal centered show={props.show} backdrop="static" size="lg" data-testid="personalisationModal">
      <StyledFormHeader>
        Personalise Text
        <button data-testid="personalisationModalCloseBtn" onClick={handleCloseModal}>
          X
        </button>
      </StyledFormHeader>
      <Modal.Body data-tour="personalisationModal">
        <Container>
          <Row>
            <CanvasCol xs={12} md={6}>
              <section>
                {personalisationVariables.map((eachVariable, index) => (
                  <StyledP
                    $index={index}
                    $hasChildren
                    $selected={config?.name === eachVariable.name}
                    onClick={() => handleVariableSelect(eachVariable)}
                    key={eachVariable.name}
                    data-testid="personalisationModalTableOption"
                  >
                    {eachVariable.name}
                  </StyledP>
                ))}
              </section>
              <RoleGuard requiredRole="Admin">
                <StyledAddNewButton onClick={handleAddNewVariable} data-testid="personalisationModalTableAddNew">
                  + Add New
                </StyledAddNewButton>
              </RoleGuard>
            </CanvasCol>
            <OptionsCol xs={12} md={6}>
              {selectedVariable && (
                <>
                  <h1>
                    {selectedVariable?.name}{' '}
                    {!createEditVariableData && (
                      <RoleGuard requiredRole="Admin">
                        <EditIcon
                          data-testid={'personalisationVariableEdit'}
                          onClick={() => setCreateEditVariableData(selectedVariable)}
                        />
                        <PromptButton
                          buttonType="icon"
                          buttonIcon={<DeleteIcon />}
                          onConfirm={handleVariableDelete}
                          confirmButtonText="delete"
                          promptTitle="Confirm Delete"
                        >
                          Are you sure you would like to delete the variable "{selectedVariable?.name}" from your
                          schema?
                        </PromptButton>
                      </RoleGuard>
                    )}
                  </h1>
                  {!createEditVariableData ? (
                    <>
                      <p data-testid={'selectedPersonalisationVariableDescription'}>
                        {selectedVariable?.description || 'No description'}
                      </p>
                      <h2>Default Value</h2>
                      <p data-testid={'selectedPersonalisationVariableDefaultValue'}>
                        {selectedVariable?.defaultValue || 'No default value set'}
                      </p>
                      {!selectedVariable?.defaultValue?.includes('://') && (
                        <>
                          <h2>Modifiers</h2>
                          <Select
                            id="modifierSelect"
                            value={filteredModifierSelectOptions.filter(
                              ({ value }) => config?.modifiers && config.modifiers.find(({ type }) => type === value)
                            )}
                            options={filteredModifierSelectOptions}
                            onChange={handleModifierChange}
                          />
                          {(config?.modifiers?.[0]?.type as unknown as string) === 'dateFormat' && (
                            <>
                              <h2>Desired Format</h2>
                              <TextField
                                placeholder={'DD-MM-YYYY'}
                                value={config?.modifiers?.[0]?.config}
                                name="config"
                                onChange={(event) => handleModifierConfigChange(event.target.value)}
                              />
                            </>
                          )}
                          <h2>Preview (with modifier)</h2>
                          <StyledPersonalisationPreview>{variablePreview}</StyledPersonalisationPreview>
                        </>
                      )}
                    </>
                  ) : (
                    <>
                      {!selectedVariable?.name && (
                        <>
                          <h2>Variable Name</h2>
                          <TextField
                            value={createEditVariableData?.name}
                            name="name"
                            onChange={handleValueChange('name')}
                            invalid={isVariableNameInvalid}
                          />
                          {createEditVariableData?.name && isVariableNameInvalid && (
                            <p>Name can only contain letters, numbers, underscores and dashes</p>
                          )}
                        </>
                      )}
                      <h2>Description</h2>
                      <TextField
                        value={createEditVariableData?.description}
                        name="description"
                        onChange={handleValueChange('description')}
                      />
                      <h2>Default value</h2>
                      <TextField
                        value={createEditVariableData?.defaultValue}
                        name="defaultValue"
                        onChange={handleValueChange('defaultValue')}
                        invalid={isDefaultValueNotSet}
                      />
                    </>
                  )}
                </>
              )}
            </OptionsCol>
          </Row>
          <Row>
            <ButtonsCol>
              {props.personalisationId && !createEditVariableData ? (
                <Button
                  testId="personalisationModalSubmit"
                  onClick={handleUpdate}
                  disabled={!isPersonalisationConfigComplete(config)}
                >
                  Update
                </Button>
              ) : (
                <Button
                  testId="personalisationModalSubmit"
                  onClick={createEditVariableData ? handleSave : handleInsert}
                  disabled={loading || !selectedVariable || isVariableNameInvalid || isDefaultValueNotSet}
                  loading={loading}
                >
                  {createEditVariableData ? 'Save' : 'Insert'}
                </Button>
              )}
            </ButtonsCol>
          </Row>
        </Container>
      </Modal.Body>
    </Modal>
  );
};
