import { action, persist, thunk, State } from 'easy-peasy';
import { handleUnAuthenticated } from '~store/helpers';
import { arrayToObject } from '~helpers/arrays';
import { PollsModel } from './types';

export * from './types';

export const LIMIT = 20;

const initialState: State<PollsModel> = {
  loading: false,
  error: null,
  totalItems: LIMIT,
  polls: {}
};

export enum POLL_UPSERT_SUCCESS_MESSAGES {
  CREATE = 'Poll created',
  UPDATE = 'Poll updated',
  CLONE = 'Your chosen poll has been duplicated successfully'
}

export const pollsModel: PollsModel = persist(
  {
    ...initialState,

    setModel: action((state, { params, polls, ...rest }) => {
      polls && Object.assign(state.polls, arrayToObject('id', polls));
      Object.assign(state, rest);
    }),

    fetchPolls: thunk(async (actions, payload, { injections, getStoreActions }) => {
      actions.setModel({ loading: true });
      const { onSuccess, onError, ...params } = payload || {};

      try {
        const { polls, totalItems } = await injections.pollingApi.fetchPolls(params);

        actions.setModel({ loading: false, error: null, polls, totalItems, params });

        if (onSuccess) onSuccess(polls, totalItems);
      } catch (error) {
        await handleUnAuthenticated(error, getStoreActions());

        getStoreActions().notificationWidget.addNotification({
          type: 'error',
          message: error.message
        });

        actions.setModel({ loading: false, error: error.message });
        if (onError) onError(error.message);
      }
    }),

    fetchPoll: thunk(async (actions, payload, { injections, getStoreActions }) => {
      actions.setModel({ loading: true });
      const { onSuccess, onError, pollId } = payload || {};

      try {
        const pollWithResults = await injections.pollingApi.fetchPoll(pollId);

        actions.setModel({ loading: false, error: null });

        if (onSuccess) onSuccess(pollWithResults);
      } catch (error) {
        await handleUnAuthenticated(error, getStoreActions());

        getStoreActions().notificationWidget.addNotification({
          type: 'error',
          message: error.message
        });

        actions.setModel({ loading: false, error: error.message });
        if (onError) onError(error.message);
      }
    }),

    upsertPoll: thunk(async (actions, payload, { injections, getStoreActions }) => {
      actions.setModel({ loading: true });
      const { onSuccess, onError, poll, successMessage } = payload || {};

      try {
        const upsertedPoll = await injections.pollingApi.upsertPoll(poll);

        actions.setModel({ loading: false, error: null });

        getStoreActions().notificationWidget.addNotification({
          type: 'success',
          message: successMessage
        });

        if (onSuccess) onSuccess(upsertedPoll);
      } catch (error) {
        await handleUnAuthenticated(error, getStoreActions());

        getStoreActions().notificationWidget.addNotification({
          type: 'error',
          message: error.message
        });

        actions.setModel({ loading: false, error: error.message });
        if (onError) onError(error.message);
      }
    }),

    deletePoll: thunk(async (_actions, payload, { injections, getStoreActions }) => {
      const { onSuccess, onError, id } = payload;

      try {
        await injections.pollingApi.deletePoll(id);

        getStoreActions().notificationWidget.addNotification({
          type: 'success',
          message: 'Poll successfully deleted'
        });

        if (onSuccess) onSuccess();
      } catch (error) {
        await handleUnAuthenticated(error, getStoreActions());

        getStoreActions().notificationWidget.addNotification({
          type: 'error',
          message: error.message
        });

        if (onError) onError(error.message);
      }
    })
  },
  {
    allow: ['polls', 'totalItems'],
    storage: 'localStorage'
  }
);
