import { AxiosInstance } from 'axios';
import { ApiTenantWithProfile } from './types';
import { instance } from '../tools/axios-instance';
import { refreshAuthToken } from '../tools/auth.decorater';

export class TenantApiClass {
  constructor(private axios: AxiosInstance) {}

  @refreshAuthToken()
  async fetchTenant(): Promise<ApiTenantWithProfile> {
    const response = await this.axios.get(`/tenant`);

    return response.data.response;
  }
}

export const tenantApi = new TenantApiClass(instance);
