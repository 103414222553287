import React from 'react';

import { ApiGeofences } from '~api/geofences/types';
import { dateTimeFormat } from '~helpers/formats';
import { StyledTr } from './styles';
import { CopyWidget } from '~components/copy-widget';
import { ActionsTd } from '~components/styled/table-section';

import { Checkbox } from '~components/checkbox';
import { MapsPromptButton } from '~components/inputs/location/maps-prompt-button';
import { useFlags } from 'launchdarkly-react-client-sdk';

interface Props {
  geofence: ApiGeofences;
}
const ActionsColumn: React.FC<Props> = ({ geofence }) => {
  return (
    <ActionsTd>
      <MapsPromptButton
        location={{
          longitude: geofence.location.longitude,
          latitude: geofence.location.latitude,
          radius: geofence.radius,
          startDate: geofence.startDate,
          vertices: geofence.vertices,
          endDate: geofence.endDate
        }}
        readOnly={true}
      />
    </ActionsTd>
  );
};

export const GeofencesRow: React.FC<Props> = ({ geofence }) => {
  const { hideGeoFenceRepeatAdminUi } = useFlags();
  return (
    <StyledTr data-testid="geofencesRow">
      <td>
        <CopyWidget copyText={geofence.id} copyMessage="copied geofence id">
          {geofence.id}
        </CopyWidget>
      </td>
      <td>{geofence.vertices ? 'Polygon' : 'Circle'}</td>

      {!hideGeoFenceRepeatAdminUi && (
        <td>
          <Checkbox checked={geofence.repeat} />
        </td>
      )}
      <td>{geofence.trigger}</td>
      <td>{dateTimeFormat(geofence.createdAt)}</td>
      <ActionsColumn geofence={geofence} />
    </StyledTr>
  );
};
