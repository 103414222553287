import React from 'react';
import { useParams } from 'react-router-dom';
import { Col } from 'react-bootstrap';
import { SUBSCRIBER_DEVICE_TYPES } from '@pushologies/database-service/db/entities/subscriber';
import { useStoreActions } from '~store/hooks';
import {
  ApiSubscriber,
  ApiSubscriberEvent,
  ApiSubscriberNotificationEvent,
  EventGroup,
  isNotificationEvent
} from '~api/subscribers/types';
import { dateTimeFormat, millisecondsToTime, numberCommaFormat } from '~helpers/formats';
import { useFileViewerOptions } from '~components/file-viewers/hooks';
import { BarChart } from '~components/bar-chart';
import { AndroidIconStyled, AppleIconStyled } from '~components/styled/icons';
import { Loader } from '~components/loader';
import { ApiTags } from '~api/tags/types';
import { EventHistoryRow, OptionsRow } from './table-rows';
import { DetailsBarsRow, DetailsTopRow, DetailsMetricsRow, EventsTileViewer, TableRow, OptionsSection } from './styles';
import { TilesViewer } from '~components/file-viewers/tiles';

const EVENT_LIMIT = 50;

// tslint:disable-next-line:cyclomatic-complexity
export const SubscriberDetail: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const [subscriber, setSubScriber] = React.useState<ApiSubscriber>(null);
  const [fetchingEvents, setFetchingEvents] = React.useState(true);
  const [fetchingNotificationEvents, setFetchingNotificationEvents] = React.useState(true);
  const [fetchingSubscriber, setFetchingSubscriber] = React.useState(true);
  const [events, setEvents] = React.useState<ApiSubscriberEvent[]>([]);
  const [notificationEvents, setNotificationEvents] = React.useState<ApiSubscriberNotificationEvent[]>([]);
  const { fetchSubscriber, fetchSubscriberEvents, fetchSubscriberNotificationEvents, deleteSubscriberTag } =
    useStoreActions((state) => state.subscribers);
  const { addNotification } = useStoreActions((state) => state.notificationWidget);
  const { options, setOptions } = useFileViewerOptions();
  const deviceIcon = {
    [SUBSCRIBER_DEVICE_TYPES.ANDROID]: <AndroidIconStyled />,
    [SUBSCRIBER_DEVICE_TYPES.IOS]: <AppleIconStyled />
  };

  const onPageChange = (offset: number) => {
    setFetchingEvents(true);
    fetchSubscriberEvents({
      subscriberId: id,
      limit: EVENT_LIMIT,
      offset,
      onSuccess(subscriberEvents, eventsTotal) {
        setEvents(subscriberEvents);
        fetchSubscriberNotificationEvents({
          subscriberId: id,
          limit: EVENT_LIMIT,
          offset,
          onSuccess(subscriberNotificationEvents, notificationEventsTotal) {
            setOptions({ totalItems: Math.max(eventsTotal, notificationEventsTotal) });
            setNotificationEvents(subscriberNotificationEvents);
            setFetchingEvents(false);
          }
        });
      }
    });
  };

  const handleDeleteTagSuccess = (tag: ApiTags) => () => {
    deleteSubscriberTag({
      subscriberId: id,
      tagId: tag.id,
      onSuccess() {
        const newSubscriber: ApiSubscriber = {
          ...subscriber,
          tags: subscriber.tags.filter((subTag) => subTag.id !== tag.id)
        };
        setSubScriber(newSubscriber);
        addNotification({
          type: 'success',
          message: `Successfully deleted subscriber tag "${tag.name}"`
        });
      }
    });
  };

  React.useEffect(() => {
    setFetchingEvents(true);
    setFetchingNotificationEvents(true);
    setFetchingSubscriber(true);

    fetchSubscriber({
      subscriberId: id,
      onSuccess: (fetchedSubscriber) => {
        setSubScriber(fetchedSubscriber);
        setFetchingSubscriber(false);
      }
    });
    fetchSubscriberEvents({
      subscriberId: id,
      limit: EVENT_LIMIT,
      offset: options.offset,
      onSuccess(subscriberEvents) {
        setEvents(subscriberEvents);
        setFetchingEvents(false);
      }
    });
    fetchSubscriberNotificationEvents({
      subscriberId: id,
      limit: EVENT_LIMIT,
      offset: options.offset,
      onSuccess(subscriberNotificationEvents, total) {
        setOptions({ totalItems: total });
        setNotificationEvents(subscriberNotificationEvents);
        setFetchingNotificationEvents(false);
      }
    });
  }, [id]);

  const allEvents = [...notificationEvents, ...events].sort(
    (a, b) => new Date(b.timestamp).getTime() - new Date(a.timestamp).getTime()
  );

  const eventList = Object.values(
    allEvents.reduce((eventsArray: { [key: string]: EventGroup }, currentEvent) => {
      if (isNotificationEvent(currentEvent)) {
        if (eventsArray[currentEvent.notificationId]) {
          eventsArray[currentEvent.notificationId].content.push(currentEvent);
        } else {
          eventsArray[currentEvent.notificationId] = {
            id: currentEvent.id,
            type: 'eventGroup',
            notificationId: currentEvent.notificationId,
            content: [currentEvent]
          };
        }
      } else {
        eventsArray[currentEvent.id] = {
          id: currentEvent.id,
          type: 'singleEvent',
          content: [currentEvent]
        };
      }
      return eventsArray;
    }, {})
  );

  return (
    <>
      <Loader loading={fetchingSubscriber} backdrop />
      <DetailsTopRow>
        <Col className="leftCol">
          <h1>{subscriber?.invalid && <span>Invalid </span>}User</h1>
          <h1>{subscriber?.id} </h1>
          {deviceIcon[subscriber?.deviceType]}
        </Col>
        <Col className="rightCol">
          <p>{subscriber?.permissionsNotification && 'subscribed'}</p>
        </Col>
        <hr />
      </DetailsTopRow>
      <DetailsBarsRow>
        <Col md="auto" className="leftCol">
          <p>Total Number Of Notifications</p>
          <section>
            {deviceIcon[subscriber?.deviceType]}
            <h6>{numberCommaFormat(subscriber?.notificationEventsSummary?.messageCount)}</h6>
          </section>
          <section>
            <p>
              <span>opened</span>
              <span>{numberCommaFormat(subscriber?.notificationEventsSummary?.opened)}</span>
            </p>
            <p>
              <span>received</span>
              <span>{numberCommaFormat(subscriber?.notificationEventsSummary?.received)}</span>
            </p>
          </section>
        </Col>
        <Col className="rightCol">
          <BarChart
            bars={[
              [
                {
                  color: ['#323d12', '#3ddc84'],
                  label: numberCommaFormat(subscriber?.notificationEventsSummary?.messageCount),
                  percentage: subscriber?.notificationEventsSummary ? 100 : 0
                }
              ]
            ]}
            label="no. of messages"
          />
          <BarChart
            bars={[
              [
                {
                  color: ['#323d12', '#3ddc84'],
                  label: numberCommaFormat(
                    subscriber?.notificationEventsSummary?.messageCount -
                      subscriber?.notificationEventsSummary?.pushFailed || 0
                  ),
                  percentage: subscriber?.notificationEventsSummary
                    ? Math.round(
                        ((subscriber.notificationEventsSummary.messageCount -
                          subscriber.notificationEventsSummary.pushFailed) /
                          subscriber.notificationEventsSummary.messageCount) *
                          100
                      )
                    : 0
                }
              ]
            ]}
            label="sent"
          />
          <BarChart
            bars={[
              [
                {
                  color: ['#323d12', '#3ddc84'],
                  label: numberCommaFormat(subscriber?.notificationEventsSummary?.received),
                  percentage: subscriber?.notificationEventsSummary
                    ? Math.round(
                        (subscriber.notificationEventsSummary.received /
                          subscriber.notificationEventsSummary.messageCount) *
                          100
                      )
                    : 0
                }
              ]
            ]}
            label="received"
          />
          <BarChart
            bars={[
              [
                {
                  color: ['#323d12', '#3ddc84'],
                  label: numberCommaFormat(subscriber?.notificationEventsSummary?.viewed),
                  percentage: subscriber?.notificationEventsSummary
                    ? Math.round(
                        (subscriber.notificationEventsSummary.viewed /
                          subscriber.notificationEventsSummary.messageCount) *
                          100
                      )
                    : 0
                }
              ]
            ]}
            label="viewed"
          />
          <BarChart
            bars={[
              [
                {
                  color: ['#323d12', '#3ddc84'],
                  label: numberCommaFormat(subscriber?.notificationEventsSummary?.downloaded),
                  percentage: subscriber?.notificationEventsSummary
                    ? Math.round(
                        (subscriber.notificationEventsSummary.downloaded /
                          subscriber.notificationEventsSummary.messageCount) *
                          100
                      )
                    : 0
                }
              ]
            ]}
            label="downloaded"
          />
          <BarChart
            bars={[
              [
                {
                  color: ['#323d12', '#3ddc84'],
                  label: numberCommaFormat(subscriber?.notificationEventsSummary?.opened),
                  percentage: subscriber?.notificationEventsSummary
                    ? Math.round(
                        (subscriber.notificationEventsSummary.opened /
                          subscriber.notificationEventsSummary.messageCount) *
                          100
                      )
                    : 0
                }
              ]
            ]}
            label="opened"
          />
          <BarChart
            bars={[
              [
                {
                  color: ['#323d12', '#3ddc84'],
                  label: numberCommaFormat(subscriber?.notificationEventsSummary?.pushFailed),
                  percentage: subscriber?.notificationEventsSummary
                    ? Math.round(
                        (subscriber.notificationEventsSummary.pushFailed /
                          subscriber.notificationEventsSummary.messageCount) *
                          100
                      )
                    : 0
                }
              ]
            ]}
            label="failed"
          />
        </Col>
      </DetailsBarsRow>
      <DetailsMetricsRow>
        <Col md={6}>
          <p>
            <span>App Version</span>
            <span>{subscriber?.appVersion}</span>
          </p>
          <p>
            <span>Sdk Version</span>
            <span>{subscriber?.sdkVersion}</span>
          </p>
          <p>
            <span>Customer ID</span>
            <span>{subscriber?.customerId}</span>
          </p>
          <p>
            <span>Notification Permission</span>
            <span>{subscriber?.permissionsNotification ? 'Enabled' : 'Disabled'}</span>
          </p>
          <p>
            <span>Location Permission</span>
            <span>{subscriber?.permissionsLocation ? 'Enabled' : 'Disabled'}</span>
          </p>
          <p>
            <span>OS</span>
            <span>
              {subscriber?.deviceType} {subscriber?.deviceVersion}
            </span>
          </p>
          <p>
            <span>Session Count</span>
            <span>{subscriber?.sessionCount}</span>
          </p>
          <p>
            <span>First seen / Create time</span>
            <span>{dateTimeFormat(subscriber?.createdAt, 'MMMM Do YYYY  HH:mm:ss')}</span>
          </p>
          <p>
            <span>Last Seen</span>
            <span>{dateTimeFormat(subscriber?.lastSeen, 'MMMM Do YYYY  HH:mm:ss')}</span>
          </p>
        </Col>
        <Col md={6}>
          <p>
            <span>Device</span>
            <span>{subscriber?.deviceModelIdentifier}</span>
          </p>
          <p>
            <span>Average Session Duration (HH:MM:SS)</span>
            <span>{millisecondsToTime(subscriber?.averageSessionDuration)?.text}</span>
          </p>
          <p>
            <span>Total Session Duration (HH:MM:SS)</span>
            <span>{millisecondsToTime(subscriber?.totalSessionDuration)?.text}</span>
          </p>
          <p>
            <span>Current Location</span>
            <span>{subscriber?.countryCode}</span>
          </p>
          <p>
            <span>Most Recent City</span>
            <span>{subscriber?.cityName}</span>
          </p>
          <p>
            <span>Timezone</span>
            <span>{subscriber?.timezone}</span>
          </p>
          <p>
            <span>Language</span>
            <span>{subscriber?.deviceLanguage}</span>
          </p>
          <p>
            <span>Connection Type</span>
            <span>{subscriber?.connectionType}</span>
          </p>
          <p>
            <span>Country</span>
            <span>{subscriber?.countryCode}</span>
          </p>
        </Col>
      </DetailsMetricsRow>
      <TableRow>
        <Col md={6}>
          <OptionsSection>
            <TilesViewer<ApiTags>
              testId="tagsViewer"
              title="Tags"
              files={subscriber?.tags || []}
              renderTile={(item) => <OptionsRow option={item} handleDeleteTagDelete={handleDeleteTagSuccess(item)} />}
            />
          </OptionsSection>
        </Col>
        <Col md={6}>
          <EventsTileViewer
            testId="eventHistoryViewer"
            title="Event History"
            files={eventList}
            renderTile={(item: EventGroup, _, i) => <EventHistoryRow event={item} index={i} key={id} />}
            pagination={{
              limit: EVENT_LIMIT,
              offset: options.offset,
              totalItems: options.totalItems,
              onPageChange
            }}
            loading={fetchingEvents || fetchingNotificationEvents}
          />
        </Col>
      </TableRow>
    </>
  );
};
