import React from 'react';
import { Redirect, Switch, useRouteMatch } from 'react-router-dom';
import { PrivateRoute } from 'src/routes/private-route';
import { CreateNotification } from './create';
import { ListNotifications } from './list';
import { ViewNotificationResults } from './results';
import { NOTIFICATION_TYPE } from '@pushologies/database-service/db/entities/notification';
import { useStoreState } from '~store/hooks';
import { userHasRequiredRole } from '../../role-guard';

interface Props {
  type?: NOTIFICATION_TYPE;
}

export const Notifications: React.FC<Props> = ({ type }) => {
  const { path } = useRouteMatch();
  const tenant = useStoreState((state) => state.tenant.tenant);

  // we only want to render the notifications /create route if the user is a creator
  // there is a problem where the user will start with no role, causing us to hit the /:id route
  // we should not try and render routes until we have fetched the role
  const userRole = tenant?.userProfile?.role?.name;
  if (!userRole) return <></>;
  const isCreator = userHasRequiredRole(userRole, 'Creator');

  return (
    <Switch>
      <PrivateRoute exact path={path}>
        <ListNotifications type={type} />
      </PrivateRoute>
      {isCreator && (
        <PrivateRoute exact path={`${path}/create`}>
          <CreateNotification />
        </PrivateRoute>
      )}
      {isCreator && (
        <PrivateRoute path={`${path}/create/:id`}>
          <CreateNotification />
        </PrivateRoute>
      )}
      <PrivateRoute exact path={`${path}/:id`}>
        <ViewNotificationResults />
      </PrivateRoute>
      <PrivateRoute exact path={`${path}/:id/:tenant`}>
        <ViewNotificationResults />
      </PrivateRoute>
      <Redirect to="/404" />
    </Switch>
  );
};
