import { Entity, PrimaryGeneratedColumn, Column, CreateDateColumn, OneToMany, ManyToOne, Index } from 'typeorm';
import { Notification } from './notification';
import { Tenant } from './tenant';

@Entity('campaign')
export class Campaign {
  @PrimaryGeneratedColumn('uuid')
  id: string;

  @ManyToOne((_) => Tenant, (tenant) => tenant.campaigns, { onDelete: 'CASCADE' })
  @Index()
  tenant: Tenant;

  @Column({ type: 'text' })
  name: string;

  @Column({ type: 'text', nullable: true })
  description: string;

  @CreateDateColumn({ name: 'created_at', type: 'timestamptz' })
  createdAt: Date;

  @OneToMany((_) => Notification, (notification) => notification.campaign)
  notifications: Notification[];
}
