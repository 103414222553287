import styled, { css } from 'styled-components';
import { Row } from 'react-bootstrap';
import { StyledForm as Form } from '~components/styled/form';
import Eye from '~images/eye.svg';

export const StyledRow = styled(Row)`
  margin-top: 7rem;
`;

export const LogoWrapperDiv = styled.div`
  width: 45rem;
  display: flex;
  justify-content: center;

  svg {
    width: 60%;
    margin-bottom: 3rem;
  }
`;

export const StyledEye = styled(Eye)`
  width: 1.5rem;
  height: 1.5rem;
  margin-top: -0.2rem;
  float: right;
  cursor: pointer;
  position: absolute;
  right: 40px;
  top: 53%;

  path {
    fill: ${({ theme }) => theme.palette.lightGrey};
  }

  &:hover {
    filter: invert(0.5) sepia(1) hue-rotate(600deg) saturate(20) brightness(0.9);
  }

  ${({ active }) =>
    active &&
    css`
      filter: invert(0.5) sepia(1) hue-rotate(600deg) saturate(20) brightness(1) !important;
    `}
`;

export const StyledForm = styled(Form)`
  width: 45rem;
`;

export const ErrorMessageP = styled.p`
  color: red;
  width: 100%;
  text-align: center;
  margin: 16px 0 0;
`;
