import styled from 'styled-components';

export const CopyDiv = styled.div`
  position: relative;
  display: flex;

  .copy-icon {
    display: inline-block;
    position: relative;
    width: 0.9rem;
    margin: 0 0.4rem;

    svg {
      path {
        fill: ${({ theme }) => theme.palette.textGrey};
      }
    }

    &:hover {
      cursor: pointer;

      svg {
        path {
          fill: ${({ theme }) => theme.palette.primaryMustard};
        }
      }
    }

    .toast {
      min-width: 140px;
      position: absolute;
      right: -3.3rem;
      top: 0.15rem;
      text-transform: uppercase;
      font-size: 0.7rem;
      padding: 0 0.2rem;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 1.1rem;
      color: ${({ theme }) => theme.palette.primaryPlum};
      border: 1px solid ${({ theme }) => theme.palette.primaryPlum};
    }
  }

  p.note {
    color: red;
    font-size: 0.8rem;
    position: absolute;
    text-transform: none;
    bottom: -0.8rem;
  }
`;
