import styled, { css } from 'styled-components';

interface LoaderDivProps {
  $backdrop: boolean;
}

export const LoaderDiv = styled.div<LoaderDivProps>`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  background: transparent;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  z-index: 200;

  .spinner-border {
    border-color: ${({ theme }) => theme.palette.primaryPlum};
    border-right-color: transparent;
  }

  ${({ $backdrop }) =>
    $backdrop &&
    css`
      &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        background: rgba(255, 255, 255, 0.7);
        width: 100%;
        height: 100%;
      }
    `}
`;
