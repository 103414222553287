import { AxiosInstance } from 'axios';
import { instance } from '../tools/axios-instance';
import { refreshAuthToken } from '../tools/auth.decorater';
import { FetchParams } from '../tools/types';
import { ApiTenantUser } from './types';
import { CreateEditUserDto } from '~store/users';

export class UsersApiClass {
  constructor(private axios: AxiosInstance) {}

  @refreshAuthToken()
  async fetch(
    params: FetchParams & { excludeApiKeyUsers?: boolean } = {}
  ): Promise<{ users: ApiTenantUser[]; totalItems: number }> {
    const response = await this.axios.get('/users', { params });
    return response.data.response;
  }

  @refreshAuthToken()
  async fetchUserAnalytics(): Promise<{ latestLoggedInUser: ApiTenantUser }> {
    const response = await this.axios.get('/users/analytics');
    return response.data.response;
  }

  @refreshAuthToken()
  async fetchUserAnalyticsByProxy(tenantId: string): Promise<{ latestLoggedInUser: ApiTenantUser }> {
    const {
      data: {
        response: {
          responses: [{ responseBody }]
        }
      }
    } = await this.axios.post('/proxy-call', {
      endpoint: `/v1/users/analytics`,
      method: 'GET',
      requests: [
        {
          tenantId
        }
      ]
    });

    return responseBody.response;
  }

  @refreshAuthToken()
  async delete(id: string): Promise<{ success: boolean }> {
    const response = await this.axios.delete('/user', { params: { id } });
    return response.data.response;
  }

  @refreshAuthToken()
  async add(user: CreateEditUserDto): Promise<{ success: boolean }> {
    const response = await this.axios.post('/user', user);
    return response.data.response;
  }

  @refreshAuthToken()
  async edit(user: CreateEditUserDto): Promise<{ success: boolean }> {
    const response = await this.axios.patch('/user', user);
    return response.data.response;
  }

  @refreshAuthToken()
  async reinstate(id: string): Promise<{ success: boolean }> {
    const response = await this.axios.patch(`/user/reinstate?id=${id}`);
    return response.data.response;
  }

  @refreshAuthToken()
  async redact(id: string): Promise<{ success: boolean }> {
    const response = await this.axios.patch(`/user/redact?id=${id}`);
    return response.data.response;
  }

  @refreshAuthToken()
  async invite(email: string): Promise<{ success: boolean }> {
    const response = await this.axios.post('/resend-invite-email', { email });
    return response.data.response;
  }
}

export const usersApi = new UsersApiClass(instance);
