import { AxiosInstance } from 'axios';
import { instance } from '../tools/axios-instance';
import { refreshAuthToken } from '../tools/auth.decorater';
import { FetchParams } from '~api/tools/types';
import { ApiGeofences } from '~api/geofences/types';

export class GeofencesApiClass {
  constructor(private axios: AxiosInstance) {}

  @refreshAuthToken()
  async fetchAll(params: FetchParams = {}): Promise<{ geofences: ApiGeofences[]; totalItems: number }> {
    const response = await this.axios.get('/geofences', { params });
    return response.data.response;
  }
}

export const geofencesApi = new GeofencesApiClass(instance);
