import originalAxios, { AxiosInstance, AxiosProgressEvent } from 'axios';
import { instance } from '../tools/axios-instance';
import { refreshAuthToken } from '../tools/auth.decorater';
import { ContentItemsFetchParams, ApiContentItem, ExternalContentItem } from './types';
import { CONTENT_ITEM_MEDIA_TYPE, CONTENT_ITEM_PRESENTATION_TYPE } from '@pushologies/common/constants/content-item';

export class ContentItemsApiClass {
  constructor(private axios: AxiosInstance) {}

  @refreshAuthToken()
  async uploadItem(
    file: File,
    mediaType: CONTENT_ITEM_MEDIA_TYPE,
    presentation: CONTENT_ITEM_PRESENTATION_TYPE[],
    onProgress: (percentage: number) => void
  ): Promise<{ contentItem: ApiContentItem }> {
    const data = {
      name: file.name,
      filename: file.name.replace(/ /, '_'),
      mediaType,
      presentation
    };
    const {
      data: {
        response: { postData, contentItem }
      }
    } = await this.axios.post('/content-item/get-upload-data', data);

    const formData = new FormData();
    Object.keys(postData.fields).forEach((key) => {
      formData.append(key, postData.fields[key]);
    });
    formData.append('file', file);

    await originalAxios.post(postData.url, formData, {
      onUploadProgress(event: AxiosProgressEvent) {
        onProgress(Math.round((event.loaded * 100) / event.total));
      }
    });

    return { contentItem };
  }

  @refreshAuthToken()
  async fetchItems(
    params: Partial<ContentItemsFetchParams> | void
  ): Promise<{ contentItems: ApiContentItem[]; totalItems: number }> {
    if (params && !params?.name?.length) delete params.name;

    const response = await this.axios.get('/content-items', { params });
    return response.data.response;
  }

  @refreshAuthToken()
  async fetchItem(id: string, includeUrl?: boolean): Promise<{ contentItem: ApiContentItem }> {
    const response = await this.axios.get('/content-item', {
      params: { id, includeUrl }
    });
    return response.data.response;
  }

  @refreshAuthToken()
  async fetchItemByProxy(
    id: string,
    tenantId?: string,
    includeUrl?: boolean
  ): Promise<{ contentItem: ApiContentItem }> {
    const {
      data: {
        response: {
          responses: [{ responseBody }]
        }
      }
    } = await this.axios.post('/proxy-call', {
      endpoint: '/v1/content-item',
      method: 'GET',
      requests: [
        {
          tenantId,
          queryStringParameters: { id, includeUrl }
        }
      ]
    });

    return responseBody.response;
  }

  @refreshAuthToken()
  async deleteItems(ids: string[]): Promise<{ success: boolean; deleted: number; unableToDelete: string[] }> {
    const response = await this.axios.delete('/content-items', { params: { ids } });
    return response.data.response;
  }

  @refreshAuthToken()
  async createExternalItem(data: ExternalContentItem): Promise<{ contentItem: ApiContentItem }> {
    const response = await this.axios.post('/content-item/external', data);
    return response.data.response;
  }
}

export const contentItemsApi = new ContentItemsApiClass(instance);
