import styled from 'styled-components';
import { darken } from 'polished';
import { Row, Modal } from 'react-bootstrap';
import Logo from '~images/logo_plum.svg';
import { TextField } from '~components/inputs';
import { InputSection } from '~components/inputs/styles';
import { BarChartDiv } from '~components/bar-chart/styles';
import { TilesViewer } from '~components/file-viewers/tiles';
import { TilesViewerDiv, ViewerDiv } from '~components/file-viewers/tiles/styles';
import { blink, HideScrollbars } from '~styles/mixins';
import { TableSection } from '~components/styled/table-section';
import { IconSpan } from '~components/prompt-button/styles';
import { Select } from '~components/select';

const topPadding = '1.5rem';
const topPaddings = `calc(${topPadding} * 3)`;
const inputSectionHeight = '1.75rem';

export const WrapperDiv = styled.div`
  position: relative;
  padding: 0 2rem;
`;

export const DetailsTopRow = styled(Row)`
  justify-content: space-between;
  padding: 0 1rem;

  & > div {
    display: flex;
    align-items: center;
    color: ${({ theme }) => theme.palette.darkGrey};
    padding: 0;

    h1 {
      &:first-child {
        position: absolute;
        top: calc(-100% + 0.5rem);
      }
    }

    &.leftCol {
      flex-grow: 2;

      h1 {
        font-family: EavesBold;
        font-size: 2rem;
        margin: 0 3rem 0 0;
        color: ${({ theme }) => theme.palette.platinum};
        text-transform: uppercase;
      }

      svg {
        width: 2rem;
      }
    }

    &.rightCol {
      justify-content: flex-end;

      p {
        margin: 0;
        text-transform: uppercase;
        color: ${({ theme }) => theme.palette.primaryMustard};
      }
    }
  }

  hr {
    width: 100%;
    margin-top: 0.3rem;
    margin-bottom: ${topPadding};
  }
`;

export const DetailsBarsRow = styled(Row)`
  text-transform: uppercase;
  color: ${({ theme }) => theme.palette.navGreyColor};
  margin-bottom: 2rem;

  .leftCol {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;

    section {
      display: flex;
      justify-content: space-between;

      h6 {
        margin: 0;
        font-family: EavesBold;
        font-size: 4rem;
        line-height: 3.5rem;
      }

      &:last-child {
        flex-direction: column;

        p {
          display: flex;
          justify-content: space-between;
          margin: 0.5rem 0 0;

          span:last-child {
            font-family: EavesBold;
          }
        }
      }
    }
  }

  .rightCol {
    display: flex;
    justify-content: space-between;

    ${BarChartDiv} {
      height: 14rem;
      margin-left: 1rem;

      &:nth-child(1) {
        margin-left: 0;
      }
    }
  }
`;

export const StyledTextField = styled(TextField)`
  min-width: 25rem;
  margin: 0 1rem;
`;

export const StyledLogo = styled(Logo)`
  width: 7rem;
  height: 2rem;
  margin-top: 0.2rem;
  margin-right: 3rem;
`;

const lightBlue = '#e6f3ff';

export const DetailsMetricsRow = styled(Row)`
  margin-bottom: 1rem;

  p {
    display: flex;
    justify-content: space-between;
    margin: 0 0 0.5rem 0;
    padding: 0 0.5rem;
    font-family: EavesBold;
    text-transform: uppercase;
    background: ${lightBlue};
    color: ${({ theme }) => theme.palette.navGreyColor};

    span:nth-child(2) {
      text-transform: none;
    }
  }
`;

export const RowTopBar = styled(Row)`
  background: ${({ theme }) => theme.palette.lightGrey};
  width: 100%;
`;

export const InputRow = styled(Row)`
  justify-content: flex-end;
  padding: 1rem 0;

  .iconCol {
    button {
      background: ${({ theme }) => theme.palette.primaryPlum};
      padding: 0.2rem 0.5rem;
      border: none;
      display: flex;
      align-items: center;
      white-space: nowrap;
      color: white;

      svg {
        transform: rotate(180deg);
        width: 0.7rem;
        height: 1rem;
        margin-right: 0.25rem;

        path {
          fill: ${({ theme }) => theme.palette.primaryMustard};
        }
      }

      &:hover {
        background: ${({ theme }) => darken(0.05, theme.palette.primaryPlum)};
      }
    }
  }

  .inputCol {
    justify-content: flex-end;
    display: flex;

    ${InputSection} {
      height: 1.9rem;
      width: 40rem;
    }
  }
`;

export const TableRow = styled(Row)`
  margin-bottom: 2rem;
`;

export const ListRow = styled(Row)`
  margin-bottom: 2rem;

  ${TableSection} {
    .table-responsive {
      max-height: ${(props) => `calc(
        100vh - 2.25rem - (
          ${props.theme.metrics.headerHeight} +
          ${props.theme.metrics.headerOverlayHeight} +
          ${topPaddings} +
          ${inputSectionHeight}
        )
      )`};
    }
  }

  tr {
    td.deviceType {
      display: flex;
      justify-content: center;

      svg {
        width: 1rem;
        margin: 0 !important;
      }
    }
  }
  tbody {
    tr {
      text-transform: none;

      td {
        &:nth-child(1) {
          justify-content: center;
        }
      }
    }
  }
`;

export const EventsTileViewer = styled(TilesViewer)`
  header {
    justify-content: flex-start;
    background: white;
    color: ${({ theme }) => theme.palette.navGreyColor};
    border-bottom: 1px solid ${({ theme }) => theme.palette.lightGrey};
  }

  ${ViewerDiv} {
    height: 22rem;
    display: block;
    padding: 0;
  }
`;

interface EventDivProps {
  $highlight: boolean;
  $expand: boolean;
}

export const EventDiv = styled.div<EventDivProps>`
  position: relative;
  display: block;
  padding: 0.1rem 1rem;
  margin-bottom: 0.05rem;
  background: ${({ $highlight }) => ($highlight ? lightBlue : 'white')};

  i {
    position: absolute;
    left: 0.25rem;
    top: 0.3rem;
    height: 0;
    border-left: 0.5rem solid ${({ theme }) => theme.palette.primaryMustard};
    border-top: 0.4rem solid transparent;
    border-bottom: 0.4rem solid transparent;
    cursor: pointer;
    transform: rotate(${({ $expand }) => ($expand ? '90deg' : '0')});
  }

  p {
    display: flex;
    justify-content: space-between;
    margin: 0;
    font-family: EavesRegular;
    color: ${({ theme }) => theme.palette.navGreyColor};

    ${IconSpan} {
      svg {
        width: 0.75rem;
        height: 1.5rem;

        fill: ${({ theme }) => theme.palette.platinum};
      }

      &:hover {
        svg {
          fill: ${({ theme }) => theme.palette.navGreyColor};
        }
      }
    }
  }

  pre {
    font-size: 87.5%;
    color: ${({ theme }) => theme.palette.platinum};
    border: 1px solid ${({ theme }) => theme.palette.platinum};
    border-radius: 0.3rem;
    padding: 0.01rem 0.25rem;
    background: ${({ theme }) => theme.palette.darkenedWhite};
    font-size: 0.7rem;
    letter-spacing: 0.025rem;
    display: ${({ $expand }) => ($expand ? 'block' : 'none')};
    margin-bottom: 0.25rem;

    span {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding: 0.5em;
    }
  }
`;

export const StyledSeedModal = styled(Modal)`
  .modal-dialog {
    .modal-content {
      border: 1px solid ${({ theme }) => theme.palette.primaryPlum};
      border-radius: 0;

      .modal-header {
        background: ${({ theme }) => theme.palette.primaryPlum};
        color: white;
        border-radius: 0;
        height: 2.25rem;
        padding: 0;
        align-items: center;
        justify-content: center;
        text-transform: uppercase;
      }

      .modal-body {
        .body {
          border: 1px solid ${({ theme }) => theme.palette.lightGrey};
          background: ${({ theme }) => theme.palette.faintGrey};
          padding: 0.5rem 0.5rem 4rem;

          p {
            font-family: EavesRegular;
            color: ${({ theme }) => theme.palette.platinum};
            line-height: 1rem;
            font-size: 0.9rem;

            i {
              font-family: EavesBold;
              color: ${({ theme }) => theme.palette.primaryPlum};
            }

            code {
              display: block;
              margin: 0.25rem;
              padding: 0.2rem;
              border: 1px solid ${({ theme }) => theme.palette.lightGrey};
              border-radius: 0.2rem;
              font-size: 0.7rem;
              overflow-x: scroll;
              white-space: nowrap;

              ${HideScrollbars}
            }
          }

          .upload {
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;

            .filename {
              padding: 0 0.5rem;
              border: 1px solid ${({ theme }) => theme.palette.platinum};
              min-width: 50%;
              height: 1.8rem;
              color: ${({ theme }) => theme.palette.textGrey};

              span {
                font-size: 0.9rem;
                text-transform: uppercase;
              }

              .error {
                color: ${({ theme }) => theme.palette.red};
              }
            }

            button {
              border-radius: 0;
              height: 1.8rem;
              margin-left: 0.5rem;
            }
          }
        }
      }
    }
  }
`;

interface UploadSectionProps {
  $progress: number;
}
export const UploadSection = styled.section<UploadSectionProps>`
  position: absolute;
  top: 2.5rem;
  width: 45%;

  div {
    width: 100%;
    height: ${topPadding};
    border: 1px solid ${({ theme }) => theme.palette.platinum};
    background: ${({ theme }) => theme.palette.faintGrey};

    &:before {
      content: '';
      position: absolute;
      background: ${({ theme }) => theme.palette.primaryPlum};
      width: ${({ $progress }) => $progress}%;
      height: 100%;
      left: 0;
      top: 0;
    }
  }

  p {
    margin: 0;
    position: absolute;
    bottom: -1.25rem;
    text-align: center;
    width: 100%;
    text-transform: uppercase;
    font-size: 0.9rem;
    animation: ${blink} 1.5s infinite;
  }
`;

export const OptionsSection = styled.section`
  display: flex;
  ${TilesViewerDiv} {
    header {
      justify-content: flex-start;
      background: white;
      color: ${({ theme }) => theme.palette.navGreyColor};
      border-bottom: 1px solid ${({ theme }) => theme.palette.lightGrey};
    }
    ${ViewerDiv} {
      height: 21.75rem;
      display: block;
      padding: 0;
      ${EventDiv} {
        margin: 0.2rem 0;
      }
    }
  }
`;

export const StyledSelect = styled(Select)`
  height: 30px;
  margin-right: 10px;
  max-width: 250px;
`;
