import { action, persist, thunk } from 'easy-peasy';
import { PersonalisationVariablesModel } from './types';
import { handleUnAuthenticated } from '~store/helpers';

export * from './types';

export const personalisationVariablesModel: PersonalisationVariablesModel = persist(
  {
    loading: false,
    error: null,
    personalisationVariables: [],

    setModel: action((state, payload) => {
      Object.assign(state, payload);
    }),

    fetchPersonalisationSchema: thunk(async (actions, _, { injections, getStoreActions }) => {
      actions.setModel({ loading: true });
      try {
        const personalisationVariables = await injections.personalisationVariablesApi.fetchSchema({ limit: 1000 });
        actions.setModel({ loading: false, error: null, personalisationVariables });
      } catch (error) {
        await handleUnAuthenticated(error, getStoreActions());
        actions.setModel({ loading: false, error: error.message });
      }
    }),

    upsertPersonalisationVariable: thunk(
      async (actions, { personalisationVariable, onSuccess }, { injections, getStoreState, getStoreActions }) => {
        actions.setModel({ loading: true });
        try {
          await injections.personalisationVariablesApi.upsertVariable(personalisationVariable);
          actions.setModel({ loading: false, error: null });

          // calculate the correct success message to show, based on the action type
          const { personalisationVariables } = getStoreState().personalisationVariables;
          let upsertActionLabel = 'created';
          if (personalisationVariables.find(({ name }) => name === personalisationVariable.name)) {
            upsertActionLabel = 'updated';
          }

          getStoreActions().notificationWidget.addNotification({
            type: 'success',
            message: `Your "${personalisationVariable.name}" variable was successfully ${upsertActionLabel}`
          });

          onSuccess && onSuccess();
        } catch (error) {
          await handleUnAuthenticated(error, getStoreActions());
          actions.setModel({ loading: false, error: error.message });
        }
      }
    ),

    deletePersonalisationVariable: thunk(
      async (actions, { variableName, onSuccess }, { injections, getStoreActions }) => {
        actions.setModel({ loading: true });
        try {
          await injections.personalisationVariablesApi.deleteVariable(variableName);
          actions.setModel({ loading: false, error: null });

          getStoreActions().notificationWidget.addNotification({
            type: 'success',
            message: `Your "${variableName}" variable was successfully deleted`
          });

          onSuccess && onSuccess();
        } catch (error) {
          await handleUnAuthenticated(error, getStoreActions());
          actions.setModel({ loading: false, error: error.message });
        }
      }
    )
  },
  {
    allow: ['personalisationVariables'],
    storage: 'sessionStorage'
  }
);
