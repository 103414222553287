import React from 'react';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useVideoBuilderContext, CANVAS_ID, VIDEO_ID } from '~contexts/video-builder';
import { useStoreState, useStoreActions } from '~store/hooks';
import { SelectOption } from '~components/select';
import RotateIcon from '~images/rotate.svg';
import { useWindowResize } from '~hooks/window-resize';
import { Loader } from '~components/loader';
import { deviceCaseMetrics, deviceSelectOptions } from '../../data';
import { calcCanvasScalingMetrics, DeviceScreenMetrics, retrieveOrientation } from '../helpers';
import { VideoOptions } from './video-options';
import { CanvasSection, PreviewDeviceDiv, ScreenClippingDiv, RotateIconSpan } from './styles';

export const DeviceCanvas: React.FC = () => {
  const { canvas, videoRef, createVideoCanvas, initialising, setCanvasScaling } = useVideoBuilderContext();
  const screenClippingDivRef = React.useRef<HTMLDivElement>();
  const { notification } = useStoreState((state) => state.createNotification);
  const [deviceOption, setDeviceOption] = React.useState<SelectOption<string>>(deviceSelectOptions[0]);
  const { items } = useStoreState((state) => state.contentItems);
  const { setVideoContent } = useStoreActions((state) => state.createNotification);
  const videoUrl = items[notification?.videoContent?.contentItem?.id]?.downloadUrl;
  const deviceCase = deviceCaseMetrics[`${deviceOption.value}${retrieveOrientation(notification)}`];
  const { hideVideoOrientationAdminUi } = useFlags();

  const calcMetrics = () => {
    const scalingMetrics = calcCanvasScalingMetrics(
      notification?.videoContent?.options?.zoomMode,
      videoRef.current,
      screenClippingDivRef.current,
      deviceCase
    );
    setCanvasScaling(scalingMetrics.canvasScaling);
    return scalingMetrics;
  };
  const [metrics, setMetrics] = React.useState<DeviceScreenMetrics>(calcMetrics());

  useWindowResize(() => setMetrics(calcMetrics()));

  const handleCanvasCreate = () => {
    createVideoCanvas();
  };

  const handlePreviewDeviceChange = ([option]: SelectOption<string>[]) => {
    setDeviceOption(option);
  };

  const handleRotateDevice = () => {
    setVideoContent({
      options: {
        orientation: notification?.videoContent?.options?.orientation === 'landscape' ? 'portrait' : 'landscape'
      }
    });
  };

  React.useEffect(() => {
    if (canvas) setMetrics(calcMetrics());
  }, [
    canvas,
    notification?.videoContent?.options?.zoomMode,
    deviceOption,
    notification?.videoContent?.options?.orientation
  ]);

  return (
    <CanvasSection data-tour="deviceCanvas">
      <VideoOptions activeDeviceOption={deviceOption} handlePreviewDeviceChange={handlePreviewDeviceChange} />
      <PreviewDeviceDiv>
        <figure key={JSON.stringify(metrics)}>
          {deviceCase.element}
          <Loader loading={initialising} testId="videoBuilderLoader" />
          <ScreenClippingDiv ref={screenClippingDivRef} metrics={metrics} deviceCase={deviceCase}>
            <canvas id={CANVAS_ID} data-testid="videoBuilderCanvas" />
            <video
              data-testid="videoBuilderVideoElement"
              id={VIDEO_ID}
              style={{ display: 'none' }}
              ref={videoRef}
              key={videoUrl}
              width={videoRef?.current?.videoWidth}
              height={videoRef?.current?.videoHeight}
              onCanPlay={handleCanvasCreate}
            >
              <source
                src={`${videoUrl}#t=0.1`} // t=0.1 so first frame is rendered
                type={items[notification?.videoContent?.contentItem?.id]?.mimeType}
              />
            </video>
          </ScreenClippingDiv>
          {!hideVideoOrientationAdminUi && (
            <RotateIconSpan onClick={handleRotateDevice} data-testid="rotateIcon">
              <RotateIcon />
            </RotateIconSpan>
          )}
        </figure>
      </PreviewDeviceDiv>
    </CanvasSection>
  );
};
