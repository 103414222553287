import React from 'react';
import { ApiKey as ApiKeyType } from '~store/api-keys/types';
import { KeyArticle } from './styles';
import { CopyWidget } from '../copy-widget';

interface Props {
  tenantId?: string;
  apiKey: ApiKeyType;
  secret?: string;
}

export const ApiKey: React.FC<Props> = ({ tenantId, apiKey: { apiKey, description }, secret }) => (
  <KeyArticle>
    {tenantId && (
      <CopyWidget copyText={tenantId} copyMessage="copied tenant id">
        <p>
          <b>Tenant ID:</b> {tenantId}
        </p>
      </CopyWidget>
    )}
    <CopyWidget copyText={apiKey} copyMessage="copied api key">
      <p>
        <b>Api Key:</b> {apiKey}
      </p>
    </CopyWidget>
    {secret ? (
      <CopyWidget copyText={secret} copyMessage="copied api secret">
        <p>
          <b>Secret:</b> {secret}
        </p>
        <p className="note">NOTE: copy secret before navigating away as only visible once</p>
      </CopyWidget>
    ) : (
      <p>
        <b>Secret:</b> **************************************
      </p>
    )}
    <p>
      <i>{description}</i>
    </p>
  </KeyArticle>
);
