import { Notification } from './';

class NotificationCache {
  private notificationModel: Partial<Notification>;

  setNotification(notification: Partial<Notification>) {
    this.notificationModel = Object.assign({}, notification);
  }

  getNotification() {
    return this.notificationModel;
  }

  clearCache() {
    this.notificationModel = undefined;
  }
}

/** Simple singleton class to hold a cache of the notification store object */
export const notificationCache = new NotificationCache();
