import { Dayjs } from 'dayjs';
import { MEASUREMENT_TYPE, MEASUREMENT_INTERVAL } from '@pushologies/database-service/db/entities/measurement';
import { Measurement } from '~api/measurements';
import { INTERVAL_TO_UNIT, sumActiveUsersWithinInterval } from '../helpers';
import { ApiUserMetrics } from '~api/notifications/types';

export interface Metrics {
  activeUsers: number;
  avgSessionLength: number;
  avgSessionPerUser: number;
  stickiness: number;
}

export interface TopMetrics {
  totalDaySubscribers?: number;
  activeOptedInSubscribers: number;
  activeSubscribers: number;
}

export const calcMetrics = (
  date: Dayjs,
  activeInterval: MEASUREMENT_INTERVAL,
  measurements: Measurement[]
): Metrics => {
  const starts = date.startOf(INTERVAL_TO_UNIT[activeInterval][1]);
  const activeUsersMeasure = measurements.find(
    ({ type, startsAt, interval }) =>
      MEASUREMENT_TYPE.ACTIVE_SUBSCRIBERS === type && startsAt === starts.toISOString() && interval === activeInterval
  );
  const avgSessionDurationMeasure = measurements.find(
    ({ type, startsAt, interval }) =>
      MEASUREMENT_TYPE.AVG_SESSION_DURATION === type && startsAt === starts.toISOString() && interval === activeInterval
  );
  const avgSessionsPerUserMeasure = measurements.find(
    ({ type, startsAt, interval }) =>
      MEASUREMENT_TYPE.AVG_SESSIONS_PER_SUBSCRIBER === type &&
      startsAt === starts.toISOString() &&
      interval === activeInterval
  );

  return {
    activeUsers: activeUsersMeasure?.value || 0,
    avgSessionLength: avgSessionDurationMeasure?.value || 0,
    avgSessionPerUser: avgSessionsPerUserMeasure?.value || 0,
    stickiness: Math.floor(
      ((activeUsersMeasure?.value || 0) / sumActiveUsersWithinInterval(starts, activeInterval, measurements)) * 100
    )
  };
};

export const calcTopMetrics = (activeInterval: MEASUREMENT_INTERVAL, measurements: Measurement[]): TopMetrics => {
  const activeOptedInSubscriberMeasure = measurements.find(
    ({ type, interval }) => MEASUREMENT_TYPE.ACTIVE_OPTED_IN_SUBSCRIBERS === type && interval === activeInterval
  );
  const activeSubscribers = measurements.find(
    ({ type, interval }) => MEASUREMENT_TYPE.ACTIVE_SUBSCRIBERS === type && interval === activeInterval
  );

  return {
    activeOptedInSubscribers: activeOptedInSubscriberMeasure?.value || 0,
    activeSubscribers: activeSubscribers?.value || 0
  };
};

export const calcUserMetrics = (measurements: Measurement[]): ApiUserMetrics => {
  const totalMessages =
    measurements.find(
      ({ type, interval }) =>
        type === MEASUREMENT_TYPE.NOTIFICATIONS_SENT_TOTAL && interval === MEASUREMENT_INTERVAL.HOURLY
    )?.value || 0;

  const totalWeekMessages =
    measurements.find(
      ({ type, interval }) =>
        type === MEASUREMENT_TYPE.NOTIFICATIONS_SENT_TOTAL && interval === MEASUREMENT_INTERVAL.DAILY
    )?.value || 0;

  const opened =
    measurements.find(
      ({ type, interval }) =>
        type === MEASUREMENT_TYPE.NOTIFICATIONS_OPENED_UNIQUE && interval === MEASUREMENT_INTERVAL.MONTHLY
    )?.value || 0;

  const openedWeek =
    measurements.find(
      ({ type, interval }) =>
        type === MEASUREMENT_TYPE.NOTIFICATIONS_OPENED_UNIQUE && interval === MEASUREMENT_INTERVAL.DAILY
    )?.value || 0;

  return {
    opened: Math.floor((opened / totalMessages) * 100 || 0),
    openedSevenDays: Math.floor((openedWeek / totalWeekMessages) * 100 || 0)
  };
};
