import React from 'react';
import { ToggleWrapperDiv } from './styles';

interface Props {
  value: boolean;
  disabled?: boolean;
  onChange(active: boolean): void;
}

export const ToggleButton: React.FC<Props> = ({ value, onChange, disabled }) => {
  const handleClick = () => {
    if (disabled) return;

    const newValue = !value;
    onChange(newValue);
  };

  return (
    <ToggleWrapperDiv active={value} onClick={handleClick} $disabled={disabled}>
      <span />
    </ToggleWrapperDiv>
  );
};
