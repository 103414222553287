import { AxiosInstance } from 'axios';
import { ApiNotification, ApiNotificationResults, FetchParams, ApiButtonResults } from './types';
import { instance } from '../tools/axios-instance';
import { refreshAuthToken } from '../tools/auth.decorater';

export class NotificationsApiClass {
  constructor(private axios: AxiosInstance) {}

  @refreshAuthToken()
  async save(notification: object): Promise<{ notification: ApiNotification }> {
    const response = await this.axios.post('/notification', notification);
    return response.data.response;
  }

  @refreshAuthToken()
  async push(notification: PartialDeep<ApiNotification>): Promise<{ success: boolean; notification: ApiNotification }> {
    const response = await this.axios.post('/notification/push', notification);
    return response.data.response;
  }

  @refreshAuthToken()
  async pushByProxy(
    notification: PartialDeep<ApiNotification>,
    tenantSegmentMap: Record<string, string>
  ): Promise<{ success: boolean; notification: ApiNotification }> {
    const responses = await this.axios.post('/proxy-call', {
      endpoint: '/v1/notification/push',
      method: 'POST',
      requests: Object.keys(tenantSegmentMap).map((tenantId) => ({
        tenantId,
        requestBody: { ...notification, segment: { id: tenantSegmentMap[tenantId] } }
      }))
    });

    const responseNotification = responses?.data?.response?.responses?.[0]?.responseBody?.response;

    if (!responses?.data?.response?.success) {
      throw new Error(
        responses?.data?.response?.responses?.[0]?.responseBody?.error?.message || 'Something went wrong'
      );
    }

    return { success: true, notification: responseNotification };
  }

  @refreshAuthToken()
  async fetchNotifications(params: FetchParams = {}): Promise<{
    totalItems: number;
    notifications: ApiNotification[];
  }> {
    const { title, ...rest } = params;

    const response = await this.axios.get(`/notifications${title ? `?title=${encodeURI(title)}` : ''}`, {
      params: rest
    });
    return response.data.response;
  }

  @refreshAuthToken()
  async fetchNotificationsByProxy(
    params: FetchParams = {},
    tenantId: string
  ): Promise<{
    totalItems: number;
    notifications: ApiNotification[];
  }> {
    const {
      data: {
        response: {
          responses: [{ responseBody }]
        }
      }
    } = await this.axios.post('/proxy-call', {
      endpoint: `/v1/notifications`,
      method: 'GET',
      requests: [
        {
          tenantId,
          queryStringParameters: params
        }
      ]
    });

    return responseBody.response;
  }

  @refreshAuthToken()
  async fetchNotificationsMetrics(): Promise<{
    lastPushDate: string;
    totalPushes: number;
    sevenDayPushes: number;
    totalDeliverySuccess: number;
  }> {
    const response = await this.axios.get(`/notifications/analytics`);
    return response.data.response;
  }

  @refreshAuthToken()
  async fetchNotificationsMetricsByProxy(
    tenantId: string
  ): Promise<{ lastPushDate: string; totalPushes: number; sevenDayPushes: number; totalDeliverySuccess: number }> {
    const {
      data: {
        response: {
          responses: [{ responseBody }]
        }
      }
    } = await this.axios.post('/proxy-call', {
      endpoint: `/v1/notifications/analytics`,
      method: 'GET',
      requests: [
        {
          tenantId
        }
      ]
    });

    return responseBody.response;
  }

  @refreshAuthToken()
  async fetchNotification(
    notificationId: string,
    getDownloadUrls?: boolean
  ): Promise<{ notification: ApiNotification }> {
    const response = await this.axios.get('/notification', { params: { notificationId, getDownloadUrls } });
    return response.data.response;
  }

  @refreshAuthToken()
  async fetchNotificationByProxy(
    notificationId: string,
    getDownloadUrls?: boolean,
    tenantId?: string
  ): Promise<{ notification: ApiNotification }> {
    const {
      data: {
        response: {
          responses: [{ responseBody }]
        }
      }
    } = await this.axios.post('/proxy-call', {
      endpoint: '/v1/notification',
      method: 'GET',
      requests: [
        {
          tenantId,
          queryStringParameters: { notificationId, getDownloadUrls }
        }
      ]
    });

    return responseBody.response;
  }

  @refreshAuthToken()
  async delete(id: string): Promise<{ success: boolean }> {
    const response = await this.axios.delete('/notification', { params: { id } });
    return response.data.response;
  }

  @refreshAuthToken()
  async fetch(
    notificationId: string
  ): Promise<{ notificationResults: ApiNotificationResults[]; buttonResults: ApiButtonResults[] }> {
    const response = await this.axios.get('/notification/results', { params: { notificationId } });
    return response.data.response;
  }

  @refreshAuthToken()
  async fetchByProxy(
    notificationId: string,
    tenantId: string
  ): Promise<{ notificationResults: ApiNotificationResults[]; buttonResults: ApiButtonResults[] }> {
    const {
      data: {
        response: {
          responses: [{ responseBody }]
        }
      }
    } = await this.axios.post('/proxy-call', {
      endpoint: '/v1/notification/results',
      method: 'GET',
      requests: [
        {
          tenantId,
          queryStringParameters: { notificationId }
        }
      ]
    });

    return responseBody.response;
  }
}

export const notificationsApi = new NotificationsApiClass(instance);
