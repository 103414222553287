import React from 'react';
import { useVideoBuilderContext } from '~contexts/video-builder';
import PlayIcon from '~images/play.svg';
import PauseIcon from '~images/pause.svg';
import ForwardIcon from '~images/fast_forward.svg';
import ScrubIcon from '~images/scrub.svg';
import SkipIcon from '~images/skip.svg';
import { millisecondsToTime } from '~helpers/formats';
import { useStoreState, useStoreActions } from '~store/hooks';
import {
  VideoTimelineDiv,
  PlayControlsSection,
  StyledReactSlider,
  SliderThumbDiv,
  SliderControlsSection,
  TimecodeSection,
  VideoSliderTrack
} from './styles';
import { ButtonsController } from './buttons-controller';

export const VideoTimeline: React.FC = () => {
  const [currentTime, setCurrentTime] = React.useState(0);
  const { canvas, videoRef, addEventListenerCallback } = useVideoBuilderContext();
  const { videoDuration } = useStoreState((state) => state.createNotification);
  const { setModel } = useStoreActions((state) => state.createNotification);
  const { text } = millisecondsToTime(currentTime);

  const handleVideoTimeChange = (direction: 'forward' | 'back', seconds: number) => () => {
    if (!canvas) return;

    if (direction === 'forward') {
      videoRef.current.currentTime = videoRef.current.currentTime + seconds;
    } else {
      videoRef.current.currentTime = videoRef.current.currentTime - seconds;
    }
  };

  const handleVideoPlayChange = (action: 'play' | 'pause') => async () => {
    if (!canvas) return;

    if (action === 'play') await videoRef.current.play();
    else videoRef.current.pause();
  };

  const handleSliderSeek = (value: number) => {
    videoRef.current.currentTime = value / 1000;
  };

  const renderThumb = (props: any) => <SliderThumbDiv {...props} currentTimeString={text} />;

  const renderTrack = (props: any, state: { index: number; value: number }) => (
    <VideoSliderTrack {...props} index={state.index} />
  );

  React.useEffect(() => {
    if (canvas) {
      setModel({ videoDuration: videoRef.current.duration * 1000 });
      addEventListenerCallback('ontimeupdate', () => {
        setCurrentTime(Math.floor(videoRef.current.currentTime * 1000));
      });
    }
  }, [canvas]);

  return (
    <VideoTimelineDiv>
      <PlayControlsSection disabled={!canvas} data-tour="videoControls">
        <span onClick={handleVideoTimeChange('back', 30)} data-testid="playControlsBack30">
          <SkipIcon />
        </span>
        <span onClick={handleVideoTimeChange('back', 10)} data-testid="playControlsBack10">
          <ForwardIcon />
        </span>
        <span onClick={handleVideoTimeChange('back', 0.5)} data-testid="playControlsBack">
          <ScrubIcon />
        </span>
        <span onClick={handleVideoPlayChange('play')} data-testid="playControlsPlay">
          <PlayIcon />
        </span>
        <span onClick={handleVideoPlayChange('pause')} data-testid="playControlsPause">
          <PauseIcon />
        </span>
        <span onClick={handleVideoTimeChange('forward', 0.5)} data-testid="playControlsForward">
          <ScrubIcon />
        </span>
        <span onClick={handleVideoTimeChange('forward', 10)} data-testid="playControlsForward10">
          <ForwardIcon />
        </span>
        <span onClick={handleVideoTimeChange('forward', 30)} data-testid="playControlsForward30">
          <SkipIcon />
        </span>
      </PlayControlsSection>
      <SliderControlsSection data-tour="timeline">
        <TimecodeSection data-testid="timecodeSection">
          <span>{text}</span>
          <span>{millisecondsToTime(videoDuration).text}</span>
        </TimecodeSection>
        <ButtonsController videoDuration={videoDuration} />
        <StyledReactSlider
          value={currentTime}
          max={videoDuration}
          renderThumb={renderThumb}
          renderTrack={renderTrack}
          onChange={handleSliderSeek}
        />
      </SliderControlsSection>
    </VideoTimelineDiv>
  );
};
