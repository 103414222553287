import styled, { css } from 'styled-components';
import Card from 'react-bootstrap/Card';

interface StyledCardWrapperProps {
  $active: boolean;
}
export const StyledCardWrapper = styled.div<StyledCardWrapperProps>`
  overflow-x: hidden;
  position: fixed;
  top: 0;
  right: 0;
  z-index: 200;
  height: 100vh;
  width: 0;
  transition: width 0.05s linear 0.5s;
  background: transparent;

  ${({ $active }) =>
    $active &&
    css`
      padding: 0;
      width: 25vw;
      transition: width 0.05s linear;

      .card {
        transition: transform 0.5s ease-in-out;
        transform: translateX(calc(0% + 50px));
      }
    `}
`;

export const StyledCard = styled(Card)`
  height: 100%;
  width: calc(100% - 50px);
  top: 0;
  padding-top: ${({ theme }) => `calc(${theme.metrics.headerHeight} + ${theme.metrics.headerOverlayHeight})`};
  position: relative;
  transition: transform 0.5s ease-in-out;
  background-color: #fff;
  font-family: EavesBold;
  box-shadow: -10px 0px 40px 0px rgba(0, 0, 0, 0.1);
  transform: translateX(calc(100% + 50px));
  border: none;
  border-radius: 0;

  h2 {
    position: relative;
    color: ${({ theme }) => theme.palette.darkGrey};
    font-family: EavesBold;
    text-transform: uppercase;
    font-size: 2rem;
    margin: 1rem 0 0;
    padding-left: 10px;

    &:before {
      content: '';
      position: absolute;
      background: linear-gradient(
        180deg,
        rgba(255, 255, 255, 1) 0%,
        rgba(255, 255, 255, 0.5) 30%,
        rgba(255, 255, 255, 0) 100%
      );
      left: 0;
      bottom: 0;
      width: 100%;
      height: 1rem;
      z-index: 100;
      transform: translateY(100%);
    }
  }

  section {
    height: 100%;
    overflow-y: scroll;
    padding: 1rem 0;
    border-radius: 0;

    .toast {
      max-width: 100%;
      font-size: 0.875rem;
      background-color: #fbfbfb;
      color: #aeaeae;
      background-clip: padding-box;
      border: none;
      box-shadow: unset;
      -webkit-backdrop-filter: unset;
      backdrop-filter: unset;
      opacity: 1;
      border-radius: 0;
      text-transform: uppercase;

      .toast-body {
        padding: 0 0 0.75rem 0.5rem;
        .toast-body-message {
          background: ${({ theme }) => theme.palette.darkenedWhite};
        }
      }
      .info {
        border-left: 3px green solid;
      }
      .success {
        border-left: 3px green solid;
      }
      .error {
        border-left: 3px #7d1d26 solid;
      }
      .toast-header {
        border-bottom: unset;
        position: relative;
        background-color: unset;
        padding: 0.75rem 0 0 0.5rem;
        .close {
          position: absolute;
          top: 5px;
          right: 10px;
          color: ${({ theme }) => theme.palette.darkGrey};
          font-family: EavesRegular;
        }
      }
    }
  }
`;
