import React from 'react';
import { OverlayTrigger } from 'react-bootstrap';
import InfoIcon from '~images/info.svg';
import { StyledTooltip } from '~components/styled/tooltip';
import { BarChartDiv, BarDiv, ColumnDiv, LabelAlign, BarOrientations } from './styles';

export interface Bar {
  percentage: number;
  label: string | number;
  subLabel?: string;
  labelAlign?: LabelAlign;
  color: string | [string, string];
}

export interface Props {
  testId?: string;
  bars: Bar[][];
  label: string;
  totalPercentage?: number;
  helpTip?: string;
  className?: string;
  orientation?: BarOrientations;
}

export const BarChart: React.FC<Props> = (props) => {
  return (
    <BarChartDiv data-testid={props.testId} className={props.className} $orientation={props.orientation}>
      <p className="label">{props.label}</p>
      {!!props.totalPercentage && <span className="percentTop">{props.totalPercentage}%</span>}
      {!!props.helpTip && (
        <OverlayTrigger
          placement="top"
          overlay={<StyledTooltip data-testid="barChartTooltip">{props.helpTip}</StyledTooltip>}
        >
          <span className="icon">
            <InfoIcon />
          </span>
        </OverlayTrigger>
      )}
      {props.bars.map((column) => (
        <ColumnDiv>
          {column.map((bar, index) => (
            <BarDiv
              className="bar"
              key={`${bar.label}${index}`}
              $percentage={bar.percentage}
              $color={bar.color}
              $index={index}
              $totalBars={props.bars.length}
              $orientation={props.orientation}
              $align={bar.labelAlign}
            >
              <span>{bar.label}</span>
              {bar.subLabel && <span className="sublabel">{bar.subLabel}</span>}
            </BarDiv>
          ))}
        </ColumnDiv>
      ))}
    </BarChartDiv>
  );
};

BarChart.defaultProps = {
  bars: [],
  orientation: 'horizontal'
};
