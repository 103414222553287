import * as Sentry from '@sentry/react';
import { hydrateApiAuth } from '~store/user/helpers';

/**
 * Decorator wraps around a API method.
 * Will try and refresh the Cognito auth token and retry request if first attempt
 * resulted in a 401
 */
export const refreshAuthToken = () =>
  function (_target: object, _propertyKey: string, descriptor: PropertyDescriptor) {
    const originalMethod = descriptor.value;

    descriptor.value = async function (...args: any[]) {
      try {
        const response = await originalMethod.apply(this, args);
        return response;
      } catch (error) {
        if (error?.response?.status === 401) {
          try {
            await hydrateApiAuth();
            return originalMethod.apply(this, args);
          } catch (error) {
            Sentry.captureException(error);
            throw new Error(
              error?.response?.data?.error?.message ||
                error?.message ||
                'Error making api request. Refresh the page and try again'
            );
          }
        }

        Sentry.captureException(error);
        throw new Error(
          error?.response?.data?.error?.message ||
            error?.message ||
            'Error making api request. Refresh the page and try again'
        );
      }
    };

    return descriptor;
  };
