import React from 'react';
import { Modal, Container, Row, Col } from 'react-bootstrap';
import Cookies from 'js-cookie';
import { VideoBuilderProvider } from '~contexts/video-builder';
import { useStoreState } from '~store/hooks';
import { Tour } from '~components/tour';
import { AssetLists } from './asset-lists';
import { DeviceCanvas } from './device-canvas';
import { VideoTimeline } from './video-timeline';
import { VideoInfo } from './video-info';
import { StyledModal, RightCol } from './styles';
import { retrieveOrientation } from './helpers';
import { videoSteps, VIDEO_TOUR_COOKIE } from './tour';

interface Props {
  show: boolean;
  closeModal(): void;
}

const cookieExists = !!Cookies.get(VIDEO_TOUR_COOKIE);

export const VideoBuilder: React.FC<Props> = ({ show, closeModal }) => {
  const [showVideoTour, setShowVideoTour] = React.useState(false);
  const { notification } = useStoreState((state) => state.createNotification);

  React.useEffect(() => {
    if (show && !Cookies.get(VIDEO_TOUR_COOKIE)) setShowVideoTour(true);
  }, [show]);

  return (
    <VideoBuilderProvider>
      <StyledModal animation={cookieExists} show={show} backdrop="static" centered>
        <Modal.Body data-testid="videoBuilderModal">
          <Container fluid>
            <Row>
              <Col md={3}>
                <AssetLists />
              </Col>
              <RightCol md={9} $orientation={retrieveOrientation(notification)}>
                <DeviceCanvas />
                <VideoTimeline />
                <VideoInfo closeModal={closeModal} />
              </RightCol>
            </Row>
          </Container>
        </Modal.Body>
      </StyledModal>
      {showVideoTour && <Tour id={VIDEO_TOUR_COOKIE} steps={videoSteps} closeWithMask={false} />}
    </VideoBuilderProvider>
  );
};
