import styled from 'styled-components';
import { lighten, darken, transparentize } from 'polished';

const thBorderColor = '#CCCCCC';

export const FileViewerWrapperDiv = styled.div`
  position: relative;
  width: 100%;
  border: 1px solid ${({ theme }) => theme.palette.lightGrey};
`;

interface TableSectionProps {
  $hasHeader?: boolean;
  hideTableHeader?: boolean;
}

export const TableSection = styled.section<TableSectionProps>`
  height: 20rem;
  overflow-y: scroll;
  overflow-x: hidden;
  background: white;

  &::-webkit-scrollbar {
    width: 2px;
  }

  &::-webkit-scrollbar-track {
    box-shadow: none;
    border-radius: 0;
    border-left: 1px solid ${({ theme }) => lighten(0.75, theme.palette.lighterGrey)};
    transition: all 0.2s linear;
  }

  &::-webkit-scrollbar-thumb {
    background: ${({ theme }) => theme.palette.lightGrey};
    border-radius: 0;
  }

  /* little hack to cover the scrollbar under the thead */
  &:after {
    content: ${({ hideTableHeader }) => (hideTableHeader ? null : '')};
    position: absolute;
    background: white;
    width: 5px;
    height: 1.75rem;
    right: 0;
    top: ${({ $hasHeader }) => ($hasHeader ? '2rem' : '0')};
    border-bottom: 1px solid ${thBorderColor};
  }

  table {
    position: relative;
    border-collapse: collapse;
    width: 100%;
    margin-bottom: 0.25rem;

    th {
      position: sticky;
      top: 0;
      white-space: nowrap;
      text-transform: uppercase;
      color: ${({ theme }) => theme.palette.platinum};
      font-size: 0.9rem;
      padding: 0;
      background: white;
      z-index: 1;

      & > div {
        position: relative;
        width: 100%;
        height: 100%;
        border-bottom: 1px solid ${thBorderColor};
        padding: 0.2rem 0.75rem;
      }
    }

    td,
    th {
      border: none !important;
    }

    .spacer {
      display: block;
      height: 0.25rem;
    }

    tbody {
      tr {
        /* 🙈 little hack to remove the small border html tables always get */
        transform: scaleX(1.01);
      }
    }
  }
`;

export const StyledTr = styled.tr`
  background: ${({ theme }) => theme.palette.lighterGrey};

  td {
    position: relative;
    color: ${({ theme }) => theme.palette.textGrey};
    padding: 0.25rem 0 0;
    text-align: center;

    &:first-child {
      padding-left: 0.5rem;
      text-align: left;
    }
  }

  &:hover {
    cursor: pointer;

    &:not(.isSelected) {
      td {
        color: ${({ theme }) => darken(0.5, theme.palette.darkGrey)};
      }
      span.square {
        background: ${({ theme }) => darken(0.5, theme.palette.darkGrey)};
      }
    }
  }

  &.isSelected {
    background: ${({ theme }) => transparentize(0.2, theme.palette.primaryPlum)};

    td {
      color: white;
    }
  }
`;
