import React from 'react';
import { useBeforeunload } from 'react-beforeunload';
import Dropzone, { FileRejection, DropEvent, DropzoneOptions } from 'react-dropzone';
import { TENANT_LICENSES } from '@pushologies/common/constants/tenant-licenses';
import { Button } from '~components/button';
import { PromptButton } from '~components/prompt-button';
import DragIcon from '~images/drag.svg';
import { showNotification } from '~store/notification-widget';
import { ExternalContentItem } from '~api/content-items/types';
import { useStoreState } from '~store/hooks';
import { DropzoneSection, ModalSection } from './styles';
import { ExternalAssetForm } from './external-asset-form';

interface Props {
  dropzoneOptions: DropzoneOptions;
  disabled?: boolean;
  buttonText: string;
  label?: string;
  testId?: string;
  tourId?: string;
  presetExternalFields?: Partial<ExternalContentItem>;
  disabledExternalFields?: Partial<keyof ExternalContentItem>[];
  onExternalContentAdded?: () => void;
}

export interface FileUploadRef {
  setLoadingPercentage(percentage: number): void;
  reset(): void;
  setLoading(loading: boolean): void;
}

export const FileUpload = React.forwardRef<FileUploadRef, Props>((props, ref) => {
  const [files, setFiles] = React.useState<File[]>([]);
  const [loading, setLoading] = React.useState(false);
  const [loadingPercentage, setLoadingPercentage] = React.useState(0);
  const { tenant } = useStoreState((state) => state.tenant);

  const platformHostingLicensed =
    tenant?.licenses && tenant.licenses.includes(TENANT_LICENSES.PLATFORM_CONTENT_HOSTING);

  React.useImperativeHandle(
    ref,
    () => ({
      setLoadingPercentage,
      setLoading,
      reset() {
        setFiles([]);
        setLoading(false);
        setLoadingPercentage(0);
      }
    }),
    []
  );

  useBeforeunload(
    loading
      ? (event) => {
          event.preventDefault();
          return 'Currently uploading data. Contents will be lost if you navigate away. Are you sure?';
        }
      : null
  );

  const handleDrop = (acceptedFiles: File[], rejections: FileRejection[], evt: DropEvent) => {
    setFiles(acceptedFiles);
    acceptedFiles.length &&
      props.dropzoneOptions.onDrop &&
      props.dropzoneOptions.onDrop(acceptedFiles, rejections, evt);

    if (rejections.length) {
      const errorMessage = rejections.map(({ errors }) => errors.map(({ message }) => message).join(',')).join(',');

      showNotification(errorMessage, 'error');
    }
  };

  return (
    <PromptButton
      buttonType="button"
      buttonText={props.buttonText}
      promptTitle={props.buttonText}
      backdropCancel={!loading}
      disableButton={props.disabled}
    >
      <ModalSection>
        <p>
          Host your own assets by submitting this form <span>(all fields must be filled before submission)</span>
        </p>
        <ExternalAssetForm
          presetExternalFields={props.presetExternalFields}
          disabledExternalFields={props.disabledExternalFields}
          onExternalContentAdded={props.onExternalContentAdded}
        />
        {platformHostingLicensed && (
          <>
            <span className="separator" />
            <p>Host assets on our platform</p>
            <Dropzone {...{ ...props.dropzoneOptions, onDrop: handleDrop }} disabled={props.disabled}>
              {({ getInputProps, getRootProps, isDragActive }) => (
                <DropzoneSection
                  {...getRootProps()}
                  $isDragActive={isDragActive}
                  $isUploading={loading}
                  disabled={props.disabled}
                  $loading={loading}
                  data-testid={props.testId || 'fileUpload'}
                  data-tour={props.tourId}
                >
                  <DragIcon />
                  {props.label ? (
                    <span className="label">{props.label}</span>
                  ) : (
                    <>
                      <span className="label">Drag contents here</span>
                    </>
                  )}
                  <span className="content">Uploading {files.map(({ name }) => name).join(',')}</span>
                  <input {...getInputProps()} disabled={props.disabled} />
                  <Button
                    onClick={() => null}
                    disabled={props.disabled}
                    loading={loading}
                    loadingPercentage={loadingPercentage}
                  >
                    Choose File
                  </Button>
                </DropzoneSection>
              )}
            </Dropzone>
          </>
        )}
      </ModalSection>
    </PromptButton>
  );
});

FileUpload.displayName = 'FileUpload';
