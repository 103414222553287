import { Auth } from '@aws-amplify/auth';
import { User } from './types';
import { instance } from '~api/tools/axios-instance';

export const cognitoUserAttrToStore = (attr: any): User => ({
  email: attr.email,
  username: attr.sub,
  name: attr.name,
  tenantId: attr['custom:tenant_id']
});

export const hydrateApiAuth = async () => {
  const session = await Auth.currentSession();
  instance.defaults.headers.common.Authorization = `Bearer ${session.getIdToken().getJwtToken()}`;
};
