import { Entity, Column, PrimaryGeneratedColumn, CreateDateColumn, ManyToOne, JoinColumn, Index } from 'typeorm';
import { Tenant } from './tenant';
import { Notification } from './notification';
import { SUBSCRIBER_DEVICE_TYPES } from './subscriber';

@Entity('notification_results')
@Index(['notification', 'tenant'])
@Index(['deviceType', 'notification'])
export class NotificationResults {
  @PrimaryGeneratedColumn('uuid')
  id: string;

  @Column()
  notificationId: string;
  @ManyToOne((_) => Notification, (notification) => notification.results, { onDelete: 'CASCADE' })
  @Index()
  notification: Notification;

  @Column()
  tenantId: string;
  @ManyToOne((_) => Tenant, (tenant) => tenant.notificationResults, { onDelete: 'CASCADE' })
  @JoinColumn()
  @Index()
  tenant: Tenant;

  @Column({ name: 'device_type', type: 'enum', enum: SUBSCRIBER_DEVICE_TYPES, enumName: 'subscriberDeviceTypes' })
  deviceType: SUBSCRIBER_DEVICE_TYPES;

  @Column({ name: 'message_count', type: 'int', default: 0 })
  messageCount: number;

  @Column({ name: 'pending', type: 'int', default: 0 })
  pending: number;

  @Column({ name: 'received', type: 'int', default: 0 })
  received: number;

  @Column({ name: 'received_unique', type: 'int', default: 0 })
  receivedUnique: number;

  @Column({ name: 'downloaded', type: 'int', default: 0 })
  downloaded: number;

  @Column({ name: 'downloaded_unique', type: 'int', default: 0 })
  downloadedUnique: number;

  @Column({ name: 'download_failed', type: 'int', default: 0 })
  downloadFailed: number;

  @Column({ name: 'download_failed_unique', type: 'int', default: 0 })
  downloadFailedUnique: number;

  @Column({ name: 'opened', type: 'int', default: 0 })
  opened: number;

  @Column({ name: 'opened_unique', type: 'int', default: 0 })
  openedUnique: number;

  @Column({ name: 'viewed', type: 'int', default: 0 })
  viewed: number;

  @Column({ name: 'viewed_unique', type: 'int', default: 0 })
  viewedUnique: number;

  @Column({ name: 'link_opened', type: 'int', default: 0 })
  linkOpened: number;

  @Column({ name: 'link_opened_unique', type: 'int', default: 0 })
  linkOpenedUnique: number;

  @Column({ name: 'push_failed', type: 'int', default: 0 })
  pushFailed: number;

  @Column({ name: 'geofence_max_limit_reached', type: 'int', default: 0 })
  geofenceMaxLimitReached: number;

  @Column({ name: 'geofence_max_limit_reached_unique', type: 'int', default: 0 })
  geofenceMaxLimitReachedUnique: number;

  @CreateDateColumn({ name: 'created_at', type: 'timestamptz' })
  createdAt: Date;
}
