import { Coordinates } from '@pushologies/database-service/helpers/point-location-transformer';

export function polygonToLonLat(polygon: PolygonGeoJson): Vertices {
  if (polygon?.coordinates) {
    const vertices: any[] = [];
    polygon.coordinates[0].forEach((coordinate) =>
      vertices.push({
        longitude: coordinate[0],
        latitude: coordinate[1]
      })
    );
    return vertices;
  } else return null;
}

export function lonLatToPolygon(input: any): PolygonGeoJson {
  if (!input || input.coordinates) {
    return input;
  } else {
    const coordinates: any[][] = [[]];
    input.forEach((coordinate: any) => coordinates[0].push([coordinate.longitude, coordinate.latitude]));
    return {
      type: 'Polygon',
      coordinates
    };
  }
}

export interface PolygonGeoJson {
  type: string;
  coordinates: [number, number][][];
}

export type Vertices = Coordinates[];
