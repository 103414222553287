import styled from 'styled-components';
import { Tooltip } from 'react-bootstrap';

export const StyledTooltip = styled(Tooltip)`
  padding: 0.1rem 0;

  .tooltip-inner {
    background: white;
    border: 1px solid ${({ theme }) => theme.palette.primaryMustard};
    color: ${({ theme }) => theme.palette.navGreyColor};
    border-radius: 0;
    text-transform: uppercase;
    box-shadow: 9px 15px 30px -20px rgb(0 0 0 / 75%);
    font-size: 0.6rem;
    line-height: 0.7rem;
  }

  .arrow {
    display: none;
  }
`;
