import React, { useCallback } from 'react';

interface Options {
  text?: string;
  totalItems: number;
  offset: number;
}

/**
 * Hook to control file viewer pagination options and also text input. Example of how this
 * is in ~pages/notifications/create/video-builder/asset-lists/index.tsx
 */
export function useFileViewerOptions() {
  const [text, setText] = React.useState('');
  const [totalItems, setTotalItems] = React.useState(10);
  const [offset, setOffset] = React.useState(0);

  const handleSetOptions = useCallback((customOptions: Partial<Options>) => {
    if (customOptions.hasOwnProperty('text')) {
      setText(customOptions.text);
      setOffset(0);
    }

    if (customOptions.hasOwnProperty('offset')) {
      setOffset(customOptions.offset);
    }

    if (customOptions.hasOwnProperty('totalItems')) {
      setTotalItems(customOptions.totalItems);
    }
  }, []);

  const handleOptionsReset = useCallback(() => {
    setText('');
    setOffset(0);
  }, []);

  return {
    options: { text, offset, totalItems },
    setOptions: handleSetOptions,
    reset: handleOptionsReset
  };
}
