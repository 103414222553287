import React from 'react';
import { useStoreActions, useStoreState } from '~store/hooks';
import { ApiGeofences } from '~api/geofences/types';
import { StyledContainer } from '~components/styled/container';
import { TableSection } from '~components/styled/table-section';
import { Loader } from '~components/loader';
import { Table } from 'react-bootstrap';
import dayjs from '~helpers/dayjs';
import { Pagination } from '~components/pagination';
import { LIMIT } from '~store/geofences';
import { StyledRow } from '~pages/settings/geofences/styles';
import { GeofencesRow } from '~pages/settings/geofences/row';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { settingsPageLinks } from '~pages/settings/links';

export const Geofences: React.FC = () => {
  const { visibility } = useStoreState(({ sideNav }) => sideNav);
  const { setSideNavModel } = useStoreActions((state) => state.sideNav);
  const [loading, setLoading] = React.useState(true);
  const [geofences, setGeofences] = React.useState<ApiGeofences[]>([]);
  const { fetchAll } = useStoreActions((state) => state.geofences);
  const [totalItems, setTotalItems] = React.useState(10);
  const [offset, setOffset] = React.useState(0);
  const { hideGeoFenceRepeatAdminUi } = useFlags();

  const handlePageChange = ({ selected }: { selected: number }) => {
    setLoading(true);
    const newOffset = selected * LIMIT;
    setOffset(newOffset);

    fetchAll({
      limit: LIMIT,
      offset: newOffset,
      onSuccess(newGeofences: ApiGeofences[], newTotalItems: number) {
        setGeofences(newGeofences);
        setTotalItems(newTotalItems);
        setLoading(false);
      }
    });
  };

  const fetchGeofences = () =>
    fetchAll({
      limit: LIMIT,
      offset,
      onSuccess(newGeofences: ApiGeofences[], newTotalItems: number) {
        setGeofences(newGeofences);
        setTotalItems(newTotalItems);
        setLoading(false);
      }
    });

  React.useEffect(() => {
    setSideNavModel({
      topBarLeftTitle: 'Geofences',
      activeChild: 'geofences',
      links: settingsPageLinks
    });

    setLoading(true);
    fetchGeofences();
  }, []);

  return (
    <StyledContainer $sideNavVisible={visibility === 'show'}>
      <StyledRow>
        <TableSection>
          <Loader loading={loading} backdrop />
          <Table responsive>
            <thead>
              <tr>
                <th style={{ width: '30%' }}>Id</th>
                <th>Shape</th>
                {!hideGeoFenceRepeatAdminUi && <th>Repeat</th>}
                <th>Trigger</th>
                <th style={{ width: '10%' }}>Created At</th>
                <th style={{ width: '8rem' }}>Actions</th>
              </tr>
            </thead>
            <tbody>
              {geofences
                .sort((a, b) => dayjs(b.createdAt).diff(a.createdAt))
                .map((geofence) => (
                  <GeofencesRow key={geofence.id} geofence={geofence} />
                ))}
            </tbody>
          </Table>
          <Pagination
            pageCount={Math.ceil(totalItems / LIMIT)}
            marginPagesDisplayed={5}
            pageRangeDisplayed={4}
            forcePage={offset / LIMIT}
            breakLabel="..."
            onPageChange={handlePageChange}
          />
        </TableSection>
      </StyledRow>
    </StyledContainer>
  );
};
