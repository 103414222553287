const ILiterals = <T extends string>(arr: T[]) => {
  const literal = <K extends string>(array: K[]): K[] => array;
  return literal(arr);
};

export const formInputsToNameValueObject = <T extends string>({ elements }: HTMLFormElement, names: T[]) => {
  const L = ILiterals(names);

  return names.reduce((prev, curr) => {
    const input = elements.namedItem(curr) as HTMLInputElement;
    if (input) {
      switch (input.type) {
        case 'file':
          prev[curr] = input.files;
          break;
        default:
          prev[curr] = input.value;
          break;
      }
    } else {
      prev[curr] = '';
    }

    return prev;
  }, {} as { [K in (typeof L)[number]]: any });
};

export const disableEnterKeyFormSubmit = (e: React.KeyboardEvent) => {
  if (e.key === 'Enter') {
    e.preventDefault();
  }
};
