import {
  Column,
  Entity,
  PrimaryGeneratedColumn,
  CreateDateColumn,
  UpdateDateColumn,
  JoinColumn,
  ManyToOne,
  Index,
  Unique
} from 'typeorm';
import { Tenant } from './tenant';
import { TenantUser } from './tenant-user';

@Entity('personalisation_variable')
@Unique(['name', 'tenantId'])
export class PersonalisationVariable {
  @PrimaryGeneratedColumn('uuid')
  id: string;

  @Column({ type: 'varchar' })
  name: string;

  @Column({ type: 'varchar', nullable: true })
  description?: string;

  @Column({ type: 'varchar', name: 'default_value' })
  defaultValue: string;

  @Column()
  tenantId: string;
  @ManyToOne((_) => Tenant, { onDelete: 'CASCADE' })
  @JoinColumn()
  @Index()
  tenant: Tenant;

  @Column({ nullable: true })
  createdById: string;
  @ManyToOne((_) => TenantUser, { onDelete: 'SET NULL' })
  @JoinColumn()
  @Index()
  createdBy: TenantUser;

  @CreateDateColumn({ name: 'created_at', type: 'timestamptz' })
  createdAt: Date;

  @UpdateDateColumn({ name: 'updated_at', type: 'timestamptz' })
  updatedAt: Date;
}
