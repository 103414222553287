import React from 'react';
import { ReactourStep } from 'reactour';
import { generateTourSelector } from '~helpers/formats';
import { TourDiv } from '~components/tour/styles';
import { TimelineGifSection } from './styles';

export const VIDEO_TOUR_COOKIE = 'VIDEO_TOUR_COMPLETE';
let goToCopy: (step: number) => void;

export const videoSteps: ReactourStep[] = [
  {
    selector: generateTourSelector('videoFilesViewer'),
    content: ({ goTo }) => {
      goToCopy = goTo;
      return <TourDiv>Select a video to use for the notification. You can also upload one to use.</TourDiv>;
    },
    action: (node) => {
      node.onmouseup = () => goToCopy(1);
    },
    mutationObservables: [generateTourSelector('videoFilesViewer')],
    resizeObservables: [generateTourSelector('videoFilesViewer')]
  },
  {
    selector: generateTourSelector('addButton'),
    content: ({ goTo }) => (
      <TourDiv>
        Once video has loaded, click here to add a button to the device canvas.
        <br />
        Make sure video is selected from <a onClick={() => goTo(0)}>previous step</a>.
      </TourDiv>
    ),
    action: (node) => {
      node.onmouseup = () => goToCopy(2);
    }
  },
  {
    selector: generateTourSelector('deviceCanvas'),
    content: ({ goTo }) => (
      <TourDiv>
        On the device preview you can edit the location/size of the button on the video by dragging the button around
        the device screen.
        <br />
        Make sure you've added a button to the canvas from <a onClick={() => goTo(1)}>previous step</a>.
      </TourDiv>
    )
  },
  {
    selector: generateTourSelector('previewDevice'),
    content: () => <TourDiv>Change the device used for the device preview by selecting a new device here.</TourDiv>
  },
  {
    selector: generateTourSelector('videoOptions'),
    content: () => (
      <TourDiv>
        These options allow you to control various aspects of the video content:
        <br />
        <br />
        <b>Orientation: </b> video orientation used on users devices
        <br />
        <br />
        <b>Aspect: </b> video render aspect mode. <i>Fill</i> for when you want the video to fill entire device screen,
        or <i>Fit</i> if you want the video to fit within window
        <br />
        <br />
        <b>Allow scrubbing: </b> allow the user to fast forward/rewind the content
        <br />
        <br />
        <b>Full Screen: </b> play attached content full screen, rather than in a window
      </TourDiv>
    )
  },
  {
    selector: generateTourSelector('videoInfoOptions'),
    content: () => (
      <TourDiv>
        <b>Allow Preview: </b> show any attached content in the preview window (iOS only)
        <br />
        <br />
        <b>Allow Sharing: </b> enable / disable social media sharing buttons
        <br />
        <br />
        <b>Use Embedded Player: </b> play video content using the SDK's embedded player. Set to false to simply download
        the content and allow your app to decide how to handle it.
      </TourDiv>
    )
  },
  {
    selector: generateTourSelector('buttonOptions'),
    content: () => (
      <TourDiv>
        Control various options of the selected button on the canvas. When no button is selected this area will be
        disabled.
      </TourDiv>
    )
  },
  {
    selector: generateTourSelector('timeline'),
    content: () => (
      <TourDiv>
        The timeline shows the time when a button appears on the video. You can also edit these points by
        clicking/dragging the timeline items.
        <TimelineGifSection />
      </TourDiv>
    ),
    action: (node) => node.scrollIntoView(),
    mutationObservables: [generateTourSelector('timeline')],
    resizeObservables: [generateTourSelector('timeline')]
  },
  {
    selector: generateTourSelector('videoControls'),
    content: () => <TourDiv>Video playback controls</TourDiv>
  }
];
