import { thunk, action } from 'easy-peasy';
import { ApiKeysModel } from './types';
import { handleUnAuthenticated } from '../helpers';

export * from './types';

export const apiKeysModel: ApiKeysModel = {
  loading: false,
  error: null,
  keys: [],
  newKeySecret: {},

  setApiKeyModel: action((state, payload) => {
    Object.assign(state, payload);
  }),

  fetchApiKeys: thunk(async (dispatch, _, { injections, getStoreActions }) => {
    dispatch.setApiKeyModel({ loading: true });
    try {
      const { apiKeys } = await injections.apiKeyApi.getApiKeys();
      dispatch.setApiKeyModel({ keys: apiKeys, loading: false, error: null });
    } catch (error) {
      await handleUnAuthenticated(error, getStoreActions());
      dispatch.setApiKeyModel({ keys: [], loading: false, error: error.message });
    }
  }),

  createApiKeys: thunk(async (dispatch, payload, { injections, getStoreActions, getStoreState }) => {
    dispatch.setApiKeyModel({ loading: true });

    try {
      const { apiKey } = await injections.apiKeyApi.createApiKey(payload.type, payload.description);
      const { secret, ...key } = apiKey;
      const {
        apiKeys: { keys }
      } = getStoreState();
      dispatch.setApiKeyModel({
        loading: false,
        error: null,
        keys: [key, ...keys],
        newKeySecret: { [key.id]: secret }
      });

      getStoreActions().notificationWidget.addNotification({
        type: 'success',
        message: `Successfully created API key: ${apiKey.description}`
      });

      if (payload.onSuccess) payload.onSuccess();
    } catch (error) {
      await handleUnAuthenticated(error, getStoreActions());

      getStoreActions().notificationWidget.addNotification({
        type: 'error',
        message: error.message
      });

      dispatch.setApiKeyModel({ loading: false, error: error.message });
    }
  })
};
