import React from 'react';
import NumberEasing from 'react-number-easing';
import { MEASUREMENT_TYPE, MEASUREMENT_INTERVAL } from '@pushologies/database-service/db/entities/measurement';
import dayjs from '~helpers/dayjs';
import { millisecondsToTime } from '~helpers/formats';
import { Measurement } from '~api/measurements';
import { useStoreState } from '~store/hooks';
import { INTERVAL_TO_UNIT } from '../helpers';
import { BarChart, BarChartMetrics } from './bar-chart';
import { MetricsHeaderDiv, MetricsWrapperDiv } from './styles';

const mainColor = '#0EA6C5';
const secondaryColor = '#b4ece9';

interface Props {
  measurements: Measurement[];
}

export const AverageSessionLengthMetrics: React.FC<Props> = ({ measurements }) => {
  const now = dayjs().utc();
  const activeInterval = useStoreState((state) => state.measurements.activeInterval);
  const unit = INTERVAL_TO_UNIT[activeInterval][1];

  const day = now.startOf(unit);
  const metrics: BarChartMetrics[] = [];
  for (let i = 7, min = 1; i >= min; i--) {
    const date = day.subtract(i, unit);
    const xLabel = activeInterval === MEASUREMENT_INTERVAL.DAILY ? date.format('dddd')[0] : date.format('MMM');
    const dayMeasurement = measurements.find(
      ({ type, interval, startsAt }) =>
        type === MEASUREMENT_TYPE.AVG_SESSION_DURATION && interval === activeInterval && startsAt === date.toISOString()
    );
    metrics.push({
      value: (dayMeasurement?.value || 0) / 60000, // convert milliseconds -> minutes
      xLabel
    });
  }
  const averageSessionLength = (metrics.reduce((num, metric) => num + metric.value, 0) * 60000) / metrics.length || 0;
  const { hours, minutes, seconds } = millisecondsToTime(averageSessionLength);

  return (
    <MetricsWrapperDiv>
      <MetricsHeaderDiv $headingColor={mainColor} data-testid="sessionLengthHeader">
        <h6>average session length</h6>
        <p className="title">total</p>
        <section>
          {!!hours && (
            <>
              <p className="number">
                <NumberEasing value={hours} speed={1500} />
              </p>
              <p className="metric">hr</p>
            </>
          )}
          {!!minutes && (
            <>
              <p className="number">
                <NumberEasing value={minutes} speed={1500} />
              </p>
              <p className="metric">min</p>
            </>
          )}
          {!!seconds && (
            <>
              <p className="number">
                <NumberEasing value={seconds} speed={1500} />
              </p>
              <p className="metric">sec</p>
            </>
          )}
        </section>
      </MetricsHeaderDiv>
      <BarChart metrics={metrics} gradient={[mainColor, secondaryColor]} testId="sessionLengthBarChart" />
    </MetricsWrapperDiv>
  );
};
