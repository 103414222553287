import styled from 'styled-components';
import { Row, Col } from 'react-bootstrap';
import { InputSection } from '~components/inputs/styles';
import { StyledDropdown } from '~components/select/styles';
import { TableSection } from '~components/styled/table-section';
import { CopyDiv } from '~components/copy-widget/styles';

const topPadding = '1.5rem';
const topPaddings = `calc(${topPadding} * 3)`;
const inputSectionHeight = '1.75rem';

export const StyledRow = styled(Row)`
  padding: ${topPadding} 4rem;

  ${TableSection} {
    .table-responsive {
      max-height: ${(props) => `calc(
        100vh - 2.75rem - (
          ${props.theme.metrics.headerHeight} +
          ${props.theme.metrics.headerOverlayHeight} +
          ${topPaddings} +
          ${inputSectionHeight}
        )
      )`};
    }
  }

  .users-tooltip {
    position: relative;
    display: inline-block;
  }

  .users-tooltip .users-tooltiptext {
    visibility: hidden;
    position: fixed;
    z-index: 1;

    span {
      display: block;
      position: absolute;
      top: -35px;
      left: -50px;
      pointer-events: none;
      width: 80px;
      background-color: black;
      color: rgb(255, 255, 255);
      text-align: center;
      padding: 3px;
      border-radius: 6px;
      text-transform: none;
      font-size: 1rem;
    }
  }

  .users-tooltip:hover .users-tooltiptext {
    visibility: visible;
  }
`;

export const StyledTr = styled.tr`
  ${CopyDiv} {
    justify-content: center;
  }
`;

export const SelectsCol = styled(Col)`
  display: flex;
  justify-content: space-between;
  padding: 0;
  height: 1.75rem;

  ${StyledDropdown} {
    margin-right: 1rem;

    button.dropdown-toggle {
      font-family: EavesRegular;
      font-size: 0.9rem;
      letter-spacing: 0;
    }
  }

  button.btn {
    margin-right: 1em;
    padding: 0 1em;
    font-size: 1rem;
  }
`;

export const StyledFiltersRow = styled(Row)`
  align-items: center;
  justify-content: flex-end;
  padding: ${topPadding} 4rem 0;
`;

export const SearchCol = styled(Col)`
  display: flex;
  justify-content: space-between;
  padding: 0;

  button {
    height: ${inputSectionHeight};
    margin-right: 1rem;
    padding: 0 2.5rem;
    background: ${({ theme }) => theme.palette.lighterGrey} !important;

    &:hover {
      background: ${({ theme }) => theme.palette.textGrey} !important;
    }
  }

  ${InputSection} {
    flex-grow: 2;
    height: ${inputSectionHeight};
    min-height: unset;
  }
`;

interface DeleteSpanProps {
  isDeleting: boolean;
}

export const DeleteSpan = styled.span<DeleteSpanProps>`
  svg {
    opacity: ${({ isDeleting }) => (isDeleting ? 0 : 1)};
  }

  .spinner-border {
    position: absolute;
    top: 0.15rem;
    left: 0;
    margin: 0;
    color: ${({ theme }) => theme.palette.lighterGrey};
  }
`;

export const StatusSpan = styled.span`
  border-radius: 5px;
  padding: 5px 10px;
  font-size: 12px;

  &.invited {
    background-color: #fef3c8;
    color: #dd8324;
  }
  &.active {
    background-color: #dcfce7;
    color: #2c9154;
  }
  &.waiting {
    background-color: #e0f3ff;
    color: #0184c7;
  }
  &.expired {
    background-color: #fee2e3;
    color: #dc2727;
  }
  &.suspended {
    background-color: #dc2727;
    color: #fff;
  }
`;

export const RoleSpan = styled.span`
  border-radius: 5px;
  padding: 5px 10px;
  font-size: 12px;
  text-transform: uppercase;
  margin: 0 10px;

  &.admin {
    background-color: #ebd4e9;
    color: #98268d;
  }
  &.publisher {
    background-color: #d4d7eb;
    color: #30409c;
  }
  &.creator {
    background-color: #e0f3ff;
    color: #0184c7;
  }
  &.read.only {
    background-color: #fef3c8;
    color: #dd8324;
  }
`;

export const UserFormContainer = styled.div`
  width: 600px;

  & > * {
    margin-bottom: 20px;
  }
`;

export const InviteToggleContainer = styled.div`
  display: flex;
`;
