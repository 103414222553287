import styled, { keyframes } from 'styled-components';
import { rgba } from 'polished';

export const ChartWrapperDiv = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  margin: 0;
  color: ${({ theme }) => theme.palette.platinum};
`;

export const YLabelsDiv = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-right: 0.7rem;
  height: calc(100% - 0.5rem);
  font-family: EavesRegular;
  font-size: 0.9rem;
  text-align: right;

  p {
    margin: 0;

    &:first-child {
      transform: translateY(-10%);
    }
    &:last-child {
      transform: translateY(10%);
    }
  }
`;

export const ContentDiv = styled.div`
  position: relative;
  display: block;
  flex-grow: 2;
  height: calc(100% - 1rem);
  margin-top: 0.25rem;
  border: 1px solid ${({ theme }) => rgba(theme.palette.textLightGrey, 0.6)};

  span.grid {
    position: absolute;
    height: 1px;
    width: 100%;
    background: ${({ theme }) => theme.palette.textLightGrey};
    left: 0;
    z-index: 10;
    opacity: 0.3;

    &:nth-child(1) {
      top: 25%;
    }
    &:nth-child(2) {
      top: 50%;
      height: 2px;
    }
    &:nth-child(3) {
      top: 75%;
    }
  }

  section {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between;
  }
`;

const barKeyframes = keyframes`
  from {
    transform: scaleY(0);
  }
  to {
    transform: scaleY(1);
  }
`;

interface BarFigureProps {
  $totalBars: number;
  $percentage: number;
  $gradient: [string, string];
}

export const BarFigure = styled.figure<BarFigureProps>`
  position: relative;
  display: inline;
  height: 100%;
  width: calc(calc(100% / ${({ $totalBars }) => $totalBars}) - 2.5rem);
  background: ${({ theme }) => rgba(theme.palette.faintBlue, 0.4)};

  &:after {
    content: '';
    position: absolute;
    background: linear-gradient(180deg, ${({ $gradient }) => $gradient[0]} 0%, ${({ $gradient }) => $gradient[1]} 100%);
    width: 100%;
    height: ${({ $percentage }) => $percentage}%;
    animation: ${barKeyframes} 2s linear;
    transform: scaleY(0);
    animation-fill-mode: forwards;
    transform-origin: bottom;
    bottom: 0;
    left: 0;
  }

  figcaption {
    position: absolute;
    text-align: center;
    bottom: 0;
    line-height: 1rem;
    font-size: 0.9rem;
    width: 100%;
    z-index: 20;
    transform: translateY(calc(100% + 0.25rem));
    font-family: EavesRegular;
    text-transform: uppercase;
  }
`;
