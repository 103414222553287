import { Container } from 'react-bootstrap';
import styled from 'styled-components';

interface StyledContainerProps {
  $sideNavVisible?: boolean;
}

export const StyledContainer = styled(Container).attrs({
  id: 'page-container'
})<StyledContainerProps>`
  width: 100%;
  margin: 0;
  max-width: none;
  padding-right: 0;
  padding-top: ${({ theme: { metrics } }) => `calc(${metrics.headerOverlayHeight} + ${metrics.headerHeight})`};
  padding-left: ${({ $sideNavVisible, theme }) => ($sideNavVisible ? theme.metrics.sideNavWidth : '2rem')};

  .row {
    margin-right: auto;
    margin-left: auto;
  }
`;
