import { ViewerPaginationProps } from '../pagination';

export interface TileFile {
  id: string;
  thumbnailUrl: string;
  name: string;
  /** Size in bytes */
  size: number;
  [key: string]: any;
}

export const isTileFile = (file: object): file is TileFile =>
  file.hasOwnProperty('thumbnailUrl') && file.hasOwnProperty('name') && file.hasOwnProperty('size');

export interface Props<T> {
  /** The files to be displayed. The id must be unique */
  files: T[];

  /** ids which match a id in the files array */
  selectedFileIds?: string[];

  /** Add top header to viewer */
  title?: string;

  testId?: string;
  tourId?: string;
  loading?: boolean;
  className?: string; // mostly for styled components
  disabled?: boolean;

  /** Allow selection of multiple files */
  multiSelect?: boolean;

  /** Limit of multiple file selections */
  multiSelectLimit?: number;

  /** Columns to display items in. Default is 2 */
  columns?: number;

  pagination?: ViewerPaginationProps;

  /** Value for search input text. When prop isn't provided, input will not be displayed */
  filterValue?: string;

  /** Placeholder for search input. Only applicable if `filterValue` prop is provided */
  filterPlaceholder?: string;

  /** Callback which is called when user types in filter input. Needed when `filterValue` prop is provided */
  onFilterValueChange?(text: string): void;

  /** Callback gives option to render your own tile instead of default one */
  renderTile?(file: T, isSelected: boolean, index: number): React.ReactNode;

  /** Callback which is called when a file is clicked. Provides selected file ids */
  onSelect?(files: string[]): void;
}
