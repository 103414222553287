import React, { useState } from 'react';
import { Row } from 'react-bootstrap';
import ReactPaginate from 'react-paginate';
import { StyledContainer } from '~components/styled/container';
import { useStoreActions, useStoreState } from '~store/hooks';
import { ContentItemsFetchParams, ApiContentItem } from '~api/content-items/types';
import { Loader } from '~components/loader';
import { PromptButton } from '~components/prompt-button';
import DeleteIcon from '~images/delete.svg';
import { constructThumbnailUrl } from '~api/tools/helpers';
import { PaginationSection } from '~components/file-viewers/styles';
import { bytesToSize } from '~helpers/files';
import { ImageDiv, CardList, ContentDetailSection, StyledRow, ThumbnailDiv } from './styles';
import { FiltersRow } from './filters';
import { DisplayStyling } from './types';
import { RoleGuard } from '../../role-guard';

const LIMIT = 25;

export const MediaGallery: React.FC = () => {
  const { visibility } = useStoreState(({ sideNav }) => sideNav);
  const { users } = useStoreState((state) => state.users);
  const { status } = useStoreState((state) => state.contentItems);
  const [contentItemsList, setContentItemsList] = useState<ApiContentItem[]>([]);
  const { fetchItems, deleteItems } = useStoreActions((state) => state.contentItems);
  const { setSideNavModel } = useStoreActions(({ sideNav }) => sideNav);
  const filterParamsRef = React.useRef<ContentItemsFetchParams>();
  const [pageCount, setPageCount] = React.useState(0);
  const [displayStyling, setDisplayStyling] = useState<DisplayStyling>('list');

  const handlePageChange = ({ selected }: { selected: number }) => {
    const newOffset = selected * LIMIT;
    fetchItems({
      limit: LIMIT,
      offset: newOffset,
      ...filterParamsRef.current,
      onSuccess(contentItems) {
        setContentItemsList(contentItems);
      }
    });
  };

  const selectedDisplayStyling = (style: DisplayStyling) => {
    setDisplayStyling(style);
  };

  const handleFilterChange = (params: Omit<ContentItemsFetchParams, 'offset' | 'limit'>) => {
    filterParamsRef.current = params;
    fetchItems({
      limit: LIMIT,
      offset: 0,
      ...params,
      onSuccess(contentItems, totalItems) {
        setContentItemsList(contentItems);
        setPageCount(totalItems / LIMIT);
      }
    });
  };

  const handleDeleteItem = (ids: string[], index: number) => () => {
    deleteItems({
      ids,
      onSuccess() {
        setContentItemsList([...contentItemsList.slice(0, index), ...contentItemsList.slice(index + 1)]);
      }
    });
  };

  React.useEffect(() => {
    setSideNavModel({
      topBarLeftTitle: 'Media Library',
      activeChild: 'mediaLibrary'
    });

    fetchItems({
      limit: LIMIT,
      offset: 0,
      onSuccess: (contentItems, totalItems) => {
        setContentItemsList(contentItems);
        setPageCount(totalItems / LIMIT);
      }
    });
  }, []);

  return (
    <StyledContainer $sideNavVisible={visibility === 'show'}>
      <FiltersRow
        handleFiltersChange={handleFilterChange}
        handleDisplayStyleChange={selectedDisplayStyling}
        displayStyling={displayStyling}
      />
      <StyledRow>
        <Loader loading={status !== 'idle'} />
        <PaginationSection>
          <ReactPaginate
            pageCount={pageCount}
            marginPagesDisplayed={1}
            pageRangeDisplayed={3}
            breakLabel="..."
            nextLabel=">"
            previousLabel="<"
            onPageChange={handlePageChange}
          />
        </PaginationSection>
        <Row>
          {contentItemsList.map((item, index) => (
            <CardList key={item.id} displayStyling={displayStyling} data-testId={`mediaCard${index}`}>
              <ThumbnailDiv>
                <ImageDiv url={constructThumbnailUrl(item)} />
              </ThumbnailDiv>
              <ContentDetailSection>
                <div className="item-name">
                  <h3 className="d-inline">{item?.name}</h3>
                  <p>{item?.mimeType}</p>
                </div>
                <div className="item-size">{bytesToSize(item?.size)}</div>
                <div className="item-createdby">
                  Uploaded by: <br></br>
                  {users[item?.createdById]?.name}
                </div>
                <div className="item-icons">
                  <RoleGuard requiredRole="Admin">
                    <PromptButton
                      buttonType="icon"
                      buttonIcon={<DeleteIcon />}
                      onConfirm={handleDeleteItem([item?.id], index)}
                      confirmButtonText="Delete"
                      testId={`mediaDeleteAction${index}`}
                    >
                      Are you sure you would like to delete <i>{item.name}</i>?
                    </PromptButton>
                  </RoleGuard>
                </div>
              </ContentDetailSection>
            </CardList>
          ))}
        </Row>
      </StyledRow>
    </StyledContainer>
  );
};
