import styled from 'styled-components';
import { transparentize } from 'polished';
import { InputSection } from '~components/inputs/styles';
import { StyledInputSection as NumberFieldInputSection } from '~components/inputs/number-field/styles';
import { StyledDropdown } from '~components/select/styles';

export const LocationInputSection = styled(InputSection)`
  flex-direction: column;
  padding: 0.25rem;

  .inputRow {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    margin: 0;
    padding: 0;

    ${NumberFieldInputSection} {
      // <NumberField />
      padding: 0;
      flex-grow: 2;
      margin-right: 0.25rem;
      border-color: ${({ theme }) => transparentize(0.5, theme.palette.navGreyColor)};

      input,
      input::-webkit-inner-spin-button,
      input::-webkit-outer-spin-button {
        -moz-appearance: textfield;
        appearance: textfield;
        -webkit-appearance: textfield;
      }

      input {
        padding-top: 0.8rem;
      }

      label {
        position: absolute;
        top: 0.1rem;
        left: 0.1rem;
        font-size: 0.7rem;
        letter-spacing: 0.02rem;
        margin: 0;
      }

      span.up,
      span.down {
        right: 0.25rem;
        width: 0.4rem;
        height: 0.5rem;
        top: 65%;

        &.up {
          border-bottom-width: 0.5rem;
          border-right-width: 0.25rem;
          border-left-width: 0.25rem;
        }

        &.down {
          border-top-width: 0.5rem;
          border-right-width: 0.25rem;
          border-left-width: 0.25rem;
        }
      }
    }

    section.circle,
    section.polygon {
      display: flex;
      align-items: center;

      &.polygon {
        ${StyledDropdown} {
          height: 2rem;
          margin-right: 0.25rem;

          button {
            padding: 0.1rem;
            text-align: center;
            flex-direction: column;

            &:after {
              margin: 0;
              right: unset;
              left: 50%;
              transform: translateY(0.15rem) translateX(-50%);
            }

            span {
              font-size: 0.75rem;
              width: 100%;
            }
          }
        }

        ${NumberFieldInputSection} {
          margin-top: 0;
        }
      }
    }

    span {
      overflow-x: unset;
    }

    svg {
      width: 1.7rem;
      height: 1.7rem;
      cursor: pointer;

      path {
        fill: ${({ theme }) => theme.palette.apple};
      }

      &:hover {
        path {
          fill: ${({ theme }) => theme.palette.primaryPlum};
        }
      }
    }

    &.dates {
      ${InputSection} {
        margin-right: 0.5rem;
      }
    }
  }

  ${InputSection} {
    // <DateTimePicker />
    width: 100%;
    margin-top: 0.25rem;
    border-color: ${({ theme }) => transparentize(0.5, theme.palette.navGreyColor)};
    padding: 0;

    label {
      position: absolute;
      top: 0.1rem;
      left: 0.1rem;
      font-size: 0.7rem;
      letter-spacing: 0.02rem;
      margin: 0;
      z-index: 10;
    }

    .react-datetime-picker__wrapper {
      padding: 0.75rem 0 0 0;
    }
  }
`;

export const StyledInputSection = styled(InputSection)`
  width: calc(100% - 18.5rem);
  margin: 0.6rem 0 0 18rem;
`;

export const PromptBottomSection = styled.section`
  display: flex;
  justify-content: space-between;
  padding: 0.75rem 0 0;

  section.circle {
    width: calc(100% - 3rem);
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 1px solid ${({ theme }) => theme.palette.platinum};

    ${InputSection} {
      border: none;

      &:first-child {
        margin-right: 0.5rem;
      }

      &:last-child {
        margin-left: 0.5rem;
      }
    }
  }

  section.polygon {
    width: calc(100% - 3rem);
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 1px solid ${({ theme }) => theme.palette.platinum};

    ${StyledDropdown} {
      width: 7rem;
      border: none;
    }

    .numberFields {
      position: relative;
      display: flex;
      justify-content: center;

      ${NumberFieldInputSection} {
        width: 45%;
        border: none;
        margin: 0;
      }
    }
  }

  section.icons {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.2rem;
    border: 1px solid ${({ theme }) => theme.palette.platinum};
    height: auto;
    width: 2.5rem;

    svg {
      width: 1.4rem;
      height: 1.4rem;
      cursor: pointer;

      path {
        fill: ${({ theme }) => theme.palette.primaryMustard};
      }

      &:hover {
        path {
          fill: ${({ theme }) => theme.palette.primaryPlum};
        }
      }
    }
  }
`;
