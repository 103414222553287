import styled from 'styled-components';
import { ViewerDiv, TilesViewerDiv } from '~components/file-viewers/tiles/styles';
import { DropzoneSection } from '~components/file-upload/styles';
import { StyledFormHeader } from '~components/styled/form';

export const TilesViewerUploadSection = styled.section`
  position: relative;
  width: 100%;
  padding: 1rem;

  ${TilesViewerDiv} {
    border-color: ${({ theme }) => theme.palette.darkGrey};

    ${ViewerDiv} {
      height: 40vh;
    }
  }

  ${StyledFormHeader} {
    height: 1.9rem;
    margin: 0;
    font-size: 1rem;
  }
`;

export const UploadWrapperDiv = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  padding: 0.4rem;
  border: 1px solid ${({ theme }) => theme.palette.darkGrey};
  border-bottom: none;
  border-top: none;

  ${DropzoneSection} {
    border-width: 1px;
  }
`;
