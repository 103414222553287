import React from 'react';

/**
 * Hook performs exactly like React.useState() and also provides a ref to the state which can be
 * used by callbacks which may need the most up to date state. Fixes problems like mentioned in
 * https://stackoverflow.com/questions/57847594/react-hooks-accessing-up-to-date-state-from-within-a-callback
 * Stolen from https://github.com/Aminadav/react-useStateRef cos why not 🤷🏾‍♂️
 */
export const useStateRef = <T>(defaultValue: T): [T, (Value: T) => void, { current: T }] => {
  const [state, setState] = React.useState(defaultValue);
  const ref = React.useRef(state);

  const dispatch = React.useCallback(function (val: T) {
    ref.current = typeof val === 'function' ? val(ref.current) : val;

    setState(ref.current);
  }, []);

  return [state, dispatch, ref];
};

export const useValueRef = <T>(value: T) => {
  const ref = React.useRef(value);

  React.useEffect(() => {
    ref.current = value;
  }, [value]);

  return ref;
};
