import styled, { css } from 'styled-components';
import { Row, Col } from 'react-bootstrap';
import { InputSection } from '~components/inputs/styles';
import { StyledDropdown } from '~components/select/styles';
import { PaginationSection } from '~components/file-viewers/styles';
import { LoaderDiv } from '~components/loader/styles';
import { DisplayStyling } from './types';

export const StyledRow = styled(Row)`
  position: relative;
  justify-content: space-between;
  padding: 2rem 4rem 1rem;

  ${LoaderDiv} {
    position: fixed;
  }

  div {
    display: flex;
    align-items: center;
    color: ${({ theme }) => theme.palette.textColor};

    h3 {
      font-family: EavesBold;
      font-size: 1.75rem;
      color: ${({ theme }) => theme.palette.platinum};
      text-transform: uppercase;
    }

    span {
      font-family: EavesBold;
      margin-left: 0.75rem;
      color: ${({ theme }) => theme.palette.primaryPlum};
    }
  }

  p {
    font-family: EavesBold;
    font-size: 0.9rem;
    color: ${({ theme }) => theme.palette.textColor};
    text-transform: uppercase;
  }

  ${PaginationSection} {
    position: absolute;
    top: 0;
    right: 4rem;
  }

  .row {
    width: 100%;
  }
`;

export const ThumbnailDiv = styled.div`
  position: relative;
  display: inline-block;
  width: 7.5rem;
  height: 7.5rem;
  background: white;
  border: 1px solid ${({ theme }) => theme.palette.darkGrey};
`;

interface ImageDivProps {
  url: string;
}

export const ImageDiv = styled.div<ImageDivProps>`
  width: 100%;
  height: 100%;
  background: url(${({ url }) => url});
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
`;

export const ContentDetailSection = styled.section`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-grow: 1;
  text-transform: uppercase;

  .item-name {
    width: 60%;
    word-wrap: break-word;
    max-width: 30rem;
    padding-left: 12px;
    display: inline-block;

    h3 {
      display: inline;
      font-size: 1.5rem;
      word-wrap: break-word;
    }
  }

  .item-size {
    display: inline-flex;
    width: 80px;
    padding-left: 12px;
  }

  .item-createdby {
    font-size: 0.9rem;
    min-width: 20%;
  }

  .item-icons {
    display: inline;
    width: 5rem;
    padding-left: 0.8rem;

    svg {
      width: 1rem;
      margin: 0 0.5rem 0 0.6rem;

      path {
        fill: ${({ theme }) => theme.palette.darkGrey};
      }

      &:hover {
        cursor: pointer;

        path {
          fill: ${({ theme }) => theme.palette.textColor};
        }
      }
    }
  }
`;

interface CardListProps {
  displayStyling: DisplayStyling;
}

export const CardList = styled.div<CardListProps>`
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  background: ${({ theme }) => theme.palette.faintGrey};
  margin-bottom: 0.6rem;

  ${({ displayStyling }) =>
    displayStyling === 'grid' &&
    css`
      display: inline-block;
      width: unset;
      margin: 0.8rem;

      ${ContentDetailSection} {
        display: none;
      }
    `}
`;

export const SelectsCol = styled(Col)`
  display: flex;
  justify-content: space-between;
  padding: 0;
  height: 1.75rem;

  ${StyledDropdown} {
    margin-right: 1rem;

    button.dropdown-toggle {
      font-family: EavesRegular;
      font-size: 0.9rem;
      letter-spacing: 0;
    }
  }
`;

export const TitleCol = styled(Col)`
  display: flex;
  justify-content: space-between;
  padding-right: 0;

  button {
    height: 1.75rem;
    margin-right: 1rem;
    padding: 0 2.5rem;
    background: ${({ theme }) => theme.palette.lighterGrey} !important;

    &:hover {
      background: ${({ theme }) => theme.palette.textGrey} !important;
    }
  }

  ${InputSection} {
    flex-grow: 2;
    height: 1.75rem;
    min-height: unset;
  }
`;

export const StyledFiltersRow = styled(Row)`
  justify-content: space-between;
  padding: 1.5rem 4rem 0;
`;

export const StylingIconDiv = styled.div`
  margin: 0 2rem;

  svg {
    transition: fill 0.2s linear;
    width: 1.5rem;
    margin-right: 0.75rem;
    cursor: pointer;

    path {
      fill: ${({ theme }) => theme.palette.darkGrey};
    }

    &.active {
      path {
        fill: ${({ theme }) => theme.palette.primaryPlum};
      }
    }
  }
`;
