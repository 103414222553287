import styled, { css } from 'styled-components';
import { Nav } from 'react-bootstrap';
import Logo from '~images/logo_plum.svg';
import { breakpoints } from '~styles/mixins';

export const StyledLogo = styled(Logo)`
  width: 10rem;
  height: 2.5rem;
  margin-top: 0.2rem;
  margin-right: 3rem;
`;

export const StyledNavBar = styled(Nav)`
  z-index: 500;
  position: fixed;
  height: ${({ theme }) => theme.metrics.headerHeight};
  width: 100vw;
  background: rgb(238, 238, 238);
  margin: 0 auto;
  padding-right: 0.75rem;
  flex-wrap: nowrap;
  box-shadow: 0px -5px 35px 0px rgba(0, 0, 0, 0.35);
  display: flex;
  align-items: center;

  #main-links {
    width: auto;
    flex-grow: 2;
    max-width: none;
    display: flex;
    align-items: center;

    .nav {
      justify-content: end;
      gap: 5%;
      margin-right: 2rem;
      width: 100%;

      .nav-link,
      .nav-link > button {
        text-decoration: none;
        text-transform: uppercase;
        letter-spacing: 0.1rem;
        font-size: 0.9rem !important;
        color: ${({ theme }) => theme.palette.primaryPlum};
        padding: 0;
        width: auto;

        &:hover,
        &.show > button {
          color: ${({ theme }) => theme.palette.primaryMustard};
          text-decoration: none;
        }

        &.active {
          color: ${({ theme }) => theme.palette.primaryMustard};

          button {
            color: ${({ theme }) => theme.palette.primaryMustard};
          }
        }
      }

      .nav-link.disabled > button {
        cursor: none;
        pointer-events: none;
        color: ${({ theme }) => theme.palette.lighterGrey} !important;
      }

      .nav-link > .dropdown-menu {
        top: 1rem !important;
        transform: translate(-20%, 1.4rem) !important;

        & > a {
          justify-content: center;

          &.active {
            color: ${({ theme }) => theme.palette.primaryPlum};
          }
        }
      }
    }
  }
`;

interface BellIconDivProps {
  $hasNew: boolean;
  $active: boolean;
}

export const BellIconDiv = styled.div<BellIconDivProps>`
  display: flex;
  position: relative;
  width: 2.6rem;
  background-color: transparent !important;
  border: none;
  justify-content: center;

  &:after {
    content: none;
  }

  svg {
    width: 1.5rem;
    path {
      fill: ${({ theme }) => theme.palette.platinum};
    }
  }

  ${({ theme, $active }) =>
    $active &&
    css`
      svg {
        path {
          fill: ${theme.palette.primaryPlum} !important;
        }
      }
    `}

  ${({ theme, $hasNew }) =>
    $hasNew &&
    css`
      &:before {
        content: '';
        height: 1rem;
        width: 1rem;
        border-radius: 50%;
        position: absolute;
        right: 0.25rem;
        top: 1rem;
        background: ${theme.palette.primaryPlum};
        border: 0.25rem rgb(238, 238, 238) solid;
      }
    `}

  &:hover {
    cursor: pointer;
    svg {
      path {
        fill: ${({ theme }) => theme.palette.navGreyColor};
      }
    }
  }
`;

export const StyledTenantName = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-transform: uppercase;
  background-color: ${({ theme }) => theme.palette.primaryPlum};
  color: #fff;
  padding: 4px;
  border-radius: 5px;
  font-size: 12px;

  @media ${breakpoints.lg} {
    display: none;
  }

  span {
    padding: 2px 0 0 5px;
  }

  div {
    position: relative;
    left: 1px;

    svg {
      width: 12px;
    }
  }
`;
