import { action, thunk } from 'easy-peasy';
import { TagsModel } from './types';
import { handleUnAuthenticated } from '~store/helpers';

export * from './types';

export const LIMIT = 20;

export const tagsModel: TagsModel = {
  loading: false,
  error: null,

  setModel: action((state, payload) => {
    Object.assign(state, payload);
  }),

  fetchTags: thunk(async (actions, payload, { injections, getStoreActions }) => {
    actions.setModel({ loading: true });
    const { onSuccess, onError, ...params } = payload;

    try {
      const { tags, totalItems } = await injections.tagsApi.fetchAll(params);
      actions.setModel({ loading: false, error: null });

      onSuccess(tags, totalItems);
    } catch (error) {
      await handleUnAuthenticated(error, getStoreActions());
      actions.setModel({ loading: false, error: error.message });
      onError && onError(error.message);
    }
  }),

  deleteTags: thunk(async (_actions, payload, { injections, getStoreActions }) => {
    const { onSuccess, onError, ids, names } = payload;

    try {
      await injections.tagsApi.delete(ids, names);

      getStoreActions().notificationWidget.addNotification({
        type: 'success',
        message: 'Tag successfully deleted'
      });

      if (onSuccess) onSuccess();
    } catch (error) {
      await handleUnAuthenticated(error, getStoreActions());

      getStoreActions().notificationWidget.addNotification({
        type: 'error',
        message: error.message
      });

      if (onError) onError(error.message);
    }
  })
};
