import { AxiosInstance } from 'axios';
import { API_KEY_TYPES, ApiKey } from '@pushologies/database-service/db/entities/api-key';
import { instance } from './tools/axios-instance';
import { refreshAuthToken } from './tools/auth.decorater';

export class ApiKeyApiClass {
  constructor(private axios: AxiosInstance) {}

  @refreshAuthToken()
  async getApiKeys(): Promise<{ apiKeys: ApiKey[] }> {
    const response = await this.axios.get('/api-keys');
    return response.data.response;
  }

  @refreshAuthToken()
  async createApiKey(type: API_KEY_TYPES, description?: string): Promise<{ apiKey: ApiKey }> {
    const response = await this.axios.post('/api-key', {
      type,
      ...(description?.length && { description })
    });
    return response.data.response;
  }
}

export const apiKeyApi = new ApiKeyApiClass(instance);
