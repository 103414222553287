import React from 'react';
import { Overlay } from 'react-bootstrap';
import { DropdownItem } from '~components/select/styles';
import { TextField, Props as TextFieldProps } from '../text-field';
import { OverlayDiv } from './styles';

export interface Suggestion<T = any> {
  label: string;
  value: T;
}

interface Props extends Omit<TextFieldProps, 'onChange' | 'value'> {
  suggestions: Suggestion[];
  onChange(text: string): void;
  onSuggestionSelect(value: any, index: number): void;
  selectedSuggestionNamePath?: string;
  stringValue?: string;
}

export const AutosuggestField: React.FC<Props> = ({
  suggestions,
  onChange,
  onSuggestionSelect,
  selectedSuggestionNamePath,
  stringValue,
  ...rest
}) => {
  const [focused, setFocused] = React.useState(false);
  const [value, setValue] = React.useState(stringValue || '');
  const [activeSuggestion, setActiveSuggestion] = React.useState(0);
  const inputRef = React.useRef<HTMLInputElement>();

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value);
    onChange(event.target.value);
  };

  const handleSuggestionClick = (suggestion: Suggestion, index: number) => () => {
    onSuggestionSelect(suggestion.value, index);
    if (selectedSuggestionNamePath) {
      selectedSuggestionNamePath === 'default'
        ? setValue(suggestion.value)
        : setValue(suggestion.value[selectedSuggestionNamePath]);
    }
  };

  const onFocus = () => setFocused(true);
  const onBlur = () => setFocused(false);
  const onKeyDown: React.KeyboardEventHandler<HTMLInputElement> = (event) => {
    switch (event.key) {
      case 'ArrowDown':
        setActiveSuggestion((activeSuggestion + 1) % suggestions.length);
        event.stopPropagation();
        event.preventDefault();
        break;
      case 'ArrowUp':
        setActiveSuggestion((activeSuggestion === 0 ? suggestions.length : activeSuggestion) - 1);
        event.stopPropagation();
        event.preventDefault();
        break;
      case 'Enter':
        if (suggestions.length) {
          onSuggestionSelect(suggestions[activeSuggestion].value, activeSuggestion);
          if (selectedSuggestionNamePath) {
            selectedSuggestionNamePath === 'default'
              ? setValue(suggestions[activeSuggestion].value)
              : setValue(suggestions[activeSuggestion].value[selectedSuggestionNamePath]);
          }
          setActiveSuggestion(0);
        }
      default:
        break;
    }
  };

  return (
    <>
      <TextField
        {...rest}
        value={value}
        onChange={handleInputChange}
        ref={inputRef}
        inputEvents={{ onFocus, onBlur, onKeyDown }}
      />
      <Overlay target={inputRef.current} show={!!suggestions.length && focused} placement="bottom">
        {({ placement, arrowProps, show, popper, ...props }) => (
          <OverlayDiv {...props}>
            {suggestions.map((suggestion, i) => (
              <DropdownItem
                as="div"
                key={suggestion.label}
                onClick={handleSuggestionClick(suggestion, i)}
                $selected={activeSuggestion === i}
                data-testid="suggestion"
              >
                {suggestion.label}
              </DropdownItem>
            ))}
          </OverlayDiv>
        )}
      </Overlay>
    </>
  );
};
