import React from 'react';
import { LocationRuleValue as RuleValue } from '@pushologies/segments-service/types';
import { Rule } from '~store/create-segment/types';
import { LocationField, Location, Vertice } from '~components/inputs/location';

interface LocationRuleValue extends Omit<RuleValue, 'polygon'> {
  vertices: Vertice[];
}
interface Props {
  rule: Rule<LocationRuleValue>;
  invalid?: boolean;
  className?: string;
  onChange(value: Partial<Location>): void;
}

export const LocationRuleInput: React.FC<Props> = ({ rule, onChange, invalid, className }) => {
  const handleOnChange = (data: Partial<Location>) => {
    onChange({
      ...rule.segmentRule.value,
      ...data
    });
  };

  return (
    <LocationField
      latitude={rule.segmentRule.value?.latitude}
      longitude={rule.segmentRule.value?.longitude}
      radius={rule.segmentRule.value?.radius}
      vertices={rule.segmentRule.value?.vertices}
      onChange={handleOnChange}
      invalid={invalid}
      className={className}
      testId="segmentLocation"
      allowedDrawingModes={['marker', 'polygon']}
    />
  );
};
