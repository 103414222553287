export function bytesToSize(bytes: number) {
  // if (typeof bytes !== 'number') return '';

  const metrics = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
  if (bytes === 0) return 'n/a';
  const i = Math.floor(Math.log(bytes) / Math.log(1024));
  if (i === 0) return `${bytes} ${metrics[i]}`;
  return `${Math.round(bytes / 1024 ** i)} ${metrics[i]}`;
}

export const generateRandomStrUID = (length: number) =>
  Math.random()
    .toString(34)
    .replace(/[^a-z]+/g, '')
    .substr(0, length);
