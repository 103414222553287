import React, { FC } from 'react';
import { InfoDiv } from './styles';

interface Props {
  message: string;
}

export const InfoBanner: FC<Props> = ({ message }) => {
  return (
    <InfoDiv>
      <h4>{message}</h4>
    </InfoDiv>
  );
};
