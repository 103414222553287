import React from 'react';
import { createRoot } from 'react-dom/client';
import { Amplify } from '@aws-amplify/core';
import amplifyconfig from '~config/amplify';
import App from './App';

Amplify.configure(amplifyconfig);

const root = createRoot(document.getElementById('root'));
root.render(<App />);
