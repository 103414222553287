export function pointToLonLat(point: any): Coordinates {
  if (point?.coordinates) {
    return {
      longitude: point.coordinates[0],
      latitude: point.coordinates[1]
    };
  }
}

export function lonLatPointToPoint(input: any): any {
  if (!input || input.coordinates) {
    return input;
  } else {
    return {
      type: 'Point',
      coordinates: [input.longitude, input.latitude]
    };
  }
}

export interface Coordinates {
  longitude: number;
  latitude: number;
}
