import styled from 'styled-components';
import { breakpoints } from '~styles/mixins';
import { Section, SECTION_HEIGHT } from '../styles';

const lime = '#43aa8b';
const green = '#90be6d';

export const NotificationsStatsSection = styled(Section)`
  display: flex;
  flex-direction: column;
  height: calc(${SECTION_HEIGHT} - 3rem);
  margin-top: 1rem;

  @media ${breakpoints.md} {
    height: ${SECTION_HEIGHT};
    margin-top: 0;
  }
`;

interface MetricProps {
  $percentage: number;
}

export const Metric = styled.div<MetricProps>`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  height: calc(calc(100% / 7) - 0.75rem);
  font-family: EavesBold;
  text-transform: uppercase;
  letter-spacing: 0.025rem;
  background: #e4edf8;

  p {
    position: absolute;
    z-index: 10;
    top: 50%;
    transform: translateY(-50%);
    font-size: 0.9rem;
    line-height: 0.8rem;

    &:nth-child(1) {
      left: 0.5rem;
      color: ${({ theme }) => theme.palette.blue};
    }
    &:nth-child(2) {
      right: 0.5rem;
      color: rgb(67, 120, 170);
    }
  }

  span {
    position: relative;
    display: inline-flex;
    align-items: center;
    height: 100%;

    &:first-child {
      width: ${({ $percentage }) => 100 - $percentage}%;
      justify-content: flex-start;
      background: transparent;
    }
    &:last-child {
      width: ${({ $percentage }) => $percentage}%;
      justify-content: flex-end;
      background: linear-gradient(90deg, ${green} 0%, ${lime} 100%);
    }
  }
`;
