import React from 'react';
import NumberEasing from 'react-number-easing';
import { MEASUREMENT_INTERVAL } from '@pushologies/database-service/db/entities/measurement';
import dayjs from '~helpers/dayjs';
import { useStoreActions } from '~store/hooks';
import { Measurement } from '~api/measurements';
import { millisecondsToTime } from '~helpers/formats';
import { Loader } from '~components/loader';
import { SectionHeaderDiv, IntervalButtonsDiv, IntervalButton } from '../styles';
import { MetricsTabsSection, TabMetric, TopMetric, MetricsRowDiv, MetricsDiv, TopMetricsDiv } from './styles';
import { TopMetrics, Metrics, calcMetrics, calcTopMetrics } from './helpers';
import { STICKINESS_UNIT } from '../helpers';

const NUMBER_EASING_SPEED = 1500;

export const UserMetrics: React.FC = () => {
  const [loading, setLoading] = React.useState(true);
  const [subHeader, setSubHeader] = React.useState('');
  const [activeInterval, setActiveInterval] = React.useState(MEASUREMENT_INTERVAL.HOURLY);
  const [metrics, setMetrics] = React.useState<Metrics>({
    activeUsers: 0,
    avgSessionLength: 0,
    avgSessionPerUser: 0,
    stickiness: 0
  });
  const [topMetrics, setTopMetrics] = React.useState<TopMetrics>({
    activeOptedInSubscribers: 0,
    activeSubscribers: 0
  });
  const { fetchMeasurements } = useStoreActions((state) => state.measurements);
  const avgSessionLengthValues = millisecondsToTime(metrics?.avgSessionLength);

  const fetchHourlyMeasurements = () => {
    const startAt = dayjs().subtract(1, 'hour').startOf('hour');
    fetchMeasurements({
      to: startAt.toDate(),
      from: startAt.startOf('day').toDate(),
      intervals: [MEASUREMENT_INTERVAL.HOURLY],
      onSuccess(measurements: Measurement[]) {
        setTopMetrics(calcTopMetrics(MEASUREMENT_INTERVAL.HOURLY, measurements));
        setMetrics(calcMetrics(startAt, MEASUREMENT_INTERVAL.HOURLY, measurements));
        setSubHeader(startAt.format('ddd HH:mm:ss'));
        setLoading(false);
      }
    });
  };

  const fetchDailyMeasurements = () => {
    const startAt = dayjs().subtract(1, 'day').startOf('day');
    fetchMeasurements({
      to: startAt.toDate(),
      from: startAt.startOf('month').toDate(),
      intervals: [MEASUREMENT_INTERVAL.DAILY],
      onSuccess(measurements: Measurement[]) {
        setMetrics(calcMetrics(startAt, MEASUREMENT_INTERVAL.DAILY, measurements));
        setSubHeader(startAt.format('dddd'));
        setLoading(false);
      }
    });
  };

  const fetchMonthlyMeasurements = () => {
    const startAt = dayjs().subtract(1, 'month');
    fetchMeasurements({
      to: startAt.toDate(),
      from: startAt.startOf('year').toDate(),
      intervals: [MEASUREMENT_INTERVAL.MONTHLY],
      onSuccess(measurements: Measurement[]) {
        setMetrics(calcMetrics(startAt, MEASUREMENT_INTERVAL.MONTHLY, measurements));
        setSubHeader(startAt.format('MMMM'));
        setLoading(false);
      }
    });
  };

  const handleIntervalChange = (interval: MEASUREMENT_INTERVAL) => () => {
    if (interval === activeInterval) return;

    setLoading(true);
    setActiveInterval(interval);
  };

  React.useEffect(() => {
    activeInterval === MEASUREMENT_INTERVAL.HOURLY && fetchHourlyMeasurements();
    activeInterval === MEASUREMENT_INTERVAL.DAILY && fetchDailyMeasurements();
    activeInterval === MEASUREMENT_INTERVAL.MONTHLY && fetchMonthlyMeasurements();
  }, [activeInterval]);

  return (
    <MetricsDiv>
      <TopMetricsDiv>
        <TopMetric data-testid="userMetric">
          <span>active subscribers</span>
          <span>
            <NumberEasing value={topMetrics?.activeSubscribers || 0} speed={NUMBER_EASING_SPEED} />
          </span>
        </TopMetric>
        <TopMetric data-testid="userMetric">
          <span>push enabled subscribers</span>
          <span>
            <NumberEasing value={topMetrics?.activeOptedInSubscribers || 0} speed={NUMBER_EASING_SPEED} />
          </span>
        </TopMetric>
      </TopMetricsDiv>
      <MetricsTabsSection>
        <Loader loading={loading} testId="sectionLoader" />
        <SectionHeaderDiv data-testid="userMetricsHeader">
          <h3>total user metrics</h3>
          <h2>{subHeader}</h2>
        </SectionHeaderDiv>
        <IntervalButtonsDiv>
          {Object.values(MEASUREMENT_INTERVAL).map((interval) => (
            <IntervalButton
              key={interval}
              data-testid={`${interval.toLowerCase()}IntervalButton`}
              onClick={handleIntervalChange(interval)}
              $active={interval === activeInterval}
            >
              {interval}
            </IntervalButton>
          ))}
        </IntervalButtonsDiv>
        <MetricsRowDiv>
          <TabMetric data-testid="userMetric">
            <span>active users</span>
            <span>
              <NumberEasing value={metrics?.activeUsers || 0} speed={NUMBER_EASING_SPEED} />
            </span>
          </TabMetric>
          <TabMetric data-testid="userMetric">
            <span>
              stickiness <i>{STICKINESS_UNIT[activeInterval]}</i>
            </span>
            <span>
              <NumberEasing value={metrics?.stickiness || 0} speed={NUMBER_EASING_SPEED} />%
            </span>
          </TabMetric>
          <TabMetric data-testid="userMetric">
            <span>average session length</span>
            <span>
              {!!avgSessionLengthValues.hours && (
                <>
                  <NumberEasing value={avgSessionLengthValues.hours} speed={NUMBER_EASING_SPEED} />
                  <i>hr</i>
                </>
              )}
              <>
                <NumberEasing value={avgSessionLengthValues.minutes} speed={NUMBER_EASING_SPEED} />
                <i>min</i>
              </>
              {!!avgSessionLengthValues.seconds && (
                <>
                  <NumberEasing value={avgSessionLengthValues.seconds} speed={NUMBER_EASING_SPEED} />
                  <i>sec</i>
                </>
              )}
            </span>
          </TabMetric>
          <TabMetric data-testid="userMetric">
            <span>average sessions per user</span>
            <span>
              <NumberEasing value={metrics?.avgSessionPerUser || 0} speed={NUMBER_EASING_SPEED} />
            </span>
          </TabMetric>
        </MetricsRowDiv>
      </MetricsTabsSection>
    </MetricsDiv>
  );
};
