import { action, persist } from 'easy-peasy';
import dayjs from 'dayjs';
import { toast } from 'react-toastify';
import { NotificationWidgetModel, NotificationType } from './types';

export const showNotification = (message: string, type: NotificationType) =>
  toast(message, {
    position: 'bottom-right',
    autoClose: 3000,
    type,
    hideProgressBar: true,
    closeOnClick: false,
    closeButton: false,
    pauseOnHover: false,
    draggable: false,
    className: 'notificationWidgetToast'
  });

export * from './types';

export const notificationWidgetModel: NotificationWidgetModel = persist(
  {
    notifications: [],
    showWidget: false,
    hasNewNotification: false,

    addNotification: action((state, payload) => {
      state.hasNewNotification = true;

      state.notifications = [
        {
          ...payload,
          timestamp: dayjs().format('LLL')
        },
        ...state.notifications.slice(0, 10)
      ];

      showNotification(payload.message, payload.type);
    }),

    setShowWidget: action((state, show) => {
      state.showWidget = show;
    }),

    toggleShowWidget: action((state) => {
      state.showWidget = state.showWidget ? false : true;

      if (state.showWidget) state.hasNewNotification = false;
    }),

    removeNotification: action((state, index) => {
      state.notifications.splice(index, 1);
    }),

    clear: action((state) => {
      state.notifications = [];
    })
  },
  {
    allow: ['notifications'],
    storage: 'localStorage'
  }
);
