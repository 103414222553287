import React from 'react';
import { Dropdown } from 'react-bootstrap';
import { StyledDropDown, StyledDropDownItem } from './styles';

export { StyledDropDownLink as HeaderDropDownLink, StyledDropDownNavLink as DropDownNavLink } from './styles';

interface HeaderDropDownProps {
  id: string;
  testId?: string;
  button?: React.ReactNode;
  className?: string;
}

export const HeaderDropDown: React.FC<HeaderDropDownProps> = ({ id, button, children, className, testId }) => (
  <StyledDropDown id={id} className={className} data-testid={testId}>
    <Dropdown.Toggle id={`${id}-toggle-btn`}>{button}</Dropdown.Toggle>
    <Dropdown.Menu>{children}</Dropdown.Menu>
  </StyledDropDown>
);

interface HeaderDropDownItemProps {
  onClick?: () => void;
}

export const HeaderDropDownItem: React.FC<HeaderDropDownItemProps> = (props) => (
  <StyledDropDownItem as="button" {...props} />
);
