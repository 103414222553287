import sizeof from 'object-sizeof';
import { thunk, action, computed, State } from 'easy-peasy';
import { SegmentRule } from '@pushologies/segments-service/types';
import { INVALID_RULE } from '~components/segment-builder/header';
import { generateRandomStrUID } from '~helpers/files';
import { REQUEST_BODY_BYTE_LIMIT } from '~helpers/constants';
import { CreateSegmentModel, IdKeys } from './types';
import { handleUnAuthenticated } from '../helpers';
import { getRuleInvalidId, IdsPayload, constructSegmentSubscribersPayloads } from './helpers';

export * from './types';

const idKeys: string[] = Object.values(IdKeys);
const initialState: State<CreateSegmentModel> = {
  id: undefined,
  createSegmentLoading: false,
  name: '',
  description: '',
  rules: [],
  dynamism: null,
  invalidRules: [INVALID_RULE],
  isInvalid: true,
  hasInvalidRules: true
};

export const createSegmentModel: CreateSegmentModel = {
  ...initialState,

  isInvalid: computed(({ invalidRules }) => !!invalidRules.length),
  hasInvalidRules: computed(
    ({ invalidRules, rules }) => !!invalidRules.filter((id) => id !== INVALID_RULE).length || !rules.length
  ),

  setModel: action((state, payload) => {
    Object.assign(state, payload);
  }),

  resetModel: action((state) => {
    Object.assign(state, { ...initialState });
  }),

  addRules: action((state, payload) => {
    const newRules = payload.map((segmentRule) => ({
      id: generateRandomStrUID(10),
      segmentRule
    }));

    state.rules = [...newRules, ...state.rules];
    state.invalidRules = [
      ...state.invalidRules,
      ...newRules.map((rule) => getRuleInvalidId(rule, 'value')),
      ...newRules.map((rule) => getRuleInvalidId(rule, 'operator'))
    ];
  }),

  deleteRule: action((state, ruleId) => {
    state.rules = state.rules.filter(({ id }) => id !== ruleId);
    state.invalidRules = state.invalidRules.filter((id) => !id.startsWith(ruleId));
  }),

  addInvalidRule: action((state, payload) => {
    const set = new Set(state.invalidRules);
    set.add(payload);
    state.invalidRules = [...set];
  }),

  removeInvalidRule: action((state, payload) => {
    const set = new Set(state.invalidRules);
    set.delete(payload);
    state.invalidRules = [...set];
  }),

  setRuleOperator: action((state, { id, operator }) => {
    const index = state.rules.findIndex((rule) => rule.id === id);
    state.rules = [
      ...state.rules.slice(0, index),
      {
        id,
        segmentRule: {
          ...state.rules[index].segmentRule,
          operator
        }
      },
      ...state.rules.slice(index + 1)
    ];
  }),

  setRuleValue: action((state, { id, value }) => {
    const index = state.rules.findIndex((rule) => rule.id === id);
    const oldRule = state.rules[index].segmentRule;
    state.rules = [
      ...state.rules.slice(0, index),
      {
        id,
        segmentRule: {
          ...oldRule,
          value
        }
      },
      ...state.rules.slice(index + 1)
    ];
  }),

  persistSegment: thunk(async (actions, { id, onComplete }, { getStoreActions }) => {
    getStoreActions().segments.fetchSegment({
      id,
      onSuccess(segment) {
        const rulesArray: any = [];
        segment.rules.map((rule) => rulesArray.push({ id: generateRandomStrUID(10), segmentRule: rule }));
        actions.setModel({
          id: segment.id,
          name: segment?.name || '',
          description: segment?.description || '',
          rules: rulesArray || [],
          dynamism: segment?.dynamism || null
        });
        onComplete && onComplete();
      }
    });
  }),

  saveSegment: thunk(async (dispatch, payload, { injections, getStoreActions, getState }) => {
    dispatch.setModel({ createSegmentLoading: true });

    try {
      const { name, description, rules, dynamism, id } = getState();
      const newSegment = {
        ...(id && { id }),
        ...(dynamism && { dynamism }),
        name,
        description,
        rules: rules.map(({ segmentRule }) => segmentRule)
      };

      // in the case where body comes close to exceeding 1MB api body limit limit, we check if there are any
      // subscriberId/deviceId/customerId rules and send only 10 of their values in the initial POST /segment
      // call. The rest we add to the segment via POST /segment/subscriber call
      let idsPayload: IdsPayload[];
      if (sizeof(newSegment) >= REQUEST_BODY_BYTE_LIMIT) {
        const idRules: SegmentRule<string[]>[] = newSegment.rules.filter(({ ruleId }) => idKeys.includes(ruleId));
        const partialIdRules = idRules.map((rule) => ({
          ...rule,
          value: rule.value.slice(0, 10)
        }));
        newSegment.rules = newSegment.rules.filter(({ ruleId }) => !idKeys.includes(ruleId)).concat(partialIdRules);
        idsPayload = constructSegmentSubscribersPayloads(
          [
            ...newSegment.rules.filter(({ ruleId }) => !idKeys.includes(ruleId)),
            ...idRules.map((rule) => ({
              ...rule,
              value: rule.value.slice(10)
            }))
          ],
          REQUEST_BODY_BYTE_LIMIT
        );
      }

      const { segment } = await (newSegment.id
        ? injections.segmentsApi.update(newSegment)
        : injections.segmentsApi.create(newSegment));

      if (idsPayload) {
        await Promise.all(
          idsPayload.map(({ key, ids }) =>
            injections.segmentsApi.addSubscribersToSegment({
              segmentBucketId: segment.buckets[0].id,
              key,
              ids
            })
          )
        );
      }

      dispatch.setModel({ createSegmentLoading: false, id: segment.id });

      getStoreActions().notificationWidget.addNotification({
        type: 'success',
        message: `Segment "${name}" successfully ${newSegment.id ? 'updated' : 'created'}`
      });

      dispatch.resetModel();
      payload && payload.onSuccess && payload.onSuccess(segment);
    } catch (error) {
      await handleUnAuthenticated(error, getStoreActions());

      getStoreActions().notificationWidget.addNotification({
        type: 'error',
        message: `Create Segment Error: ${error.message}`
      });
      dispatch.setModel({ createSegmentLoading: false });
    }
  })
};
