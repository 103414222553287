import Auth from '@aws-amplify/auth';
import { Actions } from 'easy-peasy';
import { AxiosError } from 'axios';
import { FetchParams } from '~api/tools/types';
import { StoreModel } from '.';

export const handleUnAuthenticated = async (error: AxiosError, getStoreActions: Actions<StoreModel>) => {
  if (error?.response?.status === 401) {
    await Auth.signOut();
    localStorage.clear();
    sessionStorage.clear();
    getStoreActions.user.setUserModel({ user: null });
  }
};

export const limitOffsetKey = (params: FetchParams) => `offset-${params?.offset}-limit-${params?.limit}`;
