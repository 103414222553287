import React, { useState } from 'react';
import { Modal, Container } from 'react-bootstrap';
import Cookies from 'js-cookie';
import { notificationCache } from '~store/create-notification/notification-cache';
import { useStoreState, useStoreActions } from '~store/hooks';
import { Tour } from '~components/tour';
import { Button } from '~components/button';
import { StyledModal, PollBuilderHeader, ButtonsDiv } from './styles';
import { pollSteps, POLL_TOUR_COOKIE } from './tour';
import { ListPolls } from './list';
import { ApiPoll } from '~api/polling/types';
import { CreateEditPoll, PollBasicDto } from './create-edit';

interface Props {
  show: boolean;
  closeModal(): void;
}

const cookieExists = !!Cookies.get(POLL_TOUR_COOKIE);

export const PollBuilder: React.FC<Props> = ({ show, closeModal }) => {
  const [showEditView, setShowEditView] = useState(false);
  const [pollToEdit, setPollToEdit] = useState<PollBasicDto | undefined>();
  const { notification } = useStoreState((state) => state.createNotification);
  const { setNotification } = useStoreActions((state) => state.createNotification);

  const handlePollSelect = (poll: ApiPoll) => {
    // allow for de-select
    setNotification({ pollId: poll.id === notification.pollId ? undefined : poll.id });
  };

  const handlePollEdit = (poll: ApiPoll) => {
    setPollToEdit({
      ...poll,
      question: poll.question.translations[0].value,
      answers: poll.pollChoices.map(({ text, tagName }) => ({ text: text.translations[0].value, tagName })),
      voteSuccessMessage: poll?.voteSuccessMessage?.translations[0].value
    });
    setShowEditView(true);
  };

  const toggleCreate = () => {
    setShowEditView(!showEditView);
    setPollToEdit(undefined);
  };

  const handleCancel = () => {
    setNotification({ pollId: notificationCache.getNotification()?.pollId || undefined });
    closeModal();
  };

  const handleSave = () => {
    closeModal();
  };

  const handlePollSaved = () => {
    setShowEditView(false);
  };

  React.useEffect(() => {
    if (show) notificationCache.setNotification(notification);
    else notificationCache.clearCache();
  }, [show]);

  const modalTitle = React.useMemo(() => {
    if (!showEditView) return 'Polls';
    if (!pollToEdit) return 'Create poll';
    return 'Edit poll';
  }, [showEditView, pollToEdit]);

  return (
    <StyledModal animation={cookieExists} show={show} backdrop="static" centered>
      <Modal.Body data-testid="pollBuilderModal">
        <Container fluid>
          <PollBuilderHeader>
            <h2>{modalTitle}</h2>
            <Button testId="pollBuilderCreate" onClick={toggleCreate}>
              {showEditView ? 'Cancel' : 'Create'}
            </Button>
          </PollBuilderHeader>
          {showEditView ? (
            <CreateEditPoll onPollSaved={handlePollSaved} pollToEdit={pollToEdit} />
          ) : (
            <>
              <ListPolls
                selectedPollId={notification.pollId}
                onSelectPoll={handlePollSelect}
                onEditPoll={handlePollEdit}
              />
              <ButtonsDiv>
                <Button testId="pollBuilderCancel" onClick={handleCancel}>
                  Cancel
                </Button>
                <Button testId="pollBuilderSave" onClick={handleSave}>
                  Save
                </Button>
              </ButtonsDiv>
            </>
          )}
        </Container>
      </Modal.Body>
      <Tour id={POLL_TOUR_COOKIE} steps={pollSteps} />
    </StyledModal>
  );
};
