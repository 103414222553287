import React from 'react';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { Select, SelectOption } from '~components/select';
import { useStoreState, useStoreActions } from '~store/hooks';
import { ToggleField } from '~components/inputs';
import { orientationOptions, zoomModeOptions, deviceSelectOptions } from '../../data';
import { VideoOptionsDiv } from './styles';
import { retrieveOrientation } from '../helpers';

interface Props {
  activeDeviceOption: SelectOption<string>;
  handlePreviewDeviceChange(options: SelectOption<string>[]): void;
}

export const VideoOptions: React.FC<Props> = ({ handlePreviewDeviceChange, activeDeviceOption }) => {
  const { notification } = useStoreState((state) => state.createNotification);
  const { setVideoContent } = useStoreActions((state) => state.createNotification);
  const {
    hideVideoOrientationAdminUi,
    hideVideoScrubbingAdminUi,
    hideVideoAspectRatioAdminUi,
    hideVideoFullscreenAdminUi
  } = useFlags();
  const showVideoOptionsSection =
    !hideVideoOrientationAdminUi ||
    !hideVideoScrubbingAdminUi ||
    !hideVideoAspectRatioAdminUi ||
    !hideVideoFullscreenAdminUi;

  const handleAspectChange = ([option]: SelectOption[]) => {
    setVideoContent({
      options: { zoomMode: option.value }
    });
  };

  const handleScrubbingChange = (allowScrubbing: boolean) => {
    setVideoContent({
      options: { allowScrubbing }
    });
  };

  const handleFullScreenChange = (fullScreen: boolean) => {
    setVideoContent({
      options: { fullScreen }
    });
  };

  const handleOrientationChange = ([option]: SelectOption[]) => {
    setVideoContent({
      options: { orientation: option.value }
    });
  };

  return (
    <VideoOptionsDiv>
      <section data-tour="previewDevice">
        <h2>Preview Device</h2>
        <Select
          id="devicePreviewSelect"
          value={[activeDeviceOption]}
          options={deviceSelectOptions}
          onChange={handlePreviewDeviceChange}
        />
      </section>
      {showVideoOptionsSection && (
        <section data-tour="videoOptions">
          <h2>Video Content</h2>
          {!hideVideoOrientationAdminUi && (
            <Select
              id="canvasOrientationSelect"
              label="Orientation:"
              options={orientationOptions}
              value={orientationOptions.filter(({ value }) => value === retrieveOrientation(notification))}
              onChange={handleOrientationChange}
            />
          )}
          {!hideVideoAspectRatioAdminUi && (
            <Select
              id="canvasAspectSelect"
              label="Aspect:"
              options={zoomModeOptions}
              value={zoomModeOptions.filter(
                ({ value }) => value === (notification?.videoContent?.options?.zoomMode || 'zoom')
              )}
              onChange={handleAspectChange}
            />
          )}
          {!hideVideoScrubbingAdminUi && (
            <ToggleField
              id="scrubbingToggleField"
              label="Allow Scrubbing"
              value={notification?.videoContent?.options?.allowScrubbing}
              onChange={handleScrubbingChange}
            />
          )}
          {!hideVideoFullscreenAdminUi && (
            <ToggleField
              id="fullScreenToggleField"
              label="Full Screen"
              value={notification?.videoContent?.options?.fullScreen}
              onChange={handleFullScreenChange}
            />
          )}
        </section>
      )}
    </VideoOptionsDiv>
  );
};
