import { Dayjs, ManipulateType } from 'dayjs';
import { MEASUREMENT_INTERVAL, MEASUREMENT_TYPE } from '@pushologies/database-service/db/entities/measurement';
import { Measurement } from '~api/measurements';

export const INTERVAL_TO_UNIT: Record<MEASUREMENT_INTERVAL, ManipulateType[]> = {
  HOURLY: ['day', 'hour'],
  DAILY: ['month', 'day'],
  MONTHLY: ['year', 'month']
};

export const STICKINESS_UNIT: Record<MEASUREMENT_INTERVAL, string> = {
  HOURLY: '(hau/dau) x 100',
  DAILY: '(dau/mau) x 100',
  MONTHLY: '(mau/yau) x 100'
};

export const sumActiveUsersWithinInterval = (
  date: Dayjs,
  interval: MEASUREMENT_INTERVAL,
  measurements: Measurement[]
) => {
  const limit = date.diff(date.startOf(INTERVAL_TO_UNIT[interval][0]), INTERVAL_TO_UNIT[interval][1]) || 1; // default of 1 for when its same day
  let activeUsers = 0;
  for (let i = 0; i <= limit; i++) {
    const value = measurements.find(
      (measure) =>
        date.subtract(i, INTERVAL_TO_UNIT[interval][1]).startOf(INTERVAL_TO_UNIT[interval][1]).toISOString() ===
          measure.startsAt &&
        MEASUREMENT_TYPE.ACTIVE_SUBSCRIBERS === measure.type &&
        measure.interval === interval
    )?.value;
    if (value) activeUsers += value;
  }
  return activeUsers;
};
