import {
  Entity,
  Column,
  PrimaryGeneratedColumn,
  JoinColumn,
  ManyToOne,
  CreateDateColumn,
  Index,
  DeleteDateColumn
} from 'typeorm';
import { Segment } from './segment';

@Entity('segment_bucket')
export class SegmentBucket {
  @PrimaryGeneratedColumn('uuid')
  id: string;

  @Column()
  segmentId: string;
  @ManyToOne((_) => Segment, (segment) => segment.buckets)
  @JoinColumn()
  @Index()
  segment: Segment;

  @Column({ type: 'integer', default: 0 })
  size: number;

  @Column({ type: 'integer', name: 'max_partition_value', default: 1 })
  maxPartitionValue: number;

  @Column({ name: 'subscriber_list_created_at', type: 'timestamptz', nullable: true })
  subscriberListCreatedAt: Date;

  @Column({ name: 'subscriber_list_deleted_at', type: 'timestamptz', nullable: true })
  subscriberListDeletedAt: Date;

  @CreateDateColumn({ name: 'created_at', type: 'timestamptz' })
  createdAt: Date;

  @DeleteDateColumn({ name: 'deleted_at', type: 'timestamptz' })
  deletedAt?: Date;
}
