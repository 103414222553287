import { Entity, Column, PrimaryGeneratedColumn, ManyToOne, JoinColumn, Index } from 'typeorm';
import { Tenant } from './tenant';

export enum MEASUREMENT_INTERVAL {
  HOURLY = 'HOURLY',
  DAILY = 'DAILY',
  MONTHLY = 'MONTHLY'
}

export enum MEASUREMENT_TYPE {
  ACTIVE_OPTED_IN_SUBSCRIBERS = 'ACTIVE_OPTED_IN_SUBSCRIBERS',
  TOTAL_OPTED_IN_SUBSCRIBERS = 'TOTAL_OPTED_IN_SUBSCRIBERS',
  TOTAL_SUBSCRIBERS = 'TOTAL_SUBSCRIBERS',
  ACTIVE_SUBSCRIBERS = 'ACTIVE_SUBSCRIBERS',
  AVG_SESSION_DURATION = 'AVG_SESSION_DURATION',
  AVG_SESSIONS_PER_SUBSCRIBER = 'AVG_SESSIONS_PER_SUBSCRIBER',
  NOTIFICATIONS_SENT_TOTAL = 'NOTIFICATIONS_SENT_TOTAL',
  NOTIFICATIONS_RECEIVED_UNIQUE = 'NOTIFICATIONS_RECEIVED_UNIQUE',
  NOTIFICATIONS_RECEIVED_TOTAL = 'NOTIFICATIONS_RECEIVED_TOTAL',
  NOTIFICATIONS_DOWNLOADED_UNIQUE = 'NOTIFICATIONS_DOWNLOADED_UNIQUE',
  NOTIFICATIONS_DOWNLOADED_TOTAL = 'NOTIFICATIONS_DOWNLOADED_TOTAL',
  NOTIFICATIONS_OPENED_UNIQUE = 'NOTIFICATIONS_OPENED_UNIQUE',
  NOTIFICATIONS_OPENED_TOTAL = 'NOTIFICATIONS_OPENED_TOTAL',
  NOTIFICATIONS_VIEWED_UNIQUE = 'NOTIFICATIONS_VIEWED_UNIQUE',
  NOTIFICATIONS_VIEWED_TOTAL = 'NOTIFICATIONS_VIEWED_TOTAL',
  NOTIFICATIONS_LINK_OPENED_UNIQUE = 'NOTIFICATIONS_LINK_OPENED_UNIQUE',
  NOTIFICATIONS_LINK_OPENED_TOTAL = 'NOTIFICATIONS_LINK_OPENED_TOTAL'
}

@Entity('measurement')
@Index(['tenantId', 'interval', 'type'])
@Index(['type', 'interval'])
@Index(['tenant', 'startsAt'])
export class Measurement {
  @PrimaryGeneratedColumn('increment')
  id: number;

  @Column()
  tenantId: string;
  @ManyToOne((_) => Tenant, { onDelete: 'CASCADE' })
  @JoinColumn()
  @Index()
  tenant: Tenant;

  @Column({ type: 'enum', enum: MEASUREMENT_TYPE })
  type: MEASUREMENT_TYPE;

  @Column({ type: 'enum', enum: MEASUREMENT_INTERVAL })
  interval: MEASUREMENT_INTERVAL;

  @Column({ name: 'starts_at', type: 'timestamptz' })
  @Index()
  startsAt: Date;

  @Column({ type: 'int' })
  value: number;
}
