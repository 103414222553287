import React, { useEffect, useState } from 'react';
import { StyledInputSection } from './styles';

interface Props {
  value: number;
  onChange(value: number): void;
  initialValue?: number;
  placeholder?: string;
  min?: number;
  max?: number;
  /** By how much should arrow keys increment/decrement value */
  incrementor?: number;
  invalid?: boolean;
  disabled?: boolean;
  label?: string;
  subLabel?: string;
  testId?: string;
  className?: string;
  suffixString?: string;
}

export const NumberField: React.FC<Props> = (props) => {
  const [localValue, setLocalValue] = useState<string>(String(props.value));

  // ensure any changes to our value externally are reflected in the local state
  useEffect(() => {
    if (localValue.length) {
      setLocalValue(String(props.value));
    }
  }, [props.value]);

  const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setLocalValue(event.target.value);
    const inputValue = event.target.value.length ? Number(event.target.value) : null;
    props.onChange(inputValue);
  };

  const handleOnArrowClick = (incrementor: number) => () => {
    const newValue = props.value + incrementor * (props.incrementor ?? 1);
    let valid = true;
    if (props.max && newValue > props.max) valid = false;
    if (props.min && newValue < props.min) valid = false;

    if (valid) {
      setLocalValue(String(newValue));
      props.onChange(newValue);
    }
  };

  return (
    <StyledInputSection
      className={props.className}
      invalid={props.invalid}
      data-testid={props.testId}
      $disabled={props.disabled}
    >
      {props.label && (
        <label>
          {props.label} <span>{props.subLabel}</span>
        </label>
      )}
      <input
        type="number"
        min={props.min}
        max={props.max}
        onChange={handleOnChange}
        placeholder={props.placeholder}
        value={localValue}
        disabled={props.disabled}
      />
      {props.suffixString && <span className="suffix">{props.suffixString}</span>}
      <span className="up" onClick={handleOnArrowClick(1)} />
      <span className="down" onClick={handleOnArrowClick(-1)} />
    </StyledInputSection>
  );
};
