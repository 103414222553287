import { darken, lighten } from 'polished';
import styled, { css } from 'styled-components';

const disabledButton = css`
  a {
    background: ${({ theme }) => lighten(0.25, theme.palette.primaryPlum)} !important;
    pointer-events: none;
    cursor: default !important;

    &:hover {
      cursor: default !important;
      background: ${({ theme }) => lighten(0.75, theme.palette.primaryPlum)};
    }
  }
`;

interface StyledSectionProps {
  hasNext: boolean;
}

/* https://github.com/AdeleD/react-paginate/issues/321 */
export const StyledSection = styled.section<StyledSectionProps>`
  ul {
    position: relative;
    list-style: none;
    width: 100%;
    min-height: 2.5rem;
    display: block;
    text-align: center;
    padding: 0.5rem;
    margin: 0;
    background: ${({ theme }) => theme.palette.lighterGrey};

    li {
      display: inline-block;
      margin: 0 0.5rem;
      font-family: EavesBold;

      a {
        color: ${({ theme }) => theme.palette.textGrey};

        &:focus {
          outline: none;
        }
      }

      &.selected {
        a {
          color: ${({ theme }) => theme.palette.primaryPlum};
        }
      }

      &.previous {
        position: absolute;
        left: 0.5rem;
        margin: 0 !important;

        a {
          border-radius: 2rem;
        }
      }
      &.next {
        position: absolute;
        right: 0.5rem;
        margin: 0 !important;

        a {
          border-radius: 2rem;
        }
      }

      &:hover {
        cursor: pointer;
      }
    }

    li.previous,
    li.next {
      a {
        width: 100%;
        background: ${({ theme }) => theme.palette.primaryPlum};
        transition: background 0.2s linear;
        font-family: EavesBold;
        padding: 0.3rem 2rem;
        color: white;

        &:hover {
          cursor: pointer;
          background: ${({ theme }) => darken(0.05, theme.palette.primaryPlum)};
        }

        &:active,
        &:focus {
          border: none;
          outline: none;
        }
      }

      &.disabled {
        ${disabledButton}
      }

      ${({ hasNext }) =>
        !hasNext &&
        css`
          &.next {
            ${disabledButton}
          }
        `}
    }
  }
`;
