import React from 'react';
import { NumberField } from '../../number-field';
import { Location } from '../types';

interface PromptCircleInputsProps extends Pick<Location, 'latitude' | 'longitude' | 'radius'> {
  handleInputChange(key: keyof Location): (value: any) => void;
  readOnly?: boolean;
}
/** Inputs used in `<MapsPromptButton />` */
export const PromptCircleInputs: React.FC<PromptCircleInputsProps> = ({
  latitude,
  longitude,
  radius,
  handleInputChange,
  readOnly
}) => {
  return (
    <section className="circle">
      <NumberField
        value={latitude}
        onChange={handleInputChange('latitude')}
        label="latitude"
        invalid={!latitude}
        incrementor={0.01}
        disabled={readOnly}
      />
      <NumberField
        value={longitude}
        onChange={handleInputChange('longitude')}
        label="longitude"
        invalid={!longitude}
        className="middle"
        incrementor={0.01}
        disabled={readOnly}
      />
      <NumberField
        value={radius}
        onChange={handleInputChange('radius')}
        label="radius"
        subLabel="(metres)"
        min={200}
        invalid={!radius}
        disabled={readOnly}
      />
    </section>
  );
};

interface CircleInputsProps extends Required<Pick<Location, 'latitude' | 'longitude' | 'radius'>> {
  handleInputChange(key: keyof Location): (value: number) => void;
  testId: string;
  disabled: boolean;
}
/** Inputs used in `<LocationInputSection />` */
export const CircleInputs: React.FC<CircleInputsProps> = (props) => {
  return (
    <section className="circle">
      <NumberField
        value={props.latitude}
        onChange={props.handleInputChange('latitude')}
        label="latitude"
        invalid={!props.latitude}
        incrementor={0.01}
        testId={`${props.testId}Latitude`}
        disabled={props.disabled}
      />
      <NumberField
        value={props.longitude}
        onChange={props.handleInputChange('longitude')}
        label="longitude"
        invalid={!props.longitude}
        incrementor={0.01}
        testId={`${props.testId}Longitude`}
        disabled={props.disabled}
      />
      <NumberField
        value={props.radius}
        onChange={props.handleInputChange('radius')}
        label="radius"
        min={200}
        invalid={!props.radius}
        testId={`${props.testId}Radius`}
        disabled={props.disabled}
      />
    </section>
  );
};
