import { SelectQueryBuilder } from 'typeorm';
import { Subscriber, SUBSCRIBER_DEVICE_TYPES } from '@pushologies/database-service/db/entities/subscriber';
import { SegmentRule } from '../types';
import { Rule } from '.';

export class DeviceRule extends Rule {
  constructor(private rule: SegmentRule<(keyof typeof SUBSCRIBER_DEVICE_TYPES)[]>) {
    super();
  }

  updateQuery(query: SelectQueryBuilder<Subscriber>) {
    if (this.rule.value.includes('ANDROID') && this.rule.value.includes('IOS')) return;

    const typesParam = this.createParameterHash();
    query.andWhere(`subscriber.device_type IN (:...${typesParam})`, {
      [typesParam]: this.rule.value
    });
  }
}
