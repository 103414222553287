import React from 'react';
import { useStoreState, useStoreActions } from '~store/hooks';
import { Button } from '~components/button';
import { Tour } from '~components/tour';
import { SegmentRule } from './rule';
import { SegmentHeader } from './header';
import { RuleList } from './rule-list';
import { segmentSteps } from './tour';
import { RulesWrapperDiv, NoRulesDiv, WrapperDiv, ButtonsWrapperDiv } from './styles';
import { useParams, useLocation } from 'react-router-dom';

interface Props {
  minified?: boolean;
  showTour?: boolean;
  onSave?(): void;
  onCancel?(): void;
}

export const SegmentBuilder: React.FC<Props> = ({ minified, onCancel, onSave, showTour = true }) => {
  const isInvalid = useStoreState(({ createSegment }) => createSegment.isInvalid);
  const { createSegmentLoading, rules } = useStoreState(({ createSegment }) => createSegment);
  const { user } = useStoreState((state) => state.user);
  const { persistSegment } = useStoreActions((state) => state.createSegment);
  const { id } = useParams<{ id: string }>();
  const location = useLocation();
  const SEGMENT_TOUR_COOKIE = `SEGMENT_TOUR_COOKIE_${user?.username}_v1`;

  React.useEffect(() => {
    // “id” from url is only a segmentId when not used on a notification/inApp pages
    if (id && !location?.pathname?.startsWith('/notifications') && !location?.pathname?.startsWith('/in-app')) {
      persistSegment({ id });
    }
  }, [id]);

  return (
    <>
      <WrapperDiv minified={minified}>
        <RuleList rules={rules} />
        <SegmentHeader columnAlignment={minified} />
        <RulesWrapperDiv hasRules={!!rules.length} data-testid="segmentRulesBody" data-tour="rules">
          {rules.map((rule) => (
            <SegmentRule key={rule.id} rule={rule} minified={minified} />
          ))}
          {!rules.length && (
            <NoRulesDiv>
              <h4>Click on rules on the left to add to segment</h4>
              <p>
                <u>Updated Segment Rules For Tags</u>
                <br />
                <br />
                When creating a Segment which makes use of the tag’s ruleset, the following should be considered.
                <br />
                <br />
                If you choose multiple tags in one rule entry, then it’ll be treated as <code>tag1 OR tag2</code> etc,
                meaning for a subscriber to be included in the segment they must have at least one of the tags
                specified.
                <br />
                <br />
                If you choose multiple rule entries, the entries will be treated with AND operator. For example if first
                rule entry has <code>tag1, tag2</code>, second entry has <code>tag3</code>, the segment will be created
                using logic <code>(tag1 OR tag2) AND tag3</code> etc, meaning that for a subscriber to be included in
                the segment they must either have tag1/tag2 AND tag3.
                <br />
                <br />
                For more info, consult the Portal Guide here:{' '}
                <a href="https://docs.pushologies.com/docs/creating-segments">
                  https://docs.pushologies.com/docs/creating-segments
                </a>
              </p>
            </NoRulesDiv>
          )}
        </RulesWrapperDiv>
      </WrapperDiv>
      {minified && (
        <ButtonsWrapperDiv>
          <Button variant="secondary" onClick={onCancel}>
            cancel
          </Button>
          <Button
            loading={createSegmentLoading}
            onClick={onSave}
            disabled={isInvalid}
            testId="saveSegmentButton"
            data-tour="segmentSave"
          >
            save
          </Button>
        </ButtonsWrapperDiv>
      )}
      {showTour && user && <Tour id={SEGMENT_TOUR_COOKIE} steps={segmentSteps} />}
    </>
  );
};
