import styled, { css, keyframes } from 'styled-components';
import { Col } from 'react-bootstrap';
import { Loader } from '~components/loader';
import PreviewBg from '~images/preview_bg.png';
import { InputSection } from '~components/inputs/styles';
import { StyledDropdown } from '~components/select/styles';
import { StyledFormHeader as FormHeader } from '~components/styled/form';

export const CanvasCol = styled(Col)`
  display: flex;
  background: url(${PreviewBg}) repeat;
  border: 1px solid ${({ theme }) => theme.palette.lightGrey};
  height: 20rem;
  padding: 0.75rem 0.7rem;

  section {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 90%;
    overflow: auto;
  }
`;

export const StyledFormHeader = styled(FormHeader)`
  margin-bottom: 0;
  background: ${({ theme }) => theme.palette.primaryMustard};

  button {
    position: absolute;
    padding: 0;
    right: 1rem;
    top: 0.25rem;
    line-height: 1.5rem;
    width: 1rem;
    border: none;
    background: none;
    font-family: EavesRegular;
    color: white;
    font-size: 1.5rem;

    &:hover {
      color: ${({ theme }) => theme.palette.primaryPlum};
    }
  }
`;

interface StyledPProps {
  $index: number;
  $selected: boolean;
  $hasChildren?: boolean;
}
const styledPHeight = 1.25; // rem

export const StyledP = styled.p.attrs({
  role: 'button'
})<StyledPProps>`
  color: ${({ theme, $selected }) =>
    $selected ? `${theme.palette.primaryPlum} !important` : theme.palette.navGreyColor};
  margin: 0;
  font-size: 1rem;
  line-height: ${styledPHeight}rem;
  width: fit-content;
  cursor: pointer;

  ${({ $selected }) =>
    $selected &&
    css`
      animation-fill-mode: none !important;
    `}

  &:hover {
    span {
      display: block;
    }
  }
`;

export const StyledAddNewButton = styled.button`
  position: absolute;
  bottom: 0;
  left: 0;
  text-transform: uppercase;
  background-color: transparent;
  border: none;
  color: ${({ theme }) => `${theme.palette.primaryPlum} !important`};
  margin: 4px 10px;
  padding: 0;
  font-size: 1rem;
  line-height: ${styledPHeight}rem;
  width: fit-content;
  cursor: pointer;

  &:hover {
    span {
      display: block;
    }
  }
`;

const lineKeyframes = keyframes`
  from {
    width: 0;
  }
  to {
    width: 0.75rem;
  }
`;
const colorKeyframes = keyframes`
  from {
    color: transparent;
  }
  to {
    color: #838383;
  }
`;

export const StyledPWithLinePrefix = styled(StyledP)`
  color: transparent;
  animation: ${colorKeyframes} 0.3s ease-in ${({ $index }) => 0.1 + $index * 0.1}s;
  animation-fill-mode: forwards;

  &:before {
    content: '';
    position: absolute;
    height: 1px;
    animation: ${lineKeyframes} 0.3s ease-in ${({ $index }) => 0.1 + $index * 0.1}s;
    animation-fill-mode: forwards;
    background: ${({ theme }) => theme.palette.primaryMustard};
    top: ${({ $index }) => `calc(${$index * styledPHeight}rem + ${styledPHeight / 2}rem)`};
    left: -1.25rem;
  }
`;

const treeSectionLineKeyframes = keyframes`
  from {
    transform: scaleY(0);
  }
  to {
    transform: scaleY(1);
  }
`;
interface TreeSectionProps {
  $valueCount: number;
}

export const TreeSection = styled.section<TreeSectionProps>`
  margin-left: 3rem;

  &:before {
    content: '';
    position: absolute;
    width: 1px;
    height: ${({ $valueCount }) => ($valueCount - 1) * styledPHeight}rem;
    animation: ${treeSectionLineKeyframes} ${({ $valueCount }) => 0.1 + $valueCount * 0.1}s ease-in;
    transform-origin: top;
    top: ${styledPHeight / 2}rem;
    left: -1.25rem;
    background: ${({ theme }) => theme.palette.primaryMustard};
  }
`;

export const StyledLoader = styled(Loader)`
  width: 1rem;
  align-items: flex-start;

  .spinner-border {
    width: 1rem;
    height: 1rem;
    border-color: ${({ theme }) => theme.palette.lightGrey};
    border-right-color: transparent;
  }
`;

export const OptionsCol = styled(Col)`
  padding-right: 0;

  h1 {
    color: ${({ theme }) => theme.palette.textGrey};
    font-size: 1rem;
    margin: 0 0 0.5rem 0;
  }

  svg {
    width: 10px;
    margin-left: 0.25rem;
    stroke-color: ${({ theme }) => theme.palette.textGrey};
    cursor: pointer;
  }

  p {
    text-transform: uppercase;
    color: ${({ theme }) => theme.palette.textGrey};
    font-size: 0.75rem;
    margin: 0 0 0.5rem 0;
  }

  h2 {
    text-transform: uppercase;
    color: ${({ theme }) => theme.palette.lightGrey};
    font-size: 0.8rem;
    margin: 0;
  }

  ${InputSection}, ${StyledDropdown} {
    height: 1.9rem;
    margin: 0.2rem 0 0.5rem 0;
    border-color: ${({ theme }) => theme.palette.lightGrey};
  }
`;

export const ButtonsCol = styled(Col)`
  display: flex;
  justify-content: flex-end;
  padding: 0.5rem 0 0;

  button {
    padding: 0.375rem 4rem;
  }
`;

export const StyledPersonalisationPreview = styled.pre`
  background-color: ${({ theme }) => theme.palette.lighterGrey};
  border-radius: 5px;
  padding: 10px;
  margin-top: 5px;
`;
