import React from 'react';
import { MEASUREMENT_TYPE } from '@pushologies/database-service/db/entities/measurement';
import { useStoreActions, useStoreState } from '~store/hooks';
import dayjs from '~helpers/dayjs';
import { Measurement } from '~api/measurements';
import { Loader } from '~components/loader';
import { StickinessMetrics } from './stickiness-metrics';
import { AverageSessionLengthMetrics } from './session-length-metrics';
import { AverageSessionsPerUserMetrics } from './sessions-per-user-metrics';
import { MetricsChartsSection } from './styles';
import { INTERVAL_TO_UNIT } from '../helpers';

export const MetricsCharts: React.FC = () => {
  const [loading, setLoading] = React.useState(false);
  const [measurements, setMeasurements] = React.useState([]);
  const activeInterval = useStoreState((state) => state.measurements.activeInterval);
  const { fetchMeasurements } = useStoreActions((state) => state.measurements);

  React.useEffect(() => {
    const now = dayjs();

    setLoading(true);
    fetchMeasurements({
      to: now.toDate(),
      from: now.subtract(7, INTERVAL_TO_UNIT[activeInterval][1]).startOf('month').toDate(),
      intervals: [activeInterval],
      types: [
        MEASUREMENT_TYPE.ACTIVE_SUBSCRIBERS,
        MEASUREMENT_TYPE.AVG_SESSIONS_PER_SUBSCRIBER,
        MEASUREMENT_TYPE.AVG_SESSION_DURATION
      ],
      onSuccess(response: Measurement[]) {
        setMeasurements(response);
        setLoading(false);
      }
    });
  }, [activeInterval]);

  return (
    <MetricsChartsSection>
      <Loader loading={loading} testId="sectionLoader" />
      <StickinessMetrics measurements={measurements} />
      <AverageSessionLengthMetrics measurements={measurements} />
      <AverageSessionsPerUserMetrics measurements={measurements} />
    </MetricsChartsSection>
  );
};
