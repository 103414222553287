import { AxiosInstance } from 'axios';
import { ApiPoll, ApiPollWithResults, FetchParams } from './types';
import { instance } from '../tools/axios-instance';
import { refreshAuthToken } from '../tools/auth.decorater';
import { PollBasicDto } from '~pages/notifications/create/poll-builder/create-edit';

const POLLING_API = process.env.POLLING_API_URL;

export class PollingApiClass {
  constructor(private axios: AxiosInstance) {}

  @refreshAuthToken()
  async fetchPolls(params: FetchParams = {}): Promise<{
    totalItems: number;
    polls: ApiPollWithResults[];
  }> {
    const response = await this.axios.get(`${POLLING_API}/polls`, {
      params
    });
    return response.data;
  }

  @refreshAuthToken()
  async fetchPoll(pollId: string): Promise<ApiPollWithResults> {
    const response = await this.axios.get(`${POLLING_API}/poll/${pollId}`);
    return response.data.poll;
  }

  @refreshAuthToken()
  async upsertPoll(poll: Partial<PollBasicDto>): Promise<ApiPoll> {
    // TODO: Support building multi-locale polls (out of scope for v1)
    const pollWithLocales = {
      id: poll.id,
      question: { name: `Poll Question ${poll.id}`, translations: [{ locale: 'en-GB', value: poll.question }] },
      answers: poll.answers.map((eachAnswer, index) => ({
        text: {
          name: `Poll Answer ${poll.id} [${index + 1}]`,
          translations: [
            {
              locale: 'en-GB',
              value: eachAnswer.text
            }
          ]
        },
        tagName: eachAnswer.tagName
      })),
      marginTop: poll.marginTop || 0,
      ...(poll.backgroundImageContentItemId && { backgroundImageContentItemId: poll.backgroundImageContentItemId }),
      ...(poll.headerImageContentItemId && { headerImageContentItemId: poll.headerImageContentItemId }),
      ...(poll.backgroundColor && { backgroundColor: poll.backgroundColor }),
      ...(poll.questionTextCustomisation && { questionTextCustomisation: poll.questionTextCustomisation }),
      ...(poll.choicesTextCustomisation && { choicesTextCustomisation: poll.choicesTextCustomisation }),
      ...(poll.validFrom && { validFrom: poll.validFrom }),
      ...(poll.validUntil && { validUntil: poll.validUntil }),
      ...(poll.voteSuccessMessage && {
        voteSuccessMessage: {
          name: `Poll Vote Success ${poll.id}`,
          translations: [{ locale: 'en-GB', value: poll.voteSuccessMessage }]
        }
      })
    };
    const response = await this.axios.post(`${POLLING_API}/poll`, pollWithLocales).catch((error) => {
      throw new Error(error.response.data.message);
    });
    return response.data;
  }

  @refreshAuthToken()
  async deletePoll(pollId: string) {
    return this.axios
      .delete(`${POLLING_API}/poll/${pollId}`)
      .then((response) => response.data)
      .catch((error) => {
        throw new Error(error.response.data.message);
      });
  }
}

export const pollingApi = new PollingApiClass(instance);
