import { SelectQueryBuilder } from 'typeorm';
import { Subscriber } from '@pushologies/database-service/db/entities';
import { SegmentRule, OperatorValue as Op } from '../types';
import { Rule } from '.';

export class DurationRule extends Rule {
  constructor(private rule: SegmentRule<number>) {
    super();
  }

  updateQuery(query: SelectQueryBuilder<Subscriber>) {
    const durationParam = this.createParameterHash();
    query.andWhere(`subscriber.average_session_duration ${this.getOperator()} :${durationParam}`, {
      [durationParam]: this.rule.value
    });
  }

  private getOperator() {
    switch (this.rule.operator) {
      case Op.greaterThanOrEqual:
        return '>=';
      case Op.greaterThan:
        return '>';
      case Op.lessThan:
        return '<';
      case Op.lessThanOrEqual:
        return '<=';
      default:
        throw new Error(`operator "${this.rule.operator}" not supported`);
    }
  }
}
