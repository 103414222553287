import React, { useState } from 'react';
import { Button } from 'react-bootstrap';
import { throttle } from 'throttle-debounce';
import { CONTENT_ITEM_MEDIA_TYPE } from '@pushologies/common/constants/content-item';
import { ContentItemsFetchParams } from '~api/content-items/types';
import DisplayGridIcon from '~images/display_grid.svg';
import DisplayListIcon from '~images/display_list.svg';
import { TextField } from '~components/inputs';
import { Select, SelectOption } from '~components/select';
import MagGlassIcon from '~images/mag_glass.svg';
import { SelectsCol, TitleCol, StyledFiltersRow, StylingIconDiv } from './styles';
import { DisplayStyling } from './types';
import classnames from 'classnames';

const mediaTypeSelectOption: SelectOption[] = Object.values(CONTENT_ITEM_MEDIA_TYPE).map((item) => ({
  name: item,
  value: item
}));

interface Props {
  displayStyling: DisplayStyling;
  handleFiltersChange(params: ContentItemsFetchParams): void;
  handleDisplayStyleChange(style: DisplayStyling): void;
}

export const FiltersRow: React.FC<Props> = (props) => {
  const [mediaName, setMediaName] = React.useState('');
  const [mediaType, setMediaType] = useState<SelectOption[]>([]);

  const handleNameChange = throttle(250, (event: React.ChangeEvent<HTMLInputElement>) => {
    setMediaName(event.target.value);
  });

  const handleMediaTypeChange = (selected: SelectOption[]) => {
    setMediaType(selected);
  };

  const handleClear = () => {
    setMediaName('');
    setMediaType([]);
  };

  React.useEffect(() => {
    props.handleFiltersChange({
      name: mediaName,
      ...(mediaType?.length && { mediaType: mediaType[0].value })
    });
  }, [mediaName, mediaType]);

  return (
    <StyledFiltersRow>
      <SelectsCol md={6}>
        <Select
          id="mediaTypeSelect"
          value={mediaType}
          options={mediaTypeSelectOption || []}
          placeholder="Media Type"
          inValid={false}
          onChange={handleMediaTypeChange}
        />
      </SelectsCol>
      <TitleCol md={6}>
        <Button onClick={handleClear} data-testid="clearButton">
          Clear
        </Button>
        <div className="mt-sm-2 d-flex">
          <StylingIconDiv className="d-inline-flex">
            <DisplayListIcon
              className={classnames({ active: props.displayStyling === 'list' })}
              onClick={() => props.handleDisplayStyleChange('list')}
            />
            <DisplayGridIcon
              onClick={() => props.handleDisplayStyleChange('grid')}
              className={classnames({ active: props.displayStyling === 'grid' })}
            />
          </StylingIconDiv>
          <TextField
            name="titleFilter"
            placeholder="Search by name"
            value={mediaName}
            onChange={handleNameChange}
            icon={<MagGlassIcon />}
          />
        </div>
      </TitleCol>
    </StyledFiltersRow>
  );
};
