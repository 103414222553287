import { action, persist, thunk } from 'easy-peasy';
import { handleUnAuthenticated } from '~store/helpers';
import { arrayToObject } from '~helpers/arrays';
import { GeofencesModel } from './types';

export * from './types';

export const LIMIT = 20;

export const geofencesModel: GeofencesModel = persist(
  {
    loading: false,
    error: null,
    geofences: {},

    setModel: action((state, payload) => {
      Object.assign(state, payload);
    }),

    fetchAll: thunk(async (actions, payload, { injections, getStoreActions }) => {
      actions.setModel({ loading: true });
      const { onSuccess, onError, ...params } = payload;
      try {
        const { geofences, totalItems } = await injections.geofencesApi.fetchAll(params);
        actions.setModel({
          loading: false,
          error: null,
          geofences: arrayToObject('id', geofences)
        });
        onSuccess(geofences, totalItems);
      } catch (error) {
        await handleUnAuthenticated(error, getStoreActions());

        actions.setModel({ loading: false, error: error.message });
      }
    })
  },
  {
    allow: ['geofences'],
    storage: 'localStorage'
  }
);
