import { AxiosInstance } from 'axios';
import { instance } from '../tools/axios-instance';
import { refreshAuthToken } from '../tools/auth.decorater';
import { ApiTags, FetchTagsParams } from './types';

export class TagsApiClass {
  constructor(private axios: AxiosInstance) {}

  @refreshAuthToken()
  async fetchAll(params: FetchTagsParams = {}): Promise<{ tags: ApiTags[]; totalItems: number }> {
    const response = await this.axios.get('/tags', { params });
    return response.data.response;
  }

  @refreshAuthToken()
  async delete(ids: string[], names: string[]): Promise<{ success: boolean }> {
    const response = await this.axios.delete('/tags', { params: { ids, names } });
    return response.data.response;
  }
}

export const tagsApi = new TagsApiClass(instance);
