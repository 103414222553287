import { action, persist, thunk } from 'easy-peasy';
import { handleUnAuthenticated } from '~store/helpers';
import { arrayToObject } from '~helpers/arrays';
import { UsersModel } from './types';

export * from './types';

export const LIMIT = 20;

export const usersModel: UsersModel = persist(
  {
    loading: false,
    error: null,
    users: {},

    setModel: action((state, payload) => {
      Object.assign(state, payload);
    }),

    fetchUsers: thunk(async (actions, payload, { injections, getStoreActions }) => {
      actions.setModel({ loading: true });
      const { onSuccess, onError, ...params } = payload;

      try {
        const { users, totalItems } = await injections.usersApi.fetch(params);
        actions.setModel({
          loading: false,
          error: null,
          users: arrayToObject('id', users)
        });
        onSuccess(users, totalItems);
      } catch (error) {
        await handleUnAuthenticated(error, getStoreActions());

        actions.setModel({ loading: false, error: error.message });
      }
    }),

    fetchLatestLoggedInUser: thunk(async (actions, payload, { injections, getStoreActions }) => {
      actions.setModel({ loading: true });
      const { onSuccess, tenantId } = payload;

      try {
        const { latestLoggedInUser } = await (tenantId
          ? injections.usersApi.fetchUserAnalyticsByProxy(tenantId)
          : injections.usersApi.fetchUserAnalytics());

        actions.setModel({
          loading: false,
          error: null
        });

        if (onSuccess) await onSuccess(latestLoggedInUser);
      } catch (error) {
        await handleUnAuthenticated(error, getStoreActions());

        actions.setModel({ loading: false, error: error.message });
      }
    }),

    deleteUser: thunk(async (actions, payload, { injections, getStoreActions }) => {
      actions.setModel({ loading: true });
      const { onSuccess, onError, userId } = payload;

      try {
        await injections.usersApi.delete(userId);

        getStoreActions().notificationWidget.addNotification({
          type: 'success',
          message: 'User successfully suspended'
        });

        if (onSuccess) onSuccess();
      } catch (error) {
        await handleUnAuthenticated(error, getStoreActions());

        getStoreActions().notificationWidget.addNotification({
          type: 'error',
          message: error.message
        });

        if (onError) onError(error.message);
      }

      actions.setModel({ loading: false });
    }),

    addUser: thunk(async (actions, payload, { injections, getStoreActions }) => {
      actions.setModel({ loading: true });
      const { onSuccess, onError, user } = payload;

      try {
        await injections.usersApi.add(user);

        getStoreActions().notificationWidget.addNotification({
          type: 'success',
          message: 'User successfully created'
        });

        if (onSuccess) onSuccess();
      } catch (error) {
        await handleUnAuthenticated(error, getStoreActions());

        getStoreActions().notificationWidget.addNotification({
          type: 'error',
          message: error.message
        });

        if (onError) onError(error.message);
      }

      actions.setModel({ loading: false });
    }),

    editUser: thunk(async (actions, payload, { injections, getStoreActions }) => {
      actions.setModel({ loading: true });
      const { onSuccess, onError, user } = payload;

      try {
        await injections.usersApi.edit(user);

        getStoreActions().notificationWidget.addNotification({
          type: 'success',
          message: 'User successfully updated'
        });

        if (onSuccess) onSuccess();
      } catch (error) {
        await handleUnAuthenticated(error, getStoreActions());

        getStoreActions().notificationWidget.addNotification({
          type: 'error',
          message: error.message
        });

        if (onError) onError(error.message);
      }

      actions.setModel({ loading: false });
    }),

    inviteUser: thunk(async (actions, payload, { injections, getStoreActions }) => {
      actions.setModel({ loading: true });
      const { onSuccess, onError, email } = payload;

      try {
        await injections.usersApi.invite(email);

        getStoreActions().notificationWidget.addNotification({
          type: 'success',
          message: 'An invite has been sent'
        });

        if (onSuccess) onSuccess();
      } catch (error) {
        await handleUnAuthenticated(error, getStoreActions());

        getStoreActions().notificationWidget.addNotification({
          type: 'error',
          message: error.message
        });

        if (onError) onError(error.message);
      }

      actions.setModel({ loading: false });
    }),

    reinstateUser: thunk(async (actions, payload, { injections, getStoreActions }) => {
      actions.setModel({ loading: true });
      const { onSuccess, onError, userId } = payload;

      try {
        await injections.usersApi.reinstate(userId);

        getStoreActions().notificationWidget.addNotification({
          type: 'success',
          message: 'User successfully reinstated'
        });

        if (onSuccess) onSuccess();
      } catch (error) {
        await handleUnAuthenticated(error, getStoreActions());

        getStoreActions().notificationWidget.addNotification({
          type: 'error',
          message: error.message
        });

        if (onError) onError(error.message);
      }

      actions.setModel({ loading: false });
    }),

    redactUser: thunk(async (actions, payload, { injections, getStoreActions }) => {
      actions.setModel({ loading: true });
      const { onSuccess, onError, userId } = payload;

      try {
        await injections.usersApi.redact(userId);

        getStoreActions().notificationWidget.addNotification({
          type: 'success',
          message: 'User information redacted'
        });

        if (onSuccess) onSuccess();
      } catch (error) {
        await handleUnAuthenticated(error, getStoreActions());

        getStoreActions().notificationWidget.addNotification({
          type: 'error',
          message: error.message
        });

        if (onError) onError(error.message);
      }

      actions.setModel({ loading: false });
    })
  },
  {
    allow: ['users'],
    storage: 'localStorage'
  }
);
