import React from 'react';
import { StyledContentEditable } from './styles';
import { ContentEditableEvent } from 'react-contenteditable';

interface Props {
  id: string;
  value: string;
  disabled?: boolean;
  /**
   * Stretch the <span> element to fill the whole parent <p>.
   * Necessary when <span> only child in <p> to capture click events for entire <p> element
   */
  fillEntireParentElement: boolean;
  updateStringValueAtIndex(index: number, text: string, key?: string): void;
  handleOnSelect(): void;
}

export const StringValue: React.FC<Props> = (props) => {
  const [text, setText] = React.useState(props.value);
  const index = Number(props.id.substring(props.id.lastIndexOf('_') + 1));

  const handleOnChange = (event: ContentEditableEvent) => {
    setText(event.target.value);
    props.updateStringValueAtIndex(index, event.target.value);
  };

  const handleKeyPress: React.KeyboardEventHandler<HTMLSpanElement> = (event) => {
    if (event.key === 'Backspace') {
      props.updateStringValueAtIndex(index, (event.target as HTMLSpanElement).textContent, event.key);
    }
  };

  const handleOnPaste: React.ClipboardEventHandler<HTMLDivElement> = (event) => {
    const textStr: string = (event.clipboardData ?? (window as any).clipboardData).getData('text');
    props.updateStringValueAtIndex(index, textStr);

    event.stopPropagation();
    event.preventDefault();
  };

  React.useEffect(() => {
    setText(props.value);
  }, [props.value]);

  return (
    <StyledContentEditable
      onSelect={props.handleOnSelect}
      role="textbox"
      id={props.id}
      html={text}
      onChange={handleOnChange}
      onKeyUpCapture={handleKeyPress}
      disabled={props.disabled}
      onPaste={handleOnPaste}
      $stretch={props.fillEntireParentElement}
    />
  );
};
