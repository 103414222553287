import { darken } from 'polished';
import styled, { css } from 'styled-components';
import { breakpoints } from '~styles/mixins';
import { Section } from '../styles';
import { IntervalButtonsDiv } from '../styles';

export const UserGraphSection = styled(Section)`
  position: relative;
  display: flex;
  flex-direction: column;

  @media ${breakpoints.md} {
    margin-top: 1.5rem;
  }
  @media ${breakpoints.sm} {
    margin-top: 2.5rem;
  }

  .VictoryContainer {
    svg {
      & > g {
        text {
          tspan {
            display: inline-block !important;
            fill: ${({ theme }) => theme.palette.navGreyColor} !important;
            font-family: EavesRegular !important;
            text-transform: uppercase !important;
            font-size: 0.7rem !important;
            z-index: 300 !important;
          }
        }

        &:nth-child(3) {
          text {
            transform: translateX(calc(calc(100% / 7) - 6rem)) translateY(-1rem);
          }

          @media ${breakpoints.xl} {
            text {
              transform: translateX(calc(calc(100% / 7) - 7rem)) translateY(-1rem);
            }
          }

          @media ${breakpoints.lg} {
            text {
              transform: translateX(calc(calc(100% / 7) - 4rem)) translateY(-1rem);
            }
          }
        }
      }
    }
  }
`;

export const HelperSection = styled.section`
  position: relative;
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-bottom: 0.25rem;
`;

export const ChartSection = styled.section`
  position: relative;
  display: block;
  flex-grow: 2;
`;

interface HelperSpanProps {
  $color: string;
}

export const HelperSpan = styled.span<HelperSpanProps>`
  font-family: EavesBold;
  color: ${({ theme }) => theme.palette.navGreyColor};
  font-size: 0.9rem;
  text-transform: uppercase;
  position: relative;
  display: flex;
  align-items: center;

  &:before {
    content: '';
    background: ${({ $color }) => $color};
    position: relative;
    display: inline-block;
    width: 1rem;
    height: 1rem;
    margin-right: 0.5rem;
  }
`;

export const NavigationSection = styled.section`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 1rem;
  width: calc(100% - 6.2rem);
  left: 0;
  bottom: 1rem;
  margin: 0 1rem 0 4.5rem;
  background: ${({ theme }) => theme.palette.lighterGrey};
`;

interface NavigationBtnSpanProps {
  $disabled: boolean;
}

export const NavigationBtnSpan = styled.span<NavigationBtnSpanProps>`
  position: absolute;
  height: 0;
  z-index: 150;
  transition: transform 0.1s linear;

  &:first-child {
    border-right: 0.5rem solid
      ${({ theme, $disabled }) => ($disabled ? theme.palette.lightGrey : theme.palette.primaryPlum)};
    border-top: 0.5rem solid transparent;
    border-bottom: 0.5rem solid transparent;
    left: -0.7rem;
  }
  &:last-child {
    border-left: 0.5rem solid
      ${({ theme, $disabled }) => ($disabled ? theme.palette.lightGrey : theme.palette.primaryPlum)};
    border-top: 0.5rem solid transparent;
    border-bottom: 0.5rem solid transparent;
    right: -0.7rem;
  }

  ${({ $disabled }) =>
    !$disabled &&
    css`
      &:hover {
        cursor: pointer;
        border-left-color: ${({ theme }) => darken(0.1, theme.palette.primaryPlum)};
        border-right-color: ${({ theme }) => darken(0.1, theme.palette.primaryPlum)};
        transform: scale(1.2);
      }
    `}
`;

export const GraphIntervalButtonsDiv = styled(IntervalButtonsDiv)`
  position: absolute;
  display: flex;
  right: 0;
  top: -1.75rem;
  width: 30%;
`;
