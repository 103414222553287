import React, { FC, useMemo, useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import { PieChart } from '~components/pie-chart';
import { BarChart } from './bar-chart';
import { Table } from 'react-bootstrap';
import { TableSection } from '~components/styled/table-section';
import { StyledPollResultsContainer, ChartContainer } from './styles';
import { useStoreActions } from '~store/hooks';
import { ApiPollWithResults } from '~api/polling/types';
import { Loader } from '~components/loader';

const mainColor = '#0EA6C5';
const secondaryColor = '#b4ece9';

interface Props {
  pollId: string;
}

export const PollResults: FC<Props> = ({ pollId }) => {
  const [poll, setPoll] = useState<ApiPollWithResults>();
  const [loading, setLoading] = useState(true);
  const { fetchPoll } = useStoreActions((state) => state.polls);

  React.useEffect(() => {
    fetchPoll({
      pollId,
      onSuccess(pollWithResults) {
        setPoll(pollWithResults);
        setLoading(false);
      }
    });
  }, []);

  const answerData = useMemo(
    () =>
      poll?.pollChoices?.map(({ text, voteCount }) => ({
        label: text.translations?.[0]?.value,
        value: voteCount
      })),
    [poll]
  );

  return loading ? (
    <Loader loading={loading} backdrop />
  ) : (
    <StyledPollResultsContainer fluid>
      <Row>
        <h1 data-testId={'pollResultsQuestion'}>{poll.question.translations?.[0]?.value}</h1>
        <span data-testId={'pollResultsTotalVotes'}>({poll.totalVoteCount} responses)</span>
      </Row>
      {poll.totalVoteCount > 0 && (
        <Row>
          <Col xs={12} xl={6} data-testId="pollResultsPieChart">
            <ChartContainer>
              <PieChart data={answerData} />
            </ChartContainer>
          </Col>
          <Col xs={12} xl={6}>
            <ChartContainer>
              <BarChart metrics={answerData} gradient={[mainColor, secondaryColor]} testId="pollResultsBarChart" />
            </ChartContainer>
          </Col>
        </Row>
      )}
      <Row>
        <TableSection>
          <Table responsive>
            <thead>
              <tr>
                <th>answer</th>
                <th style={{ width: '25%' }}>total votes</th>
                <th style={{ width: '25%' }}>percentage of total</th>
              </tr>
            </thead>
            <tbody>
              {answerData.map(({ label, value }) => (
                <tr data-testid="pollResultsRow">
                  <td>{label}</td>
                  <td>{value}</td>
                  <td>{Math.floor((value / poll.totalVoteCount) * 100) || 'N/A'}%</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </TableSection>
      </Row>
    </StyledPollResultsContainer>
  );
};
