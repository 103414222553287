import styled, { css } from 'styled-components';
import { Modal } from 'react-bootstrap';

export const StyledModal = styled(Modal)`
  .modal-dialog {
    .modal-header {
      display: flex;
      justify-content: center;
      background: ${({ theme }) => theme.palette.darkGrey};
      border-radius: 0;
      color: white;
      text-transform: uppercase;
      text-align: center;
      font-size: 1.2rem;
      font-weight: bold;
      padding: 0.6rem;

      button {
        position: absolute;
        right: 1rem;
      }
    }

    .modal-content {
      left: 50%;
      transform: translateX(-50%);
      border: none;
      border-radius: 0;
      width: unset;
      box-shadow: 0 1rem 4rem rgb(0 0 0 / 75%);
    }

    .modal-footer {
      border: none;
      padding-top: 0;
      justify-content: flex-end;
    }
  }
`;

interface IconSpanProps {
  disabled?: boolean;
}

export const IconSpan = styled.span<IconSpanProps>`
  &:hover {
    cursor: pointer;
  }

  ${({ disabled }) =>
    disabled &&
    css`
      pointer-events: none;

      svg {
        path {
          fill: ${({ theme }) => theme.palette.lightGrey} !important;
        }
      }
    `}
`;
