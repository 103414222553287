import styled from 'styled-components';
import { Row } from 'react-bootstrap';
import { Table } from 'react-bootstrap';
import { TableSection } from '~components/styled/table-section';

const topPaddings = `calc(1.5rem * 4)`;

export const StyledRow = styled(Row)`
  display: flex;
  padding: 2rem 2rem 0;
  margin-bottom: 1rem;

  td.dynamism {
    width: 100%;
    text-align: center;
    text-transform: uppercase;
    font-size: 0.8rem;
  }

  ${TableSection} {
    .table-responsive {
      max-height: ${(props) => `calc(
        100vh - (
          ${props.theme.metrics.headerHeight} +
          ${props.theme.metrics.headerOverlayHeight} +
          ${topPaddings}
        )
      )`};
    }
  }
`;

export const StyledDetailsTable = styled(Table)`
  text-transform: uppercase;

  thead {
    font-family: EavesBold;
  }

  tr {
    td {
      font-size: 0.9rem;
      line-height: 0.9rem;

      &:last-child {
        max-height: 3rem;
        overflow-y: scroll;
      }

      & > div {
        max-height: 10rem;
        overflow-y: scroll;

        p {
          margin: 0;
        }
      }
    }
  }
`;
interface DeleteSpanProps {
  isDeleting: boolean;
}

export const DeleteSpan = styled.span<DeleteSpanProps>`
  svg {
    opacity: ${({ isDeleting }) => (isDeleting ? 0 : 1)};
  }

  .spinner-border {
    position: absolute;
    top: 0.15rem;
    left: 0;
    margin: 0;
    color: ${({ theme }) => theme.palette.lighterGrey};
  }
`;
