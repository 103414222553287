import styled from 'styled-components';
import { InputSection } from '../styles';

export const StyledInputSection = styled(InputSection)`
  padding: 0.25rem 0.5rem;

  & > div {
    width: 2.5rem;
    height: 1.25rem;
  }
`;
