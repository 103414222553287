import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import { PasswordVerify } from './verify';
import { PasswordCode } from './code';

export const ForgotPassword: React.FC = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={path}>
        <PasswordVerify />
      </Route>
      <Route path={`${path}/code`}>
        <PasswordCode />
      </Route>
    </Switch>
  );
};
