import randomColor from 'randomcolor';
import React, { FC } from 'react';
import { PieChart as PieChartComponent } from 'react-minimal-pie-chart';

const SLICE_COLOURS = [
  '#96F5F8',
  '#54E8F3',
  '#11D6EE',
  '#32DFF0',
  '#75EFF5',
  '#B8F9FA',
  '#0FB4CD',
  '#C8FBFB',
  '#0C94AB',
  '#0A748A'
];

interface Props {
  data: { label: string; value: number }[];
}

export const PieChart: FC<Props> = ({ data }) => {
  return (
    <PieChartComponent
      data={data
        // only include items with a non-zero value (otherwise floating labels appear on the pie wth no corresponding wedge)
        .filter((eachAnswer) => !!eachAnswer.value)
        .map(({ label, value }, i) => ({
          title: label,
          value,
          color: SLICE_COLOURS[i] || randomColor({ luminosity: 'random', hue: 'random' })
        }))}
      label={({ dataEntry }) => dataEntry.title}
      labelStyle={() => ({
        fill: '#000',
        fontSize: '5px',
        fontFamily: 'sans-serif',
        letterSpacing: 0.1
      })}
      labelPosition={60}
    />
  );
};
