import React from 'react';
import { ReactourStep } from 'reactour';
import { generateTourSelector } from '~helpers/formats';
import { TourDiv } from '~components/tour/styles';
import { StyledPlusIcon, StyledDeleteIcon, StyledSwapIcon } from './styles';

export const CAROUSEL_TOUR_COOKIE = 'CAROUSEL_TOUR_COMPLETE';
let goToCopy: (step: number) => void;

export const carouselSteps: ReactourStep[] = [
  {
    selector: generateTourSelector('carouselImagesUpload'),
    content: ({ goTo }) => {
      goToCopy = goTo;
      return (
        <TourDiv>
          First, if you haven't done so, lets upload some carousel images. If you've already done so or you've just
          uploaded, go to the <a onClick={() => goToCopy(1)}>next step</a>.
        </TourDiv>
      );
    }
  },
  {
    selector: generateTourSelector('carouselImages'),
    content: () => (
      <TourDiv>
        Here is where you can view available carousel images. Filter the images in the input at the top right and
        navigate the images via the pagination controls at the bottom.
        <br />
        <br />
        Click on the <StyledPlusIcon /> icon on a image to add to carousel.
      </TourDiv>
    ),
    action: (node) => {
      node.onmouseup = () => goToCopy(2);
    }
  },
  {
    selector: generateTourSelector('carouselItems'),
    content: ({ goTo }) => (
      <TourDiv>
        This is a carousel item (make sure you've populated this area from <a onClick={() => goTo(1)}>previous step</a>
        ).
        <br />
        <br />
        Delete an item by clicking its <StyledDeleteIcon /> icon.
        <br />
        <br />
        Reorder items by dragging their <StyledSwapIcon /> icon.
        <br />
        <br />
        Make sure you enter a valid url to redirect users to when they click on the image.
      </TourDiv>
    )
  }
];
