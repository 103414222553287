import React from 'react';
import { PersonalisationConfigItem } from '@pushologies/database-service/db/entities/notification';
import { stringToPersonalisationValue, isPersonalisationItemValue } from '../inputs/personalisation/helpers';
import { StyledSpan, StyledTd } from './styles';
import { useStoreState } from '~store/hooks';
import { TENANT_LICENSES } from '@pushologies/common/constants/tenant-licenses';

type SupportedHTMLElement = 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'span' | 'p' | 'section' | 'td';

interface Props {
  as: SupportedHTMLElement;
  primaryColour?: string;
  text: string;
  personalisation: PersonalisationConfigItem[];
  className?: string;
}

const StringWrapper: React.FC<Pick<Props, 'as' | 'className'>> = ({ as, className, children }) => {
  const mapper: Record<SupportedHTMLElement, JSX.Element> = {
    h1: <h1 className={className} children={children} />,
    h2: <h2 className={className} children={children} />,
    h3: <h3 className={className} children={children} />,
    h4: <h4 className={className} children={children} />,
    h5: <h5 className={className} children={children} />,
    h6: <h6 className={className} children={children} />,
    span: <span className={className} children={children} />,
    section: <section className={className} children={children} />,
    p: <p className={className} children={children} />,
    td: <StyledTd className={className} children={children} />
  };

  return mapper[as];
};

export const PersonalisationString: React.FC<Props> = (props) => {
  const { tenant } = useStoreState((state) => state.tenant);
  const licensed = tenant?.licenses && tenant.licenses.includes(TENANT_LICENSES.PERSONALISED_NOTIFICATIONS);

  return (
    <StringWrapper as={props.as} className={props.className}>
      {!licensed
        ? props.text
        : stringToPersonalisationValue(props.text, props.personalisation).map((item) =>
            isPersonalisationItemValue(item) ? (
              <StyledSpan className="personalisation" $primaryColour={props.primaryColour} key={item.id}>
                {item?.config?.name}
              </StyledSpan>
            ) : (
              item
            )
          )}
    </StringWrapper>
  );
};

PersonalisationString.defaultProps = {
  text: ''
};

export const personalisationValueStringToPlainString = (
  str: string = '',
  personalisation?: PersonalisationConfigItem[]
) => {
  const personalisationValue = stringToPersonalisationValue(str, personalisation);
  let text = '';

  for (const item of personalisationValue) {
    if (isPersonalisationItemValue(item)) {
      text += `{{${item?.config?.name}}}`;
    } else text += item;
  }

  return text;
};
