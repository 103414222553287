import { darken } from 'polished';
import styled, { css } from 'styled-components';
import { TilesViewerUpload } from '~components/file-viewers/tiles-upload';
import { StyledFormHeader } from '~components/styled/form';

export const ContentWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 100%;

  span.valueSpan {
    color: ${({ theme }) => theme.palette.primaryPlum};
    font-family: EavesRegular;
    font-size: 0.8rem;
    text-transform: uppercase;
    white-space: nowrap;
  }
`;

export const AddIconSpan = styled.span`
  display: inline-flex;
  width: 1rem;
  height: 1rem;
  top: 0;
  cursor: pointer;

  svg {
    width: 100%;
    height: 100%;

    path {
      fill: ${({ theme }) => theme.palette.primaryMustard};
    }

    &:hover {
      path {
        fill: ${({ theme }) => darken(0.2, theme.palette.primaryMustard)};
      }
    }
  }
`;

export type Placement = 'top-right' | 'bottom-left';
interface StyledTilesViewerUploadProps {
  $placement: Placement;
}

export const StyledTilesViewerUpload = styled(TilesViewerUpload)<StyledTilesViewerUploadProps>`
  position: absolute;
  width: 30rem;
  z-index: 2000;
  background: white;
  padding: 0.5rem;
  box-shadow: -0.1rem 0.1rem 1rem 0.1rem rgba(2, 3, 2, 0.2);

  ${({ $placement }) => {
    if ($placement === 'top-right')
      return css`
        bottom: 0;
      `;
    if ($placement === 'bottom-left')
      return css`
        top: 0;
        right: 0;
      `;
  }}

  ${StyledFormHeader} {
    background: ${({ theme }) => theme.palette.primaryMustard};
  }
`;
