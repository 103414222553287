import styled from 'styled-components';
import { darken } from 'polished';
import { HideScrollbars } from '~styles/mixins';

export const TableSection = styled.section`
  position: relative;
  width: 100%;
  border: 1px solid ${({ theme }) => theme.palette.lighterGrey};
  text-transform: uppercase;

  .table-responsive {
    overflow: auto;
    min-height: 10rem;

    table {
      margin: 0;
      table-layout: fixed;

      th,
      tr,
      td {
        border: none;
        padding: 0.5rem 0.75rem;
      }

      td {
        word-wrap: break-word;
        overflow-x: scroll;
        white-space: nowrap;
        ${HideScrollbars}
      }

      thead {
        position: sticky;
        top: 0;
        z-index: 1;
        font-family: EavesBold;
        color: ${({ theme }) => theme.palette.textGrey};
        background: ${({ theme }) => theme.palette.lighterGrey};
        text-align: center;
        white-space: nowrap;
      }

      tbody {
        font-family: EavesRegular;
        font-size: 0.9rem;
        color: ${({ theme }) => theme.palette.textColor};

        tr {
          border-bottom: 1px solid ${({ theme }) => theme.palette.lighterGrey};
          text-align: center;

          &:last-child {
            border-bottom: none;
          }
        }
      }
    }
  }
`;

export const ActionsTd = styled.td`
  display: flex;
  justify-content: center;
  align-items: center;

  .poll-actions {
    width: 200px;
  }

  & > span {
    position: relative;
    min-width: 1rem;
    margin: 0 0.4rem;

    svg {
      width: 1rem;
      path {
        fill: ${({ theme }) => theme.palette.primaryMustard};
        transition: fill 0.2s linear;
      }
    }

    &.eye {
      svg {
        width: 1.25rem;
      }
    }

    &:hover {
      cursor: pointer;

      svg {
        path {
          fill: ${({ theme }) => darken(0.08, theme.palette.primaryMustard)};
        }
      }
    }
  }
`;
