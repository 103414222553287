export enum CONNECTION_TYPE {
  ALL = 'ALL',
  WIFI = 'WIFI',
  MOBILE = 'MOBILE',
  MOBILE_ROAMING = 'MOBILE_ROAMING',
  MOBILE_NO_ROAMING = 'MOBILE_NO_ROAMING'
}

export enum SUBSCRIBER_EVENT_TYPE {
  SESSION = 'session',
  TAG = 'tag',
  PREFERENCES = 'preferences',
  PURCHASE = 'purchase',
  CUSTOM = 'custom',
  GEOFENCE = 'geofence'
}

export enum GEOFENCE_TRIGGER {
  ENTRY = 'ENTRY',
  EXIT = 'EXIT',
  BOTH = 'BOTH'
}

export enum SUBSCRIBER_NOTIFICATION_EVENT_STATUS {
  PENDING = 'pending',
  RECEIVED = 'received',
  DOWNLOADED = 'downloaded',
  DOWNLOAD_FAILED = 'download_failed',
  OPENED = 'opened',
  VIEWED = 'viewed',
  LINK_OPENED = 'link_opened',
  PUSH_FAILED = 'push_failed',
  GEOFENCE_MAX_LIMIT_REACHED = 'geofence_max_limit_reached'
}
