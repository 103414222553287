import React from 'react';
import CrossIcon from '~images/cross.svg';
import { StyledInputSection, StyledCrossIcon } from './styles';

interface Props {
  chips: string[];
  onChange(chips: string[]): void;
  placeholder?: string;
  invalid?: boolean;
  label?: string;
  testId?: string;
}

export const ChipField: React.FC<Props> = (props) => {
  const inputRef = React.useRef<HTMLInputElement>();

  const handleInputChange = (event: React.KeyboardEvent<HTMLInputElement>) => {
    const text = inputRef.current.value;

    if (event.key === 'Enter' && text.length) {
      props.onChange([text, ...props.chips]);
      inputRef.current.value = '';
    }
  };

  const handleRemoveChip = (index: number) => () => {
    props.onChange([...props.chips.slice(0, index), ...props.chips.slice(index + 1)]);
  };

  const handleClear = () => {
    inputRef.current.value = '';
    props.onChange([]);
    inputRef.current.focus();
  };

  return (
    <StyledInputSection invalid={props.invalid} data-testid={props.testId}>
      <section>
        {props.label && <label>{props.label}</label>}
        <input ref={inputRef} type="text" onKeyUp={handleInputChange} placeholder={props.placeholder} />
      </section>
      <StyledCrossIcon onClick={handleClear} data-testid={`${props.testId}ClearBtn`} />
      {!!props.chips.length && (
        <figure>
          {props.chips.map((chip, i) => (
            <span key={chip + i}>
              {chip} <CrossIcon onClick={handleRemoveChip(i)} data-testid={`${props.testId}DeleteChipBtn`} />
            </span>
          ))}
        </figure>
      )}
    </StyledInputSection>
  );
};
