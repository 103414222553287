import { AxiosInstance } from 'axios';
import { instance } from '../tools/axios-instance';
import { refreshAuthToken } from '../tools/auth.decorater';
import {
  FetchSubscribersParams,
  ApiSubscriber,
  FetchSubscriberEventsParams,
  FetchSubscriberNotificationEventsParams,
  ApiSubscriberEvent,
  ApiSubscriberNotificationEvent,
  DeleteSubscriberTagParams
} from './types';

export class SubscribersApiClass {
  constructor(private axios: AxiosInstance) {}

  @refreshAuthToken()
  async fetch(params: FetchSubscribersParams = {}): Promise<{ subscribers: ApiSubscriber[]; totalItems: number }> {
    const response = await this.axios.get('/subscribers', { params });
    return response.data.response;
  }

  @refreshAuthToken()
  async fetchOne(subscriberId: string): Promise<{ subscriber: ApiSubscriber; totalItems: number }> {
    const response = await this.axios.get('/subscriber', {
      params: { subscriberId }
    });
    return response.data.response;
  }

  @refreshAuthToken()
  async fetchEvents(
    params: FetchSubscriberEventsParams
  ): Promise<{ subscriberEvents: ApiSubscriberEvent[]; totalItems: number }> {
    const response = await this.axios.get('/subscriber-events', { params });
    return response.data.response;
  }

  @refreshAuthToken()
  async fetchNotificationEvents(
    params: FetchSubscriberNotificationEventsParams
  ): Promise<{ notificationEvents: ApiSubscriberNotificationEvent[]; totalItems: number }> {
    const response = await this.axios.get('/subscriber-notification-events', { params });
    return response.data.response;
  }

  @refreshAuthToken()
  async deleteTag(params: DeleteSubscriberTagParams): Promise<{ deleteCount: number }> {
    const response = await this.axios.delete('/subscriber/tag', { params });
    return response.data.response;
  }
}

export const subscriberApi = new SubscribersApiClass(instance);
