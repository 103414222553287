import { SegmentRule } from '@pushologies/segments-service/types';
import { Rule, IdKeys } from '~store/create-segment/types';

// tslint:disable-next-line: cyclomatic-complexity
export function isInvalidValue(rule: SegmentRule) {
  switch (rule.ruleId) {
    case 'duration':
    case 'sessionCounts':
    case 'deviceLanguage':
      return !rule.value || rule.value === '';
    case 'firstSession':
    case 'lastSession':
      return !rule.value;
    case 'appVersion':
    case 'deviceIds':
    case 'device':
    case 'tags':
    case 'subscriberIds':
    case 'customerIds':
      return !Array.isArray(rule.value) || !rule.value.length;
    case 'location':
      return (
        !rule.value ||
        ((!rule.value?.latitude || !rule.value?.longitude || !rule.value?.radius) && !rule.value?.vertices)
      );
    case 'countryCode':
      return !Array.isArray(rule.value) || !rule.value.length;
    case 'notificationPermission':
      return typeof rule.value !== 'boolean';
    case 'locationPermission':
      return typeof rule.value !== 'boolean';
    case 'notificationPushStatus':
      return !(
        rule.value &&
        rule.value.status &&
        rule.value.status.length &&
        rule.value.notificationId &&
        rule.value.notificationId !== ''
      );
    default:
      throw new Error(`rule id "${rule.ruleId}" not recognised`);
  }
}

export const hasIdRules = (rules: Rule[]) => {
  return rules.some((rule) => ([IdKeys.DEVICE, IdKeys.SUBSCRIBER] as string[]).includes(rule.segmentRule.ruleId));
};
