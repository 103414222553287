import React from 'react';
import { Tenant } from '@pushologies/database-service/db/entities';
import { Button, Spinner } from 'react-bootstrap';
import { extractInitials } from '~helpers/formats';
import { TopMetrics } from '~pages/dashboard/user-metrics/helpers';
import { SubTenantDiv, SubTenantActions } from './styles';

export interface Props {
  testId?: string;
  tenant: Tenant;
  measurements: TopMetrics;
  onPrimaryButtonClick?: () => void;
  onSecondaryButtonClick?: () => void;
}

export const SubTenantCard: React.FC<Props> = ({
  testId,
  tenant,
  measurements,
  onPrimaryButtonClick,
  onSecondaryButtonClick
}) => {
  return !measurements ? (
    <Spinner animation="grow" />
  ) : (
    <SubTenantDiv data-testid={testId}>
      <div className="bio-row">
        <span>{extractInitials(tenant.name)}</span>
        <h4>{tenant.name}</h4>
      </div>
      <div className="stat-row">
        <div data-testid="childTenantTotalActiveSubscribers">
          <h3>{measurements.activeOptedInSubscribers.toLocaleString()}</h3>
          <h5>
            Active <br></br>Subscribers
          </h5>
        </div>
        <div data-testid="childTenantOptinSubscribers">
          <h3>{measurements.activeOptedInSubscribers.toLocaleString()}</h3>
          <h5>
            Active <br></br>Opt-in
          </h5>
        </div>
      </div>
      <SubTenantActions>
        <Button variant={'secondary'} onClick={onSecondaryButtonClick}>
          View More
        </Button>
        <Button onClick={onPrimaryButtonClick}>Open Tenant</Button>
      </SubTenantActions>
    </SubTenantDiv>
  );
};
