import { action, persist, thunk, State } from 'easy-peasy';
import { handleUnAuthenticated } from '~store/helpers';
import { arrayToObject } from '~helpers/arrays';
import { ChildTenantsModel } from './types';

export * from './types';

const initialState: State<ChildTenantsModel> = {
  loading: false,
  error: null,
  totalItems: 0,
  childTenants: {},
  sessionApiKey: null
};

export const childTenantsModel: ChildTenantsModel = persist(
  {
    ...initialState,

    setModel: action((state, { childTenants, ...rest }) => {
      childTenants && Object.assign(state.childTenants, arrayToObject('id', childTenants));
      Object.assign(state, rest);
    }),

    resetSuperTenantState: action((state) => {
      Object.assign(state, { ...initialState, isSuperTenant: undefined });
    }),

    fetchChildTenants: thunk(async (actions, payload, { injections, getStoreActions }) => {
      actions.setModel({ loading: true });
      const { onSuccess, onError } = payload || {};

      try {
        const { childTenants, totalItems } = await injections.childTenantsApi.fetchChildTenants();

        actions.setModel({ loading: false, error: null, childTenants, totalItems, isSuperTenant: !!totalItems });

        if (onSuccess) onSuccess(childTenants, totalItems);
      } catch (error) {
        await handleUnAuthenticated(error, getStoreActions());

        getStoreActions().notificationWidget.addNotification({
          type: 'error',
          message: error.message
        });

        actions.setModel({ loading: false, error: error.message });
        if (onError) onError(error.message);
      }
    }),

    fetchSessionApiKey: thunk(async (actions, payload, { injections, getStoreActions }) => {
      actions.setModel({ loading: true });
      const { onSuccess, onError, tenantId, regenerate } = payload || {};

      try {
        const sessionApiKey = await injections.childTenantsApi.fetchSessionApiKey(tenantId, regenerate);

        actions.setModel({ loading: false, error: null, sessionApiKey, isSuperTenant: false });

        if (onSuccess) onSuccess(sessionApiKey);
      } catch (error) {
        await handleUnAuthenticated(error, getStoreActions());

        getStoreActions().notificationWidget.addNotification({
          type: 'error',
          message: error.message
        });

        actions.setModel({ loading: false, error: error.message });
        if (onError) onError(error.message);
      }
    }),

    refreshChildTenantSession: thunk(async (actions, payload, { injections, getStoreActions }) => {
      const { onSuccess, onError, tenantId } = payload || {};

      try {
        const sessionApiKey = await injections.childTenantsApi.refreshChildTenantSession(tenantId);

        if (onSuccess) onSuccess(sessionApiKey);
      } catch (error) {
        await handleUnAuthenticated(error, getStoreActions());

        getStoreActions().notificationWidget.addNotification({
          type: 'error',
          message: error.message
        });

        actions.setModel({ error: error.message });
        if (onError) onError(error.message);
      }
    })
  },
  {
    allow: ['isSuperTenant', 'sessionApiKey'],
    storage: 'localStorage'
  }
);
