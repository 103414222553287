import styled from 'styled-components';
import PreviewBg from '~images/preview_bg.png';
import { CANVAS_ID } from '~contexts/video-builder';
import { InputSection } from '~components/inputs/styles';
import { StyledDropdown } from '~components/select/styles';
import { DeviceScreenMetrics } from '../helpers';
import { DeviceCase } from '../../data';

export const CanvasSection = styled.section`
  position: relative;
  display: block;
  width: 100%;
  height: 67.5%;
  box-shadow: 0px 0px 1rem 0px rgba(0, 0, 0, 0.2) inset;
  background: url(${PreviewBg}) repeat;
`;

export const PreviewDeviceDiv = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  padding-left: 15%;
  padding-bottom: 5%;

  figure {
    position: relative;
    display: block;
    width: 80%;
    margin: 0;

    svg {
      position: relative;
      display: block;
      width: 100%;
      z-index: 10;
      pointer-events: none;
    }
  }
`;

interface ScreenClippingDivProps {
  metrics: DeviceScreenMetrics;
  deviceCase: DeviceCase;
}

export const ScreenClippingDiv = styled.div<ScreenClippingDivProps>`
  position: absolute;
  width: ${({ metrics }) => metrics.screenClippingWidth};
  height: ${({ metrics }) => metrics.screenClippingHeight};
  top: ${({ metrics }) => metrics.screenClippingTop};
  left: ${({ metrics }) => metrics.screenClippingLeft};
  border-radius: ${({ deviceCase }) => (deviceCase.make === 'apple' ? '2rem' : 0)};
  overflow: hidden;

  &:before {
    content: '';
    position: absolute;
    background: #131313;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
  }

  canvas {
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .${CANVAS_ID}, #videoBuilderVideo {
    transform-origin: top left;
    transform: ${({ metrics }) =>
      `scale(${metrics.canvasScaling}) translateX(${metrics.canvasOffsetX}) translateY(${metrics.canvasOffsetY})`};
  }
`;

export const VideoOptionsDiv = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  left: 0;
  top: 0;
  padding: 3rem 0 2rem 1rem;
  z-index: 200;
  height: 100%;

  h2 {
    text-transform: uppercase;
    color: ${({ theme }) => theme.palette.textGrey};
    font-family: EavesBold;
    font-size: 1.2rem;
  }

  ${InputSection}, ${StyledDropdown} {
    height: 1.9rem;
    margin: 0.5rem 0;
  }
  ${StyledDropdown} {
    border: none;

    label {
      color: ${({ theme }) => theme.palette.platinum};
    }

    button {
      background: transparent;
      padding-left: 0.5rem;
      padding-right: 1.5rem;

      &#devicePreviewSelect {
        color: ${({ theme }) => theme.palette.platinum};
      }
    }
  }
  ${InputSection} {
    border: none;
    background: transparent;
  }
`;

export const RotateIconSpan = styled.span`
  position: absolute;
  width: 2rem;
  height: 2rem;
  right: -3rem;
  top: 50%;
  cursor: pointer;

  svg {
    transition: transform 0.3s ease-out;

    path {
      fill: ${({ theme }) => theme.palette.platinum};
    }
  }

  &:hover {
    svg {
      transform: rotate(-20deg);

      path {
        fill: ${({ theme }) => theme.palette.textGrey};
      }
    }
  }
`;
