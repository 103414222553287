import React from 'react';
import { useHistory } from 'react-router';
import { Table } from 'react-bootstrap';
import { StyledContainer } from '~components/styled/container';
import dayjs from '~helpers/dayjs';
import { useStoreActions, useStoreState } from '~store/hooks';
import { TableSection } from '~components/styled/table-section';
import { Pagination } from '~components/pagination';
import { LIMIT } from '~store/users';
import { Loader } from '~components/loader';
import { settingsPageLinks } from '../links';
import { StyledRow } from './styles';
import { UserRow } from './row';
import { FiltersRow, Filters, filtersToApi } from './filters';
import { throttle } from 'throttle-debounce';
import { ApiTenantUser } from '~api/users/types';
import { SelectOption } from '~components/select';

export const Users: React.FC = () => {
  const { visibility } = useStoreState(({ sideNav }) => sideNav);
  const history = useHistory();
  const { setSideNavModel } = useStoreActions((state) => state.sideNav);
  const { fetchUsers } = useStoreActions((state) => state.users);
  const { loading } = useStoreState((state) => state.users);
  const [users, setUsers] = React.useState<ApiTenantUser[]>([]);
  const [totalItems, setTotalItems] = React.useState(10);
  const [offset, setOffset] = React.useState(0);
  const [search, setSearch] = React.useState('');
  const [status, setStatus] = React.useState<SelectOption[]>([]);
  const [role, setRole] = React.useState<SelectOption[]>([]);

  const handleFilterChange = (filters: Partial<Filters>) => {
    const searchChanged = filters.hasOwnProperty('search');
    const statusChanged = filters.hasOwnProperty('status');
    const roleChanged = filters.hasOwnProperty('role');
    searchChanged && setSearch(filters.search);
    statusChanged && setStatus(filters.status || []);
    roleChanged && setRole(filters.role || []);
    // if filters above have been changed reset offset to 0 otherwise to changed offset value
    filters.hasOwnProperty('offset') ? setOffset(filters.offset) : setOffset(0);

    const showDeleted = ['SHOW ALL', 'SUSPENDED'].includes(
      statusChanged ? filters.status?.[0]?.value : status?.[0]?.value
    );

    throttle(
      searchChanged ? 250 : 0,
      fetchUsers({
        limit: LIMIT,
        excludeApiKeyUsers: true,
        ...filtersToApi({ offset, search, status, role, ...filters }),
        // are we returning "suspended" status users? We'll need to include deleted if so
        ...(showDeleted && { withDeleted: true }),
        onSuccess(newUsers, newTotalItems) {
          setUsers(newUsers);
          setTotalItems(newTotalItems);
        }
      })
    );
  };

  const handlePageChange = ({ selected }: { selected: number }) => {
    const newOffset = selected * LIMIT;
    handleFilterChange({ offset: newOffset });
  };

  const handleClear = () => {
    setOffset(0);
    setSearch('');
    history.push({ search: null });

    fetchUsers({
      limit: LIMIT,
      excludeApiKeyUsers: true,
      onSuccess(newUsers, newTotalItems) {
        setUsers(newUsers);
        setTotalItems(newTotalItems);
      }
    });
  };

  const queryParamsToFilters = () => {
    const filters: Partial<Filters> = {};

    const params = new URLSearchParams(history.location.search) as any;
    for (const [key, value] of params.entries()) {
      switch (key) {
        case 'search':
          filters.search = value;
          setSearch(filters.search);
          break;
        case 'offset':
          filters.offset = value;
          setOffset(filters.offset);
          break;
        case 'status':
          filters.status = value;
          setStatus(filters.status);
          break;
        default:
          break;
      }
    }

    return filters;
  };

  React.useLayoutEffect(() => {
    const params: string[] = [];

    search.length && params.push(`search=${search}`);
    !!offset && params.push(`offset=${offset}`);

    if (params.length) history.push({ search: `?${params.join('&')}` });
    else if (history.action !== 'POP') history.push({ search: null });
  }, [offset, search]);

  React.useEffect(() => {
    setSideNavModel({
      topBarLeftTitle: 'Users',
      activeChild: 'users',
      links: settingsPageLinks
    });

    fetchUsers({
      limit: LIMIT,
      excludeApiKeyUsers: true,
      ...filtersToApi(queryParamsToFilters()),
      onSuccess(newUsers, newTotalItems) {
        setUsers(newUsers);
        setTotalItems(newTotalItems);
      }
    });
  }, []);

  return (
    <StyledContainer $sideNavVisible={visibility === 'show'}>
      <FiltersRow
        search={search}
        status={status}
        role={role}
        offset={offset}
        onFilterChange={handleFilterChange}
        onClear={handleClear}
        onUpdateSuccess={() => handleFilterChange({})}
      />
      <StyledRow>
        <TableSection>
          <Loader loading={loading} backdrop />
          <Table responsive>
            <thead>
              <tr>
                <th>Name</th>
                <th>Status</th>
                <th>Role</th>
                <th>Creator</th>
                <th style={{ width: '20%' }}>Created At</th>
                <th style={{ width: '8rem' }}>Actions</th>
              </tr>
            </thead>
            <tbody>
              {users
                .sort((a, b) => dayjs(b.createdAt).diff(a.createdAt))
                .map((user) => (
                  <UserRow key={user.id} user={user} onUpdateSuccess={() => handleFilterChange({})} />
                ))}
            </tbody>
          </Table>
          <Pagination
            pageCount={Math.ceil(totalItems / LIMIT)}
            marginPagesDisplayed={5}
            pageRangeDisplayed={4}
            forcePage={offset / LIMIT}
            breakLabel="..."
            onPageChange={handlePageChange}
          />
        </TableSection>
      </StyledRow>
    </StyledContainer>
  );
};
