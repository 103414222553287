import styled, { css } from 'styled-components';
import { InputSection } from '~components/inputs/styles';

interface ColorPickerInputSectionProps {
  $color?: string;
}

export const ColorPickerInputSection = styled(InputSection)<ColorPickerInputSectionProps>`
  figure {
    position: relative;
    width: 1rem;
    height: 1rem;
    margin: 0;
    background: ${({ $color }) => ($color ? $color : 'transparent')};
    border: 1px solid
      ${({ theme, $disabled }) => {
        if ($disabled) return theme.palette.lightGrey;
        return theme.palette.platinum;
      }};
    cursor: ${({ $disabled }) => ($disabled ? 'no-drop' : 'pointer')};
    pointer-events: ${({ $disabled }) => ($disabled ? 'none' : 'all')};

    ${({ $color, $disabled }) =>
      !$color &&
      css`
        &:before {
          content: 'X';
          position: absolute;
          font-size: 1rem;
          color: ${({ theme }) => ($disabled ? theme.palette.lightGrey : 'red')};
          top: 50%;
          left: 50%;
          transform: translateX(-50%) translateY(-50%);
        }
      `}
  }

  ${({ theme, $disabled }) =>
    $disabled &&
    css`
      span {
        color: ${theme.palette.lightGrey} !important;
      }
    `}
`;

export const ContentWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 100%;

  span {
    color: ${({ theme }) => theme.palette.primaryPlum};
    font-family: EavesRegular;
    font-size: 1rem;
    text-transform: uppercase;
  }
`;

export const PickerWrapperDiv = styled.div`
  z-index: 2000;
`;

export const ColorPickerManualInput = styled.input`
  text-transform: uppercase;
  outline-width: 0;
`;
