import styled from 'styled-components';
import { Row, Col } from 'react-bootstrap';
import { NumberTimeFieldSection } from '~components/inputs/number-time-field/styles';
import { StyledFormHeader as Header } from '~components/styled/form';
import { TabsSection } from '~components/file-viewers/tabs/styles';
import { ViewerDiv } from '~components/file-viewers/tiles/styles';

export const StyledRow = styled(Row)`
  display: flex;
  padding: 2rem 1rem 0;
  margin-bottom: 1rem;
  justify-content: center;

  [div^='col-'] {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
`;

export const CarouselBannerDiv = styled.div`
  /* display: none !important; */
`;

export const ColumnDiv = styled.div`
  position: relative;
  display: inline-block;
  width: 100%;
  color: ${({ theme }) => theme.palette.textColor};
  background: white;
  box-shadow: -0.1rem 0.1rem 1rem 0.1rem rgba(2, 3, 2, 0.2);
  margin-bottom: 1.5rem;

  ${Header} {
    background: ${({ theme }) => theme.palette.primaryMustard};
    height: 2.5rem;
  }

  ${ViewerDiv} {
    height: 20rem;
  }

  header {
    text-align: center;
  }

  ${NumberTimeFieldSection} {
    input {
      font-size: 1.15rem;
    }
  }
`;

export const ColumnContentDiv = styled.div`
  margin: 1rem;
`;

interface TypeCheckboxesSectionType {
  $singleFile: boolean;
}

export const TypeCheckboxesSection = styled.section<TypeCheckboxesSectionType>`
  position: relative;
  display: flex;
  flex-wrap: wrap;
  justify-content: ${({ $singleFile }) => ($singleFile ? 'space-around' : 'flex-start')};
  padding: 0.25rem 1rem;

  & > div {
    // <Checkbox />
    width: ${({ $singleFile }) => ($singleFile ? 'auto' : '50%')};
    padding-left: ${({ $singleFile }) => ($singleFile ? '0' : '1rem')};
  }
`;

export const StyledFormHeader = styled(Header)`
  padding: 0;
  font-size: 1rem;
  height: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 0;
  background: ${({ theme }) => theme.palette.primaryMustard};
`;

export const StyledCol = styled(Col)`
  ${TabsSection} {
    border: none;
    box-shadow: -0.1rem 0.1rem 1rem 0.1rem rgba(2, 3, 2, 0.2);

    .content-div {
      overflow-y: auto;
      height: auto;
    }

    ${ColumnDiv} {
      box-shadow: none;
      margin-bottom: 0;
      border-bottom: 1px solid ${({ theme }) => theme.palette.lighterGrey};
    }

    nav.nav,
    nav.nav a {
      border: none !important;
    }
  }
`;
