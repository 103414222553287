import styled, { css } from 'styled-components';
import { rgba } from 'polished';
import { EnlargedScrollbar } from '~styles/mixins';
import { PaginationSection } from '../styles';

interface TilesViewerDivProps {
  hasPagination: boolean;
  $disabled: boolean;
}

export const TilesViewerDiv = styled.div<TilesViewerDivProps>`
  position: relative;
  width: 100%;

  section.viewer {
    position: relative;
    display: block;
    width: 100%;
    height: calc(100% - ${({ hasPagination }) => (hasPagination ? 2 : 0)}rem);
    border: 1px solid ${({ theme }) => theme.palette.lightGrey};
  }

  ${PaginationSection} {
    ul {
      padding-bottom: 0;
    }
  }

  ${({ $disabled }) =>
    $disabled &&
    css`
      &:after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(255, 255, 255, 0.8);
      }
    `}
`;

interface ViewerDivProps {
  columns: number;
  hasHeader?: boolean;
}

export const ViewerDiv = styled.div<ViewerDivProps>`
  position: relative;
  display: grid;
  grid-template-columns: ${({ columns }) => `repeat(${columns}, calc(100% / ${columns}))`};
  grid-auto-columns: max-content;
  grid-auto-rows: max-content;
  overflow-y: scroll;
  overflow-x: hidden;
  background: white;
  padding: 0.25rem;
  height: calc(100% - ${({ hasHeader }) => (hasHeader ? 2 : 0)}rem);

  ${EnlargedScrollbar}
`;

interface TileArticleProps {
  $selected: boolean;
}

export const TileArticle = styled.article<TileArticleProps>`
  position: relative;
  display: inline-block;
  margin: 0.25rem;
  padding: 0.5rem;
  cursor: pointer;
  background: ${({ $selected, theme }) => ($selected ? `${rgba(theme.palette.primaryPlum, 0.4)} !important` : 'white')};
  transition: 0.2s linear background;

  &:hover {
    background: ${({ theme }) => theme.palette.lighterGrey};
  }

  h4 {
    position: relative;
    display: flex;
    justify-content: space-between;
    font-size: 0.9rem;
    margin: 0;

    span {
      &:first-child {
        white-space: nowrap;
        max-width: calc(100% - 60px);
        overflow-x: hidden;
      }
    }
  }
`;

interface ImageFigureProps {
  url: string;
}

export const ImageFigure = styled.figure<ImageFigureProps>`
  background: url('${({ url }) => url}');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  width: 100%;
  padding-top: 50%; /* hack to keep rectangle aspect ratio */
  margin: 0;
`;
