import React, { useMemo } from 'react';
import Cookies from 'js-cookie';
import { useRouteMatch } from 'react-router-dom';
import { NOTIFICATION_TYPE } from '@pushologies/database-service/db/entities/notification';
import { TENANT_LICENSES } from '@pushologies/common/constants/tenant-licenses';
import { IN_APP_ALIGNMENT_OPTIONS, InApp, INAPP_TYPE } from '@pushologies/common/constants/in-app';
import { Tour } from '~components/tour';
import { Checkbox } from '~components/checkbox';
import { useStoreActions, useStoreState } from '~store/hooks';
import { Notification } from '~store/create-notification';
import { PersonalisationTextField, TextField } from '~components/inputs';
import { StyledFormHeader } from '~components/styled/form';
import { generateTourSelector } from '~helpers/formats';
import {
  NotificationDetailsColumnContentDiv,
  PollStatusIndicator,
  StyledOptedInIcon,
  ClientMetadataInputWrapper
} from './styles';
import { ColumnDiv, StyledCol, TypeCheckboxesSection } from '../styles';
import { CarouselButtonArea } from './carousel-button-area';
import { InAppImageArea } from './in-app-image-area';
import { inAppTypeOptions, notificationsTypeOptions } from '../data';
import { INAPP_TRIGGERS, isNotificationsPage } from '~pages/notifications/helpers';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { Select, SelectOption } from '~components/select';
import { TabBannerView } from './TabBannerView';
import { BannerArea } from './banner-area';
import { ClientMetadataModal } from './ClientMetadataModal';

const VIDEO_BUTTON_COOKIE = 'VIDEO_BUTTON_COMPLETE';
const CAROUSEL_BUTTON_COOKIE = 'CAROUSEL_BUTTON_COMPLETE';
const POLL_BUTTON_COOKIE = 'POLL_BUTTON_COMPLETE';

// tslint:disable:cyclomatic-complexity
export const LeftColumn: React.FC = () => {
  const [showVideoTour, setShowVideoTour] = React.useState(false);
  const [showCarouselTour, setShowCarouselTour] = React.useState(false);
  const [showPollTour, setShowPollTour] = React.useState(false);
  const [showClientMetadataModal, setShowClientMetadataModal] = React.useState(false);
  const { notification } = useStoreState((state) => state.createNotification);
  const { isSuperTenant } = useStoreState((state) => state.childTenants);
  const { setNotification, resetModel, setInApp } = useStoreActions((state) => state.createNotification);
  const { hidePolling, hideInAppVideo, hideRichImage, hideInAppCarouselFe, hideInAppClientMetadata } = useFlags();
  const { tenant } = useStoreState((state) => state.tenant);
  const pollingLicensed = tenant?.licenses && tenant.licenses.includes(TENANT_LICENSES.POLLING_NOTIFICATIONS);
  const router = useRouteMatch();
  const isOnNotificationPage = isNotificationsPage(router);

  const typeOptions = useMemo(() => {
    let options: { label: string; value: string }[] = isOnNotificationPage
      ? notificationsTypeOptions
      : inAppTypeOptions;
    if (hidePolling || isSuperTenant || !pollingLicensed) {
      options = options.filter((eachOption) => eachOption.label !== 'Poll');
    }
    if (hideInAppVideo && !isOnNotificationPage) {
      options = options.filter((eachOption) => eachOption.label !== 'Video');
    }
    if (hideInAppCarouselFe && !isOnNotificationPage) {
      options = options.filter((eachOption) => eachOption.label !== 'Carousel');
    }
    return options;
  }, [isOnNotificationPage, isSuperTenant, hidePolling, hideInAppVideo, hideInAppCarouselFe]);

  const inAppAlignmentSelectOptions: SelectOption[] = Object.values(IN_APP_ALIGNMENT_OPTIONS)
    .map((name) => ({
      name,
      value: name
    }))
    .filter((name) => notification.inApp?.type !== INAPP_TYPE.VIDEO || name.value !== IN_APP_ALIGNMENT_OPTIONS.CENTER);

  const handlePersonalisationInputChange = (name: keyof Notification | keyof InApp) => (text: string) => {
    setNotification({ [name]: text });
  };

  const handlePersonalisationInAppInputChange = (name: keyof InApp) => (text: string) => {
    setInApp({ ...notification.inApp, [name]: text });
  };

  const handleInAppAlignmentChange = ([option]: SelectOption[]) => {
    const alignmentOptions =
      notification?.inApp?.type === INAPP_TYPE.IMAGE
        ? { imageOptions: { alignment: option.value as IN_APP_ALIGNMENT_OPTIONS } }
        : { videoOptions: { alignment: option.value as IN_APP_ALIGNMENT_OPTIONS } };
    setInApp({ ...notification.inApp, ...alignmentOptions });
  };

  const handleNotificationTypeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (notification.type === NOTIFICATION_TYPE.INAPP) {
      resetModel({
        type: NOTIFICATION_TYPE.INAPP,
        ...(([INAPP_TYPE.IMAGE, INAPP_TYPE.VIDEO, INAPP_TYPE.CAROUSEL] as string[]).includes(event.target.value) && {
          message: 'N/A'
        }),
        inApp: {
          type: event.target.value as INAPP_TYPE,
          trigger: notification.inApp.trigger || INAPP_TRIGGERS.APP_OPEN,
          ...(event.target.value === INAPP_TYPE.IMAGE && {
            imageOptions: { alignment: IN_APP_ALIGNMENT_OPTIONS.CENTER }
          }),
          ...(event.target.value === INAPP_TYPE.VIDEO && {
            videoOptions: { alignment: IN_APP_ALIGNMENT_OPTIONS.FULLSCREEN }
          })
        }
      });
    } else {
      resetModel({ type: event.target.value as NOTIFICATION_TYPE });
    }
  };

  const handleClientMetadataChange = (clientMetadata: any) => {
    setNotification({ clientMetadata });
  };

  const isChecked = (option: any): boolean =>
    isOnNotificationPage ? option.value === notification.type : option.value === notification.inApp?.type;

  React.useEffect(() => {
    if (notification?.type === NOTIFICATION_TYPE.VIDEO && !Cookies.get(VIDEO_BUTTON_COOKIE)) setShowVideoTour(true);
    if (notification?.type === NOTIFICATION_TYPE.CAROUSEL && !Cookies.get(CAROUSEL_BUTTON_COOKIE))
      setShowCarouselTour(true);
    if (notification?.type === NOTIFICATION_TYPE.POLL && !Cookies.get(POLL_BUTTON_COOKIE)) setShowPollTour(true);
  }, [notification?.type]);

  // due to our use of personalisation, we need to bind against the "targetUrl" on the notification, then sync the value
  React.useEffect(() => {
    if (notification.type === NOTIFICATION_TYPE.INAPP) {
      setInApp({ ...notification.inApp, targetUrl: notification.targetUrl });
    }
  }, [notification?.targetUrl]);

  return useMemo(
    () => (
      <>
        <StyledCol md="4">
          <ColumnDiv>
            <TypeCheckboxesSection
              data-testid="typeCheckboxes"
              data-tour="type-checkboxes"
              $singleFile={typeOptions.length < 4}
            >
              {typeOptions.map((option) =>
                !hidePolling && notification.type !== NOTIFICATION_TYPE.INAPP && !isSuperTenant ? (
                  <Checkbox
                    key={option.value}
                    id={option.value}
                    onChange={handleNotificationTypeChange}
                    checked={isChecked(option)}
                    value={option.value}
                    label={option.label}
                  />
                ) : (
                  <Checkbox
                    key={option.value}
                    id={option.value}
                    onChange={handleNotificationTypeChange}
                    checked={isChecked(option)}
                    value={option.value}
                    label={option.label}
                  />
                )
              )}
            </TypeCheckboxesSection>
          </ColumnDiv>
          <ColumnDiv>
            <StyledFormHeader>Notification Details</StyledFormHeader>
            <NotificationDetailsColumnContentDiv>
              <PersonalisationTextField
                id="title"
                value={notification.title}
                label="Title:"
                subLabel="(English)"
                onChange={handlePersonalisationInputChange('title')}
              />
              {notification.type !== NOTIFICATION_TYPE.INAPP && (
                <PersonalisationTextField
                  id="subTitle"
                  value={notification.subTitle}
                  label="subTitle:"
                  subLabel="(iOS only)"
                  onChange={handlePersonalisationInputChange('subTitle')}
                />
              )}
              {![INAPP_TYPE.VIDEO, INAPP_TYPE.IMAGE, INAPP_TYPE.CAROUSEL].includes(notification.inApp?.type) && (
                <PersonalisationTextField
                  as="textarea"
                  rows={3}
                  id="message"
                  value={notification.message}
                  label="Message:"
                  subLabel="(English)"
                  onChange={handlePersonalisationInputChange('message')}
                />
              )}
              {[NOTIFICATION_TYPE.STANDARD, NOTIFICATION_TYPE.INAPP].includes(notification.type) &&
                ![INAPP_TYPE.VIDEO, INAPP_TYPE.CAROUSEL].includes(notification.inApp?.type) && (
                  <PersonalisationTextField
                    id="targetUrl"
                    value={notification.targetUrl}
                    label="Target Url:"
                    onChange={handlePersonalisationInputChange('targetUrl')}
                  />
                )}
              {notification?.inApp?.type === INAPP_TYPE.STANDARD && (
                <>
                  <PersonalisationTextField
                    id="ctaLabel"
                    value={notification?.inApp?.ctaLabel}
                    label="CTA Label:"
                    onChange={handlePersonalisationInAppInputChange('ctaLabel')}
                  />
                  <PersonalisationTextField
                    id="closeLabel"
                    value={notification?.inApp?.closeLabel}
                    label="Close Label:"
                    onChange={handlePersonalisationInAppInputChange('closeLabel')}
                  />
                </>
              )}
              {notification?.inApp?.type === INAPP_TYPE.IMAGE && (
                <Select
                  id={'mediaAlignment'}
                  label="Alignment"
                  value={inAppAlignmentSelectOptions.filter((option) =>
                    notification?.inApp?.type === INAPP_TYPE.IMAGE
                      ? notification.inApp?.imageOptions?.alignment === option.value
                      : notification.inApp?.videoOptions?.alignment === option.value
                  )}
                  onChange={handleInAppAlignmentChange}
                  options={inAppAlignmentSelectOptions}
                />
              )}

              {(notification.type !== NOTIFICATION_TYPE.INAPP || !hideInAppClientMetadata) && (
                <>
                  <ClientMetadataInputWrapper onClick={() => setShowClientMetadataModal(true)}>
                    <TextField
                      label={'Metadata:'}
                      value={notification.clientMetadata ? JSON.stringify(notification.clientMetadata) : null}
                      onChange={() => null}
                      name={'clientMetadata'}
                    />
                  </ClientMetadataInputWrapper>

                  <ClientMetadataModal
                    isOpen={showClientMetadataModal}
                    value={notification.clientMetadata}
                    onChange={handleClientMetadataChange}
                    onClose={() => setShowClientMetadataModal(false)}
                  />
                </>
              )}

              {notification.type === NOTIFICATION_TYPE.POLL && (
                <>
                  {notification.pollId ? (
                    <PollStatusIndicator data-testid={'pollSelected'} className={'valid'}>
                      <StyledOptedInIcon /> Poll selected
                    </PollStatusIndicator>
                  ) : (
                    <PollStatusIndicator data-testid={'noPollSelected'}>No poll selected</PollStatusIndicator>
                  )}
                </>
              )}
            </NotificationDetailsColumnContentDiv>
          </ColumnDiv>
          {notification.type === NOTIFICATION_TYPE.CAROUSEL || notification?.inApp?.type === INAPP_TYPE.CAROUSEL ? (
            <CarouselButtonArea />
          ) : notification.type === NOTIFICATION_TYPE.INAPP && notification?.inApp?.type === INAPP_TYPE.IMAGE ? (
            <InAppImageArea />
          ) : notification.type === NOTIFICATION_TYPE.STANDARD && !hideRichImage ? (
            <TabBannerView activeTab={!!notification.richImageContent ? 'richImageTab' : 'bannerTab'} />
          ) : (
            notification.inApp?.type !== INAPP_TYPE.STANDARD &&
            notification.inApp?.type !== INAPP_TYPE.VIDEO && <BannerArea />
          )}
        </StyledCol>
        {showVideoTour && (
          <Tour
            id={VIDEO_BUTTON_COOKIE}
            showNavigation={false}
            showButtons={false}
            showNavigationNumber={false}
            showNumber={false}
            steps={[
              {
                selector: generateTourSelector('builderButton'),
                content: () => (
                  <div data-testid="reactTour">
                    To start creating a video notification, click here to open the video builder!
                  </div>
                ),
                action: (node) => {
                  if (node?.onmousedown) {
                    node.onmousedown = () => setShowVideoTour(false);
                  }
                }
              }
            ]}
          />
        )}
        {showCarouselTour && (
          <Tour
            id={CAROUSEL_BUTTON_COOKIE}
            showNavigation={false}
            showButtons={false}
            showNavigationNumber={false}
            showNumber={false}
            steps={[
              {
                selector: generateTourSelector('builderButton'),
                content: () => (
                  <div data-testid="reactTour">
                    To start creating a carousel notification, click here to open the carousel builder!
                  </div>
                ),
                action: (node) => {
                  if (node?.onmousedown) {
                    node.onmousedown = () => setShowCarouselTour(false);
                  }
                }
              }
            ]}
          />
        )}
        {showPollTour && (
          <Tour
            id={POLL_BUTTON_COOKIE}
            showNavigation={false}
            showButtons={false}
            showNavigationNumber={false}
            showNumber={false}
            steps={[
              {
                selector: generateTourSelector('builderButton'),
                content: () => (
                  <div data-testid="reactTour">
                    To start creating a poll notification, click here to open the poll builder!
                  </div>
                ),
                action: (node) => {
                  if (node?.onmousedown) {
                    node.onmousedown = () => setShowPollTour(false);
                  }
                }
              }
            ]}
          />
        )}
      </>
    ),
    [
      notification.bannerContent,
      notification.richImageContent,
      notification?.bannerContent?.contentItem?.id,
      notification?.richImageContent?.contentItem?.id,
      notification.message,
      notification.title,
      notification.subTitle,
      notification.targetUrl,
      notification.type,
      notification.personalisation,
      notification.pollId,
      notification.inApp,
      notification.clientMetadata,
      showVideoTour,
      showCarouselTour,
      showPollTour,
      showClientMetadataModal,
      typeOptions,
      hidePolling,
      hideRichImage
    ]
  );
};
