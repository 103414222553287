import React, { ReactNode, useState } from 'react';
import { OverlayTrigger, Spinner, Tooltip } from 'react-bootstrap';
import { PromptButton } from '~components/prompt-button';
import { ActionsTd } from '~components/styled/table-section';
import { dateTimeFormat } from '~helpers/formats';
import { useStoreActions, useStoreState } from '~store/hooks';
import DeleteIcon from '~images/delete.svg';
import InviteIcon from '~images/email.svg';
import RedactIcon from '~images/cross.svg';
import ReinstateIcon from '~images/plus.svg';
import { ApiTenantUser } from '~api/users/types';
import { DeleteSpan, StyledTr, StatusSpan, RoleSpan } from './styles';
import { UserForm } from './user-form';
import dayjs from '~helpers/dayjs';

const UsersTooltip = ({
  tooltipText,
  testId,
  children
}: {
  tooltipText: string;
  testId: string;
  children: ReactNode;
}) => {
  const [tooltipPosition, setTooltipPosition] = useState({ top: '0px', left: '0px' });

  const calculateTooltipPosition = (e: any) => {
    setTooltipPosition({
      top: e.clientY + 'px',
      left: e.clientX + 'px'
    });
  };

  return (
    <span className="users-tooltip">
      <span data-testId={testId} onMouseEnter={calculateTooltipPosition}>
        {children}
      </span>
      <span className="users-tooltiptext" style={tooltipPosition}>
        <span>{tooltipText}</span>
      </span>
    </span>
  );
};

interface Props {
  user: ApiTenantUser;
  onUpdateSuccess(): void;
}

// tslint:disable:cyclomatic-complexity
const ActionsColumn: React.FC<Props> = ({ user, onUpdateSuccess }) => {
  const { email: activeUserEmail } = useStoreState((state) => state.user.user);
  const { deleteUser, inviteUser, reinstateUser, redactUser } = useStoreActions((state) => state.users);
  const [isDeleting, setIsDeleting] = React.useState(false);

  const canInvite = !!user.email && ['INVITED', 'EXPIRED', 'WAITING'].includes(user.status);
  const canReinstate = !user.redactedAt && user.status === 'SUSPENDED';
  const canEdit = activeUserEmail !== user.email && !user.redactedAt;
  const canDelete = activeUserEmail !== user.email && ['ACTIVE', 'INVITED', 'EXPIRED', 'WAITING'].includes(user.status);
  const canRedact = !user.redactedAt && user.status === 'SUSPENDED';

  const handleDeleteClick = () => {
    setIsDeleting(true);

    deleteUser({
      userId: user.id,
      onSuccess() {
        onUpdateSuccess();
        setIsDeleting(false);
      },
      onError() {
        setIsDeleting(false);
      }
    });
  };

  const handleInviteClick = () => {
    inviteUser({ email: user.email });
  };

  const handleReinstateClick = () => {
    reinstateUser({ userId: user.id, onSuccess: onUpdateSuccess });
  };

  const handleRedactClick = () => {
    redactUser({ userId: user.id, onSuccess: onUpdateSuccess });
  };

  return (
    <ActionsTd>
      {canInvite && (
        <UsersTooltip
          testId="inviteTooltip"
          tooltipText={['INVITED', 'EXPIRED'].includes(user.status) ? 'Re-invite' : 'Invite'}
        >
          <span onClick={handleInviteClick} data-testid="inviteButton">
            <InviteIcon />
          </span>
        </UsersTooltip>
      )}
      {canEdit && (
        <UsersTooltip testId="editTooltip" tooltipText={'Edit'}>
          <UserForm
            formType="edit"
            onClose={onUpdateSuccess}
            initialData={{
              id: user.id,
              name: user.name,
              email: user.email,
              role: user.role?.name
            }}
          />
        </UsersTooltip>
      )}
      {canDelete && (
        <UsersTooltip testId="suspendTooltip" tooltipText="Suspend">
          <DeleteSpan isDeleting={isDeleting} data-testid="suspendButton">
            <PromptButton
              buttonType="icon"
              buttonIcon={<DeleteIcon />}
              onConfirm={handleDeleteClick}
              confirmButtonText="suspend"
              promptTitle="Confirm Suspension"
            >
              Are you sure you would like to suspend access for user "{user.name}"?
            </PromptButton>
            {isDeleting && <Spinner animation="border" size="sm" />}
          </DeleteSpan>
        </UsersTooltip>
      )}
      {canReinstate && (
        <UsersTooltip testId="reinstateTooltip" tooltipText="Re-instate">
          <span onClick={handleReinstateClick} data-testid="reinstateButton">
            <ReinstateIcon />
          </span>
        </UsersTooltip>
      )}
      {canRedact && (
        <UsersTooltip testId="redactTooltip" tooltipText="Redact">
          <PromptButton
            buttonType="icon"
            buttonIcon={<RedactIcon />}
            onConfirm={handleRedactClick}
            confirmButtonText="redact"
            promptTitle="Confirm Redaction"
            testId={'redactButton'}
          >
            <p>
              Redacting a user account removes all personal information, leaving only the unique ID to identify the
              account in future.
            </p>
            <p>Are you sure you would like to redact all personal information for user "{user.name}"?</p>
            <p>This action cannot be undone.</p>
          </PromptButton>
        </UsersTooltip>
      )}
    </ActionsTd>
  );
};

export const UserRow: React.FC<Props> = ({ user, onUpdateSuccess }) => {
  const statusClassName = user.status.toLowerCase();

  return (
    <StyledTr data-testid="userRow">
      <td>
        {!!user.redactedAt ? (
          <OverlayTrigger
            placement="top"
            overlay={
              <Tooltip id={'redactTooltip'}>
                User info redacted {dayjs(user.redactedAt).format('HH:mm Do MMMM')}
              </Tooltip>
            }
          >
            <span>{user.name}</span>
          </OverlayTrigger>
        ) : (
          user.name
        )}
      </td>
      <td>
        {user.status === 'WAITING' ? (
          <OverlayTrigger
            placement="top"
            overlay={
              <Tooltip id={'inviteTooltip'}>
                Invite scheduled for {dayjs(user.inviteScheduledAt).format('HH:mm Do MMMM')}
              </Tooltip>
            }
          >
            <StatusSpan className={statusClassName}>{user.status}</StatusSpan>
          </OverlayTrigger>
        ) : (
          <StatusSpan className={statusClassName}>{user.status}</StatusSpan>
        )}
      </td>
      <td>
        <RoleSpan className={user?.role?.name.toLowerCase()}>{user?.role?.name}</RoleSpan>
      </td>
      <td>{user?.createdBy?.name}</td>
      <td>{dateTimeFormat(user.createdAt)}</td>
      <ActionsColumn user={user} onUpdateSuccess={onUpdateSuccess} />
    </StyledTr>
  );
};
