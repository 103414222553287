import styled, { keyframes, css } from 'styled-components';
import GridImg from '~images/grid.png';

interface BarChartDivProps {
  $orientation: 'horizontal' | 'vertical';
}
export const BarChartDiv = styled.div<BarChartDivProps>`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  box-shadow: 10px 20px 30px -20px rgba(0, 0, 0, 0.75);
  padding: 0;
  position: relative;
  border: 1px solid ${({ theme }) => theme.palette.darkGrey};
  border-bottom: none;
  width: calc(calc(100% / 6) - 1.25rem);
  max-width: 10rem;
  height: 20rem;

  &:after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: url(${GridImg});
    background-repeat: repeat-y;
    z-index: 5;
  }

  p.label {
    position: absolute;
    z-index: 100;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 0;
    top: -1.2rem;
    left: 0;
    text-align: left;
    font-weight: bold;
    color: ${({ theme }) => theme.palette.apple};
    text-transform: uppercase;
    font-size: 0.8rem;
    font-family: EavesRegular;
  }

  .percentTop {
    position: absolute;
    top: -1.2rem;
    font-size: 0.8rem;
    font-family: EavesBold;
    color: ${({ theme }) => theme.palette.apple};
  }

  .icon {
    position: absolute;
    right: 0.1rem;
    top: 0.08rem;
    width: 1.2rem;
    z-index: 10;
    cursor: pointer;

    svg {
      width: 100%;

      path {
        fill: ${({ theme }) => theme.palette.primaryPlum};
      }
    }
  }

  ${({ $orientation }) =>
    $orientation === 'vertical' &&
    css`
      width: 100%;
      max-width: unset;
      height: 100%;
      flex-direction: column;
      box-shadow: none;

      &:after {
        display: none;
      }

      ${ColumnDiv} {
        width: 100%;
        height: 100%;
        flex-direction: row;
        flex-grow: unset;
        justify-content: flex-start;
      }
    `}
`;

export const ColumnDiv = styled.div`
  display: inline-flex;
  flex-direction: column;
  justify-content: flex-end;
  height: 100%;
  flex-grow: 2;
`;

const barKeyframesY = keyframes`
  from { transform: scaleY(0); }
  to { transform: scaleY(1); }
`;
const barKeyframesX = keyframes`
  from { transform: scaleX(0); }
  to { transform: scaleX(1); }
`;
export type LabelAlign = 'left' | 'center' | 'right';
export type BarOrientations = 'horizontal' | 'vertical';
interface BarDiv {
  $percentage: number;
  $color: string | string[];
  $index: number;
  $totalBars: number;
  $orientation: BarOrientations;
  $align: LabelAlign;
}
export const BarDiv = styled.div<BarDiv>`
  background: ${({ $color }) =>
    Array.isArray($color) ? `linear-gradient(0deg, ${$color[0]} 0%, ${$color[1]} 100%)` : $color};
  height: ${({ $percentage }) => $percentage}%;
  width: 100%;
  animation: ${barKeyframesY} 1s ease-out ${({ $index, $totalBars }) => $totalBars - $index}s;
  transform: scaleY(0);
  animation-fill-mode: forwards;
  transform-origin: bottom;

  span {
    position: absolute;
    left: 0;
    padding: 0 0.5rem;
    width: 100%;
    text-align: ${({ $align }) => $align ?? 'center'};
    color: white;
    font-size: 0.8rem;

    &.sublabel {
      text-transform: uppercase;
      font-family: EavesBold;
      font-size: 1.1rem;
      opacity: 0.2;
      top: 0.6rem;
    }
  }

  ${({ $orientation, $percentage, $index }) =>
    $orientation === 'vertical' &&
    css`
      height: unset;
      width: ${$percentage}%;
      animation: ${barKeyframesX} 1s ease-out ${$index}s;
      animation-fill-mode: forwards;
      transform-origin: left;
      left: 0;
    `}
`;
