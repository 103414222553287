import styled from 'styled-components';
import { Col } from 'react-bootstrap';
import { StyledDropdown } from '~components/select/styles';
import { Button } from '~components/button';
import PreviewBg from '~images/preview_bg.png';
import { ColumnContentDiv, ColumnDiv } from '../styles';

export const StyledCol = styled(Col)`
  max-width: 32rem;
`;

export const StyledColumnDiv = styled(ColumnDiv)`
  ${StyledDropdown} {
    width: 50%;
    min-width: 250px;
    margin: 0 0 1rem 50%;
    transform: translateX(-50%);

    button {
      font-size: 0.8rem;
      padding: 0.1rem 0 0.1rem 0.4rem;
    }
  }
`;

export const StyledButton = styled(Button)`
  z-index: 100;
  top: 1rem;
  left: 50%;
  transform: translateX(-50%);
  position: absolute;
  background: ${({ theme }) => theme.palette.primaryMustard} !important;
`;

export const NotificationPreviewColumnContentDiv = styled(ColumnContentDiv)`
  position: relative;
  box-shadow: 0px 0px 1rem 0px rgba(0, 0, 0, 0.2) inset;
  background: url(${PreviewBg}) repeat;
`;
