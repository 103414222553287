import { Column, Entity, PrimaryGeneratedColumn, CreateDateColumn, ManyToOne, OneToOne, JoinColumn } from 'typeorm';
import { Poll } from '@pushologies/database-service/db/entities/poll';
import { LocaleText } from './locale-text';

export interface PollChoiceMetadata {
  secondaryId: string;
}

@Entity('poll_choice')
export class PollChoice {
  @PrimaryGeneratedColumn('uuid')
  id: string;

  @OneToOne(() => LocaleText, { cascade: ['insert', 'update'], eager: true })
  @JoinColumn()
  text: LocaleText;

  @Column({ type: 'json', nullable: true })
  metadata: PollChoiceMetadata;

  @Column({ name: 'tag_name', nullable: true })
  tagName: string;

  @Column()
  pollId: string;
  @ManyToOne((_) => Poll, (poll) => poll.id, { onDelete: 'CASCADE', orphanedRowAction: 'delete' })
  poll: Poll;

  @CreateDateColumn({ name: 'created_at', type: 'timestamptz' })
  createdAt: Date;
}
