import React from 'react';
import { Button, Modal, Spinner } from 'react-bootstrap';
import { Tenant } from '@pushologies/database-service/db/entities';
import { extractInitials } from '~helpers/formats';
import { useStoreActions } from '~store/hooks';
import { ApiTenantUser } from '~api/users/types';
import { StyledModal } from './styles';
import { ApiNotificationsMetrics } from '~api/notifications/types';
import { ApiUserMetrics } from '~api/notifications/types';
import { TopMetrics } from '~pages/dashboard/user-metrics/helpers';

interface Props {
  testId?: string;
  tenant?: Tenant;
  show: boolean;
  measurements: Record<string, TopMetrics>;
  openRate: Record<string, ApiUserMetrics>;
  sevenDaysOpenRate: Record<string, ApiUserMetrics>;
  closeModal(): void;
}

// tslint:disable-next-line: cyclomatic-complexity
export const SubTenantModal: React.FC<Props> = ({
  show,
  tenant,
  openRate,
  sevenDaysOpenRate,
  measurements,
  closeModal
}) => {
  const [{ fetchLatestLoggedInUser }, { fetchNotificationsMetrics }] = useStoreActions((state) => [
    state.users,
    state.notifications
  ]);
  const [loading, setLoading] = React.useState(true);
  const [latestLoggedInUser, setLatestLoggedInUser] = React.useState<ApiTenantUser>();
  const [notificationsMetrics, setNotificationsMetrics] = React.useState<ApiNotificationsMetrics>();

  React.useEffect(() => {
    setLoading(true);

    if (tenant) {
      fetchLatestLoggedInUser({
        tenantId: tenant.id,
        onSuccess(user) {
          setLatestLoggedInUser(user);
          setLoading(false);
        }
      });

      fetchNotificationsMetrics({
        tenantId: tenant.id,
        onSuccess(lastPushDate, totalPushes, sevenDayPushes, totalDeliverySuccess) {
          setNotificationsMetrics({ lastPushDate, totalPushes, sevenDayPushes, totalDeliverySuccess });
          setLoading(false);
        }
      });
    }
  }, [tenant, show]);

  return (
    <StyledModal show={show} backdrop="static" centered>
      {tenant && (
        <Modal.Body data-testid="videoBuilderModal">
          <div className="bio-row">
            <div>
              <span>{extractInitials(tenant ? tenant.name : 'Tenant Name')}</span>
              <h4>{loading ? 'loading...' : tenant?.name || 'Tenant Name'}</h4>
            </div>
            <div>
              <p>
                Last User:
                <b>{loading ? 'loading...' : latestLoggedInUser?.name || 'N/A'}</b>
              </p>
            </div>
          </div>

          <div>
            <div className="stat-body">
              <div className="stat-label">
                <h4>User Metrics</h4>
              </div>
              <div className="stat-row">
                <div data-testid="childTenantTotalSubscribers">
                  <h3>{loading ? <Spinner animation="grow" /> : openRate[tenant.id]?.opened || 0}%</h3>
                  <h5>
                    Open Rate<br></br>(All Time)
                  </h5>
                </div>
                <div data-testid="childTenantOptinSubscribers">
                  <h3>
                    {loading ? <Spinner animation="grow" /> : sevenDaysOpenRate[tenant.id]?.openedSevenDays || 0}%
                  </h3>
                  <h5>
                    Open Rate<br></br>(7 Days)
                  </h5>
                </div>
                <div data-testid="childTenantTotalActiveSubscribers">
                  <h3> {loading ? <Spinner animation="grow" /> : measurements[tenant.id]?.totalDaySubscribers || 0}</h3>
                  <h5>
                    New Subscriber<br></br>(24 Hours)
                  </h5>
                </div>
              </div>
            </div>
            <div className="stat-body">
              <div className="stat-label">
                <h4>Push Metrics</h4>
              </div>
              <div className="stat-row">
                <div data-testid="childTenantTotalSubscribers">
                  <h3>{loading ? <Spinner animation="grow" /> : notificationsMetrics?.sevenDayPushes || 0}</h3>
                  <h5>
                    Total Pushes<br></br>(7 Days)
                  </h5>
                </div>
                <div data-testid="childTenantOptinSubscribers">
                  <h3>{loading ? <Spinner animation="grow" /> : notificationsMetrics?.totalPushes || 0}</h3>
                  <h5>
                    Total Pushes<br></br>(All Time)
                  </h5>
                </div>
                <div data-testid="childTenantTotalActiveSubscribers">
                  <h3>
                    {loading ? (
                      <Spinner animation="grow" />
                    ) : (
                      Math.round(notificationsMetrics?.totalDeliverySuccess) || 0
                    )}
                    %
                  </h3>
                  <h5>
                    Success Rate<br></br>(Delivery)
                  </h5>
                </div>
              </div>
            </div>
            <div className="date-stat">
              <h5>Date of LAST PUSH: </h5>
              <span>
                {loading ? (
                  <Spinner animation="grow" />
                ) : (
                  new Date(notificationsMetrics?.lastPushDate).toLocaleDateString() || 'N/A'
                )}
              </span>
            </div>
          </div>

          <div className="modal-buttons">
            <Button onClick={closeModal}>Close</Button>
          </div>
        </Modal.Body>
      )}
    </StyledModal>
  );
};
