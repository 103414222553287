import React from 'react';
import { Select, SelectOption } from '~components/select';
import { NumberField } from '~components/inputs/number-field';
import { Location, Vertice } from '../types';

const verticesToSelectOptions = (vertices: Vertice[]) =>
  vertices.map((vertice, index) => ({
    name: `Vertices ${index + 1}`,
    value: { ...vertice, index }
  }));
const updateVerticeAtIndex = (vertices: Vertice[], metric: keyof Vertice, index: number, value: number) => {
  const updatedVertice: Vertice = {
    ...vertices[index],
    [metric]: value
  };
  return [...vertices.slice(0, index), updatedVertice, ...vertices.slice(index + 1)];
};

interface PromptPolygonInputsProps extends Pick<Location, 'vertices'> {
  handleInputChange(key: keyof Location): (value: any) => void;
  readOnly?: boolean;
}
type SelectValue = Vertice & { index: number };
/** Inputs used in `<MapsPromptButton />` */
export const PromptPolygonInputs: React.FC<PromptPolygonInputsProps> = ({ vertices, handleInputChange, readOnly }) => {
  const [activeVertice, setActiveVertice] = React.useState(0);
  const selectOptions: SelectOption<SelectValue>[] = React.useMemo(() => verticesToSelectOptions(vertices), [vertices]);

  const handleOnChangePolygonSelect = (option: SelectOption<SelectValue>[]) => {
    setActiveVertice(option[0].value.index);
  };

  const handleVerticeChange = (metric: keyof Vertice, index: number) => (value: number) => {
    handleInputChange('vertices')(updateVerticeAtIndex(vertices, metric, index, value));
  };

  return (
    <section className="polygon">
      <Select
        id="polygonVertices"
        options={selectOptions}
        value={[selectOptions[activeVertice]]}
        onChange={handleOnChangePolygonSelect}
      />
      <div className="numberFields">
        <NumberField
          value={selectOptions[activeVertice].value.latitude}
          onChange={handleVerticeChange('latitude', selectOptions[activeVertice].value.index)}
          label="latitude"
          incrementor={0.01}
          disabled={readOnly}
        />
        <NumberField
          value={selectOptions[activeVertice].value.longitude}
          onChange={handleVerticeChange('longitude', selectOptions[activeVertice].value.index)}
          label="longitude"
          incrementor={0.01}
          disabled={readOnly}
        />
      </div>
    </section>
  );
};

interface PolygonInputsProps extends Pick<Location, 'vertices'> {
  handleInputChange(key: keyof Location): (value: Vertice[]) => void;
  testId: string;
  disabled: boolean;
}
/** Inputs used in `<LocationInputSection />` */
export const PolygonInputs: React.FC<PolygonInputsProps> = ({ vertices, handleInputChange }) => {
  const [activeVertice, setActiveVertice] = React.useState(0);
  const selectOptions: SelectOption<SelectValue>[] = React.useMemo(() => verticesToSelectOptions(vertices), [vertices]);

  const handleOnChangePolygonSelect = (option: SelectOption<SelectValue>[]) => {
    setActiveVertice(option[0].value.index);
  };

  const handleVerticeChange = (metric: keyof Vertice, index: number) => (value: number) => {
    handleInputChange('vertices')(updateVerticeAtIndex(vertices, metric, index, value));
  };

  return (
    <section className="polygon">
      <Select
        id="polygonVertices"
        options={selectOptions}
        value={[selectOptions[activeVertice]]}
        onChange={handleOnChangePolygonSelect}
      />
      <NumberField
        value={selectOptions[activeVertice].value.latitude}
        onChange={handleVerticeChange('latitude', selectOptions[activeVertice].value.index)}
        label="latitude"
        incrementor={0.01}
      />
      <NumberField
        value={selectOptions[activeVertice].value.longitude}
        onChange={handleVerticeChange('longitude', selectOptions[activeVertice].value.index)}
        label="longitude"
        incrementor={0.01}
      />
    </section>
  );
};
