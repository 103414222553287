import styled, { css } from 'styled-components';
import { breakpoints } from '~styles/mixins';

export const PaginationSection = styled.section`
  height: 2rem;

  ul {
    position: relative;
    list-style: none;
    width: 100%;
    min-height: 2rem;
    display: block;
    text-align: center;
    padding: 0.5rem;
    margin: 0;

    @media ${breakpoints.lg} {
      display: flex;
      justify-content: space-around;
    }

    li {
      display: inline-block;
      margin: 0 0.5rem;
      font-family: EavesRegular;

      a {
        color: ${({ theme }) => theme.palette.textGrey};

        &:focus {
          outline: none;
        }
      }

      &.selected {
        a {
          color: ${({ theme }) => theme.palette.primaryPlum};
        }
      }

      &.previous,
      &.next {
        margin: 0 1.5rem !important;

        @media ${breakpoints.lg} {
          margin: 0 !important;
        }

        &:hover {
          a {
            font-family: EavesBold;
          }
        }
      }

      &:hover {
        cursor: pointer;
      }
    }
  }
`;

export const StyledHeader = styled.header`
  position: relative;
  display: flex;
  align-items: center;
  background: ${({ theme }) => theme.palette.primaryMustard};
  color: white;
  text-transform: uppercase;
  padding: 0.25rem 0.5rem;
  font-size: 1rem;
  margin: 0;
  line-height: 1rem;
`;

const placeholderStyles = css`
  font-family: EavesRegular;
  color: ${({ theme }) => theme.palette.lighterGrey};
  text-transform: uppercase;
  font-size: 0.8rem;
`;

export const InputDiv = styled.div`
  position: relative;
  border: 1px solid ${({ theme }) => theme.palette.lightGrey};
  height: 1.4rem;
  display: inline-flex;

  input {
    position: relative;
    border-radius: 0;
    border: none;
    padding: 0.2rem 0.3rem;
    font-size: 0.9rem;
    width: 100%;
    height: 100%;
    font-family: EavesRegular;

    ::-webkit-input-placeholder {
      /* Edge */
      ${placeholderStyles}
    }
    :-ms-input-placeholder {
      /* Internet Explorer 10-11 */
      ${placeholderStyles}
    }
    ::placeholder {
      ${placeholderStyles}
    }
  }

  svg {
    position: absolute;
    right: 0.25rem;
    width: 1rem;
    height: 1rem;
    top: 50%;
    transform: translateY(-50%);
  }
`;
