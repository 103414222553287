import React from 'react';
import { bytesToSize } from '~helpers/files';
import { Loader } from '~components/loader';
import { ViewerPagination } from '../pagination';
import { ViewerHeader } from '../header';
import { isTileFile, Props, TileFile } from './types';
import { TilesViewerDiv, ViewerDiv, TileArticle, ImageFigure } from './styles';

export * from './types';

/** Renders files in a tile mode displaying the name, size and thumbnail of the files */
export const TilesViewer = <T extends { id: string }>(props: React.PropsWithChildren<Props<T>>) => {
  const [selected, setSelected] = React.useState<string[]>(props.selectedFileIds || []);
  const hasHeader = !!(props.title || props.filterValue);

  const handleSelect = (file: TileFile) => () => {
    const idx = selected.findIndex((id) => file.id === id);
    let newSelected;

    if (idx >= 0) {
      newSelected = [...selected.slice(0, idx), ...selected.slice(idx + 1)];
    } else {
      newSelected = props.multiSelect ? selected.concat([file.id]) : [file.id];
    }

    if (props.multiSelect && props.multiSelectLimit) {
      newSelected = newSelected.slice(0, props.multiSelectLimit);
    }

    setSelected(newSelected);
    props.onSelect(newSelected);
  };

  React.useEffect(() => {
    if (props.selectedFileIds) {
      setSelected(props.selectedFileIds);
    } else {
      setSelected([]);
    }
  }, [props.selectedFileIds]);

  return (
    <TilesViewerDiv
      data-testid={props.testId}
      data-tour={props.tourId}
      className={props.className}
      hasPagination={!!props.pagination}
      $disabled={props.disabled}
    >
      <Loader loading={props.loading} backdrop />
      <section className="viewer">
        {hasHeader && (
          <ViewerHeader
            title={props.title}
            testId={`${props.testId}Header`}
            onTextChange={props.onFilterValueChange}
            filterValue={props.filterValue}
            filterPlaceholder={props.filterPlaceholder}
          />
        )}
        <ViewerDiv columns={props.columns || 2} hasHeader={hasHeader}>
          {props.files.map((file, i) =>
            props.renderTile
              ? props.renderTile(file, selected.includes(file.id), i)
              : isTileFile(file) && (
                  <TileArticle
                    onClick={handleSelect(file)}
                    key={file.id}
                    $selected={selected.includes(file.id)}
                    data-testid={`${props.testId}Tile`}
                  >
                    <h4>
                      <span>{file.name}</span> <span>{bytesToSize(file.size)}</span>
                    </h4>
                    <ImageFigure url={file.thumbnailUrl} key={file.thumbnailUrl} aria-label={file.name} />
                  </TileArticle>
                )
          )}
        </ViewerDiv>
      </section>
      {props.pagination && <ViewerPagination {...props.pagination} testId={`${props.testId}Pagination`} />}
    </TilesViewerDiv>
  );
};
