import {
  Column,
  CreateDateColumn,
  Entity,
  Index,
  JoinColumn,
  ManyToOne,
  OneToOne,
  PrimaryGeneratedColumn
} from 'typeorm';
import { Notification } from './notification';
import { Segment } from './segment';
import { Tenant } from './tenant';
import { Coordinates, lonLatPointToPoint, pointToLonLat } from '../../helpers/point-location-transformer';
import { GEOFENCE_TRIGGER } from '@pushologies/common/constants/subscriber';
import {
  lonLatToPolygon,
  polygonToLonLat,
  Vertices
} from '@pushologies/database-service/helpers/polygon-location-transformer';

@Entity('geofence')
export class Geofence {
  @PrimaryGeneratedColumn('uuid')
  id: string;

  @Column({
    type: 'geography',
    transformer: {
      from: (value) => pointToLonLat(value),
      to: (value) => lonLatPointToPoint(value)
    }
  })
  location: Coordinates;

  @Column({ type: 'float' })
  radius: number;

  @Column({ type: 'timestamptz', nullable: true })
  endDate: Date;

  @Column({ type: 'timestamptz', nullable: true })
  startDate: Date;

  @Column({ nullable: true })
  segmentId?: string;
  @OneToOne((_) => Segment, { onDelete: 'CASCADE' })
  @JoinColumn()
  segment?: Segment;

  @Column({ nullable: true })
  notificationId?: string;
  @OneToOne((_) => Notification, { onDelete: 'CASCADE' })
  @JoinColumn()
  notification?: Notification;

  @Column()
  tenantId: string;
  @ManyToOne((_) => Tenant, (tenant) => tenant.id, { onDelete: 'CASCADE' })
  @JoinColumn()
  @Index()
  tenant: Tenant;

  @Column({ type: 'enum', enum: GEOFENCE_TRIGGER, default: GEOFENCE_TRIGGER.BOTH, nullable: true })
  trigger: GEOFENCE_TRIGGER;

  @CreateDateColumn({ name: 'created_at', type: 'timestamptz' })
  createdAt: Date;

  @Column({ type: 'boolean', default: false })
  repeat: boolean;

  @Column({
    type: 'geometry',
    nullable: true,
    transformer: {
      from: (value) => polygonToLonLat(value),
      to: (value) => lonLatToPolygon(value)
    }
  })
  vertices?: Vertices;
}
