import React from 'react';
import { useTheme } from 'styled-components';
import { VictoryPie } from 'victory';
import NumberEasing from 'react-number-easing';
import { MEASUREMENT_INTERVAL, MEASUREMENT_TYPE } from '@pushologies/database-service/db/entities/measurement';
import dayjs from '~helpers/dayjs';
import { Measurement } from '~api/measurements';
import { ChartsWrapperDiv, ChartWrapperDiv, MetricsHeaderDiv, MetricsWrapperDiv } from './styles';
import { sumActiveUsersWithinInterval, INTERVAL_TO_UNIT, STICKINESS_UNIT } from '../helpers';
import { useStoreState } from '~store/hooks';

interface PieChartProps {
  label: string;
  percentage: number;
}

const PieChart: React.FC<PieChartProps> = ({ label, percentage }) => {
  const data = [{ y: percentage }, { y: 100 - percentage }];

  return (
    <ChartWrapperDiv data-testid="pieChart">
      <figure>
        <VictoryPie data={data} innerRadius={95} animate={true} />
        <figcaption>
          <NumberEasing value={percentage || 0} speed={1500} />%
        </figcaption>
      </figure>
      <p>{label}</p>
    </ChartWrapperDiv>
  );
};

interface StickinessMetricsProps {
  measurements: Measurement[];
}

export const StickinessMetrics: React.FC<StickinessMetricsProps> = ({ measurements = [] }) => {
  const { palette } = useTheme();
  const activeInterval = useStoreState((state) => state.measurements.activeInterval);
  const now = dayjs().utc();
  const unit = INTERVAL_TO_UNIT[activeInterval][1];
  const activeUsersSum = sumActiveUsersWithinInterval(
    now.subtract(1, unit).startOf(unit),
    activeInterval,
    measurements
  );

  const metrics: PieChartProps[] = [];
  for (let i = 7, min = 1; i >= min; i--) {
    const date = now.subtract(i, unit).startOf(unit);
    const label = activeInterval === MEASUREMENT_INTERVAL.DAILY ? date.format('dddd')[0] : date.format('MMM');
    const measurement = measurements.find(
      ({ type, interval, startsAt }) =>
        type === MEASUREMENT_TYPE.ACTIVE_SUBSCRIBERS && interval === activeInterval && startsAt === date.toISOString()
    );
    metrics.push({
      label,
      percentage: Math.floor((measurement?.value / activeUsersSum) * 100) || 0
    });
  }

  const averageStickiness = Math.floor(metrics.reduce((prev, curr) => prev + curr.percentage, 0) / 7);

  return (
    <MetricsWrapperDiv>
      <MetricsHeaderDiv $headingColor={palette.red} data-testid="stickinessHeader">
        <h6>{`stickiness = ${STICKINESS_UNIT[activeInterval]}`}</h6>
        <section>
          <p className="title">average</p>
          <p className="number">
            <NumberEasing value={averageStickiness || 0} speed={1500} />%
          </p>
        </section>
      </MetricsHeaderDiv>
      <ChartsWrapperDiv>
        <svg style={{ position: 'absolute' }}>
          <defs>
            <linearGradient id="VictoryPieGradient" x1="0%" y1="0%" x2="0%" y2="100%">
              <stop offset="0%" stopColor="#a71680" />
              <stop offset="100%" stopColor="#FC6A36" />
            </linearGradient>
          </defs>
        </svg>
        {metrics.map((metric, i) => (
          <PieChart key={`${metric.percentage}${i}${metric.label}`} {...metric} />
        ))}
      </ChartsWrapperDiv>
    </MetricsWrapperDiv>
  );
};
