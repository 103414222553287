import React, { useCallback } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Form, Container, Row, Col, Spinner } from 'react-bootstrap';
import { StyledFormHeader, StyledFormButton } from '~components/styled/form';
import { useStoreActions, useStoreState } from '~store/hooks';
import Logo from '~images/logo-strapline.svg';
import { LogoWrapperDiv, StyledForm, ErrorMessageP, StyledRow } from './styles';
import { formInputsToNameValueObject } from '~helpers/forms';

export const PasswordCode: React.FC = () => {
  const history = useHistory();
  const { state } = useLocation<{ email?: string }>();
  const { error, loading } = useStoreState(({ user }) => user);
  const [{ setUserModel, forgotPasswordSubmit }, { setSideNavModel }] = useStoreActions(({ user, sideNav }) => [
    user,
    sideNav
  ]);
  const formRef = React.useRef();

  const onSubmit = useCallback(async (e: React.MouseEvent | React.KeyboardEvent) => {
    e.preventDefault();
    e.stopPropagation();

    const { email, code, newPassword, confirmNewPassword } = formInputsToNameValueObject(formRef.current, [
      'email',
      'code',
      'newPassword',
      'confirmNewPassword'
    ]);

    if (newPassword !== confirmNewPassword) {
      setUserModel({ error: 'Password fields do not match' });
      return;
    }

    forgotPasswordSubmit({
      email: state?.email || email,
      code,
      newPassword,
      onSuccess() {
        history.push('/login');
      }
    });
  }, []);

  React.useEffect(() => {
    setSideNavModel({ topBarLeftTitle: 'Forgot Password', activeChild: 'forgotPassword' });
    return () => setUserModel({ loading: false, error: null });
  }, []);

  return (
    <Container id="page-container">
      <StyledRow className="justify-content-md-center">
        <Col md="auto">
          <LogoWrapperDiv>
            <Logo />
          </LogoWrapperDiv>
          <StyledForm ref={formRef} data-testid="submitResetCodeForm">
            <StyledFormHeader>complete reset password</StyledFormHeader>
            {!state?.email && (
              <Form.Group controlId="email">
                <Form.Label>Email</Form.Label>
                <Form.Control type="email" name="email" />
              </Form.Group>
            )}
            <Form.Group controlId="code">
              <Form.Label>Code</Form.Label>
              <p>Verification code which was sent to your email</p>
              <Form.Control type="text" name="code" />
            </Form.Group>
            <Form.Group controlId="newPassword">
              <Form.Label>New Password</Form.Label>
              <Form.Control type="password" name="newPassword" />
            </Form.Group>
            <Form.Group controlId="confirmNewPassword">
              <Form.Label>Confirm New Password</Form.Label>
              <Form.Control type="password" name="confirmNewPassword" />
            </Form.Group>
            <Form.Group>
              <StyledFormButton variant="primary" onClick={onSubmit} disabled={loading}>
                {loading ? <Spinner animation="border" size="sm" /> : 'Reset'}
              </StyledFormButton>
            </Form.Group>
            <Row>{error && <ErrorMessageP data-testid="errorMessage">{error}</ErrorMessageP>}</Row>
          </StyledForm>
        </Col>
      </StyledRow>
    </Container>
  );
};
