import React from 'react';
import { Container } from 'react-bootstrap';
import { useStoreActions } from '~store/hooks';
import { StyledRow } from './styles';
import { UploadCol } from './upload-col';
import { settingsPageLinks } from '../links';
import { ViewCol } from './view-col';

export const Credentials: React.FC = () => {
  const { setSideNavModel } = useStoreActions(({ sideNav }) => sideNav);

  React.useEffect(() => {
    setSideNavModel({ topBarLeftTitle: 'settings', activeChild: 'credentials', links: settingsPageLinks });
  }, []);

  return (
    <Container id="page-container">
      <StyledRow className="justify-content-md-center">
        <ViewCol />
        <UploadCol />
      </StyledRow>
    </Container>
  );
};
