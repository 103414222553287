import styled from 'styled-components';
import { lighten } from 'polished';

interface StyledSpanProps {
  $primaryColour?: string;
}

export const StyledSpan = styled.span<StyledSpanProps>`
  border: 1px solid
    ${({ theme, $primaryColour }) => ($primaryColour ? `${$primaryColour} !important` : theme.palette.platinum)};
  color: ${({ theme, $primaryColour }) => ($primaryColour ? `${$primaryColour} !important` : theme.palette.platinum)};
  background: ${({ theme, $primaryColour }) => lighten(0.7, $primaryColour ?? theme.palette.platinum)} !important;
  font-family: EavesBold;
  font-size: 0.7rem;
  padding: 0.1rem 0.2rem 0 0.2rem;
  border-radius: 0.3rem;
  vertical-align: middle;
  overflow-wrap: anywhere;
`;

export const StyledTd = styled.td`
  text-transform: none;
`;
