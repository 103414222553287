import styled from 'styled-components';

export const KeyArticle = styled.article`
  color: ${({ theme }) => theme.palette.textLightGrey};
  margin-bottom: 2rem;
  padding-bottom: 1.5rem;
  border-bottom: 1px solid ${({ theme }) => theme.palette.lighterGrey};
  min-width: 30rem;
  text-align: left;

  &:last-child {
    border-bottom: none;
  }

  p {
    margin-bottom: 0.2rem;

    &:last-child {
      margin-top: 0.4rem;
    }

    b {
      font-family: EavesBold;
      text-transform: uppercase;
    }
  }
`;
