import { ZoomMode } from '~store/create-notification/types';
import { DeviceCase } from '../data';
import { Notification } from '~store/create-notification';

export interface DeviceScreenMetrics {
  /** How small/big we must scale the video to either fit/fill the device screen window */
  canvasScaling: number;
  /** Percentage of which we need to move the screen in x axis for it to be centered */
  canvasOffsetX: string;
  /** Percentage of which we need to move the screen in y axis for it to be centered */
  canvasOffsetY: string;
  /** The width of the preview device screen as a percentage */
  screenClippingWidth: string;
  /** The height of the preview device screen as a percentage */
  screenClippingHeight: string;
  /** Percentage needed to move the clipping screen div from the left to fit in preview device screen */
  screenClippingLeft: string;
  /** Percentage needed to move the clipping screen div from the top to fit in preview device screen */
  screenClippingTop: string;
}

/** Function returns device screen metrics to place it within the preview device screen */
export const calcCanvasScalingMetrics = (
  zoomMode: ZoomMode,
  video: HTMLVideoElement,
  clippingDiv: HTMLDivElement,
  deviceCase: DeviceCase
): DeviceScreenMetrics => {
  let canvasScaling = 1;
  let canvasOffsetY = 0;
  let canvasOffsetX = 0;
  const screenClippingWidth = (deviceCase.screenWidth / deviceCase.width) * 100;
  const screenClippingHeight = (deviceCase.screenHeight / deviceCase.height) * 100;
  const screenClippingLeft = (deviceCase.screenOffsetX / deviceCase.width) * 100;
  const screenClippingTop = (deviceCase.screenOffsetY / deviceCase.height) * 100;

  if (!video || !clippingDiv)
    return {
      canvasScaling,
      canvasOffsetY: `${canvasOffsetY.toFixed(2)}%`,
      canvasOffsetX: `${canvasOffsetX.toFixed(2)}%`,
      screenClippingWidth: `${screenClippingWidth.toFixed(2)}%`,
      screenClippingHeight: `${screenClippingHeight.toFixed(2)}%`,
      screenClippingTop: `${screenClippingTop.toFixed(2)}%`,
      screenClippingLeft: `${screenClippingLeft.toFixed(2)}%`
    };

  if (zoomMode === 'fit') {
    if (clippingDiv.clientHeight < clippingDiv.clientWidth) {
      canvasScaling = clippingDiv.clientHeight / video.videoHeight;
    } else {
      canvasScaling = clippingDiv.clientWidth / video.videoWidth;
    }
  } else {
    if (clippingDiv.clientHeight < clippingDiv.clientWidth) {
      canvasScaling = clippingDiv.clientWidth / video.videoWidth;
    } else {
      canvasScaling = clippingDiv.clientHeight / video.videoHeight;
    }
  }

  const scaledVideoWidth = video.videoWidth * canvasScaling;
  const widthDiff = clippingDiv.clientWidth - scaledVideoWidth;
  canvasOffsetX = (widthDiff / scaledVideoWidth) * 50;
  const scaledVideoHeight = video.videoHeight * canvasScaling;
  const heightDiff = clippingDiv.clientHeight - scaledVideoHeight;
  canvasOffsetY = (heightDiff / scaledVideoHeight) * 50;

  return {
    canvasScaling: parseFloat(canvasScaling.toFixed(2)),
    canvasOffsetY: `${canvasOffsetY.toFixed(2)}%`,
    canvasOffsetX: `${canvasOffsetX.toFixed(2)}%`,
    screenClippingWidth: `${screenClippingWidth.toFixed(2)}%`,
    screenClippingHeight: `${screenClippingHeight.toFixed(2)}%`,
    screenClippingTop: `${screenClippingTop.toFixed(2)}%`,
    screenClippingLeft: `${screenClippingLeft.toFixed(2)}%`
  };
};

export const retrieveOrientation = (notification: Partial<Notification>) => {
  if (!notification?.videoContent?.options?.orientation) return 'landscape';
  if (notification?.videoContent?.options?.orientation === 'default') return 'portrait';
  return notification?.videoContent?.options?.orientation;
};
