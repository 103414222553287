import axios from 'axios';
import { cacheAdapterEnhancer, throttleAdapterEnhancer } from 'axios-extensions';
import { getJWTToken } from './helpers';

const token = getJWTToken();
const childTenantState = JSON.parse(localStorage['[PushPortal][1.1][childTenants]'] || '{}');

// do we have a session on a child tenant?
const authHeaders: Record<string, string> = {};
if (childTenantState?.data?.sessionApiKey) {
  authHeaders['x-api-key'] = childTenantState.data.sessionApiKey.apiKey;
  authHeaders['x-api-secret'] = childTenantState.data.sessionApiKey.secret;
} else if (token) {
  authHeaders.Authorization = `Bearer ${token}`;
}

const instance = axios.create({
  baseURL: process.env.API_URL,
  // https://github.com/axios/axios/pull/1964
  adapter: throttleAdapterEnhancer(
    cacheAdapterEnhancer(axios.getAdapter(axios.defaults.adapter), {
      enabledByDefault: false
    })
  )
});
instance.defaults.headers.common = {
  'Cache-Control': 'no-cache',
  'Content-Type': 'application/json',
  ...authHeaders
};

export { instance };
