import React, { useCallback, useEffect } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { Form, Container, Row, Col, Spinner } from 'react-bootstrap';
import { StyledFormHeader, StyledFormButton } from '~components/styled/form';
import { useStoreActions, useStoreState } from '~store/hooks';
import { RedirectState } from 'src/routes/private-route';
import { formInputsToNameValueObject } from '~helpers/forms';
import Logo from '~images/logo-strapline.svg';
import { LogoWrapperDiv, StyledForm, StyledEye, ErrorMessageP, StyledRow } from './styles';

const Login: React.FC = () => {
  const history = useHistory();
  const location = useLocation<RedirectState>();
  const formRef = React.useRef<HTMLFormElement>();
  const { error, loading } = useStoreState((state) => state.user);
  const isAuthenticated = useStoreState((state) => state.user.isAuthenticated);
  const [{ login, setUserModel }, { setSideNavModel }] = useStoreActions((state) => [state.user, state.sideNav]);
  const [isValid, setIsValid] = React.useState(false);
  const [showPassword, setShowPassword] = React.useState(false);
  const togglePassword = useCallback(() => setShowPassword(!showPassword), [showPassword]);
  const { sessionApiKey } = useStoreState((state) => state.childTenants);
  const { resetSuperTenantState } = useStoreActions((state) => state.childTenants);

  const onInputChange = useCallback((e: React.KeyboardEvent) => {
    const inputs = formInputsToNameValueObject(formRef.current, ['email', 'password']);
    const valid = !!inputs.email.length && !!inputs.password.length;
    setIsValid(valid);

    if (valid && !loading && e.key === 'Enter') onSubmit(e);
  }, []);

  const onSubmit = useCallback(async (e: React.MouseEvent | React.KeyboardEvent) => {
    e.preventDefault();
    e.stopPropagation();
    const { password, email } = formInputsToNameValueObject(formRef.current, ['email', 'password']);
    const onSuccess = () => history.replace(location.state?.from || '/');

    await login({ email, password, onSuccess });
  }, []);

  React.useEffect(() => {
    if (isAuthenticated) history.replace(location.state?.from || '/');
  }, [isAuthenticated]);

  React.useEffect(() => {
    setSideNavModel({ topBarLeftTitle: 'login' });
    return () => setUserModel({ loading: false, error: null });
  }, []);

  useEffect(() => {
    // if we have expired child session keys in state, we'll need to clear them
    if (sessionApiKey) {
      resetSuperTenantState();
      setTimeout(() => (window.location.href = '/'), 500);
    }
  }, [sessionApiKey]);

  return (
    <Container id="page-container">
      <StyledRow className="justify-content-md-center">
        <Col md="auto">
          <LogoWrapperDiv>
            <Logo />
          </LogoWrapperDiv>
          <StyledForm ref={formRef} data-testid="formGroup">
            <StyledFormHeader>log in to your pushologies account</StyledFormHeader>
            <Form.Group controlId="username">
              <Form.Label>Email</Form.Label>
              <Form.Control type="email" name="email" onKeyUp={onInputChange} />
            </Form.Group>
            <Form.Group controlId="password">
              <Form.Label>Password</Form.Label>
              <StyledEye onClick={togglePassword} active={showPassword ? 1 : 0} />
              <Form.Control type={showPassword ? 'text' : 'password'} name="password" onKeyUp={onInputChange} />
            </Form.Group>
            <Link className="forgot-password" to="/forgot-password">
              Forgotten your password?
            </Link>
            <Form.Group>
              <StyledFormButton
                data-testid="submitButton"
                variant="primary"
                onClick={onSubmit}
                disabled={!isValid || loading}
              >
                {loading ? <Spinner animation="border" size="sm" /> : 'Log In'}
              </StyledFormButton>
            </Form.Group>
            <Row>{error && <ErrorMessageP data-testid="errorMessage">{error}</ErrorMessageP>}</Row>
          </StyledForm>
        </Col>
      </StyledRow>
    </Container>
  );
};

export default Login;
