import React, { useMemo } from 'react';
import { ContentDiv, ChartWrapperDiv, YLabelsDiv, BarFigure } from './styles';

export interface BarChartMetrics {
  value: number;
  label: string;
}

interface Props {
  metrics: BarChartMetrics[];
  gradient: [string, string];
  testId?: string;
}

export const BarChart: React.FC<Props> = ({ metrics, gradient, testId }) => {
  const maxValue = metrics.reduce((num, metric) => Math.max(num, metric.value), 0);

  const bars = useMemo(() => {
    return metrics.map(({ value, label }, i) => (
      <BarFigure
        key={`${label}${i}${value}`}
        $percentage={(value / maxValue) * 100 || 0}
        $totalBars={metrics.length}
        $gradient={gradient}
      >
        <figcaption>{label}</figcaption>
      </BarFigure>
    ));
  }, [metrics]);

  return (
    <ChartWrapperDiv data-testid={testId || 'barChart'}>
      <YLabelsDiv>
        <p>{Math.floor(maxValue)}</p>
        <p>{Math.floor(maxValue / 2)}</p>
        <p>0</p>
      </YLabelsDiv>
      <ContentDiv>
        <span className="grid" />
        <span className="grid" />
        <span className="grid" />
        <section>{bars}</section>
      </ContentDiv>
    </ChartWrapperDiv>
  );
};
