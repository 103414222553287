import React from 'react';
import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
  DragEndEvent,
  DragOverlay,
  DragStartEvent
} from '@dnd-kit/core';
import { SortableContext, sortableKeyboardCoordinates, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { restrictToWindowEdges, restrictToFirstScrollableAncestor } from '@dnd-kit/modifiers';
import { useStoreState, useStoreActions } from '~store/hooks';
import { CarouselItem, DraggableCarouselItem } from './item';
import { CanvasSection } from './styles';

export const CarouselList: React.FC = () => {
  const { notification } = useStoreState((state) => state.createNotification);
  const { updateCarouselItem } = useStoreActions((state) => state.createNotification);
  const [activeId, setActiveId] = React.useState(null);
  const items = notification.carouselContent.map((item) => item?.contentItem?.id);
  const activeIndex = items.findIndex((id) => id === activeId);
  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates
    })
  );

  const handleDragStart = (event: DragStartEvent) => {
    setActiveId(event.active.id);
  };

  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;

    if (active.id !== over.id) {
      const currentIndex = items.indexOf(active.id);
      const newIndex = items.indexOf(over.id);
      updateCarouselItem({ currentIndex, newIndex });
    }

    setActiveId(null);
  };

  return (
    <DndContext
      sensors={sensors}
      collisionDetection={closestCenter}
      onDragEnd={handleDragEnd}
      onDragStart={handleDragStart}
      modifiers={[restrictToFirstScrollableAncestor]}
    >
      <SortableContext items={items} strategy={verticalListSortingStrategy}>
        <CanvasSection>
          {!notification.carouselContent.length && <CarouselItem index={0} />}
          {notification.carouselContent.map((item, index) => (
            <DraggableCarouselItem index={index} item={item} key={item.contentItem.id + index} />
          ))}
        </CanvasSection>
        <DragOverlay modifiers={[restrictToWindowEdges]}>
          {activeId && (
            <CarouselItem
              index={activeIndex}
              item={notification.carouselContent[activeIndex]}
              style={{ transform: 'rotate(-1deg)' }}
            />
          )}
        </DragOverlay>
      </SortableContext>
    </DndContext>
  );
};
