import styled from 'styled-components';
import { NOTIFICATION_TYPE } from '@pushologies/database-service/db/entities/notification';
import { INAPP_TYPE, IN_APP_ALIGNMENT_OPTIONS } from '@pushologies/common/constants/in-app';

export type Platform = 'ios' | 'android';

export const PlayIconFigure = styled.figure`
  position: absolute;
  display: none;
  z-index: 200;
  width: 3rem;
  height: 3rem;
  left: calc(50% - 1.5rem);
  top: calc(50% - 1.5rem);
  transition: opacity 0.15s linear;
  cursor: pointer;

  svg {
    width: 100%;
  }
`;

interface DeviceWrapperDivProps {
  $type: NOTIFICATION_TYPE;
  $platform: Platform;
}

export const DeviceWrapperDiv = styled.div<DeviceWrapperDivProps>`
  position: relative;
  display: flex;
  align-items: center;
  height: 48rem;
  padding: 4rem 0 3rem;

  figure.device {
    display: block;
    width: 55%;
    max-width: 21rem;
    min-width: 17rem;
    margin: 0 auto;
    position: relative;
    z-index: 0;
    overflow: hidden;

    /* The phone case */
    & > svg {
      display: block;
      width: 100%;
      position: relative;
      z-index: 10;
    }

    & > section {
      position: absolute;
      top: 0;
      height: 100%;
      width: 100%;
      margin: 0 auto;
      left: 50%;
      transform: translateX(-50%);
      padding: ${({ $type }) => ($type === NOTIFICATION_TYPE.CAROUSEL ? '0 0.5rem' : 0)};
      z-index: ${({ $type }) => ($type === NOTIFICATION_TYPE.CAROUSEL ? 20 : 1)};

      & > img {
        width: 100%;
        height: 100%;
        border-radius: ${({ $platform }) => ($platform === 'android' ? '0.5rem' : '3rem')};
        padding: 6px;
      }
    }

    &:before {
      content: '';
      position: absolute;
      background: ${({ $type }) => ($type === 'VIDEO' ? '#131313' : '#F6F6F6')};
      width: calc(100% - 6px);
      border-radius: ${({ $platform }) => ($platform === 'android' ? '0.5rem' : '3rem')};
      height: 100%;
      left: 50%;
      transform: scaleY(0.98) scaleX(0.98) translateX(-50%);
    }
  }
`;

export const DateDiv = styled.div`
  text-align: center;
  margin-top: 5rem;
  color: ${({ theme }) => theme.palette.darkGrey};

  h1 {
    margin-bottom: 0;
    font-family: EavesRegular;
  }
  p {
    font-family: EavesRegular;
  }
`;

interface StandardNotificationDivProps {
  $platform: 'ios' | 'android';
}

export const StandardNotificationDiv = styled.div<StandardNotificationDivProps>`
  border: 1px solid ${({ theme }) => theme.palette.textGrey};
  border-radius: 0.5rem;
  width: calc(100% - ${({ $platform }) => ($platform === 'ios' ? '2.5rem' : '0.5rem')});
  margin-left: ${({ $platform }) => ($platform === 'ios' ? '1.25rem' : '0.25rem')};
  font-family: EavesRegular;
  color: ${({ theme }) => theme.palette.platinum};
  background: white;

  img {
    position: relative;
    width: 100%;
  }

  article {
    position: relative;
    width: 100%;
    padding: 0.5rem;

    section {
      position: relative;
      display: flex;
      justify-content: space-between;
      margin-bottom: 0.25rem;
      min-height: 1rem;

      svg {
        position: absolute;
        width: 1.25rem;
        left: 0;

        path {
          fill: ${({ theme }) => theme.palette.platinum};
        }
      }

      h4 {
        margin: 0 0 0 1.25rem;
        font-size: 1.1rem;
        font-family: EavesBold;
      }

      p {
        margin: 0;
        text-transform: uppercase;
        line-height: 0.5rem;
        font-size: 0.8rem;
      }
    }

    h5 {
      margin: 0;
      font-size: 0.95rem;
      line-height: 1rem;
      margin-bottom: 0.1rem;
    }

    h6 {
      font-size: 0.9rem;
      margin: 0;
      line-height: 1rem;
    }
  }

  .android-rich-image-preview {
    padding: 1rem;
    max-height: 16rem;
    overflow: hidden;

    h5 {
      margin-bottom: 0;
    }

    img {
    }
  }

  .ios-preview {
    max-height: 16rem;
    overflow: hidden;
    padding-bottom: 2rem;

    img {
      padding: 0.5rem;
    }
  }
`;

interface InAppNotificationDivProps {
  $type: INAPP_TYPE;
  $platform: 'ios' | 'android';
  $alignment: IN_APP_ALIGNMENT_OPTIONS;
}

export const InAppNotificationDiv = styled.div<InAppNotificationDivProps>`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  ${({ $type }) => `padding: ${$type === INAPP_TYPE.VIDEO ? 0 : 20}px;`}
  display: flex;
  flex-direction: column;

  ${({ $alignment }) => {
    switch ($alignment) {
      case IN_APP_ALIGNMENT_OPTIONS.CENTER:
      default:
        return `justify-content: center;`;
    }
  }}

  .message-preview {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-width: 20px;
    min-height: 20px;
    overflow: hidden;

    ${({ $alignment }) => {
      switch ($alignment) {
        case IN_APP_ALIGNMENT_OPTIONS.FULLSCREEN:
          return `flex-grow: 1;`;
        default:
          return `height: auto;`;
      }
    }}

    ${({ $platform, $type }) =>
      $platform === 'ios'
        ? `
      background-color: #fdfdfe;
      border-radius: ${$type === INAPP_TYPE.VIDEO ? 50 : 20}px;
      text-align: center;`
        : `
      background-color: #424242;
      border-radius: 5px;
      color: #fff;
      text-align: left;`}

    img {
      width: 100%;
    }

    h1 {
      font-size: 18px;
      font-weight: bold;
      padding: 10px 10px 0 10px;
      width: 100%;
    }

    p {
      font-size: 14px;
      padding: 0 10px;
      width: 100%;

      &:last-of-type {
        text-transform: uppercase;
        margin: 0;

        ${({ $platform }) =>
          $platform === 'ios'
            ? `
            color: #77b2fb;
            border-top: 1px solid #ddd;
            text-align: center;
            display: flex;

            span {
              flex: none;
              width: 50%;
              text-align: center;
              padding: 10px;
              overflow: hidden;
              border-left: 1px solid #ddd;

              &:first-of-type {
                flex-grow: 1;
                border: none;
              }
            }`
            : `
              padding: 10px;
              color: #91c9c3;
              text-align: right;

              span {
                padding: 0 10px;
              }`}
      }
    }
  }
`;

interface CarouselImageDivProps {
  url: string;
}

export const CarouselImageDiv = styled.div<CarouselImageDivProps>`
  position: relative;
  display: block;
  margin: 0.5rem auto 0.2rem;
  width: calc(100% - 2rem);
  padding-top: 50%;
  background: url(${({ url }) => url});
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
`;

export const CarouselButton = styled.button`
  position: relative;
  display: block;
  width: calc(100% - 0.5rem);
  border: 1px solid ${({ theme }) => theme.palette.textGrey};
  border-radius: 0.25rem !important;
  background: white;
  margin: 0.2rem 0.25rem;
  padding: 0.5rem 0;
  font-size: 0.9rem;
  color: ${({ theme }) => theme.palette.platinum};
`;
