import { Container } from 'react-bootstrap';
import styled from 'styled-components';

export const StyledPollResultsContainer = styled(Container)`
  padding: 0 4rem;

  h1 {
    font-family: EavesBold;
    font-size: 2.75rem;
    margin: 0;
    color: ${({ theme }) => theme.palette.platinum};
    text-transform: uppercase;
  }

  span {
    text-transform: uppercase;
    color: ${({ theme }) => theme.palette.textGrey};
  }

  .row {
    margin-bottom: 2em;

    &:first-of-type {
      display: flex;
      align-items: center;

      * {
        margin-right: 0.5em;
      }
    }
  }
`;

export const ChartContainer = styled.div`
  display: flex;
  justifycontent: center;
  width: 600px;
  height: 400px;
`;
