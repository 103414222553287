import styled, { keyframes, css } from 'styled-components';
import { darken } from 'polished';
import PlusIcon from '~images/plus.svg';
import ReloadIcon from '~images/reload.svg';
import { Select } from '~components/select';
import { Checkbox } from '~components/checkbox';
import { HideScrollbars } from '~styles/mixins';
import { StyledButton as Button } from '~components/button/styles';

export const HeaderTopDiv = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.75rem;

  button {
    padding: 0.05rem 2.25rem;
    font-size: 1.25rem;
  }

  section {
    display: flex;
    align-items: center;
    color: ${({ theme }) => theme.palette.primaryMustard};

    h3 {
      font-family: EavesBold;
      text-transform: uppercase;
      font-size: 1.75rem;
      margin: 0 1rem 0 0;
    }

    &.userCount {
      transform: translateY(0.2rem);

      p {
        margin: 0 1rem;

        span {
          display: block;
          font-size: 0.8rem;
          line-height: 0.7rem;
          text-transform: uppercase;
          text-align: right;

          button {
            background: 0;
            border: none;
            padding: 0 0.25rem;
            font-size: 0.9rem;
            color: ${({ theme }) => theme.palette.platinum};

            &:hover {
              color: ${({ theme }) => theme.palette.textColor};
            }
          }
        }
      }
    }
  }
`;

const rotate = keyframes`
  from {
    transform: rotate(360deg);
  }
  to {
    transform: rotate(0deg);
  }
`;

interface IconSpanProps {
  $loading: boolean;
  disabled: boolean;
}

export const IconSpan = styled.span<IconSpanProps>`
  width: 1rem;

  &:hover {
    cursor: ${({ disabled }) => (disabled ? 'normal' : 'pointer')};
  }

  ${({ $loading }) =>
    $loading &&
    css`
      animation: ${rotate} 2s linear infinite;
    `}

  svg {
    width: 100%;

    path {
      fill: ${({ theme, disabled }) => (disabled ? theme.palette.lightGrey : theme.palette.primaryMustard)} !important;
    }
  }
`;

export const HeaderBottomDiv = styled.div`
  display: flex;
  justify-content: space-between;

  section {
    &:nth-child(1) {
      flex-grow: 1;
      margin-right: 1rem;
    }

    &:nth-child(2) {
      flex-grow: 2;
      margin-right: 1rem;
    }

    .dropdown {
      min-width: 10rem;
      height: 1.3rem;

      button {
        margin: 0;
        height: 1.3rem;
        background: none;

        &:hover {
          background: none;
        }
      }
    }
  }
`;

interface HeaderWrapperDivProps {
  $columnAlignment: boolean;
}

export const HeaderWrapperDiv = styled.div<HeaderWrapperDivProps>`
  position: relative;
  display: block;
  padding-left: 15px;

  ${(props) =>
    props.$columnAlignment &&
    css`
      ${HeaderTopDiv} {
        justify-content: center;
      }

      ${HeaderBottomDiv} {
        flex-direction: column;

        section {
          margin-right: 0;
          margin-bottom: 0.5rem;
        }
      }
    `}
`;

export const StyledOptionsDiv = styled.div`
  p {
    margin: 0;
    text-transform: uppercase;
    color: ${({ theme }) => theme.palette.platinum};
  }

  section.checkboxes {
    display: flex;
    margin-bottom: 1.5rem;

    & > div {
      margin-right: 1rem;
    }
  }
`;

export const StyledButton = styled(Button)`
  margin-left: 1rem;
  padding: 0.25rem 1rem !important;
  font-size: 1rem !important;

  svg {
    width: 1rem;
    margin: 0 0.5rem;

    path {
      fill: white;
    }
  }
`;

export const RuleListSection = styled.section`
  position: absolute;
  left: 0;
  width: calc(25% + 2rem);
  height: ${(props) => `calc(
    100vh - (
      ${props.theme.metrics.headerHeight} +
      ${props.theme.metrics.headerOverlayHeight} +
      1.5rem
    )
  )`};
  overflow-y: scroll;
  padding-bottom: 0.5rem;

  ${HideScrollbars}
`;

export const RuleDiv = styled.div`
  position: relative;
  display: flex;
  background: ${({ theme }) => theme.palette.lighterGrey};
  margin: 0.6rem 0;
  padding: 0.75rem 0.6rem;
  align-items: center;
  cursor: pointer;

  .add-tag {
    border: solid 2px ${({ theme }) => theme.palette.primaryMustard};
    border-radius: 5px;
    padding: 0.25rem;
    position: absolute;
    right: 1.25rem;
    display: flex;
    align-items: center;
    justify-content: center;
    svg {
      margin: 0 !important;
    }
  }
  span {
    border: solid 1px red;
    background-color: red;
    font-size: 0.75rem;
    line-height: 0.5rem;
    color: white;
    padding: 0.25rem;
    border-radius: 50%;
    position: absolute;
    right: 0.5rem;
    top: 0.25rem;
    text-align: center;
    margin: 0;
  }

  svg {
    width: 1rem;
    margin-right: 1rem;

    path {
      fill: ${({ theme }) => theme.palette.textGrey};
    }
  }

  h3 {
    text-transform: uppercase;
    font-size: 1rem;
    color: ${({ theme }) => theme.palette.textGrey};
    margin: 0;
  }
`;

export const StyledCheckbox = styled(Checkbox)`
  position: absolute;
  right: 0.5rem;
`;

interface RulesWrapperDivProps {
  hasRules: boolean;
}

export const RulesWrapperDiv = styled.div<RulesWrapperDivProps>`
  position: relative;
  background: white;
  box-shadow: ${({ hasRules }) => (hasRules ? '-4px 6px 21px 0 rgba(2, 3, 2, 0.1)' : 'none')};
  padding: 0.3rem 0.3rem 0.75rem;
  margin-top: 1rem;
  margin-left: 15px;
`;

export const StyledSelect = styled(Select)`
  button {
    margin-bottom: 1rem;
  }
`;

export const StyledAddIcon = styled(PlusIcon)`
  position: absolute;
  width: 1.25rem;
  height: 1.25rem;
  left: -2rem;
  top: 0;
  z-index: 1000;

  path {
    fill: ${({ theme }) => theme.palette.primaryMustard};
  }

  &:hover {
    path {
      fill: ${({ theme }) => darken(0.2, theme.palette.primaryMustard)};
    }
  }
`;

export const NoRulesDiv = styled.div`
  width: 100%;
  margin: 1rem 0;

  h4 {
    font-size: 1rem;
    text-align: center;
    width: 100%;
    font-family: EavesRegular;
    color: ${({ theme }) => theme.palette.textLightGrey};
    text-transform: uppercase;
  }

  code {
    color: ${({ theme }) => theme.palette.platinum};
  }

  p {
    font-family: EavesHeavy;
    color: ${({ theme }) => theme.palette.platinum};
    text-align: center;
    line-height: 1.1rem;
    padding: 0.5rem 3rem;

    a {
      color: ${({ theme }) => theme.palette.platinum};

      &:hover {
        color: ${({ theme }) => theme.palette.textColor};
      }
    }
  }
`;

interface WrapperDivProps {
  minified?: boolean;
}

export const WrapperDiv = styled.div<WrapperDivProps>`
  position: relative;
  width: 100%;
  padding: 0.75rem 2.25rem 0.5rem 3rem;
  padding-left: calc(25% + 2rem);

  ${({ minified }) =>
    minified &&
    css`
      padding-top: 0.25rem;
      height: calc(100% - 3.5rem);

      padding-right: 1rem;

      ${RulesWrapperDiv} {
        box-shadow: none;
        height: calc(100% - 11rem);
        overflow-y: scroll;
        padding: 0.5rem 0.5rem 0.75rem;

        ${HideScrollbars}
      }
    `}
`;

export const ButtonsWrapperDiv = styled.div`
  position: absolute;
  display: flex;
  bottom: 0;
  width: calc(70% - 3rem);
  background: white;
  margin-left: calc(30% + 1rem);
  padding: 1rem 0 1rem 1rem;

  button:first-child {
    padding: 0 3rem;
  }

  button:last-child {
    flex-grow: 2;
    margin-left: 1rem;
  }
`;

export const TourReloadIcon = styled(ReloadIcon)`
  width: 0.8rem;

  path {
    fill: ${({ theme }) => theme.palette.primaryMustard};
  }
`;
