import styled from 'styled-components';
import { Col } from 'react-bootstrap';
import { Button } from '~components/button';

export const StyledCol = styled(Col)`
  background: linear-gradient(180deg, rgba(167, 22, 128, 0.1) 0%, #fff 100%);
  display: flex;
  border-top: solid 4px ${({ theme }) => theme.palette.primaryPlum};
  justify-content: center;
  align-items: center;
  gap: 4rem;
  flex: 100%;
  padding: 2rem;
  margin-top: 3rem;
`;

export const PushButton = styled(Button)`
  width: 30%;
  font-family: EavesBold;
  font-size: 2rem;
  padding: 0;
`;

export const SaveButton = styled(Button)`
  background: ${({ theme }) => theme.palette.darkGrey} !important;
  color: ${({ theme }) => theme.palette.textColor};
  width: 30%;
  font-family: EavesBold;
  font-size: 2rem;
  padding: 0;

  &:hover {
    background: ${({ theme }) => theme.palette.lightGrey} !important;
  }
`;
