import styled from 'styled-components';

export const MaintenanceContainerDiv = styled.div`
  display: flex;
  justify-content: center;
  alignitems: center;
`;

export const MaintenanceMessage = styled.h1`
  color: ${({ theme }) => theme.palette.primaryPlum};
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
`;
