import React from 'react';
import { ReactourStep } from 'reactour';
import { generateTourSelector } from '~helpers/formats';
import { TourDiv } from '~components/tour/styles';
import { BracketGifSection, StyledCaretIcon, ModalJpgSection } from './styles';

export const PERSONALISATION_COOKIE = 'PERSONALISATION_COOKIE';

export const personalisationSteps = (selector: string): ReactourStep[] => [
  {
    selector: generateTourSelector(selector),
    content: () => (
      <TourDiv>
        This input also allows adding personalisation fields. There are two ways of doing so. The first is by typing{' '}
        <code>{`"{{"`}</code> which will open a dropdown with personalisation options.
        <BracketGifSection />
        Select the desired options by clicking on them or by hitting the Enter key. You can also navigate between
        options via the up/down arrow keys.
      </TourDiv>
    )
  },
  {
    selector: generateTourSelector(selector),
    content: () => (
      <TourDiv>
        The second way to add personalisation text is by clicking the <StyledCaretIcon /> icon which appears everytime
        the input is in focus.
        <br />
        <br />
        Once pressed a modal will appear to help create a personalisation text string.
        <ModalJpgSection />
      </TourDiv>
    )
  },
  {
    selector: generateTourSelector(`${selector}Delete`),
    content: () => (
      <TourDiv>
        Clear the input by clicking this icon.
        <br />
        <br />
        To edit an old personalisation object just click on it and the personalisation modal will appear for you to
        edit.
      </TourDiv>
    )
  }
];
