import React from 'react';
import { Overlay } from 'react-bootstrap';
import CrossIcon from '~images/cross.svg';
import { DropdownItem } from '~components/select/styles';
import { Suggestion } from '../autosuggest-field';
import { OverlayDiv } from '../autosuggest-field/styles';
import { StyledInputSection, StyledCrossIcon, StyledLoadingDots, StyledSpanNonCapitalised } from './styles';

interface Props<T = { id: string }> {
  chips: Suggestion<T>[];
  onChange(chips: Suggestion<T>[]): void;
  onInputChange?(text: string): void;
  placeholder?: string;
  invalid?: boolean;
  loading?: boolean;
  label?: string;
  testId?: string;
  suggestions?: Suggestion<T>[];
}

export const AutoSuggestChipField = <T extends unknown>(props: Props<T>) => {
  const containerRef = React.useRef<HTMLElement>();
  const inputRef = React.useRef<HTMLInputElement>();
  const [focused, setFocused] = React.useState(false);
  const [activeSuggestion, setActiveSuggestion] = React.useState(0);

  const onFocus = () => setFocused(true);
  const onBlur = () => setFocused(true);
  const onKeyDown: React.KeyboardEventHandler<HTMLInputElement> = (event) => {
    switch (event.key) {
      case 'ArrowDown':
        setActiveSuggestion((activeSuggestion + 1) % props.suggestions.length);
        event.stopPropagation();
        event.preventDefault();
        break;
      case 'ArrowUp':
        setActiveSuggestion((activeSuggestion === 0 ? props.suggestions.length : activeSuggestion) - 1);
        event.stopPropagation();
        event.preventDefault();
        break;
      case 'Enter':
        if (props.suggestions.length) {
          props.onChange([props.suggestions[activeSuggestion], ...props.chips]);
          inputRef.current.value = '';
          setActiveSuggestion(0);
        }
      default:
        break;
    }
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!event.target.value.length) return;
    props.onInputChange(event.target.value);
  };

  const handleSuggestionClick = (suggestion: Suggestion<T>) => () => {
    props.onChange([suggestion, ...props.chips]);
    inputRef.current.value = '';
    inputRef.current.focus();
  };

  const handleRemoveChip = (index: number) => () => {
    props.onChange([...props.chips.slice(0, index), ...props.chips.slice(index + 1)]);
  };

  const handleClear = () => {
    props.onChange([]);
    inputRef.current.value = '';
    inputRef.current.focus();
  };

  return (
    <StyledInputSection invalid={props.invalid} data-testid={props.testId} ref={containerRef}>
      <section>
        {props.label && <label>{props.label}</label>}
        <input
          ref={inputRef}
          type="text"
          onChange={handleInputChange}
          onFocus={onFocus}
          onBlur={onBlur}
          onKeyDown={onKeyDown}
          placeholder={props.placeholder}
        />
      </section>
      {props.loading && <StyledLoadingDots />}
      <StyledCrossIcon onClick={handleClear} data-testid={`${props.testId}ClearBtn`} />
      {!!props.chips.length && (
        <figure>
          {props.chips.map((chip, i) => (
            <StyledSpanNonCapitalised key={chip.label + i}>
              {chip.label} <CrossIcon onClick={handleRemoveChip(i)} data-testid={`${props.testId}DeleteChipBtn`} />
            </StyledSpanNonCapitalised>
          ))}
        </figure>
      )}
      {props?.suggestions && (
        <Overlay
          target={containerRef.current}
          show={!!props?.suggestions?.length && focused && !!inputRef.current.value.length}
          placement="bottom"
        >
          {({ placement, arrowProps, show, popper, ...root }) => (
            <OverlayDiv {...root}>
              {props.suggestions.map((suggestion, i) => (
                <DropdownItem
                  as="div"
                  key={suggestion.label + suggestion.value}
                  onClick={handleSuggestionClick(suggestion)}
                  $selected={activeSuggestion === i}
                  data-testid="suggestion"
                >
                  {suggestion.label}
                </DropdownItem>
              ))}
            </OverlayDiv>
          )}
        </Overlay>
      )}
    </StyledInputSection>
  );
};
