import { Column, CreateDateColumn, Entity, Index, JoinColumn, ManyToOne, PrimaryGeneratedColumn } from 'typeorm';
import { getFriendlyErrorMessage } from '@pushologies/common/constants/push-notification-errors';
import { SUBSCRIBER_NOTIFICATION_EVENT_STATUS } from '@pushologies/common/constants/subscriber';
import { Subscriber } from '../subscriber';
import { Tenant } from '../tenant';
import { Notification } from '../notification';

@Entity('subscriber_notification_event')
@Index(['subscriberId', 'tenantId'])
@Index(['subscriberId', 'tenantId', 'notificationId'])
@Index(['subscriberId', 'notificationId', 'status'])
@Index(['tenantId', 'createdAt'])
@Index(['link', 'subscriberId', 'tenantId'])
export class SubscriberNotificationEvent {
  @PrimaryGeneratedColumn('uuid')
  id: string;

  @Column()
  subscriberId: string;
  @ManyToOne((_) => Subscriber, { onDelete: 'CASCADE' })
  @JoinColumn()
  subscriber: Subscriber;

  @Column()
  tenantId: string;
  @ManyToOne((_) => Tenant, { onDelete: 'CASCADE' })
  @JoinColumn()
  @Index()
  tenant: Tenant;

  @Column()
  notificationId: string;
  @ManyToOne((_) => Notification, { onDelete: 'CASCADE' })
  @Index()
  notification: Notification;

  @Column({
    name: 'status',
    type: 'enum',
    enum: SUBSCRIBER_NOTIFICATION_EVENT_STATUS,
    default: SUBSCRIBER_NOTIFICATION_EVENT_STATUS.PENDING
  })
  status: SUBSCRIBER_NOTIFICATION_EVENT_STATUS;

  @Column({ name: 'event_data', type: 'jsonb', nullable: true })
  eventData: object;

  /** raw (personalisation) url of the button when `status` is `link_opened` */
  @Column({ nullable: true })
  link?: string;

  @Column({
    name: 'error',
    type: 'varchar',
    nullable: true,
    transformer: {
      from: (value) => getFriendlyErrorMessage(value),
      to: (value) => value
    }
  })
  error: string;

  @Column({ name: 'timestamp', type: 'timestamptz', default: () => 'CURRENT_TIMESTAMP' })
  timestamp: Date;

  @CreateDateColumn({ name: 'created_at', type: 'timestamptz' })
  createdAt: Date;
}
