import React from 'react';
import { Container } from 'react-bootstrap';
import { useStoreActions, useStoreState } from '~store/hooks';
import { MetricsCharts } from './metric-charts';
import { SubscriberGraph } from './user-graph';
import { NotificationStats } from './notification-stats';
import { UserMetrics } from './user-metrics';
import { StyledRow, StyledCol } from './styles';
import { MultiLanding } from '~pages/multi-tenant-landing';
import { Loader } from '~components/loader';

const Dashboard: React.FC = () => {
  const { setSideNavModel } = useStoreActions(({ sideNav }) => sideNav);
  const { isSuperTenant } = useStoreState((state) => state.childTenants);

  React.useEffect(() => {
    setSideNavModel({ topBarLeftTitle: 'dashboard' });
  }, []);

  return (
    <>
      {isSuperTenant === undefined ? (
        <Loader loading={true} />
      ) : !isSuperTenant ? (
        <Container id="page-container-full-width">
          <StyledRow>
            <StyledCol lg={3} md={12}>
              <UserMetrics />
              <NotificationStats />
            </StyledCol>
            <StyledCol lg={9} md={12}>
              <SubscriberGraph />
              <MetricsCharts />
            </StyledCol>
          </StyledRow>
        </Container>
      ) : (
        <MultiLanding />
      )}
    </>
  );
};

export default Dashboard;
