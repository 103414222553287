import styled, { css } from 'styled-components';
import { Dropdown } from 'react-bootstrap';
import { Link, NavLink } from 'react-router-dom';
import { rgba } from 'polished';

export const StyledDropDown = styled(Dropdown)`
  display: flex;

  svg {
    width: 100%;

    path {
      fill: ${({ theme }) => theme.palette.platinum};
    }
  }

  &.show {
    svg {
      path {
        fill: ${({ theme }) => theme.palette.platinum};
      }
    }
  }

  &.nav-link {
    padding: 0;

    &.active {
      color: ${({ theme }) => theme.palette.primaryMustard} !important;
    }

    &:hover {
      color: ${({ theme }) => theme.palette.primaryMustard};
    }
  }

  &:hover {
    svg {
      path {
        fill: ${({ theme }) => theme.palette.navGreyColor};
      }
    }
  }

  .dropdown-toggle {
    position: relative;
    width: 2.6rem;
    background-color: transparent !important;
    border: none;
    border-radius: 0 !important;

    &:after {
      content: none;
    }
  }

  .dropdown-menu {
    text-transform: uppercase;
    top: -3px !important;
    border-radius: 0;
    border: none;
    background: rgb(238, 238, 238);
    padding: 0 0.5rem 0.3rem;
    min-width: 12rem;
  }
`;

const dropdownItem = css`
  height: 1.75rem;
  border: 1px solid ${({ theme }) => rgba(theme.palette.primaryPlum, 0.2)};
  width: 100% !important;
  color: ${({ theme }) => theme.palette.primaryPlum};
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0.5rem 0;
  background: transparent;

  svg {
    width: 1rem;

    path {
      fill: ${({ theme }) => theme.palette.primaryPlum} !important;
    }
  }

  &:hover {
    color: ${({ theme }) => theme.palette.primaryMustard};
  }

  &.active {
    color: ${({ theme }) => theme.palette.primaryMustard} !important;
  }
`;

export const StyledDropDownItem = styled(Dropdown.Item)`
  ${dropdownItem}
`;

export const StyledDropDownLink = styled(Link)`
  ${dropdownItem}
  padding: 0 .4rem;
  font-size: 1rem;
`;

export const StyledDropDownNavLink = styled(NavLink)`
  ${dropdownItem}
  padding: 0 .4rem;
  font-size: 1rem;
`;
