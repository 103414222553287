import styled, { css } from 'styled-components';
import { lighten, darken } from 'polished';
import DateTimePicker from 'react-datetime-picker';
import DateTimeRangePicker from '@wojtekmaj/react-datetimerange-picker';
import DateRangePicker from '@wojtekmaj/react-daterange-picker';
import CalendarIcon from '~images/calendar.svg';
import { BottomCaretMixin } from '~styles/mixins';

interface DatePickerProps {
  invalid?: boolean;
}

const DatePickerBase = css`
  width: 100%;
  color: ${({ theme }) => theme.palette.primaryPlum};
  font-family: EavesRegular;

  .react-datetime-picker {
    &__inputGroup {
      &__leadingZero {
        font-family: EavesRegular;
      }
    }
  }

  .react-datetime-picker__wrapper,
  .react-datetimerange-picker__wrapper,
  .react-daterange-picker__wrapper {
    background: white;
    border: none;
    padding: 0.325rem 0.25rem;

    input {
      color: ${({ theme }) => theme.palette.primaryPlum};
      font-family: EavesRegular;
    }
  }

  &.react-datetime-picker--open,
  &.react-datetimerange-picker--open,
  &.react-daterange-picker--open {
    border: 1px solid ${({ theme }) => theme.palette.primaryPlum};

    .react-datetimerange-picker__range-divider:before,
    .react-daterange-picker__range-divider:before {
      background: ${({ theme }) => theme.palette.primaryPlum} !important;
    }
  }

  .react-datetime-picker__calendar,
  .react-datetimerange-picker__calendar,
  .react-daterange-picker__calendar {
    width: 270px;
    z-index: 1000;

    &-button {
      &:hover {
        background: transparent;
      }
    }

    .react-calendar {
      ${BottomCaretMixin}
      position: relative;
      padding: 1rem 0.5rem 1.1rem;
      border: 1px solid ${({ theme }) => theme.palette.primaryPlum};
      box-shadow: 5px 7px 5px 0 rgba(2, 3, 2, 0.16);
      margin-top: 0.25rem;

      &__navigation {
        padding: 0 0.5rem;

        button:enabled:focus,
        button:enabled:hover {
          background: none;
        }

        &__arrow {
          order: 2;
          color: transparent;
          min-width: 2rem;

          &:nth-child(2),
          &:nth-child(4) {
            color: transparent;
            position: relative;

            &:before {
              content: '';
              position: absolute;
              z-index: 10;
              top: calc(50% - 0.3rem);
              right: 0;
              border-bottom: 0.6rem solid ${({ theme }) => theme.palette.primaryMustard};
              border-right: 0.35rem solid transparent;
              border-left: 0.35rem solid transparent;
            }
          }

          &:nth-child(2):before {
            transform: rotate(-90deg);
          }
          &:nth-child(4):before {
            transform: rotate(90deg);
          }

          &:first-child,
          &:last-child {
            display: none;
          }

          &:hover {
            &:before {
              border-bottom-color: ${({ theme }) => darken(0.1, theme.palette.primaryMustard)};
            }
          }
        }

        &__label {
          color: ${({ theme }) => theme.palette.primaryPlum};
          font-family: EavesBold;
          text-align: left;
          border: 1px solid ${({ theme }) => theme.palette.lightGrey};

          &:hover {
            border-color: ${({ theme }) => theme.palette.darkGrey};
            background: ${({ theme }) => lighten(0.4, theme.palette.lighterGrey)} !important;
          }
        }
      }

      &__year-view,
      &__decade-view,
      &__century-view {
        &__months__month,
        &__years__year,
        &__decades__decade {
          font-family: EavesBold;
          color: ${({ theme }) => theme.palette.textLightGrey};
          font-size: 0.9rem;
          border-radius: 0;

          &:hover {
            background: ${({ theme }) => lighten(0.35, theme.palette.lighterGrey)};
          }
        }

        .react-calendar__tile--hasActive {
          background: ${({ theme }) => theme.palette.primaryPlum} !important;
          color: white !important;
          border-radius: 50% !important;
        }
      }

      &__month-view {
        &__weekdays {
          color: ${({ theme }) => theme.palette.textGrey};
          text-transform: capitalize;
          font-family: EavesBold;
          font-size: 0.9rem;
          letter-spacing: 0.2px;

          abbr {
            text-decoration: none;

            &:hover {
              cursor: default;
            }
          }
        }

        &__days {
          button {
            color: ${({ theme }) => theme.palette.textLightGrey};
            font-size: 0.9rem;
          }

          &__day {
            margin: 2.5px 0;

            &:hover {
              background: ${({ theme }) => lighten(0.35, theme.palette.lighterGrey)};
            }

            &--weekend {
              color: ${({ theme }) => theme.palette.lighterGrey} !important;
            }
          }

          .react-calendar__tile--active {
            background: ${({ theme }) => theme.palette.primaryPlum};

            &:not(.react-calendar__tile--range),
            &.react-calendar__tile--rangeStart {
              color: white !important;
            }
          }
        }
      }

      .react-calendar__tile--now {
        background: ${({ theme }) => lighten(0.35, theme.palette.lighterGrey)};

        &:not(.react-calendar__tile--active) {
          border-radius: 50% !important;
        }
      }
    }
  }

  .react-datetime-picker__calendar,
  .react-date-picker__calendar {
    .react-calendar__month-view__days__day {
      border-radius: 50% !important;
    }
  }
`;

const RangeBase = css`
  &.react-datetime-picker--disabled {
    color: ${({ theme }) => theme.palette.lightGrey} !important;
    border-color: ${({ theme }) => theme.palette.lightGrey} !important;
  }

  ${DatePickerBase}

  .react-daterange-picker__wrapper {
    .react-daterange-picker {
      &__inputGroup {
        display: flex;
        align-items: center;
      }

      &__range-divider {
        transform: translateX(-100%);
      }
    }
  }

  .react-datetimerange-picker__wrapper {
    flex-direction: column;

    .react-datetimerange-picker {
      &__inputGroup {
        display: block;
        align-self: flex-start;

        &__leadingZero {
          font-family: EavesRegular;
        }
      }

      &__range-divider {
        line-height: 0.5rem;
        color: transparent;

        &:before {
          content: '';
          background: ${({ theme }) => theme.palette.lightGrey};
          position: absolute;
          left: 0.5rem;
          top: 49%;
          height: 1px;
          width: calc(100% - 2.5rem);
        }
      }

      &__calendar-button {
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
      }
    }
  }

  .react-datetime-picker__calendar,
  .react-date-picker__calendar {
    .react-calendar__month-view__days__day {
      border-radius: 50% !important;
    }
  }

  .react-datetimerange-picker__calendar,
  .react-daterange-picker__calendar {
    .react-calendar {
      &__month-view__days {
        &__day {
          &:not(.react-calendar__tile--active, .react-calendar__tile--hasActive) {
            border-radius: 50% !important;
          }

          &.react-calendar__tile--active {
            border-radius: none !important;
            background: ${({ theme }) => lighten(0.45, theme.palette.primaryMustard)};
            color: ${({ theme }) => theme.palette.textLightGrey};
          }

          &.react-calendar__tile {
            &--rangeStart,
            &--rangeEnd {
              background: transparent;
              position: relative;
              color: white !important;

              abbr {
                position: relative;
                z-index: 1;
              }

              &:before {
                content: '';
                position: absolute;
                top: 0;
                background: ${({ theme }) => lighten(0.45, theme.palette.primaryMustard)} !important;
                height: 100%;
                width: 50%;
              }

              &:after {
                content: '';
                position: absolute;
                left: 0;
                top: 0;
                background: ${({ theme }) => theme.palette.primaryPlum};
                height: 100%;
                width: 100%;
                border-radius: 50%;
              }
            }

            &--rangeStart {
              &:before {
                right: 0;
              }
            }

            &--rangeEnd {
              &:before {
                left: 0;
              }
            }

            &--hover {
              border-radius: 0 !important;
              background: ${({ theme }) => lighten(0.35, theme.palette.lighterGrey)};
            }
          }
        }
      }
    }
  }
`;

export const StyledDateTimePicker = styled(DateTimePicker)<DatePickerProps>`
  &.react-datetime-picker--disabled {
    color: ${({ theme }) => theme.palette.lightGrey} !important;
    border-color: ${({ theme }) => theme.palette.lightGrey} !important;
  }

  .react-datetime-picker__clear-button {
    padding: 0;
  }

  ${DatePickerBase}
`;

interface DateRangeProps extends DatePickerProps {
  $hasPlaceholder?: boolean;
  $hasDate?: boolean;
}

export const StyledDateRangePicker = styled(DateRangePicker)<DateRangeProps>`
  ${RangeBase}

  ${({ $hasPlaceholder, $hasDate }) =>
    $hasPlaceholder &&
    !$hasDate &&
    css`
      .react-daterange-picker__wrapper {
        .react-daterange-picker {
          &__inputGroup:nth-child(1) {
            display: none !important;
          }

          &__inputGroup {
            input {
              width: 100% !important;
              font-family: EavesRegular;
              font-size: 0.9rem;
              letter-spacing: 0;
              text-transform: uppercase;
              background: white;

              ::-webkit-input-placeholder {
                color: ${({ theme }) => theme.palette.lightGrey};
              }
              :-ms-input-placeholder {
                color: ${({ theme }) => theme.palette.lightGrey};
              }
              ::placeholder {
                color: ${({ theme }) => theme.palette.lightGrey};
              }
            }

            span {
              display: none !important;
            }

            &__month,
            &__year {
              display: none !important;
            }
          }

          &__range-divider {
            display: none !important;
          }
        }
      }
    `}

  border: 1px solid ${({ theme, invalid }) => (invalid ? 'red' : theme.palette.platinum)};
`;

export const StyledDateTimeRangePicker = styled(DateTimeRangePicker)<DatePickerProps>`
  ${RangeBase}

  border: 1px solid ${({ theme, invalid }) => (invalid ? 'red' : theme.palette.platinum)};
`;

export const StyledCalendarIcon = styled(CalendarIcon)`
  width: 1rem;

  path {
    fill: ${({ theme }) => theme.palette.primaryMustard};
  }

  ${({ disabled }) =>
    disabled &&
    css`
      pointer-events: none;

      path {
        fill: ${({ theme }) => theme.palette.lightGrey} !important;
      }
    `}
`;
