import React from 'react';
import { throttle } from 'throttle-debounce';
import { NotificationStatusRuleValue } from '@pushologies/segments-service/types';
import { Select, SelectOption } from '~components/select';
import { Rule } from '~store/create-segment/types';
import { AutosuggestField, Suggestion } from '~components/inputs/autosuggest-field';
import { useStoreActions } from '~store/hooks';
import { ApiNotification } from '~api/notifications/types';
import MagnifiyGlassIcon from '~images/mag_glass.svg';
import { SUBSCRIBER_NOTIFICATION_EVENT_STATUS } from '@pushologies/common/constants/subscriber';

interface Props {
  rule: Rule;
  invalid: boolean;
  onChange(value: NotificationStatusRuleValue): void;
}

const options: SelectOption[] = [
  {
    name: 'Pending',
    value: SUBSCRIBER_NOTIFICATION_EVENT_STATUS.PENDING
  },
  {
    name: 'Received',
    value: SUBSCRIBER_NOTIFICATION_EVENT_STATUS.RECEIVED
  },
  {
    name: 'Downloaded',
    value: SUBSCRIBER_NOTIFICATION_EVENT_STATUS.DOWNLOADED
  },
  {
    name: 'Download Failed',
    value: SUBSCRIBER_NOTIFICATION_EVENT_STATUS.DOWNLOAD_FAILED
  },
  {
    name: 'Opened',
    value: SUBSCRIBER_NOTIFICATION_EVENT_STATUS.OPENED
  },
  {
    name: 'Viewed',
    value: SUBSCRIBER_NOTIFICATION_EVENT_STATUS.VIEWED
  },
  {
    name: 'Link_opened',
    value: SUBSCRIBER_NOTIFICATION_EVENT_STATUS.LINK_OPENED
  },
  {
    name: 'Push_failed',
    value: SUBSCRIBER_NOTIFICATION_EVENT_STATUS.PUSH_FAILED
  }
];

export const NotificationStatusSelect: React.FC<Props> = ({ rule, onChange, invalid }) => {
  const [value, setValue] = React.useState<NotificationStatusRuleValue>(
    rule?.segmentRule?.value || { status: [], notificationId: '' }
  );
  const [notifications, setNotifications] = React.useState<Suggestion<ApiNotification>[]>([]);
  const { fetchNotifications } = useStoreActions((state) => state.notifications);

  const selectValue = options.filter((option) => value?.status?.includes(option.value));

  const handleNotificationSelect = ({ id }: ApiNotification) => {
    setNotifications([]);
    setValue({ ...value, notificationId: id });
  };

  const handleStatusSelect = (selected: SelectOption[]) => {
    const status = selected.map((item) => item.value);
    setValue({ ...value, status });
  };

  React.useEffect(() => {
    onChange(value);
  }, [value]);

  const handleSuggestionChange = (text: string) => {
    if (text.length < 2) {
      setNotifications([]);
      return;
    }

    throttle(250, () => {
      fetchNotifications({
        title: text,
        onSuccess(items) {
          setNotifications(
            items.map((item) => ({
              label: [item.title, item.id].filter(Boolean).join(' | '),
              value: item
            }))
          );
        }
      });
    })();
  };

  return (
    <>
      <AutosuggestField
        name="userSearch"
        placeholder="search by notification title"
        onChange={handleSuggestionChange}
        icon={<MagnifiyGlassIcon />}
        suggestions={notifications}
        onSuggestionSelect={handleNotificationSelect}
        className="notificationSearch"
        invalid={invalid}
        selectedSuggestionNamePath="title"
      />
      <Select
        id={rule.segmentRule.ruleId}
        testId="notificationStatusSelect"
        value={selectValue}
        options={options}
        onChange={handleStatusSelect}
        multi
        drop="down"
        placeholder="Select Status"
        inValid={invalid}
      />
    </>
  );
};
