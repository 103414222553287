import { action } from 'easy-peasy';
import { SideNavModel } from './types';

export * from './types';

const defaultState: Pick<
  SideNavModel,
  'links' | 'activeLink' | 'topBarRightTitle' | 'topBarLeftTitle' | 'activeChild' | 'visibility' | 'warningMessage'
> = {
  topBarRightTitle: '',
  topBarLeftTitle: '',
  links: [],
  activeLink: 0,
  activeChild: null,
  visibility: 'collapse',
  warningMessage: null
};

export const sideNavModel: SideNavModel = {
  topBarRightTitle: '',
  topBarLeftTitle: '',
  links: [],
  activeLink: 0,
  activeChild: null,
  visibility: 'collapse',
  warningMessage: null,

  setTopNav: action((state, payload) => {
    Object.assign(state, payload);
  }),

  setSideNavModel: action((state, payload) => {
    Object.assign(state, { ...defaultState }, payload, { visibility: payload.visibility || 'collapse' });
  })
};
