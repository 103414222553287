export const EASY_PEASY_STORE_VERSION = 1.1;

/** 0.9MB limit payload to constrain to api 1MB body size limit */
export const REQUEST_BODY_BYTE_LIMIT = 900000;

export const PROD_S3_CDN_URL = 'https://cdn.static.pushologies.com';

export const DEFAULT_USER = {
  email: 'paul@test.com',
  name: 'Paul',
  tenantId: '7defb907-fd02-42ba-a630-d3c6fbd53773',
  username: '34ce81eb-d033-4e98-a5ad-b8bde5db5e0d'
};
