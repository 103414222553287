import { Credentials } from '~api/credentials';

export const credentialsToStore = (credentials: Credentials) => ({
  ...(credentials.apnAuthKey && {
    apn: {
      authKey: credentials.apnAuthKey,
      bundleId: credentials.apnBundleId,
      keyId: credentials.apnKeyId,
      teamId: credentials.apnTeamId
    }
  }),
  ...(credentials.firebaseEmail && {
    firebase: {
      email: credentials.firebaseEmail,
      privateKey: credentials.firebasePrivateKey,
      projectId: credentials.firebaseProjectId
    }
  })
});
