import React, { useMemo } from 'react';
import { NavLink } from 'react-router-dom';
import { useStoreState, useStoreActions } from '~store/hooks';
import { sideNavChildren } from '~store/sidenav/children';
import { StyledSideNav, TitleDiv, ChildrenDiv, LinksDiv, StyledTopBar, StyledArrowIcon } from './styles';
import { RoleSpan } from '../../pages/settings/users/styles';

export const SideNav: React.FC = () => {
  const { topBarLeftTitle, topBarRightTitle, links, activeChild, visibility, warningMessage } = useStoreState(
    ({ sideNav }) => sideNav
  );
  const { setSideNavModel } = useStoreActions(({ sideNav }) => sideNav);
  const { isSuperTenant } = useStoreState((state) => state.childTenants);
  const { isAuthenticated } = useStoreState((state) => state.user);
  const tenant = useStoreState((state) => state.tenant.tenant);
  const toggleSidebar = () => {
    setSideNavModel({
      visibility: visibility === 'collapse' ? 'show' : 'collapse',
      topBarLeftTitle,
      topBarRightTitle,
      links,
      activeChild
    });
  };

  const userRole = useMemo(() => tenant?.userProfile?.role?.name?.toLowerCase(), [tenant]);

  if (isAuthenticated && isSuperTenant && topBarLeftTitle === 'dashboard') return <></>;

  return (
    <>
      <StyledTopBar>
        {topBarLeftTitle && (
          <TitleDiv>
            <p>{topBarLeftTitle}</p>
          </TitleDiv>
        )}
        {warningMessage && (
          <TitleDiv className={warningMessage && 'warning'}>
            <p>{warningMessage}</p>
          </TitleDiv>
        )}
        <TitleDiv>
          {topBarRightTitle ? (
            <h2>{topBarRightTitle}</h2>
          ) : (
            <>
              <p>{tenant?.userProfile?.name}</p>
              <RoleSpan className={userRole}>{userRole}</RoleSpan>
            </>
          )}
        </TitleDiv>
      </StyledTopBar>

      {activeChild && (
        <StyledSideNav defaultActiveKey="/" onClick={toggleSidebar} $expanded={visibility === 'show'}>
          <StyledArrowIcon $flip={visibility !== 'show'} />
          {activeChild && <ChildrenDiv> {sideNavChildren[activeChild]()}</ChildrenDiv>}
          <LinksDiv>
            {links.map((link, index) => (
              <NavLink key={index} to={link.path} exact>
                {link.name}
              </NavLink>
            ))}
          </LinksDiv>
        </StyledSideNav>
      )}
    </>
  );
};
