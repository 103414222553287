import React, { useMemo } from 'react';
import { Select, Props as SelectProps } from '~components/select';
import { StyledInputSection } from './styles';

interface Props extends SelectProps {
  label?: string;
}

export const SelectField: React.FC<Props> = (props) => {
  const { label, ...rest } = props;

  return useMemo(
    () => (
      <StyledInputSection>
        {label && <label htmlFor={rest.id}>{label}</label>}
        <Select {...rest} inValid={rest.inValid ?? !rest.value.length} />
      </StyledInputSection>
    ),
    [rest.id, rest.options, rest.placeholder, rest.value, rest.multi, rest.drop]
  );
};
