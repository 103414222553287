import React from 'react';
import NumberEasing from 'react-number-easing';
import { MEASUREMENT_TYPE, MEASUREMENT_INTERVAL } from '@pushologies/database-service/db/entities/measurement';
import dayjs from '~helpers/dayjs';
import { Measurement } from '~api/measurements';
import { useStoreState } from '~store/hooks';
import { INTERVAL_TO_UNIT } from '../helpers';
import { BarChart, BarChartMetrics } from './bar-chart';
import { MetricsHeaderDiv, MetricsWrapperDiv } from './styles';

const mainColor = '#8587df';
const secondaryColor = '#d8a0fe';
interface Props {
  measurements: Measurement[];
}

export const AverageSessionsPerUserMetrics: React.FC<Props> = ({ measurements }) => {
  const now = dayjs().utc();
  const activeInterval = useStoreState((state) => state.measurements.activeInterval);
  const unit = INTERVAL_TO_UNIT[activeInterval][1];

  const day = now.startOf(unit);
  const metrics: BarChartMetrics[] = [];
  for (let i = 7, min = 1; i >= min; i--) {
    const date = day.subtract(i, unit);
    const xLabel = activeInterval === MEASUREMENT_INTERVAL.DAILY ? date.format('dddd')[0] : date.format('MMM');
    const dayMeasurement = measurements.find(
      ({ type, interval, startsAt }) =>
        type === MEASUREMENT_TYPE.AVG_SESSIONS_PER_SUBSCRIBER &&
        interval === activeInterval &&
        startsAt === date.toISOString()
    );
    metrics.push({
      value: dayMeasurement?.value || 0,
      xLabel
    });
  }
  const averageSessions = Math.floor(metrics.reduce((num, metric) => num + metric.value, 0) / metrics.length) || 0;

  return (
    <MetricsWrapperDiv>
      <MetricsHeaderDiv $headingColor={mainColor} data-testid="sessionsPerUserHeader">
        <h6>average sessions per user</h6>
        <section>
          <p className="title">total</p>
          <p className="number">
            <NumberEasing value={averageSessions} speed={1500} />
          </p>
          <p className="metric">sessions</p>
        </section>
      </MetricsHeaderDiv>
      <BarChart metrics={metrics} gradient={[mainColor, secondaryColor]} testId="sessionsPerUserBarChart" />
    </MetricsWrapperDiv>
  );
};
