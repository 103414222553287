import { CONTENT_ITEM_MEDIA_TYPE, CONTENT_ITEM_SOURCE_TYPE } from '@pushologies/common/constants/content-item';
import { ApiContentItem } from '~api/content-items/types';
import { PROD_S3_CDN_URL } from '~helpers/constants';
const { Auth } = require('@aws-amplify/auth');

export function getJWTToken() {
  const storage = Auth._storage;
  const idTokenKey = Object.keys(storage).find((key) => key.match(/idToken$/));

  return idTokenKey ? storage[idTokenKey] : null;
}

/**
 * Constructs thumbnail url from given argument
 * If thumbnailUrl is a blob (item recently uploaded), we return the blob url. If its a external image just
 * use external url. If not all of the above, just use default thumbnail image
 */
export const constructThumbnailUrl = (item: ApiContentItem): string => {
  if (!item) return null;

  if (item.sourceType === CONTENT_ITEM_SOURCE_TYPE.EXTERNAL_URL && item.mediaType === CONTENT_ITEM_MEDIA_TYPE.IMAGE) {
    return item.externalSourceUrl;
  }

  if (item?.thumbnailUrl?.startsWith('blob:')) {
    return item.thumbnailUrl;
  }

  if (item.hasThumbnail) {
    return `${process.env.API_URL}/content-item-thumbnail?id=${item.id}&authorization=${getJWTToken()}`;
  }

  return `${PROD_S3_CDN_URL}/assets/default_thumbnail.png`;
};
