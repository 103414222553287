import React, { useMemo } from 'react';
import { Container, Tab, Nav } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import { TopRow } from '~pages/notifications/results/top-row';
import { PersonalisationString } from '~components/personalisation-string';
import { BottomRow } from '~pages/notifications/results/bottom-row';
import { useStoreActions, useStoreState } from '~store/hooks';
import { ApiNotification } from '~api/notifications/types';
import { Loader } from '~components/loader';
import { dateTimeFormat } from '~helpers/formats';
import { notificationsPageLinks } from '../links';
import { RefreshIntervalDropdown } from './refresh-interval-dropdown';
import { StyledRow } from './styles';
import { PollResults } from './poll-results';
import { NOTIFICATION_TYPE } from '@pushologies/database-service/db/entities/notification';

enum NOTIFICATION_RESULTS_VIEW {
  NOTIFICATION = 'notification',
  POLL = 'poll'
}

export const ViewNotificationResults: React.FC = () => {
  const [tabKey, setTabKey] = React.useState(NOTIFICATION_RESULTS_VIEW.NOTIFICATION);
  const [count, setCount] = React.useState(0);
  const [loading, setLoading] = React.useState(true);
  const [notification, setNotification] = React.useState<ApiNotification>();
  const [{ loading: segmentsLoading }, { isSuperTenant }, { notificationResults, loading: resultsLoading }] =
    useStoreState((state) => [state.segments, state.childTenants, state.notificationResult]);
  const { setSideNavModel } = useStoreActions((state) => state.sideNav);
  const { fetchNotificationResults } = useStoreActions((state) => state.notificationResult);
  const { fetchNotification } = useStoreActions((state) => state.notifications);
  const { id, tenant } = useParams<{ id: string; tenant: string }>();
  const delivered = !!notificationResults.find(({ received }) => received > 0);

  const handleRefresh = () => {
    fetchNotificationResults({ notificationId: id, tenantId: isSuperTenant ? tenant : null });
  };

  const handleFetchSegmentBucket = () => {
    fetchNotification({
      id,
      getDownloadUrl: true,
      onSuccess(fetchedNotification) {
        // can take time for the segment bucket to be populated during peak use
        if (!fetchedNotification.segmentBucket?.id) {
          return setTimeout(handleFetchSegmentBucket, 1000);
        }
        setCount(fetchedNotification.segmentBucket.size);
      }
    });
  };

  React.useEffect(() => {
    setSideNavModel({
      topBarLeftTitle:
        notification && notification.type === NOTIFICATION_TYPE.INAPP
          ? 'In App Messaging Report'
          : 'Notification Report',
      activeChild: 'notificationResults',
      links: notificationsPageLinks
    });

    !notification &&
      fetchNotification({
        id,
        getDownloadUrl: true,
        tenantId: isSuperTenant ? tenant : null,
        onSuccess(fetchedNotification) {
          fetchNotificationResults({ notificationId: id, tenantId: isSuperTenant ? tenant : null });
          setNotification(fetchedNotification);

          if (!fetchedNotification.segmentBucket?.id) {
            handleFetchSegmentBucket();
          } else {
            setCount(fetchedNotification.segmentBucket.size);
          }

          setLoading(false);
        }
      });
  }, [notification]);

  const [notificationPushLabel, notificationPushTimestamp] = useMemo(() => {
    if (!notification?.pushedAt && notification?.schedule?.pushAt)
      return ['Scheduled For:', notification.schedule.pushAt];
    return ['Started Sending At:', notification?.pushedAt];
  }, [notification]);

  const handleTabChange = (tab: NOTIFICATION_RESULTS_VIEW) => {
    setTabKey(tab);
  };

  const showTabs = useMemo(() => [NOTIFICATION_TYPE.POLL].includes(notification?.type), [notification?.type]);

  return (
    <Container id="page-container">
      <Tab.Container
        defaultActiveKey={NOTIFICATION_RESULTS_VIEW.NOTIFICATION}
        activeKey={tabKey}
        onSelect={handleTabChange}
        data-testId={'notificationResultsTabs'}
      >
        <Loader loading={loading || resultsLoading || segmentsLoading !== 'idle'} backdrop />
        {showTabs && (
          <StyledRow>
            <Nav className="flex-row">
              <Nav.Item>
                <Nav.Link eventKey={NOTIFICATION_RESULTS_VIEW.NOTIFICATION}>notification</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey={NOTIFICATION_RESULTS_VIEW.POLL}>poll results</Nav.Link>
              </Nav.Item>
            </Nav>
          </StyledRow>
        )}
        <Tab.Content>
          <Tab.Pane eventKey={NOTIFICATION_RESULTS_VIEW.NOTIFICATION}>
            <StyledRow data-testid="header">
              <div>
                <PersonalisationString
                  as="h1"
                  text={notification?.title}
                  personalisation={notification?.personalisation}
                />
                {delivered && <span className="status"> Delivered</span>}
              </div>
              <div id="notificationTimeInfo">
                <p>
                  {notificationPushLabel} {dateTimeFormat(notificationPushTimestamp, 'MMMM Do YYYY, HH:mm:ss A')}
                </p>
                <RefreshIntervalDropdown onRefresh={handleRefresh} />
              </div>
            </StyledRow>
            <TopRow notification={notification} segmentSize={count} />
            <BottomRow notification={notification} />
          </Tab.Pane>
          {notification?.pollId && (
            <Tab.Pane eventKey={NOTIFICATION_RESULTS_VIEW.POLL}>
              <PollResults pollId={notification.pollId} />
            </Tab.Pane>
          )}
        </Tab.Content>
      </Tab.Container>
    </Container>
  );
};
