import { Entity, PrimaryGeneratedColumn, CreateDateColumn, Column, JoinColumn, ManyToOne, Index } from 'typeorm';
import { CONTENT_ITEM_PRESENTATION_TYPE } from '@pushologies/common/constants/content-item';
import { ContentItem } from './content-item';
import { Tenant } from './tenant';
import { Notification } from './notification';
import { TenantUser } from './tenant-user';

export type ZoomMode = 'zoom' | 'fit';
export type LinkHandling = 'defaultBrowser' | 'inAppBrowser' | 'defaultBrowserWithTag' | 'inAppBrowserWithTag';
export enum Fonts {
  Arial = 'Arial',
  Times = 'Times',
  Courier = 'Courier'
}
export type Orientation = 'default' | 'landscape' | 'portrait';
export type ButtonPosition = {
  top: number;
  left: number;
  bottom: number;
  right: number;
};
export type ButtonTextAlignmentVertical = 'top' | 'center' | 'bottom';
export type ButtonTextAlignmentHorizontal = 'left' | 'center' | 'right';

export interface ContentButtonOptions {
  displayName?: string;
  url?: string;
  text?: string;
  textColor?: string;
  fontSize?: number;
  backgroundColor?: string;
  borderColor?: string;
  contentItemId?: string;
  fullScreen?: boolean;
  position?: ButtonPosition;
  buttonAppear?: number;
  buttonDisappear?: number;
  linkHandling?: LinkHandling;
  font?: Fonts;
  buttonId?: string;
  textAlignmentVertical?: ButtonTextAlignmentVertical;
  textAlignmentHorizontal?: ButtonTextAlignmentHorizontal;
  textWrapping?: boolean;
  tagName?: string;
  /** only set when GET /notification endpoint is called with getDownloadUrls query set to true and button has a contentItemId */
  buttonImageUrl?: string;
}

export interface WebpageModalsOptions {
  url: string;
  fullScreen: boolean;
  modalAppear: number;
  modalDisappear?: number;
}

export interface ContentOptions {
  allowPreview?: boolean;
  allowSharing?: boolean;
  allowScrubbing?: boolean;
  useEmbeddedPlayer?: boolean;
  fullScreen?: boolean;
  zoomMode?: ZoomMode;
  orientation?: Orientation;
  buttons?: ContentButtonOptions[];
  webpageModal?: WebpageModalsOptions[];
}

@Entity('content')
export class Content {
  @PrimaryGeneratedColumn('uuid')
  id: string;

  @ManyToOne((_) => Tenant, (tenant) => tenant.contents, { onDelete: 'CASCADE' })
  @JoinColumn()
  @Index()
  tenant: Tenant;

  @ManyToOne((_) => Notification, (notification) => notification.contents, { onDelete: 'CASCADE' })
  @JoinColumn()
  @Index()
  notification: Notification;

  @ManyToOne((_) => ContentItem)
  @JoinColumn()
  @Index()
  contentItem: ContentItem;

  @Column({ name: 'default_content' })
  defaultContent: boolean;

  @Column({ name: 'sort_order', type: 'integer', nullable: true })
  sortOrder: number;

  @Column({
    name: 'presentation',
    type: 'varchar',
    default: CONTENT_ITEM_PRESENTATION_TYPE.DEFAULT
  })
  presentation: CONTENT_ITEM_PRESENTATION_TYPE;

  @Column({ type: 'json', nullable: true })
  options: ContentOptions;

  @Column({ nullable: true })
  createdById: string;
  @ManyToOne((_) => TenantUser, { onDelete: 'SET NULL' })
  @JoinColumn()
  @Index()
  createdBy: TenantUser;

  @CreateDateColumn({ name: 'created_at', type: 'timestamptz' })
  createdAt: Date;
}
