import { DefaultTheme } from 'styled-components';
import { darken } from 'polished';
import { SUBSCRIBER_DEVICE_TYPES } from '@pushologies/database-service/db/entities/subscriber';
import { ApiNotification, ApiNotificationResults } from '~api/notifications/types';
import { Props as BarChartProps } from '~components/bar-chart';
import { numberCommaFormat } from '~helpers/formats';
import { NOTIFICATION_TYPE } from '@pushologies/database-service/db/entities/notification';

const resultType: (keyof ApiNotificationResults)[] = [
  'messageCount',
  'receivedUnique',
  'downloadedUnique',
  'openedUnique',
  'viewedUnique',
  'linkOpened'
];

export function getBarChartData(
  results: ApiNotificationResults[],
  segmentSize: number,
  theme: DefaultTheme,
  notification?: ApiNotification
) {
  const androidResults: ApiNotificationResults = results.find(
    ({ deviceType }) => deviceType === SUBSCRIBER_DEVICE_TYPES.ANDROID
  );
  const iosResults: ApiNotificationResults = results.find(
    ({ deviceType }) => deviceType === SUBSCRIBER_DEVICE_TYPES.IOS
  );

  // ensure we only show results appropriate for each notification type
  const filteredResultTypes =
    notification?.type === NOTIFICATION_TYPE.POLL
      ? resultType.filter((eachType) => !['linkOpened'].includes(eachType))
      : resultType;

  const totalResults = androidResults?.messageCount + iosResults?.messageCount || 0;
  const barChartData: BarChartProps[] = filteredResultTypes
    .filter(
      (data) =>
        (notification?.type !== NOTIFICATION_TYPE.CAROUSEL && notification?.type !== NOTIFICATION_TYPE.VIDEO) ||
        data !== 'linkOpened'
    )
    .map((data) => {
      return getDataByResultType(data, segmentSize, androidResults, iosResults, theme);
    });

  return { barChartData, androidResults, iosResults, totalResults };
}

function getDataByResultType(
  type: keyof ApiNotificationResults,
  segmentSize: number,
  androidResults: ApiNotificationResults,
  iosResults: ApiNotificationResults,
  theme: DefaultTheme
) {
  const data: Record<string, BarChartProps> = {
    messageCount: {
      bars: [
        [
          {
            percentage: Math.round(((androidResults?.messageCount ?? 0) / segmentSize) * 100),
            color: theme.palette.android,
            label: numberCommaFormat(androidResults?.messageCount)
          },
          {
            percentage: Math.round(((androidResults?.pushFailed ?? 0) / segmentSize) * 100),
            color: darken(0.2, theme.palette.android),
            label: numberCommaFormat(androidResults?.pushFailed)
          }
        ].filter(({ percentage }) => !!percentage),
        [
          {
            percentage: Math.round(((iosResults?.messageCount ?? 0) / segmentSize) * 100),
            color: theme.palette.apple,
            label: numberCommaFormat(iosResults?.messageCount)
          },
          {
            percentage: Math.round(((iosResults?.pushFailed ?? 0) / segmentSize) * 100),
            color: darken(0.2, theme.palette.apple),
            label: numberCommaFormat(iosResults?.pushFailed)
          }
        ].filter(({ percentage }) => !!percentage)
      ],
      label: 'sent',
      totalPercentage:
        Math.round(((androidResults?.messageCount + iosResults?.messageCount) / segmentSize ?? 0) * 100) || 0,
      helpTip: `For iOS notifications, ${numberCommaFormat(iosResults?.messageCount)} were sent and ${numberCommaFormat(
        iosResults?.pushFailed
      )} failed to send. For android, ${numberCommaFormat(
        androidResults?.messageCount
      )} successfully sent and ${numberCommaFormat(androidResults?.pushFailed)} failed to push`,
      testId: 'sentBarChart'
    },

    receivedUnique: {
      bars: [
        [
          {
            percentage: Math.round(((androidResults?.receivedUnique ?? 0) / segmentSize) * 100),
            color: theme.palette.android,
            label: numberCommaFormat(androidResults?.receivedUnique)
          }
        ].filter(({ percentage }) => !!percentage),
        [
          {
            percentage: Math.round(((iosResults?.receivedUnique ?? 0) / segmentSize) * 100),
            color: theme.palette.apple,
            label: numberCommaFormat(iosResults?.receivedUnique)
          }
        ].filter(({ percentage }) => !!percentage)
      ],
      label: 'delivered',
      totalPercentage:
        Math.round(((androidResults?.receivedUnique + iosResults?.receivedUnique ?? 0) / segmentSize) * 100) || 0,
      helpTip: `${numberCommaFormat(iosResults?.receivedUnique)} ios, and ${numberCommaFormat(
        androidResults?.receivedUnique
      )} android devices have succesfully received this notification.`,
      testId: 'deliveredBarChart'
    },

    openedUnique: {
      bars: [
        [
          {
            percentage: Math.round(((androidResults?.openedUnique ?? 0) / segmentSize) * 100),
            color: theme.palette.android,
            label: numberCommaFormat(androidResults?.openedUnique)
          }
        ],
        [
          {
            percentage: Math.round(((iosResults?.openedUnique ?? 0) / segmentSize) * 100),
            color: theme.palette.apple,
            label: numberCommaFormat(iosResults?.openedUnique)
          }
        ].filter(({ percentage }) => !!percentage)
      ],
      label: 'opened',
      totalPercentage:
        Math.round(((androidResults?.openedUnique + iosResults?.openedUnique ?? 0) / segmentSize) * 100) || 0,
      helpTip: `${numberCommaFormat(iosResults?.openedUnique)} ios, and ${numberCommaFormat(
        androidResults?.openedUnique
      )} android devices have succesfully opened this notification`,
      testId: 'openedBarChart'
    },

    downloadedUnique: {
      bars: [
        [
          {
            percentage: Math.round(((androidResults?.downloadedUnique ?? 0) / segmentSize) * 100),
            color: theme.palette.android,
            label: numberCommaFormat(androidResults?.downloadedUnique)
          },
          {
            percentage: Math.round(((androidResults?.downloadFailedUnique ?? 0) / segmentSize) * 100),
            color: darken(0.2, theme.palette.android),
            label: numberCommaFormat(androidResults?.downloadFailedUnique)
          }
        ].filter(({ percentage }) => !!percentage),
        [
          {
            percentage: Math.round(((iosResults?.downloadedUnique ?? 0) / segmentSize) * 100),
            color: theme.palette.apple,
            label: numberCommaFormat(iosResults?.downloadedUnique)
          },
          {
            percentage: Math.round(((iosResults?.downloadFailedUnique ?? 0) / segmentSize) * 100),
            color: darken(0.2, theme.palette.apple),
            label: numberCommaFormat(iosResults?.downloadFailedUnique)
          }
        ].filter(({ percentage }) => !!percentage)
      ],
      label: 'downloaded',
      totalPercentage:
        Math.round(((androidResults?.downloadedUnique + iosResults?.downloadedUnique ?? 0) / segmentSize) * 100) || 0,
      helpTip: `${numberCommaFormat(iosResults?.downloadedUnique)} ios, and ${numberCommaFormat(
        androidResults?.downloadedUnique
      )} android devices have succesfully downloaded this notification. ${numberCommaFormat(
        iosResults?.downloadFailedUnique
      )} ios, and ${numberCommaFormat(
        androidResults?.downloadFailedUnique
      )} android devices failed to download the notification video`,
      testId: 'downloadedBarChart'
    },

    viewedUnique: {
      bars: [
        [
          {
            percentage: Math.round(((androidResults?.viewedUnique ?? 0) / segmentSize) * 100),
            color: theme.palette.android,
            label: numberCommaFormat(androidResults?.viewedUnique)
          }
        ],
        [
          {
            percentage: Math.round(((iosResults?.viewedUnique ?? 0) / segmentSize) * 100),
            color: theme.palette.apple,
            label: numberCommaFormat(iosResults?.viewedUnique)
          }
        ].filter(({ percentage }) => !!percentage)
      ],
      label: 'finished views',
      totalPercentage:
        Math.round(((androidResults?.viewedUnique + iosResults?.viewedUnique ?? 0) / segmentSize) * 100) || 0,
      helpTip: `${numberCommaFormat(iosResults?.viewedUnique)} ios, and ${numberCommaFormat(
        androidResults?.viewedUnique
      )} android devices have succesfully viewed this notification`,
      testId: 'finishedViewsBarChart'
    },

    linkOpened: {
      bars: [
        [
          {
            percentage: Math.round(((androidResults?.linkOpenedUnique ?? 0) / segmentSize) * 100),
            color: theme.palette.android,
            label: numberCommaFormat(androidResults?.linkOpenedUnique)
          }
        ],
        [
          {
            percentage: Math.round(((iosResults?.linkOpenedUnique ?? 0) / segmentSize) * 100),
            color: theme.palette.apple,
            label: numberCommaFormat(iosResults?.linkOpenedUnique)
          }
        ].filter(({ percentage }) => !!percentage)
      ],
      label: 'link opened',
      totalPercentage:
        Math.round(((androidResults?.linkOpenedUnique + iosResults?.linkOpenedUnique ?? 0) / segmentSize) * 100) || 0,
      helpTip: `${numberCommaFormat(iosResults?.linkOpenedUnique)} ios, and ${numberCommaFormat(
        androidResults?.linkOpenedUnique
      )} android devices have opened a link in this notification`,
      testId: 'linkOpenedBarChart'
    }
  };

  return data[type];
}
