import { SelectQueryBuilder } from 'typeorm';
import { Subscriber } from '@pushologies/database-service/db/entities';
import { SegmentRule, OperatorValue as Op, DatesRuleValue } from '../types';
import { Rule } from '.';

export class LastSessionRule extends Rule {
  constructor(private rule: SegmentRule<DatesRuleValue>) {
    super();
  }

  updateQuery(query: SelectQueryBuilder<Subscriber>) {
    const [sql, params] = this.getQueryString();

    query.andWhere(sql, params);
  }

  private getQueryString(): [string, object] {
    const dateParam = this.createParameterHash();
    switch (this.rule.operator) {
      case Op.after:
        return [`subscriber.last_seen >= :${dateParam}`, { [dateParam]: this.rule.value.date }];
      case Op.before:
        return [`subscriber.last_seen <= :${dateParam}`, { [dateParam]: this.rule.value.date }];
      case Op.between:
        const fromParam = this.createParameterHash();
        const toParam = this.createParameterHash();
        return [
          `subscriber.last_seen BETWEEN :${fromParam} AND :${toParam}`,
          { [fromParam]: this.rule.value.from, [toParam]: this.rule.value.to }
        ];
      default:
        throw new Error(`operator "${this.rule.operator}" not supported`);
    }
  }
}
