import { SelectQueryBuilder } from 'typeorm';
import { Subscriber } from '@pushologies/database-service/db/entities/subscriber';
import { OperatorValue as Op, SegmentRule } from '../types';
import { Rule } from '.';

export class DeviceModelsRule extends Rule {
  constructor(private rule: SegmentRule<string[]>) {
    super();
  }

  updateQuery(query: SelectQueryBuilder<Subscriber>) {
    if (!this.rule.value?.length) return;

    const [sql, params] = this.getQueryString();
    query.andWhere(sql, params);
  }

  private getQueryString(): [string, object] {
    const deviceModelListParam = this.createParameterHash();
    switch (this.rule.operator) {
      case Op.in:
        return [
          `subscriber.device_model_identifier IN (:...${deviceModelListParam})`,
          { [deviceModelListParam]: this.rule.value }
        ];
      case Op.notIn:
        return [
          `subscriber.device_model_identifier NOT IN (:...${deviceModelListParam})`,
          { [deviceModelListParam]: this.rule.value }
        ];
      default:
        throw new Error(`operator "${this.rule.operator}" not supported`);
    }
  }
}
