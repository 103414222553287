import React, { useMemo } from 'react';
import { StyledInputSection } from './styles';

interface Props {
  name: string;
  value?: string;
  label?: string;
  subLabel?: string;
  placeholder?: string;
  invalid?: boolean;
  cols?: number;
  rows?: number;
  onChange(event: React.ChangeEvent<HTMLTextAreaElement>): void;
}

export const TextArea: React.FC<Props> = ({
  value,
  label,
  subLabel,
  invalid,
  onChange,
  name,
  rows,
  cols,
  placeholder
}) => {
  return useMemo(
    () => (
      <StyledInputSection invalid={invalid} data-testid={name}>
        {label && (
          <label htmlFor={name}>
            {label} <span>{subLabel}</span>
          </label>
        )}
        <textarea
          rows={rows}
          cols={cols}
          id={name}
          name={name}
          value={value || ''}
          onChange={onChange}
          placeholder={placeholder}
        />
      </StyledInputSection>
    ),
    [value, label, invalid, name, rows, cols, placeholder]
  );
};
