import styled from 'styled-components';
import { rgba, darken } from 'polished';
import PreviewBg from '~images/preview_bg.png';
import { InputSection } from '~components/inputs/styles';
import { ButtonOptions } from '~store/create-notification/types';
import { StyledButton } from '~components/button/styles';
import { StyledSpan } from '~components/inputs/personalisation/styles';
import { StyledDropdown } from '~components/select/styles';
import { Select, Props } from '~components/select';
import { DropdownItem } from '~components/select/styles';

export const VideoInfoDiv = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

export const ActiveButtonSection = styled.section`
  position: relative;
  display: inline-flex;
  justify-content: space-between;
  background: ${({ theme }) => theme.palette.faintGrey};
  border: 1px solid ${({ theme }) => theme.palette.lighterGrey};
  padding: 0.5rem;
  width: 77%;
  height: 100%;

  .inputFieldsWrapper {
    position: relative;
    display: inline-flex;
    justify-content: space-between;
    flex-wrap: wrap;
    width: calc(65% - 1.5rem);

    & > section {
      width: calc(50% - 0.1rem);
      height: 1.5rem;
      min-height: unset;
      margin-bottom: 0.4rem;

      &:nth-child(9),
      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  ${StyledDropdown} {
    width: calc(50% - 0.1rem);
    height: 1.5rem;

    button {
      border: 1px solid ${({ theme }) => theme.palette.platinum};
      background: white;
      padding-top: 0;
      padding-bottom: 0;
      padding-left: 0.5rem;

      span {
        font-size: 0.9rem;
        color: ${({ theme }) => theme.palette.platinum};
      }
    }

    &:hover {
      button {
        border: 1px solid ${({ theme }) => theme.palette.platinum};
      }
    }
  }

  &.disabled {
    &:after {
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      background: ${({ theme }) => rgba(theme.palette.lighterGrey, 0.7)};
    }
  }

  ${StyledSpan} {
    display: flex;
    width: 0.8rem;
  }
`;

interface ButtonPreviewProps {
  $button: ButtonOptions;
}

export const ButtonPreviewDiv = styled.div<ButtonPreviewProps>`
  position: relative;
  display: inline-block;
  box-shadow: 0px 0px 1rem 0px rgba(0, 0, 0, 0.2) inset;
  background: url(${PreviewBg}) repeat;
  flex-grow: 2;
  margin-left: 0.6rem;
  overflow: hidden;

  p {
    position: absolute;
    font-family: EavesBold;
    color: ${({ theme }) => theme.palette.textGrey};
    text-transform: uppercase;
    margin: 0.25rem;

    &.title {
      font-size: 0.7rem;
      top: 0;
      left: 0;
    }
    &.name {
      font-size: 0.8rem;
      bottom: 0;
      right: 0;
    }
  }

  figure {
    position: absolute;
    display: flex;
    width: 70%;
    justify-content: center;
    top: 50%;
    left: 50%;
    transform: translateY(-50%) translateX(-45%);

    span.width,
    span.height {
      position: absolute;
      font-family: EavesRegular;
      color: ${({ theme }) => theme.palette.textGrey};
      display: ${({ $button }) => (!!$button ? 'block' : 'none')};

      &.width {
        top: -1.5rem;
      }
      &.height {
        top: 50%;
        left: 0;
        transform: translateY(-50%) translateX(calc(-100% - 0.25rem));
      }
    }

    img {
      width: 100%;
      object-fit: contain;
      max-height: 4rem;
      display: ${({ $button }) => (!!$button?.options?.contentItemId ? 'block' : 'none')};
    }

    section {
      width: 100%;
      height: 3rem;
      font-size: 1.5rem;
      display: ${({ $button }) => (!!$button?.options?.text ? 'flex' : 'none')};
      align-items: center;
      justify-content: center;
      text-wrap: ${({ $button }) => ($button?.options?.textWrapping ? 'wrap' : 'nowrap')};
      background: ${({ $button }) => $button?.options?.backgroundColor};
      border: ${({ $button }) =>
        $button?.options?.borderColor ? `1px solid ${$button?.options?.borderColor}` : 'none'};
      color: ${({ $button }) => $button?.options?.textColor};
    }
  }
`;

export const VideoOptionsSection = styled.section`
  position: relative;
  display: inline-flex;
  flex-direction: column;
  justify-content: space-around;
  width: calc(25% - 2rem);
  height: 100%;

  & > div {
    position: relative;
    width: 100%;

    ${InputSection} {
      height: 2rem;
      margin-bottom: 0.5rem;
    }

    &:last-child {
      display: flex;
      justify-content: flex-end;

      ${StyledButton} {
        height: 1.8rem;
        width: 6rem;

        &:first-child {
          margin: 0 0.5rem;
          background: ${({ theme }) => theme.palette.primaryMustard};
          color: white;

          &:hover {
            cursor: pointer;
            background: ${({ theme }) => darken(0.09, theme.palette.primaryMustard)};
          }
        }
      }
    }
  }
`;

export const TwinSection = styled.section`
  position: relative;
  display: flex;
  justify-content: space-between;

  ${InputSection} {
    min-height: unset;
    height: 100%;
    width: calc(50% - 0.1rem);
  }
`;

export const StyledSelect = styled(Select)<Props>`
  ${DropdownItem} {
    padding-bottom: 0.1rem;
    padding-top: 0.25rem;

    ${({ options }) =>
      options
        .map(
          (option, i) => `
      &:nth-child(${i + 1}) {
        font-family: ${option.value};
      }
    `
        )
        .join('')}
  }
`;

export const StyledTwinSectionWrapper = styled.div`
  width: 100%;
  background-color: #e6e6e6;
  border-radius: 5px;
  margin: 5px 0;

  & > div {
    font-size: 10px;
    text-align: right;
  }
`;

export const FullWidthSection = styled.div`
  width: 100%;
  margin-bottom: 10px;

  div {
    width: 100% !important;
  }
`;
