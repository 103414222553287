import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { Location } from 'history';
import { RouteProps } from 'react-router-dom';
import { useStoreState } from '~store/hooks';

export interface RedirectState {
  from: Location;
}

const exRoutes = ['/segments', '/subscriber-explorer', '/in-app'];

export const PrivateRoute: React.FC<RouteProps> = ({ children, path, ...rest }) => {
  const [{ isAuthenticated }, { isSuperTenant }] = useStoreState((state) => [state.user, state.childTenants]);

  return (
    <Route
      {...rest}
      path={path}
      render={
        isAuthenticated && isSuperTenant && exRoutes.includes(path.toString())
          ? () => <Redirect to="/404" />
          : ({ location }) =>
              isAuthenticated ? children : <Redirect to={{ pathname: '/login', state: { from: location } }} />
      }
    />
  );
};
