import styled from 'styled-components';
import { Modal } from 'react-bootstrap';
import { HideScrollbars } from '~styles/mixins';
import PlusIcon from '~images/plus.svg';
import DeleteIcon from '~images/delete.svg';
import SwapIcon from '~images/swap.svg';

export const StyledModal = styled(Modal)`
  overflow-y: hidden !important;

  .modal-dialog {
    max-width: unset;
    min-height: unset;
    height: 95vh;
    width: 60vw;
    min-width: 980px;

    .modal-content {
      width: 100%;
      height: 100%;
      border: none;
      border-radius: 0;

      .modal-body {
        overflow-y: scroll;
        padding-right: 0;
        padding-left: 0;
        ${HideScrollbars}

        .container-fluid {
          height: 100%;

          .row {
            height: 100%;

            [class^='col'] {
              height: 100%;
            }
          }
        }
      }
    }
  }
`;

export const ButtonsDiv = styled.div`
  position: relative;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  width: 100%;
  height: 2.5rem;

  button {
    width: 10rem;
    height: 2rem;
    margin: 0 0 0 0.5rem;

    &:first-child {
      background: ${({ theme }) => theme.palette.primaryMustard};
    }
  }
`;

export const PollBuilderHeader = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 1.5rem;

  h2 {
    margin: 0;
    font-weight: bold;
    font-size: 28px;
    text-transform: uppercase;
  }

  button {
    width: 10rem;
    height: 2rem;
    margin: 0 0 0 0.5rem;

    &:first-child {
      background: ${({ theme }) => theme.palette.primaryMustard};
    }
  }
`;

export const StyledPlusIcon = styled(PlusIcon)`
  width: 1rem;
  margin: 0.1rem;

  path {
    fill: ${({ theme }) => theme.palette.platinum};
  }
`;

export const StyledDeleteIcon = styled(DeleteIcon)`
  width: 0.7rem;
  margin: 0.1rem;

  path {
    fill: ${({ theme }) => theme.palette.platinum};
  }
`;

export const StyledSwapIcon = styled(SwapIcon)`
  width: 1.8rem;
  margin: 0.1rem;

  path {
    fill: ${({ theme }) => theme.palette.platinum};
  }
`;
