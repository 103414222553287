import { action, persist, thunk, State } from 'easy-peasy';
import { handleUnAuthenticated } from '~store/helpers';
import { arrayToObject } from '~helpers/arrays';
import { NotificationsModel } from './types';
import { debounce } from 'throttle-debounce';

export * from './types';

export const LIMIT = 20;

const initialState: State<NotificationsModel> = {
  loading: false,
  error: null,
  totalItems: LIMIT,
  notifications: {}
};

export const notificationsModel: NotificationsModel = persist(
  {
    ...initialState,

    setModel: action((state, { params, notifications, ...rest }) => {
      notifications && Object.assign(state.notifications, arrayToObject('id', notifications));
      Object.assign(state, rest);
    }),

    fetchNotifications: thunk(
      debounce(3000, false, async (actions, payload, { injections, getStoreActions }) => {
        actions.setModel({ loading: true });
        const { onSuccess, onError, tenantId, ...params } = payload || {};

        try {
          const { notifications } = await (tenantId
            ? injections.notificationsApi.fetchNotificationsByProxy(params, tenantId)
            : injections.notificationsApi.fetchNotifications(params));

          // The query to count notifications has become very slow so we no longer run it
          // we'll manually set the totalItems to 200 = 10 pages of 20
          actions.setModel({ loading: false, error: null, notifications, totalItems: 200, params });

          if (onSuccess) onSuccess(notifications, 200);
        } catch (error) {
          await handleUnAuthenticated(error, getStoreActions());

          getStoreActions().notificationWidget.addNotification({
            type: 'error',
            message: error.message
          });

          actions.setModel({ loading: false, error: error.message });
          if (onError) onError(error.message);
        }
      })
    ),

    fetchNotification: thunk(async (actions, payload, { injections, getStoreActions }) => {
      actions.setModel({ loading: true });
      const { id, getDownloadUrl, tenantId, onSuccess } = payload;
      try {
        const { notification } = await (tenantId
          ? injections.notificationsApi.fetchNotificationByProxy(id, getDownloadUrl, tenantId)
          : injections.notificationsApi.fetchNotification(id, getDownloadUrl));

        actions.setModel({
          loading: false,
          error: null,
          notifications: [notification],
          params: { limit: LIMIT, offset: 0 }
        });

        if (onSuccess) await onSuccess(notification);
      } catch (error) {
        await handleUnAuthenticated(error, getStoreActions());

        getStoreActions().notificationWidget.addNotification({
          type: 'error',
          message: error.message
        });

        actions.setModel({ loading: false, error: error.message });
      }
    }),

    fetchNotificationsMetrics: thunk(async (actions, payload, { injections, getStoreActions }) => {
      actions.setModel({ loading: true });
      const { tenantId, onSuccess } = payload;
      try {
        const { lastPushDate, totalPushes, sevenDayPushes, totalDeliverySuccess } = await (tenantId
          ? injections.notificationsApi.fetchNotificationsMetricsByProxy(tenantId)
          : injections.notificationsApi.fetchNotificationsMetrics());

        actions.setModel({
          loading: false,
          error: null
        });

        if (onSuccess) await onSuccess(lastPushDate, totalPushes, sevenDayPushes, totalDeliverySuccess);
      } catch (error) {
        await handleUnAuthenticated(error, getStoreActions());

        actions.setModel({ loading: false, error: error.message });
      }
    }),

    deleteNotification: thunk(async (_actions, payload, { injections, getStoreActions }) => {
      const { onSuccess, onError, id } = payload;

      try {
        await injections.notificationsApi.delete(id);

        getStoreActions().notificationWidget.addNotification({
          type: 'success',
          message: 'Notification successfully deleted'
        });

        if (onSuccess) onSuccess();
      } catch (error) {
        await handleUnAuthenticated(error, getStoreActions());

        getStoreActions().notificationWidget.addNotification({
          type: 'error',
          message: error.message
        });

        if (onError) onError(error.message);
      }
    })
  },
  {
    allow: ['notifications', 'totalItems'],
    storage: 'localStorage'
  }
);
