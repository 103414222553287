import { ComplexModifiersType, StringModifiersType } from '@pushologies/common/helpers/string-modifiers';
import { SelectOption } from '~components/select';

export const modifierSelectOptions: SelectOption<keyof StringModifiersType | keyof ComplexModifiersType>[] = [
  {
    name: 'Capitalize All First',
    value: 'capitalizeAllFirst'
  },
  {
    value: 'capitalizeFirst',
    name: 'Capitalize First'
  },
  {
    value: 'lowercase',
    name: 'Lowercase'
  },
  {
    value: 'uppercase',
    name: 'Uppercase'
  },
  {
    value: 'dateFormat',
    name: 'Date Format'
  }
];
