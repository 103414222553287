import React from 'react';
import ReactPaginate, { ReactPaginateProps } from 'react-paginate';
import { StyledSection } from './styles';

interface Props extends ReactPaginateProps {
  forcePage: number;
  testId?: string;
}

export const Pagination: React.FC<Props> = (props) => {
  const hasNext = Math.floor(props.pageCount) !== props.forcePage;

  return (
    <StyledSection hasNext={hasNext} data-testid={props.testId || 'paginationSection'}>
      <ReactPaginate {...props} />
    </StyledSection>
  );
};
