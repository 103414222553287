import { SelectQueryBuilder } from 'typeorm';
import { Subscriber } from '@pushologies/database-service/db/entities';
import { SegmentRule, OperatorValue as Op } from '../types';
import { Rule } from '.';

export class SessionCountsRule extends Rule {
  constructor(private rule: SegmentRule<number>) {
    super();
  }

  updateQuery(query: SelectQueryBuilder<Subscriber>) {
    const countParam = this.createParameterHash();
    query.andWhere(`subscriber.session_count ${this.getOperator()} :${countParam}`, {
      [countParam]: this.rule.value
    });
  }

  private getOperator() {
    switch (this.rule.operator) {
      case Op.greaterThanOrEqual:
        return '>=';
      case Op.lessThanOrEqual:
        return '<=';
      case Op.is:
        return '=';
      default:
        throw new Error(`operator "${this.rule.operator}" not supported`);
    }
  }
}
