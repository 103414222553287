import React from 'react';
import { OverlayTrigger } from 'react-bootstrap';
import { useTheme } from 'styled-components';
import { GoogleMap, LoadScript, DrawingManager, Circle, Marker, Autocomplete, Polygon } from '@react-google-maps/api';
import MapsIcon from '~images/map.svg';
import CentralizeIcon from '~images/centralize.svg';
import { StyledTooltip } from '~components/styled/tooltip';
import dayjs from '~helpers/dayjs';
import { PromptButton } from '../../prompt-button';
import { DateTimePicker } from '../date-time-picker';
import { MapsPromptButtonProps, Location, Vertice } from './types';
import { PromptCircleInputs, PromptPolygonInputs } from './inputs';
import { StyledInputSection, PromptBottomSection } from './styles';

enum DEFUALT_GEOLOCATION { // Big Ben
  longitude = -0.124616,
  latitude = 51.500752
}
// TODO: use Libraries type when @react-google-maps/api decide to export it
const GOOGLE_SCRIPT_LIBRARIES: any = ['drawing', 'places'];
const MAPS_WIDTH = Math.min(window.innerWidth, 700);
const MAPS_HEIGHT = Math.min(window.innerHeight, 600);

// tslint:disable:cyclomatic-complexity
export const MapsPromptButton: React.FC<MapsPromptButtonProps> = (props) => {
  const theme = useTheme();
  const autocompleteRef = React.useRef<google.maps.places.Autocomplete>();
  const mapRef = React.useRef<google.maps.Map>();
  const mapCenterRef = React.useRef<Vertice>({
    longitude: DEFUALT_GEOLOCATION.longitude,
    latitude: DEFUALT_GEOLOCATION.latitude
  });
  const [location, setLocation] = React.useState<Location>({
    ...(!props.location.vertices && {
      longitude: props.location.longitude ?? DEFUALT_GEOLOCATION.longitude,
      latitude: props.location.latitude ?? DEFUALT_GEOLOCATION.latitude,
      radius: props.location.radius ?? 2000
    }),
    startDate: props.displayDateFields && (props.location.startDate ?? new Date()),
    endDate: props.displayDateFields && (props.location.endDate ?? dayjs().add(1, 'day').toDate())
  });
  const [userLocation, setUserLocation] = React.useState<Location>({});

  const autocompleteOnLoad = (autocomplete: google.maps.places.Autocomplete) => {
    autocompleteRef.current = autocomplete;
  };

  const autocompleteOnPlaceChanged = () => {
    if (autocompleteRef.current !== null) {
      const { geometry } = autocompleteRef.current.getPlace();
      mapRef.current.setCenter({
        lat: geometry.location.lat(),
        lng: geometry.location.lng()
      });
    }
  };

  const mapsOnLoad = (map: google.maps.Map) => {
    mapRef.current = map;
  };

  const handleInputChange = (key: keyof Location) => (value: any) => {
    setLocation((state) => ({ ...state, [key]: value }));
  };

  const onMarkerComplete = (marker: google.maps.Marker) => {
    const position = marker.getPosition();
    mapCenterRef.current = {
      longitude: position.lng(),
      latitude: position.lat()
    };

    setLocation((state) => ({
      startDate: state.startDate,
      endDate: state.endDate,
      radius: state.radius ?? 2000,
      longitude: position.lng(),
      latitude: position.lat(),
      vertices: undefined
    }));
    // remove marker created by drawer manager, rely on our own <Marker />
    marker.setMap(null);
  };

  const onPolygonComplete = (polygon: google.maps.Polygon) => {
    const vertices: Vertice[] = [];
    const bounds = new google.maps.LatLngBounds();
    polygon.getPath().forEach((path) => {
      vertices.push({
        longitude: path.lng(),
        latitude: path.lat()
      });
      bounds.extend(path);
    });

    mapCenterRef.current = {
      longitude: bounds.getCenter().lng(),
      latitude: bounds.getCenter().lat()
    };
    setLocation((state) => ({
      startDate: state.startDate,
      endDate: state.endDate,
      vertices,
      longitude: undefined,
      latitude: undefined,
      radius: undefined
    }));
    // remove polygon created by drawer manager, rely on our own <Polygon />
    polygon.setMap(null);
  };

  const handleMapCenter = () => {
    mapRef.current.setCenter({
      lat: mapCenterRef.current.latitude,
      lng: mapCenterRef.current.longitude
    });
  };

  const handleSave = () => {
    props.onConfirm(location);
  };

  const persistPropsToLocation = () => {
    if (props.location.latitude || props.location.vertices) {
      setLocation({ ...props.location });
    } else if (userLocation.latitude) {
      setLocation({ ...userLocation, radius: 2000 });
    }

    mapCenterRef.current = {
      longitude:
        props.location.vertices?.[0]?.longitude ??
        props.location.longitude ??
        userLocation.longitude ??
        DEFUALT_GEOLOCATION.longitude,
      latitude:
        props.location.vertices?.[0]?.latitude ??
        props.location.latitude ??
        userLocation.latitude ??
        DEFUALT_GEOLOCATION.latitude
    };
  };

  React.useEffect(() => {
    persistPropsToLocation();
  }, [props.location, userLocation]);

  React.useEffect(() => {
    navigator.geolocation.getCurrentPosition((position) => {
      setUserLocation({
        latitude: position.coords.latitude,
        longitude: position.coords.longitude
      });
    });
  }, []);

  return (
    <PromptButton
      buttonType="icon"
      buttonIcon={props.buttonIcon ? props.buttonIcon : <MapsIcon data-testid="mapsPromptButton" />}
      confirmButtonText="save"
      promptTitle={props.readOnly ? 'Geofence Details' : 'Set new location'}
      onConfirm={!props.readOnly && handleSave}
      onCancel={!props.readOnly && persistPropsToLocation}
      disableButton={props.disabled}
      backdropCancel={props.readOnly}
      disableConfirm={props.readOnly}
    >
      <LoadScript
        id="LocationField"
        googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}
        libraries={GOOGLE_SCRIPT_LIBRARIES}
      >
        <GoogleMap
          mapContainerStyle={{
            width: `${MAPS_WIDTH}px`,
            height: `${MAPS_HEIGHT}px`
          }}
          zoom={10}
          center={{ lat: mapCenterRef.current.latitude, lng: mapCenterRef.current.longitude }}
          options={{ fullscreenControl: false, streetViewControl: false }}
          onLoad={mapsOnLoad}
        >
          {!props.readOnly && (
            <DrawingManager
              onMarkerComplete={onMarkerComplete}
              onPolygonComplete={onPolygonComplete}
              options={{
                drawingControlOptions: {
                  drawingModes: (props.allowedDrawingModes ?? ['marker']) as google.maps.drawing.OverlayType[]
                },
                polygonOptions: {
                  fillColor: theme.palette.primaryMustard,
                  strokeColor: theme.palette.primaryMustard
                }
              }}
            />
          )}
          <Autocomplete onLoad={autocompleteOnLoad} onPlaceChanged={autocompleteOnPlaceChanged}>
            <StyledInputSection>
              <input type="text" placeholder="Search location" />
            </StyledInputSection>
          </Autocomplete>
          {location?.longitude && !location?.vertices && (
            <>
              <Marker
                position={{ lat: location.latitude, lng: location.longitude }}
                icon={{
                  path: 'M85.5,0C38.28,0,0,38.28,0,85.5c0,34.05,31.41,69.34,49,99.68,23.99,41.38,36.21,72.82,36.5,72.82s12.92-29.3,37.5-72.82c16.22-28.72,48-64.09,48-99.68C171,38.28,132.72,0,85.5,0Zm40.59,94.24c-5.29,3.14-51.67,29.84-51.67,29.84v11.49l-19.44,11.49v-17.61c0-18.68,7.13-20.27,14.56-24.97,5.57-3.52,33.51-19.92,33.51-19.92l-28.42-16.78v22.34l-19.64,11.89V53.9c0-8.18,1.58-10,3.89-12.15s7.11-1.36,10.66,.91c3.55,2.27,55.39,31.99,58.95,34.06,3.55,2.07,5.87,4.88,5.87,8.27s-2.98,6.12-8.27,9.26Z',
                  fillColor: theme.palette.primaryPlum,
                  fillOpacity: 1,
                  scale: 0.15,
                  anchor: { x: 85.5, y: 258 } as any
                }}
              />
              <Circle
                center={{ lat: location.latitude, lng: location.longitude }}
                options={{
                  radius: location.radius,
                  fillColor: theme.palette.primaryMustard,
                  strokeColor: theme.palette.primaryMustard
                }}
              />
            </>
          )}
          {location?.vertices && (
            <Polygon
              options={{
                fillColor: theme.palette.primaryMustard,
                strokeColor: theme.palette.primaryMustard
              }}
              paths={location.vertices.map((vertice) => ({
                lat: vertice.latitude,
                lng: vertice.longitude
              }))}
            />
          )}
        </GoogleMap>
      </LoadScript>
      <PromptBottomSection>
        {!location.vertices && (location.longitude || location.latitude || location.radius) && (
          <PromptCircleInputs
            handleInputChange={handleInputChange}
            latitude={location.latitude}
            longitude={location.longitude}
            radius={location.radius}
            readOnly={props.readOnly}
          />
        )}
        {location.vertices && (
          <PromptPolygonInputs
            vertices={location.vertices}
            handleInputChange={handleInputChange}
            readOnly={props.readOnly}
          />
        )}
        <section className="icons">
          <OverlayTrigger placement="top" overlay={<StyledTooltip>Recenter map window</StyledTooltip>}>
            <CentralizeIcon onClick={handleMapCenter} />
          </OverlayTrigger>
        </section>
      </PromptBottomSection>
      {props.displayDateFields && (
        <PromptBottomSection>
          <DateTimePicker value={location.startDate} onChange={handleInputChange('startDate')} label="From" />
          <DateTimePicker value={location.endDate} onChange={handleInputChange('endDate')} label="To" />
        </PromptBottomSection>
      )}
    </PromptButton>
  );
};
