import React, { useCallback, useEffect } from 'react';
import { Button, Modal, Spinner } from 'react-bootstrap';
import { StyledModal, IconSpan } from './styles';

interface Base {
  /** if this is provided a footer will be rendered with "Cancel" and on confirm button (button text can be configured with `confirmButtonText` prop) */
  onConfirm?(): void;
  confirmButtonText?: string;
  onCancel?(): void;
  /** Will be called whenever the prompt modal closes */
  onClose?(): void;
  buttonType: 'button' | 'icon';
  promptText?: string;
  promptTitle?: string;
  cancelButtonText?: string;
  loading?: boolean;
  disableConfirm?: boolean;
  disableButton?: boolean;
  /** set to `true` if clicking on the backdrop should close the modal. Will also include a close button in the Modal.Header */
  backdropCancel?: boolean;
  testId?: string;
}

interface TypeButton extends Base {
  buttonType: 'button';
  buttonText: string;
}

interface TypeIcon extends Base {
  buttonType: 'icon';
  buttonIcon: React.ReactNode;
}

// tslint:disable-next-line: cyclomatic-complexity
export const PromptButton: React.FC<TypeButton | TypeIcon> = (props) => {
  const [show, setShow] = React.useState(false);

  const handleShow = useCallback((shouldShow: boolean) => () => setShow(shouldShow), []);

  const handleCancel = () => {
    props.onCancel && props.onCancel();
    setShow(false);
  };

  const handleConfirm = () => {
    props.onConfirm();
    setShow(false);
  };

  const handleModalSectionClick = (event: React.MouseEvent) => {
    // simple hack to stop modal from closing when parent element is using clickAway() hook
    // for example the <TilesViewerUploadField />
    event.stopPropagation();
  };

  useEffect(() => {
    if (!show && props.onClose) {
      props.onClose();
    }
  }, [show]);

  return (
    <>
      {props.buttonType === 'button' ? (
        <Button
          variant="primary"
          onClick={handleShow(true)}
          disabled={props.loading || props.disableButton}
          data-testid={props.testId || 'promptButton'}
        >
          {props.loading && <Spinner animation="border" size="sm" />}
          {props.buttonText}
        </Button>
      ) : (
        <IconSpan
          role="button"
          onClick={handleShow(true)}
          disabled={props.loading || props.disableButton}
          data-testId={props.testId || 'promptIcon'}
        >
          {props.buttonIcon}
        </IconSpan>
      )}
      <StyledModal
        show={show}
        backdrop={props.backdropCancel ? true : 'static'}
        data-testid="promptModal"
        centered
        onHide={handleCancel}
        onClick={handleModalSectionClick}
      >
        {props.promptTitle && <Modal.Header closeButton={props.backdropCancel}>{props.promptTitle}</Modal.Header>}
        <Modal.Body>
          {props.promptText && <p>{props.promptText}</p>}
          {props.children && props.children}
        </Modal.Body>
        {props.onConfirm && (
          <Modal.Footer>
            <Button variant="secondary" onClick={handleCancel} data-testid="promptModalCancelButton">
              {props.cancelButtonText || 'Cancel'}
            </Button>
            <Button
              variant="primary"
              onClick={handleConfirm}
              disabled={props.disableConfirm}
              data-testid="promptModalConfirmButton"
            >
              {props.confirmButtonText}
            </Button>
          </Modal.Footer>
        )}
      </StyledModal>
    </>
  );
};
