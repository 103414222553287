import React from 'react';
import { Modal, Container, Row, Col } from 'react-bootstrap';
import Cookies from 'js-cookie';
import { notificationCache } from '~store/create-notification/notification-cache';
import { useStoreState, useStoreActions } from '~store/hooks';
import { Tour } from '~components/tour';
import { Button } from '~components/button';
import { CarouselList } from './carousel-list';
import { ImageList } from './image-list';
import { StyledModal, ButtonsDiv } from './styles';
import { carouselSteps, CAROUSEL_TOUR_COOKIE } from './tour';

interface Props {
  show: boolean;
  closeModal(): void;
}

const cookieExists = !!Cookies.get(CAROUSEL_TOUR_COOKIE);

export const CarouselBuilder: React.FC<Props> = ({ show, closeModal }) => {
  const { notification } = useStoreState((state) => state.createNotification);
  const { setNotification } = useStoreActions((state) => state.createNotification);

  const handleCancel = () => {
    setNotification(notificationCache.getNotification());
    closeModal();
  };

  const handleSave = () => {
    closeModal();
  };

  React.useEffect(() => {
    if (show) notificationCache.setNotification(notification);
    else notificationCache.clearCache();
  }, [show]);

  return (
    <StyledModal animation={cookieExists} show={show} backdrop="static" centered>
      <Modal.Body data-testid="carouselBuilderModal">
        <Container fluid>
          <Row>
            <Col md={5} data-tour="carouselItems">
              <CarouselList />
            </Col>
            <Col md={7}>
              <ImageList />
              <ButtonsDiv>
                <Button testId="carouselBuilderCancel" onClick={handleCancel}>
                  Cancel
                </Button>
                <Button testId="carouselBuilderSave" onClick={handleSave}>
                  Save
                </Button>
              </ButtonsDiv>
            </Col>
          </Row>
        </Container>
      </Modal.Body>
      <Tour id={CAROUSEL_TOUR_COOKIE} steps={carouselSteps} />
    </StyledModal>
  );
};
