import { Popover } from 'react-bootstrap';
import styled, { css } from 'styled-components';
import ContentEditable from 'react-contenteditable';
import { HideScrollbars } from '~styles/mixins';
import BracketGif from '~images/tours/personalisation_bracket.gif';
import ModalJpg from '~images/tours/personalisation_modal_screenshot.jpg';
import CaretIcon from '~images/cursor_caret.svg';
import { InputSection } from '../styles';
import { CaretLocation } from './types';

export const StyledSpan = styled.span`
  position: absolute;
  top: 0.1rem;
  right: 0.25rem;
  width: 0.9rem;
  cursor: pointer;

  svg {
    path {
      fill: ${({ theme }) => theme.palette.primaryMustard};
    }
  }
`;

interface StyledInputSectionProps {
  $type?: 'input' | 'textarea';
  $rows?: number;
}

export const StyledInputSection = styled(InputSection)<StyledInputSectionProps>`
  p {
    display: flex;
    align-items: start;
    cursor: text;
    width: 100%;
    min-height: 1.5rem;
    margin: 0;
    overflow-x: scroll;
    white-space: nowrap;
    flex-grow: 1;
    margin-right: 0.75rem;
    padding-right: 1rem;

    & > span {
      padding-top: 3px;
    }

    ${HideScrollbars}
  }

  ${({ $type, $rows }) =>
    $type === 'textarea' &&
    css`
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      padding-top: 0.5rem;
      min-height: ${$rows * 2}rem;

      p {
        overflow-x: auto;
        white-space: normal;
        margin-right: 0;
      }
    `}

  ${({ $disabled }) =>
    $disabled &&
    css`
      ${StyledSpan} {
        svg {
          path {
            fill: ${({ theme }) => theme.palette.lightGrey};
          }
        }
      }
    `}
`;

export const InputP = styled.p`
  margin: 0;

  .wrapper {
    position: relative;

    input {
      position: absolute;
      width: 100%;
      left: 0;
      top: 0;
      border: none;
      color: ${({ theme }) => theme.palette.primaryPlum};
      font-family: EavesRegular;
    }

    .width-machine {
      padding: 0 0.1rem;
      position: relative;
      color: ${({ theme }) => theme.palette.primaryPlum};
      font-family: EavesRegular;
      font-size: 1.1rem;
    }
  }
`;

interface StyledContentEditableProps {
  $stretch: boolean;
}

export const StyledContentEditable = styled(ContentEditable)<StyledContentEditableProps>`
  display: inline;
  color: ${({ theme }) => theme.palette.primaryPlum};
  flex-grow: ${({ $stretch }) => ($stretch ? 2 : 0)};

  &:focus-visible {
    border: none;
    outline: none;
  }
`;

interface PersonalisationValueSpanProps {
  $editable: boolean;
}

export const PersonalisationValueSpan = styled.span<PersonalisationValueSpanProps>`
  border: 1px solid ${({ theme }) => theme.palette.primaryPlum};
  color: ${({ theme }) => theme.palette.primaryPlum};
  background: #ffebc2;
  font-family: EavesBold;
  font-size: 0.7rem;
  padding: 0.1rem 0.2rem 0 0.2rem;
  border-radius: 0.3rem;
  cursor: ${({ $editable }) => ($editable ? 'default' : 'pointer')};
`;

export const StyledPopover = styled(Popover)`
  border-radius: 0;

  .arrow {
    &:after {
      border-bottom-color: #f8edd4;
    }
  }
  .popover-body {
    padding: 0.25rem 0.2rem;
    background: #f8edd4;
    border-color: ${({ theme }) => theme.palette.platinum};
  }
`;

interface StyledButtonProps {
  $active: boolean;
}

export const StyledButton = styled.button<StyledButtonProps>`
  display: block;
  width: 100%;
  border: none;
  background: transparent;
  outline: none;
  font-family: EavesRegular;
  color: ${({ theme, $active }) => ($active ? theme.palette.primaryPlum : theme.palette.textColor)};
  font-size: 0.8rem;
  line-height: 1rem;

  &:hover {
    color: ${({ theme }) => theme.palette.primaryPlum};
  }
`;

interface CaretIconDivProps {
  $location: CaretLocation;
}

export const CaretIconDiv = styled.div<CaretIconDivProps>`
  position: fixed;
  width: 2rem;
  height: 0.6rem;
  left: ${({ $location }) => $location.left + 3}px;
  top: ${({ $location }) => $location.top - 2}px;
  display: ${({ $location }) => $location.display};
  cursor: pointer;
  z-index: 1000;

  svg {
    display: block;
    width: 100%;
  }

  &:hover {
    svg {
      circle {
        fill: ${({ theme }) => theme.palette.primaryPlum};
      }
    }
  }
`;

export const BracketGifSection = styled.section`
  background: url(${BracketGif});
`;

export const ModalJpgSection = styled.section`
  background: url(${ModalJpg});
`;

export const StyledCaretIcon = styled(CaretIcon)`
  width: 1.7rem;
`;
