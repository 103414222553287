import { fabric } from 'fabric';
import { PersonalisationConfigItem } from '@pushologies/database-service/db/entities/notification';
import { ButtonPosition } from '@pushologies/database-service/db/entities/content';
import { ButtonOptions } from '~store/create-notification/types';
import { personalisationValueStringToPlainString } from '~components/personalisation-string';

/**
 * Transforms a buttons canvas coordinates into format which the mobile sdk understands
 */
export function buttonToPosition(button: fabric.Object, overwritePos?: ButtonPosition): ButtonPosition {
  return Object.assign(
    {
      top: Math.round(button.aCoords.tl.y),
      left: Math.round(button.aCoords.tl.x),
      bottom: Math.round(button.aCoords.br.y),
      right: Math.round(button.aCoords.br.x)
    },
    overwritePos
  );
}

export const getButtonDimensions = (btn: fabric.Object) => ({
  width: Math.floor(btn.width * btn.scaleX),
  height: Math.floor(btn.height * btn.scaleY)
});

export const isImageObject = (object: fabric.Object): object is fabric.Image => object.type === 'image';

/**
 * Adds buttons from easy peasy store to the canvas
 */
export const persistStoreToCanvas = (
  canvas: fabric.Canvas,
  buttons: Record<string, ButtonOptions> = {},
  videoElement: HTMLVideoElement
) => {
  for (const button of Object.values(buttons)) {
    const data = {
      color: button.controlsColor,
      id: button.id,
      displayName: button.displayName,
      text: button.options.text,
      textColor: button.options.textColor,
      fontSize: button.options.fontSize,
      buttonAppear: button.options.buttonAppear,
      buttonDisappear: button.options.buttonDisappear,
      textAlignmentVertical: button.options.textAlignmentVertical,
      textAlignmentHorizontal: button.options.textAlignmentHorizontal,
      textWrapping: button.options.textWrapping,
      ...(button.options.borderColor && { borderColor: button.options.borderColor })
    };
    const width = button.width;
    const height = button.height;
    const currentTimeInMilliseconds = videoElement.currentTime * 1000;

    if (!!button.options.contentItemId) {
      fabric.Image.fromURL(button.options.buttonImageUrl, function (image) {
        image.left = button.options.position.left;
        image.top = button.options.position.top;
        image.data = data;
        image.scaleX = width / image.width;
        image.scaleY = height / image.height;
        image.objectCaching = true;
        image.statefullCache = true;
        image.lockRotation = true;
        image.cornerSize = 20;
        image.cornerColor = button.controlsColor;
        image.transparentCorners = false;
        image.visible =
          currentTimeInMilliseconds >= button.options.buttonAppear &&
          currentTimeInMilliseconds <= button.options.buttonDisappear;
        image.setControlVisible('mtr', false); // disable rotation controls
        image.setCoords();

        canvas.add(image);
      });
    } else {
      const object = new fabric.Rect({
        data,
        originX: 'left',
        originY: 'top',
        width,
        height,
        left: button.options.position.left,
        top: button.options.position.top,
        fill: button.options.backgroundColor,
        lockRotation: true,
        cornerSize: 20,
        cornerColor: button.controlsColor,
        transparentCorners: false,
        visible:
          currentTimeInMilliseconds >= button.options.buttonAppear &&
          currentTimeInMilliseconds <= button.options.buttonDisappear
      });
      object.setCoords();
      object.setControlVisible('mtr', false);

      canvas.add(object);
    }
  }
};

function fragmentText(ctx: CanvasRenderingContext2D, text: string, maxWidth: number) {
  const words = text.split(' '),
    lines = [];
  let line = '';
  if (ctx.measureText(text).width < maxWidth) {
    return [text];
  }
  while (words.length > 0) {
    while (ctx.measureText(words[0]).width >= maxWidth) {
      const tmp = words[0];
      words[0] = tmp.slice(0, -1);
      if (words.length > 1) {
        words[1] = tmp.slice(-1) + words[1];
      } else {
        words.push(tmp.slice(-1));
      }
    }
    if (ctx.measureText(line + words[0]).width < maxWidth) {
      line += words.shift() + ' ';
    } else {
      lines.push(line);
      line = '';
    }
    if (words.length === 0) {
      lines.push(line);
    }
  }
  return lines;
}

/**
 * Draws Button text onto the canvas selection context
 */
function drawButtonText(
  ctx: CanvasRenderingContext2D,
  button: fabric.Rect,
  personalisation: PersonalisationConfigItem[]
) {
  if (!button.data.text) return;

  const text = personalisationValueStringToPlainString(button.data.text, personalisation);
  const { x, y } = button.getCenterPoint();

  const halfWidth = button.getScaledWidth() / 2;
  const halfHeight = button.getScaledHeight() / 2;
  const buttonOrigin = { x, y };
  ctx.textBaseline = 'middle';
  ctx.textAlign = button.data.textAlignmentHorizontal || 'center';

  // handle horizontal alignment
  switch (button.data.textAlignmentHorizontal) {
    case 'left':
      buttonOrigin.x -= halfWidth;
      break;
    case 'right':
      buttonOrigin.x += halfWidth;
      break;
  }

  // handle vertical alignment
  switch (button.data.textAlignmentVertical) {
    case 'top':
      buttonOrigin.y -= halfHeight;
      ctx.textBaseline = 'top';
      break;
    case 'bottom':
      buttonOrigin.y += halfHeight;
      ctx.textBaseline = 'bottom';
      break;
  }

  ctx.fillStyle = button.data.textColor;
  ctx.font = `${button.data.fontSize}px ${button.data.font}`;

  let lines = fragmentText(ctx, text, button.data.textWrapping ? button.getScaledWidth() : 100000);
  if (button.data.textAlignmentVertical === 'bottom') {
    lines = lines.reverse();
  }

  lines.forEach(function (line, i) {
    const base = button.data.textAlignmentVertical === 'bottom' ? i * -1 : i;
    ctx.fillText(line, buttonOrigin.x, buttonOrigin.y + base * button.data.fontSize);
  });
}

/**
 * Draws the guidelines for a active button onto the canvas selection context
 */
function drawButtonGuidelines(canvas: fabric.Canvas, ctx: CanvasRenderingContext2D, scaling: number) {
  const button = canvas.getActiveObject();
  if (!button) return;

  const canvasWidth = canvas.getWidth();
  const canvasHeight = canvas.getHeight();
  const targetHeight = button.aCoords.bl.y - button.aCoords.tl.y;
  const targetWidth = button.aCoords.tr.x - button.aCoords.tl.x;
  // in portrait mode text appears small so enlarge
  const fontSizeMultiplier = 0.5 / scaling;
  const lineWidth = 1 / scaling;

  ctx.save();
  // top line
  ctx.lineWidth = lineWidth;
  ctx.strokeStyle = button.data.color;
  ctx.beginPath();
  ctx.moveTo(0, button.aCoords.tl.y);
  ctx.lineTo(canvasWidth, button.aCoords.tl.y);
  ctx.stroke();
  ctx.restore();
  // bottom line
  ctx.lineWidth = lineWidth;
  ctx.strokeStyle = button.data.color;
  ctx.beginPath();
  ctx.moveTo(0, button.aCoords.bl.y);
  ctx.lineTo(canvasWidth, button.aCoords.bl.y);
  ctx.stroke();
  ctx.restore();
  // left line
  ctx.lineWidth = lineWidth;
  ctx.strokeStyle = button.data.color;
  ctx.beginPath();
  ctx.moveTo(button.aCoords.tl.x, 0);
  ctx.lineTo(button.aCoords.tl.x, canvasHeight);
  ctx.stroke();
  ctx.restore();
  // right line
  ctx.lineWidth = lineWidth;
  ctx.strokeStyle = button.data.color;
  ctx.beginPath();
  ctx.moveTo(button.aCoords.tr.x, 0);
  ctx.lineTo(button.aCoords.tr.x, canvasHeight);
  ctx.stroke();
  ctx.restore();

  // x text
  ctx.fillStyle = 'white';
  ctx.beginPath();
  ctx.moveTo(button.aCoords.tl.x - 25, button.aCoords.tl.y + targetHeight / 2);
  ctx.lineTo(button.aCoords.tl.x - 40, button.aCoords.tl.y + targetHeight / 2 - 15);
  ctx.lineTo(button.aCoords.tl.x - 40, button.aCoords.tl.y + targetHeight / 2 + 15);
  ctx.fill();
  ctx.restore();
  ctx.font = `${30 * fontSizeMultiplier}px EavesBold`;
  ctx.textAlign = 'end';
  ctx.textBaseline = 'middle';
  ctx.fillText(
    `X ${Math.floor(button.aCoords.tl.x)}`,
    button.aCoords.tl.x - 50,
    button.aCoords.tl.y + targetHeight / 2
  );
  // y text
  ctx.fillStyle = 'white';
  ctx.beginPath();
  ctx.moveTo(button.aCoords.tl.x + targetWidth / 2, button.aCoords.tl.y - 20);
  ctx.lineTo(button.aCoords.tl.x + targetWidth / 2 - 15, button.aCoords.tl.y - 35);
  ctx.lineTo(button.aCoords.tl.x + targetWidth / 2 + 15, button.aCoords.tl.y - 35);
  ctx.fill();
  ctx.restore();
  ctx.font = `${30 * fontSizeMultiplier}px EavesBold`;
  ctx.textAlign = 'center';
  ctx.textBaseline = 'bottom';
  ctx.fillText(`Y ${Math.floor(button.aCoords.tl.y)}`, button.aCoords.tl.x + targetWidth / 2, button.aCoords.tl.y - 40);

  // displayName text
  ctx.fillStyle = button.data.color;
  ctx.font = `${1.75 * fontSizeMultiplier}rem EavesBold`;
  ctx.textAlign = 'left';
  ctx.textBaseline = 'bottom';
  ctx.fillText(button.data.displayName, button.aCoords.tr.x + 5, button.aCoords.tr.y - 5);
}

/**
 * Draws the selection context which is used to draw the button guides and text
 * Calculates which buttons should be visible on canvas at current video timestamp
 * @param objectCallback Callback which will be called on each button before visibility check is made
 */
export const renderSelectionContext = (
  canvas: fabric.Canvas,
  video: HTMLVideoElement,
  canvasScaling: number,
  personalisation: PersonalisationConfigItem[],
  objectCallback?: (obj: fabric.Object) => void
) => {
  const ctx = canvas.getSelectionContext();
  canvas.clearContext(ctx);
  const currentTimeInMilliSeconds = video.currentTime * 1000;

  for (const object of canvas.getObjects()) {
    objectCallback && objectCallback(object);

    let visibleStatus = false;
    if (
      currentTimeInMilliSeconds >= object.data.buttonAppear &&
      currentTimeInMilliSeconds <= object.data.buttonDisappear
    ) {
      visibleStatus = true;
    }

    if (visibleStatus !== object.visible) object.set('visible', visibleStatus);
    if (visibleStatus) drawButtonText(ctx, object, personalisation);
  }

  drawButtonGuidelines(canvas, ctx, canvasScaling);

  canvas.requestRenderAll();
};
