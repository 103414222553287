import { action, thunk, State } from 'easy-peasy';
import { handleUnAuthenticated } from '~store/helpers';
import { NotificationResultModel } from './types';

export * from './types';

const initialState: State<NotificationResultModel> = {
  loading: false,
  error: null,
  notificationResults: [],
  buttonResults: []
};

export const notificationResultModel: NotificationResultModel = {
  ...initialState,

  setModel: action((state, { params, ...rest }) => {
    Object.assign(state, rest);
  }),

  fetchNotificationResults: thunk(async (actions, payload, { injections, getStoreActions }) => {
    actions.setModel({ loading: true });
    const { notificationId, tenantId, onSuccess } = payload;

    try {
      const { notificationResults, buttonResults } = await (tenantId
        ? injections.notificationsApi.fetchByProxy(notificationId, tenantId)
        : injections.notificationsApi.fetch(notificationId));
      actions.setModel({
        loading: false,
        error: null,
        buttonResults,
        notificationResults
      });

      if (onSuccess) await onSuccess(notificationResults, buttonResults);
    } catch (error) {
      await handleUnAuthenticated(error, getStoreActions());

      getStoreActions().notificationWidget.addNotification({
        type: 'error',
        message: error.message
      });

      actions.setModel({ loading: false, error: error.message });
    }
  })
};
