import { action, thunk, State } from 'easy-peasy';
import { handleUnAuthenticated } from '~store/helpers';
import { TenantModel } from './types';

export * from './types';

const initialState: State<TenantModel> = {
  loading: false,
  error: null,
  tenant: { id: '', name: '', licenses: [], userProfile: null }
};

export const tenantModel: TenantModel = {
  ...initialState,

  setModel: action((state, { tenant }) => {
    Object.assign(state, { tenant });
  }),

  fetchTenant: thunk(async (actions, payload, { injections, getStoreActions }) => {
    actions.setModel({ loading: true });
    const { onSuccess, onError } = payload || {};

    try {
      const tenant = await injections.tenantApi.fetchTenant();

      actions.setModel({ loading: false, error: null, tenant });

      if (onSuccess) onSuccess(tenant);
    } catch (error) {
      await handleUnAuthenticated(error, getStoreActions());

      getStoreActions().notificationWidget.addNotification({
        type: 'error',
        message: error.message
      });

      actions.setModel({ loading: false, error: error.message });
      if (onError) onError(error.message);
    }
  })
};
