export enum IN_APP_ALIGNMENT_OPTIONS {
  CENTER = 'CENTER',
  FULLSCREEN = 'FULLSCREEN'
}

export type InAppMediaOptions = {
  alignment: IN_APP_ALIGNMENT_OPTIONS;
};

export type InApp = {
  type: INAPP_TYPE;
  trigger?: INAPP_TRIGGERS;
  customEvent?: string;
  imageOptions?: InAppMediaOptions;
  videoOptions?: InAppMediaOptions;
  ctaLabel?: string;
  targetUrl?: string;
  closeLabel?: string;
  expiresAt?: Date;
};

export enum INAPP_TRIGGERS {
  APP_OPEN = 'APP_OPEN',
  CUSTOM_TRIGGER = 'CUSTOM_TRIGGER'
}

export enum INAPP_TYPE {
  STANDARD = 'STANDARD',
  IMAGE = 'IMAGE',
  VIDEO = 'VIDEO',
  CAROUSEL = 'CAROUSEL'
}
