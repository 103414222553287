import { thunk, action, persist } from 'easy-peasy';
import { CredentialsModel } from './types';
import { handleUnAuthenticated } from '../helpers';
import { credentialsToStore } from './helpers';

export * from './types';

export const credentialsModel: CredentialsModel = persist(
  {
    loading: false,
    error: null,
    firebase: null,
    apn: null,

    setCredentialsModel: action((state, payload) => {
      Object.assign(state, payload);
    }),

    fetchCredentials: thunk(async (dispatch, _, { injections, getStoreActions }) => {
      dispatch.setCredentialsModel({ loading: true });
      try {
        const { credentials } = await injections.credentialsApi.fetchCredentials();
        dispatch.setCredentialsModel({ ...credentialsToStore(credentials), loading: false, error: null });
      } catch (error) {
        await handleUnAuthenticated(error, getStoreActions());
        dispatch.setCredentialsModel({ apn: null, firebase: null, loading: false, error: error.message });
      }
    }),

    uploadCredentials: thunk(async (dispatch, payload, { injections, getStoreActions }) => {
      dispatch.setCredentialsModel({ loading: true, error: null });
      try {
        const { credentials } = await injections.credentialsApi.uploadCredentials(payload);
        dispatch.setCredentialsModel({ ...credentialsToStore(credentials), loading: false, error: null });
        payload.onSuccess();
      } catch (error) {
        await handleUnAuthenticated(error, getStoreActions());

        getStoreActions().notificationWidget.addNotification({
          type: 'error',
          message: error.message
        });

        dispatch.setCredentialsModel({ apn: null, firebase: null, loading: false, error: error.message });
      }
    })
  },
  {
    allow: ['firebase', 'apn']
  }
);
