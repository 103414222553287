import { Entity, Column, PrimaryGeneratedColumn, OneToOne, JoinColumn, Index } from 'typeorm';
import { Tenant } from './tenant';
import { TenantUser } from './tenant-user';

@Entity('tenant_credentials')
export class TenantCredentials {
  @PrimaryGeneratedColumn('uuid')
  id: string;

  @Column()
  tenantId: string;
  @OneToOne((_) => Tenant, { onDelete: 'CASCADE' })
  @JoinColumn()
  @Index()
  tenant: Tenant;

  @Column({ type: 'text', name: 'apn_authkey', nullable: true })
  apnAuthKey: string;

  @Column({ type: 'varchar', name: 'apn_key_id', nullable: true })
  apnKeyId: string;

  @Column({ type: 'varchar', name: 'apn_team_id', nullable: true })
  apnTeamId: string;

  @Column({ type: 'varchar', name: 'apn_bundle_id', nullable: true })
  apnBundleId: string;

  @Column({ type: 'varchar', name: 'firebase_email', nullable: true })
  firebaseEmail: string;

  @Column({ type: 'text', name: 'firebase_private_key', nullable: true })
  firebasePrivateKey: string;

  @Column({ type: 'varchar', name: 'firebase_project_id', nullable: true })
  firebaseProjectId: string;

  @Column({ nullable: true })
  createdById: string;
  @OneToOne((_) => TenantUser, { onDelete: 'SET NULL' })
  @JoinColumn()
  @Index()
  createdBy: TenantUser;
}
